const GSTR_REPORT_API = {
    // Item Details From QR_Code
    GSTR_B2B_LIST: "api/gstr/getGSTRForB2B",
    GSTR_B2C_LIST: "api/gstr/getGSTRForB2C",
    GSTR_B2CS_LIST: "api/gstr/getGSTRForB2CS",
    GSTR_HSN_LIST: "api/gstr/getGSTRForHSN",
    GSTR_DOCS_LIST: "api/gstr/getGSTRForDOCS",
    GSTR_CONSOLIDATE_HSN_LIST: "api/gstr/getGSTRForConsolidateHSN",
};

export default GSTR_REPORT_API;