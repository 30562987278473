import { ColumnFilter } from '../Common/ColumnFilter';
export const COLUMNS = [
	// {
	// 	Header: 'S.No.',
	// 	Footer: 'S.No.',
	// 	accessor: 'id',
	// 	Filter: ColumnFilter,
	// 	// disableFilters: true,
	// },
	{
		Header: 'Supplier Name',
		Footer: 'Supplier Name',
		accessor: 'sm_supplier_name',
		Filter: ColumnFilter,
		// disableFilters: true,
	},
	{
		Header: 'City',
		Footer: 'City',
		accessor: 'sm_city',
		Filter: ColumnFilter,
		// disableFilters: true,
	},
	{
		Header: 'GSTIN',
		Footer: 'GSTIN',
		accessor: 'sm_gstin',
		Filter: ColumnFilter,
		// disableFilters: true,
	},
	{
		Header: 'Contact Person',
		Footer: 'Contact Person',
		accessor: 'sm_contact_person',
		Filter: ColumnFilter,
		// disableFilters: true,
	},
	{
		Header: 'Mobile Number',
		Footer: 'Mobile Number',
		accessor: 'sm_mobile_number',
		Filter: ColumnFilter,
		// disableFilters: true,
	},
	{
		Header: 'Email',
		Footer: 'Email',
		accessor: 'sm_emailid',
		Filter: ColumnFilter,
		// disableFilters: true,
	},
	{
		Header: 'Status',
		Footer: 'Status',
		accessor: 'status',
		Filter: ColumnFilter,
		// disableFilters: true,
	}
]

export const GROUPED_COLUMNS = [
]