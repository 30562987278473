import React from "react";
import { useState, useEffect } from 'react';
import "bootstrap-daterangepicker/daterangepicker.css";
import axios from 'axios';
import { getRefreshToken } from "../../../../services/AuthService";
import { getLoggedInUser } from "../../../../services/AuthService";
import { UserLogout } from "../../../layouts/nav/Logout";
import COMMONVARIABLES from "../../Utilities/constants";
import LoadingOverlay from 'react-loading-overlay-ts';
import ERROR_MESSAGE from "../../Validation/ErrorMessage";
import DASHBOARD_API from "../../../Api/DashboardApi";
import { ToastContainer, toast } from "react-toastify";
import { Pie } from "react-chartjs-2";
import { ArcElement } from "chart.js";
import Chart from "chart.js/auto";

const BranchWiseSalesBarChat = ({ color1, color2, color3, color4, height, width }) => {

  const user = getLoggedInUser();
  const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } };
  const [branchWiseSalesList, setBranchWiseSalesList] = useState([]);
  const [tooltipData, setTooltipData] = useState([]);
  const [xaxis, setXaxis] = useState([]);
  const [maxTotal, setMaxTotal] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const udfnBranchWiseSalesList = async () => {
    setIsLoading(true)

    try {
      const params = {
        user_id: user.id,
      };

      const [response] = await Promise.all([
        axios.post(process.env.REACT_APP_BASE_URL + DASHBOARD_API.BRANCH_WISE_SALES_LIST, params, config)
      ]);
      setIsLoading(false);

      if (response?.data?.body?.status === COMMONVARIABLES.STATUS.INVALIDUSER) {
        setIsLoading(false);
        var errorMsg = response?.data?.body?.message;
        toast.error(errorMsg, { position: toast.POSITION.TOP_RIGHT });
        UserLogout();
      }

      if (response?.data?.body?.status === COMMONVARIABLES.STATUS.UNAUTHORIZED) {
        const refresh = await getRefreshToken();
        setIsLoading(false);

        if (refresh) {
          if (Number(refresh) === COMMONVARIABLES.STATUS.EXPIRED_TOKEN) {
            UserLogout();
          } else {
            await udfnBranchWiseSalesList();
          }
        }
      }

      if (response?.data?.body?.status === COMMONVARIABLES.STATUS.SERVICE_CATCH_STATUS) {
        setIsLoading(false);
        toast.error(ERROR_MESSAGE.API_ERROR.SERVICE_CATCH_ERROR, { position: toast.POSITION.TOP_RIGHT });
      }

      if (response?.data?.body?.BranchWiseSalesList) {
        setBranchWiseSalesList(response?.data?.body?.BranchWiseSalesList)
        var data = [];
        var seriesData = [];
        var total = 0;
        response?.data?.body?.BranchWiseSalesList.forEach((row) => {
          total = total + row.total_amount;
          data.push(row.total_amount)
          seriesData.push(row.branch_name)
        })
        setTooltipData(data);
        setXaxis(seriesData);
        setMaxTotal(total)
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false)
      console.error('Error fetching master data:', error);
    }
  }

  const data = {
    datasets: [
      {
        data: tooltipData,
        borderWidth: 0,
        backgroundColor: [
          `${color1 ? color1 : "#BFF098"}`,
          `${color2 ? color2 : "#EA8D8D"}`,
          `${color3 ? color3 : "#BFF098"}`,
          `${color4 ? color4 : "#FF61D2"}`,
        ],
        hoverBackgroundColor: [
          `${color1 ? color1 : "#BFF098"}`,
          `${color2 ? color2 : "#EA8D8D"}`,
          `${color3 ? color3 : "#BFF098"}`,
          `${color4 ? color4 : "#FF61D2"}`,
        ],
      },
    ],
    labels: xaxis,
  };

  const options = {
    plugins: {
      legend: {
        show: true,
        position: "bottom",
        labels: {
          boxWidth: 36,
          padding: 40,
          font: {
            size: 15
          },
        },
        align: "center",
      },
      responsive: true,
    },

    maintainAspectRatio: false,
  };

  useEffect(() => {
    udfnBranchWiseSalesList();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    // <div className="pie-chart">
    <Pie className='pie-chart' data={data} options={options} />
    // </div>
  );
}
export default BranchWiseSalesBarChat;
