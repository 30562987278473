import axios from 'axios';
import swal from "sweetalert";
import {
    loginConfirmedAction,
    Logout,
} from '../store/actions/AuthActions';
import COMMONVARIABLES from '../jsx/components/Utilities/constants';
import COMMON_API from '../jsx/Api/CommonApi';
export function signUp(email, password) {
    //axios call
    const postData = {
        email,
        password,
        returnSecureToken: true,
    };
    return axios.post(
        `https://identitytoolkit.googleapis.com/v1/accounts:signUp?key=AIzaSyD3RPAp3nuETDn9OQimqn_YF6zdzqWITII`,
        postData,
    );
}

export function login(userid, password) {
    const postData = {
        userid,
        password,
        returnSecureToken: true,
    };
    const respose = {
        "kind": "identitytoolkit#VerifyPasswordResponse",
        "localId": "qmt6dRyipIad8UCc0QpMV2MENSy1",
        "userId": "12001",
        "displayName": "",
        "idToken": "eyJhbGciOiJSUzI1NiIsImtpZCI6ImJhNjI1OTZmNTJmNTJlZDQ0MDQ5Mzk2YmU3ZGYzNGQyYzY0ZjQ1M2UiLCJ0eXAiOiJKV1QifQ.eyJpc3MiOiJodHRwczovL3NlY3VyZXRva2VuLmdvb2dsZS5jb20vcmVhY3QtY291cnNlLWI3OThlIiwiYXVkIjoicmVhY3QtY291cnNlLWI3OThlIiwiYXV0aF90aW1lIjoxNzExNTM1NzQzLCJ1c2VyX2lkIjoicW10NmRSeWlwSWFkOFVDYzBRcE1WMk1FTlN5MSIsInN1YiI6InFtdDZkUnlpcElhZDhVQ2MwUXBNVjJNRU5TeTEiLCJpYXQiOjE3MTE1MzU3NDMsImV4cCI6MTcxMTUzOTM0MywiZW1haWwiOiJkZW1vQGV4YW1wbGUuY29tIiwiZW1haWxfdmVyaWZpZWQiOmZhbHNlLCJmaXJlYmFzZSI6eyJpZGVudGl0aWVzIjp7ImVtYWlsIjpbImRlbW9AZXhhbXBsZS5jb20iXX0sInNpZ25faW5fcHJvdmlkZXIiOiJwYXNzd29yZCJ9fQ.hBYnDJnBuOJNBIwdnYEqoIhYwDTQnCcYjJshZ60IcmcGyJvuxixaFnAEj0hweYDwXli6HDCsdo_m5eXkyEOwVeE_qLBuTlCEdWlt1tJpkK58lK_Co4spxfWyDwTIdPZ2NWoJM7mPbHpXt8FkrUyejdzAcvb5VNFjXOR9zL9qT2SAuClVNfBRLlwVc5dX1ofRSL_7SiwP_-A0SqymyVE26WHfa9VONlTMmMLlRQw8iJ491gbp9Ou86KTRn5hno89YfvTjeRytp34hUfdexKsMVr_tg54NAYqo037pXsSizoaeiPXiHo6j5ixvkXSqV2lS6Zfko-06AWCfKzMj0h-yGw",
        "registered": true,
        "refreshToken": "AMf-vBycznENOFqkwDm9cz_xX6sKKJSRjlrunkaZXtIfp_9qdmhDZz-Q7dO14hv6ew7R1BrVgOlYYqAnMY7EZB9qTu2TDWcbe8AjFzcJytBNIF--tr9gjla9YcQ-8pJ4Iq7-SL-uCw9ehlSLIgvt4yXUtdhkXQsARg1H-7YBlpfN-BAx6IAkNHwjCj7P8iAmrkHyb8w-ONUqjlcS2CxFGT-d4K8QvNOHeg",
        "expiresIn": "3600",
        "expireDate": "2024-03-27T11:34:26.717Z"
    }
    return respose;
    // return axios.post(
    //     `https://identitytoolkit.googleapis.com/v1/accounts:signInWithPassword?key=AIzaSyD3RPAp3nuETDn9OQimqn_YF6zdzqWITII`,
    //     postData,
    // );
}

export function formatError(errorResponse) {
    switch (errorResponse.error.message) {
        case 'EMAIL_EXISTS':
            //return 'Email already exists';
            swal("Oops", "Email already exists", "error");
            break;
        case 'EMAIL_NOT_FOUND':
            //return 'Email not found';
            swal("Oops", "Email not found", "error", { button: "Try Again!", });
            break;
        case 'INVALID_PASSWORD':
            //return 'Invalid Password';
            swal("Oops", "Invalid Password", "error", { button: "Try Again!", });
            break;
        case 'USER_DISABLED':
            return 'User Disabled';

        default:
            return '';
    }
}

export function saveTokenInLocalStorage(tokenDetails) {
    tokenDetails.expireDate = new Date(
        new Date().getTime() + tokenDetails.expiresIn * 1000,
    );
    localStorage.setItem('userDetails', JSON.stringify(tokenDetails));
}

export function runLogoutTimer(dispatch, timer, navigate) {
    setTimeout(() => {
        //dispatch(Logout(history));
        dispatch(Logout(navigate));
    }, timer);
}

export function checkAutoLogin(dispatch, navigate) {
    const tokenDetailsString = localStorage.getItem('userDetails');

    let tokenDetails = '';
    if (!tokenDetailsString) {
      
        dispatch(Logout(navigate));
        return;
    }

    // tokenDetails = JSON.parse(tokenDetailsString);
    // let expireDate = new Date(tokenDetails.expireDate);
    // let todaysDate = new Date();

    // if (todaysDate > expireDate) {
    //     dispatch(Logout(navigate));
    //     return;
    // }

    // dispatch(loginConfirmedAction(tokenDetails));

    // const timer = expireDate.getTime() - todaysDate.getTime();
    // runLogoutTimer(dispatch, timer, navigate);
}

export const getLoggedInUser = () => {
    const UserInfo = localStorage.getItem("userDetails");
    if (UserInfo) return JSON.parse(UserInfo);
    return null;
};

export const getStateList = () => {
    const stateList = localStorage.getItem("stateList");
    if (stateList) return JSON.parse(stateList);
    return null;
};

export const getFinancialYear = () => {
    const fy = localStorage.getItem("fy");
    if (fy) return JSON.parse(fy);
    return null;
};
//   export const userValidation = async(response) => {
//     if (response && response.statusFlag === 3) { // user validation
//       toast.error(response.message, { autoClose: 2000 });
//       localStorage.removeItem("userDetails");
//       window.location.reload()     
//       return  
//     } 
//   }


//is user is logged in
const isUserAuthenticated = () => {
    return getLoggedInUser() !== null;
};

//getRefreshToken 
export const getRefreshToken = async () => {
    const user = getLoggedInUser()
    const params = { token: user?.UserInfo?.refreshToken }

  
    const response1 = await axios.post(  process.env.REACT_APP_BASE_URL + COMMON_API.REFRESHTOKEN, params)
    if (response1?.data?.body) {
        if (response1?.data?.body?.status === COMMONVARIABLES.STATUS.EXPIRED_TOKEN) {
            localStorage.removeItem("userDetails");
            localStorage.removeItem("stateList");
            localStorage.removeItem("fy");
            window.location.reload()
            return response1?.data?.body?.status
        }
        const accessToken = response1?.data?.body?.accessToken
        const refreshToken = response1?.data?.body?.refreshToken
        const data = Object.assign({}, user?.UserInfo)
        const stateList = Object.assign({}, user?.stateList)
        const fy = Object.assign({}, user?.fy)
        data.accessToken = accessToken ? accessToken : data.accessToken
        data.refreshToken = refreshToken ? refreshToken : data.refreshToken
        localStorage.setItem('userDetails', JSON.stringify(data))
        localStorage.setItem('stateList', JSON.stringify(stateList));
        localStorage.setItem('fy', fy);
        return accessToken
    } else {
        return ''
    }
}