import React, { useReducer, useEffect, useState } from "react";
/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";
import Collapse from 'react-bootstrap/Collapse';

/// Link
import { Link } from "react-router-dom";
import profile from "../../../images/Untitled-1.jpg";
// import { ThemeContext } from "../../../context/ThemeContext";
import { getLoggedInUser } from '../../../services/AuthService';
import { MenuList } from './Menu';

const reducer = (previousState, updatedState) => ({
  ...previousState,
  ...updatedState,
});

const initialState = {
  active: "",
  activeSubmenu: "",
}

export const SideBar = () => {
  // const {
  // 	iconHover,
  // 	sidebarposition,
  // 	headerposition,
  // 	sidebarLayout,
  // } = useContext(ThemeContext);

  const [state, setState] = useReducer(reducer, initialState);
  const userDetails = getLoggedInUser();
  // const [userDetails, setuserDetails] = useState();
  const [userRoleId, setuserRoleId] = useState(0);
  let handleheartBlast = document.querySelector('.heart');
  function heartBlast() {
    return handleheartBlast.classList.toggle("heart-blast");
  }

  const handleMenuActive = status => {
    setState({ active: status });
    if (state.active === status) {
      setState({ active: "" });
    }
  }
  const handleSubmenuActive = (status) => {
    setState({ activeSubmenu: status })
    if (state.activeSubmenu === status) {
      setState({ activeSubmenu: "" })

    }
  }
  // ForAction Menu
  let path = window.location.pathname;
  path = path.split("/");
  path = path[path.length - 1];

  useEffect(() => {
    MenuList.forEach((data) => {
      data.content?.forEach((item) => {
        if (path === item.to) {
          setState({ active: data.title })
        }
        item.content?.forEach(ele => {
          if (path === ele.to) {
            setState({ activeSubmenu: item.title, active: data.title })
          }
        })
      })
    })
  }, [path]);

  useEffect(() => {
    // const userDetails = localStorage.getItem('userDetails');
    // setuserDetails(userDetails);
    setuserRoleId(userDetails.role_id);
    console.log("Number(userDetails.role_id)", userDetails.role_id)
  }, [])

  return (
    <div className="deznav">
      <PerfectScrollbar className="deznav-scroll">
        <ul className="metismenu" id="menu">
          <li className="nav-label first"></li>
          {MenuList.map((data, index) => {
            let menuClass = data.classsChange;
            if (menuClass === "nav-label") {
              if (userRoleId === 4){
                return (
                  <li className={menuClass} key={index} ></li>
                )
              }
              else
              {
                return (
                  <li className={menuClass} key={index} >{data.title}</li>
                )
              }
            } else {
              if(userRoleId != 4){
              return (
                
                <li className={`has-menu ${state.active === data.title ? 'mm-active' : ''}${data.to === path ? 'mm-active' : ''} `}
                  key={index}
                >

                  {data.content && data.content.length > 0 ?
                    <Link to={"#"}
                      className="has-arrow"
                      onClick={() => { handleMenuActive(data.title) }}
                    >
                      {/* {data.iconStyle} */}
                      {data.iconStyle}
                      <span className="nav-text">{userRoleId != 4? data.title:''}</span>
                    </Link>
                    :
                    <Link to={data.to} >
                      {data.iconStyle}
                      <span className="nav-text">{userRoleId != 4? data.title:''}</span>
                    </Link>
                  }
                   
                  <Collapse in={state.active === data.title ? true : false}>
                  <ul className={`${menuClass === "mm-collapse" ? "mm-show" : ""}`}>
                    {data.content && data.content.map((data, index) => {
                      return (
                        <li key={index}
                          className={`${state.activeSubmenu === data.title ? "mm-active" : ""}`}
                        >
                          {data.content && data.content.length > 0 ?
                            <>
                              <Link to={data.to} className={data.hasMenu ? 'has-arrow' : ''}
                                onClick={() => { handleSubmenuActive(data.title) }}
                              >
                                {data.title}
                              </Link>
                              <Collapse in={state.activeSubmenu === data.title ? true : false}>
                                <ul className={`${menuClass === "mm-collapse" ? "mm-show" : ""}`}>
                                  {data.content && data.content.map((data, index) => {
                                    return (
                                      <>
                                        <li key={index}>
                                          <Link className={`${path === data.to ? "mm-active" : ""}`} to={data.to}>{data.title}</Link>
                                        </li>
                                      </>
                                    )
                                  })}
                                </ul>
                              </Collapse>
                            </>
                            :
                            <Link to={data.to} className={`${data.to === path ? 'mm-active' : ''}`}>
                              {data.title}
                            </Link>
                          }

                        </li>

                      )
                    })}
                  </ul>
                </Collapse>
                
                  
                </li>
               
              ) }
              else
              {
                return (
                
                  <li className={`has-menu ${state.active === data.title ? 'mm-active' : ''}${data.to === path ? 'mm-active' : ''} `}
                    key={index}
                  >
  
                    {data.content && data.content.length > 0 && userRoleId == 4 && data.title == 'Masters' ?
                      <Link to={"#"}
                        className="has-arrow"
                        onClick={() => { handleMenuActive(data.title) }}
                      >
                        {data.iconStyle}
                        <span className="nav-text">{data.title}</span>
                       
                      </Link>
                      : null
                     
                    }
                     
                    <Collapse in={state.active === data.title ? true : false}>
                    <ul className={`${menuClass === "mm-collapse" ? "mm-show" : ""}`}>
                      {data.content && data.content.map((data, index) => {
                        return (
                          <li key={index}
                            className={`${state.activeSubmenu === data.title ? "mm-active" : ""}`}
                          >
                            {data.content && data.content.length > 0 ?
                              <>
                                <Link to={data.to} className={data.hasMenu ? 'has-arrow' : ''}
                                  onClick={() => { handleSubmenuActive(data.title) }}
                                >
                                  {data.title}
                                </Link>
                                <Collapse in={state.activeSubmenu === data.title ? true : false}>
                                  <ul className={`${menuClass === "mm-collapse" ? "mm-show" : ""}`}>
                                    {data.content && data.content.map((data, index) => {
                                      return (
                                        <>
                                          <li key={index}>
                                            <Link className={`${path === data.to ? "mm-active" : ""}`} to={data.to}>{data.title == 'Customer Master' ? data.title : ''}</Link>
                                          </li>
                                        </>
                                      )
                                    })}
                                  </ul>
                                </Collapse>
                              </>
                              :
                              (data.title == 'Customer Master') ? 
                                <Link to={data.to} className={`${data.to === path ? 'mm-active' : ''}`}>
                                {data.title}
                              </Link>
                              : null
                                 
                            }
  
                          </li>
  
                        )
                      })}
                    </ul>
                  </Collapse>
                  
                    
                  </li>
                 
                )
              }
            }
          })}
        </ul>

      </PerfectScrollbar>
    </div>
  );

}

export default SideBar;
