import React, { useEffect, useRef, useState } from 'react';
import { Link, useLocation } from "react-router-dom";
import { Badge, Button, Col, Modal, Row, Alert } from 'react-bootstrap';
import '../../Filters/Common/filtering.css';
import Select from "react-select";
import { useNavigate } from 'react-router-dom';
import "react-datepicker/dist/react-datepicker.css";
import PerfectScrollbar from "react-perfect-scrollbar";
import ERROR_MESSAGE from '../../Validation/ErrorMessage';
import Formatting from '../../Utilities/format';
import swal from "sweetalert";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { getLoggedInUser, getRefreshToken } from '../../../../services/AuthService';
import { Logout } from '../../../../store/actions/AuthActions';
import COMMONVARIABLES from '../../Utilities/constants';
import PURCHASE_API from '../../../Api/PurchaseApi';
import COMMON_API from '../../../Api/CommonApi';
import LoadingOverlay from 'react-loading-overlay-ts';
import { navtoggle } from "../../../../store/actions/AuthActions";
import { useDispatch } from 'react-redux';
import { Accordion } from "react-bootstrap";
import QRCode from "react-qr-code";
import MASTER_API from '../../../Api/MasterApi';
import FormValidation from '../../Validation/FormValidation';

export const PurchaseAdd = () => {
	const user = getLoggedInUser();
	const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } };
	const BASE_URL = process.env.REACT_APP_BASE_URL;
	let navigate = useNavigate();
	const paramData = useLocation();
	const [voucherNumber, setVoucherNumber] = useState("")
	const [voucherDate, setVoucherDate] = useState("")
	const [invNumber, setInvNumber] = useState("")
	const [invDate, setInvDate] = useState("")
	const [supplierName, setSupplierName] = useState({})

	const [supplierAddress, setSupplierAddress] = useState("")
	const [supplierCity, setSupplierCity] = useState("")
	const [supplierGstin, setSupplierGstin] = useState("")


	const [branchCodeOptions, setBranchCodeOptions] = useState([])
	const [supplierList, setSupplierList] = useState([])
	const [supplierNameOptions, setSupplierNameOptions] = useState([])
	const [brandNameOptions, setBrandNameOptions] = useState([])
	const [itemNameOptions, setItemNameOptions] = useState([])

	const [currentItem, setCurrentItem] = useState(null)
	const [currentCodeType, setCurrentCodeType] = useState(null)
	const [currentBranch, setCurrentBranch] = useState(null)
	const [currentRate, setCurrentRate] = useState(null)
	const [currentQty, setCurrentQty] = useState(null)
	const [currentDiscPer, setCurrentDiscPer] = useState(null)
	const [currentDiscAmt, setCurrentDiscAmt] = useState(null)
	const [currentDiscRate, setCurrentDiscRate] = useState(null)
	const [currentAmount, setCurrentAmount] = useState(null)
	const [currentBrand, setCurrentBrand] = useState(null)
	const [currentMarginPer, setCurrentMarginPer] = useState(null)
	const [currentSellingRate, setCurrentSellingRate] = useState(null)
	const [currentLabelDetails, setCurrentLabelDetails] = useState("")
	const [currentDecimal, setCurrentDecimal] = useState(null)
	const [currentSno, setCurrentSno] = useState(0)
	const [currentQRCode, setCurrentQRCode] = useState(0)
	const [isEdit, setIsEdit] = useState(0)


	const [subTotal, setSubTotal] = useState(0)
	const [cgstAmt, setCgstAmt] = useState(0)
	const [sgstAmt, setSgstAmt] = useState(0)
	const [igstAmt, setIgstAmt] = useState(0)
	const [roundedOff, setRoundedOff] = useState(0)
	const [taxType, setTaxType] = useState(0)
	const [grandTotal, setGrandTotal] = useState(0)
	const [freightAmt, setFreightAmt] = useState(0)
	const [purchaseDisc, setPurchaseDisc] = useState(0)
	const [othersAmt, setOthersAmt] = useState(0)

	const [transportDetails, setTransportDetails] = useState("")
	const [narration, setNarration] = useState("")

	const [prevPurchaseDetails, setPrevPurchaseDetails] = useState([])

	const [transactionNo, setTransactionNo] = useState(0)
	const [btnText, setBtnText] = useState("Save")

	const [deletedQRCodes, setDeletedQRCodes] = useState([])

	const [isLoading, setIsLoading] = useState(false);
	const QrManTypeOptions = [
		{ value: 'Q', label: "QR" },
		{ value: 'M', label: "Manual" },
	];

	const [file, setFile] = useState();
	const [fileName, setFileName] = useState("");
	const [invFile, setInvFile] = useState("");
	const [invDisplayFile, setInvDisplayFile] = useState("");
	const dispatch = useDispatch();
	const [labelTypeName, setLabelTypeName] = useState({ label: '35*22', value: 1 })
	const labelTypeNameOptions = COMMONVARIABLES.LABLEPRINTTYPE
	const SUPPLIERTYPE = COMMONVARIABLES.SUPPLIERTYPE;

	const [categoryOptions, setCategoryOptions] = useState([]);
	const [unitOptions, setUnitOptions] = useState([]);
	const [taxOptions, setTaxOptions] = useState([]);
	const [itemList, setItemList] = useState([]);
	const [addCard, setAddCard] = useState(false);

	const [itemCode, setItemCode] = useState(0);
	const [itemName, setItemName] = useState('');
	const [categoryCode, setCategoryCode] = useState('');
	const [groupName, setGroupName] = useState('');
	const [manualCode, setManualCode] = useState('');
	const [displayName, setDisplayName] = useState('');
	const [unitCode, setUnitCode] = useState('');
	const [hsnCode, setHsnCode] = useState('');
	const [taxPer, setTaxPer] = useState('');
	const [discPer, setDiscPer] = useState(0);
	const [status, setStatus] = useState(1);
	const [buttonFlag, setButtonFlag] = useState(false);
	const [editFlag, setEditFlag] = useState(false);
	const [supplierType, setSupplierType] = useState({ label: 'Registered', value: 1 })
	const [isDisabled, setIsDisabled] = useState(false);

	const myid = "myqr"
	const myqrid = "myqrid"

	const buttonRef = useRef(null);

	const onChangeSupplierType = (event) => {
		setSupplierType(event)
		let supplier_array = supplierList;
		supplier_array = supplier_array.filter(e => e.supplier_type === event?.value)
		setSupplierNameOptions(supplier_array || [])
	}
	const handleKeyDown = (event) => {
		// Check if the pressed key is the Tab key (key code 9)
		if (event.keyCode === 9) {
			// Prevent the default Tab behavior to prevent losing focus from the text input
			event.preventDefault();

			// Focus the button element
			buttonRef.current.focus();
		}
	};

	const saveFile = (e) => {
		setFile(e.target.files[0]);
		setFileName(e.target.files[0].name);
	};

	const downloadFile = async (e) => {

		// const url = `https://acquirebase.com/img/logo.png`

		// 	window.open(url, '_blank');
		try {
			const response = await axios.get(
				BASE_URL + PURCHASE_API.DOWNLOAD_INVOICE + "?fileName=" + invFile, {
				responseType: 'blob' // Set response type to blob
			});

			// Create a temporary URL for the downloaded file
			const url = window.URL.createObjectURL(new Blob([response.data]));
			// Create a temporary link element
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', invFile); // Set the filename for the downloaded file
			// Simulate a click on the link to start the download
			document.body.appendChild(link);
			link.click();
			// Clean up
			link.parentNode.removeChild(link);

			// const blob = new Blob([res], { type: 'text/plain' });
			// const url = window.URL.createObjectURL(blob);
			// window.open(url, '_blank');
		} catch (ex) {
			console.log(ex);
		}
	};

	const uploadFile = async (transno) => {
		if (fileName !== "") {
			const user = getLoggedInUser()
			const formData = new FormData();
			formData.append("file", file);
			formData.append("fileName", fileName);
			formData.append("transno", Number(transno));
			const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } };
			try {
				const res = await axios.post(
					BASE_URL + PURCHASE_API.UPLOAD_INVOICE,
					formData, config
				);
				console.log(res);
			} catch (ex) {
				console.log(ex);
			}
		}

	};

	const udfnLoadItem = (e, editItem, type) => {

		e.preventDefault();
		setIsEdit(type)
		setCurrentCodeType({ value: editItem?.qrmantype, label: editItem?.qrmantypetext })
		setCurrentItem({ value: editItem?.itemcode, label: editItem?.itemname })
		setCurrentBranch({ value: editItem?.branchcode, label: editItem?.branchname })
		setCurrentBrand({ value: editItem?.brandcode, label: editItem?.brandname })
		setCurrentQty(editItem?.itemqty)
		setCurrentRate(editItem?.itemrate)
		setCurrentAmount(editItem?.itemamt)
		setCurrentDiscPer(editItem?.discperc)
		setCurrentDiscAmt(editItem?.discamt)
		setCurrentDiscRate(editItem?.discrate)
		setCurrentMarginPer(editItem?.marginperc)
		setCurrentSellingRate(editItem?.sellrate)
		setCurrentLabelDetails(editItem?.labeldetails)
		setCurrentSno(editItem?.sno)
		setCurrentQRCode(editItem?.qrcode)
		// setCurrentCodeType({
		// 	value: "Q",
		// 	label: "QR Code"
		// })


		// {
		// 	"qrmanType": 1,
		// 	"qrmanTypeText": "QR Code",
		// 	"itemName": "Kamatchi Vilaku",
		// 	"itemCode": 2,
		// 	"itemRate": 120,
		// 	"itemQty": 10,
		// 	"branchCode": 2,
		// 	"branchName": "Vadaloore",
		// 	"brandCode": 0,
		// 	"brandName": "Not Applicable",
		// 	"discPerc": 10,
		// 	"discAmt": 120,
		// 	"discRate": 1080,
		// 	"taxPerc": 12,
		// 	"taxAmt": 129.6,
		// 	"marginPerc": 10,
		// 	"sellRate": 1188,
		// 	"labelDetails": null
		// }

	};

	const [purchaseItemDetails, setPurchaseItemDetails] = useState([]);

	const [qrCodeItemDetails, setQRCodeItemDetails] = useState([]);



	const udfnAddItem = (e, sno) => {

		e.preventDefault();
		var commomError = false;
		var errorMsg = '';
		if (currentItem === null || currentBranch === null || Number(currentRate) === 0 || Number(currentQty) === 0) {
			commomError = true;
			errorMsg = ERROR_MESSAGE.COMMON_REQUIRED;
		}
		if (Number(currentSellingRate) < Number(currentDiscRate)) {
			commomError = true;
			errorMsg = ERROR_MESSAGE.SELLING_RATE_VALID;
		}

		if (!commomError) {
			var item_detail = itemNameOptions.find(e => e.im_item_code === currentItem?.value)
			var item_tax_amt = (currentAmount) * (item_detail?.im_tax_per / 100)

			// qrmanType text, itemCode integer,branchCode integer, itemRate float,itemQty float,
			// discPerc float, discAmt float, discRate float, itemAmt float, brandCode integer, marginPerc float, sellRate float, labelDetails text, taxPerc float, taxAmt float


			// const formattedValue = parseFloat(e.target.value).toFixed(currentDecimal);
			// 	setCurrentQty(formattedValue)

			var item = {
				sno: isEdit !== 1 ? purchaseItemDetails.length + 1 : currentSno,
				qrmantype: currentCodeType?.value,
				qrmantypetext: currentCodeType?.label,
				itemname: item_detail?.im_display_name,
				itemcode: item_detail?.im_item_code,
				itemrate: Formatting('two_decimal', currentRate),
				itemqty: parseFloat(currentQty).toFixed(currentDecimal),
				branchcode: Number(currentBranch?.value),
				branchname: currentBranch?.label,
				brandcode: Number(currentBrand?.value),
				brandname: currentBrand?.label,
				itemamt: Formatting('two_decimal', currentAmount),
				discperc: Formatting('two_decimal', currentDiscPer),
				discamt: Formatting('two_decimal', currentDiscAmt),
				discrate: Formatting('two_decimal', currentDiscRate),
				taxperc: supplierType?.value === 1 ? Number(item_detail?.im_tax_per) : 0,
				taxamt: supplierType?.value === 1 ? Formatting('two_decimal', item_tax_amt) : 0,
				marginperc: Formatting('two_decimal', currentMarginPer),
				sellrate: Formatting('round_off', currentSellingRate),
				labeldetails: currentLabelDetails,
				qrcode: isEdit !== 1 || currentCodeType?.value == "M" ? 0 : currentQRCode

			};
			if (isEdit === 0 || isEdit === 2) // If it is add or clone , need to push into array
			{
				var newArray = purchaseItemDetails;
				newArray.push(item);
				setPurchaseItemDetails(newArray);

			}
			else {
				replaceDataBySno(item)
			}
			setCurrentSno(0)
			clearItemValues()

		} else {
			toast.error(errorMsg, {
				position: "top-right",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
			});
		}
		if (purchaseItemDetails.length > 0) {
			setIsDisabled(true)
		}

		// clearItemValues()
		// var len = itemDetails.length;
		// setitemLength(len);
	};

	function clearItemValues() {
		setCurrentItem(null)
		setCurrentBranch(null)
		setCurrentRate('')
		setCurrentQty('')
		setCurrentDiscAmt(0)
		setCurrentDiscPer('')
		setCurrentDiscRate(0)
		setCurrentAmount(0)
		setCurrentMarginPer(0)
		setCurrentSellingRate(0)
		setCurrentLabelDetails("")
		setCurrentQRCode(0)
		setCurrentBrand({
			value: 0,
			label: "Not Applicable"
		})
		setCurrentCodeType({
			value: "Q",
			label: "QR Code"
		})
		setIsEdit(0)
	}

	// Function to replace the entire JSON array based on key
	const replaceDataBySno = (newData) => {
		const updatedData = [...purchaseItemDetails]; // Create a copy of the original data

		// Find the index of items that match the key
		const indexesToUpdate = updatedData.reduce((acc, item, index) => {
			if (item.sno === currentSno) {
				acc.push(index);
			}
			return acc;
		}, []);

		// Replace the items with new data
		indexesToUpdate.forEach(index => {
			updatedData[index] = newData;
		});

		// Update the state with the modified array
		setPurchaseItemDetails(updatedData);
	};

	const udfnSavePurchase = async (event) => {
		try {
			event.preventDefault();

			var commomError = false;
			var errorMsg = '';

			var localFy = localStorage.getItem("fy")
			const user = getLoggedInUser()

			// Validate All Inputs
			if (voucherDate === "" || invDate === "" || invNumber === "" || supplierName === "" || purchaseItemDetails.length === 0) {
				commomError = true;
				errorMsg = ERROR_MESSAGE.COMMON_REQUIRED;
			}

			if (!commomError) {
				setIsLoading(true)
				var purchaseParams = {
					"voucher_no": voucherNumber,
					"voucher_date": voucherDate,
					"supplier_code": supplierName?.value,
					"inv_number": invNumber,
					"inv_date": invDate,
					"total_items": purchaseItemDetails?.length,
					"sub_total": subTotal,
					"cgst": cgstAmt,
					"sgst": sgstAmt,
					"igst": igstAmt,
					"others": Formatting('two_decimal', othersAmt),
					"freight": Formatting('two_decimal', freightAmt),
					"disc_amt": Formatting('two_decimal', purchaseDisc),
					"rounded_off": roundedOff,
					"grand_total": grandTotal,
					"transport_details": transportDetails,
					"narration": narration,
					"file_name": "",
					"user_id": user?.id,
					"company_code": user?.company_code,
					"fy": localFy ? Number(localFy) : 0,
					"item_details": purchaseItemDetails,

				}
				var purchaseURL = PURCHASE_API.SAVE_PURCHASE
				if (transactionNo !== 0) // if it is update
				{
					purchaseParams['trans_no'] = transactionNo

					purchaseParams['deleted_qr_details'] = deletedQRCodes
					purchaseURL = PURCHASE_API.UPDATE_PURCHASE
				}

				const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } };
				const [saveData] = await Promise.all([
					axios.post(BASE_URL + purchaseURL, purchaseParams, config)

				]);
				setIsLoading(false)
				if (saveData?.data?.body?.status === COMMONVARIABLES.STATUS.UNAUTHORIZED) {
					setIsLoading(false)
					fetchMasterDataWithParams()

					const refresh = await getRefreshToken();

					if (refresh) {
						if (Number(refresh) === COMMONVARIABLES.STATUS.EXPIRED_TOKEN) {
							Logout();
						} else {
							await udfnSavePurchase();
						}
					}
				}

				else if (saveData?.data?.body?.status === COMMONVARIABLES.STATUS.SUCCESS) {
					setIsLoading(false)
					uploadFile(transactionNo !== 0 ? transactionNo : saveData?.data?.body?.trans_no)
					swal({
						title: "Success",
						text: transactionNo !== 0 ? "Purchase updated successfully" : "Purchase saved successfully",
						icon: "success",
						//buttons: true,
						dangerMode: true,
					}).then((resp) => {
						if (resp) {


							swal({
								text:
									"Are you sure you want print?",
								icon: "warning",
								buttons: true,
								dangerMode: true,
							}).then((willDelete) => {
								if (willDelete) {
									setQRCodeItemDetails(saveData?.data?.body?.qrcodeList)

								} else {
									navigate("/purchase-list");
								}
							})
						}
					})

				}
				else if (saveData?.data?.body?.status === COMMONVARIABLES.STATUS.ALREADY_IN_USE) {

					toast.error("Already in use", {
						position: "top-right",
						autoClose: 5000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
					});
					setIsLoading(false)
				}

				else {
					toast.error("Something went to be wrong", {
						position: "top-right",
						autoClose: 5000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
					});
					setIsLoading(false)
				}
			} else {
				toast.error(errorMsg, {
					position: "top-right",
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
				});
			}
		}
		catch (e) {
			toast.error("Something went to be wrong", {
				position: "top-right",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
			});
			setIsLoading(false)
		}
	};


	const udfnReprintPurchase = async (event) => {
		try {
			event.preventDefault();
			swal({
				text:
					"Are you sure you want print?",
				icon: "warning",
				buttons: true,
				dangerMode: true,
			}).then((willDelete) => {
				if (willDelete) {
					ReprintPurchase()
				} else {
					navigate("/purchase-list");
				}
			})


		}
		catch (e) {
			toast.error("Something went to be wrong", {
				position: "top-right",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
			});
			setIsLoading(false)
		}
	};

	const ReprintPurchase = async () => {
		const user = getLoggedInUser()
		setIsLoading(true)

		var purchaseURL = PURCHASE_API.REPRINTPURCHASE

		const purchaseParams = { trans_no: transactionNo, user_id: user?.id };
		const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } };
		const [reprintData] = await Promise.all([
			axios.post(BASE_URL + purchaseURL, purchaseParams, config)

		]);
		setIsLoading(false)
		if (reprintData?.data?.body?.status === COMMONVARIABLES.STATUS.UNAUTHORIZED) {
			setIsLoading(false)
			fetchMasterDataWithParams()

			const refresh = await getRefreshToken();

			if (refresh) {
				if (Number(refresh) === COMMONVARIABLES.STATUS.EXPIRED_TOKEN) {
					Logout();
				} else {
					await ReprintPurchase();
				}
			}
		}

		else if (reprintData?.data?.body?.status === COMMONVARIABLES.STATUS.SUCCESS) {
			setIsLoading(false)
			setQRCodeItemDetails(reprintData?.data?.body?.qrcodeList)
		}
		else {

			toast.error("Something went to be wrong", {
				position: "top-right",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
			});
			setIsLoading(false)

		}
	}
	function printQRCode(qrcodeList) {
		const printHtml = window.open('', 'PRINT', 'height=auto,width=100%')
		let html = ''
		let itemDetails = ''
		const user = getLoggedInUser()

		for (let i = 0; i < qrcodeList.length; i++) {
			for (let j = 0; j < Number(qrcodeList[i].pi_quantity); j++) {
				const myids = "myqr"
				const getid = myids + qrcodeList[i].pi_qr_code
				const innerContents = document.getElementById(getid).innerHTML
				itemDetails += `
					<div class="float-child col-4  ml-5 mt-6">				
						<div class="align-center">
							<div class="heading">${user.label_title}</div>
						</div>

						<div class="float-container botton-align ">`
				if (qrcodeList[i].pi_label_details) {
					itemDetails += `<div class="qr_div">
													<div class="rotated"> ${qrcodeList[i].pi_label_details}</div>
												</div>`
				}
				itemDetails += `<div class="float-qrcode ml-2">
												<div class="qr_div ">
													<div> ${innerContents}</div>
												
												</div>
												<div class="qr_div bold-design size-10 align-center mt-2">${qrcodeList[i].pi_qr_code}</div>
											</div>
											<div class="float-details ml-2">
												<div class="bold-design size-9 wor-wrap">${qrcodeList[i].im_display_name}</div>
												<div class="bold-design size-15 m-10">₹${qrcodeList[i].pi_selling_rate}</div>
											</div>
						</div>			
					</div>`
			}
		}

		html += `<html>
					<head>
						<title>Purchase Print</title>
						<style>
						.rotated {
							writing-mode: tb-rl;
							transform: rotate(-180deg);
							font-size: 10px;
						}
				
						.qr_div {
							display: flex;
							align-items: center;
							justify-content: center;
						}
				
						body {
							font-family: Arial, Helvetica, sans-serif;
							width: 416px !important; 
						}
				
						.row {
							width: 100%;
							/* margin-top: 10px; */
						}
				
						.column {
							float: left;
						}
				
						@media all {
							.page-break {
								display: none
							}
						}
						@media print {
				
							body {
							margin-top: 1 !important;
							color: #000;
							}
				
						}
						.heading {
							font-size: 13px !important;
							font-weight: bold;
							text-align: center
						}
				
						.align-center {
							flex-direction: column;
							text-align: left;
						}
				
						.align-left {
							text-align: left;
						}
				
						.align-right {
							text-align: right;
							font-size: large;
						}
				
						.paddding-space {
							/* padding-bottom: 15px */
						}
				
						.center {
							text-align: center;
						}
				
						.padding {
							padding-left: 10px
						}
				
						.qrcode {
							padding-left: 50px
						}
				
						.botton-align {
							/* padding-top: 10px */
						}
				
						.botton {
							padding-top: 10px
						}
				
						.size-10 {
							font-size: 10px !important;
						}
				
						.size-11 {
							font-size: 11px !important;
						}
						.size-9 {
							font-size: 9px !important;
						}
				
						.size-12 {
							font-size: 12px !important;
						}
				
						.size-15 {
							font-size: 15px !important;
						}
				
						.size-small {
							font-size: 10px !important;
							text-align: center !important;
							font-weight: bold;
						}
						.bold-design {
							font-weight: bold;
						}
				
						.float-child {
							display: flex;
							flex-direction: column;
						}
				
						.float-container {
							width: 100%;
							display: flex;
							flex-direction: row;
						}
				
						.float-details {
							width: 65%;
							display: flex;
							flex-direction: column;
							padding-left: 2px;
							text-align: left;
						}
				
						.float-qrcode {
							width: 35%;
							display: flex;
							flex-direction: column;
						}
				
						.marleft {
							padding-left: 20px;
						}
				
						.marleft-10 {
							padding-left: 10px;
						}
				
						.marright {
							padding-right: 20px;
						}
				
						.col-4 {
							flex: 0 0 auto;
							width: 133px !important;
							height: 83px;
						}
				
						.parent {
							display: flex;
							flex-wrap: wrap;
							flex-direction: row;
						}
				
						.mb-15 {
							margin-bottom: 15px;
						}
				
						.mt-5 {
							margin-bottom: 5px;
						}
						.ml-5 {
							margin-left: 5px;
						}
						.ml-10{
							margin-left: 10px;
						}
						.mt-5{
							margin-top: 5px;
						}
						.ml-2 {
							margin-left: 2px;
						}
						.mt-3 {
							margin-top: 3px;
						}
						.mt-2 {
							margin-top: 2px;
						}
						.wor-wrap {
							text-wrap: wrap;
							width: 50px;
							overflow-wrap: break-word;
							height: 30px;
							overflow: hidden;
						}
						.mar-top{
							margin-top: -5px;
						}
						.m-10{
							margin-top: 10px
						}
						</style>
					</head>
					<body onload="window.print();window.close()">
						<div class="row  ml-10">
							<div class='paddding-space'>
								<div class="float-container parent">
									${itemDetails}					
								</div>
							</div>				
						</div>		
					</body>
				</html>`
		printHtml.document.write(html)
		printHtml.document.close()
		printHtml.print()
		printHtml.close()
		navigate("/purchase-list");
		return true
	}

	function printSingleQRCode(qrcodeList) {
		const printHtml = window.open('', 'PRINT', 'height=auto,width=100%')
		let html = ''
		let itemDetails = ''
		const user = getLoggedInUser()

		for (let i = 0; i < qrcodeList.length; i++) {
			for (let j = 0; j < Number(qrcodeList[i].pi_quantity); j++) {
				const myids = "myqrid"
				const getid = myids + qrcodeList[i].pi_qr_code
				const innerContents = document.getElementById(getid).innerHTML

				itemDetails += `<div class="float-child">
			<div class="float-container align-center justfy-center">
				<div class="heading">${user.label_title}</div>
			</div>

			<div class="float-container botton-align ">`
				if (qrcodeList[i].pi_label_details) {
					itemDetails += `<div class="qr_div marleft-10">
												<div class="rotated"> ${qrcodeList[i].pi_label_details}</div>
											</div>`
				}
				itemDetails += `
				<div class="float-qrcode marleft-10">
					<div class="qr_div ">
						<div class=""> ${innerContents}</div>

					</div>
					<div class="qr_div bold-design qrcode-margin  size-large align-center">${qrcodeList[i].pi_qr_code}</div>
				</div>
				<div class="float-details marleft-10">
					<div class="bold-design size-large wor-wrap">${qrcodeList[i].im_display_name}</div>
					<div class="bold-design botton m-1">₹${COMMONVARIABLES.PDFIndia_Rupee_Formatter(qrcodeList[i].pi_selling_rate)}</div>
				</div>
			</div>
		</div>`

			}
		}

		html += `<html>
					<head>
						<title>Purchase Print</title>
						<style>
						.rotated {
							writing-mode: tb-rl;
							transform: rotate(-180deg);
							font-size: 8px;
						}
				
						.qr_div {
							display: flex;
							align-items: center;
							justify-content: center;
						}
				
						body {
							font-family: Arial, Helvetica, sans-serif;
							width: 384px;
                            margin: 0px;
						}
				
						.row {
							width: 100%;
                            height: 56.69px;
                            display: block;
						}
				
						.column {
							float: left;
						}
				
						@media all {
							.page-break {
								display: none
							}
						}
				
						.heading {
							font-size: 11px !important;
							font-weight: bold;
							text-align: center;
						}
				
						.align-center {
							flex-direction: column;
							text-align: center;
						}
				
						.align-left {
							text-align: left;
						}
				
						.align-right {
							text-align: right;
							font-size: large;
						}

						.paddding-space {
							float:right;
						}
				
						.center {
							text-align: center;
						}
				
						.padding {
							padding-left: 10px
						}
				
						.qrcode {
							padding-left: 50px
						}
				
						.botton-align {

						}
				
						.botton {
							padding-top: 5px
						}
				
						.size-large {
							font-size: 8px !important;
						}

						.size-9 {
							font-size: 9px !important;
						}
				
						.size-Xlarge {
							font-size: 13px !important;
						}
						.qrcode-margin {
							margin-top: 2px !important
						}
				
						.size-20 {
							font-size: 20px !important;
						}
				
						.size-small {
							font-size: 10px !important;
							text-align: center !important;
							font-weight: bold;
						}
				
						.color-style {
							padding-top: 20px;
							font-size: 20px !important;
							font-weight: bold;
						}
				
						.bold-design {
							font-weight: bold;
						}
				
						.float-child {
							width: 100%;
							display: flex;
							flex-direction: column;
                            // height: 56.69px;
                            padding: 1px;
							margin-top:2px !important;
						}
				
						.float-container {
							width: 100%;
							display: flex;
							flex-direction: row;
						}
				
						.float-details {
							width: 65%;
							display: flex;
							flex-direction: column;
							padding-left: 2px;
							text-align: left;
						}
				
						.float-qrcode {
							margin-top: 2px;
							width: 35%;
							display: flex;
							flex-direction: column;
						}
				
						.marleft {
							padding-left: 20px;
						}
				
						.marleft-10 {
							padding-left: 5px;
						}
				
						.marright {
							padding-right: 20px;
						}
				
						.marright-10 {
							padding-right: 10px;
						}
				
						.col-4 {
							flex: 0 0 auto;
							width: 33.333%;
						}
				
						.parent {
							display: block;
							flex-wrap: wrap;
							flex-direction: row;
						}
				
						.mt-10 {
							margin-top: 10px;
						}
				
						.pb-15 {
							padding-bottom: 13px;
						}
				
						.mt-5 {
							margin-top: 5px;
						}
				
						.mb-5 {

						}
				
						.mb-2 {
							margin-bottom: 2px;
						}
				
						.justfy-center {
							justify-content: center;
						}
						.p-tb{
							padding: 5px 0px;
						}
						.ml-20{
							margin-left:20px;
						}
						.wor-wrap {
							text-wrap: wrap;
    						width: 10px;
						}
						</style>
					</head>
					<body onload="window.print();window.close()">
						<div class="row">
							<div class='paddding-space'>
								<div class="float-container parent">
									${itemDetails}					
								</div>
							</div>				
						</div>		
					</body>
				</html>`
		printHtml.document.write(html)
		printHtml.document.close()
		printHtml.print()
		printHtml.close()
		navigate("/purchase-list");
		return true
	}

	useEffect(() => {
		if (qrCodeItemDetails && qrCodeItemDetails.length > 0) {
			if (labelTypeName.value === 1) {
				printQRCode(qrCodeItemDetails);
			} else {
				// labelPrint(qrCodeItemDetails)
				printSingleQRCode(qrCodeItemDetails);
			}

		}
	}, [qrCodeItemDetails])
	useEffect(() => {
		var sub_total = 0;
		var total_tax = 0;

		for (var i = 0; i < purchaseItemDetails.length; i++) {
			sub_total = (purchaseItemDetails[i]['discrate'] * purchaseItemDetails[i]['itemqty']) + sub_total
			total_tax = purchaseItemDetails[i]['taxamt'] + total_tax
		}
		setSubTotal(Formatting('two_decimal', sub_total))
		if (supplierType?.value === 1) {
			if (taxType === 1) {
				setCgstAmt(Formatting('two_decimal', (total_tax / 2))) // 2 decimal format
				setSgstAmt(Formatting('two_decimal', (total_tax / 2)))
				setIgstAmt(0)
			}
			else {
				setIgstAmt(Formatting('two_decimal', total_tax))
				setCgstAmt(0) // 2 decimal format
				setSgstAmt(0)
			}
		} else {
			setCgstAmt(0)
			setSgstAmt(0)
			setIgstAmt(0)
		}


		var grand_total = (Formatting('two_decimal', sub_total) + Formatting('two_decimal', othersAmt) + Formatting('two_decimal', freightAmt) + Formatting('two_decimal', total_tax)) - Formatting('two_decimal', purchaseDisc)
		setGrandTotal(Formatting('round_off', grand_total))

		var rounded_off = Formatting('round_off', grand_total) - Formatting('two_decimal', grand_total)

		setRoundedOff(Formatting('two_decimal', rounded_off))

	}, [purchaseItemDetails.length, othersAmt, freightAmt, purchaseDisc, supplierName, taxType, supplierType]);  // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		var item_detail_json = itemNameOptions.find(e => e.im_item_code === currentItem?.value)
		setCurrentDecimal(item_detail_json?.um_no_of_decimals)
		if (currentQty > 0) {
			const formattedValue = parseFloat(currentQty).toFixed(item_detail_json?.um_no_of_decimals);
			setCurrentQty(formattedValue)
		}

		if (currentLabelDetails === "")
			udfnBindLabelDetails()  //  load the previous label details	
	}, [currentItem])  // eslint-disable-line react-hooks/exhaustive-deps

	async function udfnBindLabelDetails() {
		try {
			const user = getLoggedInUser()

			const labelParams = { item_code: currentItem?.value, user_id: user?.id };

			const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } };
			const [labelData] = await Promise.all([
				axios.post(BASE_URL + PURCHASE_API.GetPurchaseLabelDetails, labelParams, config),

			]);

			if (labelData?.data?.body?.status === COMMONVARIABLES.STATUS.UNAUTHORIZED) {


				const refresh = await getRefreshToken();

				if (refresh) {
					if (Number(refresh) === COMMONVARIABLES.STATUS.EXPIRED_TOKEN) {
						Logout();
					} else {
						await editLoadData();
					}
				}
			}

			else if (labelData?.data?.body?.LabelDetails) {
				setCurrentLabelDetails(labelData?.data?.body?.LabelDetails)
			}

		} catch (error) {
			console.error('Error fetching master data:', error);
		}

	}

	const udfnDelete = async (qrcode, sno) => {

		swal({
			title: "Are you sure?",
			text:
				"Once deleted, you will not be able to recover this details!",
			icon: "warning",
			buttons: true,
			dangerMode: true,
		}).then((willDelete) => {
			if (willDelete) {
				udfnDeleteItem(qrcode, sno);
			} else {
				swal("Your details are safe!");
			}
		})
	}

	// Function to delete item based on ID
	const udfnDeleteItem = (qrcode, sno) => {
		// Filter out the item with the matching sno
		const updatedData = purchaseItemDetails.filter(item => item.sno !== sno);

		var deletedqrcodes = deletedQRCodes
		if (qrcode != 0 && qrcode != "" && qrcode != undefined && qrcode != null) {
			var qrcode_json =
			{
				"qrcode": qrcode
			}
			deletedqrcodes.push(qrcode_json)
		}
		setDeletedQRCodes(deletedqrcodes)
		if (updatedData.length === 0) {
			setIsDisabled(false)
		}
		// Update the state with the filtered array
		setPurchaseItemDetails(updatedData);
	};

	Date.prototype.toDateString = function () {
		var month = this.getMonth() + 1;
		var day = this.getDate();
		var year = this.getFullYear();
		return year + "-" + (month < 10 ? '0' : '') + month + '-' + (day < 10 ? '0' : '') + day;
	};

	useEffect(() => {
		dispatch(navtoggle());
		fetchMasterDataWithParams()
	}, [])  // eslint-disable-line react-hooks/exhaustive-deps

	async function fetchMasterDataWithParams() {
		try {
			var localFy = localStorage.getItem("fy")
			const user = getLoggedInUser()

			const supplierParams = { status_code: 1, user_id: user?.id };
			const itemParams = { status_code: 1, user_id: user?.id };
			const branchParams = { status_code: 1, user_id: user?.id };
			const brandParams = { status_code: 1, user_id: user?.id };
			const invNoParams = { fy: localFy ? Number(localFy) : 0, user_id: user?.id };

			const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } };
			const [supplierData, itemData, branchData, brandData, invData] = await Promise.all([
				axios.post(BASE_URL + COMMON_API.SUPPLIER_MASTER_LIST, supplierParams, config),
				axios.post(BASE_URL + COMMON_API.ITEM_MASTER_LIST, itemParams, config),
				axios.post(BASE_URL + COMMON_API.BRANCH_MASTER_LIST, branchParams, config),
				axios.post(BASE_URL + COMMON_API.BRAND_MASTER_LIST, brandParams, config),
				axios.post(BASE_URL + PURCHASE_API.PURCHASE_INVNO, invNoParams, config),

			]);

			if (supplierData?.data?.body?.status === COMMONVARIABLES.STATUS.UNAUTHORIZED || itemData?.data?.body?.status === COMMONVARIABLES.STATUS.UNAUTHORIZED || branchData?.data?.body?.status === COMMONVARIABLES.STATUS.UNAUTHORIZED || brandData?.data?.body?.status === COMMONVARIABLES.STATUS.UNAUTHORIZED || invData?.data?.body?.status === COMMONVARIABLES.STATUS.UNAUTHORIZED) {


				const refresh = await getRefreshToken();

				if (refresh) {
					if (Number(refresh) === COMMONVARIABLES.STATUS.EXPIRED_TOKEN) {
						Logout();
					} else {
						await fetchMasterDataWithParams();
					}
				}
			}

			else if (supplierData?.data?.body?.supplierList || itemData?.data?.body?.itemList || branchData?.data?.body?.branchList || brandData?.data?.body?.brandList || invData?.data?.body?.voucher_no) {
				const supplier_array = supplierData?.data?.body?.supplierList.filter(e => e.supplier_type === supplierType?.value)
				setSupplierNameOptions(supplier_array || [])
				setSupplierList(supplierData?.data?.body?.supplierList)
				setItemNameOptions(itemData?.data?.body?.itemList)
				setBranchCodeOptions(branchData?.data?.body?.branchList)
				setBrandNameOptions(brandData?.data?.body?.brandList)
				setVoucherNumber(invData?.data?.body?.voucher_no)
				setCurrentBrand({
					value: 0,
					label: "Not Applicable"
				})
				setCurrentCodeType({
					value: 'Q',
					label: "QR Code"
				})
				var currentDate = new Date(invData?.data?.body?.vou_date);
				setVoucherDate(currentDate.toDateString())
				// setInvDate(currentDate.toDateString())
			}
		} catch (error) {
			console.error('Error fetching master data:', error);
		}
	}

	async function fetchItemMasterDataWithParams() {
		try {
			var localFy = localStorage.getItem("fy")
			const user = getLoggedInUser()


			const itemParams = { status_code: 1, user_id: user?.id };

			const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } };
			const [itemData] = await Promise.all([
				axios.post(BASE_URL + COMMON_API.ITEM_MASTER_LIST, itemParams, config),
			]);

			if (itemData?.data?.body?.status === COMMONVARIABLES.STATUS.UNAUTHORIZED) {
				const refresh = await getRefreshToken();

				if (refresh) {
					if (Number(refresh) === COMMONVARIABLES.STATUS.EXPIRED_TOKEN) {
						Logout();
					} else {
						await fetchItemMasterDataWithParams();
					}
				}
			}

			else if (itemData?.data?.body?.itemList) {
				setItemNameOptions(itemData?.data?.body?.itemList)
			}
		} catch (error) {
			console.error('Error fetching master data:', error);
		}
	}

	async function editLoadData() {
		try {
			const user = getLoggedInUser()

			const editloadParams = { transaction_no: paramData?.state?.transaction_no, user_id: user?.id };

			const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } };
			const [editloadValues] = await Promise.all([
				axios.post(BASE_URL + PURCHASE_API.PURCHASE_EDIT_LOAD, editloadParams, config),

			]);

			if (editloadValues?.data?.body?.status === COMMONVARIABLES.STATUS.UNAUTHORIZED) {
				const refresh = await getRefreshToken();

				if (refresh) {
					if (Number(refresh) === COMMONVARIABLES.STATUS.EXPIRED_TOKEN) {
						Logout();
					} else {
						await editLoadData();
					}
				}
			}

			else if (editloadValues?.data?.body?.purchaseList) {
				setBtnText("Update")
				setInvFile(editloadValues?.data?.body?.purchaseList[0]?.pr_file_name)
				const displayInvFile = editloadValues?.data?.body?.purchaseList[0]?.pr_file_name.split('_');
				setInvDisplayFile(displayInvFile[displayInvFile.length - 1])
				var purchase_item_list = editloadValues?.data?.body?.purchaseItemList
				var inc = 0
				purchase_item_list.forEach(element => {
					inc++
					return element['sno'] = inc
				});
				// qid = response.
				setPurchaseItemDetails(purchase_item_list)
				if (purchase_item_list.length > 0) {
					setIsDisabled(true)
				}
				var purchase = editloadValues?.data?.body?.purchaseList[0]
				bindEditLoadData(purchase)
			}
		} catch (error) {
			console.error('Error fetching master data:', error);
		}
	}

	function bindEditLoadData(purchase) {
		setVoucherNumber(purchase?.pr_voucher_number)
		setVoucherDate(new Date(purchase?.pr_voucher_date).toDateString())
		setInvNumber(purchase?.pr_inv_number)
		setInvDate(new Date(purchase?.pr_inv_date).toDateString())
		setSubTotal(purchase?.pr_sub_total)
		setFreightAmt(purchase?.pr_freight_amount)
		setOthersAmt(purchase?.pr_others)
		setPurchaseDisc(purchase?.pr_disc_amount)
		setGrandTotal(purchase?.pr_grand_total)
		setTransportDetails(purchase?.pr_transport_details)
		setNarration(purchase?.pr_narration)
		setRoundedOff(purchase?.pr_rounded_off)

		var supplierJson = supplierList.find(e => e?.sm_supplier_code === purchase?.pr_supplier_code)
		setSupplierName({ value: purchase?.pr_supplier_code, label: supplierJson?.sm_supplier_name, supplier_type: supplierJson?.supplier_type })
		var supplierTypeJson = SUPPLIERTYPE.find(e => e?.value === supplierJson?.supplier_type)
		setSupplierType({ value: supplierTypeJson?.value, label: supplierTypeJson?.label })
	}

	useEffect(() => {
		if (paramData?.state?.transaction_no > 0 && supplierList.length > 0) {
			editLoadData()
			setTransactionNo(paramData?.state?.transaction_no)
		}
	}, [supplierList])  // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {

		var supplierJson = supplierList.find(e => e?.sm_supplier_code === supplierName?.value)
		setSupplierAddress(supplierJson?.sm_address)
		setSupplierCity(supplierJson?.sm_city)
		setSupplierGstin(supplierJson?.sm_gstin)
		const gstin = supplierJson?.sm_gstin && supplierJson?.sm_gstin?.length >= 2 ? supplierJson?.sm_gstin?.substring(0, 2) : '';
		if (gstin === "33")
			setTaxType(1)
		else
			setTaxType(2)
		if (supplierJson)
			getPreviousPurchaseDetails()
	}, [supplierName])  // eslint-disable-line react-hooks/exhaustive-deps

	async function getPreviousPurchaseDetails() {
		const user = getLoggedInUser()
		const prevPurchaseParams = { supplier_code: supplierName?.value, user_id: user?.id, trans_no: transactionNo };
		const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } };
		const [prevPurchaseData] = await Promise.all([
			axios.post(BASE_URL + PURCHASE_API.PREVIOUS_PURCHASE, prevPurchaseParams, config)
		]);
		if (prevPurchaseData?.data?.body?.status === COMMONVARIABLES.STATUS.UNAUTHORIZED) {
			getPreviousPurchaseDetails()

			const refresh = await getRefreshToken();

			if (refresh) {
				if (Number(refresh) === COMMONVARIABLES.STATUS.EXPIRED_TOKEN) {
					Logout();
				} else {
					await getPreviousPurchaseDetails();
				}
			}
		}

		else if (prevPurchaseData?.data?.body?.prevPurchaseList) {
			setPrevPurchaseDetails(prevPurchaseData?.data?.body?.prevPurchaseList)
		}
	}

	useEffect(() => {
		if (currentDiscPer < 0 || currentDiscPer > 100) {
			toast.error("Discount Percentage is invalid", {
				position: "top-right",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
			});
		}

		var disc_amount = Number(currentRate) * (Number(currentDiscPer) / 100)
		setCurrentDiscAmt(disc_amount)

		var disc_rate = Number(currentRate) - disc_amount
		setCurrentDiscRate(disc_rate)

		var actual_amount = disc_rate * Number(currentQty)
		setCurrentAmount(actual_amount)


		// sellingCalculation(currentMarginPer)
		// sellDiscRateCalculation(currentSellingRate, disc_rate)


		// var actual_amount = currentQty * currentRate
		// var disc_value = currentDiscPer / 100;
		// var disc_amount = actual_amount * disc_value;
		// setCurrentAmount(actual_amount)
		// setCurrentDiscAmt(actual_amount * disc_value)
		// setCurrentDiscRate(actual_amount - disc_amount)

		// var selling_rate = (currentDiscRate * (currentMarginPer / 100)) + currentDiscRate
		// setCurrentSellingRate(selling_rate)

	}, [currentRate, currentQty, currentDiscPer, currentDiscRate])  // eslint-disable-line react-hooks/exhaustive-deps

	const sellDiscRateCalculation = (selleingrate, discRate) => {
		if (Number(selleingrate) > 0) {
			var margin_perc = ((Number(selleingrate) - Number(discRate)) / Number(discRate)) * 100

			// if (margin_perc >= 0)
			setCurrentMarginPer(margin_perc)
		}

	}

	// useEffect(() => {
	// 	var margin_perc = ((Number(currentSellingRate) - Number(currentDiscRate)) / Number(currentDiscRate)) * 100
	// 	setCurrentMarginPer(margin_perc)
	// }, [currentSellingRate,currentDiscRate])

	const sellingCalculation = (marginPer) => {
		var selling_rate = Number(currentDiscRate) + (currentDiscRate * (marginPer / 100))
		setCurrentSellingRate(selling_rate)

	}

	const udfnCategoryOptions = async () => {
		setIsLoading(true)
		var apiUrl = BASE_URL + COMMON_API.CATEGORY_LIST;
		const uploadData = {
			user_id: Number(user.id),
			status_code: Number(1),
		};

		try {
			const response = await axios.post(apiUrl, uploadData, config);
			setIsLoading(false)
			if (response && response?.data?.body?.status === COMMONVARIABLES.STATUS.INVALIDUSER) {
				toast.error(response.data.body.message, { position: toast.POSITION.TOP_RIGHT });
				Logout();
			}

			if (response?.data?.body?.status === COMMONVARIABLES.STATUS.UNAUTHORIZED) {
				const refresh = await getRefreshToken();

				if (refresh) {
					if (Number(refresh) === COMMONVARIABLES.STATUS.EXPIRED_TOKEN) {
						Logout();
					} else {
						await udfnCategoryOptions();
					}
				}
			}

			if (response?.data?.body?.status === COMMONVARIABLES.STATUS.SERVICE_CATCH_STATUS) {
				toast.error(ERROR_MESSAGE.API_ERROR.SERVICE_CATCH_ERROR, { position: toast.POSITION.TOP_RIGHT });
			}

			if (response?.data?.body?.categoryList) {
				setCategoryOptions(response.data.body.categoryList);
				setIsLoading(false);
			}
		} catch (error) {
			setIsLoading(false)
			// Handle error
			console.error("Category list API Error:", error);
			if (error.code === COMMONVARIABLES.STATUS.NETWORK_ERROR_STATUS) {
				toast.error(ERROR_MESSAGE.API_ERROR.NETWORK_ERROR, { position: toast.POSITION.TOP_RIGHT });
			}
			if (error.code === COMMONVARIABLES.STATUS.BAD_REQUEST_ERROR_STATUS) {
				toast.error(ERROR_MESSAGE.API_ERROR.BAD_REQUEST_ERROR, { position: toast.POSITION.TOP_RIGHT });
			}
		}

	};

	const udfnUnitOptions = async () => {
		setIsLoading(true)
		var apiUrl = BASE_URL + COMMON_API.UNIT_LIST;
		const uploadData = {
			user_id: Number(user.id),
			status_code: Number(1),
		};

		try {
			const response = await axios.post(apiUrl, uploadData, config);
			setIsLoading(false)
			if (response && response?.data?.body?.status === COMMONVARIABLES.STATUS.INVALIDUSER) {
				toast.error(response.data.body.message, { position: toast.POSITION.TOP_RIGHT });
				Logout();
			}

			if (response?.data?.body?.status === COMMONVARIABLES.STATUS.UNAUTHORIZED) {
				const refresh = await getRefreshToken();

				if (refresh) {
					if (Number(refresh) === COMMONVARIABLES.STATUS.EXPIRED_TOKEN) {
						Logout();
					} else {
						await udfnUnitOptions();
					}
				}
			}

			if (response?.data?.body?.status === COMMONVARIABLES.STATUS.SERVICE_CATCH_STATUS) {
				toast.error(ERROR_MESSAGE.API_ERROR.SERVICE_CATCH_ERROR, { position: toast.POSITION.TOP_RIGHT });
			}

			if (response?.data?.body?.unitList) {
				setUnitOptions(response.data.body.unitList);
				setIsLoading(false);
			}
		} catch (error) {
			setIsLoading(false)
			// Handle error
			console.error("Unit list API Error:", error);
			if (error.code === COMMONVARIABLES.STATUS.NETWORK_ERROR_STATUS) {
				toast.error(ERROR_MESSAGE.API_ERROR.NETWORK_ERROR, { position: toast.POSITION.TOP_RIGHT });
			}
			if (error.code === COMMONVARIABLES.STATUS.BAD_REQUEST_ERROR_STATUS) {
				toast.error(ERROR_MESSAGE.API_ERROR.BAD_REQUEST_ERROR, { position: toast.POSITION.TOP_RIGHT });
			}
		}

	};

	const udfnTaxOptions = async () => {
		setIsLoading(true)
		var apiUrl = BASE_URL + COMMON_API.TAX_LIST;
		const uploadData = {
			user_id: Number(user.id),
			status_code: Number(1),
		};

		try {
			const response = await axios.post(apiUrl, uploadData, config);
			setIsLoading(false)
			if (response && response?.data?.body?.status === COMMONVARIABLES.STATUS.INVALIDUSER) {
				toast.error(response.data.body.message, { position: toast.POSITION.TOP_RIGHT });
				Logout();
			}

			if (response?.data?.body?.status === COMMONVARIABLES.STATUS.UNAUTHORIZED) {
				const refresh = await getRefreshToken();

				if (refresh) {
					if (Number(refresh) === COMMONVARIABLES.STATUS.EXPIRED_TOKEN) {
						Logout();
					} else {
						await udfnTaxOptions();
					}
				}
			}

			if (response?.data?.body?.status === COMMONVARIABLES.STATUS.SERVICE_CATCH_STATUS) {
				toast.error(ERROR_MESSAGE.API_ERROR.SERVICE_CATCH_ERROR, { position: toast.POSITION.TOP_RIGHT });
			}

			if (response?.data?.body?.taxList) {
				setTaxOptions(response.data.body.taxList);
				setIsLoading(false);
			}
		} catch (error) {
			setIsLoading(false)
			// Handle error
			console.error("Unit list API Error:", error);
			if (error.code === COMMONVARIABLES.STATUS.NETWORK_ERROR_STATUS) {
				toast.error(ERROR_MESSAGE.API_ERROR.NETWORK_ERROR, { position: toast.POSITION.TOP_RIGHT });
			}
			if (error.code === COMMONVARIABLES.STATUS.BAD_REQUEST_ERROR_STATUS) {
				toast.error(ERROR_MESSAGE.API_ERROR.BAD_REQUEST_ERROR, { position: toast.POSITION.TOP_RIGHT });
			}
		}

	};

	const udfnCategoryGroup = (category) => {
		var group = categoryOptions.filter(item => item.cm_category_code === category.value);
		setCategoryCode(category)
		// setGroupCode(group[0].gm_group_code)
		setGroupName(group[0].gm_group_name)
	};

	const udfnSaveFormClear = () => {
		setItemCode(0)
		setItemName('')
		setCategoryCode('')
		setManualCode('')
		setDisplayName('')
		setUnitCode('')
		setHsnCode('')
		setTaxPer('')
		setDiscPer(0)
		setStatus(1)
		setAddCard(false);
		setEditFlag(false);
	};

	const udfnItemCreate = async () => {
		setIsLoading(true);
		setButtonFlag(true);

		var uploadData = {};
		var apiUrl = '';
		if (editFlag === true) {
			uploadData = {
				user_id: Number(user.id),
				company_code: Number(user.company_code),
				item_code: Number(itemCode),
				item_name: String(itemName.trim()),
				category_code: Number(categoryCode.value),
				item_manual_code: String(manualCode.trim()),
				display_name: String(displayName.trim()),
				unit_code: Number(unitCode.value),
				hsn_code: String(hsnCode.trim()),
				tax_per: Number(taxPer.value),
				disc_per: Number(Number(discPer)),
				status_code: Number(status),
			};
			apiUrl = BASE_URL + MASTER_API.ITEM_MASTER_UPDATE;
		} else {
			uploadData = {
				user_id: Number(user.id),
				company_code: Number(user.company_code),
				item_name: String(itemName.trim()),
				category_code: Number(categoryCode.value),
				item_manual_code: String(manualCode.trim()),
				display_name: String(displayName.trim()),
				unit_code: Number(unitCode.value),
				hsn_code: String(hsnCode.trim()),
				tax_per: Number(taxPer.value),
				disc_per: Number(Number(discPer)),
				status_code: Number(1),
			};
			apiUrl = BASE_URL + MASTER_API.ITEM_MASTER_CREATE;
		}

		try {
			const response = await axios.post(apiUrl, uploadData, config);
			setIsLoading(false);
			setButtonFlag(false);

			if (response && response?.data?.body?.status === COMMONVARIABLES.STATUS.INVALIDUSER) {
				toast.error(response.data.body.message, { position: toast.POSITION.TOP_RIGHT });
				Logout();
			}
			if (response?.data?.body?.status === COMMONVARIABLES.STATUS.UNAUTHORIZED) {
				const refresh = await getRefreshToken();
				if (refresh) {
					if (Number(refresh) === COMMONVARIABLES.STATUS.EXPIRED_TOKEN) {
						Logout();
					} else {
						await udfnItemCreate();
					}
				}
			}
			if (response && response?.data?.body?.status === COMMONVARIABLES.STATUS.EXISTS) {
				toast.error(response.data.body.message, { position: toast.POSITION.TOP_RIGHT });
			}
			if (response && response?.data?.body?.status === COMMONVARIABLES.STATUS.ALREADY_IN_USE) {
				toast.error(response.data.body.message, { position: toast.POSITION.TOP_RIGHT });
			}
			if (response?.data?.body?.status === COMMONVARIABLES.STATUS.SERVICE_CATCH_STATUS) {
				toast.error(ERROR_MESSAGE.API_ERROR.SERVICE_CATCH_ERROR, { position: toast.POSITION.TOP_RIGHT });
			}
			if (response?.data?.body?.status === COMMONVARIABLES.STATUS.SUCCESS) {
				toast.success(response.data.body.message, { position: toast.POSITION.TOP_RIGHT });
				editFlag === true ? udfnClearForm() : udfnSaveFormClear();
				await fetchItemMasterDataWithParams();
				var item_details = response.data.body.itemDetails;
				setCurrentItem({
					value: item_details.im_item_code,
					label: item_details.im_display_name,
				})
			} else {
				toast.error(response.data.body.message, { position: toast.POSITION.TOP_RIGHT });
			}
		} catch (error) {
			// Handle error
			setIsLoading(false);
			setButtonFlag(false);
			console.error("Item create API Error:", error);
			if (error.code === COMMONVARIABLES.STATUS.NETWORK_ERROR_STATUS) {
				toast.error(ERROR_MESSAGE.API_ERROR.NETWORK_ERROR, { position: toast.POSITION.TOP_RIGHT });
			}
			if (error.code === COMMONVARIABLES.STATUS.BAD_REQUEST_ERROR_STATUS) {
				toast.error(ERROR_MESSAGE.API_ERROR.BAD_REQUEST_ERROR, { position: toast.POSITION.TOP_RIGHT });
			}
		}
	};

	const udfnClearForm = () => {
		setItemCode(0)
		setItemName('')
		setCategoryCode('')
		setGroupName('')
		setManualCode('')
		setDisplayName('')
		setUnitCode('')
		setHsnCode('')
		setTaxPer('')
		setDiscPer(0)
		setStatus(1)
		setAddCard(false);
		setEditFlag(false);
	};

	const udfnNameValidation = (event) => {
		var val = event.target.value.charAt(0).toUpperCase() + event.target.value.slice(1);
		event.target.value = val;
	};

	const udfnDiscountValidation = (event) => {
		const regex = /^[0-9]*$/;
		const input = event.target.value + event.key;
		if (!regex.test(input) || input.length > 2) {
			event.preventDefault();
		}
	};

	const udfnFormValidate = (event) => {
		event.preventDefault();
		var error = "";
		if (!itemName.trim() || categoryCode === '' || groupName === '' || !displayName.trim() || unitCode === '' || taxPer === '') {
			error = ERROR_MESSAGE.COMMON_REQUIRED;
		}
		else if (!FormValidation('item_name', itemName)) {
			error = ERROR_MESSAGE.ITEM_MASTER.ITEM_VALID;
		} else if (manualCode !== '') {
			if (!FormValidation('manual_code', manualCode)) {
				error = ERROR_MESSAGE.ITEM_MASTER.MANUAL_CODE_VALID;
			}
		}
		else if (hsnCode !== '') {
			if (hsnCode.length < 4) {
				error = ERROR_MESSAGE.ITEM_MASTER.HSN_CODE_VALID;
			}
		}
		else {
			error = '';
		}
		if (error === '') {
			udfnItemCreate()
		} else {
			toast.error(error, { position: toast.POSITION.TOP_RIGHT });
		}
	};

	const openCard = () => {
		udfnClearForm()
		udfnCategoryOptions()
		udfnUnitOptions()
		udfnTaxOptions()
		setAddCard(true)
	}

	const udfnsetItemName = (value) => {
		setItemName(value)
		if (!editFlag) {
			setDisplayName(value)
		}
	};

	// useEffect(() => {
	// 	var selling_rate =  Number(currentRate) + (currentRate * (currentMarginPer / 100)) 
	// 	setCurrentSellingRate(selling_rate)
	// }, [currentMarginPer])
	// const handleMouseDown = (event) => {
	// 	event.preventDefault(); // Prevent default behavior of mouse click
	// 	event.stopPropagation(); // Stop event propagation
	// };

	// const handleKeyDown = (event) => {
	// 	event.preventDefault(); // Prevent default behavior of key press
	// 	event.stopPropagation(); // Stop event propagation
	// };
	return (
		<>
			<ToastContainer />
			<LoadingOverlay
				active={isLoading}
				spinner
				text='Loading ...'
			>
				{/* <div
					onClick={handleKeyDown}
					onClickCapture={handleKeyDown}
					onMouseDown={handleMouseDown}
					onKeyDown={handleKeyDown}
					contentEditable={false} // Disable editing of content inside the div
					tabIndex={-1} // Disable focusing via tab key
		  > */}

				{transactionNo != 0 ? <h4 className="card-title">Update Purchase</h4> : <h4 className="card-title">Add Purchase</h4>}

				<div className="basic-form overlay">
					<form>
						<div className="card">
							<div className="card-body">
								<Row>
									<Col lg={3}>
										<div className="row">
											<div className="form-group text-black font-w500 mb-2 mt-2 col-lg-4 align-middle text-end">
												<label className="col-form-label text-black font-w500">Vou No. <span className="text-danger">*</span></label>
											</div>
											<div className="form-group text-black font-w300 mb-2 ml-2 col-lg-8">
												<input
													type="text"
													className="form-control"
													placeholder="Vou No."
													readOnly={true}

													value={voucherNumber}
												/>
											</div>
											<div className="form-group text-black font-w500 mb-2 mt-2 col-lg-4 align-middle text-end">
												<label className="col-form-label text-black font-w500">Vou Date <span className="text-danger">*</span></label>
											</div>
											<div className="form-group text-black font-w500 mb-2 col-lg-8">
												<input value={voucherDate} onChange={e => setVoucherDate(e.target.value)} type="date" className="form-control" />
											</div>
										</div>
									</Col>
									<Col lg={6}>
										<div className="row">
											{/* <div className="form-group mb-3 col-md-12">
                                       
                                        <fieldset className="form-group">
                                            <div className="row">
                                                <div className="col-sm-4">
                                                    <div className="form-check checkbox-success">
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="gridRadios"
                                                            value={supplierType}
                                                            onChange={(e) => {
																setSupplierType(1)
																onChangeRadio(1)
															}}
                                                            defaultChecked
                                                        />
                                                        <label className="form-check-label">
                                                            Registered
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="col-sm-5 px-0">
                                                    <div className="form-check checkbox-danger px-0">
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="gridRadios"
                                                            value={supplierType}
                                                            onChange={(e) => {
																setSupplierType(2)
																onChangeRadio(2)
															}}
                                                        />
                                                        <label className="form-check-label">
                                                            Unregistered
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </fieldset>
                                    </div> */}
											<div className="form-group text-black font-w500 mb-2 col-lg-2 text-end">
												<label className="col-form-label text-black font-w500">Supplier <span className="text-danger">*</span></label>
											</div>
											<div className="form-group text-black font-w300 mb-2 col-lg-4">
												<Select
													defaultValue={supplierType}
													value={supplierType}
													isDisabled={isDisabled}
													onChange={(e) => {
														setSupplierName({})
														onChangeSupplierType(e)
													}}
													options={SUPPLIERTYPE}
													showContent="true"
													open="true"
													className="my-react-select-container"
													theme={theme => ({
														...theme,
														borderRadius: '6px',
														colors: {
															...theme.colors,
															primary25: '#b3b3b3',
															primary: 'black',
															neutral80: "data-theme-version" === "dark" ? 'white' : ''
														},
													})}
												/>
											</div>
											{/* <div className="form-group text-black font-w500 mb-2 col-lg-2 text-end">
												<label className="col-form-label text-black font-w500">Supplier Name <span className="text-danger">*</span></label>
											</div> */}
											<div className="form-group text-black font-w300 mb-2 col-lg-6">
												<Select
													defaultValue={supplierName}
													value={supplierName}

													onChange={setSupplierName}
													options={supplierNameOptions.map((option) => ({
														label: option.sm_supplier_name,
														value: option.sm_supplier_code,
														supplier_type: option.supplier_type
													}))}
													showContent="true"
													open="true"
													className="my-react-select-container"
													theme={theme => ({
														...theme,
														borderRadius: '6px',
														colors: {
															...theme.colors,
															primary25: '#b3b3b3',
															primary: 'black',
															neutral80: "data-theme-version" === "dark" ? 'white' : ''
														},
													})}
												/>



											</div>
											<div className="form-group text-black font-w500 mb-2 mt-2 col-lg-2 ml-4 pl-4 text-end">
												<label className="col-form-label text-black font-w500">Inv No. <span className="text-danger">*</span></label>
											</div>
											<div className="form-group text-black font-w300 mb-2 col-lg-4">
												<input
													type="text"
													className="form-control"
													placeholder="Inv No."
													value={invNumber} onChange={e => setInvNumber(e.target.value)}
												/>
											</div>
											<div className="form-group text-black font-w500 mb-2 mt-2 col-lg-2" align='right'>
												<label className="col-form-label text-black font-w500">Inv Date <span className="text-danger">*</span></label>
											</div>
											<div className="form-group text-black font-w500 mb-2 col-lg-4">
												<input type="date" className="form-control" value={invDate} onChange={e => setInvDate(e.target.value)} max={new Date().toISOString().slice(0, 10)} />
											</div>
										</div>
									</Col>
									{supplierName?.value ?
										<Col lg={3}>
											<div className="row">
												<div className="card-content border border-info pt-2">
													<address className='text-black'>
														<strong>Supplier Details</strong>
														<br></br>
														{supplierAddress}
														<br />

														{supplierCity}
														<br />
														<abbr title="Phone">GSTIN :{supplierGstin} </abbr>
													</address>

												</div>
											</div>
										</Col> : null}
								</Row>
							</div>
						</div>

						<div className="card">

							<div className="card-body px-4 pt-3 py-0">

								<Row>

									<Col lg={12}>

										<>
											<div className='row purchase-form'>
												<div className="col-lg-1 purchase-1">
													<label className="text-black font-w500">Code <span className="text-danger">*</span></label>
													<Select
														isDisabled={transactionNo !== 0 && isEdit == 1 ? true : false}

														defaultValue={currentCodeType}
														value={currentCodeType}
														onChange={setCurrentCodeType}
														options={QrManTypeOptions}
														name='qrmanType'
														showContent="true"
														open="true"
														className="my-react-select-container"
														theme={theme => ({
															...theme,
															borderRadius: '6px',
															colors: {
																...theme.colors,
																primary25: '#b3b3b3',
																primary: 'black',
																neutral80: "data-theme-version" === "dark" ? 'white' : ''
															},
														})}
													/>
												</div>
												<div className="col-lg-3 purchase-3">
													<div className='row'>
														<div className='col-lg-10'>
															<label className="text-black font-w500">Item Name <span className="text-danger">*</span>
															</label>
														</div>
														<div className='col-lg-1'>
															<Button onClick={(e) => openCard()} className="cash-total me-1 btn-icon" variant="whatsapp">
																<i className="fa fa-plus"></i>
															</Button>
														</div>
													</div>
													<Select
														defaultValue={currentItem}
														options={itemNameOptions.map((option) => ({
															label: option.im_display_name,
															value: option.im_item_code,
														}))}
														value={currentItem}
														onChange={setCurrentItem}
														name='itemName'
														showContent="true"
														open="true"
														className="my-react-select-container"

														theme={theme => ({
															...theme,
															borderRadius: '6px',
															colors: {
																...theme.colors,
																primary25: '#b3b3b3',
																primary: 'black',
																neutral80: "data-theme-version" === "dark" ? 'white' : ''
															},
														})}
													/>
												</div>

												<div className="col-lg-2 purchase-2">
													<label className="text-black font-w500">Branch <span className="text-danger">*</span></label>
													<Select
														defaultValue={currentBranch}
														onChange={setCurrentBranch}
														value={currentBranch}
														options={branchCodeOptions.map((option) => ({
															label: option.bm_branch_name,
															value: option.bm_branch_code,
														}))}
														showContent="true"
														open="true"
														className="my-react-select-container"
														theme={theme => ({
															...theme,
															borderRadius: '6px',
															colors: {
																...theme.colors,
																primary25: '#b3b3b3',
																primary: 'black',
																neutral80: "data-theme-version" === "dark" ? 'white' : ''
															},
														})}
													/>
												</div>
												<div className="col-lg-1 purchase-1">
													<label className="text-black font-w500">Rate <span className="text-danger">*</span></label>
													<input
														type="number"
														name='itemRate'
														className="form-control text-end"
														value={currentRate}
														placeholder="Rate"
														onKeyPress={(e) => {
															if (e.key === '-' || e.key === 'e') {
																e.preventDefault();
															}
														}}
														min="0"
														onPaste={(e) => {
															e.preventDefault();
															const pastedText = e.clipboardData.getData('text');
															let pastedValue = parseFloat(pastedText);
															if (isNaN(pastedValue) && pastedValue < 0) {
																pastedValue = "";
															}
															e.target.value = pastedValue.toString().slice(0, 3);

														}}
														onChange={(e) => {
															if (e.target.value <= 999999) {
																setCurrentRate(e.target.value)
															}
														}}

													/>
												</div>
												<div className="col-lg-1 purchase-1">
													<label className="text-black font-w500">Qty <span className="text-danger">*</span></label>
													<input
														type="number"
														name='itemQty'
														value={currentQty}
														onKeyPress={(e) => {
															if (e.key === '-' || e.key === 'e') {
																e.preventDefault();
															}
														}}
														min="0"
														onPaste={(e) => {
															e.preventDefault();
															const pastedText = e.clipboardData.getData('text');
															let pastedValue = parseFloat(pastedText);
															if (isNaN(pastedValue) && pastedValue < 0) {
																pastedValue = "";
															}
															e.target.value = pastedValue.toString().slice(0, 3);

														}}
														className="form-control text-end"
														placeholder="Qty"
														onChange={(e) => {
															if (e.target.value <= 9999) {
																setCurrentQty(e.target.value)
															}


															// if(e.target.value > 0)
															// {
															// 	const formattedValue = parseFloat(e.target.value).toFixed(currentDecimal);
															// 	setCurrentQty(formattedValue)
															// }


														}}
													/>
												</div>
												<div className="col-lg-1 purchase-1">
													<label className="text-black font-w500">Disc (%)</label>
													<input
														type="number"
														className="form-control text-end"
														placeholder="Disc %"
														value={currentDiscPer}
														onKeyPress={(e) => {
															if (e.key === '-' || e.key === 'e') {
																e.preventDefault();
															}
														}}
														min="0"
														onPaste={(e) => {
															e.preventDefault();
															const pastedText = e.clipboardData.getData('text');
															let pastedValue = parseFloat(pastedText);
															if (isNaN(pastedValue) && pastedValue < 0) {
																pastedValue = "";
															}
															e.target.value = pastedValue.toString().slice(0, 3);

														}}
														onChange={(e) => {
															if (e.target.value <= 100) {
																setCurrentDiscPer(e.target.value)
															}
														}}
													/>
												</div>
												<div className="col-lg-1 purchase-1">
													<label className="text-black font-w500">Disc Amt</label>
													<input
														type="number"
														className="form-control text-end"
														placeholder="Disc Amt"
														readOnly={true}
														value={currentDiscAmt}

													/>
												</div>
												<div className="col-lg-1 purchase-1">
													<label className="text-black font-w500">Disc Rate</label>
													<input
														type="number"
														className="form-control text-end"
														placeholder="Disc Rate"
														readOnly={true}
														value={currentDiscRate}
													/>
												</div>
												<div className="col-lg-1 purchase-1">
													<label className="text-black font-w500">Amount</label>
													<input
														type="number"
														className="form-control text-end"
														placeholder="Amount"
														readOnly={true}
														value={currentAmount}
													/>
												</div>
												<div className="col-lg-4 purchase-1"></div>
												<div className="col-lg-3 purchase-3">
													<label className="text-black font-w500">Brand</label>
													<Select
														defaultValue={currentBrand}
														value={currentBrand}
														onChange={setCurrentBrand}
														options={brandNameOptions.map((option) => ({
															label: option.bm_brand_name,
															value: option.bm_brand_code,
														}))}
														showContent="true"
														open="true"
														className="my-react-select-container"
														theme={theme => ({
															...theme,
															borderRadius: '6px',
															colors: {
																...theme.colors,
																primary25: '#b3b3b3',
																primary: 'black',
																neutral80: "data-theme-version" === "dark" ? 'white' : ''
															},
														})}
													/>
												</div>
												<div className="col-lg-1 purchase-1">
													<label className="text-black font-w500">Margin % <span className="text-danger">*</span></label>
													<input
														type="number"
														name='marginPerc'
														className="form-control text-end"
														placeholder="Margin %"
														value={currentMarginPer}
														onChange={(e) => {
															if (e.target.value >= 0 && e.target.value <= 100) {
																setCurrentMarginPer(e.target.value)
																sellingCalculation(e.target.value)
															}
														}}
														onKeyPress={(e) => {
															if (e.key === '-' || e.key === 'e') {
																e.preventDefault();
															}
														}}
														min="0"
														onPaste={(e) => {
															e.preventDefault();
															const pastedText = e.clipboardData.getData('text');
															let pastedValue = parseFloat(pastedText);
															if (isNaN(pastedValue) && pastedValue < 0) {
																pastedValue = "";
															}
															e.target.value = pastedValue.toString().slice(0, 3);

														}}
														maxLength={3}
													/>
												</div>
												<div className="col-lg-1 purchase-1">
													<label className="text-black font-w500 wd-100">Selling Rate</label>
													<input
														type="number"
														name='sellRate'
														className="form-control text-end"
														placeholder="Selling Rate"
														// readOnly={true}
														value={currentSellingRate}

														onChange={(e) => {
															if (e.target.value <= 999999) {
																setCurrentSellingRate(e.target.value)
																sellDiscRateCalculation(e.target.value, currentDiscRate)
															}

														}
														}
														onKeyPress={(e) => {
															if (e.key === '-' || e.key === 'e') {
																e.preventDefault();
															}
														}}
														min="0"
														onPaste={(e) => {
															e.preventDefault();
															const pastedText = e.clipboardData.getData('text');
															let pastedValue = parseFloat(pastedText);
															if (isNaN(pastedValue) && pastedValue < 0) {
																pastedValue = "";
															}
															e.target.value = pastedValue.toString().slice(0, 3);

														}}
													/>
													{/* <label className="text-black font-w500">{item.sellRate}</label> */}
												</div>
												<div className="col-lg-2 purchase-1">
													<label className="text-black font-w500">Label Details</label>
													<input
														type="text"
														name='labelDetails'
														className="form-control"
														placeholder="Label Details"
														value={currentLabelDetails}
														maxLength={12}
														onChange={(e) => setCurrentLabelDetails(e.target.value)}

													/>
												</div>

												<div className="col-lg-1 mt-4 purchase-3">
													{isEdit === 0 ?
														<Button onClick={(e) => udfnAddItem(e, 0)} className="me-1 btn-icon" variant="whatsapp">
															<i className="fa fa-plus"></i>
														</Button> :
														<Button onClick={(e) => udfnAddItem(e, 1)} className="me-1 btn-icon" variant="whatsapp">
															<i className="fa fa-check"></i>
														</Button>
													}

													<Button
														variant="danger"
														type="button"
														onClick={(e) => clearItemValues(e)}

														className="btn btn-success btn-icon"
													>
														<i className="fa fa-ban"></i>
													</Button>

													{/* <Link to={"#"} className="btn-link text-info" onClick={() => setAddCard(true)}>Add Extra details</Link> */}
													{/* <Link onClick={(e) => udfnAddRow(e)} className="btn btn-primary shadow btn-xs sharp mx-2">
															<i className="fa fa-plus"></i>
														</Link> */}
													{/* <Link className="btn btn-danger shadow btn-xs sharp mx-2">
															<i className="fa fa-trash"></i>
														</Link> */}
												</div>

											</div>
											{/* <hr className='mt-0 pt-0' /> */}
										</>

									</Col>

									<hr className='mt-0 pt-0' />
									<Col xl={12}>
										<div className="row">
											<div className="table-responsive">


												<table className="table table-striped">
													<thead>
														<tr className='table-purchase-tr '>
															<th width='2%'>#</th>

															<th width='8%'>Code</th>
															<th width='17%'>Item Name</th>
															<th className='text-end' width='5%'>Rate</th>
															<th className='text-end' width='5%'>Qty</th>
															<th className='text-end' width='5%'>Disc %</th>
															<th className='text-end' width='5%'>Disc Amt</th>
															<th className='text-end' width='5%'>Disc Rate</th>
															{supplierType?.value === 1 ? <th className='text-end' width='5%'>Tax %</th> : ''}
															{supplierType?.value === 1 ? <th className='text-end' width='5%'>Tax Amt</th> : ''}
															<th className='text-end' width='5%'>Amount</th>
															<th className='text-end' width='5%'>Margin %</th>
															<th className='text-end' width='5%'>Selling Rate</th>

															<th>Label Details</th>
															<th className='fixed-column' width='8%'>Actions</th>
														</tr>
													</thead>
													<tbody>

														{purchaseItemDetails.map((purchaseItem, index) => (

															<tr style={{ backgroundColor: currentSno === purchaseItem.sno ? '#2df1ff6e' : '' }}>
																<td> {purchaseItem?.sno}</td>

																<td>
																	<Badge bg="" className='badge-info light'>{purchaseItem?.qrmantype}</Badge>
																	{purchaseItem?.qrcode != 0 ? <span className='table-purchase-span' style={{ width: '150px' }}>{purchaseItem?.qrcode}</span> : null}
																</td>
																<td className='table-purchase-td'>
																	<label className="text-black font-w500 table-purchase-label" style={{ width: '200px' }}>{purchaseItem?.itemname}
																		{purchaseItem.brandcode !== 0 ? <span className='fst-italic table-purchase-span'>({purchaseItem?.brandname})</span> : null}
																	</label><br />
																	<span className='table-purchase-span'>({purchaseItem.branchname})</span>
																</td>
																<td align='right'>{purchaseItem?.itemrate.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
																<td align='right'>{purchaseItem?.itemqty}</td>
																<td align='right'>{purchaseItem?.discperc}</td>
																<td align='right'>{purchaseItem?.discamt.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
																<td align='right'>{purchaseItem?.discrate.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
																{supplierType?.value === 1 ? <td align='right'>{purchaseItem?.taxperc}</td> : ''}
																{supplierType?.value === 1 ? <td align='right'>{purchaseItem?.taxamt.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td> : ''}
																<td align='right'>{purchaseItem?.itemamt.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
																<td align='right'>{purchaseItem?.marginperc}</td>
																<td align='right'>{purchaseItem?.sellrate.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
																{/* <td align='right'>10</td> */}


																<td>{purchaseItem?.labeldetails}</td>
																<td className='fixed-column'>
																	{purchaseItem?.qrcode != 0 ? '' : <Link className="btn btn-secondary	 shadow btn-xs sharp mr-px2"
																		onClick={(e) => udfnLoadItem(e, purchaseItem, 1)}
																	>
																		<i className="fa fa-pencil"></i>
																	</Link>}
																	<Link onClick={(e) => udfnLoadItem(e, purchaseItem, 2)} className="btn btn-success shadow btn-xs sharp mr-px2">
																		<i className="fa fa-clone"></i>
																	</Link>
																	{purchaseItem?.qrcode != 0 ? '' : <Link onClick={(e) => udfnDelete(purchaseItem?.qrcode, purchaseItem?.sno)} className="btn btn-danger shadow btn-xs sharp">
																		<i className="fa fa-trash"></i>
																	</Link>}

																</td>
															</tr>
														))}

													</tbody>
												</table>
											</div>
										</div>
									</Col>

								</Row>
							</div>
						</div>

						<Row>
							<Col xl={4}>
								{prevPurchaseDetails.length > 0 ?

									<Accordion className="accordion accordion-rounded-stylish accordion-bordered accordion-primary mt-4" defaultActiveKey="0">
										<Accordion.Item>
											<Accordion.Header className="accordion-header accordion-header--primary">
												<span className="accordion-header-icon"></span>
												<span className="accordion-header-text">Previous Purchase Details</span>
												<span className="accordion-header-indicator">
												</span>
											</Accordion.Header>
											<Accordion.Collapse className="accordion__body">
												<PerfectScrollbar
													style={{ height: "370px" }}
													id="DZ_W_Todo4"
													className="widget-media dz-scroll height370 ps ps--active-y"
												>
													<ul className="timeline">

														{prevPurchaseDetails.map((prev, index) => (
															<li>
																<div className="timeline-panel">
																	<div className="media-body">
																		<h5 className="mb-0"><span>&#8377;</span>&nbsp;{prev?.pr_grand_total}</h5>
																		<small className="text-muted">
																			{prev?.inv_date} - {prev?.pr_inv_number}
																		</small>
																	</div>
																	<div onClick={() => {

																		// const url = "/purchase-view";
																		// const state = { transaction_no: transactionNo };
																		// const newTab = window.open(url, '_blank');
																		navigate("/purchase-view", { state: { transaction_no: prev?.pr_trans_no } });
																	}} className="btn btn-info btn-xs sharp">
																		<i className="fa fa-eye" />
																	</div>
																</div>
															</li>
														))}

													</ul>
												</PerfectScrollbar>

											</Accordion.Collapse>
										</Accordion.Item>
									</Accordion>


									// 								<div className="card border-0 pb-0">
									// 									<div className="card-header border-0 pb-0">
									// 										<h5 className="card-title">Previous Purchase Details</h5>
									// 									</div>

									// 									<div className="card-body">
									// 										<PerfectScrollbar
									// 											style={{ height: "370px" }}
									// 											id="DZ_W_Todo4"
									// 											className="widget-media dz-scroll height370 ps ps--active-y"
									// 										>
									// 											<ul className="timeline">

									// 												{prevPurchaseDetails.map((prev, index) => (
									// 													<li>
									// 														<div className="timeline-panel">
									// 															<div className="media-body">
									// 																<h5 className="mb-0"><span>&#8377;</span>&nbsp;{prev?.pr_grand_total}</h5>
									// 																<small className="text-muted">
									// 																	{prev?.inv_date} - {prev?.pr_inv_number}
									// 																</small>
									// 															</div>
									// 															<div   onClick={() => {

									// // const url = "/purchase-view";
									// // const state = { transaction_no: transactionNo };
									// // const newTab = window.open(url, '_blank');
									// 														navigate("/purchase-view", { state: { transaction_no: transactionNo } });
									// 													}} className="btn btn-info btn-xs sharp">
									// 																<i className="fa fa-eye" />
									// 															</div>
									// 														</div>
									// 													</li>
									// 												))}

									// 											</ul>
									// 										</PerfectScrollbar>
									// 									</div>
									// 								</div>:


									: null}
							</Col>
							<Col xl={4}>
								<div className="card">
									<div className="card-body">
										<div className="row">
											<div className="form-group mb-5 col-lg-12">
												<label className="text-black font-w300">Transport Details</label>
												<textarea value={transportDetails} onChange={(e) => setTransportDetails(e.target.value)} className="form-control text-area-udf"></textarea>
											</div>
											<div className="form-group mb-2 col-lg-12">
												<label className="text-black font-w300">Narration</label>
												<textarea value={narration} onChange={(e) => setNarration(e.target.value)} className="form-control text-area-udf"></textarea>
											</div>

											<div className="form-group mb-2 col-lg-12">
												<label className="text-black font-w300 mt-5">Upload</label>
												<input className="form-control" type="file" onChange={saveFile} id="formFile" />
											</div>

											{invFile !== "" ?
												<div className='row'>
													<div onClick={downloadFile} className="form-group mt-4 col-lg-12 cursor-pointer"><Badge bg="" className='badge-success light'>
														<span className="me-2">
															<i className="ms-1 fa fa-download" />
														</span>
														{invDisplayFile}
													</Badge></div>


												</div> : null}
										</div>
									</div>
								</div>
							</Col>
							<Col xl={4}>
								<div className="card">
									<div className="card-body">
										<div className="row">
											<ul className="list-group p-0">
												<li className="list-group-item d-flex justify-content-between lh-condensed p-2">
													<div>
														<span className="my-0 text-black font-w500">Sub Total</span>
													</div>
													<span className="text-muted">{'₹'}{subTotal.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
												</li>
												{cgstAmt ? <li className="list-group-item d-flex justify-content-between lh-condensed p-2">
													<div>
														<span className="my-0 text-black font-w500">CGST</span>
													</div>
													<span className="text-muted">{'₹'}{cgstAmt.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
												</li> : ''}
												{sgstAmt ? <li className="list-group-item d-flex justify-content-between lh-condensed p-2">
													<div>
														<span className="my-0 text-black font-w500">SGST</span>
													</div>
													<span className="text-muted">{'₹'}{sgstAmt.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
												</li> : ''}
												{igstAmt ? <li className="list-group-item d-flex justify-content-between lh-condensed p-2">
													<div>
														<span className="my-0 text-black font-w500">IGST</span>
													</div>
													<span className="text-muted">{'₹'}{igstAmt.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
												</li> : ''}

												<li className="list-group-item d-flex justify-content-between lh-condensed p-2">
													<div>
														<span className="my-0 text-black font-w500">Frieght Amt</span>
													</div>
													<div className="col-5">
														<span className="text-muted" align='right'>
															<input
																type="text"
																className="form-control text-end"
																placeholder="Frieght Amt"
																value={freightAmt}
																onChange={(e) => setFreightAmt(e.target.value)}
																onKeyPress={(e) => {
																	if (e.key === '-' || e.key === 'e') {
																		e.preventDefault();
																	}
																}}
																min="0"
																onPaste={(e) => {
																	e.preventDefault();
																	const pastedText = e.clipboardData.getData('text');
																	let pastedValue = parseFloat(pastedText);
																	if (isNaN(pastedValue) && pastedValue < 0) {
																		pastedValue = "";
																	}
																	e.target.value = pastedValue.toString().slice(0, 3);

																}}
															/>
														</span>
													</div>
												</li>
												<li className="list-group-item d-flex justify-content-between lh-condensed p-2">
													<div>
														<span className="my-0 text-black font-w500">Disc Amt(-)</span>
													</div>
													<div className="col-5">
														<span className="text-muted" align='right'>
															<input
																type="text"
																className="form-control text-end"
																placeholder="Disc Amt"
																value={purchaseDisc}
																onChange={(e) => setPurchaseDisc(e.target.value)}
																onKeyPress={(e) => {
																	if (e.key === '-' || e.key === 'e') {
																		e.preventDefault();
																	}
																}}
																min="0"
																onPaste={(e) => {
																	e.preventDefault();
																	const pastedText = e.clipboardData.getData('text');
																	let pastedValue = parseFloat(pastedText);
																	if (isNaN(pastedValue) && pastedValue < 0) {
																		pastedValue = "";
																	}
																	e.target.value = pastedValue.toString().slice(0, 3);

																}}
															/>
														</span>
													</div>
												</li>
												<li className="list-group-item d-flex justify-content-between lh-condensed p-2">
													<div>
														<span className="my-0 text-black font-w500">Others</span>
													</div>
													<div className="col-5">
														<span className="text-muted" align='right'>
															<input
																type="text"
																className="form-control form-control-sm text-end"
																placeholder="Others"
																value={othersAmt}
																onChange={(e) => setOthersAmt(e.target.value)}
																onKeyPress={(e) => {
																	if (e.key === 'e') {
																		e.preventDefault();
																	}
																}}
																min="0"
																onPaste={(e) => {
																	e.preventDefault();
																	const pastedText = e.clipboardData.getData('text');
																	let pastedValue = parseFloat(pastedText);
																	if (isNaN(pastedValue) && pastedValue < 0) {
																		pastedValue = "";
																	}
																	e.target.value = pastedValue.toString().slice(0, 3);

																}}
															/>
														</span>
													</div>
												</li>
												<li className="list-group-item d-flex justify-content-between lh-condensed p-2">
													<div>
														<span className="my-0 text-black font-w500">Rounded Off</span>
													</div>
													<span className="text-muted">{'₹'}{roundedOff.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
												</li>
												<li className="list-group-item d-flex justify-content-between active p-2">
													<div className="text-white">
														<h6 className="my-0 text-white">Grand Total</h6>
													</div>
													<span className="text-white">
														<h6 className="text-white">{'₹'}{grandTotal.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</h6>
													</span>
												</li>
											</ul>
										</div>
									</div>
								</div>
							</Col>
						</Row>
						<Row>
							{btnText === 'Update' && <><Col xl={6}>
								<div className="row">
									<div className="form-group text-black font-w500 mt-2 col-lg-4">
										<label className="col-form-label text-black font-w500">Label Size</label>
									</div>
									<div className="form-group text-black font-w300 mt-2 col-lg-4">
										<Select
											defaultValue={labelTypeName}
											value={labelTypeName}
											onChange={setLabelTypeName}
											options={labelTypeNameOptions}
											showContent="true"
											open="true"
											className="my-react-select-container"
											menuPlacement="top"
											theme={theme => ({
												...theme,
												borderRadius: '6px',
												colors: {
													...theme.colors,
													primary25: '#b3b3b3',
													primary: 'black',
													neutral80: "data-theme-version" === "dark" ? 'white' : ''
												},
											})}
										/>
									</div>
									<div className="form-group text-black font-w300 col-lg-4 mb-6">
										<Button className="m-2" variant="info" onClick={udfnReprintPurchase}>
											Reprint
										</Button>
									</div>
								</div>

							</Col><Col xl={1}>



								</Col><Col xl={2}></Col></>}
							{btnText === 'Save' && <><Col xl={5}></Col><Col xl={4} className="mb-3">
								<div className="row">
									<div className="form-group text-black font-w500 mb-2 mt-2 col-lg-6 text-end">
										<label className="col-form-label text-black font-w500">Label Size</label>
									</div>
									<div className="form-group text-black font-w300 mt-2 mb-2 col-lg-6">
										<Select
											defaultValue={labelTypeName}
											value={labelTypeName}
											onChange={setLabelTypeName}
											options={labelTypeNameOptions}
											onKeyDown={handleKeyDown}
											showContent="true"
											open="true"
											className="my-react-select-container"
											menuPlacement="top"
											theme={theme => ({
												...theme,
												borderRadius: '6px',
												colors: {
													...theme.colors,
													primary25: '#b3b3b3',
													primary: 'black',
													neutral80: "data-theme-version" === "dark" ? 'white' : ''
												},
											})}
										/>

									</div>
								</div>
								{/* </div> */}

							</Col></>}
							<Col xl={3}>
								<div class="mb-6">


									<Link to='/purchase-list'>
										<Button
											variant="danger"
											type="button"
											className="btn btn-success me-2"
										>
											Discard
										</Button>
									</Link>
									<Button className="me-2" variant="whatsapp" ref={buttonRef} onClick={udfnSavePurchase}>
										{transactionNo != 0 ? <span>{"Update"}</span> : <span>{"Save"}</span>}
									</Button>
								</div>


							</Col>
						</Row>




					</form >
				</div >
				{qrCodeItemDetails.map((row) => (
					<div id={myid + row.pi_qr_code} className='color-picker' style={{ display: 'none' }}>
						<QRCode
							size={45}
							value={row.pi_qr_code.toString()}
						/>
					</div>
				))}

				{qrCodeItemDetails.map((row) => (
					<div id={myqrid + row.pi_qr_code} className='color-picker' style={{ display: 'none' }}>
						<QRCode
							size={35}
							value={row.pi_qr_code.toString()}
						/>
					</div>
				))}


			</LoadingOverlay>

			<Modal className="fade bd-example-modal-lg" show={addCard} onHide={setAddCard} size="lg" backdrop="static">
				<Modal.Header>
					<h4 className="text-black">{editFlag === true ? 'Update Item' : 'Add Item'}</h4>
					<Button
						variant=""
						className="close"
						onClick={() => udfnClearForm()}
					>
						<span>&times;</span>
					</Button>
				</Modal.Header>
				<Modal.Body>
					<div className="basic-form">
						<form onSubmit={(e) => e.preventDefault()}>
							<div className="row">
								<div className="form-group mb-3 col-md-6">
									<label className="text-black font-w500">Item Name <span className="text-danger">*</span></label>
									<input
										type="text"
										className="form-control"
										value={itemName}
										autoFocus
										onChange={e => udfnsetItemName(e.target.value)}
										onKeyPress={(e) => udfnNameValidation(e)}
										placeholder="Item Name"
										maxLength="100"
									/>
								</div>
								<div className="form-group mb-3 col-md-6">
									<label className="text-black font-w500">Category Name <span className="text-danger">*</span></label>
									<Select
										defaultValue={categoryCode}
										value={categoryCode}
										onChange={e => udfnCategoryGroup(e)}
										options={categoryOptions.map((option) => ({
											label: option.cm_category_name,
											value: option.cm_category_code,
										}))}
										showContent="true"
										open="true"
										className="my-react-select-container"
										theme={theme => ({
											...theme,
											borderRadius: '6px',
											colors: {
												...theme.colors,
												primary25: '#b3b3b3',
												primary: 'black',
												neutral80: "data-theme-version" === "dark" ? 'white' : ''
											},
										})}
									/>
								</div>
								<div className="form-group mb-3 col-md-6">
									<label className="text-black font-w500">Group Name <span className="text-danger">*</span></label>
									<input
										type="text"
										className="form-control"
										placeholder="Group Name"
										value={groupName}
										readOnly
									/>
								</div>
								<div className="form-group mb-3 col-md-6">
									<label className="text-black font-w500">Item Manual Code</label>
									<input
										type="text"
										className="form-control"
										value={manualCode}
										onChange={e => setManualCode(e.target.value)}
										placeholder="Item Manual Code"
										maxLength="6"
									/>
								</div>
								<div className="form-group mb-3 col-md-6">
									<label className="text-black font-w500">Display Name <span className="text-danger">*</span></label>
									<input
										type="text"
										className="form-control"
										value={displayName}
										onChange={e => setDisplayName(e.target.value)}
										placeholder="Display Name"
										maxLength="100"
									/>
								</div>
								<div className="form-group mb-3 col-md-6">
									<label className="text-black font-w500">Unit Name <span className="text-danger">*</span></label>
									<Select
										defaultValue={unitCode}
										onChange={setUnitCode}
										value={unitCode}
										options={unitOptions.map((option) => ({
											label: option.um_display_name,
											value: option.um_unit_code,
										}))}
										showContent="true"
										open="true"
										className="my-react-select-container"
										theme={theme => ({
											...theme,
											borderRadius: '6px',
											colors: {
												...theme.colors,
												primary25: '#b3b3b3',
												primary: 'black',
												neutral80: "data-theme-version" === "dark" ? 'white' : ''
											},
										})}
									/>
								</div>
								<div className="form-group mb-3 col-md-6">
									<label className="text-black font-w500">HSN Code</label>
									<input
										type="text"
										className="form-control"
										value={hsnCode}
										onChange={e => setHsnCode(e.target.value)}
										placeholder="HSN Code"
										minLength="6"
										maxLength="16"
									/>
								</div>
								<div className="form-group mb-3 col-md-6">
									<label className="text-black font-w500">Tax (%) <span className="text-danger">*</span></label>
									<Select
										defaultValue={taxPer}
										onChange={setTaxPer}
										value={taxPer}
										options={taxOptions.map((option) => ({
											label: option.tm_tax_per,
											value: option.tm_tax_per,
										}))}
										showContent="true"
										open="true"
										className="my-react-select-container"
										theme={theme => ({
											...theme,
											borderRadius: '6px',
											colors: {
												...theme.colors,
												primary25: '#b3b3b3',
												primary: 'black',
												neutral80: "data-theme-version" === "dark" ? 'white' : ''
											},
										})}
									/>
								</div>
								<div className="form-group mb-3 col-md-6">
									<label className="text-black font-w500">Discount (%)</label>
									<input
										type="number"
										className="form-control"
										value={discPer}
										onChange={e => setDiscPer(e.target.value)}
										onKeyPress={(e) => udfnDiscountValidation(e)}
										onKeyDown={handleKeyDown}
										placeholder="Discount %"
										maxLength=""

									/>
								</div>
								{editFlag === true ?
									<div className="form-group col-md-6">
										<fieldset className="form-group mt-5">
											<div className="row">
												<label className="col-form-label col-sm-3 pt-1 text-black font-w300">
													Status
												</label>
												<div className="col-sm-3">
													<div className="form-check checkbox-success">
														{status === 1 ? <input
															className="form-check-input success"
															type="radio"
															name="gridRadios"
															onClick={() => setStatus(1)}
															onKeyDown={handleKeyDown}
															defaultChecked
														/> :
															<input
																className="form-check-input success"
																type="radio"
																name="gridRadios"
																onClick={() => setStatus(1)}
																onKeyDown={handleKeyDown}
															/>}
														<label className="form-check-label">
															Active
														</label>
													</div>
												</div>
												<div className="col-sm-5">
													<div className="form-check checkbox-danger">
														{status === 2 ?
															<input
																className="form-check-input"
																type="radio"
																name="gridRadios"
																onClick={() => setStatus(2)}
																onKeyDown={handleKeyDown}
																defaultChecked
															/> :
															<input
																className="form-check-input"
																type="radio"
																name="gridRadios"
																onClick={() => setStatus(2)}
																onKeyDown={handleKeyDown}
															/>}
														<label className="form-check-label">
															Inactive
														</label>
													</div>
												</div>
											</div>
										</fieldset>
									</div>
									: ''}
							</div>
						</form>
					</div>
				</Modal.Body>
				<Modal.Footer>
					<Button
						variant="danger"
						className="me-2"
						onClick={() => udfnClearForm()}
					>
						Discard
					</Button>
					<Button variant="whatsapp" ref={buttonRef} onClick={udfnFormValidate} disabled={buttonFlag ? true : false}>
						{editFlag === true ? 'Update' : 'Save'}
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	)

}
export default PurchaseAdd;