import React, { useEffect, useMemo, useState, useRef } from 'react';
import { useTable, useGlobalFilter, useFilters, useSortBy, usePagination } from 'react-table';
import { COLUMNS } from '../../Filters/StockTransfer/StockTransferColumns';
// import { GlobalFilter } from '../Filters/Common/GlobalFilter';
import { Link } from "react-router-dom";
import '../../Filters/Common/filtering.css';
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import Select from "react-select";
import { Button, Accordion } from "react-bootstrap";
import ERROR from "../../../components/Validation/ErrorMessage"
import axios from "axios";
import { getLoggedInUser, getRefreshToken } from '../../../../services/AuthService';
import { Logout } from '../../../../store/actions/AuthActions';
import COMMONVARIABLES from '../../Utilities/constants';
import STOCK_TRANSFER_API from '../../../Api/StockTransferApi';
import COMMON_API from '../../../Api/CommonApi';
import LoadingOverlay from 'react-loading-overlay-ts';

import swal from "sweetalert";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from 'react-router-dom';
import ERROR_MESSAGE from '../../Validation/ErrorMessage';
import { useReactToPrint } from 'react-to-print';
import Logo from '../../../../pictures/bw1.png';
export const StockTransferList = () => {
    let navigate = useNavigate();
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    const columns = useMemo(() => COLUMNS, [])
    const [data, setData] = useState([])
    const tableInstance = useTable({
        columns,
        data,
        initialState: { pageIndex: 0 }
    }, useFilters, useGlobalFilter, useSortBy, usePagination)

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        // prepareRow,
        state,
        page,
        gotoPage,
        pageCount,
        pageOptions,
        nextPage,
        previousPage,
        canNextPage,
        canPreviousPage,
        // setGlobalFilter,
    } = tableInstance

    const { pageIndex } = state
    const [itemNameOptions, setItemNameOptions] = useState([])
    const [itemName, setItemName] = useState(JSON.parse(localStorage.getItem('stockTransferItemName')) || { label: "All", value: 0 })
    const [range, setRange] = useState(localStorage.getItem('stockTransferRange') || null)
    var start_date = localStorage.getItem('stockTransferRange') && new Date(localStorage.getItem('stockTransferRange').split('-')[0]).toDateString();
    var end_date = localStorage.getItem('stockTransferRange') && new Date(localStorage.getItem('stockTransferRange').split('-')[1]).toDateString();

    const [rangeDate, setRangeDate] = useState(localStorage.getItem('stockTransferRange') && localStorage.getItem('stockTransferRange') !== "null" ? { startDate: new Date(start_date), endDate: new Date(end_date) } : { startDate: new Date(), endDate: new Date() })

    const [qrCode, setQrCode] = useState(localStorage.getItem('stockTransferQrcode') || '')
    const [isLoading, setIsLoading] = useState(false);
    ;

    // For Print data
    const [transferNoPrint, setTransferNoPrint] = useState('');
    const [transferDatePrint, setTransferDatePrint] = useState("");
    const [transferFromPrint, setTransferFromPrint] = useState('');
    const [transferToPrint, setTransferToPrint] = useState('');
    const [itemDetailsPrint, setItemDetailsPrint] = useState([]);
    const [narrationPrint, setNarrationPrint] = useState('');
    const [localUserDetails, setLocalUserDetails] = useState(null)

    const [transferBranchOptions, setTransferBranchOptions] = useState([])
    const contentToPrint = useRef(null);
    const [isPrint, setIsPrint] = useState(0)
    useEffect(() => {
        var lcluserDetails = localStorage.getItem("userDetails")
        setLocalUserDetails(JSON.parse(lcluserDetails))

    }, [])  // eslint-disable-line react-hooks/exhaustive-deps



    const handlePrint = useReactToPrint({
        documentTitle: "Print This Document",

        onBeforePrint: () => {
            //     let temp = document.querySelectorAll(".printfooter");
            //     // alert(temp.length)
            //     for (let i = 0; i < temp.length; i++) {
            //       if((i+1) == temp.length )
            //            temp[i].style.display = "block";

            //   }
        },
        onAfterPrint: () => {
            // if(transactionNo != 0)
            //      navigate("/billing-list")
        },
        removeAfterPrint: true,
    });
    useEffect(() => {
        fetchMasterDataWithParams()
        bindStockTransferData()

    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    async function fetchMasterDataWithParams() {
        try {

            const user = getLoggedInUser()

            const itemParams = { status_code: 0, user_id: user?.id };
            const branchParams = { status_code: 1, user_id: user?.id };

            const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } };
            const [itemData, branchData] = await Promise.all([
                axios.post(BASE_URL + COMMON_API.ITEM_MASTER_LIST, itemParams, config),
                axios.post(BASE_URL + COMMON_API.BRANCH_MASTER_LIST, branchParams, config),

            ]);

            if (itemData?.data?.body?.status === COMMONVARIABLES.STATUS.UNAUTHORIZED || branchData?.data?.body?.status === COMMONVARIABLES.STATUS.UNAUTHORIZED) {


                const refresh = await getRefreshToken();

                if (refresh) {
                    if (Number(refresh) === COMMONVARIABLES.STATUS.EXPIRED_TOKEN) {
                        Logout();
                    } else {
                        await fetchMasterDataWithParams();
                    }
                }
            }

            if (itemData?.data?.body?.status === COMMONVARIABLES.STATUS.SERVICE_CATCH_STATUS) {
                toast.error(ERROR_MESSAGE.API_ERROR.SERVICE_CATCH_ERROR, { position: toast.POSITION.TOP_RIGHT });
            }
            if (branchData?.data?.body?.status === COMMONVARIABLES.STATUS.SERVICE_CATCH_STATUS) {
                toast.error(ERROR.API_ERROR.SERVICE_CATCH_ERROR, { position: toast.POSITION.TOP_RIGHT });
            }

            if (itemData?.data?.body?.itemList) {
                setItemNameOptions([{ im_display_name: "All", im_item_code: 0 }, ...itemData?.data?.body?.itemList])

            }
            console.log(branchData?.data?.body?.branchList,'branchData?.data?.body?.branchList')
            if (branchData?.data?.body?.branchList) {
                setTransferBranchOptions(branchData?.data?.body?.branchList)
            }


        } catch (error) {
            console.error('Error fetching master data:', error);
            if (error.code === COMMONVARIABLES.STATUS.NETWORK_ERROR_STATUS) {
                toast.error(ERROR_MESSAGE.API_ERROR.NETWORK_ERROR, { position: toast.POSITION.TOP_RIGHT });
            }
            if (error.code === COMMONVARIABLES.STATUS.BAD_REQUEST_ERROR_STATUS) {
                toast.error(ERROR_MESSAGE.API_ERROR.BAD_REQUEST_ERROR, { position: toast.POSITION.TOP_RIGHT });
            }
        }
    }

    async function bindStockTransferData() {
        try {
            setIsLoading(true)
            const user = getLoggedInUser()
            var varFromDate = new Date().toDateString()
            var varToDate = new Date().toDateString()
            if (range != "null" && range !== null) {
                varFromDate = new Date(range.split('-')[0]).toDateString()
                varToDate = new Date(range.split('-')[1]).toDateString()
            }

            const params = { item_code: itemName?.value, user_id: user?.id, qr_code: Number(qrCode), from_date: varFromDate, to_date: varToDate };

            const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } };
            const [stockTransferList] = await Promise.all([
                axios.post(BASE_URL + STOCK_TRANSFER_API.STOCK_TRANSFER_LIST, params, config),

            ]);
            setIsLoading(false)

            if (stockTransferList?.data?.body?.status === COMMONVARIABLES.STATUS.UNAUTHORIZED) {

                const refresh = await getRefreshToken();

                if (refresh) {
                    if (Number(refresh) === COMMONVARIABLES.STATUS.EXPIRED_TOKEN) {
                        Logout();
                    } else {
                        await bindStockTransferData();
                    }
                }
            }

            if (stockTransferList?.data?.body?.status === COMMONVARIABLES.STATUS.SERVICE_CATCH_STATUS) {
                toast.error(ERROR_MESSAGE.API_ERROR.SERVICE_CATCH_ERROR, { position: toast.POSITION.TOP_RIGHT });
            }

            if (stockTransferList?.data?.body?.stockTransferList) {
                setData(stockTransferList?.data?.body?.stockTransferList)
            }


        } catch (error) {
            setIsLoading(false)
            console.error('Error fetching master data:', error);
            if (error.code === COMMONVARIABLES.STATUS.NETWORK_ERROR_STATUS) {
                toast.error(ERROR_MESSAGE.API_ERROR.NETWORK_ERROR, { position: toast.POSITION.TOP_RIGHT });
            }
            if (error.code === COMMONVARIABLES.STATUS.BAD_REQUEST_ERROR_STATUS) {
                toast.error(ERROR_MESSAGE.API_ERROR.BAD_REQUEST_ERROR, { position: toast.POSITION.TOP_RIGHT });
            }
        }
    }

    const udfnDelete = async (transfer_no, from_branch_code, to_branch_code) => {
        swal({
            title: "Are you sure?",
            text:
                "Once deleted, you will not be able to recover this Stocktransfer!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                udfnStockTransferDelete(transfer_no, from_branch_code, to_branch_code);
            } else {
                swal("Your Stock is safe!");
            }
        })
    }
    const udfnPrint = async (transfer_no) => {
        swal({
            // title: "Are you sure?",
            text:
                "Are you sure you want to print?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                callEditLoadAPI(transfer_no)
            } else {
                // swal("Your Stock is safe!");
            }
        })
    }

    async function callEditLoadAPI(transNo) {
        try {

            const user = getLoggedInUser()

            const editLoadParams = { transaction_no: Number(transNo), user_id: user?.id };


            const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } };
            const [editLoadData] = await Promise.all([
                axios.post(BASE_URL + STOCK_TRANSFER_API.EDIT_STOCK_TRASFER, editLoadParams, config),

            ]);



            if (editLoadData?.data?.body?.status === COMMONVARIABLES.STATUS.UNAUTHORIZED) {


                const refresh = await getRefreshToken();

                if (refresh) {
                    if (Number(refresh) === COMMONVARIABLES.STATUS.EXPIRED_TOKEN) {
                        Logout();
                    } else {
                        await callEditLoadAPI(transNo)
                    }
                }
            }

            if (editLoadData?.data?.body?.status === COMMONVARIABLES.STATUS.SERVICE_CATCH_STATUS) {
                toast.error(ERROR.API_ERROR.SERVICE_CATCH_ERROR, { position: toast.POSITION.TOP_RIGHT });
            }

            if (editLoadData?.data?.body?.stockTransferList) {

                setTransferNoPrint(editLoadData?.data?.body?.stockTransferList[0].st_transfer_no)
                // setTransferDate(new Date(editLoadData?.data?.body?.stockTransferList[0].st_date).toDateString())
                setTransferDatePrint(editLoadData?.data?.body?.stockTransferList[0].st_date)
                const transferFromBranchArr = Object.assign([], transferBranchOptions)
             //   console.log(transferBranchOptions,'transferFromBranchArr')
                const branchFrom = transferFromBranchArr.filter((e) => Number(e.bm_branch_code) === Number(editLoadData.data.body.stockTransferList[0].st_from_branch_code));
                const branchFromObj = {
                    label: branchFrom[0].bm_branch_name,
                    value: branchFrom[0].bm_branch_code,
                }
                setTransferFromPrint({
                    branch_name:editLoadData?.data?.body?.stockTransferList[0]?.from_branch_name,
                    branch_address:editLoadData?.data?.body?.stockTransferList[0]?.from_branch_address,
                    branch_city:editLoadData?.data?.body?.stockTransferList[0]?.from_branch_city,
                })
                const transferToBranchArr = Object.assign([], transferBranchOptions)
                const branchTo = transferToBranchArr.filter((e) => Number(e.bm_branch_code) === Number(editLoadData.data.body.stockTransferList[0].st_to_branch_code));
                const branchToObj = {
                    label: branchTo[0].bm_branch_name,
                    value: branchTo[0].bm_branch_code,
                }
               

                setTransferToPrint({
                    branch_name:editLoadData?.data?.body?.stockTransferList[0]?.to_branch_name,
                    branch_address:editLoadData?.data?.body?.stockTransferList[0]?.to_branch_address,
                    branch_city:editLoadData?.data?.body?.stockTransferList[0]?.to_branch_city,
                })
                var stockTransferItemArray = []
                for (let i = 0; i < editLoadData.data.body.stockTransferItemList.length; i++) {

                    var itemObj = {
                        sno: i + 1,
                        itemcode: editLoadData.data.body.stockTransferItemList[i].si_item_code,
                        qrcode: editLoadData.data.body.stockTransferItemList[i].si_qr_code,
                        itemUnit: editLoadData.data.body.stockTransferItemList[i].unit_name,
                        itemName: editLoadData.data.body.stockTransferItemList[i].item_name,
                        qty: parseFloat(editLoadData.data.body.stockTransferItemList[i].si_qty).toFixed(editLoadData.data.body.stockTransferItemList[i].um_no_of_decimals),
                        um_no_of_decimals: editLoadData.data.body.stockTransferItemList[i].um_no_of_decimals,
                        im_item_name: editLoadData.data.body.stockTransferItemList[i].im_item_name,
                        selling_rate: editLoadData.data.body.stockTransferItemList[i].selling_rate
                    }
                    stockTransferItemArray.push(itemObj)
                    setItemDetailsPrint(stockTransferItemArray)
                }


                setNarrationPrint(editLoadData?.data?.body?.stockTransferList[0].st_narration)
                setIsPrint(1)


            } else {

            }


        } catch (error) {

            console.error('Error fetching master data:', error);
            if (error.code === COMMONVARIABLES.STATUS.NETWORK_ERROR_STATUS) {
                toast.error(ERROR.API_ERROR.NETWORK_ERROR, { position: toast.POSITION.TOP_RIGHT });
            }
            if (error.code === COMMONVARIABLES.STATUS.BAD_REQUEST_ERROR_STATUS) {
                toast.error(ERROR.API_ERROR.BAD_REQUEST_ERROR, { position: toast.POSITION.TOP_RIGHT });
            }
        }
    }

    useEffect(() => {
        if (isPrint == 1) {

            handlePrint(null, () => contentToPrint.current);
            setIsPrint(0)
        }



    }, [itemDetailsPrint])

    const udfnStockTransferDelete = async (transfer_no, from_branch_code, to_branch_code) => {
        try {
            setIsLoading(true);
            var apiUrl = BASE_URL + STOCK_TRANSFER_API.DELETE_STOCK_TRASFER;
            const user = getLoggedInUser()
            const data = {
                user_id: user.id,
                company_code: user.company_code,
                transaction_no: transfer_no,
                from_branch_code: from_branch_code,
                to_branch_code: to_branch_code
            };

            const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } };
            const response = await axios.post(apiUrl, data, config);
            setIsLoading(false);

            if (response && response.status === COMMONVARIABLES.STATUS.INVALIDUSER) {
                toast.error(response.data.body.message, { position: toast.POSITION.TOP_RIGHT });
                localStorage.removeItem("userDetails");
                localStorage.removeItem("stateList");
                localStorage.removeItem("fy");
                window.location.reload();
                return;
            }

            if (response?.data?.body?.status === COMMONVARIABLES.STATUS.UNAUTHORIZED) {
                const refresh = await getRefreshToken();

                if (refresh) {
                    if (Number(refresh) === COMMONVARIABLES.STATUS.EXPIRED_TOKEN) {
                        Logout();
                    } else {
                        await udfnStockTransferDelete(transfer_no, from_branch_code, to_branch_code);
                    }
                }
            }

            if (response && response?.data?.body?.status === COMMONVARIABLES.STATUS.EXISTS) {
                toast.error(response.data.body.message, { position: toast.POSITION.TOP_RIGHT });
            }

            if (response?.data?.body?.status === COMMONVARIABLES.STATUS.SERVICE_CATCH_STATUS) {
                toast.error(ERROR_MESSAGE.API_ERROR.SERVICE_CATCH_ERROR, { position: toast.POSITION.TOP_RIGHT });
            }

            if (response?.data?.body?.status === COMMONVARIABLES.STATUS.SUCCESS) {
                swal(response.data.body.message, {
                    icon: "success",
                });
                await bindStockTransferData();
            } else {
                toast.error(response.data.body.message, { position: toast.POSITION.TOP_RIGHT });
            }
        } catch (error) {
            // Handle error
            setIsLoading(false);
            console.error("Stocktransfer list API Error:", error);
            if (error.code === COMMONVARIABLES.STATUS.NETWORK_ERROR_STATUS) {
                toast.error(ERROR_MESSAGE.API_ERROR.NETWORK_ERROR, { position: toast.POSITION.TOP_RIGHT });
            }
            if (error.code === COMMONVARIABLES.STATUS.BAD_REQUEST_ERROR_STATUS) {
                toast.error(ERROR_MESSAGE.API_ERROR.BAD_REQUEST_ERROR, { position: toast.POSITION.TOP_RIGHT });
            }
        }
    };

    useEffect(() => {
        localStorage.setItem('stockTransferItemName', JSON.stringify(itemName))
        localStorage.setItem('stockTransferRange', range)
        localStorage.setItem('stockTransferQrcode', qrCode)
    }, [range, itemName, qrCode])  // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <LoadingOverlay
                active={isLoading}
                spinner
                text='Loading ...'
            >
                <div className="card">
                    <div className="card-header">
                        <div className='col-sm-5'>
                            <h4 className="card-title">Stock Transfer List</h4>
                        </div>
                        <div className='col-sm-3'>
                            <Link to='/stock-transfer-add' className="btn btn-primary">
                                <i className="fa fa-plus"></i> &nbsp;Add Stock Transfer
                            </Link>
                        </div>
                    </div>
                    <div className="card-body">
                        <Accordion className="accordion accordion-rounded-stylish accordion-bordered  accordion-active-header" defaultActiveKey="0">

                            <Accordion.Item>
                                <Accordion.Header className="accordion-header accordion-header-primary">
                                    <h5>Filters</h5>
                                    <span className="accordion-header-indicator "></span>
                                </Accordion.Header>
                                <Accordion.Collapse className="accordion__body">
                                    <div className="accordion-body">
                                        <div className="row">
                                            <div className="col-md-4 mb-3">
                                                <div className="example rangeDatePicker">
                                                    <p className="mb-1">Select From Date And To Date</p>

                                                    <DateRangePicker
                                                        onApply={(e) => {
                                                            setRange(e.target.value)
                                                        }}
                                                        initialSettings={rangeDate}
                                                    >
                                                        <input type="text" className="form-control input-daterange-timepicker" styles={{ height: "90%" }} />
                                                    </DateRangePicker>
                                                </div>
                                            </div>
                                            <div className="col-md-3 mb-3">
                                                <p className="mb-1">Item Name</p>
                                                {/* <Select
                                                defaultValue={itemName}
                                                onChange={setSelectedOption}
                                                onClick={() => handleItemGroup}
                                                options={item_options}
                                                showContent="true"
                                                open="true"
                                                className="my-react-select-container"
                                                theme={theme => ({
                                                    ...theme,
                                                    borderRadius: 0,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary25: '#b3b3b3',
                                                        primary: 'black',
                                                        neutral80: "data-theme-version" === "dark" ? 'white' : ''
                                                    },
                                                })}
                                            /> */}

                                                <Select
                                                    defaultValue={itemName}
                                                    onChange={setItemName}
                                                    options={itemNameOptions.map((option) => ({
                                                        label: option?.im_display_name,
                                                        value: option?.im_item_code,
                                                    }))}
                                                    showContent="true"
                                                    open="true"
                                                    className="my-react-select-container"
                                                    theme={theme => ({
                                                        ...theme,
                                                        borderRadius: 0,
                                                        colors: {
                                                            ...theme.colors,
                                                            primary25: '#b3b3b3',
                                                            primary: 'black',
                                                            neutral80: "data-theme-version" === "dark" ? 'white' : ''
                                                        },
                                                    })}
                                                />
                                            </div>
                                            {/* </div>
                                    <div className="row"> */}
                                            <div className="col-md-3 mb-3">
                                                <p className="mb-1">QR Code</p>
                                                <input
                                                    value={qrCode}
                                                    onChange={(e) => {
                                                        setQrCode(e.target.value)
                                                    }}
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="QR Code"
                                                />
                                            </div>
                                            <div className="col-md-2 mt-3">
                                                <Button className="me-2" variant="whatsapp" style={{ marginTop: "6%" }} onClick={() => bindStockTransferData()}>
                                                    List{" "}
                                                    <span className="btn-icon-right">
                                                        <i className="fa fa-filter" />
                                                    </span>
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </Accordion.Collapse>
                            </Accordion.Item>
                        </Accordion>
                        <div className="table-responsive">
                            <div className='row'>
                                <div className='col-sm-9'>
                                    {/* <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} /> */}
                                </div>
                            </div>
                            <table {...getTableProps()} className="table filtering-table table-responsive-lg">
                                <thead>
                                    {headerGroups.map((headerGroup, index) => (
                                        <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                                            <th>
                                                <p className='tbl-heading-txt'>S.NO.</p>
                                            </th>
                                            {headerGroup.headers.map((column, index) => (
                                                <>
                                                    {column.Header === 'Total Items' ?
                                                        <th className='text-end' key={index}>
                                                            {column.render('Header')}
                                                            {column.canFilter ? column.render('Filter') : null}
                                                        </th>
                                                        :
                                                        <th key={index}>
                                                            {column.render('Header')}
                                                            {column.canFilter ? column.render('Filter') : null}
                                                        </th>
                                                    }
                                                </>
                                            ))}
                                            <th>
                                                <p className='tbl-heading-txt'>Actions</p>
                                            </th>
                                        </tr>
                                    ))}
                                </thead>
                                <tbody {...getTableBodyProps()} className="" >
                                    {page.map((row, index) => {
                                        return (
                                            <tr key={index}>
                                                <td>{index + 1}</td>
                                                <td>{row.original.transfer_date}</td>
                                                <td>{row.original.transfer_no}</td>
                                                <td>{row.original.from_branch_name}</td>
                                                <td>{row.original.to_branch_name}</td>
                                                <td className='text-end pr-6'>{row.original.no_of_items}</td>
                                                <td>{row.original.st_narration}</td>
                                                <td>
                                                    <div className="btn btn-secondary	 shadow btn-xs sharp me-1"
                                                        onClick={() => navigate("/stock-transfer-add", { state: { transNo: row.original.transfer_no } })}
                                                    >
                                                        <i className="fa fa-pencil"></i>
                                                    </div>
                                                    <Link className="btn btn-danger shadow btn-xs sharp me-1" onClick={() => udfnDelete(row.original.transfer_no, row.original.st_from_branch_code, row.original.st_to_branch_code)}>
                                                        <i className="fa fa-trash"></i>
                                                    </Link>
                                                    <div className="btn btn-warning	 shadow btn-xs sharp"
                                                        onClick={() => udfnPrint(row.original.transfer_no)}
                                                    >
                                                        <i className="fa fa-print"></i>
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                            <div className="d-flex justify-content-between">
                                <span>
                                    Page{' '}
                                    <strong>
                                        {pageIndex + 1} of {pageOptions.length}
                                    </strong>{''}
                                </span>
                                <span className="table-index">
                                    Go to page : {' '}
                                    <input type="number"
                                        className="ms-2"
                                        defaultValue={pageIndex + 1}
                                        onChange={e => {
                                            const pageNumber = e.target.value ? Number(e.target.value) - 1 : 0
                                            gotoPage(pageNumber)
                                        }}
                                    />
                                </span>
                            </div>
                            <div className="text-center">
                                <div className="filter-pagination  mt-3">
                                    <button className=" previous-button" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>{'<<'}</button>

                                    <button className="previous-button" onClick={() => previousPage()} disabled={!canPreviousPage}>
                                        Previous
                                    </button>
                                    <button className="next-button" onClick={() => nextPage()} disabled={!canNextPage}>
                                        Next
                                    </button>
                                    <button className=" next-button" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>{'>>'}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div style={{ visibility: 'hidden', height: 0 }}>
				<div ref={contentToPrint} className="row purchsecontentToPrint" style={{ padding: '16px' }} >
					<table>


						<style>
							{`
       @page {    
        size: A5 portrait;    
        padding-left: 8px;    
        padding-right: 0px;
        // margin-top: 50px;
        //  margin-bottom: 150px;
    
   
    }  
    
        
    
        `}
						</style>

						<thead ><tr><td>


							<div class="printheader" >
								<div className="col-12">
                                <div className="row text-center">
										
									
                                        <div className="brand-logo p-16 col-4">
                                                   <img className="logo-abbr me-3" src={Logo} alt="" style={{ width: '170px', height: '100px' }} />
       
                                               </div> 
       
       
                                               <div className='col-8 text-end'>
                                               <span>
                                                   <strong style={{ fontSize: 20, fontWeight: 700 }} className="d-block text-black"> {localUserDetails?.company_name}</strong>
       
       
                                               </span>
       
                                               <small className="text-black" style={{ fontSize: 16, lineHeight: 1.2 }}>
       
                                                   {localUserDetails?.address}{', '}{localUserDetails?.city}
                                                   <br></br>
                                                   {localUserDetails?.state_name}
                                                   <br></br>
                                                   {'Email'}{' : '}{localUserDetails?.email_id}
                                                   <br></br>
                                                   {'Contact No'}{' : '}{localUserDetails?.mobile_no}
       
                                                   <strong className="d-block">GSTIN : {localUserDetails?.gstin}</strong>
       
                                               </small>
                                           </div> 
                                           <div class="h_line"></div>
                                           <strong style={{ fontSize: 16, fontWeight: 700 }} className="d-block text-center text-black"> {'DELIVERY NOTE'}</strong>
       
       
                                           </div>
									<div class="h_line"></div>
									<div className='row'>
										<div className='col-6'>
											<span>
												<strong style={{ fontSize: 16, fontWeight: 700 }} className="d-block text-black"> {'From:'}</strong>


											</span>
									
											<small className="text-black" style={{ fontSize: 16, lineHeight: 1.2 }}>

												
												{transferFromPrint?.branch_name}
												<br></br>
												{transferFromPrint?.branch_address}
												<br></br>
												{transferFromPrint?.branch_city}
											

											</small>
										

										</div>
										<div className='col-6 text-end'>
											<span>
												<strong style={{ fontSize: 16, fontWeight: 700 }} className="d-block text-black"> {'To:'}</strong>


											</span>

											<small className="text-black" style={{ fontSize: 16, lineHeight: 1.2 }}>

											{transferToPrint?.branch_name}
												<br></br>
												{transferToPrint?.branch_address}
												<br></br>
												{transferToPrint?.branch_city}
											
											

											</small>

										</div>
									</div>
								</div>
								<div class="h_line"></div>



								<div className='row'>


									<div className="col-6">
										<span className="d-block text-black"> Delivery Note No. : <strong>{transferNoPrint}</strong></span>
									</div>

									<div className="text-end col-6">


										<span className="d-block text-black"> Dated : <strong>{transferDatePrint}</strong></span>

									</div>
								</div>
								<div class="h_line"></div>

							</div>
						</td></tr></thead>
						<tbody ><tr><td>

							<div className="col-lg-12 ">

								<div className="table-responsive">

									<table className="table table-striped">
										<thead >
											<tr>
												<th className="center">#</th>

												<th style={{ textAlign: 'center !important' }} className="center text-black">Item</th>
												<th className="center text-black">Qty</th>
												<th className="text-end text-black" >Rate</th>
												<th className="text-end text-black">Amount</th>
											</tr>
										</thead>
										<tbody>


											{
												itemDetailsPrint?.length > 0 ?
                                                itemDetailsPrint?.map((row, index) => {
														return (
															<tr key={index}>
																<td className='text-black'>{index + 1}</td>
																<td className='text-black'>{row.itemName}<br></br>
																{row.qrcode != '0' ?<span>{'('}{row.qrcode}{')'}</span>:null}
																</td>
                                                                <td className='center text-black'>{row.qty} {row.itemUnit}</td>
																<td className='text-end text-black'>{COMMONVARIABLES.India_Rupee_Formatter_Digits(row.selling_rate)}</td>
																<td className='text-end text-black'>{COMMONVARIABLES.India_Rupee_Formatter_Digits(row.selling_rate * row.qty)}</td>

															</tr>
														)
													})
													: ''
											}


										</tbody>
									</table>




								</div>

							</div>






						</td></tr></tbody>

						<tfoot>
							<tr><td className='text-black' style={{ paddingRight: 16, paddingLeft: 16 }}>Remarks : {narrationPrint}</td></tr>
							<tr>
								<td> <strong style={{ fontSize: 16, fontWeight: 700, paddingRight: 16, paddingLeft: 16  }} className="d-block text-black"> {'NOT FOR SALE'}</strong></td>
							</tr>
							<tr><td>
								<div class="footer-space" style={{ padding: 16 }}>



									<div className="printfooter" >
										<div class="h_line"></div>
										<div className='' style={{ marginTop: '35px' }}>
											<div className='row'>
												<div className='col-4'>

													<strong>Checked by</strong>
												</div>
												<div className='col-4'>

													<strong>Verified by</strong>
												</div>
												<div className='col-4 text-end'>

													<strong>Authorised Signatory</strong>
												</div>
											</div>
										</div>


									</div> </div>
							</td></tr></tfoot>
					</table>


				</div>
			</div>


            </LoadingOverlay>
        </>
    )

}
export default StockTransferList;