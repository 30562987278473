import React, { useMemo, useState, useEffect, useRef } from 'react';
import { Link } from "react-router-dom";
import { useTable, useGlobalFilter, useFilters, useSortBy, usePagination } from 'react-table';
import { COLUMNS } from '../Filters/ItemGroup/ItemGroupColumns';
import { GlobalFilter } from '../Filters/Common/GlobalFilter';
import { Modal, Button } from 'react-bootstrap';
import '../Filters/Common/filtering.css';
import { Badge } from "react-bootstrap";
import ERROR_MESSAGE from '../Validation/ErrorMessage';
import swal from "sweetalert";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from 'axios';
import {
    getLoggedInUser, getRefreshToken
} from '../../../services/AuthService';
import MASTER_API from '../../Api/MasterApi';
import { UserLogout } from '../../layouts/nav/Logout';
import COMMONVARIABLES from '../Utilities/constants';
import LoadingOverlay from 'react-loading-overlay-ts';
import { UncontrolledTooltip } from "reactstrap"

const ItemGroupMaster = () => {

    //Modal box
    const [addCard, setAddCard] = useState(false);
    const [groupName, setGroupName] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [editFlag, setEditFlag] = useState(false);
    const [statusCode, setStatusCode] = useState(1);
    const [groupList, setGroupList] = useState([]);
    const [group_code, setGroupCode] = useState(1);
    const [buttonFlag, setButtonFlag] = useState(true);
    const [status, setStatus] = useState(false);
    const [groupTotalCount, setGroupTotalCount] = useState('');


    const columns = useMemo(() => COLUMNS, [])
    const data = groupList
    const tableInstance = useTable({
        columns,
        data,
        initialState: { pageIndex: 0 }
    }, useFilters, useGlobalFilter, useSortBy, usePagination)

    const {
        getTableProps,
        getTableBodyProps,
        prepareRow,
        state,
        page,
        gotoPage,
        pageCount,
        pageOptions,
        nextPage,
        previousPage,
        canNextPage,
        canPreviousPage,
        setGlobalFilter,
    } = tableInstance

    const { globalFilter, pageIndex } = state

    // udfnFromValidate
    const udfnFromValidate = (event) => {
        event.preventDefault();
        var error = false;
        var errorMsg = '';
        if (!groupName.trim()) {
            error = true;
            errorMsg = ERROR_MESSAGE.COMMON_REQUIRED;
        }
        if (!error) {
            setButtonFlag(false);
            udfnGroupCreate();
        } else {
            toast.error(errorMsg, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    }

    //group list
    const udfnGroupList = async () => {
        const user = getLoggedInUser();
        setIsLoading(true);
        const data = {
            user_id: user.id
        };
        const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } };

        try {
            const response = await axios.post(process.env.REACT_APP_BASE_URL + MASTER_API.GROUP_MASTER_LIST, data, config);
            setIsLoading(false);
            if (response && response?.data?.body?.status === COMMONVARIABLES.STATUS.INVALIDUSER) {
                toast.error(response.data.body.message, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
                UserLogout();
            }

            if (response?.data?.body?.status === COMMONVARIABLES.STATUS.UNAUTHORIZED) {
                const refresh = await getRefreshToken();

                if (refresh) {
                    if (Number(refresh) === COMMONVARIABLES.STATUS.EXPIRED_TOKEN) {
                        UserLogout();
                    } else {
                        await udfnGroupList();
                    }
                }
            }

            if (response?.data?.body?.status === COMMONVARIABLES.STATUS.SERVICE_CATCH_STATUS) {
                toast.error(ERROR_MESSAGE.API_ERROR.SERVICE_CATCH_ERROR, { position: toast.POSITION.TOP_RIGHT });
            }

            if (response?.data?.body?.GroupList) {
                setGroupList(response.data.body.GroupList);
                var groupListCount = response.data.body.GroupList.filter(item => item.gm_status_code === 1).length;
                setGroupTotalCount(groupListCount);
                toast.success(response.data.body.message, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
                setIsLoading(false);
            } else {
                toast.error(response.data.body.message, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
            }
        } catch (error) {
            // Handle error
            setIsLoading(false);
            console.error("group list API Error:", error);
            if (error.code === COMMONVARIABLES.STATUS.NETWORK_ERROR_STATUS) {
                toast.error(ERROR_MESSAGE.API_ERROR.NETWORK_ERROR, { position: toast.POSITION.TOP_RIGHT });
            }
            if (error.code === COMMONVARIABLES.STATUS.BAD_REQUEST_ERROR_STATUS) {
                toast.error(ERROR_MESSAGE.API_ERROR.BAD_REQUEST_ERROR, { position: toast.POSITION.TOP_RIGHT });
            }
        }

    };

    const udfnNameValidation = (event) => {
        var val = event.target.value.charAt(0).toUpperCase() + event.target.value.slice(1);
        event.target.value = val;
    };

    //group create
    const udfnGroupCreate = async () => {
        setIsLoading(true);
        const user = getLoggedInUser();
        var uploadData = {};
        var apiUrl = '';
        if (editFlag === true) {
            uploadData = {
                user_id: user.id,
                company_code: user.company_code,
                group_code: Number(group_code),
                group_name: String(groupName.trim()),
                status_code: Number(statusCode)
            };
            apiUrl = MASTER_API.GROUP_MASTER_UPDATE;
        } else {
            uploadData = {
                user_id: user.id,
                company_code: user.company_code,
                group_name: String(groupName.trim()),
                status_code: Number(statusCode)
            };
            apiUrl = MASTER_API.GROUP_MASTER_CREATE;
        }

        const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } };

        try {
            const response = await axios.post(process.env.REACT_APP_BASE_URL + apiUrl, uploadData, config);
            setIsLoading(false);
            if (response && response?.data?.body?.status === COMMONVARIABLES.STATUS.INVALIDUSER) {
                toast.error(response.data.body.message, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
                UserLogout();
            }

            if (response?.data?.body?.status === COMMONVARIABLES.STATUS.UNAUTHORIZED) {
                const refresh = await getRefreshToken();
                if (refresh) {
                    if (Number(refresh) === COMMONVARIABLES.STATUS.EXPIRED_TOKEN) {
                        UserLogout();
                    } else {
                        await udfnGroupCreate();
                    }
                }
            }

            if (response && response?.data?.body?.status === COMMONVARIABLES.STATUS.EXISTS) {
                setButtonFlag(true);
                toast.error(response.data.body.message, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
            }

            if (response && response?.data?.body?.status === COMMONVARIABLES.STATUS.ALREADY_IN_USE) {
                toast.error(response.data.body.message, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
            }

            if (response?.data?.body?.status === COMMONVARIABLES.STATUS.SERVICE_CATCH_STATUS) {
                toast.error(ERROR_MESSAGE.API_ERROR.SERVICE_CATCH_ERROR, { position: toast.POSITION.TOP_RIGHT });
            }

            if (response?.data?.body?.status === COMMONVARIABLES.STATUS.SUCCESS) {
                toast.success(response.data.body.message, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
                setButtonFlag(true);
                udfnClearForm();
                if (editFlag === true) {
                    setAddCard(false);
                }
                else {
                    setAddCard(true);
                }
                await udfnGroupList();
            } else {
                toast.error(response.data.body.message, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
                setAddCard(true);
                setButtonFlag(true);
            }
        } catch (error) {
            // Handle error
            setIsLoading(false);
            console.error("group create API Error:", error);
            if (error.code === COMMONVARIABLES.STATUS.NETWORK_ERROR_STATUS) {
                toast.error(ERROR_MESSAGE.API_ERROR.NETWORK_ERROR, { position: toast.POSITION.TOP_RIGHT });
            }
            if (error.code === COMMONVARIABLES.STATUS.BAD_REQUEST_ERROR_STATUS) {
                toast.error(ERROR_MESSAGE.API_ERROR.BAD_REQUEST_ERROR, { position: toast.POSITION.TOP_RIGHT });
            }
        }
    };

    const udfnClearForm = () => {
        setGroupCode('')
        setGroupName('')
        setStatusCode(1);
        setAddCard(false);
        setEditFlag(false);
    };

    const udfnEditLoad = async (row) => {
        setGroupName(row.gm_group_name)
        setGroupCode(row.gm_group_code)
        setStatus(row.gm_status_code)
        setStatusCode(row.gm_status_code)
        setAddCard(true);
        setEditFlag(true);
    };

    const udfnDelete = async (group_code) => {
        swal({
            title: "Are you sure?",
            text:
                "Once deleted, you will not be able to recover this group!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                udfnGroupDelete(group_code);
            } else {
                swal("Your group is safe!");
            }
        })
    }

    //group delete
    const udfnGroupDelete = async (group_code) => {
        setIsLoading(true);
        const user = getLoggedInUser();
        const data = {
            user_id: user.id,
            company_code: user.company_code,
            group_code: Number(group_code)

        };
        const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } };

        try {
            const response = await axios.post(process.env.REACT_APP_BASE_URL + MASTER_API.GROUP_MASTER_DELETE, data, config);
            setIsLoading(false);
            if (response && response?.data?.body?.status === COMMONVARIABLES.STATUS.INVALIDUSER) {
                toast.error(response.data.body.message, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
                UserLogout();
            }

            if (response?.data?.body?.status === COMMONVARIABLES.STATUS.UNAUTHORIZED) {
                const refresh = await getRefreshToken();

                if (refresh) {
                    if (Number(refresh) === COMMONVARIABLES.STATUS.EXPIRED_TOKEN) {
                        UserLogout();
                    } else {
                        await udfnGroupDelete();
                    }
                }
            }

            if (response && response?.data?.body?.status === COMMONVARIABLES.STATUS.EXISTS) {
                toast.error(response.data.body.message, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
                setAddCard(true);
            }

            if (response?.data?.body?.status === COMMONVARIABLES.STATUS.SERVICE_CATCH_STATUS) {
                toast.error(ERROR_MESSAGE.API_ERROR.SERVICE_CATCH_ERROR, { position: toast.POSITION.TOP_RIGHT });
            }

            if (response?.data?.body?.status === COMMONVARIABLES.STATUS.SUCCESS) {
                swal(response.data.body.message, {
                    icon: "success",
                });
                await udfnGroupList();
            } else {
                toast.error(response.data.body.message, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
            }
        } catch (error) {
            // Handle error
            setIsLoading(false);
            console.error("group delete API Error:", error);
            if (error.code === COMMONVARIABLES.STATUS.NETWORK_ERROR_STATUS) {
                toast.error(ERROR_MESSAGE.API_ERROR.NETWORK_ERROR, { position: toast.POSITION.TOP_RIGHT });
            }
            if (error.code === COMMONVARIABLES.STATUS.BAD_REQUEST_ERROR_STATUS) {
                toast.error(ERROR_MESSAGE.API_ERROR.BAD_REQUEST_ERROR, { position: toast.POSITION.TOP_RIGHT });
            }
        }
    };

    const buttonRef = useRef(null);

    const handleKeyDown = (event) => {
        // Check if the pressed key is the Tab key (key code 9)
        if (event.keyCode === 9) {
            // Prevent the default Tab behavior to prevent losing focus from the text input
            event.preventDefault();

            // Focus the button element
            buttonRef.current.focus();
        }
    };

    const openCard = () => {
        udfnClearForm()
        setAddCard(true)
    }

    useEffect(() => {
        udfnGroupList();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <ToastContainer />
            <div className="col-12">
                <Modal className="modal fade" show={addCard} onHide={setAddCard} backdrop="static">
                    <div className="" role="document">
                        <div className="">
                            <form >
                                <div className="modal-header">
                                    <h4 className="modal-title fs-20">{editFlag === true ? 'Update Item Group' : 'Add Item Group'}</h4>
                                    <button type="button" className="close btn" onClick={() => udfnClearForm()}><span>×</span></button>
                                </div>
                                <div className="modal-body">
                                    <i className="flaticon-cancel-12 close" data-dismiss="modal"></i>
                                    <div className="add-contact-box">
                                        <div className="add-contact-content">
                                            <div className="form-group mb-3">
                                                <label className="text-black font-w500">Group Name <span className="text-danger">*</span></label>
                                                <div className="contact-name">
                                                    <input type="text" className="form-control" autoComplete="off" value={groupName}
                                                        name="group_name" required="required" onChange={e => setGroupName(e.target.value)}
                                                        onKeyUp={(e) => udfnNameValidation(e)}
                                                        onKeyDown={handleKeyDown}
                                                        placeholder="Group Name" autoFocus
                                                    />
                                                    <span className="validation-text"></span>
                                                </div>
                                            </div>
                                            {editFlag === true ?
                                                <fieldset className="form-group mb-3">
                                                    <div className="row">
                                                        <label className="col-form-label col-sm-3 pt-1 text-black font-w500">
                                                            Status
                                                        </label>
                                                        <div className="col-sm-2">
                                                            <div className="form-check checkbox-success">
                                                                {status === 1 ? <input
                                                                    className="form-check-input"
                                                                    type="radio"
                                                                    name="gridRadios"
                                                                    onClick={() => setStatusCode(1)}
                                                                    onKeyDown={handleKeyDown}
                                                                    defaultChecked
                                                                /> :
                                                                    <input
                                                                        className="form-check-input"
                                                                        type="radio"
                                                                        name="gridRadios"
                                                                        onClick={() => setStatusCode(1)}
                                                                        onKeyDown={handleKeyDown}
                                                                    />}
                                                                <label className="form-check-label">
                                                                    Active
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-5">
                                                            <div className="form-check checkbox-danger">
                                                                {status === 2 ? <input
                                                                    className="form-check-input"
                                                                    type="radio"
                                                                    name="gridRadios"
                                                                    onClick={() => setStatusCode(2)}
                                                                    onKeyDown={handleKeyDown}
                                                                    defaultChecked
                                                                /> :
                                                                    <input
                                                                        className="form-check-input"
                                                                        type="radio"
                                                                        name="gridRadios"
                                                                        onClick={() => setStatusCode(2)}
                                                                        onKeyDown={handleKeyDown}
                                                                    />}
                                                                <label className="form-check-label">
                                                                    Inactive
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </fieldset> : ''}
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-danger" onClick={() => udfnClearForm()}> <i className="flaticon-delete-1"></i> Discard</button>
                                    <Button className="me-2" variant="whatsapp" ref={buttonRef} onClick={udfnFromValidate} disabled={buttonFlag === false}>
                                        {editFlag === true ? 'Update' : 'Save'}
                                    </Button>
                                </div>
                            </form>

                        </div>
                    </div>
                </Modal>
                <LoadingOverlay
                    active={isLoading}
                    spinner
                    text='Loading ...'
                >
                    <div className="card">
                        <div className="card-header mb-sm-12 d-flex align-items-center flex-wrap">
                            <div className='col-sm-5'>
                                <h2 className="card-title">Item Group List</h2>
                            </div>
                            <div className='col-sm-3'>
                                <p className="bgl-primary rounded p-3 text-center mb-2"><span className="text-black me-1" >Total Active Groups  :  </span><Badge bg='info badge-rounded' className='text-black font-w400 fs-18'>{groupTotalCount}</Badge></p>
                            </div>
                            <div className='col-sm-2 me-1'>
                                <Link className="btn btn-primary mb-2" onClick={() => openCard()}>
                                    + Add Group
                                </Link>
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="table-responsive">
                                <div className='row'>
                                    <div className='col-sm-9'>
                                        <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
                                    </div>
                                </div>
                                <table {...getTableProps()} className="table filtering-table table-responsive-lg">
                                    <thead>
                                        <tr>
                                            <th>S.No.</th>
                                            <th>Item Group Name</th>
                                            <th className='text-center'>No.Of.Categories</th>
                                            <th>Status</th>
                                            <th>Actions</th>
                                        </tr>

                                    </thead>
                                    <tbody {...getTableBodyProps()} className="" >

                                        {page.map((row, index) => {
                                            prepareRow(row)
                                            return (
                                                <tr key={index}>
                                                    <td>{index + 1}</td>
                                                    <td>{row.original.gm_group_name}</td>
                                                    <td>
                                                        <div className='text-center'>
                                                            <strong>{row.original.category_count}</strong>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        {row.original.gm_status_code === 1 ? <Badge bg="" className='badge-success light'>
                                                            <span className="me-2">
                                                                <i className="ms-1 fa fa-check" />
                                                            </span>
                                                            {row.original.status}
                                                        </Badge> : <Badge bg="" className='badge-primary light'>
                                                            <span className="me-2">
                                                                <i className="fa fa-times"></i>
                                                            </span>
                                                            {row.original.status}
                                                        </Badge>
                                                        }
                                                    </td>
                                                    <td>
                                                        <Link className="btn btn-secondary	 shadow btn-xs sharp me-2"
                                                            onClick={() => udfnEditLoad(row.original)}>
                                                            <i className="fa fa-pencil" id={'edittooltip' + index}></i>
                                                            <UncontrolledTooltip placement="top" target={'edittooltip' + index}>
                                                                Edit
                                                            </UncontrolledTooltip>
                                                        </Link>
                                                        <Link className="btn btn-danger shadow btn-xs sharp" onClick={() => udfnDelete(row.original.gm_group_code)}
                                                        >
                                                            <i className="fa fa-trash" id={'deletetooltip' + index}></i>
                                                            <UncontrolledTooltip placement="top" target={'deletetooltip' + index}>
                                                                Delete
                                                            </UncontrolledTooltip>
                                                        </Link>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                                <div className="d-flex justify-content-between">
                                    <span>
                                        Page{' '}
                                        <strong>
                                            {pageIndex + 1} of {pageOptions.length}
                                        </strong>{''}
                                    </span>
                                    <span className="table-index">
                                        Go to page : {' '}
                                        <input type="number"
                                            className="ms-2"
                                            defaultValue={pageIndex + 1}
                                            onChange={e => {
                                                const pageNumber = e.target.value ? Number(e.target.value) - 1 : 0
                                                gotoPage(pageNumber)
                                            }}
                                        />
                                    </span>
                                </div>
                                <div className="text-center">
                                    <div className="filter-pagination  mt-3">
                                        <button className=" previous-button" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>{'<<'}</button>

                                        <button className="previous-button" onClick={() => previousPage()} disabled={!canPreviousPage}>
                                            Previous
                                        </button>
                                        <button className="next-button" onClick={() => nextPage()} disabled={!canNextPage}>
                                            Next
                                        </button>
                                        <button className=" next-button" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>{'>>'}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </LoadingOverlay>
            </div>
        </>
    )
}
export default ItemGroupMaster;