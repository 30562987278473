export const MenuList = [
    //Dashboard
    {
        title: 'Dashboard',
        classsChange: 'mm-collapse',
        iconStyle: <i className="flaticon-144-layout"></i>,
        content: [

            {
                title: 'Dashboard',
                to: 'dashboard',
            },
            


        ],
    },
    //Masters
    {
        title: 'Masters',
        classsChange: 'mm-collapse',
        iconStyle: <i className="flaticon-077-menu-1"></i>,
        content: [
            {
                title: 'Company Master',
                to: 'company'
            },
            {
                title: 'Branch Master',
                to: 'branch'
            },
            {
                title: 'Voucher Setting - Sales',
                to: 'voucher-type'
            },
            {
                title: 'Customer Master',
                to: 'customer'
            },
            {
                title: 'Supplier Master',
                to: 'supplier'
            },
            {
                title: 'User Master',
                to: 'userlist'
            },
            {
                title: 'Brand Master',
                to: 'brand'
            },
            {
                title: 'Unit Master',
                to: 'unit'
            },
            {
                title: 'Item Group Master',
                to: 'item-group'
            },
            {
                title: 'Item Category Master',
                to: 'item-category'
            },
            {
                title: 'Item Master',
                to: 'item'
            },
            {
                title: 'Salesman Master',
                to: 'salesman'
            },

            {
                title: 'Salesman Print',
                to: 'salesman-print'
            },
            {
                title: 'Discount Bulk Update',
                to: 'discount-bulk-update'
            },
            {
                title: 'HSN Bulk Update',
                to: 'hsn-bulk-update'
            },
        ],
    },
    //Transactions Screen
    {
        title: 'Transactions',
        classsChange: 'mm-collapse',
        iconStyle: <i className="flaticon-061-puzzle"></i>,
        content: [
            {
                title: 'Purchase List',
                to: 'purchase-list'
            },
            {
                title: 'Stock Transfer List',
                to: 'stock-transfer-list'
            },
            {
                title: 'Stock Adjustment List',
                to: 'stock-adjustment-list'
            },
            {
                title: 'Change Price List',
                to: 'change-price-list'
            },
            {
                title: 'QrCode Reprint',
                to: 'qrcode-reprint'
            }

        ],
    },
    // Billing Screen
    // {
    //     title: 'Billing',
    //     //classsChange: 'mm-collapse',
    //     iconStyle: <i className="flaticon-381-network" />,
    //     to: 'billing-list',
    // },
    {
        title: 'Reports',
        classsChange: 'mm-collapse',
        iconStyle: <i className="flaticon-049-copy" />,
        content: [
            {
                title: 'Stock Report',
                to: 'stock-report'
            },
            {
                title: 'Aging Stock Report',
                to: 'aging-stock-report'
            },
            {
                title: 'POS Report',
                to: 'pos-report'
            },
            {
                title: 'Itemwise Sales Report',
                to: 'itemwise-sales-report'
            },
            {
                title: 'Buyback Report',
                to: 'buyback-report'
            },
            {
                title: 'Itemwise Analysis Report',
                to: 'itemwise-analysis-report'
            },
            {
                title: 'Salesman Report',
                to: 'salesman-report'
            },
            {
                title: 'QrCode Analysis Report',
                to: 'qrcode-analysis-report'
            },
            {
                title: 'Item Price Details',
                to: 'item-price-details'
            },
            {
                title: 'GSTR Report',
                to: 'gstr-report'
            },
            {
                title: 'Profit Report',
                to: 'profit-report'
            }
        ],
    },
    // //Charts
    // {
    //     title: 'Charts',	
    //     classsChange: 'mm-collapse',
    //     iconStyle: <i className="flaticon-061-puzzle" />,
    //     content: [

    //         {
    //             title: 'RechartJs',
    //             to: 'chart-rechart',					
    //         },
    //         {
    //             title: 'Chartjs',
    //             to: 'chart-chartjs',					
    //         },
    //         {
    //             title: 'Sparkline',
    //             to: 'chart-sparkline',					
    //         },
    //         {
    //             title: 'Apexchart',
    //             to: 'chart-apexchart',					
    //         },
    //     ]
    // },
    // {
    //     title: ' COMPONENTS',
    //     classsChange: 'nav-label'
    // },
    // //Boosttrap
    // {
    //     title: 'Bootstrap',	
    //     classsChange: 'mm-collapse',
    //     iconStyle: <i className="flaticon-003-diamond" />,	
    //     content: [
    //         {
    //             title: 'Accordion',
    //             to: 'ui-accordion',					
    //         },
    //         {
    //             title: 'Alert',
    //             to: 'ui-alert',					
    //         },
    //         {
    //             title: 'Badge',
    //             to: 'ui-badge',					
    //         },
    //         {
    //             title: 'Button',
    //             to: 'ui-button',					
    //         },
    //         {
    //             title: 'Modal',
    //             to: 'ui-modal',					
    //         },
    //         {
    //             title: 'Button Group',
    //             to: 'ui-button-group',					
    //         },
    //         {
    //             title: 'List Group',
    //             to: 'ui-list-group',					
    //         },
    //         {
    //             title: 'Cards',
    //             to: 'ui-card',					
    //         },
    //         {
    //             title: 'Carousel',
    //             to: 'ui-carousel',					
    //         },
    //         {
    //             title: 'Dropdown',
    //             to: 'ui-dropdown',					
    //         },
    //         {
    //             title: 'Popover',
    //             to: 'ui-popover',					
    //         },
    //         {
    //             title: 'Progressbar',
    //             to: 'ui-progressbar',					
    //         },
    //         {
    //             title: 'Tab',
    //             to: 'ui-tab',					
    //         },
    //         {
    //             title: 'Typography',
    //             to: 'ui-typography',					
    //         },
    //         {
    //             title: 'Pagination',
    //             to: 'ui-pagination',					
    //         },
    //         {
    //             title: 'Grid',
    //             to: 'ui-grid',					
    //         },
    //     ]
    // },
    // //plugins
    // {
    //     title:'Plugins',
    //     classsChange: 'mm-collapse',
    //     iconStyle : <i className="flaticon-053-heart" />,
    //     content : [
    //         {
    //             title:'Select 2',
    //             to: 'uc-select2',
    //         },
    //         // {
    //         //     title:'Noui Slider',
    //         //     to: 'uc-noui-slider',
    //         // },
    //         {
    //             title:'Sweet Alert',
    //             to: 'uc-sweetalert',
    //         },
    //         {
    //             title:'Toastr',
    //             to: 'uc-toastr',
    //         },
    //         {
    //             title:'Jqv Map',
    //             to: 'map-jqvmap',
    //         },
    //         {
    //             title:'Light Gallery',
    //             to: 'uc-lightgallery',
    //         },
    //     ]
    // },
    // {
    //     title: 'Redux',	
    //     classsChange: 'mm-collapse',
    //     iconStyle: <i className="flaticon-381-book"></i>,
    //     content: [
    //         {
    //             title: 'Todo',
    //             to: 'todo'
    //         },
    //     ]
    // },
    // //Widget
    // {   
    //     title:'Widget',
    //     //classsChange: 'mm-collapse',
    //     iconStyle: <i className="flaticon-381-settings-2" />,
    //     to: 'widget-basic',
    // },
    // //Forms
    // {
    //     title:'Forms',
    //     classsChange: 'mm-collapse',
    //     iconStyle: <i className="flaticon-044-file" />,
    //     content : [
    //         {
    //             title:'Form Elements',
    //             to: 'form-element',
    //         },
    //         {
    //             title:'Wizard',
    //             to: 'form-wizard',
    //         },
    //         {
    //             title:'CkEditor',
    //             to: 'form-ckeditor',
    //         },
    //         {
    //             title:'Pickers',
    //             to: 'form-pickers',
    //         },
    //         {
    //             title:'Form Validate',
    //             to: 'form-validation',
    //         },

    //     ]
    // },
    // //Table
    // {
    //     title:'Table',
    //     classsChange: 'mm-collapse',
    //     iconStyle: <i className="flaticon-381-network" />,
    //     content : [
    //         {
    //             title:'Table Filtering',
    //             to: 'table-filtering',
    //         },
    //         {
    //             title:'Table Sorting',
    //             to: 'table-sorting',
    //         },
    //         {
    //             title:'Bootstrap',
    //             to: 'table-bootstrap-basic',
    //         },


    //     ]
    // },
    // //Pages
    // {
    //     title:'Pages',
    //     classsChange: 'mm-collapse',
    //     iconStyle: <i className="flaticon-049-copy" />,
    //     content : [
    //         {
    //             title:'Error',
    //             hasMenu : true,
    //             content : [
    //                 {
    //                     title: 'Error 400',
    //                     to : 'page-error-400',
    //                 },
    //                 {
    //                     title: 'Error 403',
    //                     to : 'page-error-403',
    //                 },
    //                 {
    //                     title: 'Error 404',
    //                     to : 'page-error-404',
    //                 },
    //                 {
    //                     title: 'Error 500',
    //                     to : 'page-error-500',
    //                 },
    //                 {
    //                     title: 'Error 503',
    //                     to : 'page-error-503',
    //                 },
    //             ],
    //         },
    //         {
    //             title:'Lock Screen',
    //             to: 'page-lock-screen',
    //         },

    //     ]
    // },

]