import React, { useEffect, useRef, useState } from 'react';
import { Button } from 'react-bootstrap';
import '../Filters/Common/filtering.css';
import Select from "react-select";
import FORM_VALIDATION from "../../components/Validation/FormValidation"
import ERROR from "../../components/Validation/ErrorMessage"
import { ToastContainer, toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { getLoggedInUser, getStateList, getRefreshToken } from "../../../services/AuthService"
import MASTER_API from '../../Api/MasterApi';
import { UserLogout } from '../../layouts/nav/Logout';
import axios from 'axios';
import COMMONVARIABLES from '../Utilities/constants';
import LoadingOverlay from 'react-loading-overlay-ts';
import { useNavigate } from "react-router-dom";

export const CompanyMaster = () => {
	const BASE_URL = process.env.REACT_APP_BASE_URL;
	const navigate = useNavigate();
	const stateList = getStateList();

	const [buttonText, setButtonText] = useState('Save');
	const [isLoading, setIsLoading] = useState(false);
	const [buttonFlag, setButtonFlag] = useState(false);
	const [companyName, setCompanyName] = useState('');
	const [companyCode, setCompanyCode] = useState('');
	const [companyAddress, setCompanyAddress] = useState('');
	const [companyCity, setCompanyCity] = useState('');
	const [companyState, setCompanyState] = useState('');
	const [companyGstin, setCompanyGstin] = useState('');
	const [companyPincode, setCompanyPincode] = useState('');
	const [companyContactPerson, setCompanyContactPerson] = useState('');
	const [companyMobile, setCompanyMobile] = useState('');
	const [companyEmail, setCompanyEmail] = useState('');
	const [labelTitle, setLabelTitle] = useState('');
	const [companyBankName, setCompanyBankName] = useState('');
	const [companyBranchName, setCompanyBranchName] = useState('');
	const [companyAccountName, setCompanyAccountName] = useState('');
	const [companyAccNo, setCompanyAccNo] = useState('');
	const [companyIfscCode, setCompanyIfscCode] = useState('');
	const [companyTelephone, setCompanyTelephone] = useState('');
	const [companyCustomerCare, setCompanyCustomerCare] = useState('');
	const [companyWebsite, setCompanyWebsite] = useState('');
	const [stateOptions, setStateOptions] = useState([]);

	const udfnUpperCase = (event) => {
		var val = event.target.value.toUpperCase()
		event.target.value = val;
	};

	const udfnLowerCase = (event) => {
		var val = event.target.value.toLowerCase();
		event.target.value = val;
	};

	const udfnGstinValidation = (event) => {
		const regex = /^[a-zA-Z0-9]*$/;
		const input = event.target.value + event.key;

		if (!regex.test(input)) {
			event.preventDefault();
		}
	};

	const udfnsetCompanyName = (value) => {
		var val = value.charAt(0).toUpperCase() + value.slice(1);
		setCompanyName(val);
	};

	const udfnsetCompanyCity = (value) => {
		var val = value.charAt(0).toUpperCase() + value.slice(1);
		setCompanyCity(val);
	};

	const udfnsetCompanyContactPerson = (value) => {
		var val = value.charAt(0).toUpperCase() + value.slice(1);
		setCompanyContactPerson(val);
	};

	const udfnNameValidation = (event) => {
		var val = event.target.value.charAt(0).toUpperCase() + event.target.value.slice(1);
		event.target.value = val;
	};

	const udfnPincodeValidation = (event) => {
		const regex = /^[0-9]*$/;
		const input = event.target.value + event.key;
		if (!regex.test(input) || input.length > 6) {
			event.preventDefault();
		}
	};

	const udfnCityValidation = (event) => {
		const regex = /^[a-zA-Z ]*$/;
		const input = event.target.value + event.key;
		if (!regex.test(input)) {
			event.preventDefault();
		}
	};

	const udfnPhoneNumberValidation = (event) => {
		const regex = /^[0-9]*$/;
		const input = event.target.value + event.key;
		if (!regex.test(input) || input.length > 10) {
			event.preventDefault();
		}
	};

	const udfnBankNameValidation = (event) => {
		const regex = /^[a-zA-Z ]*$/;
		const input = event.target.value + event.key;
		if (!regex.test(input)) {
			event.preventDefault();
		} else {
			var val = event.target.value.charAt(0).toUpperCase() + event.target.value.slice(1);
			event.target.value = val;
		}
	};

	const udfnBranchNameValidation = (event) => {
		const regex = /^[a-zA-Z ]*$/;
		const input = event.target.value + event.key;
		if (!regex.test(input)) {
			event.preventDefault();
		} else {
			var val = event.target.value.charAt(0).toUpperCase() + event.target.value.slice(1);
			event.target.value = val;
		}
	};

	const udfnAccountNameValidation = (event) => {
		const regex = /^[a-zA-Z ]*$/;
		const input = event.target.value + event.key;
		if (!regex.test(input)) {
			event.preventDefault();
		} else {
			var val = event.target.value.charAt(0).toUpperCase() + event.target.value.slice(1);
			event.target.value = val;
		}
	};

	const udfnAccountNumberValidation = (event) => {
		const regex = /^[0-9]*$/;
		const input = event.target.value + event.key;
		if (!regex.test(input)) {
			event.preventDefault();
		}
	};

	const udfnIFSCCodeValidation = (event) => {
		const regex = /^[a-zA-Z0-9]*$/;
		const input = event.target.value + event.key;
		if (!regex.test(input)) {
			event.preventDefault();
		}
	};

	const udfnTelephoneNumberValidation = (event) => {
		const regex = /^[0-9]*$/;
		const input = event.target.value + event.key;
		if (!regex.test(input)) {
			event.preventDefault();
		}
	};

	const udfnCustomerCareNumberValidation = (event) => {
		const regex = /^[0-9 ]*$/;
		const input = event.target.value + event.key;
		if (!regex.test(input)) {
			event.preventDefault();
		}
	};

	const udfnCompanyDetails = () => {
		var userData = getLoggedInUser();
		if (userData.company_code > 0) {
			setButtonText("Update");
			setStateOptions(stateList);
			setCompanyName(userData.company_name);
			setCompanyCode(userData.company_code);
			setCompanyAddress(userData.address);
			setCompanyCity(userData.city);
			setCompanyState({
				value: userData.state_code,
				label: userData.state_name,
			});
			setCompanyGstin(userData.gstin);
			setCompanyPincode(userData.pincode);
			setCompanyContactPerson(userData.contact_person);
			setCompanyMobile(userData.mobile_no);
			setCompanyEmail(userData.email_id);
			setLabelTitle(userData.label_title);
			setCompanyBankName(userData.bank_name);
			setCompanyAccNo(userData.account_number);
			setCompanyIfscCode(userData.ifsc_code);
			setCompanyTelephone(userData.cm_telephone_no);
			setCompanyCustomerCare(userData.cm_customer_care_no);
			setCompanyWebsite(userData.cm_website);
			setCompanyBranchName(userData.cm_branch_name);
			setCompanyAccNo(userData.cm_account_name);
		}
	};

	const udfnFormValidate = (e) => {
		e.preventDefault();
		var error = "";

		var gstinValid = Number(companyGstin.substring(0, 2)) === Number(companyState.value) ? true : false;

		if (!companyName.trim() || !companyAddress.trim() || !labelTitle.trim() || !companyCity.trim() || companyState === '' || !companyGstin.trim() || !companyPincode.trim() || !companyMobile.trim()) {
			error = ERROR.COMMON_REQUIRED;
			toast.error(error, { position: toast.POSITION.TOP_RIGHT });
			return
		}
		if (!FORM_VALIDATION('alpha_numeric_space_symbol', labelTitle)) {
			error = ERROR.COMPANY_MASTER.LABEL_TITLE_VALID;
			toast.error(error, { position: toast.POSITION.TOP_RIGHT });
			return
		}
		if (!FORM_VALIDATION('alpha_space', companyCity)) {
			error = ERROR.CITY_VALID;
			toast.error(error, { position: toast.POSITION.TOP_RIGHT });
			return
		}
		if (!FORM_VALIDATION('phone_number', companyMobile)) {
			error = ERROR.MOBILE_VALID;
			toast.error(error, { position: toast.POSITION.TOP_RIGHT });
			return
		}
		if (!FORM_VALIDATION('gstin', companyGstin) || !gstinValid) {
			error = ERROR.GSTIN_VALID;
			toast.error(error, { position: toast.POSITION.TOP_RIGHT });
			return
		}
		if (companyEmail !== '' && !FORM_VALIDATION('email', companyEmail)) {
			error = ERROR.EMAIL_VALID;
			toast.error(error, { position: toast.POSITION.TOP_RIGHT });
			return
		}
		if (!FORM_VALIDATION('pincode', companyPincode)) {
			error = ERROR.PINCODE_VALID;
			toast.error(error, { position: toast.POSITION.TOP_RIGHT });
			return
		}
		if (companyWebsite !== '' && !FORM_VALIDATION('website', companyWebsite)) {
			error = ERROR.WEBSITE_VALID;
			toast.error(error, { position: toast.POSITION.TOP_RIGHT });
			return
		}

		if (error === '') {
			udfnSaveCompany(e);
		}
	};

	const udfnSaveCompany = async () => {
		setIsLoading(true)
		setButtonFlag(true)
		var apiUrl = BASE_URL + MASTER_API.COMPANY_MASTER_CREATE;
		var userData = getLoggedInUser();

		const uploadData = {
			user_id: Number(userData.id),
			company_code: Number(companyCode),
			company_name: String(companyName.trim()),
			address: String(companyAddress.trim()),
			label_title: String(labelTitle.trim()),
			city: String(companyCity.trim()),
			gstin: String(companyGstin.trim()),
			pincode: String(companyPincode),
			contact_person: String(companyContactPerson.trim()),
			mobile_no: String(companyMobile),
			email_id: String(companyEmail.trim()),
			state_code: Number(companyState.value),
			bank_name: String(companyBankName.trim()),
			account_number: String(companyAccNo),
			ifsc_code: String(companyIfscCode.trim()),
			telephone_no: String(companyTelephone),
			customer_care_no: String(companyCustomerCare),
			website: String(companyWebsite.trim()),
			branch_name: String(companyBranchName.trim()),
			account_name: String(companyAccountName.trim()),
		};
		const config = { headers: { authorization: 'Bearer '.concat(userData.accessToken) } };
		try {
			const response = await axios.post(apiUrl, uploadData, config);
			setIsLoading(false)
			setButtonFlag(false)

			if (response && response?.data?.body?.status === COMMONVARIABLES.STATUS.INVALIDUSER) {
				toast.error(response.data.body.message, { position: toast.POSITION.TOP_RIGHT });
				UserLogout();
			}
			if (response && response?.data?.body?.status === COMMONVARIABLES.STATUS.UNAUTHORIZED) {
				const refresh = await getRefreshToken();
				if (refresh) {
					if (Number(refresh) === COMMONVARIABLES.STATUS.EXPIRED_TOKEN) {
						UserLogout()
					} else {
						await udfnSaveCompany();
					}
				}
			}
			if (response?.data?.body?.status === COMMONVARIABLES.STATUS.EXISTS) {
				toast.error(response.data.body.message, { position: toast.POSITION.TOP_RIGHT });
			}

			if (response?.data?.body?.status === COMMONVARIABLES.STATUS.SERVICE_CATCH_STATUS) {
				toast.error(ERROR.API_ERROR.SERVICE_CATCH_ERROR, { position: toast.POSITION.TOP_RIGHT });
			}

			if (response?.data?.body?.status === COMMONVARIABLES.STATUS.SUCCESS) {
				var UserInfo = {
					id: userData.id,
					user_id: userData.user_id,
					status: userData.status,
					user_name: userData.user_name,
					role_id: userData.role_id,
					last_sync_date: userData.last_sync_date,
					company_name: companyName,
					label_title: labelTitle,
					city: companyCity,
					pincode: companyPincode,
					contact_person: companyContactPerson,
					email_id: companyEmail,
					address: companyAddress,
					mobile_no: companyMobile,
					gstin: companyGstin,
					company_code: userData.company_code,
					state_code: companyState.value,
					state_name: companyState.label,
					bank_name: companyBankName,
					account_number: companyAccNo,
					ifsc_code: companyIfscCode,
					cm_telephone_no: companyTelephone,
					cm_customer_care_no: companyCustomerCare,
					cm_website: companyWebsite,
					cm_branch_name: companyBranchName,
					cm_account_name: companyAccNo,
					accessToken: userData.accessToken,
					refreshToken: userData.refreshToken
				};
				localStorage.removeItem('userDetails');
				localStorage.setItem('userDetails', JSON.stringify(UserInfo));
				udfnCompanyDetails()
				toast.success(response.data.body.message, { position: toast.POSITION.TOP_RIGHT });
			} else {
				toast.error(response.data.body.message, { position: toast.POSITION.TOP_RIGHT });
			}
		} catch (error) {
			// Handle error
			setIsLoading(false)
			console.error("Company Master API Error:", error);
			if (error.code === COMMONVARIABLES.STATUS.NETWORK_ERROR_STATUS) {
				toast.error(ERROR.API_ERROR.NETWORK_ERROR, { position: toast.POSITION.TOP_RIGHT });
			}
			if (error.code === COMMONVARIABLES.STATUS.BAD_REQUEST_ERROR_STATUS) {
				toast.error(ERROR.API_ERROR.BAD_REQUEST_ERROR, { position: toast.POSITION.TOP_RIGHT });
			}
		}
	};

	const udfnAddressKeyDown = (event) => {
		// Check if the pressed key is the Enter key (key code 13)
		if (event.keyCode === 13) {
			// Prevent the default Enter behavior to prevent adding new lines

			// Get the number of lines in the textarea
			const lines = event.target.value.split('\n');

			// Check if the maximum number of lines (e.g., 5) is reached
			if (lines.length >= 3) {
				// Do not allow further input
				event.preventDefault();
			}
		}
	};

	const buttonRef = useRef(null);

	const handleKeyDown = (event) => {
		// Check if the pressed key is the Tab key (key code 9)
		if (event.keyCode === 9) {
			// Prevent the default Tab behavior to prevent losing focus from the text input
			event.preventDefault();

			// Focus the button element
			buttonRef.current.focus();
		}
	};

	useEffect(() => {
		udfnCompanyDetails()
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<ToastContainer />

			<LoadingOverlay
				active={isLoading}
				spinner
				text='Loading ...'
			>
				<div className="card">
					<div className="card-header">
						<h4 className="card-title">Company Master </h4>
					</div>
					<div className="card-body">
						<div className="basic-form">
							<form onSubmit={(e) => e.preventDefault()}>
								<div className="row">
									<div className="form-group mb-3 col-lg-6">
										<label className="text-black font-w300">Company Name</label>
										<span className="text-danger star-txt">*</span>
										<input
											type="text"
											className="form-control"
											placeholder="Company Name"
											maxLength='100'
											autoFocus
											value={companyName}
											onKeyUp={(e) => udfnNameValidation(e)}
											onChange={(e) => udfnsetCompanyName(e.target.value)}
										/>
									</div>
									<div className="form-group mb-3 col-lg-6">
										<label className="text-black font-w300">Address</label>
										<span className="text-danger star-txt">*</span>
										<textarea
											className="form-control text-area-udf1"
											// rows={3}
											placeholder="Address"
											maxLength='250'
											value={companyAddress}
											onChange={(e) => setCompanyAddress(e.target.value)}
											onKeyDown={udfnAddressKeyDown}
										/>
									</div>
									<div className="form-group mb-3 col-lg-6">
										<label className="text-black font-w300">Label Title</label>
										<span className="text-danger star-txt">*</span>
										<input
											className="form-control"
											placeholder="Label Title"
											maxLength='12'
											value={labelTitle}
											onChange={(e) => setLabelTitle(e.target.value)}
										/>
									</div>
									<div className="form-group mb-3 col-lg-6">
										<label className="text-black font-w300">City</label>
										<span className="text-danger star-txt">*</span>
										<input
											type="text"
											className="form-control"
											placeholder="City"
											maxLength='100'
											value={companyCity}
											onKeyUp={(e) => udfnNameValidation(e)}
											onKeyPress={(e) => udfnCityValidation(e)}
											onChange={(e) => udfnsetCompanyCity(e.target.value)}
										/>
									</div>
									<div className="form-group mb-3 col-lg-6">
										<label className="text-black font-w300">Contact Person</label>
										<input
											type="text"
											className="form-control"
											placeholder="Contact Person"
											maxLength='100'
											value={companyContactPerson}
											onKeyUp={(e) => udfnNameValidation(e)}
											onChange={(e) => udfnsetCompanyContactPerson(e.target.value)}
										/>
									</div>
									<div className="form-group mb-3 col-lg-6">
										<label className="text-black font-w300">State</label>
										<span className="text-danger star-txt">*</span>
										<Select
											defaultValue={companyState}
											onChange={setCompanyState}
											value={companyState}
											options={stateOptions.map((option) => ({
												label: option.sm_state_name,
												value: option.sm_state_code,
											}))}
											maxLength='100'
											showContent="true"
											open="true"
											className="my-react-select-container"
											theme={theme => ({
												...theme,
												borderRadius: '6px',
												colors: {
													...theme.colors,
													primary25: '#b3b3b3',
													primary: 'black',
													neutral80: "data-theme-version" === "dark" ? 'white' : ''
												},
											})}
										/>
									</div>
									<div className="form-group mb-3 col-lg-6">
										<label className="text-black font-w300">Mobile Number</label>
										<span className="text-danger star-txt">*</span>
										<input
											type="number"
											className="form-control"
											placeholder="Mobile"
											onKeyPress={(e) => udfnPhoneNumberValidation(e)}
											value={companyMobile}
											onChange={(e) => setCompanyMobile(e.target.value)}
										/>
									</div>
									<div className="form-group mb-3 col-lg-6">
										<label className="text-black font-w300">GSTIN</label>
										<span className="text-danger star-txt">*</span>
										<input
											type="text"
											className="form-control"
											placeholder="GSTIN"
											maxLength='15'
											value={companyGstin}
											onKeyUp={(e) => udfnUpperCase(e)}
											onKeyPress={(e) => udfnGstinValidation(e)}
											onChange={(e) => setCompanyGstin(e.target.value)}
										/>
									</div>
									<div className="form-group mb-3 col-lg-6">
										<label className="text-black font-w300">Email</label>
										<input
											className="form-control"
											placeholder="Email"
											maxLength='50'
											value={companyEmail}
											onKeyUp={(e) => udfnLowerCase(e)}
											onChange={(e) => setCompanyEmail(e.target.value)}
										/>
									</div>
									<div className="form-group mb-3 col-lg-6">
										<label className="text-black font-w300">Pincode</label>
										<span className="text-danger star-txt">*</span>
										<input
											type="text"
											className="form-control"
											placeholder="Pincode"
											value={companyPincode}
											onKeyPress={(e) => udfnPincodeValidation(e)}
											onChange={(e) => setCompanyPincode(e.target.value)}
											maxLength="6"
										/>
									</div>
									<div className="form-group mb-3 col-lg-6">
										<label className="text-black font-w300">Bank Name</label>
										<input
											className="form-control"
											placeholder="Bank Name"
											maxLength='100'
											onKeyUp={(e) => udfnNameValidation(e)}
											onKeyPress={(e) => udfnBankNameValidation(e)}
											value={companyBankName}
											onChange={(e) => setCompanyBankName(e.target.value)}
										/>
									</div>
									<div className="form-group mb-3 col-lg-6">
										<label className="text-black font-w300">Branch Name</label>
										<input
											className="form-control"
											placeholder="Branch Name"
											maxLength='100'
											onKeyUp={(e) => udfnNameValidation(e)}
											onKeyPress={(e) => udfnBranchNameValidation(e)}
											value={companyBranchName}
											onChange={(e) => setCompanyBranchName(e.target.value)}
										/>
									</div>
									<div className="form-group mb-3 col-lg-6">
										<label className="text-black font-w300">Account Name</label>
										<input
											className="form-control"
											placeholder="Account Name"
											maxLength='100'
											onKeyUp={(e) => udfnNameValidation(e)}
											onKeyPress={(e) => udfnAccountNameValidation(e)}
											value={companyAccountName}
											onChange={(e) => setCompanyAccountName(e.target.value)}
										/>
									</div>
									<div className="form-group mb-3 col-lg-6">
										<label className="text-black font-w300">Acc.No.</label>
										<input
											className="form-control"
											placeholder="Acc.No."
											maxLength='20'
											onKeyPress={(e) => udfnAccountNumberValidation(e)}
											value={companyAccNo}
											onChange={(e) => setCompanyAccNo(e.target.value)}
										/>
									</div>
									<div className="form-group mb-3 col-lg-6">
										<label className="text-black font-w300">IFSC Code</label>
										<input
											className="form-control"
											placeholder="IFSC Code"
											maxLength='15'
											onKeyUp={(e) => udfnUpperCase(e)}
											onKeyPress={(e) => udfnIFSCCodeValidation(e)}
											value={companyIfscCode}
											onChange={(e) => setCompanyIfscCode(e.target.value)}
										/>
									</div>
									<div className="form-group mb-3 col-lg-6">
										<label className="text-black font-w300">Telephone Number</label>
										<input
											className="form-control"
											placeholder="Telephone Number"
											maxLength='11'
											onKeyPress={(e) => udfnTelephoneNumberValidation(e)}
											value={companyTelephone}
											onChange={(e) => setCompanyTelephone(e.target.value)}
										/>
									</div>
									<div className="form-group mb-3 col-lg-6">
										<label className="text-black font-w300">Customer Care Number</label>
										<input
											className="form-control"
											placeholder="Customer Care Number"
											maxLength='11'
											onKeyPress={(e) => udfnCustomerCareNumberValidation(e)}
											value={companyCustomerCare}
											onChange={(e) => setCompanyCustomerCare(e.target.value)}
										/>
									</div>
									<div className="form-group mb-3 col-lg-6">
										<label className="text-black font-w300">Website URL</label>
										<input
											className="form-control"
											placeholder="Website"
											maxLength='50'
											value={companyWebsite}
											onKeyUp={(e) => udfnLowerCase(e)}
											onKeyDown={handleKeyDown}
											onChange={(e) => setCompanyWebsite(e.target.value)}
										/>
									</div>
								</div>
								<div className='text-end'>
									<Button
										variant="danger"
										type="button"
										className="btn btn-success me-2"
										onClick={() => {
											navigate("/dashboard");
										}}
									>
										Discard
									</Button>
									<Button variant="whatsapp" ref={buttonRef} onClick={udfnFormValidate} disabled={buttonFlag ? true : false}>
										{buttonText}
									</Button>
								</div>
							</form>
						</div>
					</div>
				</div >
			</LoadingOverlay >
		</>
	)
}

export default CompanyMaster;