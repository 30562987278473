import { format } from 'date-fns';
import { ColumnFilter } from '../Common/ColumnFilter';
export const COLUMNS = [
    {
        Header: 'S.No.',
        Footer: 'Voucher Code',
        accessor: 'id',
        Filter: ColumnFilter,
        disableFilters: true,
    },
    {
        Header: 'Branch Name',
        Footer: 'Branch Name',
        accessor: 'branch_name',
        Filter: ColumnFilter,
        disableFilters: true,
    },
    {
        Header: 'Voucher Name',
        Footer: 'Voucher Name',
        accessor: 'vt_voucher_type_name',
        Filter: ColumnFilter,
        disableFilters: true,
    },
    {
        Header: 'Prefix',
        Footer: 'Voucher Prefix',
        accessor: 'vt_prefix',
        Filter: ColumnFilter,
        disableFilters: true,
    },
    {
        Header: 'Suffix',
        Footer: 'Voucher Suffix',
        accessor: 'vt_suffix',
        Filter: ColumnFilter,
        disableFilters: true,
    },
    {
        Header: 'NO.Of.Digits',
        Footer: 'NO.Of.Digits',
        accessor: 'vt_no_of_digits',
        Filter: ColumnFilter,
        disableFilters: true,
    },
    {
        Header: 'Starting No.',
        Footer: 'Starting No.',
        accessor: 'vt_starting_number',
        Filter: ColumnFilter,
        disableFilters: true,
    },
    {
        Header: 'Voucher No.',
        Footer: 'Voucher No.',
        accessor: 'voucher_no',
        Filter: ColumnFilter,
        disableFilters: true,
    },
    {
        Header: 'Print Title',
        Footer: 'Print Title',
        accessor: 'vt_print_title',
        Filter: ColumnFilter,
        disableFilters: true,
    },
    {
        Header: 'Stock Applicable',
        Footer: 'Stock Reflection',
        accessor: 'vt_stock_applicable',
        Filter: ColumnFilter,
        disableFilters: true,
    },
    {
        Header: 'Status',
        Footer: 'Voucher Status',
        accessor: 'status',
        Filter: ColumnFilter,
        disableFilters: true,
    }
]

export const GROUPED_COLUMNS = [
]