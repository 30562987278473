import React, { useMemo, useState, useEffect, useRef } from 'react';
import { useTable, useGlobalFilter, useFilters, useSortBy, usePagination } from 'react-table';
import { COLUMNS } from '../Filters/Branch/BranchColumns';
import { GlobalFilter } from '../Filters/Common/GlobalFilter';
import { Link } from "react-router-dom";
import { Button, Modal } from 'react-bootstrap';
import '../Filters/Common/filtering.css'
import { Badge } from "react-bootstrap";
import ERROR_MESSAGE from '../Validation/ErrorMessage';
import FormValidation from '../Validation/FormValidation';
import swal from "sweetalert";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from 'axios';
import {
	getLoggedInUser, getRefreshToken
} from '../../../services/AuthService';
import MASTER_API from '../../Api/MasterApi';
import { UserLogout } from '../../layouts/nav/Logout';
import COMMONVARIABLES from '../Utilities/constants';
import LoadingOverlay from 'react-loading-overlay-ts';
import { UncontrolledTooltip } from "reactstrap"

export const BranchMaster = () => {

	const [isLoading, setIsLoading] = useState(false);
	const [addCard, setAddCard] = useState(false);
	const [editFlag, setEditFlag] = useState(false);
	const [branchName, setBranchName] = useState('');
	const [address, setAddress] = useState('');
	const [city, setCity] = useState('');
	const [statusCode, setStatusCode] = useState(1);
	const [branchList, setBranchList] = useState([]);
	const [branch_code, setBranchCode] = useState(1);
	const [buttonFlag, setButtonFlag] = useState(true);
	const [status, setStatus] = useState(false);
	const [branchTotalCount, setBranchTotalCount] = useState('');
	const [contactNo, setContactNo] = useState('');
	const [telephoneNo, setTelephoneNo] = useState('');

	const columns = useMemo(() => COLUMNS, [])
	const data = branchList;
	const tableInstance = useTable({
		columns,
		data,
		initialState: { pageIndex: 0 }
	}, useFilters, useGlobalFilter, useSortBy, usePagination)

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		state,
		page,
		gotoPage,
		pageCount,
		pageOptions,
		nextPage,
		previousPage,
		canNextPage,
		canPreviousPage,
		setGlobalFilter,
	} = tableInstance

	const { globalFilter, pageIndex } = state

	const udfnPhoneNumberValidation = (event) => {
		const regex = /^[0-9]*$/;
		const input = event.target.value + event.key;
		if (!regex.test(input) || input.length > 10) {
			event.preventDefault();
		}
	};

	const udfnAddressKeyDown = (event) => {
		// Check if the pressed key is the Enter key (key code 13)
		if (event.keyCode === 13) {
			// Prevent the default Enter behavior to prevent adding new lines

			// Get the number of lines in the textarea
			const lines = event.target.value.split('\n');

			// Check if the maximum number of lines (e.g., 5) is reached
			if (lines.length >= 3) {
				// Do not allow further input
				event.preventDefault();
			}
		}
	};

	const udfnTelePhoneNumberValidation = (event) => {
		const regex = /^[0-9]*$/;
		const input = event.target.value + event.key;
		if (!regex.test(input) || input.length > 11) {
			event.preventDefault();
		}
	};

	const udfnNameValidation = (event) => {
		var val = event.target.value.charAt(0).toUpperCase() + event.target.value.slice(1);
		event.target.value = val;
	};

	const udfnformValidate = (event) => {
		event.preventDefault();

		var commomError = false;
		var cityValid = false;
		var cityError = false;
		var errorMsg = '';
		if (!branchName.trim() || !address.trim() || !city.trim()) {
			commomError = true;
			errorMsg = ERROR_MESSAGE.COMMON_REQUIRED;
		}
		else if (contactNo !== '' && contactNo !== null) {
			if (!FormValidation('phone_number', contactNo)) {
				commomError = true;
				errorMsg = ERROR_MESSAGE.BRANCH.CONTACT_NO_VALID;
			}
		}
		else if (telephoneNo !== '' && telephoneNo !== null) {
			if (!FormValidation('phone_number', telephoneNo)) {
				commomError = true;
				errorMsg = ERROR_MESSAGE.BRANCH.TELEPHONE_NO_VALID;
			}
		}
		else {
			// Validation City
			cityValid = FormValidation('alpha_space', city);
			if (!cityValid) {
				cityError = true;
				errorMsg = ERROR_MESSAGE.CITY_VALID;
			}
		}

		if (!commomError && !cityError) {
			setButtonFlag(true);
			udfnBranchCreate();

		} else {
			toast.error(errorMsg, {
				position: "top-right",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
			});
		}

	};

	const udfnBranchList = async () => {

		const user = getLoggedInUser();
		setIsLoading(true);
		const data = {
			user_id: user.id
		};
		const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } };

		try {
			const response = await axios.post(process.env.REACT_APP_BASE_URL + MASTER_API.BRANCH_MASTER_LIST, data, config);
			setIsLoading(false);
			if (response && response?.data?.body?.status === COMMONVARIABLES.STATUS.INVALIDUSER) {
				toast.error(response.data.body.message, {
					position: "top-right",
					autoClose: 2000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
				});
				UserLogout();
			}

			if (response?.data?.body?.status === COMMONVARIABLES.STATUS.UNAUTHORIZED) {
				const refresh = await getRefreshToken();

				if (refresh) {
					if (Number(refresh) === COMMONVARIABLES.STATUS.EXPIRED_TOKEN) {
						UserLogout();
					} else {
						await udfnBranchList();
					}
				}
			}

			if (response?.data?.body?.status === COMMONVARIABLES.STATUS.SERVICE_CATCH_STATUS) {
				toast.error(ERROR_MESSAGE.API_ERROR.SERVICE_CATCH_ERROR, { position: toast.POSITION.TOP_RIGHT });
			}

			if (response?.data?.body?.branchList) {
				setBranchList(response.data.body.branchList);
				var branchListCount = response.data.body.branchList.filter(item => item.bm_status_code === 1).length;
				setBranchTotalCount(branchListCount);
				toast.success(response.data.body.message, {
					position: "top-right",
					autoClose: 2000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
				});
				setIsLoading(false);
			}
		} catch (error) {
			// Handle error
			setIsLoading(false);
			console.error("branch list API Error:", error);
			if (error.code === COMMONVARIABLES.STATUS.NETWORK_ERROR_STATUS) {
				toast.error(ERROR_MESSAGE.API_ERROR.NETWORK_ERROR, { position: toast.POSITION.TOP_RIGHT });
			}
			if (error.code === COMMONVARIABLES.STATUS.BAD_REQUEST_ERROR_STATUS) {
				toast.error(ERROR_MESSAGE.API_ERROR.BAD_REQUEST_ERROR, { position: toast.POSITION.TOP_RIGHT });
			}
		}

	};

	const udfnBranchCreate = async () => {
		setIsLoading(true);
		const user = getLoggedInUser();
		var uploadData = {};
		var apiUrl = '';
		if (editFlag === true) {
			uploadData = {
				user_id: user.id,
				company_code: user.company_code,
				branch_code: branch_code,
				branch_name: branchName.trim(),
				address: address.trim(),
				city: city.trim(),
				contact_no: String(contactNo),
				telephone_no: String(telephoneNo),
				status_code: statusCode
			};
			apiUrl = MASTER_API.BRANCH_MASTER_UPDATE;
		} else {
			uploadData = {
				user_id: user.id,
				company_code: user.company_code,
				branch_name: branchName.trim(),
				address: address.trim(),
				city: city.trim(),
				contact_no: String(contactNo.trim()),
				telephone_no: String(telephoneNo.trim()),
				status_code: statusCode
			};
			apiUrl = MASTER_API.BRANCH_MASTER_CREATE;
		}
		const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } };

		try {
			const response = await axios.post(process.env.REACT_APP_BASE_URL + apiUrl, uploadData, config);
			setIsLoading(false);
			if (response && response?.data?.body?.status === COMMONVARIABLES.STATUS.INVALIDUSER) {
				toast.error(response.data.body.message, {
					position: "top-right",
					autoClose: 2000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
				});
				UserLogout();
			}

			if (response?.data?.body?.status === COMMONVARIABLES.STATUS.UNAUTHORIZED) {
				const refresh = await getRefreshToken();
				if (refresh) {
					if (Number(refresh) === COMMONVARIABLES.STATUS.EXPIRED_TOKEN) {
						UserLogout();
					} else {
						await udfnBranchCreate();
					}
				}
			}

			if (response && response?.data?.body?.status === COMMONVARIABLES.STATUS.EXISTS) {
				setButtonFlag(true);
				toast.error(response.data.body.message, {
					position: "top-right",
					autoClose: 2000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
				});
			}

			if (response && response?.data?.body?.status === COMMONVARIABLES.STATUS.ALREADY_IN_USE) {
				toast.error(response.data.body.message, {
					position: "top-right",
					autoClose: 2000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
				});
			}

			if (response?.data?.body?.status === COMMONVARIABLES.STATUS.SERVICE_CATCH_STATUS) {
				toast.error(ERROR_MESSAGE.API_ERROR.SERVICE_CATCH_ERROR, { position: toast.POSITION.TOP_RIGHT });
			}

			if (response?.data?.body?.status === COMMONVARIABLES.STATUS.SUCCESS) {
				toast.success(response.data.body.message, {
					position: "top-right",
					autoClose: 2000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
				});
				setButtonFlag(true);
				udfnClearForm();
				if (editFlag === true) {
					setAddCard(false);
				}
				else {
					setAddCard(true);
				}
				await udfnBranchList();
			} else {
				toast.error(response.data.body.message, { position: toast.POSITION.TOP_RIGHT });
				setAddCard(true);
				setButtonFlag(true);
			}
		} catch (error) {
			// Handle error
			setIsLoading(false);
			console.error("branch create API Error:", error);
			if (error.code === COMMONVARIABLES.STATUS.NETWORK_ERROR_STATUS) {
				toast.error(ERROR_MESSAGE.API_ERROR.NETWORK_ERROR, { position: toast.POSITION.TOP_RIGHT });
			}
			if (error.code === COMMONVARIABLES.STATUS.BAD_REQUEST_ERROR_STATUS) {
				toast.error(ERROR_MESSAGE.API_ERROR.BAD_REQUEST_ERROR, { position: toast.POSITION.TOP_RIGHT });
			}
		}
	};

	const udfnClearForm = () => {
		setBranchName('')
		setAddress('')
		setCity('')
		setContactNo('')
		setTelephoneNo('')
		setBranchCode(1)
		setStatusCode(1);
		setAddCard(false);
		setEditFlag(false);
	};

	const udfnEditLoad = async (row) => {
		setBranchName(row.bm_branch_name)
		setAddress(row.bm_address)
		setCity(row.bm_city)
		if (row.bm_contact_no !== '') {
			setContactNo(row.bm_contact_no)
		}
		if (row.bm_telephone_no !== '') {
			setTelephoneNo(row.bm_telephone_no)
		}
		setBranchCode(row.bm_branch_code)
		setStatus(row.bm_status_code)
		setStatusCode(row.bm_status_code)
		setAddCard(true);
		setEditFlag(true);
	};

	const udfnDelete = async (branch_code) => {
		swal({
			title: "Are you sure?",
			text:
				"Once deleted, you will not be able to recover this branch!",
			icon: "warning",
			buttons: true,
			dangerMode: true,
		}).then((willDelete) => {
			if (willDelete) {
				udfnBranchDelete(branch_code);
			} else {
				swal("Your branch is safe!");
			}
		})
	}

	const udfnBranchDelete = async (branch_code) => {
		setIsLoading(true);
		const user = getLoggedInUser();
		const data = {
			user_id: user.id,
			company_code: user.company_code,
			branch_code: branch_code

		};
		const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } };

		try {
			const response = await axios.post(process.env.REACT_APP_BASE_URL + MASTER_API.BRANCH_MASTER_DELETE, data, config);
			setIsLoading(false);
			if (response && response?.data?.body?.status === COMMONVARIABLES.STATUS.INVALIDUSER) {
				toast.error(response.data.body.message, {
					position: "top-right",
					autoClose: 2000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
				});
				UserLogout();
			}

			if (response?.data?.body?.status === COMMONVARIABLES.STATUS.UNAUTHORIZED) {
				const refresh = await getRefreshToken();

				if (refresh) {
					if (Number(refresh) === COMMONVARIABLES.STATUS.EXPIRED_TOKEN) {
						UserLogout();
					} else {
						await udfnBranchDelete();
					}
				}
			}

			if (response && response?.data?.body?.status === COMMONVARIABLES.STATUS.EXISTS) {
				toast.error(response.data.body.message, {
					position: "top-right",
					autoClose: 2000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
				});
				setAddCard(true);
			}

			if (response?.data?.body?.status === COMMONVARIABLES.STATUS.SERVICE_CATCH_STATUS) {
				toast.error(ERROR_MESSAGE.API_ERROR.SERVICE_CATCH_ERROR, { position: toast.POSITION.TOP_RIGHT });
			}

			if (response?.data?.body?.status === COMMONVARIABLES.STATUS.SUCCESS) {
				swal(response.data.body.message, {
					icon: "success",
				});
				await udfnBranchList();
			} else {
				toast.error(response.data.body.message, { position: toast.POSITION.TOP_RIGHT });
			}
		} catch (error) {
			// Handle error
			setIsLoading(false);
			console.error("branch list API Error:", error);
			if (error.code === COMMONVARIABLES.STATUS.NETWORK_ERROR_STATUS) {
				toast.error(ERROR_MESSAGE.API_ERROR.NETWORK_ERROR, { position: toast.POSITION.TOP_RIGHT });
			}
			if (error.code === COMMONVARIABLES.STATUS.BAD_REQUEST_ERROR_STATUS) {
				toast.error(ERROR_MESSAGE.API_ERROR.BAD_REQUEST_ERROR, { position: toast.POSITION.TOP_RIGHT });
			}
		}
	};

	const buttonRef = useRef(null);

	const handleKeyDown = (event) => {
		// Check if the pressed key is the Tab key (key code 9)
		if (event.keyCode === 9) {
			// Prevent the default Tab behavior to prevent losing focus from the text input
			event.preventDefault();

			// Focus the button element
			buttonRef.current.focus();
		}
	};

	const openCard = () => {
		udfnClearForm()
		setAddCard(true)
	}

	useEffect(() => {
		udfnBranchList();
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<ToastContainer />
			<>
				<Modal className="modal fade" show={addCard} onHide={setAddCard} backdrop="static">
					<div className="" role="document">
						<div className="">
							<form >
								<div className="modal-header">
									<h4 className="modal-title fs-20">{editFlag === true ? 'Update Branch' : 'Add Branch'}</h4>
									<button type="button" className="close btn" onClick={() => udfnClearForm()}><span>×</span></button>
								</div>
								<div className="modal-body">
									<i className="flaticon-cancel-12 close" data-dismiss="modal"></i>
									<div className="add-contact-box">
										<div className="add-contact-content">
											<div className="form-group mb-3">
												<label className="text-black font-w500">Branch Name <span className="text-danger">*</span></label>
												<div className="contact-name">
													<input type="text" className="form-control" autoComplete="off"
														name="branch_name" required="required" value={branchName}
														onChange={e => setBranchName(e.target.value)}
														onKeyUp={(e) => udfnNameValidation(e)}
														placeholder="Branch Name" maxLength="100" autoFocus
													/>
													<span className="validation-text"></span>
												</div>
											</div>
											<div className="form-group mb-3">
												<label className="text-black font-w500">Address <span className="text-danger">*</span></label>
												<div className="contact-name">
													<textarea className="form-control text-area-udf" placeholder="Address" value={address} onChange={e => setAddress(e.target.value)} onKeyDown={udfnAddressKeyDown} maxLength="250"></textarea>
												</div>
											</div>
											<div className="form-group mb-3">
												<label className="text-black font-w500">City <span className="text-danger">*</span></label>
												<input
													type="text"
													className="form-control"
													value={city}
													onChange={e => setCity(e.target.value)}
													onKeyUp={(e) => udfnNameValidation(e)}
													placeholder="City"
													maxLength="100"
												/>
											</div>
											<div className="form-group mb-3">
												<label className="text-black font-w500">Contact No </label>
												<input
													type="number"
													className="form-control"
													value={contactNo}
													onKeyPress={(e) => udfnPhoneNumberValidation(e)}
													onChange={e => setContactNo(e.target.value)}
													placeholder="Contact No"
												/>
											</div>
											<div className="form-group mb-3">
												<label className="text-black font-w500">Telephone No</label>
												<input
													type="number"
													className="form-control"
													value={telephoneNo}
													onKeyPress={(e) => udfnTelePhoneNumberValidation(e)}
													onKeyDown={handleKeyDown}
													onChange={e => setTelephoneNo(e.target.value)}
													placeholder="Telephone No"
												/>
											</div>
											{editFlag === true ?
												<fieldset className="form-group mb-3">
													<div className="row">
														<label className="col-form-label col-sm-3 pt-1 text-black font-w500">
															Status
														</label>
														<div className="col-sm-2">
															<div className="form-check checkbox-success">
																{status === 1 ? <input
																	className="form-check-input"
																	type="radio"
																	name="gridRadios"
																	onClick={() => setStatusCode(1)}
																	onKeyDown={handleKeyDown}
																	defaultChecked
																/> :
																	<input
																		className="form-check-input"
																		type="radio"
																		name="gridRadios"
																		onClick={() => setStatusCode(1)}
																		onKeyDown={handleKeyDown}
																	/>}
																<label className="form-check-label">
																	Active
																</label>
															</div>
														</div>
														<div className="col-sm-5">
															<div className="form-check checkbox-danger">
																{status === 2 ? <input
																	className="form-check-input"
																	type="radio"
																	name="gridRadios"
																	onClick={() => setStatusCode(2)}
																	onKeyDown={handleKeyDown}
																	defaultChecked
																/> :
																	<input
																		className="form-check-input"
																		type="radio"
																		name="gridRadios"
																		onClick={() => setStatusCode(2)}
																		onKeyDown={handleKeyDown}
																	/>}
																<label className="form-check-label">
																	Inactive
																</label>
															</div>
														</div>
													</div>
												</fieldset> : ''}
										</div>
									</div>
								</div>
								<div className="modal-footer">
									<button type="button" className="btn btn-danger" onClick={() => udfnClearForm()}> <i className="flaticon-delete-1"></i> Discard</button>
									<Button className="me-2" variant="whatsapp" ref={buttonRef} onClick={udfnformValidate} disabled={buttonFlag === false}>
										{editFlag === true ? 'Update' : 'Save'}
									</Button>
								</div>
							</form>

						</div>
					</div>
				</Modal>
				<LoadingOverlay
					active={isLoading}
					spinner
					text='Loading ...'
				>
					<div className="card">
						<div className="card-header">
							<div className='col-sm-5'>
								<h4 className="card-title">Branch Master </h4>
							</div>
							<div className='col-sm-3'>
								<p className="bgl-primary rounded p-3 text-center mb-2"><span className="text-black me-1" >Total Active Branch  :  </span><Badge bg='info badge-rounded' className='text-black font-w400 fs-18'>{branchTotalCount}</Badge></p>
							</div>
							<div className='col-sm-2 me-1'>
								<Link className="btn btn-primary mb-2" onClick={() => openCard()}>
									+ Add Branch
								</Link>
							</div>
						</div>
						<div className="card-body">
							<div className="table-responsive">
								{/* have to add when needed */}
								<div className='row'>
									<div className='col-sm-9'>
										<GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
									</div>
								</div>
								<table {...getTableProps()} className="table filtering-table table-responsive-lg">
									<thead>
										{headerGroups.map(headerGroup => (
											<tr {...headerGroup.getHeaderGroupProps()}>
												{headerGroup.headers.map((column, index) => (
													<th {...column.getHeaderProps()} key={index}>
														{column.render('Header')}
														{column.canFilter ? column.render('Filter') : null}
													</th>
												))}
												<th>Actions</th>
											</tr>
										))}
									</thead>
									<tbody {...getTableBodyProps()} className="" >
										{page.map((row, index) => {
											return (
												<tr key={index}>
													<td>{index + 1}</td>
													<td>{row.original.bm_branch_name}</td>
													<td>{row.original.bm_city}</td>

													<td>
														{row.original.bm_status_code === 1 ? <Badge bg="" className='badge-success light'>
															<span className="me-2">
																<i className="ms-1 fa fa-check" />
															</span>
															{row.original.status}
														</Badge> : <Badge bg="" className='badge-primary light'>
															<span className="me-2">
																<i className="fa fa-times"></i>
															</span>
															{row.original.status}
														</Badge>
														}
													</td>
													<td>
														<Link className="btn btn-secondary	 shadow btn-xs sharp me-2"
															onClick={() => udfnEditLoad(row.original)}
														>
															<i className="fa fa-pencil" id={'edittooltip' + index}></i>
															<UncontrolledTooltip placement="top" target={'edittooltip' + index}>
																Edit
															</UncontrolledTooltip>
														</Link>
														<Link className="btn btn-danger shadow btn-xs sharp" onClick={() => udfnDelete(row.original.bm_branch_code)}>
															<i className="fa fa-trash" id={"deletetooltip" + index} ></i>
															<UncontrolledTooltip placement="top" target={"deletetooltip" + index}>
																Delete
															</UncontrolledTooltip>
														</Link>
													</td>
												</tr>
											)
										})}
									</tbody>
								</table>
								<div className="d-flex justify-content-between">
									<span>
										Page{' '}
										<strong>
											{pageIndex + 1} of {pageOptions.length}
										</strong>{''}
									</span>
									<span className="table-index">
										Go to page : {' '}
										<input type="number"
											className="ms-2"
											defaultValue={pageIndex + 1}
											onChange={e => {
												const pageNumber = e.target.value ? Number(e.target.value) - 1 : 0
												gotoPage(pageNumber)
											}}
										/>
									</span>
								</div>
								<div className="text-center">
									<div className="filter-pagination  mt-3">
										<button className=" previous-button" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>{'<<'}</button>

										<button className="previous-button" onClick={() => previousPage()} disabled={!canPreviousPage}>
											Previous
										</button>
										<button className="next-button" onClick={() => nextPage()} disabled={!canNextPage}>
											Next
										</button>
										<button className=" next-button" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>{'>>'}</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</LoadingOverlay>
			</>
		</>
	)

}
export default BranchMaster;