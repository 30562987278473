import { ColumnFilter } from '../Common/ColumnFilter';
export const COLUMNS = [
	{
		Header: 'S.No.',
		Footer: 'S.No.',
		accessor: 'id',
		Filter: ColumnFilter,
		disableFilters: true,
	},
	{
		Header: 'Date',
		Footer: 'Date',
		accessor: 'bill_date',
		Filter: ColumnFilter,
		disableFilters: true,
	},
	{
		Header: 'Salesman Code',
		Footer: 'Salesman Code',
		accessor: 'salesman_code',
		Filter: ColumnFilter,
		disableFilters: true,
	},
	{
		Header: 'Salesman Name',
		Footer: 'Salesman Name',
		accessor: 'salesman_name',
		Filter: ColumnFilter,
		disableFilters: true,
	},
	{
		Header: 'Total Amount',
		Footer: 'Total Amount',
		accessor: 'amount',
		Filter: ColumnFilter,
		disableFilters: true,
	},
]

export const GROUPED_COLUMNS = [
]