const MASTER_API = {

    // branch api url
    BRANCH_MASTER_LIST: "api/master/getBranchList",
    BRANCH_MASTER_CREATE: "api/master/saveBranch",
    BRANCH_MASTER_UPDATE: "api/master/updateBranch",
    BRANCH_MASTER_DELETE: "api/master/deleteBranch",

    // Company Screen
    COMPANY_MASTER_CREATE: "api/master/saveCompany",

    // Customer Screen
    CUSTOMER_MASTER_LIST: "api/master/getCustomerList",
    CUSTOMER_MASTER_CREATE: "api/master/saveCustomer",
    CUSTOMER_MASTER_UPDATE: "api/master/updateCustomer",
    CUSTOMER_MASTER_DELETE: "api/master/deleteCustomer",

    // brand api url
    BRAND_MASTER_LIST: "api/master/getBrandList",
    BRAND_MASTER_CREATE: "api/master/saveBrand",
    BRAND_MASTER_UPDATE: "api/master/updateBrand",
    BRAND_MASTER_DELETE: "api/master/deleteBrand",

    // unit api url
    UNIT_MASTER_LIST: "api/master/getUnitList",
    UNIT_MASTER_CREATE: "api/master/saveUnit",
    UNIT_MASTER_UPDATE: "api/master/updateUnit",
    UNIT_MASTER_DELETE: "api/master/deleteUnit",

    // user api url
    USER_MASTER_LIST: "api/master/getUserList",
    USER_MASTER_CREATE: "api/master/saveUser",
    USER_MASTER_UPDATE: "api/master/updateUser",
    USER_MASTER_DELETE: "api/master/deleteUser",
    USER_MASTER_PASSWORD_UPDATE: "api/master/updateUserPassword",
    USER_MASTER_PASSWORD_CHANGE: "api/master/changePassword",

    // group api url
    GROUP_MASTER_LIST: "api/master/getGroupList",
    GROUP_MASTER_CREATE: "api/master/saveGroup",
    GROUP_MASTER_UPDATE: "api/master/updateGroup",
    GROUP_MASTER_DELETE: "api/master/deleteGroup",

    // category api url
    CATEGORY_MASTER_LIST: "api/master/getCategoryList",
    CATEGORY_MASTER_CREATE: "api/master/saveCategory",
    CATEGORY_MASTER_UPDATE: "api/master/updateCategory",
    CATEGORY_MASTER_DELETE: "api/master/deleteCategory",


    // Supplier Screen
    SUPPLIER_MASTER_LIST: "api/master/getSupplierList",
    SUPPLIER_MASTER_CREATE: "api/master/saveSupplier",
    SUPPLIER_MASTER_UPDATE: "api/master/updateSupplier",
    SUPPLIER_MASTER_DELETE: "api/master/deleteSupplier",

    // Item Screen
    ITEM_MASTER_LIST: "api/master/getItemList",
    ITEM_MASTER_CREATE: "api/master/saveItem",
    ITEM_MASTER_UPDATE: "api/master/updateItem",
    ITEM_MASTER_DELETE: "api/master/deleteItem",
    ITEM_DISCOUNT_BULK_UPDATE: "api/master/DicountBulkUpdate",
    ITEM_HSN_BULK_UPDATE: "api/master/HSNBulkUpdate",

    // Salesman Screen
    SALESMAN_MASTER_LIST: "api/master/getSalesmanList",
    SALESMAN_MASTER_CREATE: "api/master/saveSalesman",
    SALESMAN_MASTER_UPDATE: "api/master/updateSalesman",
    SALESMAN_MASTER_DELETE: "api/master/deleteSalesman",

    // voucher type  api url
    VOUCHER_TYPE_MASTER_LIST: "api/master/getVoucherTypeList",
    VOUCHER_TYPE_MASTER_CREATE: "api/master/saveVoucher",
    VOUCHER_TYPE_MASTER_UPDATE: "api/master/updateVoucher",
    VOUCHER_TYPE_MASTER_DELETE: "api/master/deleteVoucher",

    // discount bulk update
    BULK_UPDATE_ITEM_LIST: "api/master/getBulkItemList",
    BULK_HSN_UPDATE_ITEM_LIST: "api/master/getHSNBulkItemList",

    BILLING_LIST: "api/report/getBillingList",
    ITEMWISE_SALES_LIST: "api/report/getItemWiseSalesReport",
    ITEMWISE_ANALYSIS_LIST: "api/report/getItemWiseAnalysisReport",
    BUYBACK_LIST: "api/report/getBuybackReport",
    SALESMAN_LIST: "api/report/getSalesmanReport",
    QRCODE_ANALYSIS_LIST: "api/report/getQrCodeAnalysisReport",
    ITEM_PRICE_DETAILS: "api/report/getItemPriceDetails",
    CONSOLIDATE_PROFIT_LIST: "api/report/getConsolidateProfitReport",
    MONTH_WISE_PROFIT_LIST: "api/report/getMonthWiseProfitReport",
};

export default MASTER_API;





