import { format } from 'date-fns';
import { ColumnFilter } from '../Common/ColumnFilter';
export const COLUMNS = [
    {
        Header: 'S.No.',
        Footer: 'User ID',
        accessor: 'id',
        Filter: ColumnFilter,
        disableFilters: true,
    },
    {
        Header: 'User ID',
        Footer: 'User ID',
        accessor: 'um_user_id',
        Filter: ColumnFilter,
        disableFilters: true,
    },
    {
        Header: 'User Name',
        Footer: 'User Name',
        accessor: 'um_username',
        Filter: ColumnFilter,
        disableFilters: true,
    },
    {
        Header: 'User Role',
        Footer: 'User Role',
        accessor: 'ur_user_role_name',
        Filter: ColumnFilter,
        disableFilters: true,
    },
    {
        Header: 'Branch Name',
        Footer: 'Branch Name',
        accessor: 'bm_branch_name',
        Filter: ColumnFilter,
        disableFilters: true,
    },
    {
        Header: 'Status',
        Footer: 'User Status',
        accessor: 'status',
        Filter: ColumnFilter,
        disableFilters: true,
    },
]

export const GROUPED_COLUMNS = [
]