import React, { useMemo, useState, useEffect } from 'react';
import { useTable, useGlobalFilter, useFilters, useSortBy, usePagination } from 'react-table';
import { Button, Accordion, Modal } from 'react-bootstrap';
import { COLUMNS } from '../Filters/ProfitReport/ProfitReportColumns';
import '../Filters/Common/filtering.css';
import "bootstrap-daterangepicker/daterangepicker.css";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { ToastContainer, toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { getLoggedInUser, getRefreshToken } from '../../../services/AuthService';
import COMMONVARIABLES from '../Utilities/constants';
import LoadingOverlay from 'react-loading-overlay-ts';
import { UserLogout } from '../../layouts/nav/Logout';
import MASTER_API from '../../Api/MasterApi';
import Formatting from '../Utilities/format';
import { Link } from "react-router-dom";
import { UncontrolledTooltip } from "reactstrap"
import ERROR_MESSAGE from '../Validation/ErrorMessage';
import moment from 'moment';

const ExcelJS = require('exceljs');

export const ProfitReport = () => {
    const user = getLoggedInUser();
    const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } };
    const BASE_URL = process.env.REACT_APP_BASE_URL;

    const [isLoading, setIsLoading] = useState(false);
    const [range, setRange] = useState(localStorage.getItem('profitReportRange') || null);

    var start_date = localStorage.getItem('profitReportRange') && new Date(localStorage.getItem('profitReportRange').split('-')[0]).toDateString();
    var end_date = localStorage.getItem('profitReportRange') && new Date(localStorage.getItem('profitReportRange').split('-')[1]).toDateString();

    const [rangeDate, setRangeDate] = useState(localStorage.getItem('profitReportRange') && localStorage.getItem('profitReportRange') !== "null" ? { startDate: new Date(start_date), endDate: new Date(end_date) } : { startDate: new Date(), endDate: new Date() })

    const [profitList, setProfitList] = useState([]);
    const [monthWiseProfitList, setMonthWiseProfitList] = useState([]);
    const [addCard, setAddCard] = useState(false);
    const [totalBillAmount, setTotalBillAmount] = useState(false);
    const [totalProfitAmount, setTotalProfitAmount] = useState(false);


    const columns = useMemo(() => COLUMNS, [])
    const data = profitList
    const tableInstance = useTable({
        columns,
        data,
        initialState: { pageIndex: 0 }
    }, useFilters, useGlobalFilter, useSortBy, usePagination)

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        state,
        page,
        gotoPage,
        pageCount,
        pageOptions,
        nextPage,
        previousPage,
        canNextPage,
        canPreviousPage,
    } = tableInstance

    Date.prototype.toDateString = function () {
        var month = this.getMonth() + 1;
        var day = this.getDate();
        var year = this.getFullYear();
        return year + "-" + (month < 10 ? '0' : '') + month + '-' + (day < 10 ? '0' : '') + day;
    };

    const { pageIndex } = state

    async function udfnProfitReport() {
        try {
            setIsLoading(true)
            setProfitList([])
            var varFromDate = new Date().toDateString()
            var varToDate = new Date().toDateString()
            if (range !== "null" && range !== null) {
                varFromDate = new Date(range.split('-')[0]).toDateString()
                varToDate = new Date(range.split('-')[1]).toDateString()
            }

            const params = {
                user_id: Number(user?.id),
                from_date: varFromDate,
                to_date: varToDate,
            };

            const [profitList] = await Promise.all([
                axios.post(BASE_URL + MASTER_API.CONSOLIDATE_PROFIT_LIST, params, config),
            ]);
            setIsLoading(false)

            if (profitList && profitList?.data?.body?.status === COMMONVARIABLES.STATUS.INVALIDUSER) {
                toast.error(profitList?.data?.body?.message, { position: toast.POSITION.TOP_RIGHT });
                UserLogout();
            }

            if (profitList?.data?.body?.status === COMMONVARIABLES.STATUS.UNAUTHORIZED) {
                const refresh = await getRefreshToken();
                if (refresh) {
                    if (Number(refresh) === COMMONVARIABLES.STATUS.EXPIRED_TOKEN) {
                        UserLogout();
                    } else {
                        await udfnProfitReport();
                    }
                }
            }

            if (profitList?.data?.body?.ConsolidateProfitList) {
                setProfitList(profitList?.data?.body?.ConsolidateProfitList)
                console.log(profitList?.data?.body?.ConsolidateProfitList);
            }

        } catch (error) {
            setIsLoading(false)
            console.error('Error fetching master data:', error);
        }
    }

    async function udfnMonthwiseProfitReport(row) {
        try {
            setIsLoading(true)
            setMonthWiseProfitList([])
            setTotalBillAmount(row.ps_total_amount)
            setTotalProfitAmount(row.profit)
            var varFromDate = new Date().toDateString()
            var varToDate = new Date().toDateString()
            if (range !== "null" && range !== null) {
                varFromDate = new Date(range.split('-')[0]).toDateString()
                varToDate = new Date(range.split('-')[1]).toDateString()
            }

            const params = {
                user_id: Number(user?.id),
                month_code: String(row.billing_month_code),
                from_date: varFromDate,
                to_date: varToDate,
            };

            const [profitList] = await Promise.all([
                axios.post(BASE_URL + MASTER_API.MONTH_WISE_PROFIT_LIST, params, config),
            ]);
            setIsLoading(false)

            if (profitList && profitList?.data?.body?.status === COMMONVARIABLES.STATUS.INVALIDUSER) {
                toast.error(profitList?.data?.body?.message, { position: toast.POSITION.TOP_RIGHT });
                UserLogout();
            }

            if (profitList?.data?.body?.status === COMMONVARIABLES.STATUS.UNAUTHORIZED) {
                const refresh = await getRefreshToken();
                if (refresh) {
                    if (Number(refresh) === COMMONVARIABLES.STATUS.EXPIRED_TOKEN) {
                        UserLogout();
                    } else {
                        await udfnMonthwiseProfitReport();
                    }
                }
            }

            if (profitList?.data?.body?.MonthWiseProfitList) {
                setMonthWiseProfitList(profitList?.data?.body?.MonthWiseProfitList)
                var total = 0;
                var profit_total = 0;
                profitList?.data?.body?.MonthWiseProfitList.forEach(row => {
                    total = total + row.ps_total_amount;
                    profit_total = profit_total + row.profit;
                });
                console.log("total", total);
                console.log("profit_total", profit_total);
                setAddCard(true)
                console.log(profitList?.data?.body?.MonthWiseProfitList);
            }

        } catch (error) {
            setIsLoading(false)
            console.error('Error fetching master data:', error);
        }
    }

    const exportToExcel = async () => {
        setIsLoading(true)
        const arraydata = profitList
        var listCount = profitList.length

        if (listCount > 0) {
            // colorWiseTotalDispatch
            let Profit_Report = []

            let total_bill_total_amount = 0
            let total_item_total_amount = 0
            let total_profit = 0

            Profit_Report = arraydata.map((e, index) => {
                total_bill_total_amount = total_bill_total_amount + e.ps_total_amount
                total_item_total_amount = total_item_total_amount + e.item_amount
                total_profit = total_profit + e.profit

                return {
                    "S.No.": index + 1, "Month": e.billing_month, 'Total Bill Amount': COMMONVARIABLES.India_Rupee_Formatter_Digits(Formatting('two_decimal', e.ps_total_amount)), "Total Item Amount": COMMONVARIABLES.India_Rupee_Formatter_Digits(Formatting('two_decimal', e.item_amount)), "Total Profit": COMMONVARIABLES.India_Rupee_Formatter_Digits(Formatting('two_decimal', e.profit))
                }
            })

            const data_array = []
            Profit_Report.forEach((row) => {
                data_array.push(Object.values(row))
            })

            var varFromDate = new Date().toDateString()
            var varToDate = new Date().toDateString()
            if (range !== "null" && range !== null) {
                varFromDate = new Date(range.split('-')[0]).toDateString()
                varToDate = new Date(range.split('-')[1]).toDateString()
            }

            const title = 'Profit Report On '
            const frontside = '('
            const backside = ')'
            const tofrontside = '  to ('
            const reportData = {
                title: title + frontside + moment(new Date(varFromDate)).format("DD-MM-YYYY ") + backside + tofrontside + moment(new Date(varToDate)).format("DD-MM-YYYY") + backside,
                data: data_array,
                headers: Object.keys(Profit_Report[0])
            }

            const workbook = new ExcelJS.Workbook()
            const worksheet = workbook.addWorksheet('itemwise-sales-report')


            worksheet.mergeCells('A1', 'E1')
            const titleRow = worksheet.getCell('A1')
            titleRow.value = reportData['title']
            titleRow.font = {
                name: 'Calibri',
                size: 16,
                bold: true,
                color: { argb: '000000' }
            }
            titleRow.alignment = { vertical: 'middle', horizontal: 'center' }


            const headerRow = worksheet.addRow(reportData['headers'])
            headerRow.eachCell((cell) => {
                cell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'ff8b3d' },
                    bgColor: { argb: 'ff8b3d' }
                }
                cell.font = {
                    bold: true,
                    color: { argb: 'FFFFFF' },
                    size: 12
                }
            })

            data_array.forEach(d => {
                worksheet.addRow(d)
            })

            var format_total_bill_total_amount = COMMONVARIABLES.India_Rupee_Formatter_Digits(Formatting('two_decimal', total_bill_total_amount))
            var format_total_item_total_amount = COMMONVARIABLES.India_Rupee_Formatter_Digits(Formatting('two_decimal', total_item_total_amount))
            var format_total_profit = COMMONVARIABLES.India_Rupee_Formatter_Digits(Formatting('two_decimal', total_profit))

            const total = ['', 'Total', format_total_bill_total_amount, format_total_item_total_amount, format_total_profit]
            const footerRow = worksheet.addRow(total)
            footerRow.eachCell((cell) => {
                cell.font = {
                    bold: true,
                    color: { argb: '000000' },
                    size: 14
                }
            })
            footerRow.alignment = { vertical: 'middle', horizontal: 'right' }

            var array_data_count = data_array.length;

            var starting_cell = 3;
            var last_cell = array_data_count + starting_cell

            for (let i = starting_cell; i <= last_cell; i++) {
                const TotalBillTotalAmountColumn = worksheet.getCell('C' + i)
                const TotalItemTotalAmountColumn = worksheet.getCell('D' + i)
                const TotalProfitColumn = worksheet.getCell('E' + i)

                TotalBillTotalAmountColumn.alignment = { vertical: 'middle', horizontal: 'right' }
                TotalItemTotalAmountColumn.alignment = { vertical: 'middle', horizontal: 'right' }
                TotalProfitColumn.alignment = { vertical: 'middle', horizontal: 'right' }
            }

            worksheet.getColumn(1).width = 5
            worksheet.getColumn(2).width = 20
            worksheet.getColumn(3).width = 20
            worksheet.getColumn(4).width = 20
            worksheet.getColumn(5).width = 20

            // Save the workbook
            const buffer = await workbook.xlsx.writeBuffer()
            const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })

            const url = URL.createObjectURL(blob)
            const a = document.createElement('a')
            a.href = url
            a.download = `Profit Report.xlsx`
            document.body.appendChild(a)
            a.click()
            document.body.removeChild(a)
        } else {
            toast.error(ERROR_MESSAGE.EXPORT_TO_EXCEL_NO_RECORD, { position: toast.POSITION.TOP_RIGHT });
        }
        setIsLoading(false)
    }

    useEffect(() => {
        udfnProfitReport()
    }, []); // eslint-disable-line react-hooks/exhaustive-deps   

    useEffect(() => {
        localStorage.setItem('profitReportRange', range)
    }, [range])  // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <Modal className="fade bd-example-modal-xl" show={addCard} onHide={setAddCard} backdrop="static" size='xl'>
                <div className="" role="document">
                    <div className="">
                        <form >
                            <div className="modal-header">
                                <div className='row profit-report-row'>
                                    <div className='col-sm-5'>
                                        <h4 className="card-title mt-4">MonthWise Profit Report</h4>
                                    </div>
                                    <div className='col-lg-3 me-2'>
                                        <div className="bg-success coin-holding flex-wrap px-1">
                                            <div className="coin-bx">
                                                <div className="d-flex align-items-center">
                                                    <div className="ms-3">
                                                        <h4 className="coin-font font-w600 mb-2 text-white">Total Bill Amount</h4>
                                                        <p className="mb-0 text-white op-6"><i className="fa fa-rupee udfn-rupee-icon profit-rupeeicon me-2"></i>{COMMONVARIABLES.India_Rupee_Formatter_Digits(Formatting('two_decimal', totalBillAmount))}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-3'>
                                        <div className="bg-secondary coin-holding flex-wrap px-1">
                                            <div className="coin-bx">
                                                <div className="d-flex align-items-center">
                                                    <div className="ms-3">
                                                        <h4 className="coin-font font-w600 mb-2 text-white">Total Profit Amount</h4>
                                                        <p className="mb-0 text-white op-6"><i className="fa fa-rupee udfn-rupee-icon profit-rupeeicon me-2"></i>{COMMONVARIABLES.India_Rupee_Formatter_Digits(Formatting('two_decimal', totalProfitAmount))}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-sm-1'>
                                        <button type="button" className="close btn" onClick={() => setAddCard(false)}><span>×</span></button>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-body">
                                <i className="flaticon-cancel-12 close" data-dismiss="modal"></i>
                                <div className="add-contact-box">
                                    <div className="add-contact-content">
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th>
                                                        S.NO.
                                                    </th>
                                                    <th>
                                                        Date
                                                    </th>
                                                    <th>
                                                        Bill No
                                                    </th>
                                                    <th>
                                                        Bill Amount
                                                    </th>
                                                    <th>
                                                        Item Amount
                                                    </th>
                                                    <th>
                                                        Profit
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody className="" >
                                                {monthWiseProfitList.map((row, index) => {
                                                    return (
                                                        <tr>
                                                            <td>{index + 1}</td>
                                                            <td>{row.bill_date}</td>
                                                            <td>{row.ps_bill_text}</td>
                                                            <td className='text-end'><i className="fa fa-rupee udfn-rupee-icon rupeeicon"></i>{COMMONVARIABLES.India_Rupee_Formatter_Digits(Formatting('two_decimal', row.ps_total_amount))}</td>
                                                            <td className='text-end'><i className="fa fa-rupee udfn-rupee-icon rupeeicon"></i>{COMMONVARIABLES.India_Rupee_Formatter_Digits(Formatting('two_decimal', row.item_amount))}</td>
                                                            <td className='text-end'><i className="fa fa-rupee udfn-rupee-icon rupeeicon"></i>{COMMONVARIABLES.India_Rupee_Formatter_Digits(Formatting('two_decimal', row.profit))}</td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <Button className="me-2" variant="whatsapp" onClick={() => { setAddCard(false) }}>
                                    Discard
                                </Button>
                            </div>
                        </form>
                    </div>
                </div>
            </Modal>
            <LoadingOverlay
                active={isLoading}
                spinner
                text='Loading ...'
            >
                <ToastContainer />
                <div className="card">
                    <div className="card-header">
                        <div className='col-sm-5'>
                            <h4 className="card-title">Profit Report</h4>
                        </div>
                        <div className='cash-total col-sm-4 text-center'>
                            <Button variant="info" className="mb-2" onClick={exportToExcel}>
                                <i className="fa fa-download" /> &nbsp; Export
                            </Button>
                        </div>
                    </div>
                    <div className="card-body">
                        <Accordion className="accordion accordion-rounded-stylish accordion-bordered  accordion-active-header" defaultActiveKey="0">
                            <Accordion.Item>
                                <Accordion.Header className="accordion-header accordion-header-primary">
                                    <h5>Filters</h5>
                                    <span className="accordion-header-indicator "></span>
                                </Accordion.Header>
                                <Accordion.Collapse className="accordion__body">
                                    <div className="accordion-body">
                                        <div className="row">
                                            <div className="col-md-4 mb-3">
                                                <div className="example rangeDatePicker">
                                                    <p className="mb-1">Select From Date And To Date</p>
                                                    <DateRangePicker
                                                        onApply={(e) => {
                                                            setRange(e.target.value)
                                                        }}
                                                        initialSettings={rangeDate}
                                                    >
                                                        <input type="text" className="form-control input-daterange-timepicker" styles={{ height: "90%" }} />
                                                    </DateRangePicker>
                                                </div>
                                            </div>
                                            <div className="col-md-2 mt-4">
                                                <Button className="me-2" variant="whatsapp" style={{ marginTop: "6%" }} onClick={udfnProfitReport}>
                                                    List{" "}
                                                    <span className="btn-icon-right">
                                                        <i className="fa fa-filter" />
                                                    </span>
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </Accordion.Collapse>
                            </Accordion.Item>
                        </Accordion>
                        <div className='row mt-5'>
                            <div className="table-responsive">
                                <div className='row'>
                                    <div className='col-sm-9'>
                                        {/* <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} /> */}
                                    </div>
                                </div>
                                <table {...getTableProps()} className="table filtering-table table-responsive-lg">
                                    <thead>
                                        {headerGroups.map(headerGroup => (
                                            <tr {...headerGroup.getHeaderGroupProps()}>
                                                <th>
                                                    <p className='tbl-heading-txt'>S.NO.</p>
                                                </th>
                                                {headerGroup.headers.map((column, index) => (
                                                    <>
                                                        {
                                                            <th key={index}>
                                                                {column.render('Header')}
                                                                {column.canFilter ? column.render('Filter') : null}
                                                            </th>
                                                        }
                                                    </>
                                                ))}
                                            </tr>
                                        ))}
                                    </thead>
                                    <tbody {...getTableBodyProps()} className="" >
                                        {page.map((row, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>{index + 1}</td>
                                                    <td>{row.original.billing_month}</td>
                                                    <td className='text-end'><i className="fa fa-rupee udfn-rupee-icon rupeeicon"></i>{COMMONVARIABLES.India_Rupee_Formatter_Digits(Formatting('two_decimal', row.original.ps_total_amount))}</td>
                                                    <td className='text-end'><i className="fa fa-rupee udfn-rupee-icon rupeeicon"></i>{COMMONVARIABLES.India_Rupee_Formatter_Digits(Formatting('two_decimal', row.original.item_amount))}</td>
                                                    <td className='text-end'><i className="fa fa-rupee udfn-rupee-icon rupeeicon"></i>{COMMONVARIABLES.India_Rupee_Formatter_Digits(Formatting('two_decimal', row.original.profit))}</td>
                                                    <td>
                                                        <Link className="btn btn-secondary shadow btn-xs sharp me-2"
                                                            onClick={() => udfnMonthwiseProfitReport(row.original)}
                                                        >
                                                            <i className="fa fa-eye" id={'edittooltip' + index}></i>
                                                            <UncontrolledTooltip placement="top" target={'edittooltip' + index}>
                                                                View
                                                            </UncontrolledTooltip>
                                                        </Link>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                                <div className='text-center'>
                                    {profitList.length < 1 ? "No records found." : ""}
                                </div>
                                <div className="d-flex justify-content-between">
                                    <span>
                                        Page{' '}
                                        <strong>
                                            {pageIndex + 1} of {pageOptions.length}
                                        </strong>{''}
                                    </span>
                                    <span className="table-index">
                                        Go to page : {' '}
                                        <input type="number"
                                            className="ms-2"
                                            defaultValue={pageIndex + 1}
                                            onChange={e => {
                                                const pageNumber = e.target.value ? Number(e.target.value) - 1 : 0
                                                gotoPage(pageNumber)
                                            }}
                                        />
                                    </span>
                                </div>
                                <div className="text-center">
                                    <div className="filter-pagination  mt-3">
                                        <button className=" previous-button" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>{'<<'}</button>

                                        <button className="previous-button" onClick={() => previousPage()} disabled={!canPreviousPage}>
                                            Previous
                                        </button>
                                        <button className="next-button" onClick={() => nextPage()} disabled={!canNextPage}>
                                            Next
                                        </button>
                                        <button className=" next-button" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>{'>>'}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </LoadingOverlay>
        </>
    )
}

export default ProfitReport;