import React, { Fragment, useContext, useEffect } from 'react';
import loadable from "@loadable/component";
import pMinDelay from "p-min-delay";
import { ThemeContext } from "../../../context/ThemeContext";
import { Row, Col, Card } from "react-bootstrap";
//Images
import Card1 from './../../../images/card/card1.jpg';
import Card2 from './../../../images/card/card2.jpg';
import Card3 from './../../../images/card/card3.jpg';
import Card4 from './../../../images/card/card4.jpg';
import BranchWiseSalesBarChat from './Chart/BranchWiseSalesBarChat';


const VoucherTypeSalesChat = loadable(() => pMinDelay(import("./Chart/VoucherTypeWiseSalesBarChart"), 1000));
const MonthWiseSalesChat = loadable(() => pMinDelay(import("./Chart/MonthSalesBarChat"), 1000));
const TopFiveSalesChat = loadable(() => pMinDelay(import("./Chart/TopFiveSalesItemBarChat"), 1000));
const YearWiseSalesChat = loadable(() => pMinDelay(import("./Chart/YearWiseSalesBarChat"), 1000));

const cardBox = [
	{ image: Card1 },
	{ image: Card2 },
	{ image: Card3 },
	{ image: Card4 },
];

const orderdataBlog = [
	{ price: '82.1', amount: '58.9', total: '134.10', },
	{ price: '85.2', amount: '55.8', total: '136,12', },
	{ price: '87.3', amount: '53.7', total: '138,12', },
	{ price: '89.4', amount: '51.6', total: '139,12', },
	{ price: '91.9', amount: '47.1', total: '140,12', },
	{ price: '93.8', amount: '46.2', total: '142,12', },
	{ price: '94.7', amount: '45.3', total: '145,12', },
	{ price: '97.6', amount: '44.4', total: '147,12', },
];

const Home = () => {
	const { changeBackground } = useContext(ThemeContext);
	const currentYear = new Date().getFullYear();
	useEffect(() => {

		// changeBackground({ value: "dark", label: "Dark" });
		changeBackground({ value: "light", label: "Light" });

	}, []);


	return (
		<Fragment>
			{/* <div className="form-head mb-sm-5 mb-3 d-flex flex-wrap align-items-center"> */}
			<div className="h-80">
				<Row>
					<Col xl={6} lg={6}>
						<Card>
							<Card.Header>
								<h4 className="card-title">VoucherType Wise Sales</h4>
							</Card.Header>
							<Card.Body>
								<VoucherTypeSalesChat />
							</Card.Body>
						</Card>
					</Col>
					<Col xl={6} lg={6}>
						<Card>
							<Card.Header>
								<h4 className="card-title">Branch Wise Sales</h4>
							</Card.Header>
							<Card.Body>
								<BranchWiseSalesBarChat />
							</Card.Body>
						</Card>
					</Col>
				</Row>
				<Row>
					<Col xl={12} lg={12}>
						<Card>
							<Card.Header>
								<h4 className="card-title">Month Wise Sales</h4>
							</Card.Header>
							<Card.Body>
								<MonthWiseSalesChat />
							</Card.Body>
						</Card>
					</Col>
				</Row>
				<Row>
					<Col xl={12} lg={12}>
						<Card>
							<Card.Header>
								<h4 className="card-title">Top 5 Sales Item</h4>
							</Card.Header>
							<Card.Body>
								<TopFiveSalesChat />
							</Card.Body>
						</Card>
					</Col>
				</Row>
				<Row>
					<Col xl={12} lg={12}>
						<Card>
							<Card.Header>
								<h4 className="card-title">Year Wise Sales - {currentYear}</h4>
							</Card.Header>
							<Card.Body>
								<YearWiseSalesChat />
							</Card.Body>
						</Card>
					</Col>
				</Row>
			</div>
			{/* </div> */}

		</Fragment>
	)

}
export { orderdataBlog };
export default Home;