import { ColumnFilter } from '../Common/ColumnFilter';
export const COLUMNS = [
    // {
    // 	Header: 'S.No.',
    // 	Footer: 'S.No.',
    // 	accessor: 'id',
    // 	Filter: ColumnFilter,
    // 	// disableFilters: true,
    // },
    {
		Header: 'Supplier Name',
		Footer: 'Supplier Name',
		accessor: 'sm_supplier_name',
		Filter: ColumnFilter,
		// disableFilters: true,
	},
    {
        Header: 'Item Group',
        Footer: 'Item Group',
        accessor: 'group_name',
        Filter: ColumnFilter,
        // disableFilters: true,
    },
    {
        Header: 'Item Category',
        Footer: 'Item Category',
        accessor: 'category_name',
        Filter: ColumnFilter,
        // disableFilters: true,
    },
    {
        Header: 'Brand',
        Footer: 'Brand',
        accessor: 'brand_name',
        Filter: ColumnFilter,
        // disableFilters: true,
    },
   
    {
        Header: 'Item Name',
        Footer: 'Item Name',
        accessor: 'item_name',
        Filter: ColumnFilter,
        // disableFilters: true,
    },
    {
        Header: 'Qr Code',
        Footer: 'Qr Code',
        accessor: 'qr_code',
        Filter: ColumnFilter,
        // disableFilters: true,
    },
    {
        Header: 'Current Stock',
        Footer: 'Current Stock',
        accessor: 'current_stock',
        Filter: ColumnFilter,
        // disableFilters: true,
    },
    {
        Header: 'Purchase Rate',
        Footer: 'Purchase Rate',
        accessor: 'qs_rate',
        Filter: ColumnFilter,
        // disableFilters: true,
    },
    {
        Header: 'Total Amount',
        Footer: 'Total Amount',
        accessor: 'total_amount',
        Filter: ColumnFilter,
        // disableFilters: true,
    },
]

export const GROUPED_COLUMNS = [
]