import React, { useMemo, useState, useEffect } from 'react';
import { useTable, useGlobalFilter, useFilters, useSortBy, usePagination } from 'react-table';
import { COLUMNS } from '../Filters/AgingStockReport/AgingStockReportColumns';
import { Button, Accordion } from 'react-bootstrap';
import '../Filters/Common/filtering.css';
import "bootstrap-daterangepicker/daterangepicker.css";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { getLoggedInUser, getRefreshToken } from '../../../services/AuthService';
import { UserLogout } from '../../layouts/nav/Logout';
import COMMONVARIABLES from '../Utilities/constants';
import COMMON_API from '../../Api/CommonApi';
import LoadingOverlay from 'react-loading-overlay-ts';
import STOCK_REPORT_API from '../../Api/StockReportApi';
import ERROR_MESSAGE from '../Validation/ErrorMessage';
import Formatting from '../Utilities/format';
const ExcelJS = require('exceljs');

export const AgingStockReport = () => {
    const user = getLoggedInUser()
    const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } };

    const [excelButtonFlag, setExcelButtonFlag] = useState(false);
    const columns = useMemo(() => COLUMNS, [])
    const [agingStockList, setAgingStockList] = useState([])
    const [agingStockListFiltered, setAgingStockListFiltered] = useState([])
    const data = agingStockListFiltered
    const [totalAmount, setTotalAmount] = useState(0)
    const tableInstance = useTable({
        columns,
        data,
        initialState: { pageIndex: 0 }
    }, useFilters, useGlobalFilter, useSortBy, usePagination)

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        state,
        page,
        gotoPage,
        pageCount,
        pageOptions,
        nextPage,
        previousPage,
        canNextPage,
        canPreviousPage,
    } = tableInstance

    const { pageIndex } = state

    Date.prototype.toDateString = function () {
        var month = this.getMonth() + 1;
        var day = this.getDate();
        var year = this.getFullYear();
        return year + "-" + (month < 10 ? '0' : '') + month + '-' + (day < 10 ? '0' : '') + day;
    };

    const [isLoading, setIsLoading] = useState(false);
    const currentDate = new Date().toDateString();
    const [transferDate, setTransferDate] = useState(localStorage.getItem('agingStockReportDate') || currentDate);

    const [branchName, setBranchName] = useState(JSON.parse(localStorage.getItem('agingStockReportBranchName')) || { label: "All", value: 0 });
    const [branchNameOptions, setBranchNameOptions] = useState([])
    const [groupName, setGroupName] = useState(JSON.parse(localStorage.getItem('agingStockReportGroupName')) || { label: "All", value: 0 });
    const [itemGroupNameOptions, setItemGroupNameOptions] = useState([])
    const [categoryName, setCategoryName] = useState(JSON.parse(localStorage.getItem('agingStockReportCategoryName')) || { label: "All", value: 0 });
    const [categoryNameOptions, setCategoryNameOptions] = useState([])
    const [brandName, setBrandName] = useState(JSON.parse(localStorage.getItem('agingStockReportBrandName')) || { label: "All", value: 0 });
    const [brandNameOptions, setBrandNameOptions] = useState([])
    const [itemName, setItemName] = useState(JSON.parse(localStorage.getItem('agingStockReportItemName')) || { label: "All", value: 0 });
    const [itemNameOptions, setItemNameOptions] = useState([])
    const [supplierName, setSupplierName] = useState(JSON.parse(localStorage.getItem('agingStockReportSupplierName')) || { label: "All", value: 0 })
    const [supplierNameOptions, setSupplierNameOptions] = useState([])

    const [qrcodeWise, setQrcodeWise] = useState(localStorage.getItem('agingStockReportQrcodeWise') || true)
    const [supplierWise, setSupplierWise] = useState(localStorage.getItem('agingStockReportSupplierWise') || true)

    async function fetchMasterDataWithParams() {
        try {
            setIsLoading(true)
            const branchParams = { status_code: 0, user_id: user?.id };
            const categoryParams = { status_code: 0, user_id: user?.id };
            const itemGroupParams = { status_code: 0, user_id: user?.id };
            const brandParams = { status_code: 0, user_id: user?.id };
            const itemParams = { status_code: 0, user_id: user?.id };
            const supplierParams = { status_code: 0, user_id: user?.id };

            const [branchData, categoryData, itemGroupData, brandData, itemData, supplierData] = await Promise.all([
                axios.post(process.env.REACT_APP_BASE_URL + COMMON_API.BRANCH_MASTER_LIST, branchParams, config),
                axios.post(process.env.REACT_APP_BASE_URL + COMMON_API.CATEGORY_LIST, categoryParams, config),
                axios.post(process.env.REACT_APP_BASE_URL + COMMON_API.GET_GROUP_LIST, itemGroupParams, config),
                axios.post(process.env.REACT_APP_BASE_URL + COMMON_API.BRAND_MASTER_LIST, brandParams, config),
                axios.post(process.env.REACT_APP_BASE_URL + COMMON_API.ITEM_MASTER_LIST, itemParams, config),
                axios.post(process.env.REACT_APP_BASE_URL + COMMON_API.SUPPLIER_MASTER_LIST, supplierParams, config)
            ]);

            if (branchData?.data?.body?.status === COMMONVARIABLES.STATUS.INVALIDUSER || categoryData?.data?.body?.status === COMMONVARIABLES.STATUS.INVALIDUSER || itemGroupData?.data?.body?.status === COMMONVARIABLES.STATUS.INVALIDUSER || brandData?.data?.body?.status === COMMONVARIABLES.STATUS.INVALIDUSER || itemData?.data?.body?.status === COMMONVARIABLES.STATUS.INVALIDUSER || supplierData?.data?.body?.status === COMMONVARIABLES.STATUS.INVALIDUSER) {
                setIsLoading(false)
                var err = branchData?.data?.body?.message || categoryData?.data?.body?.message || itemGroupData?.data?.body?.message || brandData?.data?.body?.message || itemData?.data?.body?.message || supplierData?.data?.body?.message;
                toast.error(err, { position: toast.POSITION.TOP_RIGHT });
                UserLogout();
            }

            if (branchData?.data?.body?.status === COMMONVARIABLES.STATUS.UNAUTHORIZED || categoryData?.data?.body?.status === COMMONVARIABLES.STATUS.UNAUTHORIZED || itemGroupData?.data?.body?.status === COMMONVARIABLES.STATUS.UNAUTHORIZED || brandData?.data?.body?.status === COMMONVARIABLES.STATUS.UNAUTHORIZED || itemData?.data?.body?.status === COMMONVARIABLES.STATUS.UNAUTHORIZED || supplierData?.data?.body?.status === COMMONVARIABLES.STATUS.UNAUTHORIZED) {
                setIsLoading(false)
                const refresh = await getRefreshToken();
                if (refresh) {
                    if (Number(refresh) === COMMONVARIABLES.STATUS.EXPIRED_TOKEN) {
                        UserLogout();
                    } else {
                        await fetchMasterDataWithParams();
                    }
                }
            }
            else if (branchData?.data?.body?.branchList || categoryData?.data?.body?.categoryList || itemGroupData?.data?.body?.groupList || brandData?.data?.body?.brandList || itemData?.data?.body?.itemList || supplierData?.data?.body?.itemList) {
                setBranchNameOptions([{ bm_branch_name: "All", bm_branch_code: 0 }, ...branchData?.data?.body?.branchList])
                setItemNameOptions([{ im_display_name: "All", im_item_code: 0 }, ...itemData?.data?.body?.itemList])
                var filtArray = brandData?.data?.body?.brandList.filter(item => item.bm_brand_code !== 0)
                setBrandNameOptions([{ bm_brand_name: "All", bm_brand_code: 0 }, ...filtArray])
                setCategoryNameOptions([{ cm_category_name: "All", cm_category_code: 0 }, ...categoryData?.data?.body?.categoryList])
                setItemGroupNameOptions([{ gm_group_name: "All", gm_group_code: 0 }, ...itemGroupData?.data?.body?.groupList])
                setSupplierNameOptions([{ sm_supplier_name: "All", sm_supplier_code: 0 }, ...supplierData?.data?.body?.supplierList])
            }
        } catch (error) {
            setIsLoading(false)
            console.error('Error fetching master data:', error);
            if (error.code === COMMONVARIABLES.STATUS.NETWORK_ERROR_STATUS) {
                toast.error(ERROR_MESSAGE.API_ERROR.NETWORK_ERROR, { position: toast.POSITION.TOP_RIGHT });
            }
            if (error.code === COMMONVARIABLES.STATUS.BAD_REQUEST_ERROR_STATUS) {
                toast.error(ERROR_MESSAGE.API_ERROR.BAD_REQUEST_ERROR, { position: toast.POSITION.TOP_RIGHT });
            }
        }
    }

    async function udfnAgingStockReport() {
        try {
            setIsLoading(true)
            setAgingStockList([])
            setAgingStockListFiltered([])
            setTotalAmount(0)

            const params = { user_id: user?.id, item_code: itemName.value, branch_code: branchName.value, category_code: categoryName.value, group_code: groupName.value, brand_code: brandName.value, date: transferDate, supplier_code: supplierName.value };

            const [agingStockList] = await Promise.all([
                axios.post(process.env.REACT_APP_BASE_URL + STOCK_REPORT_API.AGING_STOCK_REPORT, params, config),

            ]);

            if (agingStockList && agingStockList?.data?.body?.status === COMMONVARIABLES.STATUS.INVALIDUSER) {
                setIsLoading(false)
                toast.error(agingStockList?.data?.body?.message, { position: toast.POSITION.TOP_RIGHT });
                UserLogout();
            }

            if (agingStockList?.data?.body?.status === COMMONVARIABLES.STATUS.UNAUTHORIZED) {
                setIsLoading(false)
                const refresh = await getRefreshToken();
                if (refresh) {
                    if (Number(refresh) === COMMONVARIABLES.STATUS.EXPIRED_TOKEN) {
                        UserLogout();
                    } else {
                        await udfnAgingStockReport();
                    }
                }
            }

            if (agingStockList?.data?.body?.status === COMMONVARIABLES.STATUS.SERVICE_CATCH_STATUS) {
                setIsLoading(false)
                toast.error(ERROR_MESSAGE.API_ERROR.SERVICE_CATCH_ERROR, { position: toast.POSITION.TOP_RIGHT });
            }

            if (agingStockList?.data?.body?.agingStockReportList) {
                setAgingStockList(agingStockList?.data?.body?.agingStockReportList)
                setAgingStockListFiltered(agingStockList?.data?.body?.agingStockReportList)
                var total = 0;
                agingStockList?.data?.body?.agingStockReportList.forEach(row => {
                    total = total + row.total_amount;
                });
                setTotalAmount(COMMONVARIABLES.India_Rupee_Formatter_Digits(Formatting('two_decimal', total)))
                setIsLoading(false)
                setQrcodeWise(true)
                setSupplierWise(true)
            }
        } catch (error) {
            setIsLoading(false)
            console.error('Error fetching master data:', error);
            if (error.code === COMMONVARIABLES.STATUS.NETWORK_ERROR_STATUS) {
                toast.error(ERROR_MESSAGE.API_ERROR.NETWORK_ERROR, { position: toast.POSITION.TOP_RIGHT });
            }
            if (error.code === COMMONVARIABLES.STATUS.BAD_REQUEST_ERROR_STATUS) {
                toast.error(ERROR_MESSAGE.API_ERROR.BAD_REQUEST_ERROR, { position: toast.POSITION.TOP_RIGHT });
            }
        }
    }

    function udfnAgingStockReportFiltered() {
        var dataArray = agingStockList
        setIsLoading(true)
        if ((supplierWise === true && qrcodeWise === true) || (supplierWise === false && qrcodeWise === true)) {
            setAgingStockListFiltered(dataArray)
            var total = 0;
            dataArray.forEach(row => {
                total = total + row.total_amount;
            });
            setTotalAmount(COMMONVARIABLES.India_Rupee_Formatter_Digits(Formatting('two_decimal', total)))
        }

        if (supplierWise === false && qrcodeWise === false) {
            // Remove the qrcode column
            const cleanedData = dataArray.map(({ qrcode, ...rest }) => rest);
            const bothUnCheck = Object.values(cleanedData.reduce((acc, item) => {
                const { item_code, current_stock, qs_rate } = item;
                if (!acc[item_code]) {
                    acc[item_code] = { ...item }; // Copy the object
                } else {
                    // If item_code is equal, perform addition for current_stock
                    acc[item_code].current_stock += current_stock;
                    // Multiply current_stock by qs_rate and update total_amount
                    acc[item_code].total_amount = acc[item_code].current_stock * qs_rate;
                }
                return acc;
            }, {}));
            setAgingStockListFiltered(bothUnCheck)
            var total = 0;
            bothUnCheck.forEach(row => {
                total = total + row.total_amount;
            });
            setTotalAmount(COMMONVARIABLES.India_Rupee_Formatter_Digits(Formatting('two_decimal', total)))
            // return bothUnCheck;
        }

        if (supplierWise === true && qrcodeWise === false) {
            // Remove the qrcode column
            const cleanedData = dataArray.map(({ qrcode, ...rest }) => rest);
            const onlySupplierCheck = Object.values(cleanedData.reduce((acc, item) => {
                const { item_code, pr_supplier_code, current_stock, qs_rate } = item;
                const key = `${pr_supplier_code}_${item_code}`;

                if (!acc[key]) {
                    acc[key] = { ...item }; // Copy the object
                } else {
                    // If pr_supplier_code and item_code are equal, perform addition for current_stock
                    acc[key].current_stock += current_stock;
                    // Multiply current_stock by qs_rate and update total_amount
                    acc[key].total_amount = acc[key].current_stock * acc[key].qs_rate;
                }
                return acc;
            }, {}));
            setAgingStockListFiltered(onlySupplierCheck)
            var total = 0;
            onlySupplierCheck.forEach(row => {
                total = total + row.total_amount;
            });
            setTotalAmount(COMMONVARIABLES.India_Rupee_Formatter_Digits(Formatting('two_decimal', total)))
            // return onlySupplierCheck;
        }
        setIsLoading(false)
    }

    const exportToExcel = async () => {

        const array_data = agingStockListFiltered
        const array_data_count = agingStockListFiltered.length

        if (array_data_count > 0) {
            setIsLoading(true)
            setExcelButtonFlag(true)

            let Aging_Stock_Report = []

            let total_current_stock = 0
            let total_purchase_rate = 0
            let total_amount = 0

            Aging_Stock_Report = array_data.map((item, index) => {
                var rate = COMMONVARIABLES.India_Rupee_Formatter_Digits(Formatting('two_decimal', item.qs_rate))
                var amount = COMMONVARIABLES.India_Rupee_Formatter_Digits(Formatting('two_decimal', item.total_amount))
                total_current_stock = total_current_stock + item.current_stock
                total_purchase_rate = total_purchase_rate + item.qs_rate
                total_amount = total_amount + item.total_amount

                if ((qrcodeWise === true && supplierWise === true) || (qrcodeWise === true && supplierWise === false)) {
                    return {
                        "S.No.": index + 1, "Supplier Name": item.sm_supplier_name, "Item Group": item.group_name, "Item Category": item.category_name, "Brand": item.brand_name ? item.brand_name : "-", "Item Name": item.item_name, "QrCode": item.qr_code, "No. Of Days": item.noofdays, "Current Stock": item.current_stock, "Purchase Rate": rate, "Total Amount": amount
                    }
                } else {
                    return {
                        "S.No.": index + 1, "Supplier Name": item.sm_supplier_name, "Item Group": item.group_name, "Item Category": item.category_name, "Brand": item.brand_name ? item.brand_name : "-", "Item Name": item.item_name, "No. Of Days": item.noofdays, "Current Stock": item.current_stock, "Purchase Rate": rate, "Total Amount": amount
                    }
                }
            })


            const data_array = []
            Aging_Stock_Report.forEach((row) => {
                data_array.push(Object.values(row))
            })

            const title = 'Aging Stock Report On ' + transferDate

            const reportData = {
                title: title,
                data: data_array,
                headers: Object.keys(Aging_Stock_Report[0])
            }

            const workbook = new ExcelJS.Workbook()
            const worksheet = workbook.addWorksheet("Aging Stock")

            if ((qrcodeWise === true && supplierWise === true) || (qrcodeWise === true && supplierWise === false)) {
                worksheet.mergeCells('A1', 'K1')
            } else {
                worksheet.mergeCells('A1', 'J1')
            }
            const titleRow = worksheet.getCell('A1')
            titleRow.value = reportData['title']
            titleRow.font = {
                name: 'Calibri',
                size: 16,
                bold: true,
                color: { argb: '000000' }
            }
            titleRow.alignment = { vertical: 'middle', horizontal: 'center' }

            worksheet.mergeCells('A2', 'E2')
            const branch_head = 'Branch : '
            const branch_val = branchName.label ? branchName.label : 'All'
            const branchRow = worksheet.getCell('A2')
            branchRow.value = branch_head + branch_val
            branchRow.font = {
                name: 'Calibri',
                size: 12,
                color: { argb: '00000' }
            }
            branchRow.alignment = { vertical: 'left', horizontal: 'left' }

            if ((qrcodeWise === true && supplierWise === true) || (qrcodeWise === true && supplierWise === false)) {
                worksheet.mergeCells('F2', 'K2')
            } else {
                worksheet.mergeCells('F2', 'J2')
            }
            const item_group_head = 'Item Group : '
            const item_group_val = groupName.label ? groupName.label : 'All'
            const itemGroupRow = worksheet.getCell('F2')
            itemGroupRow.value = item_group_head + item_group_val
            itemGroupRow.font = {
                name: 'Calibri',
                size: 12,
                color: { argb: '00000' }
            }
            itemGroupRow.alignment = { vertical: 'left', horizontal: 'left' }

            worksheet.mergeCells('A3', 'E3')
            const item_category_head = 'Item Category : '
            const item_category_val = branchName.label ? branchName.label : 'All'
            const itemCategoryRow = worksheet.getCell('A3')
            itemCategoryRow.value = item_category_head + item_category_val
            itemCategoryRow.font = {
                name: 'Calibri',
                size: 12,
                color: { argb: '00000' }
            }
            itemCategoryRow.alignment = { vertical: 'left', horizontal: 'left' }

            if ((qrcodeWise === true && supplierWise === true) || (qrcodeWise === true && supplierWise === false)) {
                worksheet.mergeCells('F3', 'K3')
            } else {
                worksheet.mergeCells('F3', 'J3')
            }
            const brand_head = 'Brand : '
            const brand_val = brandName.label ? brandName.label : 'All'
            const brandRow = worksheet.getCell('F3')
            brandRow.value = brand_head + brand_val
            brandRow.font = {
                name: 'Calibri',
                size: 12,
                color: { argb: '00000' }
            }
            brandRow.alignment = { vertical: 'left', horizontal: 'left' }

            worksheet.mergeCells('A4', 'E4')
            const item_name_head = 'Item Name : '
            const item_name_val = itemName.label ? itemName.label : 'All'
            const itemNameRow = worksheet.getCell('A4')
            itemNameRow.value = item_name_head + item_name_val
            itemNameRow.font = {
                name: 'Calibri',
                size: 12,
                color: { argb: '00000' }
            }
            itemNameRow.alignment = { vertical: 'left', horizontal: 'left' }

            if ((qrcodeWise === true && supplierWise === true) || (qrcodeWise === true && supplierWise === false)) {
                worksheet.mergeCells('F4', 'K4')
            } else {
                worksheet.mergeCells('F4', 'J4')
            }
            const supplier_head = 'Supplier : '
            const supplier_val = supplierName.label ? supplierName.label : 'All'
            const supplierRow = worksheet.getCell('F4')
            supplierRow.value = supplier_head + supplier_val
            supplierRow.font = {
                name: 'Calibri',
                size: 12,
                color: { argb: '00000' }
            }
            supplierRow.alignment = { vertical: 'left', horizontal: 'left' }

            const headerRow = worksheet.addRow(reportData['headers'])
            headerRow.eachCell((cell) => {
                cell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'ff8b3d' },
                    bgColor: { argb: 'ff8b3d' }
                }
                cell.font = {
                    bold: true,
                    color: { argb: 'FFFFFF' },
                    size: 12
                }
            })

            data_array.forEach(d => {
                worksheet.addRow(d)
            })

            var format_total_purchase_rate = COMMONVARIABLES.India_Rupee_Formatter_Digits(Formatting('two_decimal', total_purchase_rate))
            var format_total_amount = COMMONVARIABLES.India_Rupee_Formatter_Digits(Formatting('two_decimal', total_amount))

            const total = (qrcodeWise === true && supplierWise === true) || (qrcodeWise === true && supplierWise === false) ? ['', '', '', '', '', '', '', 'Total', total_current_stock, format_total_purchase_rate, format_total_amount] : ['', '', '', '', '', '', 'Total', total_current_stock, format_total_purchase_rate, format_total_amount]
            const footerRow = worksheet.addRow(total)
            footerRow.eachCell((cell) => {
                cell.font = {
                    bold: true,
                    color: { argb: '000000' },
                    size: 14
                }
            })

            var starting_cell = 5;
            var last_cell = array_data_count + starting_cell + 1

            for (let i = starting_cell; i <= last_cell; i++) {
                if ((qrcodeWise === true && supplierWise === true) || (qrcodeWise === true && supplierWise === false)) {
                    const qrcodeColumn = worksheet.getCell('G' + i)
                    const noOfDaysColumn = worksheet.getCell('H' + i)
                    const currentStockColumn = worksheet.getCell('I' + i)
                    const purchaseRateColumn = worksheet.getCell('J' + i)
                    const totalAmountColumn = worksheet.getCell('K' + i)
                    qrcodeColumn.alignment = { vertical: 'middle', horizontal: 'right' }
                    noOfDaysColumn.alignment = { vertical: 'middle', horizontal: 'right' }
                    currentStockColumn.alignment = { vertical: 'middle', horizontal: 'right' }
                    purchaseRateColumn.alignment = { vertical: 'middle', horizontal: 'right' }
                    totalAmountColumn.alignment = { vertical: 'middle', horizontal: 'right' }
                } else {
                    const noOfDaysColumn = worksheet.getCell('G' + i)
                    const currentStockColumn = worksheet.getCell('H' + i)
                    const purchaseRateColumn = worksheet.getCell('I' + i)
                    const totalAmountColumn = worksheet.getCell('J' + i)
                    noOfDaysColumn.alignment = { vertical: 'middle', horizontal: 'right' }
                    currentStockColumn.alignment = { vertical: 'middle', horizontal: 'right' }
                    purchaseRateColumn.alignment = { vertical: 'middle', horizontal: 'right' }
                    totalAmountColumn.alignment = { vertical: 'middle', horizontal: 'right' }
                }
            }

            worksheet.getColumn(1).width = 5
            worksheet.getColumn(2).width = 20
            worksheet.getColumn(3).width = 15
            worksheet.getColumn(4).width = 15
            worksheet.getColumn(5).width = 15
            worksheet.getColumn(6).width = 15
            worksheet.getColumn(7).width = 15
            worksheet.getColumn(8).width = 15
            worksheet.getColumn(9).width = 15
            worksheet.getColumn(10).width = 15
            worksheet.getColumn(11).width = 15

            // Save the workbook
            const buffer = await workbook.xlsx.writeBuffer()
            const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })

            const url = URL.createObjectURL(blob)
            const a = document.createElement('a')
            a.href = url
            a.download = `Aging Stock Report.xlsx`
            document.body.appendChild(a)
            a.click()
            document.body.removeChild(a)

            setIsLoading(false)
            setExcelButtonFlag(false)
        } else {
            toast.error(ERROR_MESSAGE.EXPORT_TO_EXCEL_NO_RECORD, { position: toast.POSITION.TOP_RIGHT });
        }
    }

    const udfnQrCodeWiseValue = (value) => {
        var returnVal = value === 'true' ? false : true;
        setQrcodeWise(returnVal);
    };

    const udfnSupplierWiseValue = (value) => {
        var returnVal = value === 'true' ? false : true;
        setSupplierWise(returnVal);
    };

    useEffect(() => {
        setAgingStockList([])
        setAgingStockListFiltered([])
        setTotalAmount(0)
    }, [transferDate, branchName?.value, groupName?.value, categoryName?.value, brandName?.value, itemName?.value, supplierName?.value]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setAgingStockListFiltered([])
        setTotalAmount(0)
        udfnAgingStockReportFiltered()
    }, [qrcodeWise, supplierWise]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        fetchMasterDataWithParams()
        udfnAgingStockReport()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        localStorage.setItem('agingStockReportDate', transferDate)
        localStorage.setItem('agingStockReportBranchName', JSON.stringify(branchName))
        localStorage.setItem('agingStockReportBrandName', JSON.stringify(brandName))
        localStorage.setItem('agingStockReportGroupName', JSON.stringify(groupName))
        localStorage.setItem('agingStockReportCategoryName', JSON.stringify(categoryName))
        localStorage.setItem('agingStockReportItemName', JSON.stringify(itemName))
        localStorage.setItem('agingStockReportSupplierName', JSON.stringify(supplierName))
        localStorage.setItem('agingStockReportQrcodeWise', JSON.stringify(qrcodeWise))
        localStorage.setItem('agingStockReportSupplierWise', JSON.stringify(supplierWise))
    }, [transferDate, groupName, categoryName, branchName, itemName, supplierName, brandName, qrcodeWise, supplierWise])  // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <LoadingOverlay
                active={isLoading}
                spinner
                text='Loading ...'
            >
                <ToastContainer />
                <div className="card">
                    <div className="card-header">
                        <div className='col-sm-5'>
                            <h4 className="card-title">Aging Stock Report</h4>
                        </div>
                        <div className='cash-total col-sm-4 text-center'>
                            <Button variant="info" className="mb-2" onClick={() => exportToExcel()} disabled={excelButtonFlag ? true : false}>
                                <i className="fa fa-download" /> &nbsp; Export
                            </Button>
                        </div>
                        <div className='col-sm-2 mb-2'>
                            <div className="bg-secondary coin-holding flex-wrap py-0 m-0">
                                <div className="mb-2 coin-bx">
                                    <div className="d-flex align-items-center">
                                        <div>
                                            <i className="fa fa-rupee udfn-rupee-icon"></i>
                                        </div>
                                        <div className="ms-3">
                                            <h4 className="coin-font font-w600 mb-0 text-white mt-2">Total</h4>
                                            <p className="mb-0 text-white op-6">{totalAmount}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card-body">
                        <Accordion className="accordion accordion-rounded-stylish accordion-bordered  accordion-active-header" defaultActiveKey="0">
                            <Accordion.Item>
                                <Accordion.Header className="accordion-header accordion-header-primary">
                                    <h5>Filters</h5>
                                    <span className="accordion-header-indicator "></span>
                                </Accordion.Header>
                                <Accordion.Collapse className="accordion__body">
                                    <div className="accordion-body">
                                        <div className="row">
                                            <div className="col-md-3 mb-3">
                                                <label className="text-black font-w300">Date</label>
                                                <input value={transferDate} onChange={e => setTransferDate(e.target.value)} type="date" className="form-control" />
                                            </div>
                                            <div className="col-md-3 mb-3">
                                                <p className="mb-1">Branch</p>
                                                <Select
                                                    defaultValue={branchName}
                                                    onChange={setBranchName}
                                                    options={branchNameOptions.map((option) => ({
                                                        label: option?.bm_branch_name,
                                                        value: option?.bm_branch_code,
                                                    }))}
                                                    showContent="true"
                                                    open="true"
                                                    className="my-react-select-container"
                                                    theme={theme => ({
                                                        ...theme,
                                                        borderRadius: "6px",
                                                        colors: {
                                                            ...theme.colors,
                                                            primary25: '#b3b3b3',
                                                            primary: 'black',
                                                            neutral80: "data-theme-version" === "dark" ? 'white' : ''
                                                        },
                                                    })}
                                                />
                                            </div>
                                            <div className="col-md-3 mb-3">
                                                <p className="mb-1">Item Group</p>
                                                <Select
                                                    defaultValue={groupName}
                                                    onChange={setGroupName}
                                                    options={itemGroupNameOptions.map((option) => ({
                                                        label: option?.gm_group_name,
                                                        value: option?.gm_group_code,
                                                    }))}
                                                    showContent="true"
                                                    open="true"
                                                    className="my-react-select-container"
                                                    theme={theme => ({
                                                        ...theme,
                                                        borderRadius: "6px",
                                                        colors: {
                                                            ...theme.colors,
                                                            primary25: '#b3b3b3',
                                                            primary: 'black',
                                                            neutral80: "data-theme-version" === "dark" ? 'white' : ''
                                                        },
                                                    })}
                                                />
                                            </div>
                                            <div className="col-md-3 mb-3">
                                                <p className="mb-1">Item Category</p>
                                                <Select
                                                    defaultValue={categoryName}
                                                    onChange={setCategoryName}
                                                    options={categoryNameOptions.map((option) => ({
                                                        label: option?.cm_category_name,
                                                        value: option?.cm_category_code,
                                                    }))}
                                                    showContent="true"
                                                    open="true"
                                                    className="my-react-select-container"
                                                    theme={theme => ({
                                                        ...theme,
                                                        borderRadius: "6px",
                                                        colors: {
                                                            ...theme.colors,
                                                            primary25: '#b3b3b3',
                                                            primary: 'black',
                                                            neutral80: "data-theme-version" === "dark" ? 'white' : ''
                                                        },
                                                    })}
                                                />
                                            </div>
                                            <div className="col-md-3 mb-3">
                                                <p className="mb-1">Brand</p>
                                                <Select
                                                    defaultValue={brandName}
                                                    onChange={setBrandName}
                                                    options={brandNameOptions.map((option) => ({
                                                        label: option?.bm_brand_name,
                                                        value: option?.bm_brand_code,
                                                    }))}
                                                    showContent="true"
                                                    open="true"
                                                    className="my-react-select-container"
                                                    theme={theme => ({
                                                        ...theme,
                                                        borderRadius: "6px",
                                                        colors: {
                                                            ...theme.colors,
                                                            primary25: '#b3b3b3',
                                                            primary: 'black',
                                                            neutral80: "data-theme-version" === "dark" ? 'white' : ''
                                                        },
                                                    })}
                                                />
                                            </div>
                                            <div className="col-md-3 mb-3">
                                                <p className="mb-1">Item Name</p>
                                                <Select
                                                    defaultValue={itemName}
                                                    onChange={setItemName}
                                                    options={itemNameOptions.map((option) => ({
                                                        label: option?.im_display_name,
                                                        value: option?.im_item_code,
                                                    }))}
                                                    showContent="true"
                                                    open="true"
                                                    className="my-react-select-container"
                                                    theme={theme => ({
                                                        ...theme,
                                                        borderRadius: "6px",
                                                        colors: {
                                                            ...theme.colors,
                                                            primary25: '#b3b3b3',
                                                            primary: 'black',
                                                            neutral80: "data-theme-version" === "dark" ? 'white' : ''
                                                        },
                                                    })}
                                                />
                                            </div>
                                            <div className="col-md-3 mb-3">
                                                <p className="mb-1">Supplier</p>
                                                <Select
                                                    defaultValue={supplierName}
                                                    onChange={setSupplierName}
                                                    options={supplierNameOptions.map((option) => ({
                                                        label: option?.sm_supplier_name,
                                                        value: option?.sm_supplier_code,
                                                    }))}
                                                    showContent="true"
                                                    open="true"
                                                    className="my-react-select-container"
                                                    theme={theme => ({
                                                        ...theme,
                                                        borderRadius: "6px",
                                                        colors: {
                                                            ...theme.colors,
                                                            primary25: '#b3b3b3',
                                                            primary: 'black',
                                                            neutral80: "data-theme-version" === "dark" ? 'white' : ''
                                                        },
                                                    })}
                                                />
                                            </div>
                                            <div className="col-md-3 mt-3">
                                                <Button className="me-2 mt-2" variant="whatsapp" onClick={() => { udfnAgingStockReport() }}>
                                                    List{" "}
                                                    <span className="btn-icon-right">
                                                        <i className="fa fa-filter" />
                                                    </span>
                                                </Button>
                                            </div>
                                            <div className="col-md-3 mb-3">
                                                <p className="mb-1">Aging Stock</p>
                                                <div className='mt-4'>
                                                    <input
                                                        type="checkbox"
                                                        className="custom-control-input"
                                                        value={qrcodeWise}
                                                        checked={qrcodeWise ? true : false}
                                                        onChange={(e) => udfnQrCodeWiseValue(e.target.value)}
                                                    />
                                                    &nbsp;
                                                    QrCode &nbsp; &nbsp;
                                                    <input
                                                        type="checkbox"
                                                        className="custom-control-input"
                                                        checked={supplierWise ? true : false}
                                                        value={supplierWise}
                                                        onChange={(e) => udfnSupplierWiseValue(e.target.value)}
                                                    />
                                                    &nbsp;
                                                    Supplier
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Accordion.Collapse>
                            </Accordion.Item>
                        </Accordion>
                        <div className="table-responsive">
                            <table {...getTableProps()} className="table filtering-table table-responsive-lg">
                                <thead>
                                    {headerGroups.map(headerGroup => (
                                        <tr {...headerGroup.getHeaderGroupProps()}>
                                            <th>
                                                <p className='tbl-heading-txt'>S.NO.</p>
                                            </th>
                                            {headerGroup.headers.map((column, index) => (
                                                <>
                                                    {column.Header === 'No. Of Days' || column.Header === 'Current Stock' || column.Header === 'Purchase Rate' || column.Header === 'Total Amount' ?
                                                        <th className='text-end' key={index}>
                                                            {column.render('Header')}
                                                            {column.canFilter ? column.render('Filter') : null}
                                                        </th>
                                                        :
                                                        (supplierWise === true && qrcodeWise === false) || (supplierWise === false && qrcodeWise === false)
                                                            ?
                                                            (
                                                                column.ShowColumn === true ?
                                                                    <th key={index}>
                                                                        {column.render('Header')}
                                                                        {column.canFilter ? column.render('Filter') : null}
                                                                    </th>
                                                                    : ""
                                                            )
                                                            :
                                                            <th key={index}>
                                                                {column.render('Header')}
                                                                {column.canFilter ? column.render('Filter') : null}
                                                            </th>
                                                    }
                                                </>
                                            ))}
                                        </tr>
                                    ))}
                                </thead>
                                <tbody {...getTableBodyProps()} className="" >
                                    {page.map((row, index) => {
                                        return (
                                            <tr key={index}>
                                                <td>{index + 1}</td>
                                                <td>{row.original.sm_supplier_name}</td>
                                                <td>{row.original.group_name}</td>
                                                <td>{row.original.category_name}</td>
                                                <td>{row.original.brand_name}</td>
                                                <td>{row.original.item_name}</td>
                                                {(supplierWise === false && qrcodeWise === true) || (supplierWise === true && qrcodeWise === true)
                                                    ? <td>{row.original.qr_code}</td>
                                                    : ""}
                                                <td className='text-end'>{row.original.noofdays}</td>
                                                <td className='text-end'>{row.original.current_stock}</td>
                                                <td className='text-end'><i className="fa fa-rupee udfn-rupee-icon rupeeicon"></i> {COMMONVARIABLES.India_Rupee_Formatter_Digits(Formatting('two_decimal', row.original.qs_rate))}</td>
                                                <td className='text-end'><i className="fa fa-rupee udfn-rupee-icon rupeeicon"></i> {COMMONVARIABLES.India_Rupee_Formatter_Digits(Formatting('two_decimal', row.original.total_amount))}</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                            <div className='text-center'>
                                {agingStockListFiltered.length < 1 ? "No records found." : ""}
                            </div>
                            <div className="d-flex justify-content-between">
                                <span>
                                    Page{' '}
                                    <strong>
                                        {pageIndex + 1} of {pageOptions.length}
                                    </strong>{''}
                                </span>
                                <span className="table-index">
                                    Go to page : {' '}
                                    <input type="number"
                                        className="ms-2"
                                        defaultValue={pageIndex + 1}
                                        onChange={e => {
                                            const pageNumber = e.target.value ? Number(e.target.value) - 1 : 0
                                            gotoPage(pageNumber)
                                        }}
                                    />
                                </span>
                            </div>
                            <div className="text-center">
                                <div className="filter-pagination  mt-3">
                                    <button className=" previous-button" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>{'<<'}</button>

                                    <button className="previous-button" onClick={() => previousPage()} disabled={!canPreviousPage}>
                                        Previous
                                    </button>
                                    <button className="next-button" onClick={() => nextPage()} disabled={!canNextPage}>
                                        Next
                                    </button>
                                    <button className=" next-button" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>{'>>'}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </LoadingOverlay >
        </>
    )

}
export default AgingStockReport;
