import React, { useEffect, useState, useMemo, useRef } from 'react';
import { useTable, useGlobalFilter, useFilters, useSortBy, usePagination, loopHooks } from 'react-table';
import { COLUMNS } from '../../Filters/Purchase/PurchaseColumns';
import { useNavigate } from "react-router-dom";
import { Button, Modal } from 'react-bootstrap';
import '../../Filters/Common/filtering.css';
import Select from "react-select";
import { Accordion } from "react-bootstrap";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";

import axios from "axios";
import { getLoggedInUser, getRefreshToken } from '../../../../services/AuthService';
import { Logout } from '../../../../store/actions/AuthActions';
import COMMONVARIABLES from '../../Utilities/constants';
import PURCHASE_API from '../../../Api/PurchaseApi';
import COMMON_API from '../../../Api/CommonApi';
import LoadingOverlay from 'react-loading-overlay-ts';
import Formatting from '../../Utilities/format';
import swal from "sweetalert";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ERROR_MESSAGE from '../../Validation/ErrorMessage';
import QRCode from "react-qr-code";
import { UncontrolledTooltip } from "reactstrap"


import { useReactToPrint } from 'react-to-print';
import Logo from '../../../../pictures/bw1.png';
import moment from 'moment';
export const PurchaseList = () => {
	const BASE_URL = process.env.REACT_APP_BASE_URL;

	Date.prototype.toDateString = function () {
		var month = this.getMonth() + 1;
		var day = this.getDate();
		var year = this.getFullYear();
		return year + "-" + (month < 10 ? '0' : '') + month + '-' + (day < 10 ? '0' : '') + day;
	};
	const currentDate = new Date().toDateString();

	const [isLoading, setIsLoading] = useState(false);
	const navigate = useNavigate();
	const columns = useMemo(() => COLUMNS, [])
	const [data, setData] = useState([])
	const [supplierName, setSupplierName] = useState(JSON.parse(localStorage.getItem('supplierName')) || { label: "All", value: 0 })
	const [itemName, setItemName] = useState(JSON.parse(localStorage.getItem('itemName')) || { label: "All", value: 0 })
	const [qrCode, setQrCode] = useState(localStorage.getItem('qrcode') || '')

	const [range, setRange] = useState(localStorage.getItem('range') || null)

	var start_date = localStorage.getItem('range') && new Date(localStorage.getItem('range').split('-')[0]).toDateString();
	var end_date = localStorage.getItem('range') && new Date(localStorage.getItem('range').split('-')[1]).toDateString();

	const [rangeDate, setRangeDate] = useState(localStorage.getItem('range') && localStorage.getItem('range') !== "null" ? { startDate: new Date(start_date), endDate: new Date(end_date) } : { startDate: new Date(), endDate: new Date() })

	const [supplierNameOptions, setSupplierNameOptions] = useState([])
	const [itemNameOptions, setItemNameOptions] = useState([])
	const [addCard, setAddCard] = useState(false);
	const [dcPrintCard, setDcPrintCard] = useState(false);
	const [itemqty, setitemqty] = useState("")
	const [reprintList, setReprintList] = useState([])
	const [reprintArray, setReprintArray] = useState([])
	const [list, setList] = useState([])
	const [labelTypeName, setLabelTypeName] = useState({ label: '35*22', value: 1 })
	const labelTypeNameOptions = COMMONVARIABLES.LABLEPRINTTYPE;

	const [dcCard, setDCCard] = useState(false);
	const [dcPrintList, setDCPrintList] = useState(null)
	const [dcPrintDetails, setDCPrintDetails] = useState([])
	const contentToPrint = useRef(null);
	const [localUserDetails, setLocalUserDetails] = useState(null)
	const [transferNoPrint, setTransferNoPrint] = useState('');
	const [transferDatePrint, setTransferDatePrint] = useState("");
	const [DCRemarks, setDCRemarks] = useState("");
	const [fromBranchDetails, setFromBranchDetails] = useState([])
	const [toBranchDetails, setToBranchDetails] = useState([])

	var from_branch_details = [];
	var to_branch_details = [];
	var address = '';
	var branch_name = '';
	var city = '';

	useEffect(() => {
		var lcluserDetails = localStorage.getItem("userDetails")
		setLocalUserDetails(JSON.parse(lcluserDetails))

	}, [])  // 

	const handlePrint = useReactToPrint({
		documentTitle: "Print This Document",

		onBeforePrint: () => {
			let temp = document.querySelectorAll(".printfooter");
			// alert(temp.length)
			for (let i = 0; i < temp.length; i++) {
				if ((i + 1) == temp.length)
					temp[i].style.display = "block";

			}
		},
		onAfterPrint: () => {
			setDCRemarks("")
		},
		removeAfterPrint: true,
	});

	const myid = "myqr"
	const myqrid = "myqrid"

	var slice_array = [];

	const tableInstance = useTable({
		columns,
		data,
		initialState: { pageIndex: 0 }
	}, useFilters, useGlobalFilter, useSortBy, usePagination)

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		prepareRow,
		state,
		page,
		gotoPage,
		pageCount,
		pageOptions,
		nextPage,
		previousPage,
		canNextPage,
		canPreviousPage,
	} = tableInstance
	const { pageIndex } = state

	useEffect(() => {
		fetchMasterDataWithParams()
		bindPurchaseData()

	}, [])  // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		localStorage.setItem('itemName', JSON.stringify(itemName))
		localStorage.setItem('range', range)
		localStorage.setItem('qrcode', qrCode)
		localStorage.setItem('supplierName', JSON.stringify(supplierName))
	}, [range, itemName, qrCode, supplierName])  // eslint-disable-line react-hooks/exhaustive-deps

	async function fetchMasterDataWithParams() {
		try {
			const user = getLoggedInUser()

			const supplierParams = { status_code: 0, user_id: user?.id };
			const itemParams = { status_code: 0, user_id: user?.id };
			const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } };
			const [supplierData, itemData] = await Promise.all([
				axios.post(BASE_URL + COMMON_API.SUPPLIER_MASTER_LIST, supplierParams, config),
				axios.post(BASE_URL + COMMON_API.ITEM_MASTER_LIST, itemParams, config)

			]);

			if (supplierData?.data?.body?.status === COMMONVARIABLES.STATUS.UNAUTHORIZED || itemData?.data?.body?.status === COMMONVARIABLES.STATUS.UNAUTHORIZED) {
				const refresh = await getRefreshToken();

				if (refresh) {
					if (Number(refresh) === COMMONVARIABLES.STATUS.EXPIRED_TOKEN) {
						Logout();
					} else {
						await fetchMasterDataWithParams();
					}
				}
			}

			if (supplierData?.data?.body?.status === COMMONVARIABLES.STATUS.SERVICE_CATCH_STATUS) {
				toast.error(ERROR_MESSAGE.API_ERROR.SERVICE_CATCH_ERROR, { position: toast.POSITION.TOP_RIGHT });
			}

			if (supplierData?.data?.body?.supplierList || itemData?.data?.body?.itemList) {
				setSupplierNameOptions([{ sm_supplier_name: "All", sm_supplier_code: 0 }, ...supplierData?.data?.body?.supplierList])
				setItemNameOptions([{ im_item_name: "All", im_item_code: 0 }, ...itemData?.data?.body?.itemList])

			}

		} catch (error) {
			console.error('Error fetching master data:', error);
			if (error.code === COMMONVARIABLES.STATUS.NETWORK_ERROR_STATUS) {
				toast.error(ERROR_MESSAGE.API_ERROR.NETWORK_ERROR, { position: toast.POSITION.TOP_RIGHT });
			}
			if (error.code === COMMONVARIABLES.STATUS.BAD_REQUEST_ERROR_STATUS) {
				toast.error(ERROR_MESSAGE.API_ERROR.BAD_REQUEST_ERROR, { position: toast.POSITION.TOP_RIGHT });
			}
		}
	}
	async function bindPurchaseData() {
		try {
			setIsLoading(true)
			setRange(range)
			const user = getLoggedInUser()
			var varFromDate = new Date().toDateString()
			var varToDate = new Date().toDateString()

			if (range !== "null" && range !== null) {
				varFromDate = new Date(range.split('-')[0]).toDateString()
				varToDate = new Date(range.split('-')[1]).toDateString()
			}

			const params = { supplier_code: Number(supplierName.value), item_code: Number(itemName.value), user_id: user?.id, qr_code: Number(qrCode), from_date: varFromDate, to_date: varToDate };

			const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } };
			const [purchaseList] = await Promise.all([
				axios.post(BASE_URL + PURCHASE_API.PURCHASE_LIST, params, config),
			]);
			setIsLoading(false)
			if (purchaseList?.data?.body?.status === COMMONVARIABLES.STATUS.UNAUTHORIZED) {

				const refresh = await getRefreshToken();

				if (refresh) {
					if (Number(refresh) === COMMONVARIABLES.STATUS.EXPIRED_TOKEN) {
						Logout();
					} else {
						await bindPurchaseData();
					}
				}
			}

			if (purchaseList?.data?.body?.status === COMMONVARIABLES.STATUS.SERVICE_CATCH_STATUS) {
				toast.error(ERROR_MESSAGE.API_ERROR.SERVICE_CATCH_ERROR, { position: toast.POSITION.TOP_RIGHT });
			}

			if (purchaseList?.data?.body?.purchaseList) {
				setData(purchaseList?.data?.body?.purchaseList)
			}
		} catch (error) {
			setIsLoading(false)
			console.error('Error fetching master data:', error);
			if (error.code === COMMONVARIABLES.STATUS.NETWORK_ERROR_STATUS) {
				toast.error(ERROR_MESSAGE.API_ERROR.NETWORK_ERROR, { position: toast.POSITION.TOP_RIGHT });
			}
			if (error.code === COMMONVARIABLES.STATUS.BAD_REQUEST_ERROR_STATUS) {
				toast.error(ERROR_MESSAGE.API_ERROR.BAD_REQUEST_ERROR, { position: toast.POSITION.TOP_RIGHT });
			}
		}
	}
	const udfnDelete = async (transno) => {
		// setBranchCode(branch_code)
		swal({
			title: "Are you sure?",
			text:
				"Once deleted, you will not be able to recover this details!",
			icon: "warning",
			buttons: true,
			dangerMode: true,
		}).then((willDelete) => {
			if (willDelete) {
				udfnPurchaseDelete(transno);
			} else {
				swal("Your details are safe!");
			}
		})
	}
	const udfnPurchaseDelete = async (transno) => {

		setIsLoading(true);
		const user = getLoggedInUser();
		var localFy = localStorage.getItem("fy")
		const data = {
			user_id: user.id,
			company_code: user.company_code,
			trans_no: transno,
			fy: localFy ? Number(localFy) : 0,

		};
		const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } };

		try {
			const response = await axios.post(process.env.REACT_APP_BASE_URL + PURCHASE_API.PURCHASE_DELETE, data, config);
			setIsLoading(false);
			if (response && response?.data?.body?.status === COMMONVARIABLES.STATUS.INVALIDUSER) {
				toast.error(response.data.body.message, {
					position: "top-right",
					autoClose: 2000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
				});
				Logout();
				return;
			}

			if (response?.data?.body?.status === COMMONVARIABLES.STATUS.UNAUTHORIZED) {
				const refresh = await getRefreshToken();

				if (refresh) {
					if (Number(refresh) === COMMONVARIABLES.STATUS.EXPIRED_TOKEN) {
						Logout();
					} else {
						await udfnPurchaseDelete();
					}
				}
			}

			if (response && response?.data?.body?.status === COMMONVARIABLES.STATUS.EXISTS) {
				toast.error(response.data.body.message, {
					position: "top-right",
					autoClose: 2000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
				});
			}

			if (response?.data?.body?.status === COMMONVARIABLES.STATUS.SERVICE_CATCH_STATUS) {
				toast.error(ERROR_MESSAGE.API_ERROR.SERVICE_CATCH_ERROR, { position: toast.POSITION.TOP_RIGHT });
			}

			if (response?.data?.body?.status === COMMONVARIABLES.STATUS.SUCCESS) {
				swal(response.data.body.message, {
					icon: "success",
				});
				await bindPurchaseData();
			} else {
				toast.error(response.data.body.message, {
					position: "top-right",
					autoClose: 2000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
				});
			}
		} catch (error) {
			// Handle error
			setIsLoading(false);
			console.error("branch list API Error:", error);
			if (error.code === COMMONVARIABLES.STATUS.NETWORK_ERROR_STATUS) {
				toast.error(ERROR_MESSAGE.API_ERROR.NETWORK_ERROR, { position: toast.POSITION.TOP_RIGHT });
			}
			if (error.code === COMMONVARIABLES.STATUS.BAD_REQUEST_ERROR_STATUS) {
				toast.error(ERROR_MESSAGE.API_ERROR.BAD_REQUEST_ERROR, { position: toast.POSITION.TOP_RIGHT });
			}
		}
	};

	async function editLoadData(trans_no) {
		try {
			const user = getLoggedInUser()

			const editloadParams = { transaction_no: trans_no, user_id: user?.id };

			const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } };
			const [editloadValues] = await Promise.all([
				axios.post(BASE_URL + PURCHASE_API.PURCHASE_EDIT_LOAD, editloadParams, config),
			]);

			if (editloadValues?.data?.body?.status === COMMONVARIABLES.STATUS.UNAUTHORIZED) {
				const refresh = await getRefreshToken();

				if (refresh) {
					if (Number(refresh) === COMMONVARIABLES.STATUS.EXPIRED_TOKEN) {
						Logout();
					} else {
						await editLoadData();
					}
				}
			}

			else if (editloadValues?.data?.body?.purchaseList) {
				var purchaseItemList = editloadValues?.data?.body?.purchaseItemList;
				setList(purchaseItemList);
				frameDCPrintData(purchaseItemList)
				var purchase_item_list = editloadValues?.data?.body?.purchaseItemList.filter(item => item.qrmantype === 'Q')
				purchase_item_list.map(row => {
					setitemqty(row.itemqty)
					row['checkbox_value'] = false
					// row['itemqty'] = value
					return row
				});
				// purchase_item_list.map(row => {
				// 	// row['checkbox_value'] = true,
				// 	row['itemqty'] = 0
				// 	return row
				// });
				setReprintList(purchase_item_list)


				setTransferNoPrint(editloadValues?.data?.body?.purchaseList[0].pr_voucher_number)

				setTransferDatePrint(editloadValues?.data?.body?.purchaseList[0].pr_voucher_date)
			}
		} catch (error) {
			console.error('Error fetching master data:', error);
		}
	}
	const frameDCPrintData = (purchaseItems) => {


		let branchItemCounts = {};
		purchaseItems.forEach(item => {
			const { branchcode, branchname, checkbox_value } = item;
			if (!(branchcode in branchItemCounts)) {
				branchItemCounts[branchcode] = {
					item_count: 1,
					branch_name: branchname,
					branch_code: branchcode,
					checkbox_value: checkbox_value,
				};
			} else {
				branchItemCounts[branchcode].item_count++;
			}
		});
		console.log(branchItemCounts, 'after purchaseItems')
		setDCPrintList(branchItemCounts)
	}

	const udfnCheckBoxSpecific = (id, row) => {
		const obj1 = row;
		obj1['checkbox_value'] = obj1['checkbox_value'] ? false : true;
		console.log(obj1['checkbox_value']);
		reprintList.splice(id, 1, obj1)
		console.log('reprintList', reprintList);
		setReprintList(reprintList)
	};



	const udfnTextBoxSpecific = (id, row, value) => {
		const obj1 = row;
		slice_array = reprintList;
		slice_array[id]['itemqty'] = value;
		setReprintList(slice_array)
	};
	const udfnDCTextBoxSpecific = (id, row, value) => {
		const obj1 = row;
		slice_array = dcPrintList;
		slice_array[id]['remarks'] = value;
		setDCPrintList(slice_array)
		console.log('dcPrintList', dcPrintList);
	};


	function printQRCode(qrcodeList) {
		const printHtml = window.open('', 'PRINT', 'height=auto,width=100%')
		let html = ''
		let itemDetails = ''
		const user = getLoggedInUser()

		for (let i = 0; i < qrcodeList.length; i++) {
			for (let j = 0; j < Number(qrcodeList[i].itemqty); j++) {
				const myids = "myqr"
				const getid = myids + qrcodeList[i].qrcode
				const innerContents = document.getElementById(getid).innerHTML
				itemDetails += `
					<div class="float-child col-4  ml-5 mt-3">				
						<div class="align-center">
							<div class="heading">${user.label_title}</div>
						</div>

						<div class="float-container botton-align ">`
				if (qrcodeList[i].labeldetails) {
					itemDetails += `<div class="qr_div">
													<div class="rotated"> ${qrcodeList[i].labeldetails}</div>
												</div>`
				}
				itemDetails += `<div class="float-qrcode ml-2">
												<div class="qr_div ">
													<div> ${innerContents}</div>
												
												</div>
												<div class="qr_div bold-design size-10 align-center mt-2">${qrcodeList[i].qrcode}</div>
											</div>
											<div class="float-details ml-2">
												<div class="bold-design size-9 wor-wrap">${qrcodeList[i].im_display_name}</div>
												<div class="bold-design size-15 m-10">₹${qrcodeList[i].sellrate}</div>
											</div>
						</div>			
					</div>`
			}
		}

		html += `<html>
					<head>
						<title>Purchase Print</title>
						<style>
						.rotated {
							writing-mode: tb-rl;
							transform: rotate(-180deg);
							font-size: 10px;
						}
				
						.qr_div {
							display: flex;
							align-items: center;
							justify-content: center;
						}
				
						body {
							font-family: Arial, Helvetica, sans-serif;
							width: 416px !important; 
						}
				
						.row {
							width: 100%;
							/* margin-top: 10px; */
						}
				
						.column {
							float: left;
						}
				
						@media all {
							.page-break {
								display: none
							}
						}
						@media print {
				
							body {
							margin-top: 1 !important;
							color: #000;
							}
				
						}
						.heading {
							font-size: 13px !important;
							font-weight: bold;
							text-align: center
						}
				
						.align-center {
							flex-direction: column;
							text-align: left;
						}
				
						.align-left {
							text-align: left;
						}
				
						.align-right {
							text-align: right;
							font-size: large;
						}
				
						.paddding-space {
							/* padding-bottom: 15px */
						}
				
						.center {
							text-align: center;
						}
				
						.padding {
							padding-left: 10px
						}
				
						.qrcode {
							padding-left: 50px
						}
				
						.botton-align {
							/* padding-top: 10px */
						}
				
						.botton {
							padding-top: 10px
						}
				
						.size-10 {
							font-size: 10px !important;
						}
				
						.size-11 {
							font-size: 11px !important;
						}
						.size-9 {
							font-size: 9px !important;
						}
						.size-12 {
							font-size: 12px !important;
						}
				
						.size-15 {
							font-size: 15px !important;
						}
				
						.size-small {
							font-size: 10px !important;
							text-align: center !important;
							font-weight: bold;
						}
						.bold-design {
							font-weight: bold;
						}
				
						.float-child {
							display: flex;
							flex-direction: column;
						}
				
						.float-container {
							width: 100%;
							display: flex;
							flex-direction: row;
						}
				
						.float-details {
							width: 65%;
							display: flex;
							flex-direction: column;
							padding-left: 2px;
							text-align: left;
						}
				
						.float-qrcode {
							width: 35%;
							display: flex;
							flex-direction: column;
						}
				
						.marleft {
							padding-left: 20px;
						}
				
						.marleft-10 {
							padding-left: 10px;
						}
				
						.marright {
							padding-right: 20px;
						}
				
						.col-4 {
							flex: 0 0 auto;
							width: 133px !important;
							height: 83px;
						}
				
						.parent {
							display: flex;
							flex-wrap: wrap;
							flex-direction: row;
						}
				
						.mb-15 {
							margin-bottom: 15px;
						}
				
						.mt-5 {
							margin-bottom: 5px;
						}
						.ml-5 {
							margin-left: 5px;
						}
						.ml-10{
							margin-left: 10px;
						}
						.mt-5{
							margin-top: 5px;
						}
						.ml-2 {
							margin-left: 2px;
						}
						.mt-3 {
							margin-top: 3px;
						}
						.mt-2 {
							margin-top: 2px;
						}
						.wor-wrap {
							text-wrap: wrap;
							width: 50px;
							overflow-wrap: break-word;
							height: 30px;
							overflow: hidden;
						}
						.mar-top{
							margin-top: -5px;
						}
						.m-10{
							margin-top: 10px
						}
						</style>
					</head>
					<body onload="window.print();window.close()">
						<div class="row  ml-10">
							<div class='paddding-space'>
								<div class="float-container parent">
									${itemDetails}					
								</div>
							</div>				
						</div>		
					</body>
				</html>`
		printHtml.document.write(html)
		printHtml.document.close()
		printHtml.print()
		printHtml.close()
		setAddCard(false);
		navigate("/purchase-list");
		return true
	}

	function printSingleQRCode(qrcodeList) {

		const printHtml = window.open('', 'PRINT', 'height=auto,width=100%')
		let html = ''
		let itemDetails = ''
		const user = getLoggedInUser()

		for (let i = 0; i < qrcodeList.length; i++) {
			for (let j = 0; j < Number(qrcodeList[i].itemqty); j++) {
				const myids = "myqrid"
				const getid = myids + qrcodeList[i].qrcode
				const innerContents = document.getElementById(getid).innerHTML

				itemDetails += `<div class="float-child">
			<div class="float-container align-center justfy-center">
				<div class="heading">${user.label_title}</div>
			</div>

			<div class="float-container botton-align ">`
				if (qrcodeList[i].labeldetails) {
					itemDetails += `<div class="qr_div marleft-10">
												<div class="rotated"> ${qrcodeList[i].labeldetails}</div>
											</div>`
				}
				itemDetails += `
				<div class="float-qrcode marleft-10">
					<div class="qr_div ">
						<div class=""> ${innerContents}</div>

					</div>
					<div class="qr_div bold-design qrcode-margin  size-large align-center">${qrcodeList[i].qrcode}</div>
				</div>
				<div class="float-details marleft-10">
					<div class="bold-design size-large wor-wrap">${qrcodeList[i].im_display_name}</div>
					<div class="bold-design botton m-1">₹${COMMONVARIABLES.PDFIndia_Rupee_Formatter(qrcodeList[i].sellrate)}</div>
				</div>
			</div>
		</div>`

			}
		}

		html += `<html>
					<head>
						<title>Purchase Print</title>
						<style>
						.rotated {
							writing-mode: tb-rl;
							transform: rotate(-180deg);
							font-size: 8px;
						}
				
						.qr_div {
							display: flex;
							align-items: center;
							justify-content: center;
						}
				
						body {
							font-family: Arial, Helvetica, sans-serif;
							width: 384px;
                            margin: 0px;
						}
				
						.row {
							width: 100%;
                            height: 56.69px;
                            display: block;
						}
				
						.column {
							float: left;
						}
				
						@media all {
							.page-break {
								display: none
							}
						}
				
						.heading {
							font-size: 11px !important;
							font-weight: bold;
							text-align: center;
						}
				
						.align-center {
							flex-direction: column;
							text-align: center;
						}
				
						.align-left {
							text-align: left;
						}
				
						.align-right {
							text-align: right;
							font-size: large;
						}

						.paddding-space {
							float:right;
						}
				
						.center {
							text-align: center;
						}
				
						.padding {
							padding-left: 10px
						}
				
						.qrcode {
							padding-left: 50px
						}
				
						.botton-align {

						}
				
						.botton {
							padding-top: 5px
						}
				
						.size-large {
							font-size: 8px !important;
						}

						.size-9 {

							font-size: 9px !important;

						}
				
						.size-Xlarge {
							font-size: 13px !important;
						}

						.qrcode-margin {
							margin-top: 2px !important
						}
				
						.size-20 {
							font-size: 20px !important;
						}
				
						.size-small {
							font-size: 10px !important;
							text-align: center !important;
							font-weight: bold;
						}
				
						.color-style {
							padding-top: 20px;
							font-size: 20px !important;
							font-weight: bold;
						}
				
						.bold-design {
							font-weight: bold;
						}
				
						.float-child {
							width: 100%;
							display: flex;
							flex-direction: column;
                            // height: 56.69px;
                            padding: 1px;
							margin-top:2px !important;
						}
				
						.float-container {
							width: 100%;
							display: flex;
							flex-direction: row;
						}
				
						.float-details {
							width: 65%;
							display: flex;
							flex-direction: column;
							padding-left: 2px;
							text-align: left;
						}
				
						.float-qrcode {
							margin-top: 2px;
							width: 35%;
							display: flex;
							flex-direction: column;
						}
				
						.marleft {
							padding-left: 20px;
						}
				
						.marleft-10 {
							padding-left: 5px;
						}
				
						.marright {
							padding-right: 20px;
						}
				
						.marright-10 {
							padding-right: 10px;
						}
				
						.col-4 {
							flex: 0 0 auto;
							width: 33.333%;
						}
				
						.parent {
							display: block;
							flex-wrap: wrap;
							flex-direction: row;
						}
				
						.mt-10 {
							margin-top: 10px;
						}
				
						.pb-15 {
							padding-bottom: 13px;
						}
				
						.mt-5 {
							margin-top: 5px;
						}
				
						.mb-5 {

						}
				
						.mb-2 {
							margin-bottom: 2px;
						}
				
						.justfy-center {
							justify-content: center;
						}
						.p-tb{
							padding: 5px 0px;
						}
						.ml-20{
							margin-left:20px;
						}
						.wor-wrap {
							text-wrap: wrap;
    						width: 10px;
						}
						</style>
					</head>
					<body onload="window.print();window.close()">
						<div class="row">
							<div class='paddding-space'>
								<div class="float-container parent">
									${itemDetails}					
								</div>
							</div>				
						</div>		
					</body>
				</html>`
		printHtml.document.write(html)
		printHtml.document.close()
		printHtml.print()
		printHtml.close()
		setAddCard(false);
		navigate("/purchase-list");
		return true
	}

	function udfnRePrintList() {
		var reprint_array = [];
		reprint_array = reprintList.filter(item => item.checkbox_value === true);
		if (reprint_array.length < 1) {
			toast.error("atleast one purchase item required to print.", { position: toast.POSITION.TOP_RIGHT });
		} else {
			swal({
				text:
					"Are you sure you want print?",
				icon: "warning",
				buttons: true,
				dangerMode: true,
			}).then((willDelete) => {
				if (willDelete) {
					setReprintArray(reprint_array)
				} else {
					navigate("/purchase-list");
				}
			})
		}
	}

	const udfnBranchDetails = async (branch_code) => {
		setIsLoading(true)
		const user = getLoggedInUser()
		const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } };
		try {
			const branchParams = { user_id: Number(user?.id), branch_code: Number(branch_code) };

			const [branchData] = await Promise.all([
				axios.post(BASE_URL + COMMON_API.BRANCH_DETAILS, branchParams, config)
			]);

			setIsLoading(false)
			if (branchData?.data?.body?.status === COMMONVARIABLES.STATUS.INVALIDUSER) {
				var errorMsg = branchData?.data?.body?.message;
				toast.error(errorMsg, { position: toast.POSITION.TOP_RIGHT });
				Logout();
			}

			if (branchData?.data?.body?.status === COMMONVARIABLES.STATUS.UNAUTHORIZED) {
				const refresh = await getRefreshToken();

				if (refresh) {
					if (Number(refresh) === COMMONVARIABLES.STATUS.EXPIRED_TOKEN) {
						Logout();
					} else {
						await udfnBranchDetails(branch_code);
					}
				}
			}

			if (branchData?.data?.body?.FromBranch && branchData?.data?.body?.ToBranch) {
				console.log(branchData?.data?.body.FromBranch[0],'branchData?.data?.body');
				setFromBranchDetails(branchData?.data?.body?.FromBranch[0])
				setToBranchDetails(branchData?.data?.body?.ToBranch[0])
				// from_branch_details = branchData?.data?.body?.FromBranch;
				// to_branch_details = branchData?.data?.body?.ToBranch;
				// from_branch_details.map((row) => {
				// 	address = row.bm_address
				// })
				// console.log(address);
			}
		} catch (error) {
			setIsLoading(false)
			console.error('Error fetching master data:', error);
		}
	}

	async function udfnDcPrintList(branchcode) {

		var dc_details = list.filter(item => item.branchcode == branchcode);
		const jsonArray = Object.values(dcPrintList);
		var dc_remark = jsonArray.filter(item => item.branch_code == branchcode);
		setDCPrintDetails(dc_details)
		dc_remark.map((row) => {
			setDCRemarks(row.remarks)
		})

		await udfnBranchDetails(branchcode);
		if (dc_details.length < 1) {
			toast.error("atleast one purchase item required to print.", { position: toast.POSITION.TOP_RIGHT });
		} else {
			swal({
				text:
					"Are you sure you want print?",
				icon: "warning",
				buttons: true,
				dangerMode: true,
			}).then((willDelete) => {
				if (willDelete) {
					
						handlePrint(null, () => contentToPrint.current);
				

				} else {
					navigate("/purchase-list");
				}
			})
		}
	}

	const udfnClearData = () => {

		setAddCard(false)
	}
	const udfnDCPrintClose = () => {
		setDCCard(false)
	}

	const udfnRePrint = (trans_no) => {
		editLoadData(trans_no)
		setAddCard(true)
	}
	const udfnDCPrint = (trans_no) => {
		editLoadData(trans_no)
		setDCCard(true)
	}

	const udfnDcPrint = (trans_no) => {
		editLoadData(trans_no)
		setDcPrintCard(true)
	}

	// function groupByCount(array, key) {
	// 	return array.reduce((result, currentValue) => {
	// 		result['checkbox_value'] = false;
	// 		// Get the value of the key to group by
	// 		const groupKey = currentValue[key];

	// 		// If the group doesn't exist, create it and set the count to 0
	// 		if (!result[groupKey]) {
	// 			result[groupKey] = 0;
	// 		}
	// 		// Increment the count for the group
	// 		result[groupKey] += 1;

	// 		result['item_count'] = result[groupKey];

	// 		// console.log('groupKey',groupKey);
	// 		// console.log('value',result[groupKey]);

	// 		return result;
	// 	}, {}); // Initial value of result is an empty object
	// }



	// const groupedData = groupByCount(reprintList, 'branchname');

	// console.log('groupedData', groupedData['branchname']);

	useEffect(() => {
		if (reprintArray && reprintArray.length > 0) {
			if (labelTypeName.value === 1) {
				printQRCode(reprintArray);
			} else {
				printSingleQRCode(reprintArray);
			}

		}
	}, [reprintArray]) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<ToastContainer />
			<Modal className="fade bd-example-modal-xl" show={addCard} onHide={setAddCard} backdrop="static" size='xl'>
				<div className="" role="document">
					<div className="">
						<form >
							<div className="modal-header">
								<h4 className="modal-title fs-20">RePrint</h4>
								<button type="button" className="close btn" onClick={udfnClearData}><span>×</span></button>
							</div>
							<div className="modal-body">
								<i className="flaticon-cancel-12 close" data-dismiss="modal"></i>
								<div className="add-contact-box">
									<div className="add-contact-content">
										<table className="table">
											<thead>
												<tr>
													<th>
														S.NO.
													</th>
													<th>
														Qr Code
													</th>
													<th>
														Item Name
													</th>
													<th>
														No.Of.Stickers
													</th>
													<th>
														Print
													</th>
												</tr>
											</thead>
											<tbody className="" >
												{reprintList.map((row, index) => {
													return (
														<tr>
															<td>{index + 1}</td>
															<td>{row.qrcode}</td>
															<td>{row.itemname}</td>
															<td><input
																type='number'
																className="purchase-reprint btn-xs"
																onChange={e => {
																	udfnTextBoxSpecific(index, row, e.target.value)
																}}
																// value={row.itemqty}
																placeholder="No.Of.Stickers"
															/></td>
															<td> <input
																type="checkbox"
																className="custom-control-input"
																Checked={row.checkbox_value === true}
																onClick={() => udfnCheckBoxSpecific(index, row)}
																value={row.checkbox_value}
															/></td>
														</tr>
													)
												})}
											</tbody>
										</table>
									</div>
								</div>
							</div>
							<div className="modal-footer">
								<div className="col-md-2">
									<label className="col-form-label text-black font-w500">Label Print Types</label>
								</div>
								<div className="col-md-2">
									<Select
										defaultValue={labelTypeName}
										value={labelTypeName}
										onChange={setLabelTypeName}
										options={labelTypeNameOptions}
										showContent="true"
										open="true"
										className="my-react-select-container"
										theme={theme => ({
											...theme,
											borderRadius: '6px',
											colors: {
												...theme.colors,
												primary25: '#b3b3b3',
												primary: 'black',
												neutral80: "data-theme-version" === "dark" ? 'white' : ''
											},
										})}
									/>
								</div>
								<Button className="me-2" variant="whatsapp" onClick={udfnRePrintList}>
									RePrint
								</Button>
							</div>
						</form>
					</div>
				</div>
			</Modal>

			<Modal className="fade bd-example-modal-xl" show={dcCard} onHide={setDCCard} backdrop="static" size='xl'>
				<div className="" role="document">
					<div className="">
						<form >
							<div className="modal-header">
								<h4 className="modal-title fs-20">DC Print</h4>
								<button type="button" className="close btn" onClick={udfnDCPrintClose}><span>×</span></button>
							</div>
							<div className="modal-body">
								<i className="flaticon-cancel-12 close" data-dismiss="modal"></i>
								<div className="add-contact-box">
									<div className="add-contact-content">

										{/* {dcPrintList && Object.keys(dcPrintList).map(branchCode => (
											<div key={branchCode}>
												<h2>Branch Code: {branchCode}</h2>
												<p>Item Count: {dcPrintList[branchCode].item_count}</p>
												<p>Branch Name: {dcPrintList[branchCode].branch_name}</p>
											</div>

										))} */}

										<table className="table">
											<thead>
												<tr>
													{/* <th>
														S.NO.
													</th> */}

													<th>
														Branch Name
													</th>
													<th>
														No.Of.Items
													</th>
													<th>
														Remarks
													</th>
													<th>
														Print
													</th>
												</tr>
											</thead>
											<tbody className="" >
												{dcPrintList && Object.keys(dcPrintList).map(branchCode => (
													<tr>
														{/* <td>{index + 1}</td> */}

														<td>{dcPrintList[branchCode].branch_name}</td>
														<td>{dcPrintList[branchCode].item_count}</td>
														<td>
															<td><textarea
																type='text'
																className="custom-control-input "
																onChange={e => {
																	udfnDCTextBoxSpecific(branchCode, dcPrintList, e.target.value)
																}}
															// value={row.itemqty}
															// placeholder="No.Of.Stickers"
															/></td>
														</td>
														<td>
															{/* <input
															type="checkbox"
															className="custom-control-input"
															Checked={dcPrintList[branchCode].checkbox_value === true}
															onClick={() => udfnCheckBoxSpecificDcPrint(branchCode, dcPrintList)}
															value={dcPrintList[branchCode].checkbox_value}
														/> */}

															<div onClick={() => udfnDcPrintList(branchCode)} className="btn btn-warning shadow btn-xs sharp me-1">
																<i className="fa fa-print" />
															</div>


														</td>
													</tr>
												))
												}
											</tbody>
										</table>
									</div>
								</div>
							</div>
							<div className="modal-footer">

								<Button className="me-2" variant="whatsapp" onClick={udfnDCPrintClose}>
									Close
								</Button>
							</div>
						</form>
					</div>
				</div>
			</Modal>
			<LoadingOverlay
				active={isLoading}
				spinner
				text='Loading ...'
			>
				<div className="card">
					<div className="card-header">
						<h4 className="card-title">Purchase List </h4>
						<div className='row'>
							<div className='col-sm-4'>

							</div>
						</div>
						<div onClick={() => {
							navigate("/purchase-add", { state: { transaction_no: 0 } });
						}} className="btn btn-primary text-white">
							<i className="fa fa-plus"></i> &nbsp;
							Add Purchase
						</div>
					</div>
					<div className="card-body">
						<Accordion className="accordion accordion-rounded-stylish accordion-bordered  accordion-active-header" defaultActiveKey="0">
							<Accordion.Item>
								<Accordion.Header className="accordion-header accordion-header-primary">
									<h5>Filters</h5>
									<span className="accordion-header-indicator "></span>
									{/* <span className="accordion-header-icon"></span>
                        <span className="accordion-header-text">{d.title}</span>
                        <span className="accordion-header-indicator "></span> */}
								</Accordion.Header>
								<Accordion.Collapse className="accordion__body">
									<div className="accordion-body">
										<div className="row">
											<div className="col-md-4 mb-3">
												<div className="example rangeDatePicker">
													<p className="mb-1">Select From Date And To Date</p>
													{/* <DatePicker
                      startText="Start"
                      endText="End"
                      startPlaceholder="Start Date"
                      endPlaceholder="End Date"
                    />  */}
													<DateRangePicker
														onApply={(e) => {
															setRange(e.target.value)
														}}
														initialSettings={rangeDate}
													>
														<input type="text" className="form-control input-daterange-timepicker" styles={{ height: "90%" }} />
													</DateRangePicker>
												</div>
											</div>
											<div className="col-md-4 mb-3">
												<p className="mb-1">Supplier Name</p>
												<Select
													defaultValue={supplierName}
													onChange={setSupplierName}
													options={supplierNameOptions.map((option) => ({
														label: option?.sm_supplier_name,
														value: option?.sm_supplier_code,
													}))}
													showContent="true"
													open="true"
													className="my-react-select-container"
													theme={theme => ({
														...theme,
														borderRadius: 0,
														colors: {
															...theme.colors,
															primary25: '#b3b3b3',
															primary: 'black',
															neutral80: "data-theme-version" === "dark" ? 'white' : ''
														},
													})}
												/>
											</div>
											<div className="col-md-4 mb-3">
												<p className="mb-1">Item Name</p>
												<Select
													defaultValue={itemName}
													onChange={setItemName}
													options={itemNameOptions.map((option) => ({
														label: option?.im_item_name,
														value: option?.im_item_code,
													}))}
													showContent="true"
													open="true"
													className="my-react-select-container"
													theme={theme => ({
														...theme,
														borderRadius: 0,
														colors: {
															...theme.colors,
															primary25: '#b3b3b3',
															primary: 'black',
															neutral80: "data-theme-version" === "dark" ? 'white' : ''
														},
													})}
												/>
											</div>
										</div>
										<div className="row">
											<div className="col-md-4 mb-3">
												<p className="mb-1">QR Code</p>
												<input
													type="text"
													className="form-control"
													placeholder="QR Code"
													value={qrCode}
													onChange={(e) => {
														setQrCode(e.target.value)
													}}
												/>
											</div>
											<div className="col-md-6">
												<Button onClick={() => {
													bindPurchaseData()
												}} className="me-2" variant="whatsapp" style={{ marginTop: "6%" }}>
													List{" "}
													<span className="btn-icon-right">
														<i className="fa fa-filter" />
													</span>
												</Button>
												{/* <button type="submit" className="btn btn-warning">List</button> */}
											</div>
										</div>
									</div>
								</Accordion.Collapse>
							</Accordion.Item>
						</Accordion>
						<div className="table-responsive">
							{/* have to add when needed */}
							{/* <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} /> */}
							<table {...getTableProps()} className="table filtering-table table-responsive-lg">
								<thead>
									{headerGroups.map(headerGroup => (
										<tr {...headerGroup.getHeaderGroupProps()}>
											<th>
												<p className='tbl-heading-txt'>S.NO.</p>
											</th>
											{headerGroup.headers.map(column => (
												// have to add when needed
												// <th {...column.getHeaderProps(column.getSortByToggleProps())}>
												<th {...column.getHeaderProps()}>
													{column.render('Header')}
													{/* have to add when needed */}
													{/* <span className="ms-1">
													{column.isSorted ? (column.isSortedDesc ? <i className="fa fa-arrow-down" /> : <i className="fa fa-arrow-up" />) : ''}
												</span> */}
													{column.canFilter ? column.render('Filter') : null}
												</th>
											))}
											<th>
												<p className='tbl-heading-txt'>Actions</p>
											</th>
										</tr>
									))}
								</thead>
								<tbody {...getTableBodyProps()} className="" >
									{page.map((row, index) => {
										prepareRow(row)
										return (
											<tr>
												<td className='text-center'>{index + 1}</td>
												<td className='text-center'>{row.original.vou_date}</td>
												<td className='text-center'>{row.original.pr_voucher_number}</td>
												<td className='text-center'>{row.original.inv_date}</td>
												<td className='text-center'>{row.original.pr_inv_number}</td>
												{/* {row.original.pr_grand_total.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} */}
												<td >{row.original.sm_supplier_name}</td>
												<td align='right'>{COMMONVARIABLES.India_Rupee_Formatter_Digits(Formatting('two_decimal', row.original.pr_grand_total))}</td>
												<td style={{ width: '11%' }}>
													<div className="btn btn-secondary	 shadow btn-xs sharp me-1"
														onClick={() => {
															navigate("/purchase-add", { state: { transaction_no: row.original.pr_trans_no } });
														}}
													>
														<i className="fa fa-pencil"></i>
													</div>
													<div onClick={() => udfnRePrint(row.original.pr_trans_no)} className="btn btn-warning shadow btn-xs sharp me-1">
														<i className="fa fa-print" />
													</div>
													{row.original.pr_trans_no != 1 ?
														<div onClick={() => udfnDelete(row.original.pr_trans_no)} className="btn btn-danger shadow btn-xs sharp me-1">
															<i className="fa fa-trash"></i>
														</div> : null}
													<div onClick={() => udfnDCPrint(row.original.pr_trans_no)} className="btn btn-warning shadow btn-xs sharp">
														DC
													</div>
												</td>
											</tr>
										)
									})}
								</tbody>
							</table>
							{/* <div className='text-end mb-3'>
								<Button
									variant="success"
									type="button"
									className="btn btn-success me-2"
									onClick={() => {
										udfnRePrint()
									}}
								>
									RePrint
								</Button>
							</div> */}
							<div className="d-flex justify-content-between mb-3">
								<span>
									Page{' '}
									<strong>
										{pageIndex + 1} of {pageOptions.length}
									</strong>{''}
								</span>
								<span className="table-index">
									Go to page : {' '}
									<input type="number"
										className="ms-2"
										defaultValue={pageIndex + 1}
										onChange={e => {
											const pageNumber = e.target.value ? Number(e.target.value) - 1 : 0
											gotoPage(pageNumber)
										}}
									/>
								</span>
							</div>
							<div className="text-center">
								<div className="filter-pagination  mt-3">
									<button className=" previous-button" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>{'<<'}</button>

									<button className="previous-button" onClick={() => previousPage()} disabled={!canPreviousPage}>
										Previous
									</button>
									<button className="next-button" onClick={() => nextPage()} disabled={!canNextPage}>
										Next
									</button>
									<button className=" next-button" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>{'>>'}</button>
								</div>
							</div>
						</div>
					</div>
				</div>
				{reprintArray.map((row) => (
					<div id={myid + row.qrcode} className='color-picker' style={{ display: 'none' }}>
						<QRCode
							size={45}
							value={row.qrcode.toString()}
						/>
					</div>
				))}

				{reprintArray.map((row) => (
					<div id={myqrid + row.qrcode} className='color-picker' style={{ display: 'none' }}>
						<QRCode
							size={35}
							value={row.qrcode.toString()}
						/>
					</div>
				))}
			</LoadingOverlay>

			<div style={{ visibility: 'hidden', height: 0 }}>
				<div ref={contentToPrint} className="row purchsecontentToPrint" style={{ padding: '16px' }} >
					<table>


						<style>
							{`
       @page {    
        size: A5 portrait;    
        padding-left: 8px;    
        padding-right: 0px;
        // margin-top: 50px;
        //  margin-bottom: 150px;
    
   
    }  
    
        
    
        `}
						</style>

						<thead ><tr><td>


							<div class="printheader" >
								<div className="col-12">
									<div className="row text-center">
										
									
								 <div className="brand-logo p-16 col-4">
                                            <img className="logo-abbr me-3" src={Logo} alt="" style={{ width: '170px', height: '100px' }} />

                                        </div> 


										<div className='col-8 text-end'>
                                        <span>
                                            <strong style={{ fontSize: 20, fontWeight: 700 }} className="d-block text-black"> {localUserDetails?.company_name}</strong>


                                        </span>

                                        <small className="text-black" style={{ fontSize: 16, lineHeight: 1.2 }}>

                                            {localUserDetails?.address}{', '}{localUserDetails?.city}
                                            <br></br>
                                            {localUserDetails?.state_name}
                                            <br></br>
                                            {'Email'}{' : '}{localUserDetails?.email_id}
                                            <br></br>
                                            {'Contact No'}{' : '}{localUserDetails?.mobile_no}

                                            <strong className="d-block">GSTIN : {localUserDetails?.gstin}</strong>

                                        </small>
                                    </div> 
									<div class="h_line"></div>
									<strong style={{ fontSize: 16, fontWeight: 700 }} className="d-block text-center text-black"> {'DELIVERY NOTE'}</strong>


									</div>
									<div class="h_line"></div>
									<div className='row'>
										<div className='col-6'>
											<span>
												<strong style={{ fontSize: 16, fontWeight: 700 }} className="d-block text-black"> {'From:'}</strong>


											</span>
											{/* {from_branch_details.map((row) => { */}
											<small className="text-black" style={{ fontSize: 16, lineHeight: 1.2 }}>

												
												{fromBranchDetails?.bm_branch_name}
												<br></br>
												{fromBranchDetails?.bm_address}
												<br></br>
												{fromBranchDetails?.bm_city}
											

											</small>
											{/* })} */}

										</div>
										<div className='col-6 text-end'>
											<span>
												<strong style={{ fontSize: 16, fontWeight: 700 }} className="d-block text-black"> {'To:'}</strong>


											</span>

											<small className="text-black" style={{ fontSize: 16, lineHeight: 1.2 }}>

												{toBranchDetails?.bm_branch_name}
												<br></br>
												{toBranchDetails?.bm_address}
												<br></br>
												{toBranchDetails?.bm_city}
											

											</small>

										</div>
									</div>
								</div>
								<div class="h_line"></div>



								<div className='row'>


									<div className="col-6">
										<span className="d-block text-black"> Delivery Note No. : <strong>{transferNoPrint}</strong></span>
									</div>

									<div className="text-end col-6">


										<span className="d-block text-black"> Dated : <strong>{moment(transferDatePrint).format('DD/MM/YYYY')}</strong></span>

									</div>
								</div>
								<div class="h_line"></div>

							</div>
						</td></tr></thead>
						<tbody ><tr><td>

							<div className="col-lg-12 ">



								{/* <hr></hr> */}

								<div className="table-responsive">


									<table className="table table-striped">
										<thead >
											<tr>
												<th className="center">#</th>

												<th style={{ textAlign: 'center !important' }} className="center text-black">Item</th>
												<th className="center text-black">Qty</th>
												<th className="text-end text-black" >Rate</th>
												<th className="text-end text-black">Amount</th>
											</tr>
										</thead>
										<tbody>


											{
												dcPrintDetails?.length > 0 ?
													dcPrintDetails?.map((row, index) => {
														return (
															<tr key={index}>
																<td className='text-black'>{index + 1}</td>
																<td className='text-black'>{row.itemname}<br></br>
																{row.qrcode != '0' ?<span>{'('}{row.qrcode}{')'}</span>:null}
																</td>
																<td className='center text-black'>{row.itemqty?.toString()} {row.um_display_name}</td>
																<td className='text-end text-black'>{COMMONVARIABLES.India_Rupee_Formatter_Digits(row.sellrate)}</td>
																<td className='text-end text-black'>{COMMONVARIABLES.India_Rupee_Formatter_Digits(row.sellrate * row.itemqty)}</td>

															</tr>
														)
													})
													: ''
											}


										</tbody>
									</table>




								</div>

							</div>






						</td></tr></tbody>

						<tfoot>
							<tr><td className='text-black' style={{ paddingRight: 16, paddingLeft: 16 }}>Remarks : {DCRemarks}</td></tr>
							<tr>
								<td> <strong style={{ fontSize: 16, fontWeight: 700, paddingRight: 16, paddingLeft: 16  }} className="d-block text-black"> {'NOT FOR SALE'}</strong></td>
							</tr>
							<tr><td>
								<div class="footer-space" style={{ padding: 16 }}>



									<div className="printfooter" >
										<div class="h_line"></div>
										<div className='' style={{ marginTop: '35px' }}>
											<div className='row'>
												<div className='col-4'>

													<strong>Checked by</strong>
												</div>
												<div className='col-4'>

													<strong>Verified by</strong>
												</div>
												<div className='col-4 text-end'>

													<strong>Authorised Signatory</strong>
												</div>
											</div>
										</div>


									</div> </div>
							</td></tr></tfoot>
					</table>


				</div>
			</div>


		</>
	)

}
export default PurchaseList;