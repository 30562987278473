import React, { useMemo, useState, useEffect } from 'react';
import { useTable, useGlobalFilter, useFilters, useSortBy, usePagination } from 'react-table';
import { COLUMNS } from '../Filters/ItemwiseAnalysis/ItemwiseAnalysisColumns';
import { Button, Accordion } from 'react-bootstrap';
import DateRangePicker from "react-bootstrap-daterangepicker";
import ERROR from "../../components/Validation/ErrorMessage"
import '../Filters/Common/filtering.css';
import "bootstrap-daterangepicker/daterangepicker.css";
import { ToastContainer, toast } from "react-toastify";
import Select from "react-select";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { getLoggedInUser, getRefreshToken } from '../../../services/AuthService';
import { UserLogout } from '../../layouts/nav/Logout';
import COMMONVARIABLES from '../Utilities/constants';
import COMMON_API from '../../Api/CommonApi';
import MASTER_API from '../../Api/MasterApi';
import LoadingOverlay from 'react-loading-overlay-ts';
import moment from 'moment';
import ERROR_MESSAGE from '../Validation/ErrorMessage';
const ExcelJS = require('exceljs');

export const ItemwiseAnalysisReport = () => {
    const user = getLoggedInUser();
    const BASE_URL = process.env.REACT_APP_BASE_URL;

    const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } };

    const [itemAnalysisList, setItemAnalysisList] = useState([]);
    const columns = useMemo(() => COLUMNS, [])
    const data = itemAnalysisList
    const tableInstance = useTable({
        columns,
        data,
        initialState: { pageIndex: 0 }
    }, useFilters, useGlobalFilter, useSortBy, usePagination)

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        state,
        page,
        gotoPage,
        pageCount,
        pageOptions,
        nextPage,
        previousPage,
        canNextPage,
        canPreviousPage,
    } = tableInstance

    Date.prototype.toDateString = function () {
        var month = this.getMonth() + 1;
        var day = this.getDate();
        var year = this.getFullYear();
        return year + "-" + (month < 10 ? '0' : '') + month + '-' + (day < 10 ? '0' : '') + day;
    };

    const { pageIndex } = state

    const [excelButtonFlag, setExcelButtonFlag] = useState(false);
    const [showOpenCloseStock, setShowOpenCloseStock] = useState(false);
    const [openingStock, setOpeningStock] = useState(0);
    const [closingStock, setClosingStock] = useState(0);
    const [range, setRange] = useState(localStorage.getItem('itemWiseAnalyseRange') || null)

    var start_date = range !== null && new Date(localStorage.getItem('itemWiseAnalyseRange').split('-')[0]).toDateString();
    var end_date = localStorage.getItem('itemWiseAnalyseRange') && new Date(localStorage.getItem('itemWiseAnalyseRange').split('-')[1]).toDateString();

    console.log(start_date);
    console.log(end_date);

    const [rangeDate, setRangeDate] = useState(localStorage.getItem('itemWiseAnalyseRange') && localStorage.getItem('itemWiseAnalyseRange') !== "null" ? { startDate: new Date(start_date), endDate: new Date(end_date) } : { startDate: new Date(), endDate: new Date() })

    console.log(rangeDate);
    console.log(range);

    const [itemOptions, setItemOptions] = useState([]);
    const [branchOptions, setBranchOptions] = useState([]);
    const [itemCode, setItemCode] = useState(JSON.parse(localStorage.getItem('itemWiseAnalyseItemName')) || '');
    const [branchCode, setBranchCode] = useState(JSON.parse(localStorage.getItem('itemWiseAnalyseBranchName')) || '');

    const [isLoading, setIsLoading] = useState(false);

    const udfnFormValidate = (e) => {
        if (itemCode?.value === '' || itemCode?.value === null || itemCode?.value === undefined) {
            toast.error(ERROR.ITEMWISE_ANALYSIS_REPORT.ITEM_VALID, { position: toast.POSITION.TOP_RIGHT });
        } else {
            udfnItemwiseAnalysisList();
        }
    };

    const udfnItemNameList = async () => {
        setIsLoading(true)
        try {

            const itemParams = { user_id: Number(user?.id), status_code: Number(0) };

            const [itemData] = await Promise.all([
                axios.post(BASE_URL + COMMON_API.ITEM_MASTER_LIST, itemParams, config)
            ]);

            if (itemData?.data?.body?.status === COMMONVARIABLES.STATUS.INVALIDUSER) {
                setIsLoading(false)
                var errorMsg = itemData?.data?.body?.message;
                toast.error(errorMsg, { position: toast.POSITION.TOP_RIGHT });
                UserLogout();
            }

            if (itemData?.data?.body?.status === COMMONVARIABLES.STATUS.UNAUTHORIZED) {
                setIsLoading(false)
                const refresh = await getRefreshToken();
                if (refresh) {
                    if (Number(refresh) === COMMONVARIABLES.STATUS.EXPIRED_TOKEN) {
                        UserLogout();
                    } else {
                        await udfnItemNameList();
                    }
                }
            }

            if (itemData?.data?.body?.itemList) {
                setItemOptions(itemData?.data?.body?.itemList)
                setIsLoading(false)
            }
        } catch (error) {
            setIsLoading(false)
            console.error('Error fetching master data:', error);
        }
    }

    const udfnBranchNameList = async () => {
        setIsLoading(true)
        try {

            const branchParams = { user_id: Number(user?.id), status_code: Number(0) };

            const [branchData] = await Promise.all([
                axios.post(BASE_URL + COMMON_API.BRANCH_MASTER_LIST, branchParams, config)
            ]);

            if (branchData?.data?.body?.status === COMMONVARIABLES.STATUS.INVALIDUSER) {
                setIsLoading(false)
                var errorMsg = branchData?.data?.body?.message;
                toast.error(errorMsg, { position: toast.POSITION.TOP_RIGHT });
                UserLogout();
            }

            if (branchData?.data?.body?.status === COMMONVARIABLES.STATUS.UNAUTHORIZED) {
                setIsLoading(false)
                const refresh = await getRefreshToken();
                if (refresh) {
                    if (Number(refresh) === COMMONVARIABLES.STATUS.EXPIRED_TOKEN) {
                        UserLogout();
                    } else {
                        await udfnBranchNameList();
                    }
                }
            }

            if (branchData?.data?.body?.branchList) {
                setBranchOptions(branchData?.data?.body?.branchList)
                setIsLoading(false)
            }
        } catch (error) {
            setIsLoading(false)
            console.error('Error fetching master data:', error);
        }
    }

    async function udfnItemwiseAnalysisList() {
        try {
            setIsLoading(true)
            var varFromDate = new Date().toDateString()
            var varToDate = new Date().toDateString()
            if (range !== "null" && range !== null) {
                varFromDate = new Date(range.split('-')[0]).toDateString()
                varToDate = new Date(range.split('-')[1]).toDateString()
            }

            const params = {
                user_id: Number(user?.id),
                from_date: varFromDate,
                to_date: varToDate,
                item_code: itemCode?.value === '' || itemCode?.value === undefined || itemCode?.value === null ? 0 : itemCode?.value,
                branch_code: branchCode?.value === '' || branchCode?.value === undefined || branchCode?.value === null ? 0 : branchCode?.value,
            };

            const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } };
            const [itemAnalysisList] = await Promise.all([
                axios.post(BASE_URL + MASTER_API.ITEMWISE_ANALYSIS_LIST, params, config),
            ]);

            if (itemAnalysisList && itemAnalysisList?.data?.body?.status === COMMONVARIABLES.STATUS.INVALIDUSER) {
                setIsLoading(false)
                toast.error(itemAnalysisList?.data?.body?.message, { position: toast.POSITION.TOP_RIGHT });
                UserLogout();
            }

            if (itemAnalysisList?.data?.body?.status === COMMONVARIABLES.STATUS.UNAUTHORIZED) {
                setIsLoading(false)
                const refresh = await getRefreshToken();
                if (refresh) {
                    if (Number(refresh) === COMMONVARIABLES.STATUS.EXPIRED_TOKEN) {
                        UserLogout();
                    } else {
                        await udfnItemwiseAnalysisList();
                    }
                }
            }
            setShowOpenCloseStock(true)
            if (itemAnalysisList?.data?.body?.itemAnalysisReport) {
                setItemAnalysisList(itemAnalysisList?.data?.body?.itemAnalysisReport)
            }
            if (itemAnalysisList?.data?.body?.openingStock) {
                setOpeningStock(itemAnalysisList?.data?.body?.openingStock)
            }
            if (itemAnalysisList?.data?.body?.closingStock) {
                setClosingStock(itemAnalysisList?.data?.body?.closingStock)
            }
            setIsLoading(false)
        } catch (error) {
            setIsLoading(false)
            console.error('Error fetching master data:', error);
        }
    }

    const exportToExcel = async () => {
        // itemAnalysisList[{ "trans_type": "Purchase", "trans_date": "2024-04-03T18:30:00.000Z", "trans_date_time": "2024-04-03T18:30:00.000Z", "item_code": 3, "inward": 30, "outward": 0, "closing": 30, "finalclosing": 60 }]

        if (itemAnalysisList.length > 0) {
            setIsLoading(true)
            setExcelButtonFlag(true)
            const array_data = itemAnalysisList
            const array_data_count = itemAnalysisList.length
            const opening_stock = openingStock
            const closing_stock = closingStock

            // colorWiseTotalDispatch
            let Itemwise_Analysis_Report = []

            Itemwise_Analysis_Report = array_data.map((item, index) => {
                var trans_date = moment(item.trans_date).format('DD-MM-YYYY')
                return {
                    "S.NO.": index + 1, "DATE": trans_date, "TYPE": item.trans_type, "INWARD": item.inward, "OUTWARD": item.outward, "CLOSING": item.finalclosing
                }
            })

            const data_array = []
            Itemwise_Analysis_Report.forEach((row) => {
                data_array.push(Object.values(row))
            })

            var varFromDate = new Date().toDateString()
            var varToDate = new Date().toDateString()
            if (range !== "null" && range !== null) {
                varFromDate = new Date(range.split('-')[0]).toDateString()
                varToDate = new Date(range.split('-')[1]).toDateString()
            }

            const title = 'Itemwise Analysis Report On (' + moment(varFromDate).format("DD-MM-YYYY") + ') to (' + moment(varToDate).format("DD-MM-YYYY") + ')'

            const reportData = {
                title: title,
                data: data_array,
                headers: Object.keys(Itemwise_Analysis_Report[0])
            }

            const workbook = new ExcelJS.Workbook()
            const worksheet = workbook.addWorksheet('Itemwise Analysis')

            worksheet.mergeCells('A1', 'F1')
            const titleRow = worksheet.getCell('A1')
            titleRow.value = reportData['title']
            titleRow.font = {
                name: 'Calibri',
                size: 16,
                bold: true,
                color: { argb: '000000' }
            }
            titleRow.alignment = { vertical: 'middle', horizontal: 'center' }

            worksheet.mergeCells('A2', 'C2')
            const item_filter_head = 'Item Name : '
            const item_filter_label = itemCode?.label
            const itemFilterRow = worksheet.getCell('A2')
            itemFilterRow.value = item_filter_head + item_filter_label
            itemFilterRow.font = {
                name: 'Calibri',
                size: 12,
                color: { argb: '00000' }
            }
            // itemFilterRow.alignment = { vertical: 'middle', horizontal: 'center' }

            worksheet.mergeCells('D2', 'F2')
            const branch_filter_head = 'Branch Name : '
            const branch_filter_label = branchCode ? branchCode?.label : "All"
            const branchFilterRow = worksheet.getCell('D2')
            branchFilterRow.value = branch_filter_head + branch_filter_label
            branchFilterRow.font = {
                name: 'Calibri',
                size: 12,
                color: { argb: '00000' }
            }
            // branchFilterRow.alignment = { vertical: 'middle', horizontal: 'center' }

            const opening = ['', '', '', '', 'Opening', opening_stock]
            const openingStockRow = worksheet.addRow(opening)
            openingStockRow.eachCell((cell) => {
                cell.font = {
                    bold: true,
                    color: { argb: '000000' },
                    size: 14
                }
            })
            openingStockRow.alignment = { vertical: 'middle', horizontal: 'right' }


            //Adding Header Row
            const headerRow = worksheet.addRow(reportData['headers'])
            headerRow.eachCell((cell) => {
                cell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'ff8b3d' },
                    bgColor: { argb: '' }
                }
                cell.font = {
                    bold: true,
                    color: { argb: 'FFFFFF' },
                    size: 12
                }
            })

            data_array.forEach(item => {
                worksheet.addRow(item)
            })

            const closing = ['', '', '', '', 'Closing', closing_stock]
            const closingStockRow = worksheet.addRow(closing)
            closingStockRow.eachCell((cell) => {
                cell.font = {
                    bold: true,
                    color: { argb: '000000' },
                    size: 14
                }
            })
            closingStockRow.alignment = { vertical: 'middle', horizontal: 'right' }

            worksheet.getColumn(1).width = 8
            worksheet.getColumn(2).width = 15
            worksheet.getColumn(3).width = 15
            worksheet.getColumn(4).width = 15
            worksheet.getColumn(5).width = 15
            worksheet.getColumn(6).width = 15

            // worksheet.getColumn(4).alignment = { vertical: 'middle', horizontal: 'right', wrapText: true }
            // worksheet.getColumn(5).alignment = { vertical: 'middle', horizontal: 'right', wrapText: true }

            var starting_cell = 4;
            var last_cell = array_data_count + starting_cell

            for (let i = starting_cell; i <= last_cell; i++) {
                const inwardColumn = worksheet.getCell('D' + i)
                const outwardColumn = worksheet.getCell('E' + i)
                const closingColumn = worksheet.getCell('F' + i)
                inwardColumn.alignment = { vertical: 'middle', horizontal: 'right' }
                outwardColumn.alignment = { vertical: 'middle', horizontal: 'right' }
                closingColumn.alignment = { vertical: 'middle', horizontal: 'right' }
            }

            // Save the workbook
            const buffer = await workbook.xlsx.writeBuffer()
            const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })

            const url = URL.createObjectURL(blob)
            const a = document.createElement('a')
            a.href = url
            a.download = `Itemwise Analysis Report.xlsx`
            document.body.appendChild(a)
            a.click()
            document.body.removeChild(a)

            setIsLoading(false)
            setExcelButtonFlag(false)
        } else {
            toast.error(ERROR_MESSAGE.EXPORT_TO_EXCEL_NO_RECORD, { position: toast.POSITION.TOP_RIGHT });
        }
    }

    useEffect(() => {
        setItemAnalysisList([])
        setOpeningStock(0)
        setClosingStock(0)
    }, [range, itemCode?.value, branchCode?.value]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        udfnItemNameList()
        udfnBranchNameList()
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        localStorage.setItem('itemWiseAnalyseItemName', JSON.stringify(itemCode))
        localStorage.setItem('itemWiseAnalyseRange', range)
        localStorage.setItem('itemWiseAnalyseBranchName', JSON.stringify(branchCode))
    }, [range, itemCode, branchCode])  // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <ToastContainer />
            <LoadingOverlay
                active={isLoading}
                spinner
                text='Loading ...'
            >
                <div className="card">
                    <div className="card-header pt-3 pb-0">
                        <div className='col-sm-5 mb-2'>
                            <h4 className="card-title">Itemwise Analysis Report</h4>
                        </div>
                        <div className='col-sm-4 text-end'>
                            <Button variant="info" className="mb-2" onClick={() => exportToExcel()} disabled={excelButtonFlag ? true : false}>
                                <i className="fa fa-download" /> &nbsp; Export
                            </Button>
                        </div>
                    </div>
                    <div className="card-body">
                        <Accordion className="accordion accordion-rounded-stylish accordion-bordered  accordion-active-header" defaultActiveKey="0">
                            <Accordion.Item eventKey="0">
                                <Accordion.Header className="accordion-header accordion-header-primary">
                                    <h5>Filters</h5>
                                    <span className="accordion-header-indicator "></span>
                                </Accordion.Header>
                                <Accordion.Collapse className="accordion__body" eventKey="0">
                                    <div className="accordion-body">
                                        <div className="row">
                                            <div className="col-md-3 mb-3">
                                                <div className="example rangeDatePicker">
                                                    <p className="mb-1">Select From Date And To Date <span className="text-danger star-txt">*</span></p>
                                                    <DateRangePicker
                                                        onApply={(e) => {
                                                            setRange(e.target.value)
                                                        }}
                                                        initialSettings={rangeDate}
                                                    >
                                                        <input type="text" className="form-control input-daterange-timepicker" styles={{ height: "90%" }} />
                                                    </DateRangePicker>
                                                </div>
                                            </div>
                                            <div className="col-md-3 mb-3">
                                                <p className="mb-1">Item Name <span className="text-danger star-txt">*</span></p>
                                                <Select
                                                    defaultValue={itemCode}
                                                    value={itemCode}
                                                    onChange={setItemCode}
                                                    options={itemOptions.map((option) => ({
                                                        label: option.im_item_name,
                                                        value: option.im_item_code,
                                                    }))}
                                                    isClearable
                                                    showContent="true"
                                                    open="true"
                                                    className="my-react-select-container"
                                                    theme={theme => ({
                                                        ...theme,
                                                        borderRadius: '6px',
                                                        colors: {
                                                            ...theme.colors,
                                                            primary25: '#b3b3b3',
                                                            primary: 'black',
                                                            neutral80: "data-theme-version" === "dark" ? 'white' : ''
                                                        },
                                                    })}
                                                />
                                            </div>
                                            <div className="col-md-3 mb-3">
                                                <p className="mb-1">Branch Name <span className="text-danger star-txt"></span></p>
                                                <Select
                                                    defaultValue={branchCode}
                                                    value={branchCode}
                                                    onChange={setBranchCode}
                                                    options={branchOptions.map((option) => ({
                                                        label: option.bm_branch_name,
                                                        value: option.bm_branch_code,
                                                    }))}
                                                    isClearable
                                                    showContent="true"
                                                    open="true"
                                                    className="my-react-select-container"
                                                    theme={theme => ({
                                                        ...theme,
                                                        borderRadius: '6px',
                                                        colors: {
                                                            ...theme.colors,
                                                            primary25: '#b3b3b3',
                                                            primary: 'black',
                                                            neutral80: "data-theme-version" === "dark" ? 'white' : ''
                                                        },
                                                    })}
                                                />
                                            </div>
                                            <div className="col-md-2 mt-3">
                                                <Button className="me-2" variant="whatsapp" style={{ marginTop: "6%" }} onClick={udfnFormValidate}>
                                                    List{" "}
                                                    <span className="btn-icon-right">
                                                        <i className="fa fa-filter" />
                                                    </span>
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </Accordion.Collapse>
                            </Accordion.Item>
                        </Accordion>
                        {
                            showOpenCloseStock ? <div className='text-end mt-3 mb-3' style={{ paddingRight: '16px' }}> Opening :<span className='text-black font-w400 fs-16'> {openingStock} </span> </div> : ""
                        }

                        <div className="table-responsive">
                            <div className='row'>
                                <div className='col-sm-9'>
                                    {/* <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} /> */}
                                </div>
                            </div>
                            <table {...getTableProps()} className="table filtering-table table-responsive-lg">
                                <thead>
                                    {headerGroups.map((headerGroup, index) => (
                                        <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                                            {headerGroup.headers.map((column, index) => (
                                                <>
                                                    {column.Header === 'Inward' || column.Header === 'Outward' || column.Header === 'Closing' ?
                                                        <th className='text-end' key={index}>
                                                            {column.render('Header')}
                                                        </th>
                                                        :
                                                        <th key={index}>
                                                            {column.render('Header')}
                                                        </th>
                                                    }
                                                </>
                                            ))}
                                        </tr>
                                    ))}
                                </thead>
                                <tbody {...getTableBodyProps()} className="" >
                                    {page.map((row, index) => {
                                        return (
                                            <tr key={index}>
                                                <td width='1%'>{index + 1}</td>
                                                <td>
                                                    {/* {row.original.trans_date}  */}
                                                    {moment(row.original.trans_date).format('DD-MM-YYYY')}
                                                </td>
                                                <td>{row.original.trans_type}</td>
                                                <td className='text-end' style={{ paddingRight: '16px' }}>{row.original.inward}</td>
                                                <td className='text-end' style={{ paddingRight: '16px' }}>{row.original.outward}</td>
                                                <td className='text-end' style={{ paddingRight: '16px' }}>{row.original.finalclosing}</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                            {
                                showOpenCloseStock ?
                                    <div className='text-end mt-3 mb-3' style={{ paddingRight: '16px' }}>
                                        Closing :<span className='text-black font-w400 fs-16'> {closingStock} </span>
                                    </div>
                                    : ""
                            }
                            <div className='text-center'>
                                {itemAnalysisList.length < 1 ? "No records found." : ""}
                            </div>
                            {/* <div className="d-flex justify-content-between">
                                <span>
                                    Page{' '}
                                    <strong>
                                        {pageIndex + 1} of {pageOptions.length}
                                    </strong>{''}
                                </span>
                                <span className="table-index">
                                    Go to page : {' '}
                                    <input type="number"
                                        className="ms-2"
                                        defaultValue={pageIndex + 1}
                                        onChange={e => {
                                            const pageNumber = e.target.value ? Number(e.target.value) - 1 : 0
                                            gotoPage(pageNumber)
                                        }}
                                    />
                                </span>
                            </div>
                            <div className="text-center">
                                <div className="filter-pagination  mt-3">
                                    <button className=" previous-button" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>{'<<'}</button>

                                    <button className="previous-button" onClick={() => previousPage()} disabled={!canPreviousPage}>
                                        Previous
                                    </button>
                                    <button className="next-button" onClick={() => nextPage()} disabled={!canNextPage}>
                                        Next
                                    </button>
                                    <button className=" next-button" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>{'>>'}</button>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </LoadingOverlay>
        </>
    )
}
export default ItemwiseAnalysisReport;