
const ERROR_MESSAGE = {

    EXPORT_TO_EXCEL_NO_RECORD: "There is no record to export.",
    COMMON_REQUIRED: "Please fill all mandatory field.",
    PINCODE_VALID: "Pincode must be valid 6 digits.",
    MOBILE_VALID: "Mobile Number must be valid 10 digits.",
    EMAIL_VALID: "Email is not valid.",
    WEBSITE_VALID: "Website URL is not valid.",
    GSTIN_VALID: "GSTIN Number is not valid.",
    CITY_VALID: "City Name does not contain number or special characters.",
    BANK_NAME_VALID: "Please enter valid Bank Name.",
    SELLING_RATE_VALID: "Selling rate should be greater than purchase rate",
    ACCOUNT_NUMBER_VALID: "Account Number does not contain special characters",
    IFSC_CODE_VALID: "IFSC Code does not contain special characters",
    BRANCH_REQUIRED: "Please select the branch.",

    // Login Screen
    LOGIN_SCREEN: {
        USERID_VALID: "Please enter valid User Id.",
    },

    COMPANY_MASTER: {
        LABEL_TITLE_VALID: "Label Title only contain Alphabet and Number and &",
    },

    USER_MASTER: {
        USER_NAME_VALID: "Please enter valid User Name.",
        USERID_VALID: "Please enter valid User Id.",
        USERID_LENGTH_VALID: "UserID must greater than 4 letters.",
        PASSWORD_VALID: "Password must contain 6 characters including one capital letter , one numerical and one special character.",
        NEW_PASSWORD_VALID: "New password and confirm password must be same.",
    },

    SALESMAN_MASTER: {
        SALESMAN_CODE_VALID: "Please enter valid SalesMan Code.",
    },

    CHANGE_PASSWORD: {
        OLD_PASSWORD_VALID: "Old Password is Incorrect.",
        CHANGE_PASSWORD_VALID: "New Password And Confirm Password Must be Same.",
    },

    STOCK_TRANSFER_ADD: {
        ITEM_DETAILS_VALID: "Please fill atleast 1 item details.",
        ITEM_QTY_VALID: "Please enter valid quantity."
    },

    UNIT_MASTER: {
        NO_OF_DECIMAL_VALID: "Please enter valid Number Of Decimals."
    },

    SALESMAN_PRINT_MASTER: {
        SALESMAN_NO_OF_PRINT_VALID: "Please enter no.of Prints.",
    },

    DISCOUNT_BULK_UPDATE_MASTER: {
        SELECT_VALID: "Please select any one group name or category name or item name",
    },

    API_ERROR: {
        NETWORK_ERROR: "Server Not Reachable",
        BAD_REQUEST_ERROR: "Bad Request",
        SERVICE_CATCH_ERROR: "Something Went Wrong"
    },

    ITEM_MASTER: {
        ITEM_VALID: "Please enter valid Item Name.",
        HSN_CODE_VALID: "Please enter valid HSN Code.",
        MANUAL_CODE_VALID: "Manual Code does not contain Special characters.",
    },

    BRANCH: {
        CONTACT_NO_VALID: "Please enter valid contact number",
        TELEPHONE_NO_VALID: "Please enter valid telephone number"
    },

    ITEMWISE_ANALYSIS_REPORT: {
        ITEM_VALID: "Please select Item Name.",
    }
};

export default ERROR_MESSAGE;
