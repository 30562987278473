import React, { useEffect, useRef, useState } from 'react';
import { Link, useLocation } from "react-router-dom";
import { Button } from 'react-bootstrap';
import '../../Filters/Common/filtering.css';
import Select from "react-select";
import { useNavigate } from 'react-router-dom';
import "react-datepicker/dist/react-datepicker.css";
import ERROR from "../../../components/Validation/ErrorMessage"
import { ToastContainer, toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import COMMON_API from '../../../Api/CommonApi';
import axios from "axios";
import { getLoggedInUser, getRefreshToken } from '../../../../services/AuthService';
import { Logout } from '../../../../store/actions/AuthActions';
import COMMONVARIABLES from '../../Utilities/constants';
import STOCK_TRANSFER_API from '../../../Api/StockTransferApi';
import swal from "sweetalert";

import LoadingOverlay from 'react-loading-overlay-ts';
import { useReactToPrint } from 'react-to-print';
import Logo from '../../../../pictures/bw1.png';
export const StockTransferAdd = () => {
    const paramData = useLocation();
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    let navigate = useNavigate();
    const [transNo, setTransNo] = useState(0);
    const [transferNo, setTransferNo] = useState('');
    const [transferDate, setTransferDate] = useState("");
    const [transferFrom, setTransferFrom] = useState('');
    const [transferTo, setTransferTo] = useState('');
    const [itemCode, setItemCode] = useState('');
    const [itemName, setItemName] = useState('');
    const [itemQty, setItemQty] = useState('');
    const [narration, setNarration] = useState('');

    const [transferFromBranchOptions, setTransferFromBranchOptions] = useState([])
    const [transferToBranchOptions, setTransferToBranchOptions] = useState([])

    const [itemDetails, setItemDetails] = useState([]);
    const [isEdit, setIsEdit] = useState(0)
    const [currentSno, setCurrentSno] = useState(0)
    const [isLoading, setIsLoading] = useState(false);
    const [fromBranchDisabled, setFromBranchDisabled] = useState(false);
    const [toBranchDisabled, setToBranchDisabled] = useState(false);
    const [title, setTitle] = useState('Add Stock Transfer')
    const [btnTitle, setBtnTitle] = useState('Save')
    const [buttonFlag, setButtonFlag] = useState(false);

    const buttonRef = useRef(null);



    // For Print data
    const [transferNoPrint, setTransferNoPrint] = useState('');
    const [transferDatePrint, setTransferDatePrint] = useState("");
    const [transferFromPrint, setTransferFromPrint] = useState('');
    const [transferToPrint, setTransferToPrint] = useState('');
    const [itemDetailsPrint, setItemDetailsPrint] = useState([]);
    const [narrationPrint, setNarrationPrint] = useState('');
    const [localUserDetails, setLocalUserDetails] = useState(null)

    const [transferBranchOptions, setTransferBranchOptions] = useState([])
    const contentToPrint = useRef(null);
    const [isPrint, setIsPrint] = useState(0)
    useEffect(() => {
        var lcluserDetails = localStorage.getItem("userDetails")
        setLocalUserDetails(JSON.parse(lcluserDetails))

    }, [])  // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (isPrint == 1) {

            handlePrint(null, () => contentToPrint.current);
            setIsPrint(0)
        }



    }, [itemDetailsPrint])

    const handlePrint = useReactToPrint({
        documentTitle: "Print This Document",

        onBeforePrint: () => {
            let temp = document.querySelectorAll(".printfooter");
            // alert(temp.length)
            for (let i = 0; i < temp.length; i++) {
                if ((i + 1) == temp.length)
                    temp[i].style.display = "block";

            }
        },
        onAfterPrint: () => {
            navigate("/stock-transfer-list")
        },
        removeAfterPrint: true,
    });



    const handleKeyDown = (event) => {
        // Check if the pressed key is the Tab key (key code 9)
        if (event.keyCode === 9) {
            // Prevent the default Tab behavior to prevent losing focus from the text input
            event.preventDefault();

            // Focus the button element
            buttonRef.current.focus();
        }
    };

    useEffect(() => {
        fetchMasterDataWithParams()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (paramData?.state?.transNo > 0 && transferFromBranchOptions.length > 0) {
            setTransNo(paramData.state.transNo)
            setTitle('Update Stock Transfer')
            setBtnTitle('Update')
            editLoadData(paramData.state.transNo, 0)
        }
    }, [transferFromBranchOptions]) // eslint-disable-line react-hooks/exhaustive-deps

    async function fetchMasterDataWithParams() {
        try {

            var localFy = localStorage.getItem("fy")


            const user = getLoggedInUser()
            const branchParams = { status_code: 1, user_id: user?.id };
            const transNoParams = { fy: localFy ? Number(localFy) : 0, user_id: user?.id };


            const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } };
            const [branchData, stockTransData] = await Promise.all([
                axios.post(BASE_URL + COMMON_API.BRANCH_MASTER_LIST, branchParams, config),
                axios.post(BASE_URL + STOCK_TRANSFER_API.STOCK_TRANSFER_NO, transNoParams, config),

            ]);

            if (branchData?.data?.body?.status === COMMONVARIABLES.STATUS.UNAUTHORIZED || stockTransData?.data?.body?.status === COMMONVARIABLES.STATUS.UNAUTHORIZED) {


                const refresh = await getRefreshToken();

                if (refresh) {
                    if (Number(refresh) === COMMONVARIABLES.STATUS.EXPIRED_TOKEN) {
                        Logout();
                    } else {
                        await fetchMasterDataWithParams();
                    }
                }
            }

            if (branchData?.data?.body?.status === COMMONVARIABLES.STATUS.SERVICE_CATCH_STATUS) {
                toast.error(ERROR.API_ERROR.SERVICE_CATCH_ERROR, { position: toast.POSITION.TOP_RIGHT });
            }

            if (branchData?.data?.body?.branchList || stockTransData?.data?.body?.transfer_no) {
                setTransferFromBranchOptions(branchData?.data?.body?.branchList)
                setTransferToBranchOptions(branchData?.data?.body?.branchList)
                setTransferNo(stockTransData?.data?.body?.transfer_no)
                // var currentDate = new Date(stockTransData?.data?.body?.transfer_date);
                setTransferDate(stockTransData?.data?.body?.transfer_date)


            }


        } catch (error) {
            console.error('Error fetching master data:', error);
            if (error.code === COMMONVARIABLES.STATUS.NETWORK_ERROR_STATUS) {
                toast.error(ERROR.API_ERROR.NETWORK_ERROR, { position: toast.POSITION.TOP_RIGHT });
            }
            if (error.code === COMMONVARIABLES.STATUS.BAD_REQUEST_ERROR_STATUS) {
                toast.error(ERROR.API_ERROR.BAD_REQUEST_ERROR, { position: toast.POSITION.TOP_RIGHT });
            }
        }
    }
    async function fetchItemListFromQrCode(qrCode) {
        try {
            const user = getLoggedInUser()

            const itemListParams = { qr_code: Number(qrCode), user_id: user?.id, branch_code: Number(transferFrom?.value || 0) };


            const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } };
            const [itemData] = await Promise.all([
                axios.post(BASE_URL + COMMON_API.ITEM_LIST_FROM_QRCODE, itemListParams, config),

            ]);

            if (itemData?.data?.body?.status === COMMONVARIABLES.STATUS.UNAUTHORIZED) {


                const refresh = await getRefreshToken();

                if (refresh) {
                    if (Number(refresh) === COMMONVARIABLES.STATUS.EXPIRED_TOKEN) {
                        Logout();
                    } else {
                        await fetchItemListFromQrCode(qrCode);
                    }
                }
            }

            if (itemData?.data?.body?.status === COMMONVARIABLES.STATUS.SERVICE_CATCH_STATUS) {
                toast.error(ERROR.API_ERROR.SERVICE_CATCH_ERROR, { position: toast.POSITION.TOP_RIGHT });
            }

            if (itemData?.data?.body?.itemList) {

                setItemName(itemData?.data?.body?.itemList[0])
            }


        } catch (error) {
            console.error('Error fetching master data:', error);
            if (error.code === COMMONVARIABLES.STATUS.NETWORK_ERROR_STATUS) {
                toast.error(ERROR.API_ERROR.NETWORK_ERROR, { position: toast.POSITION.TOP_RIGHT });
            }
            if (error.code === COMMONVARIABLES.STATUS.BAD_REQUEST_ERROR_STATUS) {
                toast.error(ERROR.API_ERROR.BAD_REQUEST_ERROR, { position: toast.POSITION.TOP_RIGHT });
            }
        }
    }
    async function editLoadData(transNo, is_print) {
        try {
            setIsLoading(true)
            const user = getLoggedInUser()

            const editLoadParams = { transaction_no: Number(transNo), user_id: user?.id };


            const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } };
            const [editLoadData] = await Promise.all([
                axios.post(BASE_URL + STOCK_TRANSFER_API.EDIT_STOCK_TRASFER, editLoadParams, config),

            ]);



            if (editLoadData?.data?.body?.status === COMMONVARIABLES.STATUS.UNAUTHORIZED) {
                setIsLoading(false)

                const refresh = await getRefreshToken();

                if (refresh) {
                    if (Number(refresh) === COMMONVARIABLES.STATUS.EXPIRED_TOKEN) {
                        Logout();
                    } else {
                        await editLoadData(transNo, is_print)
                    }
                }
            }

            if (editLoadData?.data?.body?.status === COMMONVARIABLES.STATUS.SERVICE_CATCH_STATUS) {
                toast.error(ERROR.API_ERROR.SERVICE_CATCH_ERROR, { position: toast.POSITION.TOP_RIGHT });
            }

            if (is_print == 1) {
                if (editLoadData?.data?.body?.stockTransferList) {

                    setTransferNoPrint(editLoadData?.data?.body?.stockTransferList[0].st_transfer_no)
                    // setTransferDate(new Date(editLoadData?.data?.body?.stockTransferList[0].st_date).toDateString())
                    setTransferDatePrint(editLoadData?.data?.body?.stockTransferList[0].st_date)
                    const transferFromBranchArr = Object.assign([], transferFromBranchOptions)
                    const branchFrom = transferFromBranchArr.filter((e) => Number(e.bm_branch_code) === Number(editLoadData.data.body.stockTransferList[0].st_from_branch_code));
                    const branchFromObj = {
                        label: branchFrom[0].bm_branch_name,
                        value: branchFrom[0].bm_branch_code,
                    }
                    setTransferFromPrint({
                        branch_name:editLoadData?.data?.body?.stockTransferList[0]?.from_branch_name,
                        branch_address:editLoadData?.data?.body?.stockTransferList[0]?.from_branch_address,
                        branch_city:editLoadData?.data?.body?.stockTransferList[0]?.from_branch_city,
                    })
                    const transferToBranchArr = Object.assign([], transferToBranchOptions)
                    const branchTo = transferToBranchArr.filter((e) => Number(e.bm_branch_code) === Number(editLoadData.data.body.stockTransferList[0].st_to_branch_code));
                    const branchToObj = {
                        label: branchTo[0].bm_branch_name,
                        value: branchTo[0].bm_branch_code,
                    }
                    setTransferToPrint({
                        branch_name:editLoadData?.data?.body?.stockTransferList[0]?.to_branch_name,
                        branch_address:editLoadData?.data?.body?.stockTransferList[0]?.to_branch_address,
                        branch_city:editLoadData?.data?.body?.stockTransferList[0]?.to_branch_city,
                    })
                    var stockTransferItemArray = []
                    for (let i = 0; i < editLoadData.data.body.stockTransferItemList.length; i++) {

                        var itemObj = {
                            sno: i + 1,
                            itemcode: editLoadData.data.body.stockTransferItemList[i].si_item_code,
                            qrcode: editLoadData.data.body.stockTransferItemList[i].si_qr_code,
                            itemUnit: editLoadData.data.body.stockTransferItemList[i].unit_name,
                            itemName: editLoadData.data.body.stockTransferItemList[i].item_name,
                            qty: parseFloat(editLoadData.data.body.stockTransferItemList[i].si_qty).toFixed(editLoadData.data.body.stockTransferItemList[i].um_no_of_decimals),
                            um_no_of_decimals: editLoadData.data.body.stockTransferItemList[i].um_no_of_decimals,
                            im_item_name: editLoadData.data.body.stockTransferItemList[i].im_item_name,
                            selling_rate: editLoadData.data.body.stockTransferItemList[i].selling_rate
                        }
                        stockTransferItemArray.push(itemObj)
                        setItemDetailsPrint(stockTransferItemArray)
                    }


                    setNarrationPrint(editLoadData?.data?.body?.stockTransferList[0].st_narration)
                    setIsPrint(1)
                    setIsLoading(false)

                } else {
                    setIsLoading(false)
                }
            } else {
                if (editLoadData?.data?.body?.stockTransferList) {

                    setTransferNo(editLoadData?.data?.body?.stockTransferList[0].st_transfer_no)
                    // setTransferDate(new Date(editLoadData?.data?.body?.stockTransferList[0].st_date).toDateString())
                    setTransferDate(editLoadData?.data?.body?.stockTransferList[0].st_date)
                    const transferFromBranchArr = Object.assign([], transferFromBranchOptions)
                    const branchFrom = transferFromBranchArr.filter((e) => Number(e.bm_branch_code) === Number(editLoadData.data.body.stockTransferList[0].st_from_branch_code));
                    const branchFromObj = {
                        label: branchFrom[0].bm_branch_name,
                        value: branchFrom[0].bm_branch_code,
                    }
                    setTransferFrom(branchFromObj)
                    const transferToBranchArr = Object.assign([], transferToBranchOptions)
                    const branchTo = transferToBranchArr.filter((e) => Number(e.bm_branch_code) === Number(editLoadData.data.body.stockTransferList[0].st_to_branch_code));
                    const branchToObj = {
                        label: branchTo[0].bm_branch_name,
                        value: branchTo[0].bm_branch_code,
                    }
                    setTransferTo(branchToObj)
                    var stockTransferItemArray = []
                    for (let i = 0; i < editLoadData.data.body.stockTransferItemList.length; i++) {

                        var itemObj = {
                            sno: i + 1,
                            itemcode: editLoadData.data.body.stockTransferItemList[i].si_item_code,
                            qrcode: editLoadData.data.body.stockTransferItemList[i].si_qr_code,
                            itemUnit: editLoadData.data.body.stockTransferItemList[i].unit_name,
                            itemName: editLoadData.data.body.stockTransferItemList[i].item_name,
                            qty: parseFloat(editLoadData.data.body.stockTransferItemList[i].si_qty).toFixed(editLoadData.data.body.stockTransferItemList[i].um_no_of_decimals),
                            um_no_of_decimals: editLoadData.data.body.stockTransferItemList[i].um_no_of_decimals,
                            im_item_name: editLoadData.data.body.stockTransferItemList[i].im_item_name
                        }
                        stockTransferItemArray.push(itemObj)
                        setItemDetails(stockTransferItemArray)
                    }


                    setNarration(editLoadData?.data?.body?.stockTransferList[0].st_narration)
                    setFromBranchDisabled(true)
                    setToBranchDisabled(true)

                    setIsLoading(false)
                } else {
                    setIsLoading(false)
                }
            }




        } catch (error) {
            setIsLoading(false)
            console.error('Error fetching master data:', error);
            if (error.code === COMMONVARIABLES.STATUS.NETWORK_ERROR_STATUS) {
                toast.error(ERROR.API_ERROR.NETWORK_ERROR, { position: toast.POSITION.TOP_RIGHT });
            }
            if (error.code === COMMONVARIABLES.STATUS.BAD_REQUEST_ERROR_STATUS) {
                toast.error(ERROR.API_ERROR.BAD_REQUEST_ERROR, { position: toast.POSITION.TOP_RIGHT });
            }
        }
    }

    function clearAddData() {
        setItemName('');
        setItemCode('');
        setItemQty('');
        setIsEdit(0)
    }
    function itemDetailsValidate(e) {
        e.preventDefault();
        var error = "";
        if (itemCode === '' || itemName === '' || itemQty === '') {
            error = ERROR.COMMON_REQUIRED;
        } else if (itemName?.stock < itemQty || itemQty === '0') {
            error = ERROR.STOCK_TRANSFER_ADD.ITEM_QTY_VALID
        }
        else {
            error = '';
        }
        if (error === '') {
            const formattedValue = parseFloat(itemQty).toFixed(itemName?.um_no_of_decimals);
            var itemObj = {
                sno: isEdit !== 1 ? itemDetails.length + 1 : currentSno,
                itemcode: isEdit !== 1 ? itemName.qs_item_code : itemName.itemcode,
                qrcode: itemCode,
                itemUnit: itemName.um_display_name,
                itemName: itemName.im_display_name,
                qty: formattedValue,
                um_no_of_decimals: itemName.um_no_of_decimals,
                im_unit_code: itemName.im_unit_code,
                im_item_name: itemName.im_item_name,
                stock: itemName.stock,
                selling_rate: itemName.selling_rate
            }
            if (isEdit === 0) // If it is add, need to push into array
            {
                var itemDetailsExist = itemDetails.filter((e) => e.itemcode === itemName.qs_item_code && e.qrcode === itemCode)
                var itemDetailsArray = itemDetails;
                if (itemDetailsExist.length === 0) {

                    itemDetailsArray.push(itemObj)
                    setItemDetails(itemDetailsArray)
                } else {
                    let qty = 0
                    itemDetailsArray.forEach((e, i) => {
                        if (e.itemcode === itemName.qs_item_code && e.qrcode === itemCode) {
                            const obj = { ...e }
                            qty = Number(obj['qty']) + Number(itemQty)
                            const formattedValue = parseFloat(qty).toFixed(itemName?.um_no_of_decimals);
                            setItemQty(formattedValue)
                            obj['qty'] = formattedValue
                            itemDetailsArray.splice(i, 1, obj)

                        }
                    })
                    setItemDetails(itemDetailsArray)
                }




            }
            else {
                replaceDataBySno(itemObj)
            }

            clearAddData()
        } else {
            toast.error(error, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }

    const onClickSaveBtn = async (event) => {
        if (transNo === 0) {
            udfnSaveStockTransfer(event)
        } else {
            udfnUpdateStockTransfer(event)
        }

    }
    const udfnSaveStockTransfer = async (event) => {
        try {

            event.preventDefault();
            var error = "";
            var localFy = localStorage.getItem("fy")
            const user = getLoggedInUser()
            if (transferNo === '' || transferDate === '' || transferFrom === '' || transferFrom === '' || transferTo === '') {
                error = ERROR.COMMON_REQUIRED;
            } else if (itemDetails.length < 1) {
                error = ERROR.STOCK_TRANSFER_ADD.ITEM_DETAILS_VALID;
            } else {
                error = '';
            }
            if (error === '') {
                setButtonFlag(true)
                var stockTransferParams = {
                    "transfer_date": transferDate,
                    "user_id": user?.id,
                    "from_branch_code": Number(transferFrom?.value || 0),
                    "to_branch_code": Number(transferTo?.value || 0),
                    "narration": narration,
                    "company_code": user?.company_code,
                    "fy": Number(localFy),
                    "st_item": itemDetails
                }


                const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } };
                const [saveData] = await Promise.all([
                    axios.post(BASE_URL + STOCK_TRANSFER_API.SAVE_STOCK_TRANSFER, stockTransferParams, config)

                ]);
                setButtonFlag(false)
                if (saveData?.data?.body?.status === COMMONVARIABLES.STATUS.UNAUTHORIZED) {
                    fetchMasterDataWithParams()

                    const refresh = await getRefreshToken();

                    if (refresh) {
                        if (Number(refresh) === COMMONVARIABLES.STATUS.EXPIRED_TOKEN) {
                            Logout();
                        } else {
                            await udfnSaveStockTransfer();
                        }
                    }
                }

                if (saveData?.data?.body?.status === COMMONVARIABLES.STATUS.SERVICE_CATCH_STATUS) {
                    toast.error(ERROR.API_ERROR.SERVICE_CATCH_ERROR, { position: toast.POSITION.TOP_RIGHT });
                }

                if (saveData?.data?.body?.status === COMMONVARIABLES.STATUS.SUCCESS) {


                    setIsPrint(1)
                    editLoadData(saveData?.data?.body?.transaction_no, 1)
                    // swal({
                    //     title: "Success",
                    //     text:
                    //         "Stocktransfer saved successfully",
                    //     icon: "success",
                    //     //buttons: true,
                    //     dangerMode: true,
                    // }).then((resp) => {
                    //     if (resp) {

                    //         navigate("/stock-transfer-list");
                    //     }
                    // })

                }
            } else {
                toast.error(error, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        }
        catch (e) {
            setButtonFlag(true)
            if (e.code === COMMONVARIABLES.STATUS.NETWORK_ERROR_STATUS) {
                toast.error(ERROR.API_ERROR.NETWORK_ERROR, { position: toast.POSITION.TOP_RIGHT });
            }
            if (e.code === COMMONVARIABLES.STATUS.BAD_REQUEST_ERROR_STATUS) {
                toast.error(ERROR.API_ERROR.BAD_REQUEST_ERROR, { position: toast.POSITION.TOP_RIGHT });
            }
        }
    }

    const udfnUpdateStockTransfer = async (event) => {
        try {

            event.preventDefault();

            var error = "";
            var localFy = localStorage.getItem("fy")
            const user = getLoggedInUser()
            if (transferNo === '' || transferDate === '' || transferFrom === '' || transferFrom === '' || transferTo === '') {
                error = ERROR.COMMON_REQUIRED;
            } else if (itemDetails.length < 1) {
                error = ERROR.STOCK_TRANSFER_ADD.ITEM_DETAILS_VALID;
            } else {
                error = '';
            }
            if (error === '') {
                setButtonFlag(true)

                var stockTransferParams = {
                    "transaction_no": Number(transferNo),
                    "transfer_date": transferDate,
                    "user_id": user?.id,
                    "from_branch_code": Number(transferFrom?.value || 0),
                    "to_branch_code": Number(transferTo?.value || 0),
                    "narration": narration,
                    "company_code": user?.company_code,
                    "fy": Number(localFy),
                    "st_item": itemDetails
                }


                const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } };
                const [saveData] = await Promise.all([
                    axios.post(BASE_URL + STOCK_TRANSFER_API.UPDATE_STOCK_TRASFER, stockTransferParams, config)

                ]);

                setButtonFlag(false)
                if (saveData?.data?.body?.status === COMMONVARIABLES.STATUS.UNAUTHORIZED) {
                    fetchMasterDataWithParams()

                    const refresh = await getRefreshToken();

                    if (refresh) {
                        if (Number(refresh) === COMMONVARIABLES.STATUS.EXPIRED_TOKEN) {
                            Logout();
                        } else {
                            await udfnUpdateStockTransfer();
                        }
                    }
                }

                if (saveData?.data?.body?.status === COMMONVARIABLES.STATUS.SERVICE_CATCH_STATUS) {
                    toast.error(ERROR.API_ERROR.SERVICE_CATCH_ERROR, { position: toast.POSITION.TOP_RIGHT });
                }

                if (saveData?.data?.body?.status === COMMONVARIABLES.STATUS.SUCCESS) {


                    swal({
                        text:
                            "Are you sure you want print?",
                        icon: "warning",
                        buttons: true,
                        dangerMode: true,
                    }).then((willDelete) => {
                        if (willDelete) {

                            setIsPrint(1)
                            editLoadData(Number(transferNo), 1)

                        }
                        else {
                            if (Number(transferNo) != 0) {
                                navigate("/stock-transfer-list");
                            }


                        }
                    })



                    // swal({
                    //     title: "Success",
                    //     text:
                    //         "Stocktransfer updated successfully",
                    //     icon: "success",
                    //     //buttons: true,
                    //     dangerMode: true,
                    // }).then((resp) => {
                    //     if (resp) {

                    //         navigate("/stock-transfer-list");
                    //     }
                    // })

                }
            } else {
                toast.error(error, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        }
        catch (e) {

            setButtonFlag(false)
            if (e.code === COMMONVARIABLES.STATUS.NETWORK_ERROR_STATUS) {
                toast.error(ERROR.API_ERROR.NETWORK_ERROR, { position: toast.POSITION.TOP_RIGHT });
            }
            if (e.code === COMMONVARIABLES.STATUS.BAD_REQUEST_ERROR_STATUS) {
                toast.error(ERROR.API_ERROR.BAD_REQUEST_ERROR, { position: toast.POSITION.TOP_RIGHT });
            }
        }
    }

    const onChangeBranchFrom = (row) => {
        setTransferFrom(row)
        if (transferFromBranchOptions && transferFromBranchOptions.length > 0) {
            setTransferTo('')
            const transferFromBranchArr = Object.assign([], transferFromBranchOptions)
            const result = transferFromBranchArr.filter((e) => e.bm_branch_code !== row.value)
            setTransferToBranchOptions(result)
        }
    }
    const onBlurQrCode = (qrCode) => {
        if (qrCode) {
            fetchItemListFromQrCode(qrCode)
        }

    }

    // Function to replace the entire JSON array based on key
    const replaceDataBySno = (newData) => {
        const updatedData = [...itemDetails]; // Create a copy of the original data

        // Find the index of items that match the key
        const indexesToUpdate = updatedData.reduce((acc, item, index) => {
            if (item.sno === currentSno) {
                acc.push(index);
            }
            return acc;
        }, []);

        // Replace the items with new data
        indexesToUpdate.forEach(index => {
            updatedData[index] = newData;
        });

        // Update the state with the modified array
        setItemDetails(updatedData);
    };

    const udfnLoadItem = (e, editItem, type) => {

        e.preventDefault();
        setIsEdit(type)

        setItemName({ itemcode: editItem?.itemcode, im_item_name: editItem?.im_item_name, im_display_name: editItem?.itemName, im_unit_code: editItem?.im_unit_code, um_no_of_decimals: editItem?.um_no_of_decimals, um_unit_name: editItem?.itemUnit, um_display_name: editItem?.itemUnit, stock: editItem?.stock })

        setCurrentSno(editItem?.sno)
        setItemCode(editItem?.qrcode);
        setItemQty(editItem?.qty);

    };

    // Function to delete item based on ID
    const udfnDeleteItem = sno => {
        // Filter out the item with the matching sno
        const updatedData = itemDetails.filter(item => item.sno !== sno);

        // Update the state with the filtered array
        setItemDetails(updatedData);
    };

    return (
        <>
            <ToastContainer />
            <LoadingOverlay
                active={isLoading}
                spinner
                text='Loading ...'
            >
                <div className="card">
                    <div className="card-header">
                        <div className='col-sm-5'>
                            <h4 className="card-title">{title}</h4>
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="basic-form">
                            <form onSubmit={(e) => e.preventDefault()}>
                                <div className="row stock-transfer">
                                    <div className="form-group mb-3 col-lg-2">
                                        <label className="text-black font-w300">Transfer Date</label>
                                        <span className="text-danger star-txt">*</span>
                                        {/* <input type="date" className="form-control"
                                        value={transferDate}
                                        onChange={(e) => setTransferDate(e.target.value)} /> */}
                                        <input value={transferDate} onChange={e => setTransferDate(e.target.value)} type="date" className="form-control" />

                                    </div>
                                    <div className="form-group mb-3 col-lg-2">
                                        <label className="text-black font-w300">Transfer No</label>
                                        <span className="text-danger star-txt">*</span>
                                        <input
                                            type="number"
                                            className="form-control"
                                            placeholder="Transfer No"
                                            value={transferNo}
                                            readOnly
                                        />
                                    </div>
                                    <div className="form-group mb-3 col-lg-4">
                                        <label className="text-black font-w300">From Branch</label>
                                        <span className="text-danger star-txt">*</span>
                                        <Select
                                            isDisabled={fromBranchDisabled}
                                            defaultValue={transferFrom}
                                            // onChange={setTransferFrom}
                                            onChange={(e) => onChangeBranchFrom(e)}
                                            value={transferFrom}
                                            options={transferFromBranchOptions.map((option) => ({
                                                label: option.bm_branch_name,
                                                value: option.bm_branch_code,
                                            }))}
                                            showContent="true"
                                            open="true"
                                            className="my-react-select-container"
                                            theme={theme => ({
                                                ...theme,
                                                borderRadius: '6px',
                                                colors: {
                                                    ...theme.colors,
                                                    primary25: '#b3b3b3',
                                                    primary: 'black',
                                                    neutral80: "data-theme-version" === "dark" ? 'white' : ''
                                                },
                                            })}
                                        />
                                    </div>
                                    <div className="form-group mb-3 col-lg-4">
                                        <label className="text-black font-w300">To Branch</label>
                                        <span className="text-danger star-txt">*</span>
                                        <Select
                                            isDisabled={toBranchDisabled}
                                            defaultValue={transferTo}
                                            onChange={setTransferTo}
                                            value={transferTo}
                                            options={transferToBranchOptions.map((option) => ({
                                                label: option.bm_branch_name,
                                                value: option.bm_branch_code,
                                            }))}
                                            showContent="true"
                                            open="true"
                                            className="my-react-select-container"
                                            theme={theme => ({
                                                ...theme,
                                                borderRadius: '6px',
                                                colors: {
                                                    ...theme.colors,
                                                    primary25: '#b3b3b3',
                                                    primary: 'black',
                                                    neutral80: "data-theme-version" === "dark" ? 'white' : ''
                                                },
                                            })}
                                        />
                                    </div>
                                </div>
                                <hr />
                                <div className='row stock-transfer'>
                                    <div className="col-md-3">
                                        <label className="text-black font-w300">Item Code</label>
                                        <span className="text-danger star-txt">*</span>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Item Code"
                                            value={itemCode}
                                            onChange={(e) => {
                                                setItemName('')
                                                setItemCode(e.target.value)
                                            }}
                                            onBlur={(e) => onBlurQrCode(e.target.value)}
                                        />
                                    </div>
                                    <div className="col-md-5">
                                        <div className="currentStock">
                                            <div>
                                                <label className="text-black font-w300">Item Name</label>
                                                <span className="text-danger star-txt">*</span>
                                            </div>
                                            {itemName && <div>
                                                <label className="text-black font-w300">Current Stock : </label>
                                                <span className="text-danger star-txt stock-transfer-span">{itemName?.stock || '0'}</span>
                                            </div>}
                                        </div>


                                        {/* <Select
                                        defaultValue={itemName}
                                        options={itemNameOptions}
                                        value={itemName}
                                        onChange={setItemName}
                                        showContent="true"
                                        open="true"
                                        className="my-react-select-container"
                                        theme={theme => ({
                                            ...theme,
                                            borderRadius: '6px',
                                            colors: {
                                                ...theme.colors,
                                                primary25: '#b3b3b3',
                                                primary: 'black',
                                                neutral80: "data-theme-version" === "dark" ? 'white' : ''
                                            },
                                        })}
                                    /> */}
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Item Name"
                                            value={itemName?.im_display_name || ''}
                                            readOnly
                                        />
                                    </div>
                                    <div className="col-md-2">
                                        <label className="text-black font-w300">Quantity</label>
                                        <span className="text-danger star-txt">*</span>
                                        <input
                                            type="number"
                                            className="form-control text-end"
                                            placeholder="Qty"
                                            value={itemQty}
                                            onKeyPress={(e) => {
                                                if (e.key === '-' || e.key === 'e') {
                                                    e.preventDefault();
                                                }
                                            }}
                                            min="0"
                                            onPaste={(e) => {
                                                e.preventDefault();
                                                const pastedText = e.clipboardData.getData('text');
                                                let pastedValue = parseFloat(pastedText);
                                                if (isNaN(pastedValue) && pastedValue < 0) {
                                                    pastedValue = "";
                                                }
                                                e.target.value = pastedValue.toString().slice(0, 3);

                                            }}
                                            onChange={(e) => {

                                                const formattedValue = parseFloat(e.target.value).toFixed(itemName?.um_no_of_decimals);
                                                setItemQty(e.target.value)

                                            }}
                                        />
                                    </div>
                                    <div className="col-md-2">
                                        <Button className="me-1 mt-4" variant="whatsapp" onClick={itemDetailsValidate}>
                                            <i className="fa fa-plus"></i>
                                        </Button>
                                        <Button
                                            variant="danger"
                                            type="button"
                                            className="btn btn-danger mt-4"
                                            onClick={(e) => clearAddData(e)}
                                        >
                                            <i className="fa fa-ban"></i>
                                        </Button>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="table-responsive">
                                        <table className="table table-striped">
                                            <thead>
                                                <tr>
                                                    <th width='5%'>#</th>
                                                    <th width='15%'>Item Code</th>
                                                    <th width='35%'>Item Name</th>
                                                    <th width='15%' className='text-end'>Quantity</th>
                                                    <th width='15%' className='text-end'>Selling Rate</th>
                                                    <th width='15%' className='text-center'>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    itemDetails.length > 0 ?
                                                        itemDetails.map((row, index) => {
                                                            return (
                                                                <tr key={index}>
                                                                    <td>{index + 1}</td>
                                                                    <td>{row.qrcode}</td>
                                                                    <td>{row.itemName}</td>
                                                                    <td className='text-end'>{row.qty} {row.itemUnit}</td>
                                                                    <td className='text-end'>{row.selling_rate}</td>
                                                                    <td className='text-center'>
                                                                        <Link className="btn btn-secondary	 shadow btn-xs sharp me-1" onClick={(e) => udfnLoadItem(e, row, 1)}>
                                                                            <i className="fa fa-pencil"></i>
                                                                        </Link>
                                                                        <Link className="btn btn-danger shadow btn-xs sharp" onClick={(e) => udfnDeleteItem(row?.sno)}>
                                                                            <i className="fa fa-trash"></i>
                                                                        </Link>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })
                                                        : ''
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className='row mb-0'>
                                    <div className="form-group col-lg-6 col-sm-12 mb-2">
                                        <label className="text-black font-w300">Narration</label>
                                        <textarea className="form-control text-area-udf1"
                                            placeholder="Narration"
                                            value={narration}
                                            onKeyDown={handleKeyDown}
                                            onChange={(e) => setNarration(e.target.value)}></textarea>
                                    </div>
                                    <div className="col-lg-6 col-sm-12 mb-3 text-end">
                                        <div style={{ marginTop: '80px' }}>

                                            <Link to='/stock-transfer-list'>
                                                <Button
                                                    variant="danger"
                                                    type="button"
                                                    className="btn btn-success me-2"
                                                >
                                                    Discard
                                                </Button>
                                            </Link>
                                            <Button className="me-2" variant="whatsapp" ref={buttonRef} onClick={onClickSaveBtn} disabled={buttonFlag}>
                                                {btnTitle}
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div >
                </div >


                <div style={{ visibility: 'hidden', height: 0 }}>
				<div ref={contentToPrint} className="row purchsecontentToPrint" style={{ padding: '16px' }} >
					<table>


						<style>
							{`
       @page {    
        size: A5 portrait;    
        padding-left: 8px;    
        padding-right: 0px;
        // margin-top: 50px;
        //  margin-bottom: 150px;
    
   
    }  
    
        
    
        `}
						</style>

						<thead ><tr><td>


							<div class="printheader" >
								<div className="col-12">
                                <div className="row text-center">
										
									
                                        <div className="brand-logo p-16 col-4">
                                                   <img className="logo-abbr me-3" src={Logo} alt="" style={{ width: '170px', height: '100px' }} />
       
                                               </div> 
       
       
                                               <div className='col-8 text-end'>
                                               <span>
                                                   <strong style={{ fontSize: 20, fontWeight: 700 }} className="d-block text-black"> {localUserDetails?.company_name}</strong>
       
       
                                               </span>
       
                                               <small className="text-black" style={{ fontSize: 16, lineHeight: 1.2 }}>
       
                                                   {localUserDetails?.address}{', '}{localUserDetails?.city}
                                                   <br></br>
                                                   {localUserDetails?.state_name}
                                                   <br></br>
                                                   {'Email'}{' : '}{localUserDetails?.email_id}
                                                   <br></br>
                                                   {'Contact No'}{' : '}{localUserDetails?.mobile_no}
       
                                                   <strong className="d-block">GSTIN : {localUserDetails?.gstin}</strong>
       
                                               </small>
                                           </div> 
                                           <div class="h_line"></div>
                                           <strong style={{ fontSize: 16, fontWeight: 700 }} className="d-block text-center text-black"> {'DELIVERY NOTE'}</strong>
       
       
                                           </div>
									<div class="h_line"></div>
                                    <div className='row'>
										<div className='col-6'>
											<span>
												<strong style={{ fontSize: 16, fontWeight: 700 }} className="d-block text-black"> {'From:'}</strong>


											</span>
									
											<small className="text-black" style={{ fontSize: 16, lineHeight: 1.2 }}>

												
												{transferFromPrint?.branch_name}
												<br></br>
												{transferFromPrint?.branch_address}
												<br></br>
												{transferFromPrint?.branch_city}
											

											</small>
										

										</div>
										<div className='col-6 text-end'>
											<span>
												<strong style={{ fontSize: 16, fontWeight: 700 }} className="d-block text-black"> {'To:'}</strong>


											</span>

											<small className="text-black" style={{ fontSize: 16, lineHeight: 1.2 }}>

											{transferToPrint?.branch_name}
												<br></br>
												{transferToPrint?.branch_address}
												<br></br>
												{transferToPrint?.branch_city}
											
											

											</small>

										</div>
									</div>
								</div>
								<div class="h_line"></div>



								<div className='row'>


									<div className="col-6">
										<span className="d-block text-black"> Delivery Note No. : <strong>{transferNoPrint}</strong></span>
									</div>

									<div className="text-end col-6">


										<span className="d-block text-black"> Dated : <strong>{transferDatePrint}</strong></span>

									</div>
								</div>
								<div class="h_line"></div>

							</div>
						</td></tr></thead>
						<tbody ><tr><td>

							<div className="col-lg-12 ">



								{/* <hr></hr> */}

								<div className="table-responsive">


									<table className="table table-striped">
										<thead >
											<tr>
												<th className="center">#</th>

												<th style={{ textAlign: 'center !important' }} className="center text-black">Item</th>
												<th className="center text-black">Qty</th>
												<th className="text-end text-black" >Rate</th>
												<th className="text-end text-black">Amount</th>
											</tr>
										</thead>
										<tbody>


											{
												itemDetailsPrint?.length > 0 ?
                                                itemDetailsPrint?.map((row, index) => {
														return (
															<tr key={index}>
																<td className='text-black'>{index + 1}</td>
																<td className='text-black'>{row.itemName}<br></br>
																{row.qrcode != '0' ?<span>{'('}{row.qrcode}{')'}</span>:null}
																</td>
                                                                <td className='center text-black'>{row.qty} {row.itemUnit}</td>
																<td className='text-end text-black'>{COMMONVARIABLES.India_Rupee_Formatter_Digits(row.selling_rate)}</td>
																<td className='text-end text-black'>{COMMONVARIABLES.India_Rupee_Formatter_Digits(row.selling_rate * row.qty)}</td>

															</tr>
														)
													})
													: ''
											}


										</tbody>
									</table>




								</div>

							</div>






						</td></tr></tbody>

						<tfoot>
							<tr><td className='text-black' style={{ paddingRight: 16, paddingLeft: 16 }}>Remarks : {narrationPrint}</td></tr>
							<tr>
								<td> <strong style={{ fontSize: 16, fontWeight: 700, paddingRight: 16, paddingLeft: 16  }} className="d-block text-black"> {'NOT FOR SALE'}</strong></td>
							</tr>
							<tr><td>
								<div class="footer-space" style={{ padding: 16 }}>



									<div className="printfooter" >
										<div class="h_line"></div>
										<div className='' style={{ marginTop: '35px' }}>
											<div className='row'>
												<div className='col-4'>

													<strong>Checked by</strong>
												</div>
												<div className='col-4'>

													<strong>Verified by</strong>
												</div>
												<div className='col-4 text-end'>

													<strong>Authorised Signatory</strong>
												</div>
											</div>
										</div>


									</div> </div>
							</td></tr></tfoot>
					</table>


				</div>
			</div>

            </LoadingOverlay>
        </>
    )

}
export default StockTransferAdd;