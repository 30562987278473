import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTable, useGlobalFilter, useFilters, useSortBy, usePagination } from 'react-table';
import { COLUMNS } from '../Filters/Customer/CustomerColumns';
import { Link } from "react-router-dom";
import { Button, Modal } from 'react-bootstrap';
import '../Filters/Common/filtering.css';
import Select from "react-select";
import { Badge } from "react-bootstrap";
import { Accordion } from "react-bootstrap";
import ERROR_MESSAGE from '../Validation/ErrorMessage';
import FORM_VALIDATION from '../Validation/FormValidation';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import swal from "sweetalert";
import { getLoggedInUser, getStateList, getRefreshToken } from "../../../services/AuthService"
import MASTER_API from '../../Api/MasterApi';
import { UserLogout } from '../../layouts/nav/Logout';
import axios from 'axios';
import COMMONVARIABLES from '../Utilities/constants';
import LoadingOverlay from 'react-loading-overlay-ts';
import { UncontrolledTooltip } from 'reactstrap';
const ExcelJS = require('exceljs');
// import { UncontrolledTooltip } from "reactstrap";

export const CustomerMaster = () => {
	const user = getLoggedInUser();
	const stateList = getStateList();

	const BASE_URL = process.env.REACT_APP_BASE_URL;
	const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } };

	const [isLoading, setIsLoading] = useState([]);
	const [buttonFlag, setButtonFlag] = useState(false);
	const [excelButtonFlag, setExcelButtonFlag] = useState(false);
	const [editFlag, setEditFlag] = useState(false);

	const [customerList, setCustomerList] = useState([]);
	const [customerTotalCount, setCustomerTotalCount] = useState(0);
	const [addCard, setAddCard] = useState(false);

	const [customerCode, setCustomerCode] = useState(0);
	const [customerName, setCustomerName] = useState('');
	const [customerAddress, setCustomerAddress] = useState('');
	const [customerCity, setCustomerCity] = useState('');
	const [customerState, setCustomerState] = useState('');
	const [customerGstin, setCustomerGstin] = useState('');
	const [customerPincode, setCustomerPincode] = useState('');
	const [customerContactPerson, setCustomerContactPerson] = useState('');
	const [customerMobile, setCustomerMobile] = useState('');
	const [customerEmail, setCustomerEmail] = useState('');
	const [customerBankName, setCustomerBankName] = useState('');
	const [customerBranchName, setCustomerBranchName] = useState('');
	const [customerAccountName, setCustomerAccountName] = useState('');
	const [customerAccNo, setCustomerAccNo] = useState('');
	const [customerIfscCode, setCustomerIfscCode] = useState('');
	const [customerPanNo, setCustomerPanNo] = useState('');
	const [status, setStatus] = useState(1);
	const [stateOptions, setStateOptions] = useState([]);

	const columns = useMemo(() => COLUMNS, [])
	const data = customerList
	const tableInstance = useTable({
		columns,
		data,
		initialState: { pageIndex: 0 }
	}, useFilters, useGlobalFilter, useSortBy, usePagination)

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		state,
		page,
		gotoPage,
		pageCount,
		pageOptions,
		nextPage,
		previousPage,
		canNextPage,
		canPreviousPage,
	} = tableInstance

	const { pageIndex } = state

	const udfnUpperCase = (event) => {
		var val = event.target.value.toUpperCase()
		event.target.value = val;
	};

	const udfnLowerCase = (event) => {
		var val = event.target.value.toLowerCase();
		event.target.value = val;
	};

	const udfnNameValidation = (event) => {
		var val = event.target.value.charAt(0).toUpperCase() + event.target.value.slice(1);
		event.target.value = val;
	};

	const udfnCityValidation = (event) => {
		const regex = /^[a-zA-Z ]*$/;
		const input = event.target.value + event.key;
		if (!regex.test(input)) {
			event.preventDefault();
		}
	};

	const udfnGstinValidation = (event) => {
		const regex = /^[a-zA-Z0-9]*$/;
		const input = event.target.value + event.key;
		if (!regex.test(input)) {
			event.preventDefault();
		}
	};

	const udfnPanNoValidation = (event) => {
		const regex = /^[a-zA-Z0-9]*$/;
		const input = event.target.value + event.key;
		if (!regex.test(input)) {
			event.preventDefault();
		}
	};

	const udfnPincodeValidation = (event) => {
		const regex = /^[0-9]*$/;
		const input = event.target.value + event.key;
		if (!regex.test(input) || input.length > 6) {
			event.preventDefault();
		}
	};

	const udfnPhoneNumberValidation = (event) => {
		const regex = /^[0-9]*$/;
		const input = event.target.value + event.key;
		if (!regex.test(input) || input.length > 10) {
			event.preventDefault();
		}
	};

	const udfnBankNameValidation = (event) => {
		const regex = /^[a-zA-Z ]*$/;
		const input = event.target.value + event.key;
		if (!regex.test(input)) {
			event.preventDefault();
		}
	};

	const udfnAccountNumberValidation = (event) => {
		const regex = /^[0-9]*$/;
		const input = event.target.value + event.key;
		if (!regex.test(input)) {
			event.preventDefault();
		}
	};

	const udfnIFSCCodeValidation = (event) => {
		const regex = /^[a-zA-Z0-9]*$/;
		const input = event.target.value + event.key;
		if (!regex.test(input)) {
			event.preventDefault();
		}
	};

	const udfnBranchNameValidation = (event) => {
		const regex = /^[a-zA-Z ]*$/;
		const input = event.target.value + event.key;
		if (!regex.test(input)) {
			event.preventDefault();
		}
	};

	const udfnAccountNameValidation = (event) => {
		const regex = /^[a-zA-Z ]*$/;
		const input = event.target.value + event.key;
		if (!regex.test(input)) {
			event.preventDefault();
		}
	};

	const udfnClearForm = () => {
		setCustomerName('');
		setCustomerCode(0);
		setCustomerAddress('');
		setCustomerCity('');
		setCustomerState('')
		setCustomerGstin('');
		setCustomerPincode('');
		setCustomerContactPerson('');
		setCustomerMobile('');
		setCustomerEmail('');
		setCustomerBankName('');
		setCustomerAccountName('');
		setCustomerBranchName('');
		setCustomerAccNo('');
		setCustomerIfscCode('');
		setCustomerPanNo('');
		setStatus(1);

		setAddCard(false);
		setEditFlag(false);
	};

	const udfnCustomerList = async () => {
		setIsLoading(true)

		var apiUrl = BASE_URL + MASTER_API.CUSTOMER_MASTER_LIST;

		const uploadData = {
			user_id: Number(user.id)
		};

		try {
			const response = await axios.post(apiUrl, uploadData, config);
			setIsLoading(false)
			if (response && response?.data?.body?.status === COMMONVARIABLES.STATUS.INVALIDUSER) {
				toast.error(response.data.body.message, { position: toast.POSITION.TOP_RIGHT });
				UserLogout();
			}

			if (response?.data?.body?.status === COMMONVARIABLES.STATUS.UNAUTHORIZED) {
				const refresh = await getRefreshToken();

				if (refresh) {
					if (Number(refresh) === COMMONVARIABLES.STATUS.EXPIRED_TOKEN) {
						UserLogout();
					} else {
						await udfnCustomerList();
					}
				}
			}

			if (response?.data?.body?.status === COMMONVARIABLES.STATUS.SERVICE_CATCH_STATUS) {
				toast.error(ERROR_MESSAGE.API_ERROR.SERVICE_CATCH_ERROR, { position: toast.POSITION.TOP_RIGHT });
			}

			if (response?.data?.body?.cutomerList) {
				setCustomerList(response.data.body.cutomerList);
				var cutomerListCount = response.data.body.cutomerList.filter(item => item.cm_status_code === 1).length;
				setCustomerTotalCount(cutomerListCount);
				toast.success(response.data.body.message, { position: toast.POSITION.TOP_RIGHT });
				setIsLoading(false);
			} else {
				toast.error(response.data.body.message, { position: toast.POSITION.TOP_RIGHT });
			}
		} catch (error) {
			setIsLoading(false)
			// Handle error
			console.error("Customer list API Error:", error);
			if (error.code === COMMONVARIABLES.STATUS.NETWORK_ERROR_STATUS) {
				toast.error(ERROR_MESSAGE.API_ERROR.NETWORK_ERROR, { position: toast.POSITION.TOP_RIGHT });
			}
			if (error.code === COMMONVARIABLES.STATUS.BAD_REQUEST_ERROR_STATUS) {
				toast.error(ERROR_MESSAGE.API_ERROR.BAD_REQUEST_ERROR, { position: toast.POSITION.TOP_RIGHT });
			}
		}

	};

	const udfnFormValidate = (event) => {
		event.preventDefault();
		var error = "";
		if (!customerName.trim() || !customerAddress.trim() || !customerCity.trim() || customerState === '' || !customerPincode.trim() || !customerMobile.trim()) {
			error = ERROR_MESSAGE.COMMON_REQUIRED;
		} else if (!FORM_VALIDATION('alpha_space', customerCity)) {
			error = ERROR_MESSAGE.CITY_VALID;
		} else if (customerGstin != '') {
			var gstinValid = Number(customerGstin.substring(0, 2)) === Number(customerState.value) ? true : false;
			error = !FORM_VALIDATION('gstin', customerGstin) || !gstinValid ? ERROR_MESSAGE.GSTIN_VALID : '';
		} else if (!FORM_VALIDATION('pincode', customerPincode)) {
			error = ERROR_MESSAGE.PINCODE_VALID;
		} else if (!FORM_VALIDATION('phone_number', customerMobile)) {
			error = ERROR_MESSAGE.MOBILE_VALID;
		} else if (customerEmail !== '') {
			error = !FORM_VALIDATION('email', customerEmail) ? ERROR_MESSAGE.EMAIL_VALID : '';
		} else {
			error = '';
		}

		if (error === '') {
			udfnCustomerCreate()
		} else {
			toast.error(error, { position: toast.POSITION.TOP_RIGHT });
		}
	};

	const udfnCustomerCreate = async () => {
		setIsLoading(true);
		setButtonFlag(true);

		var uploadData = {};
		var apiUrl = '';
		if (editFlag === true) {
			uploadData = {
				user_id: Number(user.id),
				company_code: Number(user.company_code),
				customer_code: Number(customerCode),
				customer_name: String(customerName.trim()),
				address: String(customerAddress.trim()),
				city: String(customerCity.trim()),
				state_code: Number(customerState.value),
				gstin: String(customerGstin.trim()),
				pincode: String(customerPincode),
				contact_person: String(customerContactPerson.trim()),
				mobile_number: String(customerMobile),
				emailid: String(customerEmail.trim()),
				pan_number: String(customerPanNo.trim()),
				bank_name: String(customerBankName.trim()),
				account_number: String(customerAccNo.trim()),
				ifsc_code: String(customerIfscCode.trim()),
				status_code: Number(status),
				account_name: String(customerAccountName.trim()),
				branch_name: String(customerBranchName.trim())
			};
			apiUrl = BASE_URL + MASTER_API.CUSTOMER_MASTER_UPDATE;
		} else {
			uploadData = {
				user_id: Number(user.id),
				company_code: Number(user.company_code),
				customer_name: String(customerName.trim()),
				address: String(customerAddress.trim()),
				city: String(customerCity.trim()),
				state_code: Number(customerState.value),
				gstin: String(customerGstin.trim()),
				pincode: String(customerPincode),
				contact_person: String(customerContactPerson.trim()),
				mobile_number: String(customerMobile),
				emailid: String(customerEmail.trim()),
				pan_number: String(customerPanNo.trim()),
				bank_name: String(customerBankName.trim()),
				account_number: String(customerAccNo.trim()),
				ifsc_code: String(customerIfscCode.trim()),
				status_code: Number(1),
				account_name: String(customerAccountName.trim()),
				branch_name: String(customerBranchName.trim())
			};
			apiUrl = BASE_URL + MASTER_API.CUSTOMER_MASTER_CREATE;
		}

		try {
			const response = await axios.post(apiUrl, uploadData, config);
			setIsLoading(false);
			setButtonFlag(false);

			if (response && response?.data?.body?.status === COMMONVARIABLES.STATUS.INVALIDUSER) {
				toast.error(response.data.body.message, { position: toast.POSITION.TOP_RIGHT });
				UserLogout();
			}
			if (response?.data?.body?.status === COMMONVARIABLES.STATUS.UNAUTHORIZED) {
				const refresh = await getRefreshToken();
				if (refresh) {
					if (Number(refresh) === COMMONVARIABLES.STATUS.EXPIRED_TOKEN) {
						UserLogout();
					} else {
						await udfnCustomerCreate();
					}
				}
			}
			if (response && response?.data?.body?.status === COMMONVARIABLES.STATUS.EXISTS) {
				toast.error(response.data.body.message, { position: toast.POSITION.TOP_RIGHT });
			}
			if (response && response?.data?.body?.status === COMMONVARIABLES.STATUS.ALREADY_IN_USE) {
				toast.error(response.data.body.message, { position: toast.POSITION.TOP_RIGHT });
			}
			if (response?.data?.body?.status === COMMONVARIABLES.STATUS.SERVICE_CATCH_STATUS) {
				toast.error(ERROR_MESSAGE.API_ERROR.SERVICE_CATCH_ERROR, { position: toast.POSITION.TOP_RIGHT });
			}
			if (response?.data?.body?.status === COMMONVARIABLES.STATUS.SUCCESS) {
				toast.success(response.data.body.message, { position: toast.POSITION.TOP_RIGHT });
				udfnClearForm();
				setAddCard(editFlag === true ? false : true)
				await udfnCustomerList();
			} else {
				toast.error(response.data.body.message, { position: toast.POSITION.TOP_RIGHT });
			}
		} catch (error) {
			// Handle error
			setIsLoading(false);
			setButtonFlag(false);
			console.error("Customer create API Error:", error);
			if (error.code === COMMONVARIABLES.STATUS.NETWORK_ERROR_STATUS) {
				toast.error(ERROR_MESSAGE.API_ERROR.NETWORK_ERROR, { position: toast.POSITION.TOP_RIGHT });
			}
			if (error.code === COMMONVARIABLES.STATUS.BAD_REQUEST_ERROR_STATUS) {
				toast.error(ERROR_MESSAGE.API_ERROR.BAD_REQUEST_ERROR, { position: toast.POSITION.TOP_RIGHT });
			}
		}
	};

	const udfnEditLoad = async (row) => {
		setCustomerName(row.cm_customer_name);
		setCustomerCode(row.cm_customer_code);
		setCustomerAddress(row.cm_address);
		setCustomerCity(row.cm_city);
		setCustomerState({
			label: row.sm_state_name,
			value: row.sm_state_code,
		});
		setCustomerGstin(row.cm_gstin);
		setCustomerPincode(row.cm_pincode);
		setCustomerContactPerson(row.cm_contact_person);
		setCustomerMobile(row.cm_mobile_number);
		setCustomerEmail(row.cm_emailid);
		setCustomerBankName(row.cm_bank_name);
		setCustomerAccNo(row.cm_account_number);
		setCustomerIfscCode(row.cm_ifsc_code);
		setCustomerPanNo(row.cm_pan_number);
		setStatus(row.cm_status_code);
		setCustomerAccountName(row.cm_account_name);
		setCustomerBranchName(row.cm_branch_name);

		setAddCard(true);
		setEditFlag(true);
	};

	const udfnDelete = async (customer_code) => {
		swal({
			title: "Are you sure?",
			text:
				"Once deleted, you will not be able to recover this Customer!",
			icon: "warning",
			buttons: true,
			dangerMode: true,
		}).then((willDelete) => {
			if (willDelete) {
				udfnCustomerDelete(customer_code);
			} else {
				swal("Your Customer is safe!");
			}
		})
	}

	const udfnCustomerDelete = async (customer_code) => {
		setIsLoading(true);
		var apiUrl = BASE_URL + MASTER_API.CUSTOMER_MASTER_DELETE;

		const uploadData = {
			user_id: Number(user.id),
			company_code: Number(user.company_code),
			customer_code: Number(customer_code),
		};

		try {
			const response = await axios.post(apiUrl, uploadData, config);
			setIsLoading(false);

			if (response && response?.data?.body?.status === COMMONVARIABLES.STATUS.INVALIDUSER) {
				toast.error(response.data.body.message, { position: toast.POSITION.TOP_RIGHT });
				UserLogout();
			}

			if (response?.data?.body?.status === COMMONVARIABLES.STATUS.UNAUTHORIZED) {
				const refresh = await getRefreshToken();

				if (refresh) {
					if (Number(refresh) === COMMONVARIABLES.STATUS.EXPIRED_TOKEN) {
						UserLogout();
					} else {
						await udfnCustomerDelete();
					}
				}
			}

			if (response && response?.data?.body?.status === COMMONVARIABLES.STATUS.EXISTS) {
				toast.error(response.data.body.message, { position: toast.POSITION.TOP_RIGHT });
			}
			if (response?.data?.body?.status === COMMONVARIABLES.STATUS.SERVICE_CATCH_STATUS) {
				toast.error(ERROR_MESSAGE.API_ERROR.SERVICE_CATCH_ERROR, { position: toast.POSITION.TOP_RIGHT });
			}
			if (response?.data?.body?.status === COMMONVARIABLES.STATUS.SUCCESS) {
				swal(response.data.body.message, {
					icon: "success",
				});
				await udfnCustomerList();
			} else {
				toast.error(response.data.body.message, { position: toast.POSITION.TOP_RIGHT });
			}
		} catch (error) {
			// Handle error
			setIsLoading(false);
			console.error("Customer list API Error:", error);
			if (error.code === COMMONVARIABLES.STATUS.NETWORK_ERROR_STATUS) {
				toast.error(ERROR_MESSAGE.API_ERROR.NETWORK_ERROR, { position: toast.POSITION.TOP_RIGHT });
			}
			if (error.code === COMMONVARIABLES.STATUS.BAD_REQUEST_ERROR_STATUS) {
				toast.error(ERROR_MESSAGE.API_ERROR.BAD_REQUEST_ERROR, { position: toast.POSITION.TOP_RIGHT });
			}
		}
	};

	const exportToExcel = async () => {
		setIsLoading(true)
		setExcelButtonFlag(true)

		const workbook = new ExcelJS.Workbook()
		const worksheet = workbook.addWorksheet('Data')

		// Add headers with styling
		const headerRow = worksheet.addRow([
			'S.No.',
			'Customer Name',
			'Address',
			'City',
			'State',
			'Pincode',
			'GSTIN',
			'Contact Person',
			'Mobile Number',
			'Email',
			'Bank Name',
			'Branch Name',
			'Account Name',
			'ACC.NO.',
			'IFSC Code',
			'PAN NO.',
			'Status'
		])

		headerRow.font = { bold: true }
		headerRow.fill = {
			type: 'pattern',
			pattern: 'solid',
			fgColor: { argb: 'c0d5e8' } // Background color
		}

		// Add data
		customerList.forEach((item, index) => {
			worksheet.addRow([
				index + 1,
				item.cm_customer_name,
				item.cm_address,
				item.cm_city,
				item.sm_state_name,
				item.cm_pincode,
				item.cm_gstin,
				item.cm_contact_person ? item.cm_contact_person : '-',
				item.cm_mobile_number,
				item.cm_emailid ? item.cm_emailid : '-',
				item.cm_bank_name ? item.cm_bank_name : '-',
				item.cm_account_name ? item.cm_account_name : '-',
				item.cm_branch_name ? item.cm_branch_name : '-',
				item.cm_account_number ? item.cm_account_number : '-',
				item.cm_ifsc_code ? item.cm_ifsc_code : '-',
				item.cm_pan_number ? item.cm_pan_number : '-',
				item.status
			])
		})

		// Save the workbook
		const buffer = await workbook.xlsx.writeBuffer()
		const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })

		const url = URL.createObjectURL(blob)
		const a = document.createElement('a')
		a.href = url
		a.download = `Customer Report.xlsx`
		document.body.appendChild(a)
		a.click()
		document.body.removeChild(a)
		setIsLoading(false)
		setExcelButtonFlag(false)
	}

	const buttonRef = useRef(null);

	const handleKeyDown = (event) => {
		// Check if the pressed key is the Tab key (key code 9)
		if (event.keyCode === 9) {
			// Prevent the default Tab behavior to prevent losing focus from the text input
			event.preventDefault();

			// Focus the button element
			buttonRef.current.focus();
		}
	};

	const udfnAddressKeyDown = (event) => {
		// Check if the pressed key is the Enter key (key code 13)
		if (event.keyCode === 13) {
			// Prevent the default Enter behavior to prevent adding new lines

			// Get the number of lines in the textarea
			const lines = event.target.value.split('\n');

			// Check if the maximum number of lines (e.g., 5) is reached
			if (lines.length >= 3) {
				// Do not allow further input
				event.preventDefault();
			}
		}
	};

	const openCard = () => {
		udfnClearForm()
		setAddCard(true)
	}

	useEffect(() => {
		setStateOptions(stateList);
		udfnCustomerList()
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<ToastContainer />
			<LoadingOverlay
				active={isLoading}
				spinner
				text='Loading ...'
			>
				<div className="card">
					<div className="card-header">
						<div className='col-sm-5'>
							<h4 className="card-title">Customer Master </h4>
						</div>
						<div className='col-sm-3'>
							<p className="bgl-primary rounded p-2 text-center mb-2"><span className="text-black" >Total Active Customers  :  </span><Badge bg='info badge-rounded' className='text-black font-w400 fs-18'>{customerTotalCount}</Badge></p>
						</div>
						<div className='col-sm-2 text-center'>
							<Button variant="info" className="mb-2" onClick={() => exportToExcel()} disabled={excelButtonFlag ? true : false}>
								<i className="fa fa-download" /> &nbsp; Export
							</Button>
						</div>
						<div className='col-sm-2'>
							<Link className="btn btn-primary mb-2" onClick={() => openCard()}>
								<i className="fa fa-plus"></i> &nbsp; Add Customer
							</Link>
						</div>
					</div>
					<div className="card-body">
						<div className="table-responsive">
							<table {...getTableProps()} className="table filtering-table table-responsive-lg">
								<thead>
									{headerGroups.map(headerGroup => (
										<tr {...headerGroup.getHeaderGroupProps()}>
											<th>
												<p className='tbl-heading-txt'>S.NO.</p>
											</th>
											{headerGroup.headers.map((column, index) => (
												<th {...column.getHeaderProps()} key={index}>
													{column.render('Header')}
													{column.canFilter ? column.render('Filter') : null}
												</th>
											))}
											<th>
												<p className='tbl-heading-txt'>Actions</p>
											</th>
										</tr>
									))}
								</thead>
								<tbody {...getTableBodyProps()} className="" >
									{page.map((row, index) => {
										return (
											<tr key={index}>
												<td>{index + 1}</td>
												<td>{row.original.cm_customer_name}</td>
												<td>{row.original.cm_city}</td>
												<td>{row.original.cm_gstin}</td>
												<td>{row.original.cm_contact_person}</td>
												<td>{row.original.cm_mobile_number}</td>
												<td>{row.original.cm_emailid}</td>
												<td>
													{row.original.status === "Active" ? <Badge bg="" className='badge-success light'>
														<span className="me-2">
															<i className="ms-1 fa fa-check" />
														</span>
														{row.original.status}
													</Badge> : <Badge bg="" className='badge-primary light'>
														<span className="me-2">
															<i className="fa fa-times"></i>
														</span>
														{row.original.status}
													</Badge>
													}
												</td>
												<td>
													<Link className="btn btn-secondary	 shadow btn-xs sharp me-2"
														onClick={() => udfnEditLoad(row.original)}
													>
														<i className="fa fa-pencil" id={"printtooltip" + index}></i>
														<UncontrolledTooltip placement="top" target={"printtooltip" + index}>
															Edit
														</UncontrolledTooltip>
													</Link>
													<Link className="btn btn-danger shadow btn-xs sharp" onClick={() => udfnDelete(row.original.cm_customer_code)}>
														<i className="fa fa-trash" id={"deletetooltip" + index}></i>
														<UncontrolledTooltip placement="top" target={"deletetooltip" + index}>
															Delete
														</UncontrolledTooltip>
													</Link>
												</td>
											</tr>
										)
									})}
								</tbody>
							</table>
							<div className="d-flex justify-content-between">
								<span>
									Page{' '}
									<strong>
										{pageIndex + 1} of {pageOptions.length}
									</strong>{''}
								</span>
								<span className="table-index">
									Go to page : {' '}
									<input type="number"
										className="ms-2"
										defaultValue={pageIndex + 1}
										onChange={e => {
											const pageNumber = e.target.value ? Number(e.target.value) - 1 : 0
											gotoPage(pageNumber)
										}}
									/>
								</span>
							</div>
							<div className="text-center">
								<div className="filter-pagination  mt-3">
									<button className=" previous-button" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>{'<<'}</button>

									<button className="previous-button" onClick={() => previousPage()} disabled={!canPreviousPage}>
										Previous
									</button>
									<button className="next-button" onClick={() => nextPage()} disabled={!canNextPage}>
										Next
									</button>
									<button className=" next-button" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>{'>>'}</button>
								</div>
							</div>
						</div>
					</div>
				</div>
				<Modal className="fade bd-example-modal-lg" show={addCard} onHide={setAddCard} size="lg" backdrop="static">
					<Modal.Header>
						<h4 className="text-black">{editFlag === true ? 'Update Customer' : 'Add Customer'}</h4>
						<Button
							variant=""
							className="close"
							onClick={() => udfnClearForm()}
						>
							<span>&times;</span>
						</Button>
					</Modal.Header>
					<Modal.Body>
						<div className="basic-form">
							<form onSubmit={(e) => e.preventDefault()}>
								<div className="row">
									<div className="form-group mb-3 col-md-6">
										<label className="text-black font-w300">Customer Name <span className="text-danger star-txt">*</span></label>
										<input
											type="text"
											className="form-control"
											value={customerName}
											autoFocus
											onKeyUp={(e) => udfnNameValidation(e)}
											onChange={(e) => setCustomerName(e.target.value)}
											placeholder="Customer Name"
											maxLength="100"
										/>
									</div>
									<div className="form-group mb-3 col-md-6">
										<label className="text-black font-w300">Address</label>
										<span className="text-danger star-txt">*</span>
										<textarea
											className="form-control text-area-udf1"
											rows={3}
											value={customerAddress}
											onChange={(e) => setCustomerAddress(e.target.value)}
											onKeyDown={udfnAddressKeyDown}
											placeholder="Address"
											maxLength='250'
										/>
									</div>
									<div className="form-group mb-3 col-md-6">
										<label className="text-black font-300">Contact Person</label>
										<input
											type="text"
											className="form-control"
											value={customerContactPerson}
											onKeyUp={(e) => udfnNameValidation(e)}
											onChange={(e) => setCustomerContactPerson(e.target.value)}
											placeholder="Contact"
											maxLength="100"
										/>
									</div>
									<div className="form-group mb-3 col-md-6">
										<label className="text-black font-w300">State <span className="text-danger star-txt">*</span></label>
										<Select
											defaultValue={customerState}
											onChange={setCustomerState}
											value={customerState}
											options={stateOptions.map((option) => ({
												label: option.sm_state_name,
												value: option.sm_state_code,
											}))}
											showContent="true"
											open="true"
											className="my-react-select-container"
											theme={theme => ({
												...theme,
												borderRadius: '6px',
												colors: {
													...theme.colors,
													primary25: '#b3b3b3',
													primary: 'black',
													neutral80: "data-theme-version" === "dark" ? 'white' : ''
												},
											})}
										/>
									</div>
									<div className="form-group mb-3 col-md-6">
										<label className="text-black font-w300">GSTIN</label>
										<input
											type="text"
											className="form-control"
											value={customerGstin}
											onKeyUp={(e) => udfnUpperCase(e)}
											onKeyPress={(e) => udfnGstinValidation(e)}
											onChange={(e) => setCustomerGstin(e.target.value)}
											placeholder="GSTIN"
											maxLength="15"
										/>
									</div>
									<div className="form-group mb-3 col-md-6">
										<label className="text-black font-w300">City <span className="text-danger star-txt">*</span></label>
										<input
											type="text"
											className="form-control"
											value={customerCity}
											onKeyUp={(e) => udfnNameValidation(e)}
											onKeyPress={(e) => udfnCityValidation(e)}
											onChange={(e) => setCustomerCity(e.target.value)}
											placeholder="City"
											maxLength="100"
										/>
									</div>
									<div className="form-group mb-3 col-md-6">
										<label className="text-black font-w300">Mobile Number <span className="text-danger star-txt">*</span></label>
										<input
											type="text"
											className="form-control"
											value={customerMobile}
											onChange={(e) => setCustomerMobile(e.target.value)}
											onKeyPress={(e) => udfnPhoneNumberValidation(e)}
											placeholder="Mobile"
										/>
									</div>
									<div className="form-group mb-3 col-md-6">
										<label className="text-black font-w300">Pincode <span className="text-danger star-txt">*</span></label>
										<input
											type="text"
											className="form-control"
											value={customerPincode}
											onChange={(e) => setCustomerPincode(e.target.value)}
											onKeyPress={(e) => udfnPincodeValidation(e)}
											placeholder="Pincode"
											maxLength="6"
										/>
									</div>
									<div className="form-group mb-3 col-md-6">
										<label className="text-black font-300">Email</label>
										<input
											type="email"
											className="form-control"
											value={customerEmail}
											onKeyUp={(e) => udfnLowerCase(e)}
											onChange={(e) => setCustomerEmail(e.target.value)}
											placeholder="Email"
											maxLength="100"
										/>
									</div>
									<div className="form-group mb-3 col-md-12">
										<Accordion className="accordion accordion-rounded-stylish accordion-bordered accordion-primary mt-4" defaultActiveKey="0">
											<Accordion.Item>
												<Accordion.Header className="accordion-header accordion-header--primary">
													<span className="accordion-header-icon"></span>
													<span className="accordion-header-text">Bank Details</span>
													<span className="accordion-header-indicator">
													</span>
												</Accordion.Header>
												<Accordion.Collapse className="accordion__body">
													<div className="accordion-body">
														<div className='row'>
															<div className="form-group mb-3 col-md-6">
																<label className="text-black font-300">Bank Name</label>
																<input
																	type="text"
																	className="form-control"
																	value={customerBankName}
																	onKeyUp={(e) => udfnNameValidation(e)}
																	onKeyPress={(e) => udfnBankNameValidation(e)}
																	onChange={(e) => setCustomerBankName(e.target.value)}
																	placeholder="Bank Name"
																	maxLength="100"
																/>
															</div>
															<div className="form-group mb-3 col-lg-6">
																<label className="text-black font-w300">Branch Name</label>
																<input
																	className="form-control"
																	placeholder="Branch Name"
																	maxLength='100'
																	onKeyUp={(e) => udfnNameValidation(e)}
																	onKeyPress={(e) => udfnBranchNameValidation(e)}
																	value={customerBranchName}
																	onChange={(e) => setCustomerBranchName(e.target.value)}
																/>
															</div>
															<div className="form-group mb-3 col-lg-6">
																<label className="text-black font-w300">Account Name</label>
																<input
																	className="form-control"
																	placeholder="Account Name"
																	maxLength='100'
																	onKeyUp={(e) => udfnNameValidation(e)}
																	onKeyPress={(e) => udfnAccountNameValidation(e)}
																	value={customerAccountName}
																	onChange={(e) => setCustomerAccountName(e.target.value)}
																/>
															</div>
															<div className="form-group mb-3 col-md-6">
																<label className="text-black font-300">ACC.NO.</label>
																<input
																	type="text"
																	className="form-control"
																	value={customerAccNo}
																	onKeyPress={(e) => udfnAccountNumberValidation(e)}
																	onChange={(e) => setCustomerAccNo(e.target.value)}
																	placeholder="Account Number"
																	maxLength="20"
																/>
															</div>
														</div>
														<div className='row'>
															<div className="form-group mb-3 col-md-6">
																<label className="text-black font-300">IFSC Code</label>
																<input
																	type="text"
																	className="form-control"
																	value={customerIfscCode}
																	onKeyUp={(e) => udfnUpperCase(e)}
																	onKeyPress={(e) => udfnIFSCCodeValidation(e)}
																	onChange={(e) => setCustomerIfscCode(e.target.value)}
																	placeholder="IFSC Code"
																	maxLength="15"
																/>
															</div>
														</div>
													</div>
												</Accordion.Collapse>
											</Accordion.Item>
										</Accordion>
									</div>
									<div className="form-group mb-3 col-md-6">
										<label className="text-black font-300">PAN No.</label>
										<input
											type="email"
											className="form-control"
											value={customerPanNo}
											onKeyPress={(e) => udfnPanNoValidation(e)}
											onKeyUp={(e) => udfnUpperCase(e)}
											onKeyDown={handleKeyDown}
											onChange={(e) => setCustomerPanNo(e.target.value)}
											placeholder="PAN Number"
											maxLength="10"
										/>
									</div>
									{editFlag === true ?
										<div className="form-group col-md-6">
											<fieldset className="form-group mt-5">
												<div className="row">
													<label className="col-form-label col-sm-3 pt-1 text-black font-w300">
														Status
													</label>
													<div className="col-sm-3">
														<div className="form-check checkbox-success">
															{status === 1 ? <input
																className="form-check-input success"
																type="radio"
																name="gridRadios"
																onClick={() => setStatus(1)}
																onKeyDown={handleKeyDown}
																defaultChecked
															/> :
																<input
																	className="form-check-input success"
																	type="radio"
																	name="gridRadios"
																	onClick={() => setStatus(1)}
																	onKeyDown={handleKeyDown}
																/>}
															<label className="form-check-label">
																Active
															</label>
														</div>
													</div>
													<div className="col-sm-5">
														<div className="form-check checkbox-danger">
															{status === 2 ?
																<input
																	className="form-check-input"
																	type="radio"
																	name="gridRadios"
																	onClick={() => setStatus(2)}
																	onKeyDown={handleKeyDown}
																	defaultChecked
																/> :
																<input
																	className="form-check-input"
																	type="radio"
																	name="gridRadios"
																	onClick={() => setStatus(2)}
																	onKeyDown={handleKeyDown}
																/>}
															<label className="form-check-label">
																Inactive
															</label>
														</div>
													</div>
												</div>
											</fieldset>
										</div>
										: ''}
								</div>
							</form>
						</div>
					</Modal.Body >
					<Modal.Footer>
						<Button
							variant="danger"
							className="me-2"
							onClick={() => udfnClearForm()}
						>
							Discard
						</Button>
						<Button variant="whatsapp" ref={buttonRef} onClick={udfnFormValidate} disabled={buttonFlag ? true : false}>
							{editFlag === true ? 'Update' : 'Save'}
						</Button>
					</Modal.Footer>
				</Modal >
			</LoadingOverlay>
		</>
	)

}
export default CustomerMaster;