import React, { useMemo, useState, useEffect } from 'react';
import { useTable, useGlobalFilter, useFilters, useSortBy, usePagination } from 'react-table';
import { COLUMNS } from '../Filters/SalesmanReport/SalesmanReportColumns';
import { Button, Accordion } from 'react-bootstrap';
import DateRangePicker from "react-bootstrap-daterangepicker";
import '../Filters/Common/filtering.css';
import "bootstrap-daterangepicker/daterangepicker.css";
import { ToastContainer, toast } from "react-toastify";
import Select from "react-select";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { getLoggedInUser, getRefreshToken } from '../../../services/AuthService';
import { UserLogout } from '../../layouts/nav/Logout';
import COMMONVARIABLES from '../Utilities/constants';
import Formatting from '../Utilities/format';
import COMMON_API from '../../Api/CommonApi';
import MASTER_API from '../../Api/MasterApi';
import LoadingOverlay from 'react-loading-overlay-ts';
import ERROR_MESSAGE from '../Validation/ErrorMessage';
import moment from 'moment';
const ExcelJS = require('exceljs');

export const SalesmanReport = () => {
    const user = getLoggedInUser();
    const BASE_URL = process.env.REACT_APP_BASE_URL;

    const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } };

    const [excelButtonFlag, setExcelButtonFlag] = useState(false);
    const [range, setRange] = useState(localStorage.getItem('salesManReportRange') || null)

    var start_date = localStorage.getItem('salesManReportRange') && new Date(localStorage.getItem('salesManReportRange').split('-')[0]).toDateString();
    var end_date = localStorage.getItem('salesManReportRange') && new Date(localStorage.getItem('salesManReportRange').split('-')[1]).toDateString();

    const [rangeDate, setRangeDate] = useState(localStorage.getItem('salesManReportRange') && localStorage.getItem('salesManReportRange') !== "null" ? { startDate: new Date(start_date), endDate: new Date(end_date) } : { startDate: new Date(), endDate: new Date() })

    const [salesmanOptions, setSalesmanOptions] = useState([])
    const [salesmanCode, setSalesmanCode] = useState(JSON.parse(localStorage.getItem('salesManReportSalesManName')) || "")
    const [salesmanList, setSalesmanList] = useState([])
    const [salesmanTotal, setSalesmanTotal] = useState(0)
    const [isLoading, setIsLoading] = useState(false);

    const columns = useMemo(() => COLUMNS, [])
    const data = salesmanList
    const tableInstance = useTable({
        columns,
        data,
        initialState: { pageIndex: 0 }
    }, useFilters, useGlobalFilter, useSortBy, usePagination)

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        state,
        page,
        gotoPage,
        pageCount,
        pageOptions,
        nextPage,
        previousPage,
        canNextPage,
        canPreviousPage,
    } = tableInstance

    Date.prototype.toDateString = function () {
        var month = this.getMonth() + 1;
        var day = this.getDate();
        var year = this.getFullYear();
        return year + "-" + (month < 10 ? '0' : '') + month + '-' + (day < 10 ? '0' : '') + day;
    };

    const { pageIndex } = state

    const udfnSalesmanList = async () => {
        setIsLoading(true)
        try {
            const salesmanParams = { user_id: Number(user?.id), status_code: Number(0) };

            const [salesmanData] = await Promise.all([
                axios.post(BASE_URL + COMMON_API.SALESMAN_LIST, salesmanParams, config),
            ]);

            if (salesmanData?.data?.body?.status === COMMONVARIABLES.STATUS.INVALIDUSER) {
                setIsLoading(false)
                toast.error(salesmanData?.data?.body?.message, { position: toast.POSITION.TOP_RIGHT });
                UserLogout();
            }

            if (salesmanData?.data?.body?.status === COMMONVARIABLES.STATUS.UNAUTHORIZED) {
                setIsLoading(false)
                const refresh = await getRefreshToken();
                if (refresh) {
                    if (Number(refresh) === COMMONVARIABLES.STATUS.EXPIRED_TOKEN) {
                        UserLogout();
                    } else {
                        await udfnSalesmanList();
                    }
                }
            }

            if (salesmanData?.data?.body?.salesManDetails) {
                setSalesmanOptions(salesmanData?.data?.body?.salesManDetails)
                setIsLoading(false)
            }
        } catch (error) {
            setIsLoading(false)
            console.error('Error fetching master data:', error);
        }
    }

    async function udfnSalesmanReport() {
        try {
            setIsLoading(true)
            var varFromDate = new Date().toDateString()
            var varToDate = new Date().toDateString()
            if (range !== "null" && range !== null) {
                varFromDate = new Date(range.split('-')[0]).toDateString()
                varToDate = new Date(range.split('-')[1]).toDateString()
            }

            const params = {
                user_id: Number(user?.id),
                from_date: varFromDate,
                to_date: varToDate,
                salesman_code: salesmanCode?.value === '' || salesmanCode?.value === undefined ? 0 : salesmanCode?.value,
            };

            const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } };
            const [salesmanList] = await Promise.all([
                axios.post(BASE_URL + MASTER_API.SALESMAN_LIST, params, config),
            ]);
            setIsLoading(false)

            if (salesmanList && salesmanList?.data?.body?.status === COMMONVARIABLES.STATUS.INVALIDUSER) {
                toast.error(salesmanList?.data?.body?.message, { position: toast.POSITION.TOP_RIGHT });
                UserLogout();
            }

            if (salesmanList?.data?.body?.status === COMMONVARIABLES.STATUS.UNAUTHORIZED) {
                const refresh = await getRefreshToken();
                if (refresh) {
                    if (Number(refresh) === COMMONVARIABLES.STATUS.EXPIRED_TOKEN) {
                        UserLogout();
                    } else {
                        await udfnSalesmanReport();
                    }
                }
            }

            if (salesmanList?.data?.body?.salesmanList) {
                setSalesmanList(salesmanList?.data?.body?.salesmanList)
                var total = 0;
                salesmanList?.data?.body?.salesmanList.forEach(row => {
                    total = total + row.amount;
                });
                setSalesmanTotal(COMMONVARIABLES.India_Rupee_Formatter_Digits(Formatting('two_decimal', total)))
            }

        } catch (error) {
            setIsLoading(false)
            console.error('Error fetching master data:', error);
        }
    }

    const exportToExcel = async () => {
        if (salesmanList.length > 0) {
            setIsLoading(true)
            setExcelButtonFlag(true)

            const array_data = salesmanList
            const array_data_count = salesmanList.length

            // colorWiseTotalDispatch
            let Salesman_Report = []
            let total_amount = 0

            Salesman_Report = array_data.map((item, index) => {
                total_amount = total_amount + item.amount;
                var amount = COMMONVARIABLES.India_Rupee_Formatter_Digits(Formatting('two_decimal', item.amount))
                return {
                    "S.NO.": index + 1, "DATE": item.bill_date, "SALESMAN CODE": item.salesman_code, "SALESMAN NAME": item.salesman_name, "TOTAL AMOUNT": amount
                }
            })

            const data_array = []
            Salesman_Report.forEach((row) => {
                data_array.push(Object.values(row))
            })

            var varFromDate = new Date().toDateString()
            var varToDate = new Date().toDateString()
            if (range !== "null" && range !== null) {
                varFromDate = new Date(range.split('-')[0]).toDateString()
                varToDate = new Date(range.split('-')[1]).toDateString()
            }

            const title = 'Salesman Report On (' + moment(varFromDate).format("DD-MM-YYYY") + ') to (' + moment(varToDate).format("DD-MM-YYYY") + ')'
            const reportData = {
                title: title,
                data: data_array,
                headers: Object.keys(Salesman_Report[0])
            }

            const workbook = new ExcelJS.Workbook()
            const worksheet = workbook.addWorksheet('Salesman')

            worksheet.mergeCells('A1', 'E1')
            const titleRow = worksheet.getCell('A1')
            titleRow.value = reportData['title']
            titleRow.font = {
                name: 'Calibri',
                size: 16,
                bold: true,
                color: { argb: '000000' }
            }
            titleRow.alignment = { vertical: 'middle', horizontal: 'center' }

            worksheet.mergeCells('A2', 'E2')
            const salesman_filter_head = 'Salesman Name : '
            const salesman_filter_label = salesmanCode ? salesmanCode.label : 'All'
            const itemcodeRow = worksheet.getCell('A2')
            itemcodeRow.value = salesman_filter_head + salesman_filter_label
            itemcodeRow.font = {
                name: 'Calibri',
                size: 12,
                color: { argb: '00000' }
            }
            // itemcodeRow.alignment = { vertical: 'middle', horizontal: 'center' }

            //Adding Header Row
            const headerRow = worksheet.addRow(reportData['headers'])
            headerRow.eachCell((cell) => {
                cell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'ff8b3d' },
                    bgColor: { argb: '' }
                }
                cell.font = {
                    bold: true,
                    color: { argb: 'FFFFFF' },
                    size: 12
                }
            })

            data_array.forEach(item => {
                worksheet.addRow(item)
            })

            total_amount = COMMONVARIABLES.India_Rupee_Formatter_Digits(Formatting('two_decimal', total_amount))

            const total = ['', '', '', 'Total', total_amount]
            const footerRow = worksheet.addRow(total)
            footerRow.eachCell((cell) => {
                cell.font = {
                    bold: true,
                    color: { argb: '000000' },
                    size: 14
                }
            })

            worksheet.getColumn(1).width = 8
            worksheet.getColumn(2).width = 15
            worksheet.getColumn(3).width = 20
            worksheet.getColumn(4).width = 20
            worksheet.getColumn(5).width = 20

            var starting_cell = 3;
            var last_cell = array_data_count + starting_cell + 1

            for (let i = starting_cell; i <= last_cell; i++) {
                const salesmanCodeColumn = worksheet.getCell('C' + i)
                const totalAmountColumn = worksheet.getCell('E' + i)
                salesmanCodeColumn.alignment = { vertical: 'middle', horizontal: 'right' }
                totalAmountColumn.alignment = { vertical: 'middle', horizontal: 'right' }
            }

            // Save the workbook
            const buffer = await workbook.xlsx.writeBuffer()
            const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })

            const url = URL.createObjectURL(blob)
            const a = document.createElement('a')
            a.href = url
            a.download = `Salesman Report.xlsx`
            document.body.appendChild(a)
            a.click()
            document.body.removeChild(a)

            setIsLoading(false)
            setExcelButtonFlag(false)
        } else {
            toast.error(ERROR_MESSAGE.EXPORT_TO_EXCEL_NO_RECORD, { position: toast.POSITION.TOP_RIGHT });
        }
    }

    useEffect(() => {
        setSalesmanList([])
        setSalesmanTotal(0)
    }, [range, salesmanCode?.value]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        udfnSalesmanList()
        udfnSalesmanReport()
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        localStorage.setItem('salesManReportSalesManName', JSON.stringify(salesmanCode))
        localStorage.setItem('salesManReportRange', range)
    }, [range, salesmanCode])  // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <ToastContainer />
            <LoadingOverlay
                active={isLoading}
                spinner
                text='Loading ...'
            >
                <div className="card">
                    <div className="card-header pt-3 pb-0">
                        <div className='col-sm-5'>
                            <h4 className="card-title">Salesman Report</h4>
                        </div>
                        <div className='col-sm-4 text-end'>
                            <Button variant="info" className="mb-2" onClick={() => exportToExcel()} disabled={excelButtonFlag ? true : false}>
                                <i className="fa fa-download" /> &nbsp; Export
                            </Button>
                        </div>
                        <div className='col-sm-2 mb-2'>
                            <div className="bg-secondary coin-holding flex-wrap py-0 m-0">
                                <div className="mb-2 coin-bx">
                                    <div className="d-flex align-items-center">
                                        <div>
                                            <i className="fa fa-rupee udfn-rupee-icon"></i>
                                        </div>
                                        <div className="ms-3">
                                            <h4 className="coin-font font-w600 mb-0 text-white mt-2">Total</h4>
                                            <p className="mb-0 text-white op-6">{salesmanTotal}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card-body">
                        <Accordion className="accordion accordion-rounded-stylish accordion-bordered  accordion-active-header" defaultActiveKey="0">
                            <Accordion.Item>
                                <Accordion.Header className="accordion-header accordion-header-primary">
                                    <h5>Filters</h5>
                                    <span className="accordion-header-indicator "></span>
                                </Accordion.Header>
                                <Accordion.Collapse className="accordion__body">
                                    <div className="accordion-body">
                                        <div className="row">
                                            <div className="col-md-4 mb-3">
                                                <div className="example rangeDatePicker">
                                                    <p className="mb-1">Select From Date And To Date <span className="text-danger star-txt">*</span></p>
                                                    <DateRangePicker
                                                        onApply={(e) => {
                                                            setRange(e.target.value)
                                                        }}
                                                        initialSettings={rangeDate}
                                                    >
                                                        <input type="text" className="form-control input-daterange-timepicker" styles={{ height: "90%" }} />
                                                    </DateRangePicker>
                                                </div>
                                            </div>
                                            <div className="col-md-4 mb-3">
                                                <p className="mb-1">Salesman Name</p>
                                                <Select
                                                    defaultValue={salesmanCode}
                                                    onChange={setSalesmanCode}
                                                    options={salesmanOptions.map((option) => ({
                                                        label: option?.sm_sales_man_name + ' - ' + option?.sm_sales_man_code,
                                                        value: option?.sm_sales_man_code,
                                                    }))}
                                                    isClearable
                                                    showContent="true"
                                                    open="true"
                                                    className="my-react-select-container"
                                                    theme={theme => ({
                                                        ...theme,
                                                        borderRadius: '6px',
                                                        colors: {
                                                            ...theme.colors,
                                                            primary25: '#b3b3b3',
                                                            primary: 'black',
                                                            neutral80: "data-theme-version" === "dark" ? 'white' : ''
                                                        },
                                                    })}
                                                />
                                            </div>
                                            <div className="col-md-2 mt-3">
                                                <Button className="me-2" variant="whatsapp" style={{ marginTop: "6%" }} onClick={() => { udfnSalesmanReport() }}>
                                                    List{" "}
                                                    <span className="btn-icon-right">
                                                        <i className="fa fa-filter" />
                                                    </span>
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </Accordion.Collapse>
                            </Accordion.Item>
                        </Accordion>
                        <div className="table-responsive">
                            <div className='row'>
                                <div className='col-sm-9'>
                                    {/* <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} /> */}
                                </div>
                            </div>
                            <table {...getTableProps()} className="table filtering-table table-responsive-lg">
                                <thead>
                                    {headerGroups.map((headerGroup, index) => (
                                        <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                                            {headerGroup.headers.map((column, index) => (
                                                <>
                                                    {column.Header === 'Total Amount' ?
                                                        <th className='text-end' key={index}>
                                                            {column.render('Header')}
                                                        </th>
                                                        :
                                                        <th key={index}>
                                                            {column.render('Header')}
                                                        </th>
                                                    }
                                                </>
                                            ))}
                                        </tr>
                                    ))}
                                </thead>
                                <tbody {...getTableBodyProps()}>
                                    {page.map((row, index) => {
                                        return (
                                            <tr key={index}>
                                                <td>{index + 1}</td>
                                                <td>{row.original.bill_date}</td>
                                                <td>{row.original.salesman_code}</td>
                                                <td>{row.original.salesman_name}</td>
                                                <td className='text-end'><i className="fa fa-rupee udfn-rupee-icon rupeeicon"></i> {COMMONVARIABLES.India_Rupee_Formatter_Digits(Formatting('two_decimal', row.original.amount))}</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                            <div className='text-center'>
                                {salesmanList.length < 1 ? "No records found." : ""}
                            </div>
                            <div className="d-flex justify-content-between">
                                <span>
                                    Page{' '}
                                    <strong>
                                        {pageIndex + 1} of {pageOptions.length}
                                    </strong>{''}
                                </span>
                                <span className="table-index">
                                    Go to page : {' '}
                                    <input type="number"
                                        className="ms-2"
                                        defaultValue={pageIndex + 1}
                                        onChange={e => {
                                            const pageNumber = e.target.value ? Number(e.target.value) - 1 : 0
                                            gotoPage(pageNumber)
                                        }}
                                    />
                                </span>
                            </div>
                            <div className="text-center">
                                <div className="filter-pagination  mt-3">
                                    <button className=" previous-button" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>{'<<'}</button>

                                    <button className="previous-button" onClick={() => previousPage()} disabled={!canPreviousPage}>
                                        Previous
                                    </button>
                                    <button className="next-button" onClick={() => nextPage()} disabled={!canNextPage}>
                                        Next
                                    </button>
                                    <button className=" next-button" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>{'>>'}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </LoadingOverlay>
        </>
    )
}
export default SalesmanReport;