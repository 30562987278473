import React, { useEffect, useMemo, useState } from 'react';
import { useTable, useGlobalFilter, useFilters, useSortBy, usePagination } from 'react-table';
import { COLUMNS } from '../Filters/SalesManPrint/SalesManPrintColumns';
import { GlobalFilter } from '../Filters/Common/GlobalFilter';
import { Button } from 'react-bootstrap';
import '../Filters/Common/filtering.css';
import '../../../css/custom.css';
import ERROR_MESSAGE from '../Validation/ErrorMessage';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getLoggedInUser, getRefreshToken } from "../../../services/AuthService"
import MASTER_API from '../../Api/MasterApi';
import { UserLogout } from '../../layouts/nav/Logout';
import axios from 'axios';
import COMMONVARIABLES from '../Utilities/constants';
import LoadingOverlay from 'react-loading-overlay-ts';
import QRCode from "react-qr-code";
import swal from "sweetalert";

export const SalesmanPrint = () => {
    const user = getLoggedInUser();
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } };

    const [isLoading, setIsLoading] = useState(false);
    const [salesmanList, setSalesmanList] = useState([]);
    const [noOfPrint, setNoOfPrint] = useState('');
    const myid = "myqr"
    var slice_array = [];

    const columns = useMemo(() => COLUMNS, [])
    const data = salesmanList

    const tableInstance = useTable({
        columns,
        data,
        initialState: { pageIndex: 0 }
    }, useFilters, useGlobalFilter, useSortBy, usePagination)

    const {
        getTableProps,
        getTableBodyProps,
        prepareRow,
        state,
        page,
        gotoPage,
        pageCount,
        pageOptions,
        nextPage,
        previousPage,
        canNextPage,
        canPreviousPage,
        setGlobalFilter,
    } = tableInstance

    const { globalFilter, pageIndex } = state

    const udfnNumberValidation = (event) => {
        const regex = /^[0-9]*$/;
        const input = event.target.value + event.key;
        if (!regex.test(input) || input.length > 3) {
            event.preventDefault();
        }
    };

    const udfnSalesmanList = async () => {
        setIsLoading(true)

        var apiUrl = BASE_URL + MASTER_API.SALESMAN_MASTER_LIST;

        const uploadData = {
            user_id: Number(user.id)
        };

        try {
            const response = await axios.post(apiUrl, uploadData, config);
            setIsLoading(false)
            if (response && response?.data?.body?.status === COMMONVARIABLES.STATUS.INVALIDUSER) {
                toast.error(response.data.body.message, { position: toast.POSITION.TOP_RIGHT });
                UserLogout();
            }

            if (response?.data?.body?.status === COMMONVARIABLES.STATUS.UNAUTHORIZED) {
                const refresh = await getRefreshToken();

                if (refresh) {
                    if (Number(refresh) === COMMONVARIABLES.STATUS.EXPIRED_TOKEN) {
                        UserLogout();
                    } else {
                        await udfnSalesmanList();
                    }
                }
            }

            if (response?.data?.body?.status === COMMONVARIABLES.STATUS.SERVICE_CATCH_STATUS) {
                toast.error(ERROR_MESSAGE.API_ERROR.SERVICE_CATCH_ERROR, { position: toast.POSITION.TOP_RIGHT });
            }

            if (response?.data?.body?.salesmanList) {
                setSalesmanList(response.data.body.salesmanList);
                // var salesmanListCount = response.data.body.salesmanList.filter(item => item.sm_status_code === 1).length;
                // setSalesmanTotalCount(salesmanListCount);
                response.data.body.salesmanList.map(row => {
                    row['no_of_print'] = 0
                    return row
                });
                toast.success(response.data.body.message, { position: toast.POSITION.TOP_RIGHT });
                setIsLoading(false);
            } else {
                toast.error(response.data.body.message, { position: toast.POSITION.TOP_RIGHT });
            }
        } catch (error) {
            setIsLoading(false)
            // Handle error
            console.error("Salesman list API Error:", error);
            if (error.code === COMMONVARIABLES.STATUS.NETWORK_ERROR_STATUS) {
                toast.error(ERROR_MESSAGE.API_ERROR.NETWORK_ERROR, { position: toast.POSITION.TOP_RIGHT });
            }
            if (error.code === COMMONVARIABLES.STATUS.BAD_REQUEST_ERROR_STATUS) {
                toast.error(ERROR_MESSAGE.API_ERROR.BAD_REQUEST_ERROR, { position: toast.POSITION.TOP_RIGHT });
            }
        }
    };

    const udfnFormValidate = (id) => {
        var noOfPrint = document.getElementById("noOfPrint" + id).value;
        console.log("noOfPrint =", noOfPrint);
        if (noOfPrint === 0 || noOfPrint === undefined || noOfPrint === null || noOfPrint === '') {
            toast.error(ERROR_MESSAGE.SALESMAN_PRINT_MASTER.SALESMAN_NO_OF_PRINT_VALID, { position: toast.POSITION.TOP_RIGHT });
        } else {
            // udfnSupplierCreate()
        }
    };

    function printQRCode(qrcodeList) {
        const printHtml = window.open('', 'PRINT', 'height=auto,width=100%')
        let html = ''
        let itemDetails = ''
        const user = getLoggedInUser()

        for (let i = 0; i < qrcodeList.length; i++) {
            for (let j = 0; j < Number(qrcodeList[i].no_of_print); j++) {
                const myids = "myqr"
                const getid = myids + qrcodeList[i].sm_sales_man_mannual_code
                const innerContents = document.getElementById(getid).innerHTML
                itemDetails += `
					<div class="float-child col-lg-6  ml-5 mt-5">				

						<div class="float-container botton-align mt-5">`
                itemDetails += `<div class="float-qrcode ml-2">
												<div class="qr_div">
													<div> ${innerContents}</div>
												
												</div>
												<div class="qr_div bold-design size-15 align-center  mt-5">${qrcodeList[i].sm_sales_man_mannual_code}</div>
											</div>
											<div class="float-details mt-5">
												<div class="bold-design .size-9 wor-wrap">${qrcodeList[i].sm_sales_man_name}</div>
											</div>
						</div>			
					</div>`
            }
        }

        html += `<html>
					<head>
						<title>Purchase Print</title>
						<style>
						.rotated {
							writing-mode: tb-rl;
							transform: rotate(-180deg);
							font-size: 10px;
						}
				
						.qr_div {
							display: flex;
							align-items: center;
							justify-content: center;
						}
				
						body {
							font-family: Arial, Helvetica, sans-serif;
							width: 2480 x 3508px !important; 
						}
				
						.row {
							width: 100%;
							/* margin-top: 10px; */
						}
				
						.column {
							float: left;
						}
				
						@media all {
							.page-break {
								display: none
							}
						}
						@media print {
				
							body {
							margin-top: 1 !important;
							color: #000;
							}
				
						}
						.heading {
							font-size: 13px !important;
							font-weight: bold;
							text-align: center
						}
				
						.align-center {
							flex-direction: column;
							text-align: left;
						}
				
						.align-left {
							text-align: left;
						}
				
						.align-right {
							text-align: right;
							font-size: large;
						}
				
						.paddding-space {
							/* padding-bottom: 15px */
						}
				
						.center {
							text-align: center;
						}
				
						.padding {
							padding-left: 10px
						}
				
						.qrcode {
							padding-left: 50px
						}
				
						.botton-align {
							/* padding-top: 10px */
						}
				
						.botton {
							padding-top: 10px
						}
				
						.size-10 {
							font-size: 10px !important;
						}
				
						.size-11 {
							font-size: 11px !important;
						}
						.size-9 {
							font-size: 9px !important;
						}
						.size-12 {
							font-size: 12px !important;
						}
				
						.size-15 {
							font-size: 15px !important;
						}
				
						.size-small {
							font-size: 10px !important;
							text-align: center !important;
							font-weight: bold;
						}
						.bold-design {
							font-weight: bold;
						}
				
						.float-child {
							display: flex;
							flex-direction: column;
						}
                        .col-lg-6 {
                            flex: 0 0 auto;
                            width: 45%;
                        }
				
						.float-container {
							width: 100%;
							display: flex;
							flex-direction: row;
						}
				
						.float-details {
							width: 70%;
							display: flex;
							flex-direction: column;
							text-align: left;
						}
				
						.float-qrcode {
							width: 50%;
							display: flex;
							flex-direction: column;
						}
				
						.marleft {
							padding-left: 20px;
						}
				
						.marleft-10 {
							padding-left: 10px;
						}
				
						.marright {
							padding-right: 20px;
						}
				
						.col-4 {
							flex: 0 0 auto;
							width: 133px !important;
							height: 83px;
						}
				
						.parent {
							display: flex;
							flex-wrap: wrap;
							flex-direction: row;
						}
				
						.mb-15 {
							margin-bottom: 15px;
						}
				
						.mt-5 {
							margin-bottom: 5px;
						}
						.ml-5 {
							margin-left: 5px;
						}
						.ml-10{
							margin-left: 10px;
						}
						.mt-5{
							margin-top: 5px;
						}
						.ml-2 {
							margin-left: 2px;
						}
						.mt-3 {
							margin-top: 3px;
						}
						.mt-2 {
							margin-top: 2px;
						}
						.wor-wrap {
							text-wrap: wrap;
							width: 60px;
							overflow-wrap: break-word;
							height: 36px;
							overflow: hidden;
						}
						.mar-top{
							margin-top: -5px;
						}
						.m-10{
							margin-top: 10px
						}
						</style>
					</head>
					<body onload="window.print();window.close()">
						<div class="row  ml-10">
							<div class='paddding-space'>
								<div class="float-container parent">
									${itemDetails}					
								</div>
							</div>				
						</div>		
					</body>
				</html>`
        printHtml.document.write(html)
        console.log(html)
        printHtml.document.close()
        printHtml.print()
        printHtml.close()
        return true
    }

    function qrCodePrint() {
        var salesmanListCount = salesmanList.length;
        var noOfPrintCount = salesmanList.filter(item => item.no_of_print === 0).length;
        if (salesmanListCount === noOfPrintCount) {
            toast.error(ERROR_MESSAGE.SALESMAN_PRINT_MASTER.SALESMAN_NO_OF_PRINT_VALID, { position: toast.POSITION.TOP_RIGHT });
        } else {
            swal({
                text:
                    "Are you sure you want print?",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            }).then((willDelete) => {
                if (willDelete) {
                    printQRCode(salesmanList)
                } else {
                    udfnSalesmanList()
                }
            })
        }
    }

    const udfnTextBoxSpecific = (id, row, value) => {
        if (value !== '') {
            slice_array = salesmanList;
            slice_array[id]['no_of_print'] = value;
            setSalesmanList(slice_array)
        }
    };

    useEffect(() => {
        udfnSalesmanList()
    }, []); // eslint-disable-line react-hooks/exhaustive-deps


    return (
        <>
            <ToastContainer />
            <LoadingOverlay
                active={isLoading}
                spinner
                text='Loading ...'
            >
                <div className="card">
                    <div className="card-header">
                        <h4 className="card-title">Salesman Print </h4>
                        <div className='col-sm-2'>
                            <Button className='btn btn-primary' onClick={qrCodePrint}>
                                <span>
                                    <i className="fa fa-print" />
                                </span>&nbsp;
                                <span>Print</span>
                            </Button>
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="table-responsive">
                            <div className='row'>
                                <div className='col-sm-9'>
                                    <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
                                </div>
                            </div>
                            <table {...getTableProps()} className="table filtering-table table-responsive-lg">
                                <thead>
                                    <tr>
                                        <th>
                                            S.NO.
                                        </th>
                                        <th>
                                            Salesman Name
                                        </th>
                                        <th>
                                            Salesman Manual Code
                                        </th>
                                        <th>
                                            Short Name
                                        </th>
                                        <th>
                                            Designation
                                        </th>
                                        <th className='text-center'>
                                            No.of Prints
                                        </th>
                                        {/* <th>
                                            Actions
                                        </th> */}
                                    </tr>
                                </thead>
                                <tbody {...getTableBodyProps()} className="" >
                                    {page.map((row, index) => {
                                        prepareRow(row)
                                        return (
                                            <tr key={index}>
                                                <td>{index + 1}</td>
                                                <td>{row.original.sm_sales_man_name}</td>
                                                <td>{row.original.sm_sales_man_mannual_code}</td>
                                                <td>{row.original.sm_short_name}</td>
                                                <td>{row.original.sm_designation}</td>
                                                <td className='text-center'>
                                                    <input type="number"
                                                        id={"noOfPrint" + index}
                                                        className="salesman-no-print btn-xs"
                                                        autoComplete="off"
                                                        onKeyPress={(e) => udfnNumberValidation(e)}
                                                        placeholder="No.of Print"
                                                        onChange={e => {
                                                            udfnTextBoxSpecific(index, row.original, e.target.value)
                                                        }}
                                                    />
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                            <div className="d-flex justify-content-between">
                                <span>
                                    Page{' '}
                                    <strong>
                                        {pageIndex + 1} of {pageOptions.length}
                                    </strong>{''}
                                </span>
                                <span className="table-index">
                                    Go to page : {' '}
                                    <input type="number"
                                        className="ms-2"
                                        defaultValue={pageIndex + 1}
                                        onChange={e => {
                                            const pageNumber = e.target.value ? Number(e.target.value) - 1 : 0
                                            gotoPage(pageNumber)
                                        }}
                                    />
                                </span>
                            </div>
                            <div className="text-center">
                                <div className="filter-pagination  mt-3">
                                    <button className=" previous-button" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>{'<<'}</button>

                                    <button className="previous-button" onClick={() => previousPage()} disabled={!canPreviousPage}>
                                        Previous
                                    </button>
                                    <button className="next-button" onClick={() => nextPage()} disabled={!canNextPage}>
                                        Next
                                    </button>
                                    <button className=" next-button" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>{'>>'}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {salesmanList.map((row) => (
                    <div id={myid + row.sm_sales_man_mannual_code} className='color-picker' style={{ display: 'none' }}>
                        <QRCode
                            size={80}
                            value={row.sm_sales_man_mannual_code.toString()}
                        />
                    </div>
                ))}
            </LoadingOverlay>
        </>
    )

}
export default SalesmanPrint;