import React, { useMemo, useState, useEffect, useRef } from 'react';
import { Link } from "react-router-dom";
import Select from "react-select";
import swal from "sweetalert";
import { useTable, useGlobalFilter, useFilters, useSortBy, usePagination } from 'react-table';
import { COLUMNS } from '../Filters/User/UserColumns';
import { GlobalFilter } from '../Filters/Common/GlobalFilter';
import { Modal, Button } from 'react-bootstrap';
import '../Filters/Common/filtering.css';
/// Bootstrap
import { Badge } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import FormValidation from '../Validation/FormValidation';
import ERROR_MESSAGE from '../Validation/ErrorMessage';
import axios from 'axios';
import {
    getLoggedInUser, getRefreshToken
} from '../../../services/AuthService';
import MASTER_API from '../../Api/MasterApi';
import { UserLogout } from '../../layouts/nav/Logout';
import COMMONVARIABLES from '../Utilities/constants';
import LoadingOverlay from 'react-loading-overlay-ts';
import COMMON_API from '../../Api/CommonApi';
import { UncontrolledTooltip } from "reactstrap"

const UserMaster = () => {

    //Modal box
    const [addCard, setAddCard] = useState(false);
    const [passwordCard, setPasswordCard] = useState(false);

    // Input Fields Declaration
    const [userName, setUserName] = useState('');
    const [userId, setUserId] = useState('');
    const [password, setPassword] = useState('');
    const [userRole, setUserRole] = useState({ label: 'Select Role', value: 0 });
    const [showPassword, setShowPassword] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [editFlag, setEditFlag] = useState(false);
    const [statusCode, setStatusCode] = useState(1);
    const [userList, setUserList] = useState([]);
    const [user_code, setUserCode] = useState(1);
    const [buttonFlag, setButtonFlag] = useState(true);
    const [status, setStatus] = useState(false);
    const [options, setOptions] = useState([]);
    const [userTotalCount, setUserTotalCount] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [branchName, setBranchName] = useState({ label: 'Select Branch', value: 0 });
    const [branchOptions, setBranchOptions] = useState([]);

    const columns = useMemo(() => COLUMNS, [])
    const data = userList;
    const tableInstance = useTable({
        columns,
        data,
        initialState: { pageIndex: 0 }
    }, useFilters, useGlobalFilter, useSortBy, usePagination)

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        state,
        page,
        gotoPage,
        pageCount,
        pageOptions,
        nextPage,
        previousPage,
        canNextPage,
        canPreviousPage,
        setGlobalFilter,
    } = tableInstance

    const { globalFilter, pageIndex } = state

    const user = getLoggedInUser();
    //Add Submit data
    const udfnFromValidate = (event) => {
        event.preventDefault();

        var commomError = false;
        var userValid = false;
        var userIdValid = false;
        var whiteSpaceValid = false;
        var passwordValid = false;
        var userNameError = false;
        var userIdError = false;
        var passwordError = false;
        var userRoleError = false;
        var errorMsg = '';

        // Validate All Inputs
        if (userRole.value === 3) {
            if (branchName.label === 'Select Branch') {
                commomError = true;
                errorMsg = ERROR_MESSAGE.COMMON_REQUIRED;
            }
        }
        if (!userName.trim() || !userId.trim() || !password.trim() || userRole.label === "Select Role") {
            commomError = true;
            errorMsg = ERROR_MESSAGE.COMMON_REQUIRED;
        }
        else {
            //userName Validation
            userValid = FormValidation('alpha_space', userName);
            if (!userValid) {
                userNameError = true;
                errorMsg = ERROR_MESSAGE.USER_MASTER.USER_NAME_VALID;
            }
        }

        //userId Validation
        if (userValid === true) {
            if (userId.length < 4) {
                userNameError = true;
                errorMsg = ERROR_MESSAGE.USER_MASTER.USERID_LENGTH_VALID;
            }
            else {
                userIdValid = FormValidation('alpha_numeric', userId);
                whiteSpaceValid = FormValidation('white_space', userId);
                if (!whiteSpaceValid || !userIdValid) {
                    userIdError = true;
                    errorMsg = ERROR_MESSAGE.USER_MASTER.USERID_VALID;
                }
            }
        }
        //password Validation
        if (userIdValid === true && editFlag === false) {
            passwordValid = FormValidation('password', password);
            if (password.length < 6) {
                passwordError = true;
                errorMsg = ERROR_MESSAGE.USER_MASTER.PASSWORD_VALID;
            }
            else if (!passwordValid) {
                passwordError = true;
                errorMsg = ERROR_MESSAGE.USER_MASTER.PASSWORD_VALID;
            }
        }

        if (!commomError && !userNameError && !userIdError && !passwordError && !userRoleError) {
            setButtonFlag(false);
            udfnUserCreate();
        } else {
            toast.error(errorMsg, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    };

    const udfnNameValidation = (event) => {
        var val = event.target.value.charAt(0).toUpperCase() + event.target.value.slice(1);
        event.target.value = val;
    };

    const udfnUserList = async () => {
        const user = getLoggedInUser();
        setIsLoading(true);
        const data = {
            user_id: user.id
        };
        const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } };

        try {
            const response = await axios.post(process.env.REACT_APP_BASE_URL + MASTER_API.USER_MASTER_LIST, data, config);
            setIsLoading(false);
            if (response && response?.data?.body?.status === COMMONVARIABLES.STATUS.INVALIDUSER) {
                toast.error(response.data.body.message, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
                UserLogout();
            }

            if (response?.data?.body?.status === COMMONVARIABLES.STATUS.UNAUTHORIZED) {
                const refresh = await getRefreshToken();

                if (refresh) {
                    if (Number(refresh) === COMMONVARIABLES.STATUS.EXPIRED_TOKEN) {
                        UserLogout();
                    } else {
                        await udfnUserList();
                    }
                }
            }

            if (response?.data?.body?.UserList) {
                setUserList(response.data.body.UserList);
                var userListCount = response.data.body.UserList.filter(item => item.um_status_code === 1).length;
                setUserTotalCount(userListCount);
                toast.success(response.data.body.message, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
                setIsLoading(false);
            } else {
                toast.error(response.data.body.message, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
            }
        } catch (error) {
            // Handle error
            setIsLoading(false);
            console.error("branch list API Error:", error);
        }

    };

    const udfnUserCreate = async () => {
        setIsLoading(true);
        const user = getLoggedInUser();
        var uploadData = {};
        var apiUrl = '';
        if (editFlag === true) {
            if (userRole.value != 3) {
                uploadData = {
                    user_id: user.id,
                    company_code: user.company_code,
                    user_code: Number(user_code),
                    user_name: String(userName.trim()),
                    userid: String(userId.trim()),
                    role: Number(userRole.value),
                    branch_code: 0,
                    status_code: Number(statusCode)
                };
            }
            else {
                uploadData = {
                    user_id: user.id,
                    company_code: user.company_code,
                    user_code: Number(user_code),
                    user_name: String(userName.trim()),
                    userid: String(userId.trim()),
                    role: Number(userRole.value),
                    branch_code: Number(branchName.value),
                    status_code: Number(statusCode)
                };
            }
            apiUrl = MASTER_API.USER_MASTER_UPDATE;
        } else if (passwordCard === true) {
            uploadData = {
                user_id: user.id,
                company_code: user.company_code,
                user_code: Number(user_code),
                password: String(newPassword.trim()),
            };
            apiUrl = MASTER_API.USER_MASTER_PASSWORD_UPDATE;
        }
        else {
            uploadData = {
                user_id: user.id,
                company_code: user.company_code,
                user_name: String(userName.trim()),
                userid: String(userId.trim()),
                password: String(password.trim()),
                role: Number(userRole.value),
                branch_code: Number(branchName.value),
                status_code: Number(statusCode)
            };
            apiUrl = MASTER_API.USER_MASTER_CREATE;
        }
        const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } };

        try {
            const response = await axios.post(process.env.REACT_APP_BASE_URL + apiUrl, uploadData, config);
            setIsLoading(false);
            if (response && response?.data?.body?.status === COMMONVARIABLES.STATUS.INVALIDUSER) {
                toast.error(response.data.body.message, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
                UserLogout();
            }

            if (response?.data?.body?.status === COMMONVARIABLES.STATUS.UNAUTHORIZED) {
                const refresh = await getRefreshToken();
                if (refresh) {
                    if (Number(refresh) === COMMONVARIABLES.STATUS.EXPIRED_TOKEN) {
                        UserLogout();
                    } else {
                        await udfnUserCreate();
                    }
                }
            }

            if (response && response?.data?.body?.status === COMMONVARIABLES.STATUS.EXISTS) {
                setButtonFlag(true);
                toast.error(response.data.body.message, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
            }

            if (response && response?.data?.body?.status === COMMONVARIABLES.STATUS.ALREADY_IN_USE) {
                toast.error(response.data.body.message, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
            }

            if (response?.data?.body?.status === COMMONVARIABLES.STATUS.SUCCESS) {
                toast.success(response.data.body.message, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
                setButtonFlag(true);
                udfnClearForm();
                if (editFlag === true) {
                    setAddCard(false);
                } else if (passwordCard === true) {
                    udfnClearUpdatePasswordForm();
                }
                else {
                    setAddCard(true);
                }
                await udfnUserList();
            } else {
                toast.error(response.data.body.message, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
                setAddCard(true);
                setButtonFlag(true);
            }
        } catch (error) {
            // Handle error
            setIsLoading(false);
            console.error("branch create API Error:", error);
        }
    };

    const udfnClearForm = () => {
        setUserName('')
        setUserId('')
        setPassword('')
        setUserRole({ label: 'Select Role', value: 0 })
        setBranchName({ label: 'Select Branch', value: 0 })
        setStatusCode(1);
        setAddCard(false);
        setEditFlag(false);
    };

    const udfnClearUpdatePasswordForm = () => {
        setNewPassword('');
        setConfirmPassword('');
        setShowNewPassword(false);
        setShowConfirmPassword(false);
        setPasswordCard(false);
    };

    const udfnEditLoad = async (row) => {
        setUserName(row.um_username)
        setUserId(row.um_user_id)
        setPassword(row.um_password)
        setUserRole({
            label: row.ur_user_role_name,
            value: row.ur_user_role_id,
        })
        if (row.bm_branch_name != '' && row.bm_branch_code != '') {
            setBranchName({
                label: row.bm_branch_name,
                value: row.bm_branch_code,
            })
        }
        setStatus(row.um_status_code)
        setStatusCode(row.um_status_code)
        setUserCode(row.um_user_code)
        setEditFlag(true);
        udfnGetUserRole();
    };

    const udfnEditPassword = async (row) => {
        setUserCode(row.um_user_code)
        setPasswordCard(true);
    };

    const udfnDelete = async (user_code) => {
        swal({
            title: "Are you sure?",
            text:
                "Once deleted, you will not be able to recover this user!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                udfnUserDelete(user_code);
            } else {
                swal("Your user is safe!");
            }
        })
    }

    const udfnUserDelete = async (user_code) => {
        setIsLoading(true);
        const user = getLoggedInUser();
        const data = {
            user_id: user.id,
            company_code: user.company_code,
            user_code: Number(user_code)

        };
        const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } };

        try {
            const response = await axios.post(process.env.REACT_APP_BASE_URL + MASTER_API.USER_MASTER_DELETE, data, config);
            setIsLoading(false);
            if (response && response?.data?.body?.status === COMMONVARIABLES.STATUS.INVALIDUSER) {
                toast.error(response.data.body.message, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
                UserLogout();
            }

            if (response?.data?.body?.status === COMMONVARIABLES.STATUS.UNAUTHORIZED) {
                const refresh = await getRefreshToken();

                if (refresh) {
                    if (Number(refresh) === COMMONVARIABLES.STATUS.EXPIRED_TOKEN) {
                        UserLogout();
                    } else {
                        await udfnUserDelete();
                    }
                }
            }

            if (response && response?.data?.body?.status === COMMONVARIABLES.STATUS.EXISTS) {
                toast.error(response.data.body.message, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
                setAddCard(true);
            }

            if (response?.data?.body?.status === COMMONVARIABLES.STATUS.SUCCESS) {
                swal(response.data.body.message, {
                    icon: "success",
                });
                await udfnUserList();
            } else {
                toast.error(response.data.body.message, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
            }
        } catch (error) {
            // Handle error
            setIsLoading(false);
            console.error("branch list API Error:", error);
        }
    };

    const udfnGetUserRole = async () => {
        setAddCard(true);
        setIsLoading(true);
        const user = getLoggedInUser();
        const data = {
            user_id: user.id,
            status_code: Number(1)
        };
        const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } };
        try {
            const response = await axios.post(process.env.REACT_APP_BASE_URL + COMMON_API.USER_ROLE_LIST, data, config);
            setIsLoading(false);
            if (response && response?.data?.body?.status === COMMONVARIABLES.STATUS.INVALIDUSER) {
                toast.error(response.data.body.message, { position: toast.POSITION.TOP_RIGHT });
                UserLogout();
            }

            if (response?.data?.body?.status === COMMONVARIABLES.STATUS.UNAUTHORIZED) {
                const refresh = await getRefreshToken();

                if (refresh) {
                    if (Number(refresh) === COMMONVARIABLES.STATUS.EXPIRED_TOKEN) {
                        UserLogout();
                    } else {
                        await udfnGetUserRole();
                    }
                }
            }
            setOptions(response?.data?.body?.UserRoleList)
        }
        catch (error) {
            // Handle error
            console.error("get branch list API Error:", error);
        }
    };

    const udfnUpdatePassword = async (event) => {
        event.preventDefault();

        var commomError = false;
        var passwordValid = false;
        var passwordError = false;
        var errorMsg = '';

        // Validate All Inputs
        if (!newPassword.trim() || !confirmPassword.trim()) {
            commomError = true;
            errorMsg = ERROR_MESSAGE.COMMON_REQUIRED;
        } else {

            //password Validation
            if (newPassword === confirmPassword) {
                passwordValid = FormValidation('password', newPassword);
                if (newPassword.length < 6) {
                    passwordError = true;
                    errorMsg = ERROR_MESSAGE.USER_MASTER.PASSWORD_VALID;
                }
                else if (!passwordValid) {
                    passwordError = true;
                    errorMsg = ERROR_MESSAGE.USER_MASTER.PASSWORD_VALID;
                }
            }
            else {
                passwordError = true;
                errorMsg = ERROR_MESSAGE.USER_MASTER.NEW_PASSWORD_VALID;
            }
        }

        if (!commomError && !passwordError) {
            setButtonFlag(false);
            udfnUserCreate();
        } else {
            toast.error(errorMsg, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    };

    const udfnGetBranchList = async () => {
        const user = getLoggedInUser();
        const data = {
            user_id: user.id,
            status_code: Number(1)
        };
        const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } };
        try {
            const response = await axios.post(process.env.REACT_APP_BASE_URL + COMMON_API.BRANCH_MASTER_LIST, data, config);
            setIsLoading(false);
            if (response && response?.data?.body?.status === COMMONVARIABLES.STATUS.INVALIDUSER) {
                toast.error(response.data.body.message, { position: toast.POSITION.TOP_RIGHT });
                UserLogout();
            }

            if (response?.data?.body?.status === COMMONVARIABLES.STATUS.UNAUTHORIZED) {
                const refresh = await getRefreshToken();

                if (refresh) {
                    if (Number(refresh) === COMMONVARIABLES.STATUS.EXPIRED_TOKEN) {
                        UserLogout();
                    } else {
                        await udfnGetBranchList();
                    }
                }
            }
            setBranchOptions(response?.data?.body?.branchList)
        }
        catch (error) {
            // Handle error
            console.error("get branch list API Error:", error);
        }
    };

    const buttonRef = useRef(null);

    const handleKeyDown = (event) => {
        // Check if the pressed key is the Tab key (key code 9)
        if (event.keyCode === 9) {
            // Prevent the default Tab behavior to prevent losing focus from the text input
            event.preventDefault();

            // Focus the button element
            buttonRef.current.focus();
        }
    };

    const openCard = () => {
        udfnClearForm()
        setAddCard(true)
        udfnGetUserRole()
    }

    useEffect(() => {
        udfnUserList();
        udfnGetBranchList()
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <div className="col-12">
                <ToastContainer />
                <Modal className="modal fade" show={passwordCard} onHide={setPasswordCard} backdrop="static">
                    <div className="" role="document">
                        <div className="">
                            <form >
                                <div className="modal-header">
                                    <h4 className="modal-title fs-20">Update Password</h4>
                                    <button type="button" className="close btn" onClick={() => udfnClearUpdatePasswordForm()}><span>×</span></button>
                                </div>
                                <div className="modal-body">
                                    <i className="flaticon-cancel-12 close" data-dismiss="modal"></i>
                                    <div className="add-contact-box">
                                        <div className="add-contact-content">
                                            <div className="form-group mb-3">
                                                <label className="text-black font-w500">New Password<span className="text-danger">*</span></label>
                                                <div className="input-group mb-3">
                                                    <input type={showNewPassword ? "text" : "password"} className="form-control" autoComplete="off"
                                                        value={newPassword} onChange={e => setNewPassword(e.target.value)} required="required"
                                                        placeholder="New Password" maxLength="10" autoFocus
                                                    />
                                                    <span className="input-group-text udf-pass-icon" onClick={() => setShowNewPassword(!showNewPassword)}>
                                                        {showNewPassword === false ? (<i className="fa fa-eye-slash" />) : (<i className="fa fa-eye" />)}
                                                    </span>
                                                </div>
                                            </div>
                                            <span className="validation-text"></span>
                                            <div className="form-group mb-3">
                                                <label className="text-black font-w500">Confirm Password <span className="text-danger">*</span></label>
                                                <div className="input-group mb-3">
                                                    <input type={showConfirmPassword ? "text" : "password"} className="form-control" autoComplete="off"
                                                        value={confirmPassword} onChange={e => setConfirmPassword(e.target.value)} required="required"
                                                        placeholder="Confirm Password" maxLength="10"
                                                    />
                                                    <span className="input-group-text udf-pass-icon" onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
                                                        {showConfirmPassword === false ? (<i className="fa fa-eye-slash" />) : (<i className="fa fa-eye" />)}
                                                    </span>
                                                </div>
                                            </div>
                                            <span className="validation-text"></span>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" onClick={() => udfnClearUpdatePasswordForm()} className="btn btn-danger" > <i className="flaticon-delete-1"></i> Discard</button>
                                    <Button className="me-2" variant="whatsapp" onClick={udfnUpdatePassword}>
                                        update
                                    </Button>
                                </div>
                            </form>
                        </div>
                    </div >
                </Modal >
                <Modal className="modal fade" show={addCard} onHide={setAddCard} backdrop="static">
                    <div className="" role="document">
                        <div className="">
                            <form >
                                <div className="modal-header">
                                    <h4 className="modal-title fs-20">{editFlag === true ? 'Update User' : 'Add User'}</h4>
                                    <button type="button" className="close btn" onClick={() => udfnClearForm()}><span>×</span></button>
                                </div>
                                <div className="modal-body">
                                    <i className="flaticon-cancel-12 close" data-dismiss="modal"></i>
                                    <div className="add-contact-box">
                                        <div className="add-contact-content">
                                            <div className="form-group mb-3">
                                                <label className="text-black font-w500">User Id <span className="text-danger">*</span></label>
                                                <div className="contact-name">
                                                    <input type="text" className="form-control" autoComplete="off"
                                                        value={userId} onChange={e => setUserId(e.target.value)} required="required"
                                                        placeholder="User Id" minLength="4" maxLength="10" autoFocus
                                                    />
                                                    <span className="validation-text"></span>
                                                </div>
                                            </div>
                                            <span className="validation-text"></span>
                                            <div className="form-group mb-3">
                                                <label className="text-black font-w500">User Name <span className="text-danger">*</span></label>
                                                <div className="contact-name">
                                                    <input type="text" className="form-control" autoComplete="off"
                                                        value={userName} onChange={e => setUserName(e.target.value)} required="required"
                                                        onKeyUp={(e) => udfnNameValidation(e)}
                                                        placeholder="User Name" maxLength="30"
                                                    />
                                                    <span className="validation-text"></span>
                                                </div>
                                            </div>
                                            <span className="validation-text"></span>
                                            {editFlag === false &&
                                                <div className="form-group mb-3"
                                                >
                                                    <label className="text-black font-w500">
                                                        <strong>Password</strong>
                                                        <span className="text-danger star-txt">*</span>
                                                    </label>
                                                    <div className="input-group mb-3">
                                                        <input
                                                            type={showPassword ? "text" : "password"}
                                                            className="form-control"
                                                            maxLength="10"
                                                            value={password}
                                                            onChange={(e) =>
                                                                setPassword(e.target.value)
                                                            }
                                                        />
                                                        <span className="input-group-text udf-pass-icon" onClick={() => setShowPassword(!showPassword)}>
                                                            {showPassword === false ? (<i className="fa fa-eye-slash" />) : (<i className="fa fa-eye" />)}
                                                        </span>
                                                    </div>
                                                </div>}
                                            <div className="form-group mb-3">
                                                <label className="text-black font-w500">User Role <span className="text-danger">*</span></label>
                                                <div className="contact-name">
                                                    <Select
                                                        defaultValue={userRole}
                                                        onChange={setUserRole}
                                                        options={options.map((option) => ({
                                                            label: option.ur_user_role_name,
                                                            value: option.ur_user_role_id,
                                                        }))}
                                                        value={userRole}
                                                        showContent="true"
                                                        open="true"
                                                        className="my-react-select-container"
                                                        theme={theme => ({
                                                            ...theme,
                                                            borderRadius: '6px',
                                                            colors: {
                                                                ...theme.colors,
                                                                primary25: '#b3b3b3',
                                                                primary: 'black',
                                                                neutral80: "data-theme-version" === "dark" ? 'white' : ''
                                                            },
                                                        })}
                                                    />

                                                    <span className="validation-text"></span>
                                                </div>
                                            </div>
                                            {userRole.value === 3 &&
                                                <div className="form-group mb-3">
                                                    <label className="text-black font-w500">Branch Name <span className="text-danger">*</span></label>
                                                    <Select
                                                        defaultValue={branchName}
                                                        onChange={setBranchName}
                                                        options={branchOptions.map((option) => ({
                                                            label: option.bm_branch_name,
                                                            value: option.bm_branch_code,
                                                        }))}
                                                        value={branchName}
                                                        onKeyDown={handleKeyDown}
                                                        showContent="true"
                                                        open="true"
                                                        className="my-react-select-container"
                                                        theme={theme => ({
                                                            ...theme,
                                                            borderRadius: '6px',
                                                            colors: {
                                                                ...theme.colors,
                                                                primary25: '#b3b3b3',
                                                                primary: 'black',
                                                                neutral80: "#b3b3b3",
                                                            },
                                                        })}
                                                    />
                                                </div>}
                                            {editFlag === true ?
                                                <fieldset className="form-group mb-3">
                                                    <div className="row">
                                                        <label className="col-form-label col-sm-3 pt-1 text-black font-w500">
                                                            Status
                                                        </label>
                                                        <div className="col-sm-2">
                                                            <div className="form-check checkbox-success">
                                                                {status === 1 ? <input
                                                                    className="form-check-input"
                                                                    type="radio"
                                                                    name="gridRadios"
                                                                    onClick={() => setStatusCode(1)}
                                                                    onKeyDown={handleKeyDown}
                                                                    defaultChecked
                                                                /> :
                                                                    <input
                                                                        className="form-check-input"
                                                                        type="radio"
                                                                        name="gridRadios"
                                                                        onClick={() => setStatusCode(1)}
                                                                        onKeyDown={handleKeyDown}
                                                                    />}
                                                                <label className="form-check-label">
                                                                    Active
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-5">
                                                            <div className="form-check checkbox-danger">
                                                                {status === 2 ? <input
                                                                    className="form-check-input"
                                                                    type="radio"
                                                                    name="gridRadios"
                                                                    onClick={() => setStatusCode(2)}
                                                                    onKeyDown={handleKeyDown}
                                                                    defaultChecked
                                                                /> :
                                                                    <input
                                                                        className="form-check-input"
                                                                        type="radio"
                                                                        name="gridRadios"
                                                                        onClick={() => setStatusCode(2)}
                                                                        onKeyDown={handleKeyDown}
                                                                    />}
                                                                <label className="form-check-label">
                                                                    Inactive
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </fieldset> : ''}
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" onClick={() => udfnClearForm()} className="btn btn-danger" > <i className="flaticon-delete-1"></i> Discard</button>
                                    <Button className="me-2" variant="whatsapp" ref={buttonRef} onClick={udfnFromValidate} disabled={buttonFlag === false}>
                                        {editFlag === true ? 'Update' : 'Save'}
                                    </Button>
                                </div>
                            </form>
                        </div>
                    </div >
                </Modal >
                <LoadingOverlay
                    active={isLoading}
                    spinner
                    text='Loading ...'
                >
                    <div className="card">
                        <div className="card-header mb-sm-12 mb-3 d-flex align-items-center flex-wrap">
                            <div className='col-sm-5'>
                                <h2 className="card-title">User List</h2>
                            </div>
                            <div className='col-sm-3'>
                                <p className="bgl-primary rounded p-3 text-center mb-2"><span className="text-black me-1" >Total Active Users  :  </span><Badge bg='info badge-rounded' className='text-black font-w400 fs-18'>{userTotalCount}</Badge></p>
                            </div>
                            <div className='col-sm-2 me-1'>
                                <Link className="btn btn-primary mb-2" onClick={() => openCard()}>
                                    + Add User
                                </Link>
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="table-responsive">
                                <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
                                <table {...getTableProps()} className="table filtering-table table-responsive-lg">
                                    <thead>
                                        {headerGroups.map(headerGroup => (
                                            <tr {...headerGroup.getHeaderGroupProps()}>
                                                {headerGroup.headers.map((column, index) => (
                                                    <th {...column.getHeaderProps()} key={index}>
                                                        {column.render('Header')}
                                                        {column.canFilter ? column.render('Filter') : null}
                                                    </th>
                                                ))}
                                                <th>Actions</th>
                                            </tr>
                                        ))}
                                    </thead>
                                    <tbody {...getTableBodyProps()} className="" >

                                        {page.map((row, index) => {
                                            prepareRow(row)
                                            return (
                                                <tr key={index}>
                                                    <td>{index + 1}</td>
                                                    <td>{row.original.um_user_id}</td>
                                                    <td>{row.original.um_username}</td>
                                                    <td>{row.original.ur_user_role_name}</td>
                                                    <td>{row.original.bm_branch_name}</td>
                                                    <td>
                                                        {row.original.um_status_code === 1 ? <Badge bg="" className='badge-success light'>
                                                            <span className="me-2">
                                                                <i className="ms-1 fa fa-check" />
                                                            </span>
                                                            {row.original.status}
                                                        </Badge> : <Badge bg="" className='badge-primary light'>
                                                            <span className="me-2">
                                                                <i className="fa fa-times"></i>
                                                            </span>
                                                            {row.original.status}
                                                        </Badge>
                                                        }
                                                    </td>
                                                    <td>
                                                        <Link className="btn btn-info	 shadow btn-xs sharp me-2" onClick={() => udfnEditPassword(row.original)}>
                                                            <i className="fa fa-key" id={'editpasswordtooltip' + index}></i>
                                                            <UncontrolledTooltip placement="top" target={'editpasswordtooltip' + index}>
                                                                Edit Password
                                                            </UncontrolledTooltip>
                                                        </Link>
                                                        <Link className="btn btn-secondary	 shadow btn-xs sharp me-2"
                                                            onClick={() => udfnEditLoad(row.original)}>
                                                            <i className="fa fa-pencil" id={'edittooltip' + index}></i>
                                                            <UncontrolledTooltip placement="top" target={'edittooltip' + index}>
                                                                Edit
                                                            </UncontrolledTooltip>
                                                        </Link>
                                                        {user.id !== row.original.um_user_code && <Link className="btn btn-danger shadow btn-xs sharp"
                                                            onClick={() => udfnDelete(row.original.um_user_code)}>
                                                            <i className="fa fa-trash" id={'deletetooltip' + index}></i>
                                                            <UncontrolledTooltip placement="top" target={'deletetooltip' + index}>
                                                                Delete
                                                            </UncontrolledTooltip>
                                                        </Link>}
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                                <div className="d-flex justify-content-between">
                                    <span>
                                        Page{' '}
                                        <strong>
                                            {pageIndex + 1} of {pageOptions.length}
                                        </strong>{''}
                                    </span>
                                    <span className="table-index">
                                        Go to page : {' '}
                                        <input type="number"
                                            className="ms-2"
                                            defaultValue={pageIndex + 1}
                                            onChange={e => {
                                                const pageNumber = e.target.value ? Number(e.target.value) - 1 : 0
                                                gotoPage(pageNumber)
                                            }}
                                        />
                                    </span>
                                </div>
                                <div className="text-center">
                                    <div className="filter-pagination  mt-3">
                                        <button className=" previous-button" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>{'<<'}</button>

                                        <button className="previous-button" onClick={() => previousPage()} disabled={!canPreviousPage}>
                                            Previous
                                        </button>
                                        <button className="next-button" onClick={() => nextPage()} disabled={!canNextPage}>
                                            Next
                                        </button>
                                        <button className=" next-button" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>{'>>'}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </LoadingOverlay>
            </div >
        </>
    )
}
export default UserMaster;