import { format } from 'date-fns';
import { ColumnFilter } from '../Common/ColumnFilter';
export const COLUMNS = [
    {
        Header: 'S.No.',
        Footer: 'Unit Code',
        accessor: 'id',
        Filter: ColumnFilter,
        disableFilters: true,
    },
    {
        Header: 'Unit Name',
        Footer: 'Unit Name',
        accessor: 'um_unit_name',
        Filter: ColumnFilter,
        disableFilters: true,
    },
    {
        Header: 'No.Of.Digits',
        Footer: 'No.Of.Digits',
        accessor: 'um_no_of_decimals',
        Filter: ColumnFilter,
        disableFilters: true,
    },
    {
        Header: 'Display Name',
        Footer: 'Display Name',
        accessor: 'um_display_name',
        Filter: ColumnFilter,
        disableFilters: true,
    },
    {
        Header: 'Status',
        Footer: 'Unit Status',
        accessor: 'status',
        Filter: ColumnFilter,
        disableFilters: true,
    }
]

export const GROUPED_COLUMNS = [
]