import { ColumnFilter } from '../Common/ColumnFilter';
export const COLUMNS = [
	// {
	// 	Header: 'S.No.',
	// 	Footer: 'S.No.',
	// 	accessor: 'id',
	// 	Filter: ColumnFilter,
	// 	// disableFilters: true,
	// },
	{
		Header: 'Salesman Name',
		Footer: 'Salesman Name',
		accessor: 'sm_sales_man_name',
		Filter: ColumnFilter,
		// disableFilters: true,
	},
	{
		Header: 'Salesman Code',
		Footer: 'Salesman Code',
		accessor: 'sm_sales_man_mannual_code',
		Filter: ColumnFilter,
		// disableFilters: true,
	},
	{
		Header: 'Salesman Branch',
		Footer: 'Salesman Branch',
		accessor: 'branch_name',
		Filter: ColumnFilter,
		// disableFilters: true,
	},
	{
		Header: 'Short Name',
		Footer: 'Short Name',
		accessor: 'sm_short_name',
		Filter: ColumnFilter,
		// disableFilters: true,
	},
	{
		Header: 'Designation',
		Footer: 'Designation',
		accessor: 'sm_designation',
		Filter: ColumnFilter,
		// disableFilters: true,
	},
	{
		Header: 'Section',
		Footer: 'Section',
		accessor: 'sm_section',
		Filter: ColumnFilter,
		// disableFilters: true,
	},
	{
		Header: 'Floor',
		Footer: 'Floor',
		accessor: 'sm_floor',
		Filter: ColumnFilter,
		// disableFilters: true,
	},
	{
		Header: 'Status',
		Footer: 'Status',
		accessor: 'status',
		Filter: ColumnFilter,
		// disableFilters: true,
	},
]

export const GROUPED_COLUMNS = [
]
