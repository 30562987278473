import { format } from 'date-fns';
import { ColumnFilter } from '../Common/ColumnFilter';
export const COLUMNS = [
    {
        Header: 'S.No.',
        Footer: 'Item Group Code',
        accessor: 'id',
        Filter: ColumnFilter,
        disableFilters: true,
    },
    {
        Header: 'Item Group Name',
        Footer: 'Item Group Name',
        accessor: 'gm_group_name',
        Filter: ColumnFilter,
        disableFilters: true,
    },
    {
        Header: 'No.Of.Categories',
        Footer: 'Category Count',
        accessor: 'category_count',
        Filter: ColumnFilter,
        disableFilters: true,
    },
    {
        Header: 'Status',
        Footer: 'Item Group Status',
        accessor: 'status',
        Filter: ColumnFilter,
        disableFilters: true,
    },
]

export const GROUPED_COLUMNS = [
]