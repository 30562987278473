import React, { useEffect, useRef, useState } from 'react';
import { Link, useLocation } from "react-router-dom";
import { Button } from 'react-bootstrap';
import '../../Filters/Common/filtering.css';
import Select from "react-select";
import { useNavigate } from 'react-router-dom';
import "react-datepicker/dist/react-datepicker.css";
import ERROR from "../../../components/Validation/ErrorMessage"
import { ToastContainer, toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import COMMON_API from '../../../Api/CommonApi';
import axios from "axios";
import { getLoggedInUser, getRefreshToken } from '../../../../services/AuthService';
import { Logout } from '../../../../store/actions/AuthActions';
import COMMONVARIABLES from '../../Utilities/constants';
import STOCK_ADJUSTMENT_API from '../../../Api/StockAdjustmentApi';
import swal from "sweetalert";
import QRCode from "react-qr-code";
import LoadingOverlay from 'react-loading-overlay-ts';
import { useReactToPrint } from 'react-to-print';
import Logo from '../../../../pictures/bw1.png';
export const StockAdjustmentAdd = () => {
    const paramData = useLocation();
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    let navigate = useNavigate();
    const [transNo, setTransNo] = useState(0);
    const [transferNo, setTransferNo] = useState('');
    const [transferDate, setTransferDate] = useState("");
    const [adjustmentBranch, setAdjustmentBranch] = useState('');
    const [adjustmentType, setAdjustmentType] = useState('');

    const [itemCode, setItemCode] = useState('');
    const [itemName, setItemName] = useState('');
    const [itemQty, setItemQty] = useState('');
    const [narration, setNarration] = useState('');

    const [adjustmentBranchOptions, setAdjustmentBranchOptions] = useState([])
    const [adjustmentTypeOptions, setAdjustmentTypeOptions] = useState([])
    const [itemDetails, setItemDetails] = useState([]);
    const [isEdit, setIsEdit] = useState(0)
    const [currentSno, setCurrentSno] = useState(0)
    const [isLoading, setIsLoading] = useState(false);
    const [fromBranchDisabled, setFromBranchDisabled] = useState(false);
    const [toBranchDisabled, setToBranchDisabled] = useState(false);
    const [title, setTitle] = useState('Add Stock Adjustment')
    const [btnTitle, setBtnTitle] = useState('Save')
    const [buttonFlag, setButtonFlag] = useState(false);
    const [noOfPrints, setNoOfPrints] = useState('');
    const buttonRef = useRef(null);
    const [labelTypeName, setLabelTypeName] = useState({ label: '35*22', value: 1 })
    const labelTypeNameOptions = COMMONVARIABLES.LABLEPRINTTYPE;
    const [qrCodeItemDetails, setQRCodeItemDetails] = useState([]);
    const [printStatus, setPrintStatus] = useState(false)
    const [currentStock, setCurrentStock] = useState(-1)
    // const [printLabelDetails, setCurrentStock] = useState(-1)
    const myid = "myqr"
    const myqrid = "myqrid"
    useEffect(() => {
        setAdjustmentTypeOptions([{
            label: 'Add', value: 1
        }, {
            label: 'Sub', value: 2
        }])
        fetchMasterDataWithParams()
    }, [])
    useEffect(() => {
        clearAddData()
    }, [adjustmentBranch])
    const qtyValidation = (event) => {
        const regex = /^[0-9]*$/;
        const input = event.target.value + event.key;
        if (!regex.test(input) || input.length > 4) {
            event.preventDefault();
        }
    };
    const handleKeyDown = (event) => {
        // Check if the pressed key is the Tab key (key code 9)
        if (event.keyCode === 9) {
            // Prevent the default Tab behavior to prevent losing focus from the text input
            event.preventDefault();

            // Focus the button element
            buttonRef.current.focus();
        }
    };
    const onClickSaveBtn = async (event) => {

        udfnSaveStockAdjustment(event)


    }
    function printQRCode(qrcodeList) {
        const printHtml = window.open('', 'PRINT', 'height=auto,width=100%')
        let html = ''
        let itemDetails = ''
        const user = getLoggedInUser()
       
            for (let i = 0; i < qrcodeList.length; i++) {
                for (let j = 0; j < Number(qrcodeList[i].printcount); j++) {
                const myids = "myqr"
                const getid = myids + qrcodeList[i].qrcode
                const innerContents = document.getElementById(getid).innerHTML
                itemDetails += `
					<div class="float-child col-4  ml-5 mt-3">				
						<div class="align-center">
							<div class="heading">${user.label_title}</div>
						</div>

						<div class="float-container botton-align ">`
                if (qrcodeList[i].qs_label_details) {
                    itemDetails += `<div class="qr_div">
													<div class="rotated"> ${qrcodeList[i].qs_label_details}</div>
												</div>`
                }
                itemDetails += `<div class="float-qrcode ml-2">
												<div class="qr_div ">
													<div> ${innerContents}</div>
												
												</div>
												<div class="qr_div bold-design size-10 align-center">${qrcodeList[i].qrcode}</div>
											</div>
											<div class="float-details ml-2">
												<div class="bold-design size-9 wor-wrap">${qrcodeList[i].itemName}</div>
												<div class="bold-design size-15 m-10">₹${qrcodeList[i].selling_rate}</div>
											</div>
						</div>			
					</div>`
            }
        }

        html += `<html>
					<head>
						<title>StockAdjustment Print</title>
						<style>
						.rotated {
							writing-mode: tb-rl;
							transform: rotate(-180deg);
							font-size: 10px;
						}
				
						.qr_div {
							display: flex;
							align-items: center;
							justify-content: center;
						}
				
						body {
							font-family: Arial, Helvetica, sans-serif;
							width: 416px !important; 
						}
				
						.row {
							width: 100%;
							/* margin-top: 10px; */
						}
				
						.column {
							float: left;
						}
				
						@media all {
							.page-break {
								display: none
							}
						}
						@media print {
				
							body {
							margin-top: 1 !important;
							color: #000;
							}
				
						}
						.heading {
							font-size: 13px !important;
							font-weight: bold;
							text-align: center
						}
				
						.align-center {
							flex-direction: column;
							text-align: left;
						}
				
						.align-left {
							text-align: left;
						}
				
						.align-right {
							text-align: right;
							font-size: large;
						}
				
						.paddding-space {
							/* padding-bottom: 15px */
						}
				
						.center {
							text-align: center;
						}
				
						.padding {
							padding-left: 10px
						}
				
						.qrcode {
							padding-left: 50px
						}
				
						.botton-align {
							/* padding-top: 10px */
						}
				
						.botton {
							padding-top: 10px
						}
				
						.size-10 {
							font-size: 10px !important;
						}
				
						.size-11 {
							font-size: 11px !important;
						}

                        .size-9 {
							font-size: 9px !important;
						}
				
						.size-12 {
							font-size: 12px !important;
						}
				
						.size-15 {
							font-size: 15px !important;
						}
				
						.size-small {
							font-size: 10px !important;
							text-align: center !important;
							font-weight: bold;
						}
						.bold-design {
							font-weight: bold;
						}
				
						.float-child {
							display: flex;
							flex-direction: column;
						}
				
						.float-container {
							width: 100%;
							display: flex;
							flex-direction: row;
						}
				
						.float-details {
							width: 65%;
							display: flex;
							flex-direction: column;
							padding-left: 2px;
							text-align: left;
						}
				
						.float-qrcode {
							width: 35%;
							display: flex;
							flex-direction: column;
						}
				
						.marleft {
							padding-left: 20px;
						}
				
						.marleft-10 {
							padding-left: 10px;
						}
				
						.marright {
							padding-right: 20px;
						}
				
						.col-4 {
							flex: 0 0 auto;
							width: 133px !important;
							height: 83px;
						}
				
						.parent {
							display: flex;
							flex-wrap: wrap;
							flex-direction: row;
						}
				
						.mb-15 {
							margin-bottom: 15px;
						}
				
						.mt-5 {
							margin-bottom: 5px;
						}
						.ml-5 {
							margin-left: 5px;
						}
						.ml-10{
							margin-left: 10px;
						}
						.mt-5{
							margin-top: 5px;
						}
						.ml-2 {
							margin-left: 2px;
						}
						.mt-3 {
							margin-top: 3px;
						}
						.mt-2 {
							margin-top: 2px;
						}
						.wor-wrap {
                            text-wrap: wrap;
                            width: 50px;
                            overflow-wrap: break-word;
                            height: 30px;
                            overflow: hidden;
                        }
                        .mar-top{
                            margin-top: -5px;
                        }
                        .m-10{
                            margin-top: 10px
                        }
						</style>
					</head>
					<body onload="window.print();window.close()">
						<div class="row  ml-10">
							<div class='paddding-space'>
								<div class="float-container parent">
									${itemDetails}					
								</div>
							</div>				
						</div>		
					</body>
                </html>`
        console.log(html, "html")
        printHtml.document.write(html)
        printHtml.document.close()
        printHtml.print()
        printHtml.close()
        navigate("/stock-adjustment-list");

        return true
    }

    function printSingleQRCode(qrcodeList) {
        const printHtml = window.open('', 'PRINT', 'height=auto,width=100%')
        let html = ''
        let itemDetails = ''
        const user = getLoggedInUser()
      
            for (let i = 0; i < qrcodeList.length; i++) {
                for (let j = 0; j < Number( qrcodeList[i].printcount); j++) {
                const myids = "myqrid"
                const getid = myids + qrcodeList[i].qrcode
                const innerContents = document.getElementById(getid).innerHTML

                itemDetails += `<div class="float-child">
			<div class="float-container align-center justfy-center">
				<div class="heading">${user.label_title}</div>
			</div>

			<div class="float-container botton-align ">`
                if (qrcodeList[i].qs_label_details) {
                    itemDetails += `<div class="qr_div marleft-10">
												<div class="rotated"> ${qrcodeList[i].qs_label_details}</div>
											</div>`
                }
                itemDetails += `
				<div class="float-qrcode marleft-10">
					<div class="qr_div ">
						<div class=""> ${innerContents}</div>

					</div>
					<div class="qr_div bold-design size-large align-center">${qrcodeList[i].qrcode}</div>
				</div>
				<div class="float-details marleft-10">
					<div class="bold-design size-large wor-wrap">${qrcodeList[i].itemName}</div>
					<div class="bold-design botton size-Xlarge">₹${COMMONVARIABLES.PDFIndia_Rupee_Formatter(qrcodeList[i].selling_rate)}</div>
				</div>
			</div>
		</div>`

            }
        }

        html += `<html>
					<head>
						<title>Purchase Print</title>
						<style>
						.rotated {
							writing-mode: tb-rl;
							transform: rotate(-180deg);
							font-size: 8px;
						}
				
						.qr_div {
							display: flex;
							align-items: center;
							justify-content: center;
						}
				
						body {
							font-family: Arial, Helvetica, sans-serif;
							width: 384px;
                            margin: 0px;
						}
				
						.row {
							width: 100%;
                            height: 56.69px;
                            display: block;
						}
				
						.column {
							float: left;
						}
				
						@media all {
							.page-break {
								display: none
							}
						}
				
						.heading {
							font-size: 12px !important;
							font-weight: bold;
							text-align: center;
						}
				
						.align-center {
							flex-direction: column;
							text-align: center;
						}
				
						.align-left {
							text-align: left;
						}
				
						.align-right {
							text-align: right;
							font-size: large;
						}

						.paddding-space {
							float:right;
						}
				
						.center {
							text-align: center;
						}
				
						.padding {
							padding-left: 10px
						}
				
						.qrcode {
							padding-left: 50px
						}
				
						.botton-align {

						}
				
						.botton {
							padding-top: 5px
						}
				
						.size-large {
							font-size: 8px !important;
						}
				
						.size-Xlarge {
							font-size: 13px !important;
						}
				
						.size-20 {
							font-size: 20px !important;
						}
				
						.size-small {
							font-size: 10px !important;
							text-align: center !important;
							font-weight: bold;
						}
				
						.color-style {
							padding-top: 20px;
							font-size: 20px !important;
							font-weight: bold;
						}
				
						.bold-design {
							font-weight: bold;
						}
				
						.float-child {
							width: 100%;
							display: flex;
							flex-direction: column;
                            padding: 1px;
						}
				
						.float-container {
							width: 100%;
							display: flex;
							flex-direction: row;
						}
				
						.float-details {
							width: 65%;
							display: flex;
							flex-direction: column;
							padding-left: 2px;
							text-align: left;
						}
				
						.float-qrcode {
							width: 35%;
							display: flex;
							flex-direction: column;
						}
				
						.marleft {
							padding-left: 20px;
						}
				
						.marleft-10 {
							padding-left: 5px;
						}
				
						.marright {
							padding-right: 20px;
						}
				
						.marright-10 {
							padding-right: 10px;
						}
				
						.col-4 {
							flex: 0 0 auto;
							width: 33.333%;
						}
				
						.parent {
							display: block;
							flex-wrap: wrap;
							flex-direction: row;
						}
				
						.mt-10 {
							margin-top: 10px;
						}
				
						.pb-15 {
							padding-bottom: 13px;
						}
				
						.mt-5 {
							margin-top: 5px;
						}
				
						.mb-5 {

						}
				
						.mb-2 {
							margin-bottom: 2px;
						}
				
						.justfy-center {
							justify-content: center;
						}
						.p-tb{
							padding: 5px 0px;
						}
						.ml-20{
							margin-left:20px;
						}
						.wor-wrap {
							text-wrap: wrap;
    						width: 10px;
						}
						</style>
					</head>
					<body onload="window.print();window.close()">
						<div class="row">
							<div class='paddding-space'>
								<div class="float-container parent">
									${itemDetails}					
								</div>
							</div>				
						</div>		
					</body>
                </html>`
        console.log(html, "html")
        printHtml.document.write(html)
        printHtml.document.close()
        printHtml.print()
        printHtml.close()
        navigate("/stock-adjustment-list");
        return true
    }

    useEffect(() => {
        if (printStatus && qrCodeItemDetails && qrCodeItemDetails.length > 0) {
            if (labelTypeName.value === 1) {
                printQRCode(qrCodeItemDetails);
            } else {
                printSingleQRCode(qrCodeItemDetails);
            }

        }
    }, [qrCodeItemDetails])
    const udfnSaveStockAdjustment = async (event) => {
        try {

            event.preventDefault();
            var error = "";
            var localFy = localStorage.getItem("fy")
            const user = getLoggedInUser()
            if (adjustmentBranch == '') {
                error = ERROR.COMMON_REQUIRED;
            } else if (itemDetails.length < 1) {
                error = ERROR.STOCK_TRANSFER_ADD.ITEM_DETAILS_VALID;
            } else {
                error = '';
            }
            if (error === '') {
                setButtonFlag(true)
                var stockAdjustmentParams = {
                    "branch_code": Number(adjustmentBranch?.value),
                    "user_id": user?.id,

                    "company_code": user?.company_code,
                    "fy": Number(localFy),
                    "stock_adjustment_item": itemDetails
                }


                const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } };
                const [saveData] = await Promise.all([
                    axios.post(BASE_URL + STOCK_ADJUSTMENT_API.MULTISAVE_STOCK_ADJUSTMENT, stockAdjustmentParams, config)

                ]);
                setButtonFlag(false)
                if (saveData?.data?.body?.status === COMMONVARIABLES.STATUS.UNAUTHORIZED) {

                    const refresh = await getRefreshToken();

                    if (refresh) {
                        if (Number(refresh) === COMMONVARIABLES.STATUS.EXPIRED_TOKEN) {
                            Logout();
                        } else {
                            await udfnSaveStockAdjustment();
                        }
                    }
                }

                if (saveData?.data?.body?.status === COMMONVARIABLES.STATUS.SERVICE_CATCH_STATUS) {
                    toast.error(ERROR.API_ERROR.SERVICE_CATCH_ERROR, { position: toast.POSITION.TOP_RIGHT });
                }

                if (saveData?.data?.body?.status === COMMONVARIABLES.STATUS.SUCCESS) {

                    swal({
                        title: "Success",
                        text:
                            "Stock adjustment saved successfully",
                        icon: "success",
                        //buttons: true,
                        dangerMode: true,
                    }).then((resp) => {
                        if (resp) {
                            if (saveData?.data?.body?.qrcodeList && saveData?.data?.body?.qrcodeList.length > 0) {
                                swal({
                                    text:
                                        "Are you sure you want print?",
                                    icon: "warning",
                                    buttons: true,
                                    dangerMode: true,
                                }).then((willDelete) => {
                                    if (willDelete) {
                                        setPrintStatus(true)
                                        var addItems = itemDetails.filter(e=>e.ad_type == 1)
                                        setQRCodeItemDetails(addItems)
                                       

                                    } else {
                                        navigate("/stock-adjustment-list");
                                    }
                                })
                            } else {
                                navigate("/stock-adjustment-list");
                            }

                        }
                    })


                }
            } else {
                toast.error(error, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        }
        catch (e) {
            setButtonFlag(true)
            if (e.code === COMMONVARIABLES.STATUS.NETWORK_ERROR_STATUS) {
                toast.error(ERROR.API_ERROR.NETWORK_ERROR, { position: toast.POSITION.TOP_RIGHT });
            }
            if (e.code === COMMONVARIABLES.STATUS.BAD_REQUEST_ERROR_STATUS) {
                toast.error(ERROR.API_ERROR.BAD_REQUEST_ERROR, { position: toast.POSITION.TOP_RIGHT });
            }
        }
    }

    async function fetchMasterDataWithParams() {
        try {
            setIsLoading(true)
            const user = getLoggedInUser()
            const branchParams = { status_code: 1, user_id: user?.id };
            const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } };
            const [branchData] = await Promise.all([
                axios.post(BASE_URL + COMMON_API.BRANCH_MASTER_LIST, branchParams, config),

            ]);
            setIsLoading(false)
            if (branchData?.data?.body?.status === COMMONVARIABLES.STATUS.UNAUTHORIZED) {


                const refresh = await getRefreshToken();

                if (refresh) {
                    if (Number(refresh) === COMMONVARIABLES.STATUS.EXPIRED_TOKEN) {
                        Logout();
                    } else {
                        await fetchMasterDataWithParams();
                    }
                }
            }

            if (branchData?.data?.body?.status === COMMONVARIABLES.STATUS.SERVICE_CATCH_STATUS) {
                toast.error(ERROR.API_ERROR.SERVICE_CATCH_ERROR, { position: toast.POSITION.TOP_RIGHT });
            }

            if (branchData?.data?.body?.branchList) {
                setAdjustmentBranchOptions(branchData?.data?.body?.branchList)

            }


        } catch (error) {
            console.error('Error fetching master data:', error);
            if (error.code === COMMONVARIABLES.STATUS.NETWORK_ERROR_STATUS) {
                toast.error(ERROR.API_ERROR.NETWORK_ERROR, { position: toast.POSITION.TOP_RIGHT });
            }
            if (error.code === COMMONVARIABLES.STATUS.BAD_REQUEST_ERROR_STATUS) {
                toast.error(ERROR.API_ERROR.BAD_REQUEST_ERROR, { position: toast.POSITION.TOP_RIGHT });
            }
        }
    }
    const onBlurQrCode = (qrCode) => {
        if (qrCode) {
            fetchItemListFromQrCode(qrCode)
        }

    }
    async function fetchItemListFromQrCode(qrCode) {
        try {
            const user = getLoggedInUser()

            const itemListParams = { qr_code: Number(qrCode), user_id: user?.id, branch_code: Number(adjustmentBranch?.value || 0) };


            const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } };
            const [itemData] = await Promise.all([
                axios.post(BASE_URL + COMMON_API.ITEM_LIST_FROM_QRCODE, itemListParams, config),

            ]);

            if (itemData?.data?.body?.status === COMMONVARIABLES.STATUS.UNAUTHORIZED) {


                const refresh = await getRefreshToken();

                if (refresh) {
                    if (Number(refresh) === COMMONVARIABLES.STATUS.EXPIRED_TOKEN) {
                        Logout();
                    } else {
                        await fetchItemListFromQrCode(qrCode);
                    }
                }
            }

            if (itemData?.data?.body?.status === COMMONVARIABLES.STATUS.SERVICE_CATCH_STATUS) {
                toast.error(ERROR.API_ERROR.SERVICE_CATCH_ERROR, { position: toast.POSITION.TOP_RIGHT });
            }

            if (itemData?.data?.body?.itemList) {
                
                setItemName(itemData?.data?.body?.itemList[0])
                fnCalculateCurrentStock(itemData?.data?.body?.itemList[0].stock,Number(qrCode),0,0)

                // setCurrentStock(itemData?.data?.body?.itemList[0].stock)
                //setCurrentStock()
            }


        } catch (error) {
            console.error('Error fetching master data:', error);
            if (error.code === COMMONVARIABLES.STATUS.NETWORK_ERROR_STATUS) {
                toast.error(ERROR.API_ERROR.NETWORK_ERROR, { position: toast.POSITION.TOP_RIGHT });
            }
            if (error.code === COMMONVARIABLES.STATUS.BAD_REQUEST_ERROR_STATUS) {
                toast.error(ERROR.API_ERROR.BAD_REQUEST_ERROR, { position: toast.POSITION.TOP_RIGHT });
            }
        }
    }

    function fnCalculateCurrentStock(curStock,qrcode,adType, qty)
    {
        let addSum = 0;
        let subSum = 0;

        // Iterate through the array and accumulate the sum
        for (let i = 0; i < itemDetails.length; i++) {
        // Access the value of the 'value' key for each object
            if(itemDetails[i].qrcode == qrcode)
            {
                if(itemDetails[i]?.ad_type == 2)
                    subSum += Number(itemDetails[i].qty);
                else
                    addSum += Number(itemDetails[i].qty);
            }
         
        }
        curStock =  (Number(curStock) + addSum) - subSum
        if(adType != 0)
        {
            if(adType ==1)
            {
                curStock = Number(curStock) - Number(qty) 
            }
            else{
                curStock = Number(curStock) + Number(qty)   
            }
        }
        setCurrentStock(curStock)

    }
    function itemDetailsValidate(e) {
        e.preventDefault();
        var error = "";
       
        if(adjustmentBranch == "")
            error = ERROR.BRANCH_REQUIRED;
        else if (itemCode === '' || itemName === '' || itemQty === '' || adjustmentType?.value == '' || adjustmentType?.value ==  undefined || (adjustmentType?.value ==1 && noOfPrints == '')) {
            error = ERROR.COMMON_REQUIRED;
        } else if (itemQty === '0') {
            error = ERROR.STOCK_TRANSFER_ADD.ITEM_QTY_VALID
        }
        else {
            error = '';
        }
        if (adjustmentType?.value === 2) {
            if (currentStock >= 0 ? itemQty > currentStock : itemQty > itemName.stock) {
                        error = ERROR.STOCK_TRANSFER_ADD.ITEM_QTY_VALID
                    }
        }
        // var quantity = currentStock >= 0 ? currentStock : itemName?.stock
        // if (adjustmentType?.value === 2) {
        //     if (currentStock >= 0 ? itemQty > currentStock : itemQty > itemName.stock) {
        //         error = ERROR.STOCK_TRANSFER_ADD.ITEM_QTY_VALID
        //     } else {
        //         quantity = quantity - itemQty
        //         setCurrentStock(quantity)
        //         console.log(quantity);
        //     }
        // } else {
        //     quantity = parseFloat(quantity) + parseFloat(itemQty)
        //     setCurrentStock(quantity)
        //     console.log(quantity);
        // }
        if (error === '') {
            const formattedValue = parseFloat(itemQty).toFixed(itemName?.um_no_of_decimals);
            var itemObj = {
                sno: isEdit !== 1 ? itemDetails.length + 1 : currentSno,
                ad_type: adjustmentType?.value,
                itemcode: isEdit !== 1 ? itemName.qs_item_code : itemName.itemcode,
                qrcode: itemCode,
                itemUnit: itemName.um_display_name,
                itemName: itemName.im_display_name,
                qty: formattedValue,
                um_no_of_decimals: itemName.um_no_of_decimals,
                im_unit_code: itemName.im_unit_code,
                im_item_name: itemName.im_item_name,
                stock: itemName.stock,
                selling_rate: itemName.selling_rate,
                remarks: narration,
                printcount:noOfPrints,
                adjustment_type: adjustmentType,
                qs_label_details:itemName?.qs_label_details
            }
            if (isEdit === 0) // If it is add, need to push into array
            {
                var itemDetailsExist = itemDetails.filter((e) => e.itemcode === itemName.qs_item_code && e.qrcode === itemCode)
                var itemDetailsArray = itemDetails;
                if (itemDetailsExist.length === 0) {

                    itemDetailsArray.push(itemObj)
                    setItemDetails(itemDetailsArray)
                } else {
                    let qty = 0
                    let diff_type = 0
                    itemDetailsArray.forEach((e, i) => {
                        if (e.itemcode === itemName.qs_item_code && e.qrcode === itemCode && e.ad_type == adjustmentType?.value) {
                            const obj = { ...e }
                            qty = Number(obj['qty']) + Number(itemQty)
                            const formattedValue = parseFloat(qty).toFixed(itemName?.um_no_of_decimals);
                            setItemQty(formattedValue)
                            obj['qty'] = formattedValue
                            itemDetailsArray.splice(i, 1, obj)
                            diff_type = 1

                        }

                    })
                    if (diff_type == 0)// if the item is different adjustment type, then need to push
                    {

                        itemDetailsArray.push(itemObj)
                        setItemDetails(itemDetailsArray)

                    }
                    setItemDetails(itemDetailsArray)
                }




            }
            else {
                replaceDataBySno(itemObj)
            }

            clearAddData()
        } else {
            toast.error(error, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }

    const udfnLoadItem = (e, editItem, type) => {

        e.preventDefault();
        console.log('editItem', editItem);
        // if (editItem?.ad_type == 1) {
        //     var edit_quantity = currentStock - editItem?.qty;
        //     setCurrentStock(edit_quantity)
        // }
        // else {
        //     var edit_quantity = parseFloat(currentStock) + parseFloat(editItem?.qty);
        //     setCurrentStock(edit_quantity)
        // }
        setIsEdit(type)

        setItemName({ itemcode: editItem?.itemcode, im_item_name: editItem?.im_item_name, im_display_name: editItem?.itemName, im_unit_code: editItem?.im_unit_code, um_no_of_decimals: editItem?.um_no_of_decimals, um_unit_name: editItem?.itemUnit, um_display_name: editItem?.itemUnit, stock: editItem?.stock,qs_label_details:editItem?.qs_label_details,selling_rate:editItem?.selling_rate })

        setCurrentSno(editItem?.sno)
        setItemCode(editItem?.qrcode);

        setItemQty(editItem?.qty);
         setNoOfPrints(editItem?.printcount)
        setNarration(editItem?.remarks)
        setAdjustmentType(editItem?.adjustment_type)
      
        fnCalculateCurrentStock(editItem?.stock, editItem?.qrcode, editItem?.ad_type, editItem?.qty)
    
        // alert(Number(editItem.qty))
        // if(editItem?.ad_type == 1 )
        //     setCurrentStock(currentStock - Number(editItem.qty))
        // else
        //      setCurrentStock(currentStock + Number(editItem.qty))

    };
    // Function to delete item based on ID
    const udfnDeleteItem = sno => {
        // Filter out the item with the matching sno
        console.log(itemDetails);
        // var quantity = currentStock >= 0 ? currentStock : itemName?.stock
        // var quantity = currentStock;
        // console.log(quantity);
        // itemDetails.map((row) => {
        //     if (row.sno == sno) {
        //         if (row?.ad_type === 2) {
        //             quantity = parseFloat(quantity) + parseFloat(row?.qty)
        //             setCurrentStock(quantity)
        //             console.log(quantity);
        //         }
        //         if (row?.ad_type === 1) {
        //             quantity = parseFloat(quantity) - parseFloat(row?.qty)
        //             setCurrentStock(quantity)
        //             console.log(quantity);
        //         }
        //     }
        // })
        const updatedData = itemDetails.filter(item => item.sno !== sno);

        // Update the state with the filtered array
        setItemDetails(updatedData);
    };
    function clearAddData() {
        setItemName('');
        setItemCode('');
        setItemQty('');
        setIsEdit(0)
        setAdjustmentType('')
        setNarration("")
        setNoOfPrints("")
    }
    // Function to replace the entire JSON array based on key
    const replaceDataBySno = (newData) => {
        const updatedData = [...itemDetails]; // Create a copy of the original data

        // Find the index of items that match the key
        const indexesToUpdate = updatedData.reduce((acc, item, index) => {
            if (item.sno === currentSno) {
                acc.push(index);
            }
            return acc;
        }, []);

        // Replace the items with new data
        indexesToUpdate.forEach(index => {
            updatedData[index] = newData;
        });

        // Update the state with the modified array
        setItemDetails(updatedData);
    };
    return (
        <>
            <ToastContainer />
            <LoadingOverlay
                active={isLoading}
                spinner
                text='Loading ...'
            >
                <div className="card">
                    <div className="card-header">
                        <div className='col-sm-5'>
                            <h4 className="card-title">{title}</h4>
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="basic-form">
                            <form onSubmit={(e) => e.preventDefault()}>
                                <div className="row stock-transfer">

                                    <div className="form-group mb-3 col-lg-4">
                                        <label className="text-black font-w300">Branch Name </label>
                                        <span className="text-danger star-txt">*</span>
                                        <Select
                                            isDisabled={itemDetails.length == 0 ? false : true}
                                            defaultValue={adjustmentBranch}
                                            // onChange={setTransferFrom}
                                            onChange={setAdjustmentBranch}
                                            value={adjustmentBranch}
                                            options={adjustmentBranchOptions.map((option) => ({
                                                label: option.bm_branch_name,
                                                value: option.bm_branch_code,
                                            }))}
                                            showContent="true"
                                            open="true"
                                            className="my-react-select-container"
                                            theme={theme => ({
                                                ...theme,
                                                borderRadius: '6px',
                                                colors: {
                                                    ...theme.colors,
                                                    primary25: '#b3b3b3',
                                                    primary: 'black',
                                                    neutral80: "data-theme-version" === "dark" ? 'white' : ''
                                                },
                                            })}
                                        />
                                    </div>

                                </div>
                                <hr />
                                <div className='row stock-transfer'>
                                    <div className="col-md-2">
                                        <label className="text-black font-w300">Type</label>
                                        <span className="text-danger star-txt">*</span>
                                        <Select
                                            isDisabled={isEdit === 0 ? false:true}
                                            defaultValue={adjustmentType}
                                            // onChange={setTransferFrom}
                                            onChange={setAdjustmentType}
                                            value={adjustmentType}
                                            options={adjustmentTypeOptions.map((option) => ({
                                                label: option.label,
                                                value: option.value,
                                            }))}
                                            showContent="true"
                                            open="true"
                                            className="my-react-select-container"
                                            theme={theme => ({
                                                ...theme,
                                                borderRadius: '6px',
                                                colors: {
                                                    ...theme.colors,
                                                    primary25: '#b3b3b3',
                                                    primary: 'black',
                                                    neutral80: "data-theme-version" === "dark" ? 'white' : ''
                                                },
                                            })}
                                        />
                                    </div>
                                    <div className="col-md-2">
                                        <label className="text-black font-w300">Item Code</label>
                                        <span className="text-danger star-txt">*</span>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Item Code"
                                            value={itemCode}
                                            onChange={(e) => {
                                                setItemName('')
                                                setItemCode(e.target.value)
                                            }}
                                            onBlur={(e) => onBlurQrCode(e.target.value)}
                                        />
                                    </div>
                                    <div className="col-md-5">
                                        <div className="currentStock">
                                            <div>
                                                <label className="text-black font-w300">Item Name</label>
                                                <span className="text-danger star-txt">*</span>
                                            </div>
                                            {itemName && <div>
                                                <label className="text-black font-w300">Current Stock : </label>
                                                {currentStock >= 0 ? <span className="text-danger star-txt stock-transfer-span">{currentStock || '0'}</span> : <span className="text-danger star-txt stock-transfer-span">{itemName?.stock || '0'}</span>}
                                            </div>}
                                        </div>


                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Item Name"
                                            value={itemName?.im_display_name || ''}
                                            readOnly
                                        />
                                    </div>
                                    <div className="col-md-1">
                                        <label className="text-black font-w300">Qty</label>
                                        <span className="text-danger star-txt">*</span>
                                        <input
                                            type="number"
                                            className="form-control text-end"
                                            placeholder="Qty"
                                            value={itemQty}
                                            onKeyPress={(e) => {
                                                if (e.key === '-' || e.key === 'e') {
                                                    e.preventDefault();
                                                }
                                            }}
                                            min="0"
                                            onPaste={(e) => {
                                                e.preventDefault();
                                                const pastedText = e.clipboardData.getData('text');
                                                let pastedValue = parseFloat(pastedText);
                                                if (isNaN(pastedValue) && pastedValue < 0) {
                                                    pastedValue = "";
                                                }
                                                e.target.value = pastedValue.toString().slice(0, 3);

                                            }}
                                            onChange={(e) => {

                                                const formattedValue = parseFloat(e.target.value).toFixed(itemName?.um_no_of_decimals);
                                                setItemQty(e.target.value)

                                            }}
                                        />
                                    </div>
                                    
                                    {
                                        adjustmentType.value === 1 ?
                                            <div className="col-md-1">
                                                <label className="text-black font-w300">Prints</label>
                                                <span className="text-danger star-txt">*</span>
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    placeholder="Nos."
                                                    onKeyPress={(e) => qtyValidation(e)}
                                                    value={noOfPrints}
                                                    onChange={(e) => setNoOfPrints(e.target.value)}
                                                />
                                            </div>
                                            : ""
                                    }

                                    <div className="form-group col-lg-6 col-sm-12 mb-2">
                                        <label className="text-black font-w300">Narration</label>
                                        <textarea className="form-control text-area-udf1"
                                            placeholder="Narration"
                                            value={narration}
                                            onChange={(e) => setNarration(e.target.value)}></textarea>
                                    </div>

                                    <div className="col-md-2">
                                        <Button className="me-1 mt-4" variant="whatsapp" onClick={itemDetailsValidate}>
                                            <i className={isEdit === 0? "fa fa-plus":"fa fa-check"}></i>
                                        </Button>

                                     
                                        <Button
                                            variant="danger"
                                            type="button"
                                            className="btn btn-danger mt-4"
                                            onClick={(e) => clearAddData(e)}
                                        >
                                            <i className="fa fa-ban"></i>
                                        </Button>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="table-responsive">
                                        <table className="table table-striped">
                                            <thead>
                                                <tr>
                                                    <th width='5%'>#</th>
                                                    <th width='15%'>Type</th>
                                                    <th width='15%'>Item Code</th>
                                                    <th width='30%'>Item Name</th>
                                                    <th width='15%' className='text-end'>Quantity</th>
                                                    <th width='20%' className='text-center'>Narration</th>
                                                    <th width='15%' className='text-end'>No of prints</th>
                                                    <th width='15%' className='text-center'>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    itemDetails.length > 0 ?
                                                        itemDetails.map((row, index) => {
                                                            return (
                                                                <tr  key={index} style={{ backgroundColor: row.ad_type === 1 ? '#2df1ff6e' : '#ff8282',color: row.ad_type === 1 ? '#000' : '#FFF'}}>
                                                                    <td style={{color: row.ad_type === 1 ? '#000' : '#FFF' }}>{index + 1}</td>
                                                                    <td>{row.adjustment_type?.label}</td>
                                                                    <td>{row.qrcode}</td>
                                                                    <td>{row.itemName}</td>
                                                                    <td className='text-end'>{row.qty} {row.itemUnit}</td>
                                                                    <td className='text-center'>{row.remarks}</td>
                                                                    <td className='text-end'>{row.printcount}</td>
                                                                    <td className='text-center'>
                                                                        <Link className="btn btn-secondary	 shadow btn-xs sharp me-1" onClick={(e) => udfnLoadItem(e, row, 1)}>
                                                                            <i className="fa fa-pencil"></i>
                                                                        </Link>
                                                                        <Link className="btn btn-danger shadow btn-xs sharp" onClick={(e) => udfnDeleteItem(row?.sno)}>
                                                                            <i className="fa fa-trash"></i>
                                                                        </Link>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })
                                                        : ''
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className='row mb-0'>

                                    {/* <div className="col-md-3">
                                        <label className="text-black font-w300">No of Prints</label>
                                        <span className="text-danger star-txt">*</span>
                                        <input
                                            type="number"
                                            className="form-control"
                                            placeholder="Nos."
                                            onKeyPress={(e) => qtyValidation(e)}
                                            value={noOfPrints}
                                            onChange={(e) => setNoOfPrints(e.target.value)}
                                        />
                                    </div> */}

                                    <div className="form-group mb-3 mt-3 col-md-3">
                                        <label className="text-black font-w300">Label Size</label>
                                        <Select
                                            defaultValue={labelTypeName}
                                            value={labelTypeName}
                                            onChange={setLabelTypeName}
                                            options={labelTypeNameOptions}
                                            onKeyDown={handleKeyDown}
                                            showContent="true"
                                            open="true"
                                            className="my-react-select-container"
                                            theme={theme => ({
                                                ...theme,
                                                borderRadius: '6px',
                                                colors: {
                                                    ...theme.colors,
                                                    primary25: '#b3b3b3',
                                                    primary: 'black',
                                                    neutral80: "data-theme-version" === "dark" ? 'white' : ''
                                                },
                                            })}
                                        />
                                    </div>
                                    <div className="col-lg-9 col-sm-12 mb-3 text-end">
                                        <div style={{ marginTop: '80px' }}>

                                            <Link to='/stock-adjustment-list'>
                                                <Button
                                                    variant="danger"
                                                    type="button"
                                                    className="btn btn-success me-2"
                                                >
                                                    Discard
                                                </Button>
                                            </Link>
                                            <Button className="me-2" variant="whatsapp" ref={buttonRef} onClick={onClickSaveBtn} disabled={buttonFlag}>
                                                {btnTitle}
                                            </Button>
                                        </div>
                                    </div>
                                </div>

                            </form>
                        </div>
                    </div >
                </div >


                {qrCodeItemDetails.map((row) => (
                    <div id={myid + row.qrcode} className='color-picker' style={{ display: 'none' }}>
                        <QRCode
                            size={45}
                            value={row.qrcode.toString()}
                        />
                    </div>
                ))}

                {qrCodeItemDetails.map((row) => (
                    <div id={myqrid + row.qrcode} className='color-picker' style={{ display: 'none' }}>
                        <QRCode
                            size={35}
                            value={row.qrcode.toString()}
                        />
                    </div>
                ))}

            </LoadingOverlay>
        </>
    )

}
export default StockAdjustmentAdd;