import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTable, useGlobalFilter, useFilters, useSortBy, usePagination } from 'react-table';
import { COLUMNS } from '../Filters/Item/ItemColumns';
import { Link } from "react-router-dom";
import { Button, Modal } from 'react-bootstrap';
import '../Filters/Common/filtering.css';
import Select from "react-select";
import { Badge } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ERROR_MESSAGE from '../Validation/ErrorMessage';
import swal from "sweetalert";
import { getLoggedInUser, getRefreshToken } from "../../../services/AuthService"
import MASTER_API from '../../Api/MasterApi';
import COMMON_API from '../../Api/CommonApi';
import Formatting from '../Utilities/format';
import { UserLogout } from '../../layouts/nav/Logout';
import axios from 'axios';
import COMMONVARIABLES from '../Utilities/constants';
import LoadingOverlay from 'react-loading-overlay-ts';
import FormValidation from '../Validation/FormValidation';
import { UncontrolledTooltip } from "reactstrap"

export const ItemMaster = () => {
	const user = getLoggedInUser();

	const BASE_URL = process.env.REACT_APP_BASE_URL;
	const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } };

	const [isLoading, setIsLoading] = useState(false);
	const [buttonFlag, setButtonFlag] = useState(false);
	const [editFlag, setEditFlag] = useState(false);

	const [categoryOptions, setCategoryOptions] = useState([]);
	const [unitOptions, setUnitOptions] = useState([]);
	const [taxOptions, setTaxOptions] = useState([]);
	const [itemList, setItemList] = useState([]);
	const [list, setList] = useState([]);
	const [itemTotalCount, setItemTotalCount] = useState(0);
	const [addCard, setAddCard] = useState(false);

	const [itemCode, setItemCode] = useState(0);
	const [itemName, setItemName] = useState('');
	const [categoryCode, setCategoryCode] = useState('');
	const [groupName, setGroupName] = useState('');
	const [manualCode, setManualCode] = useState('');
	const [displayName, setDisplayName] = useState('');
	const [unitCode, setUnitCode] = useState('');
	const [hsnCode, setHsnCode] = useState('');
	const [taxPer, setTaxPer] = useState('');
	const [discPer, setDiscPer] = useState(0);
	const [status, setStatus] = useState(1);


	const columns = useMemo(() => COLUMNS, [])
	const data = itemList
	const tableInstance = useTable({
		columns,
		data,
		initialState: { pageIndex: 0 }
	}, useFilters, useGlobalFilter, useSortBy, usePagination)

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		prepareRow,
		state,
		page,
		gotoPage,
		pageCount,
		pageOptions,
		nextPage,
		previousPage,
		canNextPage,
		canPreviousPage,
	} = tableInstance

	const { pageIndex } = state

	// item name filter
	const udfnfilter = (value, field) => {
		if (value !== "") {
			setItemList([])
			var filteredItems = [];
			var result = [];
			if (value.length > 3) {
				result = value.split(" ", value.length);
			} else {
				result = [value];
			}
			result.map((filter) => {
				var filter_word = filter.toLowerCase();
				if (field === "im_item_name") {
					filteredItems.push(...itemList.filter(item => item.im_item_name.toLowerCase().includes(filter_word)));
				}
			})
			setItemList(filteredItems);
		} else if (value.length === 0) {
			setItemList(list)
		}
	}

	const udfnsetItemName = (value) => {
		setItemName(value)
		if (!editFlag) {
			setDisplayName(value)
		}
	};

	const udfnNameValidation = (event) => {
		var val = event.target.value.charAt(0).toUpperCase() + event.target.value.slice(1);
		event.target.value = val;
	};

	const udfnDiscountValidation = (event) => {
		const regex = /^[0-9]*$/;
		const input = event.target.value + event.key;
		if (!regex.test(input) || input.length > 2) {
			event.preventDefault();
		}
	};

	const udfnHsnCodeValidation = (event) => {
		const regex = /^[0-9]*$/;
		const input = event.target.value + event.key;
		if (!regex.test(input)) {
			event.preventDefault();
		}
	};

	const udfnClearForm = () => {
		setItemCode(0)
		setItemName('')
		setCategoryCode('')
		setGroupName('')
		setManualCode('')
		setDisplayName('')
		setUnitCode('')
		setHsnCode('')
		setTaxPer('')
		setDiscPer(0)
		setStatus(1)
		setAddCard(false);
		setEditFlag(false);
	};

	const udfnSaveFormClear = () => {
		setItemCode(0)
		setItemName('')
		setCategoryCode('')
		setManualCode('')
		setDisplayName('')
		setUnitCode('')
		setHsnCode('')
		setTaxPer('')
		setDiscPer(0)
		setStatus(1)
		setAddCard(false);
		setEditFlag(false);
	};

	const udfnFormValidate = (event) => {
		event.preventDefault();
		var error = "";
		if (!itemName.trim() || categoryCode === '' || groupName === '' || !displayName.trim() || unitCode === '' || taxPer === '') {
			error = ERROR_MESSAGE.COMMON_REQUIRED;
		}
		else if (!FormValidation('item_name', itemName)) {
			error = ERROR_MESSAGE.ITEM_MASTER.ITEM_VALID;
		} else if (manualCode !== '') {
			if (!FormValidation('manual_code', manualCode)) {
				error = ERROR_MESSAGE.ITEM_MASTER.MANUAL_CODE_VALID;
			}
		}
		else if (hsnCode !== '') {
			if (hsnCode.length < 4) {
				error = ERROR_MESSAGE.ITEM_MASTER.HSN_CODE_VALID;
			}
		}
		else {
			error = '';
		}
		if (error === '') {
			udfnItemCreate()
		} else {
			toast.error(error, { position: toast.POSITION.TOP_RIGHT });
		}
	};

	const udfnItemList = async () => {
		setIsLoading(true)

		var apiUrl = BASE_URL + MASTER_API.ITEM_MASTER_LIST;

		const uploadData = {
			user_id: Number(user.id)
		};

		try {
			const response = await axios.post(apiUrl, uploadData, config);
			if (response && response?.data?.body?.status === COMMONVARIABLES.STATUS.INVALIDUSER) {
				setIsLoading(false)
				toast.error(response.data.body.message, { position: toast.POSITION.TOP_RIGHT });
				UserLogout();
			}

			if (response?.data?.body?.status === COMMONVARIABLES.STATUS.UNAUTHORIZED) {
				setIsLoading(false)
				const refresh = await getRefreshToken();

				if (refresh) {
					if (Number(refresh) === COMMONVARIABLES.STATUS.EXPIRED_TOKEN) {
						UserLogout();
					} else {
						await udfnItemList();
					}
				}
			}

			if (response?.data?.body?.status === COMMONVARIABLES.STATUS.SERVICE_CATCH_STATUS) {
				setIsLoading(false)
				toast.error(ERROR_MESSAGE.API_ERROR.SERVICE_CATCH_ERROR, { position: toast.POSITION.TOP_RIGHT });
			}

			if (response?.data?.body?.itemList) {
				setItemList(response.data.body.itemList);
				setList(response.data.body.itemList)
				var itemListCount = response.data.body.itemList.filter(item => item.im_status_code === 1).length;
				setItemTotalCount(itemListCount);
				toast.success(response.data.body.message, { position: toast.POSITION.TOP_RIGHT });
				setIsLoading(false);
			} else {
				setIsLoading(false)
				toast.error(response.data.body.message, { position: toast.POSITION.TOP_RIGHT });
			}
		} catch (error) {
			setIsLoading(false)
			// Handle error
			console.error("Item list API Error:", error);
			if (error.code === COMMONVARIABLES.STATUS.NETWORK_ERROR_STATUS) {
				toast.error(ERROR_MESSAGE.API_ERROR.NETWORK_ERROR, { position: toast.POSITION.TOP_RIGHT });
			}
			if (error.code === COMMONVARIABLES.STATUS.BAD_REQUEST_ERROR_STATUS) {
				toast.error(ERROR_MESSAGE.API_ERROR.BAD_REQUEST_ERROR, { position: toast.POSITION.TOP_RIGHT });
			}
		}
	};

	const udfnCategoryOptions = async () => {
		setIsLoading(true)
		var apiUrl = BASE_URL + COMMON_API.CATEGORY_LIST;
		const uploadData = {
			user_id: Number(user.id),
			status_code: Number(1),
		};

		try {
			const response = await axios.post(apiUrl, uploadData, config);
			setIsLoading(false)
			if (response && response?.data?.body?.status === COMMONVARIABLES.STATUS.INVALIDUSER) {
				toast.error(response.data.body.message, { position: toast.POSITION.TOP_RIGHT });
				UserLogout();
			}

			if (response?.data?.body?.status === COMMONVARIABLES.STATUS.UNAUTHORIZED) {
				const refresh = await getRefreshToken();

				if (refresh) {
					if (Number(refresh) === COMMONVARIABLES.STATUS.EXPIRED_TOKEN) {
						UserLogout();
					} else {
						await udfnCategoryOptions();
					}
				}
			}

			if (response?.data?.body?.status === COMMONVARIABLES.STATUS.SERVICE_CATCH_STATUS) {
				toast.error(ERROR_MESSAGE.API_ERROR.SERVICE_CATCH_ERROR, { position: toast.POSITION.TOP_RIGHT });
			}

			if (response?.data?.body?.categoryList) {
				setCategoryOptions(response.data.body.categoryList);
				setIsLoading(false);
			}
		} catch (error) {
			setIsLoading(false)
			// Handle error
			console.error("Category list API Error:", error);
			if (error.code === COMMONVARIABLES.STATUS.NETWORK_ERROR_STATUS) {
				toast.error(ERROR_MESSAGE.API_ERROR.NETWORK_ERROR, { position: toast.POSITION.TOP_RIGHT });
			}
			if (error.code === COMMONVARIABLES.STATUS.BAD_REQUEST_ERROR_STATUS) {
				toast.error(ERROR_MESSAGE.API_ERROR.BAD_REQUEST_ERROR, { position: toast.POSITION.TOP_RIGHT });
			}
		}

	};

	const udfnUnitOptions = async () => {
		setIsLoading(true)
		var apiUrl = BASE_URL + COMMON_API.UNIT_LIST;
		const uploadData = {
			user_id: Number(user.id),
			status_code: Number(1),
		};

		try {
			const response = await axios.post(apiUrl, uploadData, config);
			setIsLoading(false)
			if (response && response?.data?.body?.status === COMMONVARIABLES.STATUS.INVALIDUSER) {
				toast.error(response.data.body.message, { position: toast.POSITION.TOP_RIGHT });
				UserLogout();
			}

			if (response?.data?.body?.status === COMMONVARIABLES.STATUS.UNAUTHORIZED) {
				const refresh = await getRefreshToken();

				if (refresh) {
					if (Number(refresh) === COMMONVARIABLES.STATUS.EXPIRED_TOKEN) {
						UserLogout();
					} else {
						await udfnUnitOptions();
					}
				}
			}

			if (response?.data?.body?.status === COMMONVARIABLES.STATUS.SERVICE_CATCH_STATUS) {
				toast.error(ERROR_MESSAGE.API_ERROR.SERVICE_CATCH_ERROR, { position: toast.POSITION.TOP_RIGHT });
			}

			if (response?.data?.body?.unitList) {
				setUnitOptions(response.data.body.unitList);
				setIsLoading(false);
			}
		} catch (error) {
			setIsLoading(false)
			// Handle error
			console.error("Unit list API Error:", error);
			if (error.code === COMMONVARIABLES.STATUS.NETWORK_ERROR_STATUS) {
				toast.error(ERROR_MESSAGE.API_ERROR.NETWORK_ERROR, { position: toast.POSITION.TOP_RIGHT });
			}
			if (error.code === COMMONVARIABLES.STATUS.BAD_REQUEST_ERROR_STATUS) {
				toast.error(ERROR_MESSAGE.API_ERROR.BAD_REQUEST_ERROR, { position: toast.POSITION.TOP_RIGHT });
			}
		}

	};

	const udfnTaxOptions = async () => {
		setIsLoading(true)
		var apiUrl = BASE_URL + COMMON_API.TAX_LIST;
		const uploadData = {
			user_id: Number(user.id),
			status_code: Number(1),
		};

		try {
			const response = await axios.post(apiUrl, uploadData, config);
			setIsLoading(false)
			if (response && response?.data?.body?.status === COMMONVARIABLES.STATUS.INVALIDUSER) {
				toast.error(response.data.body.message, { position: toast.POSITION.TOP_RIGHT });
				UserLogout();
			}

			if (response?.data?.body?.status === COMMONVARIABLES.STATUS.UNAUTHORIZED) {
				const refresh = await getRefreshToken();

				if (refresh) {
					if (Number(refresh) === COMMONVARIABLES.STATUS.EXPIRED_TOKEN) {
						UserLogout();
					} else {
						await udfnTaxOptions();
					}
				}
			}

			if (response?.data?.body?.status === COMMONVARIABLES.STATUS.SERVICE_CATCH_STATUS) {
				toast.error(ERROR_MESSAGE.API_ERROR.SERVICE_CATCH_ERROR, { position: toast.POSITION.TOP_RIGHT });
			}

			if (response?.data?.body?.taxList) {
				setTaxOptions(response.data.body.taxList);
				setIsLoading(false);
			}
		} catch (error) {
			setIsLoading(false)
			// Handle error
			console.error("Unit list API Error:", error);
			if (error.code === COMMONVARIABLES.STATUS.NETWORK_ERROR_STATUS) {
				toast.error(ERROR_MESSAGE.API_ERROR.NETWORK_ERROR, { position: toast.POSITION.TOP_RIGHT });
			}
			if (error.code === COMMONVARIABLES.STATUS.BAD_REQUEST_ERROR_STATUS) {
				toast.error(ERROR_MESSAGE.API_ERROR.BAD_REQUEST_ERROR, { position: toast.POSITION.TOP_RIGHT });
			}
		}

	};

	const udfnCategoryGroup = (category) => {
		var group = categoryOptions.filter(item => item.cm_category_code === category.value);
		setCategoryCode(category)
		// setGroupCode(group[0].gm_group_code)
		setGroupName(group[0].gm_group_name)
	};

	const udfnItemCreate = async () => {
		setIsLoading(true);
		setButtonFlag(true);

		var uploadData = {};
		var apiUrl = '';
		if (editFlag === true) {
			uploadData = {
				user_id: Number(user.id),
				company_code: Number(user.company_code),
				item_code: Number(itemCode),
				item_name: String(itemName.trim()),
				category_code: Number(categoryCode.value),
				item_manual_code: String(manualCode.trim()),
				display_name: String(displayName.trim()),
				unit_code: Number(unitCode.value),
				hsn_code: String(hsnCode.trim()),
				tax_per: Number(taxPer.value),
				disc_per: Number(Number(discPer)),
				status_code: Number(status),
			};
			apiUrl = BASE_URL + MASTER_API.ITEM_MASTER_UPDATE;
		} else {
			uploadData = {
				user_id: Number(user.id),
				company_code: Number(user.company_code),
				item_name: String(itemName.trim()),
				category_code: Number(categoryCode.value),
				item_manual_code: String(manualCode.trim()),
				display_name: String(displayName.trim()),
				unit_code: Number(unitCode.value),
				hsn_code: String(hsnCode.trim()),
				tax_per: Number(taxPer.value),
				disc_per: Number(Number(discPer)),
				status_code: Number(1),
			};
			apiUrl = BASE_URL + MASTER_API.ITEM_MASTER_CREATE;
		}

		try {
			const response = await axios.post(apiUrl, uploadData, config);
			setIsLoading(false);
			setButtonFlag(false);

			if (response && response?.data?.body?.status === COMMONVARIABLES.STATUS.INVALIDUSER) {
				toast.error(response.data.body.message, { position: toast.POSITION.TOP_RIGHT });
				UserLogout();
			}
			if (response?.data?.body?.status === COMMONVARIABLES.STATUS.UNAUTHORIZED) {
				const refresh = await getRefreshToken();
				if (refresh) {
					if (Number(refresh) === COMMONVARIABLES.STATUS.EXPIRED_TOKEN) {
						UserLogout();
					} else {
						await udfnItemCreate();
					}
				}
			}
			if (response && response?.data?.body?.status === COMMONVARIABLES.STATUS.EXISTS) {
				toast.error(response.data.body.message, { position: toast.POSITION.TOP_RIGHT });
			}
			if (response && response?.data?.body?.status === COMMONVARIABLES.STATUS.ALREADY_IN_USE) {
				toast.error(response.data.body.message, { position: toast.POSITION.TOP_RIGHT });
			}
			if (response?.data?.body?.status === COMMONVARIABLES.STATUS.SERVICE_CATCH_STATUS) {
				toast.error(ERROR_MESSAGE.API_ERROR.SERVICE_CATCH_ERROR, { position: toast.POSITION.TOP_RIGHT });
			}
			if (response?.data?.body?.status === COMMONVARIABLES.STATUS.SUCCESS) {
				toast.success(response.data.body.message, { position: toast.POSITION.TOP_RIGHT });
				editFlag === true ? udfnClearForm() : udfnSaveFormClear();
				setAddCard(editFlag === true ? false : true)
				await udfnItemList();
			} else {
				toast.error(response.data.body.message, { position: toast.POSITION.TOP_RIGHT });
			}
		} catch (error) {
			// Handle error
			setIsLoading(false);
			setButtonFlag(false);
			console.error("Item create API Error:", error);
			if (error.code === COMMONVARIABLES.STATUS.NETWORK_ERROR_STATUS) {
				toast.error(ERROR_MESSAGE.API_ERROR.NETWORK_ERROR, { position: toast.POSITION.TOP_RIGHT });
			}
			if (error.code === COMMONVARIABLES.STATUS.BAD_REQUEST_ERROR_STATUS) {
				toast.error(ERROR_MESSAGE.API_ERROR.BAD_REQUEST_ERROR, { position: toast.POSITION.TOP_RIGHT });
			}
		}
	};

	const udfnEditLoad = async (row) => {
		udfnCategoryOptions()
		udfnUnitOptions()
		udfnTaxOptions()
		setItemCode(row.im_item_code)
		setItemName(row.im_item_name)
		setCategoryCode({
			value: row.cm_category_code,
			label: row.cm_category_name,
		})
		// setGroupCode(row.gm_group_code)
		setGroupName(row.gm_group_name)
		setManualCode(row.im_item_manual_code)
		setDisplayName(row.im_display_name)
		setUnitCode({
			value: row.um_unit_code,
			label: row.um_display_name,
		})
		setHsnCode(row.im_hsn_code)
		setTaxPer({
			value: row.im_tax_per,
			label: row.im_tax_per,
		})
		setDiscPer(row.im_disc_per)
		setStatus(row.im_status_code)

		setAddCard(true);
		setEditFlag(true);
	};

	const udfnDelete = async (item_code) => {
		swal({
			title: "Are you sure?",
			text:
				"Once deleted, you will not be able to recover this Item!",
			icon: "warning",
			buttons: true,
			dangerMode: true,
		}).then((willDelete) => {
			if (willDelete) {
				udfnItemDelete(item_code);
			} else {
				swal("Your Item is safe!");
			}
		})
	}

	const udfnItemDelete = async (item_code) => {
		setIsLoading(true);
		var apiUrl = BASE_URL + MASTER_API.ITEM_MASTER_DELETE;

		const uploadData = {
			user_id: Number(user.id),
			company_code: Number(user.company_code),
			item_code: Number(item_code),
		};

		try {
			const response = await axios.post(apiUrl, uploadData, config);
			setIsLoading(false);

			if (response && response?.data?.body?.status === COMMONVARIABLES.STATUS.INVALIDUSER) {
				toast.error(response.data.body.message, { position: toast.POSITION.TOP_RIGHT });
				UserLogout();
			}

			if (response?.data?.body?.status === COMMONVARIABLES.STATUS.UNAUTHORIZED) {
				const refresh = await getRefreshToken();

				if (refresh) {
					if (Number(refresh) === COMMONVARIABLES.STATUS.EXPIRED_TOKEN) {
						UserLogout();
					} else {
						await udfnItemDelete();
					}
				}
			}

			if (response && response?.data?.body?.status === COMMONVARIABLES.STATUS.EXISTS) {
				toast.error(response.data.body.message, { position: toast.POSITION.TOP_RIGHT });
			}
			if (response?.data?.body?.status === COMMONVARIABLES.STATUS.SERVICE_CATCH_STATUS) {
				toast.error(ERROR_MESSAGE.API_ERROR.SERVICE_CATCH_ERROR, { position: toast.POSITION.TOP_RIGHT });
			}

			if (response?.data?.body?.status === COMMONVARIABLES.STATUS.SUCCESS) {
				swal(response.data.body.message, {
					icon: "success",
				});
				await udfnItemList();
			} else {
				toast.error(response.data.body.message, { position: toast.POSITION.TOP_RIGHT });
			}
		} catch (error) {
			// Handle error
			setIsLoading(false);
			console.error("Item list API Error:", error);
			if (error.code === COMMONVARIABLES.STATUS.NETWORK_ERROR_STATUS) {
				toast.error(ERROR_MESSAGE.API_ERROR.NETWORK_ERROR, { position: toast.POSITION.TOP_RIGHT });
			}
			if (error.code === COMMONVARIABLES.STATUS.BAD_REQUEST_ERROR_STATUS) {
				toast.error(ERROR_MESSAGE.API_ERROR.BAD_REQUEST_ERROR, { position: toast.POSITION.TOP_RIGHT });
			}
		}
	};

	const buttonRef = useRef(null);

	const handleKeyDown = (event) => {
		// Check if the pressed key is the Tab key (key code 9)
		if (event.keyCode === 9) {
			// Prevent the default Tab behavior to prevent losing focus from the text input
			event.preventDefault();

			// Focus the button element
			buttonRef.current.focus();
		}
	};

	const openCard = () => {
		udfnClearForm()
		udfnCategoryOptions()
		udfnUnitOptions()
		udfnTaxOptions()
		setAddCard(true)
	}

	useEffect(() => {
		udfnItemList()
		// udfnCategoryOptions()
		// udfnUnitOptions()
		// udfnTaxOptions()
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<ToastContainer />
			<LoadingOverlay
				active={isLoading}
				spinner
				text='Loading ...'
			>
				<div className="card">
					<div className="card-header">
						<div className='col-sm-5'>
							<h4 className="card-title">Item Master </h4>
						</div>
						<div className='col-sm-3'>
							<p className="bgl-primary rounded p-2 text-center mb-2"><span className="text-black" >Total Active Items  :  </span><Badge bg='info badge-rounded' className='text-black font-w400 fs-18'>{itemTotalCount}</Badge></p>
						</div>
						<div className='col-sm-2 me-1'>
							<Link className="btn btn-primary mb-2" onClick={() => openCard()}>
								<i className="fa fa-plus"></i> &nbsp; Add Item
							</Link>
						</div>
					</div>
					<div className="card-body">
						<div className="table-responsive">
							<table {...getTableProps()} className="table filtering-table table-responsive-lg">
								<thead>
									{headerGroups.map(headerGroup => (
										<tr {...headerGroup.getHeaderGroupProps()}>
											<th>
												<p className='tbl-heading-txt'>S.NO.</p>
											</th>
											{headerGroup.headers.map((column, index) => (
												<th {...column.getHeaderProps()} key={index}>
													{column.render('Header')}
													{column.canFilter ? column.render('Filter') : <input type='text' className="form-control item-filter" onChange={e => udfnfilter(e.target.value, "im_item_name")} />}
												</th>
											))}
											<th>
												<p className='tbl-heading-txt'>Actions</p>
											</th>
										</tr>
									))}
								</thead>
								<tbody {...getTableBodyProps()} className="" >
									{page.map((row, index) => {
										prepareRow(row)
										return (
											<tr key={index}>
												<td>{index + 1}</td>
												<td>{row.original.im_item_name}</td>
												<td>{row.original.cm_category_name}</td>
												<td>{row.original.gm_group_name}</td>
												<td>{row.original.im_item_manual_code}</td>
												<td>{row.original.im_display_name}</td>
												<td>{row.original.um_display_name}</td>
												<td>{row.original.im_hsn_code}</td>
												<td className='text-end'>{COMMONVARIABLES.India_Rupee_Formatter_Digits(Formatting('two_decimal', row.original.im_tax_per))}</td>
												<td>
													{row.original.status === "Active" ? <Badge bg="" className='badge-success light'>
														<span className="me-2">
															<i className="ms-1 fa fa-check" />
														</span>
														{row.original.status}
													</Badge> : <Badge bg="" className='badge-primary light'>
														<span className="me-2">
															<i className="fa fa-times"></i>
														</span>
														{row.original.status}
													</Badge>
													}
												</td>
												<td>
													<Link className="btn btn-secondary	 shadow btn-xs sharp me-2"
														onClick={() => udfnEditLoad(row.original)}
													>
														<i className="fa fa-pencil" id={'edittooltip' + index}></i>
														<UncontrolledTooltip placement="top" target={'edittooltip' + index}>
															Edit
														</UncontrolledTooltip>
													</Link>
													<Link className="btn btn-danger shadow btn-xs sharp" onClick={() => udfnDelete(row.original.im_item_code)}>
														<i className="fa fa-trash" id={'deletetooltip' + index}></i>
														<UncontrolledTooltip placement="top" target={'deletetooltip' + index}>
															Delete
														</UncontrolledTooltip>
													</Link>
												</td>
											</tr>
										)
									})}
								</tbody>
							</table>
							<div className="d-flex justify-content-between">
								<span>
									Page{' '}
									<strong>
										{pageIndex + 1} of {pageOptions.length}
									</strong>{''}
								</span>
								<span className="table-index">
									Go to page : {' '}
									<input type="number"
										className="ms-2"
										defaultValue={pageIndex + 1}
										onChange={e => {
											const pageNumber = e.target.value ? Number(e.target.value) - 1 : 0
											gotoPage(pageNumber)
										}}
									/>
								</span>
							</div>
							<div className="text-center">
								<div className="filter-pagination  mt-3">
									<button className=" previous-button" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>{'<<'}</button>

									<button className="previous-button" onClick={() => previousPage()} disabled={!canPreviousPage}>
										Previous
									</button>
									<button className="next-button" onClick={() => nextPage()} disabled={!canNextPage}>
										Next
									</button>
									<button className=" next-button" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>{'>>'}</button>
								</div>
							</div>
						</div>
					</div>
				</div>
				<Modal className="fade bd-example-modal-lg" show={addCard} onHide={setAddCard} size="lg" backdrop="static">
					<Modal.Header>
						<h4 className="text-black">{editFlag === true ? 'Update Item' : 'Add Item'}</h4>
						<Button
							variant=""
							className="close"
							onClick={() => udfnClearForm()}
						>
							<span>&times;</span>
						</Button>
					</Modal.Header>
					<Modal.Body>
						<div className="basic-form">
							<form onSubmit={(e) => e.preventDefault()}>
								<div className="row">
									<div className="form-group mb-3 col-md-6">
										<label className="text-black font-w500">Item Name <span className="text-danger">*</span></label>
										<input
											type="text"
											className="form-control"
											value={itemName}
											autoFocus
											onChange={e => udfnsetItemName(e.target.value)}
											onKeyUp={(e) => udfnNameValidation(e)}
											placeholder="Item Name"
											maxLength="100"
										/>
									</div>
									<div className="form-group mb-3 col-md-6">
										<label className="text-black font-w500">Category Name <span className="text-danger">*</span></label>
										<Select
											defaultValue={categoryCode}
											value={categoryCode}
											onChange={e => udfnCategoryGroup(e)}
											options={categoryOptions.map((option) => ({
												label: option.cm_category_name,
												value: option.cm_category_code,
											}))}
											showContent="true"
											open="true"
											className="my-react-select-container"
											theme={theme => ({
												...theme,
												borderRadius: '6px',
												colors: {
													...theme.colors,
													primary25: '#b3b3b3',
													primary: 'black',
													neutral80: "data-theme-version" === "dark" ? 'white' : ''
												},
											})}
										/>
									</div>
									<div className="form-group mb-3 col-md-6">
										<label className="text-black font-w500">Group Name <span className="text-danger">*</span></label>
										<input
											type="text"
											className="form-control"
											placeholder="Group Name"
											value={groupName}
											readOnly
										/>
									</div>
									<div className="form-group mb-3 col-md-6">
										<label className="text-black font-w500">Item Manual Code</label>
										<input
											type="text"
											className="form-control"
											value={manualCode}
											onChange={e => setManualCode(e.target.value)}
											placeholder="Item Manual Code"
											maxLength="20"
										/>
									</div>
									<div className="form-group mb-3 col-md-6">
										<label className="text-black font-w500">Display Name <span className="text-danger">*</span></label>
										<input
											type="text"
											className="form-control"
											value={displayName}
											onChange={e => setDisplayName(e.target.value)}
											placeholder="Display Name"
											maxLength="100"
										/>
									</div>
									<div className="form-group mb-3 col-md-6">
										<label className="text-black font-w500">Unit Name <span className="text-danger">*</span></label>
										<Select
											defaultValue={unitCode}
											onChange={setUnitCode}
											value={unitCode}
											options={unitOptions.map((option) => ({
												label: option.um_display_name,
												value: option.um_unit_code,
											}))}
											showContent="true"
											open="true"
											className="my-react-select-container"
											theme={theme => ({
												...theme,
												borderRadius: '6px',
												colors: {
													...theme.colors,
													primary25: '#b3b3b3',
													primary: 'black',
													neutral80: "data-theme-version" === "dark" ? 'white' : ''
												},
											})}
										/>
									</div>
									<div className="form-group mb-3 col-md-6">
										<label className="text-black font-w500">HSN Code</label>
										<input
											type="text"
											className="form-control"
											value={hsnCode}
											onChange={e => setHsnCode(e.target.value)}
											onKeyPress={(e) => udfnHsnCodeValidation(e)}
											placeholder="HSN Code"
											minLength="6"
											maxLength="16"
										/>
									</div>
									<div className="form-group mb-3 col-md-6">
										<label className="text-black font-w500">Tax (%) <span className="text-danger">*</span></label>
										<Select
											defaultValue={taxPer}
											onChange={setTaxPer}
											value={taxPer}
											options={taxOptions.map((option) => ({
												label: option.tm_tax_per,
												value: option.tm_tax_per,
											}))}
											showContent="true"
											open="true"
											className="my-react-select-container"
											theme={theme => ({
												...theme,
												borderRadius: '6px',
												colors: {
													...theme.colors,
													primary25: '#b3b3b3',
													primary: 'black',
													neutral80: "data-theme-version" === "dark" ? 'white' : ''
												},
											})}
										/>
									</div>
									<div className="form-group mb-3 col-md-6">
										<label className="text-black font-w500">Discount (%)</label>
										<input
											type="number"
											className="form-control"
											value={discPer}
											onChange={e => setDiscPer(e.target.value)}
											onKeyPress={(e) => udfnDiscountValidation(e)}
											onKeyDown={handleKeyDown}
											placeholder="Discount %"
											maxLength=""

										/>
									</div>
									{editFlag === true ?
										<div className="form-group col-md-6">
											<fieldset className="form-group mt-5">
												<div className="row">
													<label className="col-form-label col-sm-3 pt-1 text-black font-w300">
														Status
													</label>
													<div className="col-sm-3">
														<div className="form-check checkbox-success">
															{status === 1 ? <input
																className="form-check-input success"
																type="radio"
																name="gridRadios"
																onClick={() => setStatus(1)}
																onKeyDown={handleKeyDown}
																defaultChecked
															/> :
																<input
																	className="form-check-input success"
																	type="radio"
																	name="gridRadios"
																	onClick={() => setStatus(1)}
																	onKeyDown={handleKeyDown}
																/>}
															<label className="form-check-label">
																Active
															</label>
														</div>
													</div>
													<div className="col-sm-5">
														<div className="form-check checkbox-danger">
															{status === 2 ?
																<input
																	className="form-check-input"
																	type="radio"
																	name="gridRadios"
																	onClick={() => setStatus(2)}
																	onKeyDown={handleKeyDown}
																	defaultChecked
																/> :
																<input
																	className="form-check-input"
																	type="radio"
																	name="gridRadios"
																	onClick={() => setStatus(2)}
																	onKeyDown={handleKeyDown}
																/>}
															<label className="form-check-label">
																Inactive
															</label>
														</div>
													</div>
												</div>
											</fieldset>
										</div>
										: ''}
								</div>
							</form>
						</div>
					</Modal.Body>
					<Modal.Footer>
						<Button
							variant="danger"
							className="me-2"
							onClick={() => udfnClearForm()}
						>
							Discard
						</Button>
						<Button variant="whatsapp" ref={buttonRef} onClick={udfnFormValidate} disabled={buttonFlag ? true : false}>
							{editFlag === true ? 'Update' : 'Save'}
						</Button>
					</Modal.Footer>
				</Modal>
			</LoadingOverlay>
		</>
	)

}
export default ItemMaster;