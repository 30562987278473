import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTable, useGlobalFilter, useFilters, useSortBy, usePagination } from 'react-table';
import { COLUMNS } from '../Filters/Salesman/SalesmanColumns';
import { Link } from "react-router-dom";
import { Badge, Button, Modal } from 'react-bootstrap';
import '../Filters/Common/filtering.css';
import ERROR_MESSAGE from '../Validation/ErrorMessage';
import FORM_VALIDATION from '../Validation/FormValidation';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import swal from "sweetalert";
import { getLoggedInUser, getRefreshToken } from "../../../services/AuthService"
import MASTER_API from '../../Api/MasterApi';
import { UserLogout } from '../../layouts/nav/Logout';
import axios from 'axios';
import COMMONVARIABLES from '../Utilities/constants';
import LoadingOverlay from 'react-loading-overlay-ts';
import { UncontrolledTooltip } from "reactstrap"
import Select from "react-select";
import COMMON_API from '../../Api/CommonApi';

export const SalesmanMaster = () => {
	const user = getLoggedInUser();

	const BASE_URL = process.env.REACT_APP_BASE_URL;
	const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } };

	const [isLoading, setIsLoading] = useState(false);
	const [buttonFlag, setButtonFlag] = useState(false);
	const [editFlag, setEditFlag] = useState(false);

	const [salesmanList, setSalesmanList] = useState([]);
	const [salesmanTotalCount, setSalesmanTotalCount] = useState(0);
	const [addCard, setAddCard] = useState(false);

	const [salesManCode, setSalesManCode] = useState(0);
	const [salesManName, setSalesManName] = useState('');
	const [salesManMannualCode, setSalesManMannualCode] = useState('');
	const [salesManShortName, setSalesManShortName] = useState('');
	const [salesManDesignation, setSalesManDesignation] = useState('');
	const [salesManSection, setSalesManSection] = useState('');
	const [salesManFloor, setSalesManFloor] = useState('');
	const [status, setStatus] = useState(1);
	const [branchOptions, setBranchOptions] = useState([])
	const [branch, setBranch] = useState('')


	const columns = useMemo(() => COLUMNS, [])
	const data = salesmanList
	const tableInstance = useTable({
		columns,
		data,
		initialState: { pageIndex: 0 }
	}, useFilters, useGlobalFilter, useSortBy, usePagination)

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		prepareRow,
		state,
		page,
		gotoPage,
		pageCount,
		pageOptions,
		nextPage,
		previousPage,
		canNextPage,
		canPreviousPage,
	} = tableInstance

	const { pageIndex } = state

	const udfnClearForm = () => {
		setSalesManCode(0);
		setSalesManName('');
		setSalesManMannualCode('');
		setSalesManDesignation('');
		setSalesManShortName('');
		setSalesManSection('');
		setSalesManFloor('');
		setStatus(1)
		setBranch('')
		setAddCard(false);
		setEditFlag(false);
	};

	const udfnFormValidate = (event) => {
		event.preventDefault();
		var error = "";
		if (!salesManName.trim() || !salesManMannualCode.trim() || branch === '') {
			error = ERROR_MESSAGE.COMMON_REQUIRED;
		} else if (!FORM_VALIDATION('alpha_numeric', salesManMannualCode)) {
			error = ERROR_MESSAGE.SALESMAN_MASTER.SALESMAN_CODE_VALID;
		}
		else {
			error = '';
		}

		if (error === '') {
			udfnSalesmanCreate()
		} else {
			toast.error(error, { position: toast.POSITION.TOP_RIGHT });
		}
	};

	const udfnNameValidation = (event) => {
		var val = event.target.value.charAt(0).toUpperCase() + event.target.value.slice(1);
		event.target.value = val;
	};

	const udfnSalesmanList = async () => {
		setIsLoading(true)

		var apiUrl = BASE_URL + MASTER_API.SALESMAN_MASTER_LIST;

		const uploadData = {
			user_id: Number(user.id)
		};

		try {
			const response = await axios.post(apiUrl, uploadData, config);
			setIsLoading(false)
			if (response && response?.data?.body?.status === COMMONVARIABLES.STATUS.INVALIDUSER) {
				toast.error(response.data.body.message, { position: toast.POSITION.TOP_RIGHT });
				UserLogout();
			}

			if (response?.data?.body?.status === COMMONVARIABLES.STATUS.UNAUTHORIZED) {
				const refresh = await getRefreshToken();

				if (refresh) {
					if (Number(refresh) === COMMONVARIABLES.STATUS.EXPIRED_TOKEN) {
						UserLogout();
					} else {
						await udfnSalesmanList();
					}
				}
			}

			if (response?.data?.body?.status === COMMONVARIABLES.STATUS.SERVICE_CATCH_STATUS) {
				toast.error(ERROR_MESSAGE.API_ERROR.SERVICE_CATCH_ERROR, { position: toast.POSITION.TOP_RIGHT });
			}

			if (response?.data?.body?.salesmanList) {
				setSalesmanList(response.data.body.salesmanList);
				var salesmanListCount = response.data.body.salesmanList.filter(item => item.sm_status_code === 1).length;
				setSalesmanTotalCount(salesmanListCount);
				toast.success(response.data.body.message, { position: toast.POSITION.TOP_RIGHT });
				setIsLoading(false);
			} else {
				toast.error(response.data.body.message, { position: toast.POSITION.TOP_RIGHT });
			}
		} catch (error) {
			setIsLoading(false)
			// Handle error
			console.error("Salesman list API Error:", error);
			if (error.code === COMMONVARIABLES.STATUS.NETWORK_ERROR_STATUS) {
				toast.error(ERROR_MESSAGE.API_ERROR.NETWORK_ERROR, { position: toast.POSITION.TOP_RIGHT });
			}
			if (error.code === COMMONVARIABLES.STATUS.BAD_REQUEST_ERROR_STATUS) {
				toast.error(ERROR_MESSAGE.API_ERROR.BAD_REQUEST_ERROR, { position: toast.POSITION.TOP_RIGHT });
			}
		}
	};

	const udfnSalesmanCreate = async () => {
		setIsLoading(true);
		setButtonFlag(true);

		var uploadData = {};
		var apiUrl = '';
		if (editFlag === true) {
			uploadData = {
				user_id: Number(user.id),
				company_code: Number(user.company_code),
				sales_man_code: Number(salesManCode),
				sales_man_name: String(salesManName.trim()),
				salesman_mannual_code: String(salesManMannualCode.trim()),
				short_name: String(salesManShortName.trim()),
				designation: String(salesManDesignation.trim()),
				section: String(salesManSection.trim()),
				floor: String(salesManFloor.trim()),
				status_code: Number(status),
				branch_code: Number(branch.value)
			};
			apiUrl = BASE_URL + MASTER_API.SALESMAN_MASTER_UPDATE;
		} else {
			uploadData = {
				user_id: Number(user.id),
				company_code: Number(user.company_code),
				sales_man_name: String(salesManName.trim()),
				salesman_mannual_code: String(salesManMannualCode.trim()),
				short_name: String(salesManShortName.trim()),
				designation: String(salesManDesignation.trim()),
				section: String(salesManSection.trim()),
				floor: String(salesManFloor.trim()),
				status_code: Number(1),
				branch_code: Number(branch.value)
			};
			apiUrl = BASE_URL + MASTER_API.SALESMAN_MASTER_CREATE;
		}

		try {
			const response = await axios.post(apiUrl, uploadData, config);
			setIsLoading(false);
			setButtonFlag(false);

			if (response && response?.data?.body?.status === COMMONVARIABLES.STATUS.INVALIDUSER) {
				toast.error(response.data.body.message, { position: toast.POSITION.TOP_RIGHT });
				UserLogout();
			}
			if (response?.data?.body?.status === COMMONVARIABLES.STATUS.UNAUTHORIZED) {
				const refresh = await getRefreshToken();
				if (refresh) {
					if (Number(refresh) === COMMONVARIABLES.STATUS.EXPIRED_TOKEN) {
						UserLogout();
					} else {
						await udfnSalesmanCreate();
					}
				}
			}
			if (response && response?.data?.body?.status === COMMONVARIABLES.STATUS.EXISTS) {
				toast.error(response.data.body.message, { position: toast.POSITION.TOP_RIGHT });
			}
			if (response && response?.data?.body?.status === COMMONVARIABLES.STATUS.ALREADY_IN_USE) {
				toast.error(response.data.body.message, { position: toast.POSITION.TOP_RIGHT });
			}
			if (response?.data?.body?.status === COMMONVARIABLES.STATUS.SERVICE_CATCH_STATUS) {
				toast.error(ERROR_MESSAGE.API_ERROR.SERVICE_CATCH_ERROR, { position: toast.POSITION.TOP_RIGHT });
			}
			if (response?.data?.body?.status === COMMONVARIABLES.STATUS.SUCCESS) {
				toast.success(response.data.body.message, { position: toast.POSITION.TOP_RIGHT });
				udfnClearForm();
				setAddCard(editFlag === true ? false : true)
				await udfnSalesmanList();
			} else {
				toast.error(response.data.body.message, { position: toast.POSITION.TOP_RIGHT });
			}
		} catch (error) {
			// Handle error
			setIsLoading(false);
			setButtonFlag(false);
			console.error("Salesman create API Error:", error);
			if (error.code === COMMONVARIABLES.STATUS.NETWORK_ERROR_STATUS) {
				toast.error(ERROR_MESSAGE.API_ERROR.NETWORK_ERROR, { position: toast.POSITION.TOP_RIGHT });
			}
			if (error.code === COMMONVARIABLES.STATUS.BAD_REQUEST_ERROR_STATUS) {
				toast.error(ERROR_MESSAGE.API_ERROR.BAD_REQUEST_ERROR, { position: toast.POSITION.TOP_RIGHT });
			}
		}
	};

	const udfnEditLoad = async (row) => {
		setSalesManCode(row.sm_sales_man_code);
		setSalesManName(row.sm_sales_man_name);
		setSalesManMannualCode(row.sm_sales_man_mannual_code);
		setSalesManShortName(row.sm_short_name);
		setSalesManDesignation(row.sm_designation);
		setSalesManSection(row.sm_section);
		setSalesManFloor(row.sm_floor);
		setStatus(row.sm_status_code);
		if (row.sm_branch_code !== null) {
			setBranch({ label: row.branch_name, value: row.sm_branch_code });
		}
		setAddCard(true);
		setEditFlag(true);
	};

	const udfnDelete = async (sales_man_code) => {
		swal({
			title: "Are you sure?",
			text:
				"Once deleted, you will not be able to recover this Salesman!",
			icon: "warning",
			buttons: true,
			dangerMode: true,
		}).then((willDelete) => {
			if (willDelete) {
				udfnSalesmanDelete(sales_man_code);
			} else {
				swal("Your Salesman is safe!");
			}
		})
	}

	const udfnSalesmanDelete = async (sales_man_code) => {
		setIsLoading(true);
		var apiUrl = BASE_URL + MASTER_API.SALESMAN_MASTER_DELETE;

		const uploadData = {
			user_id: Number(user.id),
			company_code: Number(user.company_code),
			sales_man_code: Number(sales_man_code),
		};

		try {
			const response = await axios.post(apiUrl, uploadData, config);
			setIsLoading(false);

			if (response && response?.data?.body?.status === COMMONVARIABLES.STATUS.INVALIDUSER) {
				toast.error(response.data.body.message, { position: toast.POSITION.TOP_RIGHT });
				UserLogout();
			}

			if (response?.data?.body?.status === COMMONVARIABLES.STATUS.UNAUTHORIZED) {
				const refresh = await getRefreshToken();

				if (refresh) {
					if (Number(refresh) === COMMONVARIABLES.STATUS.EXPIRED_TOKEN) {
						UserLogout();
					} else {
						await udfnSalesmanDelete();
					}
				}
			}

			if (response && response?.data?.body?.status === COMMONVARIABLES.STATUS.EXISTS) {
				toast.error(response.data.body.message, { position: toast.POSITION.TOP_RIGHT });
			}
			if (response?.data?.body?.status === COMMONVARIABLES.STATUS.SERVICE_CATCH_STATUS) {
				toast.error(ERROR_MESSAGE.API_ERROR.SERVICE_CATCH_ERROR, { position: toast.POSITION.TOP_RIGHT });
			}
			if (response?.data?.body?.status === COMMONVARIABLES.STATUS.SUCCESS) {
				swal(response.data.body.message, {
					icon: "success",
				});
				await udfnSalesmanList();
			} else {
				toast.error(response.data.body.message, { position: toast.POSITION.TOP_RIGHT });
			}
		} catch (error) {
			// Handle error
			setIsLoading(false);
			console.error("Salesman Delete API Error:", error);
			if (error.code === COMMONVARIABLES.STATUS.NETWORK_ERROR_STATUS) {
				toast.error(ERROR_MESSAGE.API_ERROR.NETWORK_ERROR, { position: toast.POSITION.TOP_RIGHT });
			}
			if (error.code === COMMONVARIABLES.STATUS.BAD_REQUEST_ERROR_STATUS) {
				toast.error(ERROR_MESSAGE.API_ERROR.BAD_REQUEST_ERROR, { position: toast.POSITION.TOP_RIGHT });
			}
		}
	};

	const buttonRef = useRef(null);

	const handleKeyDown = (event) => {
		// Check if the pressed key is the Tab key (key code 9)
		if (event.keyCode === 9) {
			// Prevent the default Tab behavior to prevent losing focus from the text input
			event.preventDefault();

			// Focus the button element
			buttonRef.current.focus();
		}
	};

	const udfnUpperCase = (event) => {
		var val = event.target.value.toUpperCase()
		event.target.value = val;
	};

	const udfnSalesmanCode = (event) => {
		const regex = /^[a-zA-Z0-9]*$/;
		const input = event.target.value + event.key;
		if (!regex.test(input)) {
			event.preventDefault();
		}
	};


	const openCard = () => {
		udfnClearForm()
		setAddCard(true)
	}

	const udfnBranchData = async () => {
		setIsLoading(true)
		try {
			const branchParams = { user_id: Number(user?.id), status_code: Number(0) };

			const [branchData] = await Promise.all([
				axios.post(BASE_URL + COMMON_API.BRANCH_MASTER_LIST, branchParams, config)
			]);

			setIsLoading(false)
			if (branchData?.data?.body?.status === COMMONVARIABLES.STATUS.INVALIDUSER) {
				var errorMsg = branchData?.data?.body?.message;
				toast.error(errorMsg, { position: toast.POSITION.TOP_RIGHT });
				UserLogout();
			}

			if (branchData?.data?.body?.status === COMMONVARIABLES.STATUS.UNAUTHORIZED) {
				const refresh = await getRefreshToken();

				if (refresh) {
					if (Number(refresh) === COMMONVARIABLES.STATUS.EXPIRED_TOKEN) {
						UserLogout();
					} else {
						await udfnBranchData();
					}
				}
			}

			if (branchData?.data?.body?.branchList) {
				setBranchOptions(branchData?.data?.body?.branchList)
			}
		} catch (error) {
			setIsLoading(false)
			console.error('Error fetching master data:', error);
		}
	}

	useEffect(() => {
		udfnSalesmanList()
		udfnBranchData()
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<ToastContainer />
			<LoadingOverlay
				active={isLoading}
				spinner
				text='Loading ...'
			>
				<div className="card">
					<div className="card-header">
						<div className='col-sm-5'>
							<h4 className="card-title">Salesman Master </h4>
						</div>
						<div className='col-sm-3'>
							<p className="bgl-primary rounded p-2 text-center mb-2"><span className="text-black" >Total Active Salesman  :  </span><Badge bg='info badge-rounded' className='text-black font-w400 fs-18'>{salesmanTotalCount}</Badge></p>
						</div>
						<div className='col-sm-2 me-1'>
							<Link className="btn btn-primary mb-2" onClick={() => openCard()}>
								<i className="fa fa-plus"></i> &nbsp; Add Salesman
							</Link>
						</div>
					</div>
					<div className="card-body">
						<div className="table-responsive">

							<table {...getTableProps()} className="table filtering-table table-responsive-lg">
								<thead>
									{headerGroups.map(headerGroup => (
										<tr {...headerGroup.getHeaderGroupProps()}>
											<th>
												<p className='tbl-heading-txt'>S.NO.</p>
											</th>
											{headerGroup.headers.map((column, index) => (
												<th {...column.getHeaderProps()} key={index}>
													{column.render('Header')}
													{column.canFilter ? column.render('Filter') : null}
												</th>
											))}
											<th>
												<p className='tbl-heading-txt'>Actions</p>
											</th>
										</tr>
									))}
								</thead>
								<tbody {...getTableBodyProps()} className="" >
									{page.map((row, index) => {
										prepareRow(row)
										return (
											<tr key={index}>
												<td>{index + 1}</td>
												<td>{row.original.sm_sales_man_name}</td>
												<td>{row.original.sm_sales_man_mannual_code}</td>
												<td>{row.original.branch_name}</td>
												<td>{row.original.sm_short_name}</td>
												<td>{row.original.sm_designation}</td>
												<td>{row.original.sm_section}</td>
												<td>{row.original.sm_floor}</td>
												<td>
													{row.original.status === "Active" ? <Badge bg="" className='badge-success light'>
														<span className="me-2">
															<i className="ms-1 fa fa-check" />
														</span>
														{row.original.status}
													</Badge> : <Badge bg="" className='badge-primary light'>
														<span className="me-2">
															<i className="fa fa-times"></i>
														</span>
														{row.original.status}
													</Badge>
													}
												</td>
												<td>
													<Link className="btn btn-secondary	 shadow btn-xs sharp me-2"
														onClick={() => udfnEditLoad(row.original)}
													>
														<i className="fa fa-pencil" id={'edittooltip' + index}></i>
														<UncontrolledTooltip placement="top" target={'edittooltip' + index}>
															Edit
														</UncontrolledTooltip>
													</Link>
													<Link className="btn btn-danger shadow btn-xs sharp" onClick={() => udfnDelete(row.original.sm_sales_man_code)}>
														<i className="fa fa-trash" id={'deletetooltip' + index}></i>
														<UncontrolledTooltip placement="top" target={'deletetooltip' + index}>
															Delete
														</UncontrolledTooltip>
													</Link>
												</td>
											</tr>
										)
									})}
								</tbody>
							</table>
							<div className="d-flex justify-content-between">
								<span>
									Page{' '}
									<strong>
										{pageIndex + 1} of {pageOptions.length}
									</strong>{''}
								</span>
								<span className="table-index">
									Go to page : {' '}
									<input type="number"
										className="ms-2"
										defaultValue={pageIndex + 1}
										onChange={e => {
											const pageNumber = e.target.value ? Number(e.target.value) - 1 : 0
											gotoPage(pageNumber)
										}}
									/>
								</span>
							</div>
							<div className="text-center">
								<div className="filter-pagination  mt-3">
									<button className=" previous-button" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>{'<<'}</button>

									<button className="previous-button" onClick={() => previousPage()} disabled={!canPreviousPage}>
										Previous
									</button>
									<button className="next-button" onClick={() => nextPage()} disabled={!canNextPage}>
										Next
									</button>
									<button className=" next-button" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>{'>>'}</button>
								</div>
							</div>
						</div>
					</div>
				</div>
				<Modal className="fade bd-example-modal-lg" show={addCard} onHide={setAddCard} size="lg" backdrop="static">
					<Modal.Header>
						<h4 className="text-black">{editFlag === true ? 'Update Salesman' : 'Add Salesman'}</h4>
						<Button
							variant=""
							className="close"
							onClick={() => udfnClearForm()}
						>
							<span>&times;</span>
						</Button>
					</Modal.Header>
					<Modal.Body>
						<div className="basic-form">
							<form onSubmit={(e) => e.preventDefault()}>
								<div className="row">
									<div className="form-group mb-3 col-md-6">
										<label className="text-black font-w500">Salesman Name <span className="text-danger star-txt">*</span></label>
										<input
											type="text"
											className="form-control"
											value={salesManName}
											autoFocus
											onChange={(e) => setSalesManName(e.target.value)}
											onKeyUp={(e) => udfnNameValidation(e)}
											placeholder="Salesman Name"
											maxLength="100"
										/>
									</div>
									<div className="form-group mb-3 col-md-6">
										<label className="text-black font-w500">Branch Name <span className="text-danger star-txt">*</span></label>
										<Select
											defaultValue={branch}
											onChange={setBranch}
											options={branchOptions.map((option) => ({
												label: option?.bm_branch_name,
												value: option?.bm_branch_code,
											}))}
											isClearable
											showContent="true"
											open="true"
											className="my-react-select-container"
											theme={theme => ({
												...theme,
												borderRadius: '6px',
												colors: {
													...theme.colors,
													primary25: '#b3b3b3',
													primary: 'black',
													neutral80: "data-theme-version" === "dark" ? 'white' : ''
												},
											})}
										/>
									</div>
									<div className="form-group mb-3 col-md-6">
										<label className="text-black font-w500">Salesman Code <span className="text-danger star-txt">*</span></label>
										<input
											type="text"
											className="form-control"
											value={salesManMannualCode}
											onKeyUp={(e) => udfnUpperCase(e)}
											onKeyPress={(e) => udfnSalesmanCode(e)}
											onChange={(e) => setSalesManMannualCode(e.target.value)}
											placeholder="Salesman Code"
											maxLength="10"
										/>
									</div>
									<div className="form-group mb-3 col-md-6">
										<label className="text-black font-w500">Short Name</label>
										<input
											type="text"
											className="form-control"
											value={salesManShortName}
											onChange={(e) => setSalesManShortName(e.target.value)}
											onKeyUp={(e) => udfnNameValidation(e)}
											placeholder="Short Name"
											maxLength="50"
										/>
									</div>
									<div className="form-group mb-3 col-md-6">
										<label className="text-black font-w500">Designation</label>
										<input
											type="text"
											className="form-control"
											value={salesManDesignation}
											onChange={(e) => setSalesManDesignation(e.target.value)}
											placeholder="Designation"
											maxLength="100"
										/>
									</div>
									<div className="form-group mb-3 col-md-6">
										<label className="text-black font-w500">Section</label>
										<input
											type="text"
											className="form-control"
											value={salesManSection}
											onChange={(e) => setSalesManSection(e.target.value)}
											placeholder="Sections"
											maxLength="10"
										/>
									</div>
									<div className="form-group mb-3 col-md-6">
										<label className="text-black font-w500">Floor</label>
										<input
											type="text"
											className="form-control"
											value={salesManFloor}
											onChange={(e) => setSalesManFloor(e.target.value)}
											onKeyDown={handleKeyDown}
											placeholder="Floors"
											maxLength="10"
										/>
									</div>
									{editFlag === true ?
										<div className="form-group col-md-6">
											<fieldset className="form-group">
												<div className="row">
													<label className="col-form-label col-sm-3 pt-1 text-black font-w300">
														Status
													</label>
													<div className="col-sm-3">
														<div className="form-check checkbox-success">
															{status === 1 ? <input
																className="form-check-input success"
																type="radio"
																name="gridRadios"
																onClick={() => setStatus(1)}
																onKeyDown={handleKeyDown}
																defaultChecked
															/> :
																<input
																	className="form-check-input success"
																	type="radio"
																	name="gridRadios"
																	onClick={() => setStatus(1)}
																	onKeyDown={handleKeyDown}
																/>}
															<label className="form-check-label">
																Active
															</label>
														</div>
													</div>
													<div className="col-sm-5">
														<div className="form-check checkbox-danger">
															{status === 2 ?
																<input
																	className="form-check-input"
																	type="radio"
																	name="gridRadios"
																	onClick={() => setStatus(2)}
																	onKeyDown={handleKeyDown}
																	defaultChecked
																/> :
																<input
																	className="form-check-input"
																	type="radio"
																	name="gridRadios"
																	onClick={() => setStatus(2)}
																	onKeyDown={handleKeyDown}
																/>}
															<label className="form-check-label">
																Inactive
															</label>
														</div>
													</div>
												</div>
											</fieldset>
										</div>
										: ''}
								</div>
							</form>
						</div>
					</Modal.Body>
					<Modal.Footer>
						<Button
							variant="danger"
							className="me-2"
							onClick={() => udfnClearForm()}
						>
							Discard
						</Button>
						<Button variant="whatsapp" ref={buttonRef} onClick={udfnFormValidate} disabled={buttonFlag ? true : false}>
							{editFlag === true ? 'Update' : 'Save'}
						</Button>
					</Modal.Footer>
				</Modal>
			</LoadingOverlay>
		</>
	)

}
export default SalesmanMaster;