import React, { useMemo, useState, useEffect } from 'react';
import { useTable, useGlobalFilter, useFilters, useSortBy, usePagination } from 'react-table';
import { COLUMNS } from '../Filters/ItemwiseSales/ItemwiseSalesColumns';
import { Button, Accordion } from 'react-bootstrap';
import DateRangePicker from "react-bootstrap-daterangepicker";
import { Badge } from "react-bootstrap";
import '../Filters/Common/filtering.css';
import "bootstrap-daterangepicker/daterangepicker.css";
import { ToastContainer, toast } from "react-toastify";
import Select from "react-select";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { getLoggedInUser, getRefreshToken } from '../../../services/AuthService';
import { UserLogout } from '../../layouts/nav/Logout';
import COMMONVARIABLES from '../Utilities/constants';
import Formatting from '../Utilities/format';
import COMMON_API from '../../Api/CommonApi';
import MASTER_API from '../../Api/MasterApi';
import LoadingOverlay from 'react-loading-overlay-ts';
import moment from 'moment';
import ERROR_MESSAGE from '../Validation/ErrorMessage';
const ExcelJS = require('exceljs');

export const ItemwiseSalesReport = () => {
    const user = getLoggedInUser();
    const BASE_URL = process.env.REACT_APP_BASE_URL;

    const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } };

    const [salesList, setSalesList] = useState([]);
    const [salesListCount, setSalesListCount] = useState(0);
    const columns = useMemo(() => COLUMNS, [])
    const data = salesList
    const tableInstance = useTable({
        columns,
        data,
        initialState: { pageIndex: 0 }
    }, useFilters, useGlobalFilter, useSortBy, usePagination)

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        state,
        page,
        gotoPage,
        pageCount,
        pageOptions,
        nextPage,
        previousPage,
        canNextPage,
        canPreviousPage,
    } = tableInstance

    Date.prototype.toDateString = function () {
        var month = this.getMonth() + 1;
        var day = this.getDate();
        var year = this.getFullYear();
        return year + "-" + (month < 10 ? '0' : '') + month + '-' + (day < 10 ? '0' : '') + day;
    };

    const { pageIndex } = state

    const statusOptions = [
        { status_code: COMMONVARIABLES.DROPDOWN_VALUE.ACTIVE, status_name: COMMONVARIABLES.DROPDOWN_LABEL.ACTIVE },
        { status_code: COMMONVARIABLES.DROPDOWN_VALUE.CANCELLED, status_name: COMMONVARIABLES.DROPDOWN_LABEL.CANCELLED },
    ];
    const [branchOptions, setBranchOptions] = useState([])
    const [voucherTypeOptionsFiltered, setVoucherTypeOptionsFiltered] = useState([]);
    const [voucherTypeOptions, setVoucherTypeOptions] = useState([])
    const [groupOptions, setGroupOptions] = useState([]);
    const [categoryOptions, setCategoryOptions] = useState([]);
    const [categoryOptionsFiltered, setCategoryOptionsFiltered] = useState([]);
    const [itemOptions, setItemOptions] = useState([]);

    const [range, setRange] = useState(localStorage.getItem('itemWiseSalesReportRange') || null)

    var start_date = localStorage.getItem('itemWiseSalesReportRange') && new Date(localStorage.getItem('itemWiseSalesReportRange').split('-')[0]).toDateString();
    var end_date = localStorage.getItem('itemWiseSalesReportRange') && new Date(localStorage.getItem('itemWiseSalesReportRange').split('-')[1]).toDateString();

    const [rangeDate, setRangeDate] = useState(localStorage.getItem('itemWiseSalesReportRange') && localStorage.getItem('itemWiseSalesReportRange') !== "null" ? { startDate: new Date(start_date), endDate: new Date(end_date) } : { startDate: new Date(), endDate: new Date() })

    const [status, setStatus] = useState(JSON.parse(localStorage.getItem('itemWiseSalesReportStatus')) || "")
    const [branch, setBranch] = useState(JSON.parse(localStorage.getItem('itemWiseSalesReportBranchName')) || "")
    const [voucherType, setVoucherType] = useState(JSON.parse(localStorage.getItem('itemWiseSalesReportVoucherType')) || "")
    const [groupCode, setGroupCode] = useState(JSON.parse(localStorage.getItem('itemWiseSalesReportGroupName')) || '');
    const [categoryCode, setCategoryCode] = useState(JSON.parse(localStorage.getItem('itemWiseSalesReportCategoryName')) || '');
    const [itemCode, setItemCode] = useState(JSON.parse(localStorage.getItem('itemWiseSalesReportItemName')) || '');

    var estimate_status = false;
    if (localStorage.getItem('itemWiseSalesReportEstimate') === "true" || localStorage.getItem('posReportEstimate') === true) {
        estimate_status = true;
    } else if (localStorage.getItem('itemWiseSalesReportEstimate') === "false" || localStorage.getItem('posReportEstimate') === false) {
        estimate_status = false;
    }

    const [estimate, setEstimate] = useState(estimate_status || false)

    console.log(localStorage.getItem('itemWiseSalesReportCategoryName'));
    console.log(localStorage.getItem('itemWiseSalesReportGroupName'));

    const [isLoading, setIsLoading] = useState(false);

    const udfnItemwiseSalesData = async () => {
        setIsLoading(true)
        try {

            const groupParams = { user_id: Number(user?.id), status_code: Number(0) };
            const categoryParams = { user_id: Number(user?.id), status_code: Number(0) };
            const itemParams = { user_id: Number(user?.id), status_code: Number(0) };
            const branchParams = { user_id: Number(user?.id), status_code: Number(0) };
            const voucherTypeParams = {
                user_id: Number(user?.id), status_code: Number(0), branch_code: branch?.value === '' || branch?.value === undefined ? 0 : Number(branch?.value)
            };

            const [groupData, categoryData, itemData, branchData, voucherTypeData] = await Promise.all([
                axios.post(BASE_URL + COMMON_API.GET_GROUP_LIST, groupParams, config),
                axios.post(BASE_URL + COMMON_API.CATEGORY_LIST, categoryParams, config),
                axios.post(BASE_URL + COMMON_API.ITEM_MASTER_LIST, itemParams, config),
                axios.post(BASE_URL + COMMON_API.BRANCH_MASTER_LIST, branchParams, config),
                axios.post(BASE_URL + COMMON_API.VOUCHER_TYPE_LIST, voucherTypeParams, config)
            ]);

            setIsLoading(false)

            if (groupData?.data?.body?.status === COMMONVARIABLES.STATUS.INVALIDUSER || categoryData?.data?.body?.status === COMMONVARIABLES.STATUS.INVALIDUSER || itemData?.data?.body?.status === COMMONVARIABLES.STATUS.INVALIDUSER || branchData?.data?.body?.status === COMMONVARIABLES.STATUS.INVALIDUSER || voucherTypeData?.data?.body?.status === COMMONVARIABLES.STATUS.INVALIDUSER) {
                var errorMsg = groupData?.data?.body?.message || categoryData?.data?.body?.message || itemData?.data?.body?.message || branchData?.data?.body?.message || voucherTypeData?.data?.body?.message;
                toast.error(errorMsg, { position: toast.POSITION.TOP_RIGHT });
                UserLogout();
            }

            if (groupData?.data?.body?.status === COMMONVARIABLES.STATUS.UNAUTHORIZED || categoryData?.data?.body?.status === COMMONVARIABLES.STATUS.UNAUTHORIZED || itemData?.data?.body?.status === COMMONVARIABLES.STATUS.UNAUTHORIZED || branchData?.data?.body?.status === COMMONVARIABLES.STATUS.UNAUTHORIZED || voucherTypeData?.data?.body?.status === COMMONVARIABLES.STATUS.UNAUTHORIZED) {
                const refresh = await getRefreshToken();
                if (refresh) {
                    if (Number(refresh) === COMMONVARIABLES.STATUS.EXPIRED_TOKEN) {
                        UserLogout();
                    } else {
                        await udfnItemwiseSalesData();
                    }
                }
            }

            if (groupData?.data?.body?.groupList || categoryData?.data?.body?.categoryList || itemData?.data?.body?.itemList || branchData?.data?.body?.branchList || voucherTypeData?.data?.body?.voucherTypeList) {
                setGroupOptions(groupData?.data?.body?.groupList)
                setCategoryOptions(categoryData?.data?.body?.categoryList)
                setCategoryOptionsFiltered(categoryData?.data?.body?.categoryList)
                setItemOptions(itemData?.data?.body?.itemList)
                setBranchOptions(branchData?.data?.body?.branchList)
                setVoucherTypeOptions(voucherTypeData?.data?.body?.voucherTypeList)
                setVoucherTypeOptionsFiltered(voucherTypeData?.data?.body?.voucherTypeList)
                setVoucherType(JSON.parse(localStorage.getItem('itemWiseSalesReportVoucherType')) || "")
            }
        } catch (error) {
            setIsLoading(false)
            console.error('Error fetching master data:', error);
        }
    }

    async function udfnItemwiseSalesList() {
        try {
            setIsLoading(true)
            setSalesList([])
            var varFromDate = new Date().toDateString()
            var varToDate = new Date().toDateString()
            if (range !== "null" && range !== null) {
                varFromDate = new Date(range.split('-')[0]).toDateString()
                varToDate = new Date(range.split('-')[1]).toDateString()
            }

            const params = {
                user_id: Number(user?.id),
                from_date: varFromDate,
                to_date: varToDate,
                group_code: groupCode?.value === '' || groupCode?.value === undefined ? 0 : groupCode?.value,
                category_code: categoryCode?.value === '' || categoryCode?.value === undefined ? 0 : categoryCode?.value,
                item_code: itemCode?.value === '' || itemCode?.value === undefined ? 0 : itemCode?.value,
                branch_code: branch?.value === '' || branch?.value === undefined ? 0 : branch?.value,
                voucher_type_code: voucherType?.value === '' || voucherType?.value === undefined ? 0 : voucherType?.value,
                status_code: status?.value === '' || status?.value === undefined ? 0 : status?.value,
                bill_type: estimate ? 0 : 1
            };

            const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } };
            const [salesList] = await Promise.all([
                axios.post(BASE_URL + MASTER_API.ITEMWISE_SALES_LIST, params, config),
            ]);
            setIsLoading(false)

            if (salesList && salesList?.data?.body?.status === COMMONVARIABLES.STATUS.INVALIDUSER) {
                toast.error(salesList?.data?.body?.message, { position: toast.POSITION.TOP_RIGHT });
                UserLogout();
            }

            if (salesList?.data?.body?.status === COMMONVARIABLES.STATUS.UNAUTHORIZED) {

                const refresh = await getRefreshToken();

                if (refresh) {
                    if (Number(refresh) === COMMONVARIABLES.STATUS.EXPIRED_TOKEN) {
                        UserLogout();
                    } else {
                        await udfnItemwiseSalesList();
                    }
                }
            }

            else if (salesList?.data?.body?.billDetails) {
                setSalesList(salesList?.data?.body?.billDetails)
                var total = 0;
                salesList?.data?.body?.billDetails.forEach(row => {
                    total = total + row.amount;
                });
                setSalesListCount(COMMONVARIABLES.India_Rupee_Formatter_Digits(Formatting('two_decimal', total)))
            }

        } catch (error) {
            setIsLoading(false)
            console.error('Error fetching master data:', error);
        }
    }

    const udfnEstimateValue = (value) => {
        var returnVal = value === 'true' ? false : true;
        setEstimate(returnVal);
    };

    useEffect(() => {
        if (groupCode?.value > 0) {
            setCategoryCode(JSON.parse(localStorage.getItem('itemWiseSalesReportCategoryName')) || '')
            var categoryOptionsArray = categoryOptions
            var filtArray = categoryOptionsArray.filter(item => item.gm_group_code === groupCode?.value)
            setCategoryOptionsFiltered(filtArray)
        } else {
            setCategoryOptionsFiltered(categoryOptions)
        }
    }, [groupCode?.value]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        var bill_type = estimate ? 0 : 1;
        if (branch?.value > 0) {
            // setVoucherType('')
            setVoucherType(JSON.parse(localStorage.getItem('itemWiseSalesReportVoucherType')) || "")
            var voucherTypeOptionsArray = voucherTypeOptions
            var filtArray = voucherTypeOptionsArray.filter(item => item.vt_branch_code === branch?.value && item.vt_stock_applicable === bill_type)
            setVoucherTypeOptionsFiltered(filtArray)
        } else {
            var voucherTypeOptionsArray = voucherTypeOptions
            var filtArray = voucherTypeOptionsArray.filter(item => item.vt_stock_applicable === bill_type)
            setVoucherTypeOptionsFiltered(filtArray)
        }
    }, [branch?.value, estimate]); // eslint-disable-line react-hooks/exhaustive-deps


    useEffect(() => {
        setSalesList([])
    }, [range, groupCode?.value, categoryCode?.value, itemCode?.value, status?.value, branch?.value, voucherType?.value]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        udfnItemwiseSalesData()
        udfnItemwiseSalesList()
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const exportToExcel = async () => {
        setIsLoading(true)
        const arraydata = salesList
        var listCount = salesList.length

        if (listCount > 0) {
            // colorWiseTotalDispatch
            let Item_Wise_Sales_Report = []

            let total_rate = 0
            let total_quantity = 0
            let total_cgst = 0
            let total_sgst = 0
            let total_igst = 0
            let total_discount_amount = 0
            let total_amount = 0

            Item_Wise_Sales_Report = arraydata.map((e, index) => {
                total_rate = total_rate + e.rate
                total_quantity = total_quantity + e.itemqty
                total_cgst = total_cgst + e.cgstamt
                total_sgst = total_sgst + e.sgstamt
                total_igst = total_igst + e.igstamt
                total_discount_amount = total_discount_amount + e.discamt
                total_amount = total_amount + e.amount

                return {
                    "S.No.": index + 1, "Bill Date": moment(new Date(e.ps_billing_date)).format("DD-MM-YYYY "), "Bill No.": e.ps_bill_no, "ITEM GROUP": e.gm_group_name, "ITEM CATEGORY": e.cm_category_name, "ITEM NAME": e.itemname, 'ITEM CODE': e.itemcode, 'RATE': COMMONVARIABLES.India_Rupee_Formatter_Digits(Formatting('two_decimal', e.rate)), "QUANTITY": e.itemqty, "CGST": COMMONVARIABLES.India_Rupee_Formatter_Digits(Formatting('two_decimal', e.cgstamt)), "SGST": COMMONVARIABLES.India_Rupee_Formatter_Digits(Formatting('two_decimal', e.sgstamt)), "IGST": COMMONVARIABLES.India_Rupee_Formatter_Digits(Formatting('two_decimal', e.igstamt)), "DISCOUNT AMOUNT": COMMONVARIABLES.India_Rupee_Formatter_Digits(Formatting('two_decimal', e.discamt)), "AMOUNT": COMMONVARIABLES.India_Rupee_Formatter_Digits(Formatting('two_decimal', e.amount))
                }
            })

            const data_array = []
            Item_Wise_Sales_Report.forEach((row) => {
                data_array.push(Object.values(row))
            })

            var varFromDate = new Date().toDateString()
            var varToDate = new Date().toDateString()
            if (range !== "null" && range !== null) {
                varFromDate = new Date(range.split('-')[0]).toDateString()
                varToDate = new Date(range.split('-')[1]).toDateString()
            }

            const title = 'Itemwise Sales Report On '
            const frontside = '('
            const backside = ')'
            const tofrontside = '  to ('
            const reportData = {
                title: title + frontside + moment(new Date(varFromDate)).format("DD-MM-YYYY ") + backside + tofrontside + moment(new Date(varToDate)).format("DD-MM-YYYY") + backside,
                data: data_array,
                headers: Object.keys(Item_Wise_Sales_Report[0])
            }

            const workbook = new ExcelJS.Workbook()
            const worksheet = workbook.addWorksheet('itemwise-sales-report')


            worksheet.mergeCells('A1', 'N1')
            const titleRow = worksheet.getCell('A1')
            titleRow.value = reportData['title']
            titleRow.font = {
                name: 'Calibri',
                size: 16,
                bold: true,
                color: { argb: '000000' }
            }
            titleRow.alignment = { vertical: 'middle', horizontal: 'center' }

            worksheet.mergeCells('A2', 'G2')
            const group_head = 'Group Name : '
            const group_val = groupCode.label ? groupCode.label : 'All'
            const groupRow = worksheet.getCell('A2')
            groupRow.value = group_head + group_val
            groupRow.font = {
                name: 'Calibri',
                size: 12,
                color: { argb: '00000' }
            }
            groupRow.alignment = { vertical: 'left', horizontal: 'left' }

            worksheet.mergeCells('H2', 'N2')
            const category_head = 'Category Name : '
            const category_val = categoryCode.label ? categoryCode.label : 'All'
            const categoryRow = worksheet.getCell('H2')
            categoryRow.value = category_head + category_val
            categoryRow.font = {
                name: 'Calibri',
                size: 12,
                color: { argb: '00000' }
            }
            categoryRow.alignment = { vertical: 'left', horizontal: 'left' }

            worksheet.mergeCells('A3', 'G3')
            const item_head = 'Item Name : '
            const item_val = itemCode.label ? itemCode.label : 'All'
            const itemRow = worksheet.getCell('A3')
            itemRow.value = item_head + item_val
            itemRow.font = {
                name: 'Calibri',
                size: 12,
                color: { argb: '00000' }
            }
            itemRow.alignment = { vertical: 'left', horizontal: 'left' }


            worksheet.mergeCells('H3', 'N3')
            const branch_head = 'Branch Name : '
            const branch_val = branch.label ? branch.label : 'All'
            const branchRow = worksheet.getCell('H3')
            branchRow.value = branch_head + branch_val
            branchRow.font = {
                name: 'Calibri',
                size: 12,
                color: { argb: '00000' }
            }
            branchRow.alignment = { vertical: 'left', horizontal: 'left' }

            worksheet.mergeCells('A4', 'G4')
            const estimate_head = 'Type : '
            const estimate_val = estimate === true ? 'Estimate' : 'Bill'
            const estimateRow = worksheet.getCell('A4')
            estimateRow.value = estimate_head + estimate_val
            estimateRow.font = {
                name: 'Calibri',
                size: 12,
                color: { argb: '00000' }
            }
            estimateRow.alignment = { vertical: 'left', horizontal: 'left' }


            worksheet.mergeCells('H4', 'N4')
            const voucher_type_head = 'Voucher Type : '
            const voucher_type_val = voucherType.label ? voucherType.label : 'All'
            const voucherTypeRow = worksheet.getCell('H4')
            voucherTypeRow.value = voucher_type_head + voucher_type_val
            voucherTypeRow.font = {
                name: 'Calibri',
                size: 12,
                color: { argb: '00000' }
            }
            voucherTypeRow.alignment = { vertical: 'left', horizontal: 'left' }

            worksheet.mergeCells('A5', 'G5')
            const status_head = 'Status : '
            const status_val = status.label ? status.label : 'All'
            const statusRow = worksheet.getCell('A5')
            statusRow.value = status_head + status_val
            statusRow.font = {
                name: 'Calibri',
                size: 12,
                color: { argb: '00000' }
            }
            statusRow.alignment = { vertical: 'left', horizontal: 'left' }

            const headerRow = worksheet.addRow(reportData['headers'])
            headerRow.eachCell((cell) => {
                cell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'ff8b3d' },
                    bgColor: { argb: 'ff8b3d' }
                }
                cell.font = {
                    bold: true,
                    color: { argb: 'FFFFFF' },
                    size: 12
                }
            })

            data_array.forEach(d => {
                worksheet.addRow(d)
            })

            var format_total_rate = COMMONVARIABLES.India_Rupee_Formatter_Digits(Formatting('two_decimal', total_rate))
            var format_total_cgst = COMMONVARIABLES.India_Rupee_Formatter_Digits(Formatting('two_decimal', total_cgst))
            var format_total_sgst = COMMONVARIABLES.India_Rupee_Formatter_Digits(Formatting('two_decimal', total_sgst))
            var format_total_igst = COMMONVARIABLES.India_Rupee_Formatter_Digits(Formatting('two_decimal', total_igst))
            var format_total_discount_amount = COMMONVARIABLES.India_Rupee_Formatter_Digits(Formatting('two_decimal', total_discount_amount))
            var format_total_amount = COMMONVARIABLES.India_Rupee_Formatter_Digits(Formatting('two_decimal', total_amount))

            const total = ['', '', '', '', '', '', 'Total', format_total_rate, total_quantity, format_total_cgst, format_total_sgst, format_total_igst, format_total_discount_amount, format_total_amount]
            const footerRow = worksheet.addRow(total)
            footerRow.eachCell((cell) => {
                cell.font = {
                    bold: true,
                    color: { argb: '000000' },
                    size: 14
                }
            })
            footerRow.alignment = { vertical: 'middle', horizontal: 'right' }

            var array_data_count = data_array.length;

            var starting_cell = 7;
            var last_cell = array_data_count + starting_cell

            for (let i = starting_cell; i <= last_cell; i++) {
                const rateColumn = worksheet.getCell('H' + i)
                const cgstColumn = worksheet.getCell('J' + i)
                const sgstColumn = worksheet.getCell('K' + i)
                const igstColumn = worksheet.getCell('L' + i)
                const discountAmountColumn = worksheet.getCell('M' + i)
                const totalAmountColumn = worksheet.getCell('N' + i)
                rateColumn.alignment = { vertical: 'middle', horizontal: 'right' }
                cgstColumn.alignment = { vertical: 'middle', horizontal: 'right' }
                sgstColumn.alignment = { vertical: 'middle', horizontal: 'right' }
                igstColumn.alignment = { vertical: 'middle', horizontal: 'right' }
                discountAmountColumn.alignment = { vertical: 'middle', horizontal: 'right' }
                totalAmountColumn.alignment = { vertical: 'middle', horizontal: 'right' }
            }

            worksheet.getColumn(1).width = 5
            worksheet.getColumn(2).width = 20
            worksheet.getColumn(3).width = 20
            worksheet.getColumn(4).width = 20
            worksheet.getColumn(5).width = 20
            worksheet.getColumn(6).width = 25
            worksheet.getColumn(7).width = 15
            worksheet.getColumn(8).width = 15
            worksheet.getColumn(9).width = 15
            worksheet.getColumn(10).width = 15
            worksheet.getColumn(11).width = 15
            worksheet.getColumn(12).width = 23
            worksheet.getColumn(13).width = 20
            worksheet.getColumn(14).width = 15

            // Save the workbook
            const buffer = await workbook.xlsx.writeBuffer()
            const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })

            const url = URL.createObjectURL(blob)
            const a = document.createElement('a')
            a.href = url
            a.download = `Itemwise Sales Report.xlsx`
            document.body.appendChild(a)
            a.click()
            document.body.removeChild(a)
        } else {
            toast.error(ERROR_MESSAGE.EXPORT_TO_EXCEL_NO_RECORD, { position: toast.POSITION.TOP_RIGHT });
        }
        setIsLoading(false)
    }

    useEffect(() => {
        localStorage.setItem('itemWiseSalesReportRange', range)
        localStorage.setItem('itemWiseSalesReportGroupName', JSON.stringify(groupCode))
        localStorage.setItem('itemWiseSalesReportStatus', JSON.stringify(status))
        localStorage.setItem('itemWiseSalesReportEstimate', estimate)
        localStorage.setItem('itemWiseSalesReportBranchName', JSON.stringify(branch))
        localStorage.setItem('itemWiseSalesReportVoucherType', JSON.stringify(voucherType))
        localStorage.setItem('itemWiseSalesReportCategoryName', JSON.stringify(categoryCode))
        localStorage.setItem('itemWiseSalesReportItemName', JSON.stringify(itemCode))
    }, [range, groupCode, categoryCode, itemCode, branch, status, estimate, voucherType])  // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <ToastContainer />
            <LoadingOverlay
                active={isLoading}
                spinner
                text='Loading ...'
            >
                <div className="card">
                    <div className="card-header pt-3 pb-0">
                        <div className='col-sm-5'>
                            <h4 className="card-title">Itemwise Sales Report</h4>
                        </div>
                        <div className='cash-total col-sm-4 text-center'>
                            <Button variant="info" className="mb-2" onClick={() => exportToExcel()}>
                                <i className="fa fa-download" /> &nbsp; Export
                            </Button>
                        </div>
                        <div className='col-sm-2 mb-2'>
                            <div className="bg-secondary coin-holding flex-wrap py-0 m-0">
                                <div className="mb-2 coin-bx">
                                    <div className="d-flex align-items-center">
                                        <div>
                                            <i className="fa fa-rupee udfn-rupee-icon"></i>
                                        </div>
                                        <div className="ms-3">
                                            <h4 className="coin-font font-w600 mb-0 text-white mt-2">Total</h4>
                                            <p className="mb-0 text-white op-6">{salesListCount}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card-body">
                        <Accordion className="accordion accordion-rounded-stylish accordion-bordered  accordion-active-header" defaultActiveKey="0">
                            <Accordion.Item>
                                <Accordion.Header className="accordion-header accordion-header-primary">
                                    <h5>Filters</h5>
                                    <span className="accordion-header-indicator "></span>
                                </Accordion.Header>
                                <Accordion.Collapse className="accordion__body">
                                    <div className="accordion-body">
                                        <div className="row">
                                            <div className="col-md-3 mb-3">
                                                <div className="example rangeDatePicker">
                                                    <p className="mb-1">Select From Date And To Date</p>
                                                    <DateRangePicker
                                                        onApply={(e) => {
                                                            setRange(e.target.value)
                                                        }}
                                                        initialSettings={rangeDate}
                                                    >
                                                        <input type="text" className="form-control input-daterange-timepicker" styles={{ height: "90%" }} />
                                                    </DateRangePicker>
                                                </div>
                                            </div>
                                            <div className="col-md-3 mb-3">
                                                <p className="mb-1">Group Name</p>
                                                <Select
                                                    defaultValue={groupCode}
                                                    onChange={setGroupCode}
                                                    value={groupCode}
                                                    options={groupOptions.map((option) => ({
                                                        label: option.gm_group_name,
                                                        value: option.gm_group_code,
                                                    }))}
                                                    isClearable
                                                    showContent="true"
                                                    open="true"
                                                    className="my-react-select-container"
                                                    theme={theme => ({
                                                        ...theme,
                                                        borderRadius: '6px',
                                                        colors: {
                                                            ...theme.colors,
                                                            primary25: '#b3b3b3',
                                                            primary: 'black',
                                                            neutral80: "data-theme-version" === "dark" ? 'white' : ''
                                                        },
                                                    })}
                                                />
                                            </div>
                                            <div className="col-md-3 mb-3">
                                                <p className="mb-1">Category Name</p>
                                                <Select
                                                    defaultValue={categoryCode}
                                                    value={categoryCode}
                                                    onChange={setCategoryCode}
                                                    options={categoryOptionsFiltered.map((option) => ({
                                                        label: option.cm_category_name,
                                                        value: option.cm_category_code,
                                                    }))}
                                                    isClearable
                                                    showContent="true"
                                                    open="true"
                                                    className="my-react-select-container"
                                                    theme={theme => ({
                                                        ...theme,
                                                        borderRadius: '6px',
                                                        colors: {
                                                            ...theme.colors,
                                                            primary25: '#b3b3b3',
                                                            primary: 'black',
                                                            neutral80: "data-theme-version" === "dark" ? 'white' : ''
                                                        },
                                                    })}
                                                />
                                            </div>
                                            <div className="col-md-3 mb-3">
                                                <p className="mb-1">Item Name</p>
                                                <Select
                                                    defaultValue={itemCode}
                                                    value={itemCode}
                                                    onChange={setItemCode}
                                                    options={itemOptions.map((option) => ({
                                                        label: option.im_item_name,
                                                        value: option.im_item_code,
                                                    }))}
                                                    isClearable
                                                    showContent="true"
                                                    open="true"
                                                    className="my-react-select-container"
                                                    theme={theme => ({
                                                        ...theme,
                                                        borderRadius: '6px',
                                                        colors: {
                                                            ...theme.colors,
                                                            primary25: '#b3b3b3',
                                                            primary: 'black',
                                                            neutral80: "data-theme-version" === "dark" ? 'white' : ''
                                                        },
                                                    })}
                                                />
                                            </div>
                                            <div className="col-md-3 mb-3">
                                                <p className="mb-1">Branch Name</p>
                                                <Select
                                                    defaultValue={branch}
                                                    onChange={setBranch}
                                                    options={branchOptions.map((option) => ({
                                                        label: option?.bm_branch_name,
                                                        value: option?.bm_branch_code,
                                                    }))}
                                                    isClearable
                                                    showContent="true"
                                                    open="true"
                                                    className="my-react-select-container"
                                                    theme={theme => ({
                                                        ...theme,
                                                        borderRadius: '6px',
                                                        colors: {
                                                            ...theme.colors,
                                                            primary25: '#b3b3b3',
                                                            primary: 'black',
                                                            neutral80: "data-theme-version" === "dark" ? 'white' : ''
                                                        },
                                                    })}
                                                />
                                            </div>
                                            <div className="col-md-2 mb-3">
                                                <p className="text-center mb-1">Estimate </p>
                                                <p className='text-center'>
                                                    <input
                                                        type="checkbox"
                                                        className="custom-control-input p-2"
                                                        value={estimate}
                                                        onChange={(e) => udfnEstimateValue(e.target.value)}
                                                        checked={estimate === true || estimate === 'true'}
                                                    />
                                                </p>
                                            </div>
                                            <div className="col-md-3 mb-3">
                                                <p className="mb-1">Voucher Type</p>
                                                <Select
                                                    defaultValue={voucherType}
                                                    onChange={setVoucherType}
                                                    options={voucherTypeOptionsFiltered.map((option) => ({
                                                        label: option?.voucher_type_name,
                                                        value: option?.voucher_type_code,
                                                    }))}
                                                    isClearable
                                                    showContent="true"
                                                    open="true"
                                                    className="my-react-select-container"
                                                    theme={theme => ({
                                                        ...theme,
                                                        borderRadius: '6px',
                                                        colors: {
                                                            ...theme.colors,
                                                            primary25: '#b3b3b3',
                                                            primary: 'black',
                                                            neutral80: "data-theme-version" === "dark" ? 'white' : ''
                                                        },
                                                    })}
                                                />
                                            </div>
                                            <div className="col-md-3 mb-3">
                                                <p className="mb-1">Status</p>
                                                <Select
                                                    defaultValue={status}
                                                    onChange={setStatus}
                                                    options={statusOptions.map((option) => ({
                                                        label: option?.status_name,
                                                        value: option?.status_code,
                                                    }))}
                                                    isClearable
                                                    showContent="true"
                                                    open="true"
                                                    className="my-react-select-container"
                                                    theme={theme => ({
                                                        ...theme,
                                                        borderRadius: '6px',
                                                        colors: {
                                                            ...theme.colors,
                                                            primary25: '#b3b3b3',
                                                            primary: 'black',
                                                            neutral80: "data-theme-version" === "dark" ? 'white' : ''
                                                        },
                                                    })}
                                                />
                                            </div>
                                            <div className="col-md-2 mt-3">
                                                <Button className="me-2" variant="whatsapp" style={{ marginTop: "6%" }} onClick={() => { udfnItemwiseSalesList() }}>
                                                    List{" "}
                                                    <span className="btn-icon-right">
                                                        <i className="fa fa-filter" />
                                                    </span>
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </Accordion.Collapse>
                            </Accordion.Item>
                        </Accordion>
                        <div className="table-responsive">
                            <div className='row'>
                                <div className='col-sm-9'>
                                    {/* <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} /> */}
                                </div>
                            </div>
                            <table {...getTableProps()} className="table filtering-table table-responsive-lg">
                                <thead>
                                    {headerGroups.map((headerGroup, index) => (
                                        <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                                            {/* <th>
                                                <p className='tbl-heading-txt'>S.NO.</p>
                                            </th> */}
                                            {headerGroup.headers.map((column, index) => (
                                                <>
                                                    {column.Header === 'Rate' || column.Header === 'Qty' || column.Header === 'CGST' || column.Header === 'SGST' || column.Header === 'IGST' || column.Header === 'Disc Amt' || column.Header === 'Amount' ?
                                                        <th className='text-end' key={index}>
                                                            {column.render('Header')}
                                                        </th>
                                                        :
                                                        <th key={index}>
                                                            {column.render('Header')}
                                                        </th>
                                                    }
                                                </>
                                            ))}
                                            {/* <th>
                                                <p className='tbl-heading-txt'>Actions</p>
                                            </th> */}
                                        </tr>
                                    ))}
                                </thead>
                                <tbody {...getTableBodyProps()} className="" >
                                    {page.map((row, index) => {
                                        return (
                                            <tr key={index}>
                                                <td width='1%'>{index + 1}</td>
                                                <td width='25%'>{moment(row.original.ps_billing_date).format('DD-MM-YYYY')}</td>
                                                <td>{row.original.ps_bill_no}</td>
                                                <td>{row.original.gm_group_name}</td>
                                                <td>{row.original.cm_category_name}</td>
                                                <td>{row.original.itemname}</td>
                                                <td>{row.original.qrcode}</td>
                                                <td width='25%' className='text-end td-width'><i className="fa fa-rupee udfn-rupee-icon rupeeicon"></i>{COMMONVARIABLES.India_Rupee_Formatter_Digits(Formatting('two_decimal', row.original.rate))}</td>
                                                <td width='5%' className='text-end'>{row.original.itemqty}</td>
                                                <td width='5%' className='text-end td-width'><i className="fa fa-rupee udfn-rupee-icon rupeeicon"></i>{COMMONVARIABLES.India_Rupee_Formatter_Digits(Formatting('two_decimal', row.original.cgstamt))}</td>
                                                <td width='5%' className='text-end td-width'><i className="fa fa-rupee udfn-rupee-icon rupeeicon"></i>{COMMONVARIABLES.India_Rupee_Formatter_Digits(Formatting('two_decimal', row.original.sgstamt))}</td>
                                                <td width='5%' className='text-end td-width'><i className="fa fa-rupee udfn-rupee-icon rupeeicon"></i>{COMMONVARIABLES.India_Rupee_Formatter_Digits(Formatting('two_decimal', row.original.igstamt))}</td>
                                                <td width='5%' className='text-end td-width'><i className="fa fa-rupee udfn-rupee-icon rupeeicon"></i>{COMMONVARIABLES.India_Rupee_Formatter_Digits(Formatting('two_decimal', row.original.discamt))}</td>
                                                <td width='5%' className='text-end td-width'><i className="fa fa-rupee udfn-rupee-icon rupeeicon"></i>{COMMONVARIABLES.India_Rupee_Formatter_Digits(Formatting('two_decimal', row.original.amount))}</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                            <div className='text-center'>
                                {salesList.length < 1 ? "No records found." : ""}
                            </div>
                            <div className="d-flex justify-content-between">
                                <span>
                                    Page{' '}
                                    <strong>
                                        {pageIndex + 1} of {pageOptions.length}
                                    </strong>{''}
                                </span>
                                <span className="table-index">
                                    Go to page : {' '}
                                    <input type="number"
                                        className="ms-2"
                                        defaultValue={pageIndex + 1}
                                        onChange={e => {
                                            const pageNumber = e.target.value ? Number(e.target.value) - 1 : 0
                                            gotoPage(pageNumber)
                                        }}
                                    />
                                </span>
                            </div>
                            <div className="text-center">
                                <div className="filter-pagination  mt-3">
                                    <button className=" previous-button" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>{'<<'}</button>

                                    <button className="previous-button" onClick={() => previousPage()} disabled={!canPreviousPage}>
                                        Previous
                                    </button>
                                    <button className="next-button" onClick={() => nextPage()} disabled={!canNextPage}>
                                        Next
                                    </button>
                                    <button className=" next-button" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>{'>>'}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </LoadingOverlay>
        </>
    )
}
export default ItemwiseSalesReport;