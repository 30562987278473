import React, { useMemo, useState, useEffect, useRef } from 'react';
import { Link } from "react-router-dom";
import { useTable, useGlobalFilter, useFilters, useSortBy, usePagination } from 'react-table';
import { COLUMNS } from '../Filters/VoucherType/VoucherTypeColumns';
import { GlobalFilter } from '../Filters/Common/GlobalFilter';
import { Modal, Button } from 'react-bootstrap';
import '../Filters/Common/filtering.css';
/// Bootstrap
import { Badge } from "react-bootstrap";
import Select from "react-select";
import ERROR_MESSAGE from '../Validation/ErrorMessage';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import swal from "sweetalert";
import axios from 'axios';
import {
    getLoggedInUser, getRefreshToken, getFinancialYear
} from '../../../services/AuthService';
import MASTER_API from '../../Api/MasterApi';
import { UserLogout } from '../../layouts/nav/Logout';
import COMMONVARIABLES from '../Utilities/constants';
import LoadingOverlay from 'react-loading-overlay-ts';
import COMMON_API from '../../Api/CommonApi';
import { UncontrolledTooltip } from "reactstrap"

const VoucherTypeMaster = () => {

    const [branchName, setBranchName] = useState({ label: 'Select Branch', value: 0 });
    const [voucherName, setVoucherName] = useState('');
    const [prefix, setPrefix] = useState('');
    const [suffix, setSuffix] = useState('');
    const [noOfDigits, setNoOfDigits] = useState('');
    const [startingNumber, setStartingNumber] = useState('');
    const [printTitle, setPrintTitle] = useState('');
    const [stockApplicable, setStockApplicable] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const [editFlag, setEditFlag] = useState(false);
    const [statusCode, setStatusCode] = useState(1);
    const [voucherTypeList, setVoucherTypeList] = useState([]);
    const [voucher_type_code, setVoucherTypeCode] = useState(1);
    const [buttonFlag, setButtonFlag] = useState(true);
    const [status, setStatus] = useState(false);
    const [options, setOptions] = useState([]);
    const [voucherTypeTotalCount, setVoucherTypeTotalCount] = useState('');
    const [voucherNumber, setVoucherNumber] = useState('');
    const [financialYear, setFinancialYear] = useState('');

    const columns = useMemo(() => COLUMNS, [])
    const data = voucherTypeList;
    const tableInstance = useTable({
        columns,
        data,
        initialState: { pageIndex: 0 }
    }, useFilters, useGlobalFilter, useSortBy, usePagination)

    const {
        getTableProps,
        getTableBodyProps,
        prepareRow,
        state,
        page,
        gotoPage,
        pageCount,
        pageOptions,
        nextPage,
        previousPage,
        canNextPage,
        canPreviousPage,
        setGlobalFilter,
    } = tableInstance

    const { globalFilter, pageIndex } = state

    //Modal box
    const [addCard, setAddCard] = useState(false);

    const udfnFromValidate = () => {

        var error = false;
        var errorMsg = '';
        // All Input Validation
        if (branchName.label === 'Select Branch' || !voucherName.trim() || !printTitle.trim() || startingNumber === '') {
            error = true;
            errorMsg = ERROR_MESSAGE.COMMON_REQUIRED;
        }
        if (!error) {
            udfnChangeStockApplicable();
        } else {
            toast.error(errorMsg, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    }

    const udfnNoOfDigitsValidation = (event) => {
        const regex = /^[0-9]*$/;
        const input = event.target.value + event.key;
        if (!regex.test(input) || input.length > 1) {
            event.preventDefault();
        }
    };

    const udfnStartingNumberValidation = (event) => {
        const regex = /^[0-9]*$/;
        const input = event.target.value + event.key;
        if (!regex.test(input) || input.length > noOfDigits) {
            event.preventDefault();
        }
    };

    const udfnNameValidation = (event) => {
        var val = event.target.value.charAt(0).toUpperCase() + event.target.value.slice(1);
        event.target.value = val;
    };

    const udfnVoucherTypeList = async () => {
        const user = getLoggedInUser();
        setFinancialYear(getFinancialYear());
        setIsLoading(true);
        const data = {
            user_id: user.id
        };
        const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } };

        try {
            const response = await axios.post(process.env.REACT_APP_BASE_URL + MASTER_API.VOUCHER_TYPE_MASTER_LIST, data, config);
            setIsLoading(false);
            if (response && response?.data?.body?.status === COMMONVARIABLES.STATUS.INVALIDUSER) {
                toast.error(response.data.body.message, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
                UserLogout();
            }

            if (response?.data?.body?.status === COMMONVARIABLES.STATUS.UNAUTHORIZED) {
                const refresh = await getRefreshToken();

                if (refresh) {
                    if (Number(refresh) === COMMONVARIABLES.STATUS.EXPIRED_TOKEN) {
                        UserLogout();
                    } else {
                        await udfnVoucherTypeList();
                    }
                }
            }

            if (response?.data?.body?.status === COMMONVARIABLES.STATUS.SERVICE_CATCH_STATUS) {
                toast.error(ERROR_MESSAGE.API_ERROR.SERVICE_CATCH_ERROR, { position: toast.POSITION.TOP_RIGHT });
            }

            if (response?.data?.body?.VoucherTypeList) {
                setVoucherTypeList(response.data.body.VoucherTypeList);
                var voucherTypeListCount = response.data.body.VoucherTypeList.filter(item => item.vt_status_code === 1).length;
                setVoucherTypeTotalCount(voucherTypeListCount);
                toast.success(response.data.body.message, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
                setIsLoading(false);
            } else {
                toast.error(response.data.body.message, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
            }
        } catch (error) {
            // Handle error
            setIsLoading(false);
            console.error("voucher type list API Error:", error);
            if (error.code === COMMONVARIABLES.STATUS.NETWORK_ERROR_STATUS) {
                toast.error(ERROR_MESSAGE.API_ERROR.NETWORK_ERROR, { position: toast.POSITION.TOP_RIGHT });
            }
            if (error.code === COMMONVARIABLES.STATUS.BAD_REQUEST_ERROR_STATUS) {
                toast.error(ERROR_MESSAGE.API_ERROR.BAD_REQUEST_ERROR, { position: toast.POSITION.TOP_RIGHT });
            }
        }

    };

    const udfnVoucherTypeCreate = async () => {
        setIsLoading(true);
        const user = getLoggedInUser();
        var uploadData = {};
        var apiUrl = '';
        if (editFlag === true) {
            uploadData = {
                user_id: user.id,
                company_code: user.company_code,
                voucher_type_code: Number(voucher_type_code),
                voucher_type_name: String(voucherName.trim()),
                branch_code: Number(branchName.value),
                voucher_prefix: String(prefix.trim()),
                voucher_suffix: String(suffix.trim()),
                no_of_digits: Number(noOfDigits),
                starting_number: Number(startingNumber),
                print_title: String(printTitle.trim()),
                stock_applicable: Number(stockApplicable),
                financial_year_code: Number(financialYear),
                status_code: Number(statusCode)
            };
            apiUrl = MASTER_API.VOUCHER_TYPE_MASTER_UPDATE;
        } else {

            uploadData = {
                user_id: user.id,
                company_code: user.company_code,
                voucher_type_name: String(voucherName.trim()),
                branch_code: Number(branchName.value),
                voucher_prefix: String(prefix.trim()),
                voucher_suffix: String(suffix.trim()),
                no_of_digits: Number(noOfDigits),
                starting_number: Number(startingNumber),
                print_title: String(printTitle.trim()),
                stock_applicable: Number(stockApplicable),
                financial_year_code: Number(financialYear),
                status_code: Number(statusCode)
            };
            apiUrl = MASTER_API.VOUCHER_TYPE_MASTER_CREATE;
        }

        const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } };

        try {
            const response = await axios.post(process.env.REACT_APP_BASE_URL + apiUrl, uploadData, config);
            setIsLoading(false);
            if (response && response?.data?.body?.status === COMMONVARIABLES.STATUS.INVALIDUSER) {
                toast.error(response.data.body.message, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
                UserLogout();
            }

            if (response?.data?.body?.status === COMMONVARIABLES.STATUS.UNAUTHORIZED) {
                const refresh = await getRefreshToken();
                if (refresh) {
                    if (Number(refresh) === COMMONVARIABLES.STATUS.EXPIRED_TOKEN) {
                        UserLogout();
                    } else {
                        await udfnVoucherTypeCreate();
                    }
                }
            }

            if (response && response?.data?.body?.status === COMMONVARIABLES.STATUS.EXISTS) {
                setButtonFlag(true);
                toast.error(response.data.body.message, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
            }

            if (response && response?.data?.body?.status === COMMONVARIABLES.STATUS.ALREADY_IN_USE) {
                toast.error(response.data.body.message, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
            }

            if (response?.data?.body?.status === COMMONVARIABLES.STATUS.SERVICE_CATCH_STATUS) {
                toast.error(ERROR_MESSAGE.API_ERROR.SERVICE_CATCH_ERROR, { position: toast.POSITION.TOP_RIGHT });
            }

            if (response?.data?.body?.status === COMMONVARIABLES.STATUS.SUCCESS) {
                toast.success(response.data.body.message, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
                setButtonFlag(true);
                udfnClearForm();
                if (editFlag === true) {
                    setAddCard(false);
                }
                else {
                    setAddCard(true);
                }
                await udfnVoucherTypeList();
            } else {
                toast.error(response.data.body.message, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
                setAddCard(true);
                setButtonFlag(true);
            }
        } catch (error) {
            // Handle error
            setIsLoading(false);
            console.error("voucher type create API Error:", error);
            if (error.code === COMMONVARIABLES.STATUS.NETWORK_ERROR_STATUS) {
                toast.error(ERROR_MESSAGE.API_ERROR.NETWORK_ERROR, { position: toast.POSITION.TOP_RIGHT });
            }
            if (error.code === COMMONVARIABLES.STATUS.BAD_REQUEST_ERROR_STATUS) {
                toast.error(ERROR_MESSAGE.API_ERROR.BAD_REQUEST_ERROR, { position: toast.POSITION.TOP_RIGHT });
            }
        }
    };

    const udfnClearForm = () => {
        setVoucherTypeCode('')
        setBranchName({ label: 'Select Branch', value: 0 })
        setVoucherName('')
        setPrefix('')
        setSuffix('')
        setStartingNumber('')
        setNoOfDigits('')
        setPrintTitle('')
        setStockApplicable(1)
        setStatusCode(1);
        setAddCard(false);
        setEditFlag(false);
    };

    const udfnEditLoad = async (row) => {
        if (row.vt_sync_flag === 1) {
            toast.error('Already in use', {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        } else {
            setVoucherName(row.vt_voucher_type_name);
            setPrefix(row.vt_prefix)
            setSuffix(row.vt_suffix);
            setBranchName({
                label: row.branch_name,
                value: row.vt_branch_code,
            })
            setNoOfDigits(row.vt_no_of_digits)
            setPrintTitle(row.vt_print_title)
            setStockApplicable(row.vt_stock_applicable)
            setStartingNumber(row.vt_starting_number)
            setVoucherTypeCode(row.vt_voucher_type_code)
            setStatus(row.vt_status_code)
            setStatusCode(row.vt_status_code)
            setAddCard(true);
            setEditFlag(true);
            udfnGetBranchList();
        }
    };

    const udfnDelete = async (voucher_type_code, sync_flag) => {
        if (sync_flag === 1) {
            toast.error('Already in use', {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        } else {
            swal({
                title: "Are you sure?",
                text:
                    "Once deleted, you will not be able to recover this voucher type!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            }).then((willDelete) => {
                if (willDelete) {
                    udfnVoucherTypeDelete(voucher_type_code);
                } else {
                    swal("Your voucher type is safe!");
                }
            })
        }
    }

    const udfnChangeStockApplicable = () => {
        if (stockApplicable === 0) {
            swal({
                title: "Are you sure?",
                text:
                    "you want to save without applying stock?",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            }).then((willDelete) => {
                if (willDelete) {
                    setButtonFlag(false);
                    udfnVoucherTypeCreate();
                } else {
                    setStockApplicable(1)
                }
            })
        }
        else {
            setButtonFlag(false);
            udfnVoucherTypeCreate();
        }
    }

    const udfnVoucherTypeDelete = async (voucher_type_code) => {
        setIsLoading(true);
        const user = getLoggedInUser();
        const data = {
            user_id: user.id,
            company_code: user.company_code,
            voucher_type_code: Number(voucher_type_code)

        };
        const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } };

        try {
            const response = await axios.post(process.env.REACT_APP_BASE_URL + MASTER_API.VOUCHER_TYPE_MASTER_DELETE, data, config);
            setIsLoading(false);
            if (response && response?.data?.body?.status === COMMONVARIABLES.STATUS.INVALIDUSER) {
                toast.error(response.data.body.message, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
                UserLogout();
            }

            if (response?.data?.body?.status === COMMONVARIABLES.STATUS.UNAUTHORIZED) {
                const refresh = await getRefreshToken();

                if (refresh) {
                    if (Number(refresh) === COMMONVARIABLES.STATUS.EXPIRED_TOKEN) {
                        UserLogout();
                    } else {
                        await udfnVoucherTypeDelete();
                    }
                }
            }

            if (response && response?.data?.body?.status === COMMONVARIABLES.STATUS.EXISTS) {
                toast.error(response.data.body.message, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
                setAddCard(true);
            }

            if (response?.data?.body?.status === COMMONVARIABLES.STATUS.SERVICE_CATCH_STATUS) {
                toast.error(ERROR_MESSAGE.API_ERROR.SERVICE_CATCH_ERROR, { position: toast.POSITION.TOP_RIGHT });
            }

            if (response?.data?.body?.status === COMMONVARIABLES.STATUS.SUCCESS) {
                swal(response.data.body.message, {
                    icon: "success",
                });
                await udfnVoucherTypeList();
            } else {
                toast.error(response.data.body.message, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
            }
        } catch (error) {
            // Handle error
            setIsLoading(false);
            console.error("voucher type delete API Error:", error);
            if (error.code === COMMONVARIABLES.STATUS.NETWORK_ERROR_STATUS) {
                toast.error(ERROR_MESSAGE.API_ERROR.NETWORK_ERROR, { position: toast.POSITION.TOP_RIGHT });
            }
            if (error.code === COMMONVARIABLES.STATUS.BAD_REQUEST_ERROR_STATUS) {
                toast.error(ERROR_MESSAGE.API_ERROR.BAD_REQUEST_ERROR, { position: toast.POSITION.TOP_RIGHT });
            }
        }
    };

    const udfnGetBranchList = async () => {
        setAddCard(true);
        const user = getLoggedInUser();
        const data = {
            user_id: user.id,
            status_code: Number(1)
        };
        const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } };
        try {
            const response = await axios.post(process.env.REACT_APP_BASE_URL + COMMON_API.BRANCH_MASTER_LIST, data, config);
            setIsLoading(false);
            if (response && response?.data?.body?.status === COMMONVARIABLES.STATUS.INVALIDUSER) {
                toast.error(response.data.body.message, { position: toast.POSITION.TOP_RIGHT });
                UserLogout();
            }

            if (response?.data?.body?.status === COMMONVARIABLES.STATUS.UNAUTHORIZED) {
                const refresh = await getRefreshToken();

                if (refresh) {
                    if (Number(refresh) === COMMONVARIABLES.STATUS.EXPIRED_TOKEN) {
                        UserLogout();
                    } else {
                        await udfnGetBranchList();
                    }
                }
            }
            if (response?.data?.body?.status === COMMONVARIABLES.STATUS.SERVICE_CATCH_STATUS) {
                toast.error(ERROR_MESSAGE.API_ERROR.SERVICE_CATCH_ERROR, { position: toast.POSITION.TOP_RIGHT });
            }
            setOptions(response?.data?.body?.branchList)
        }
        catch (error) {
            // Handle error
            console.error("get branch list API Error:", error);
            if (error.code === COMMONVARIABLES.STATUS.NETWORK_ERROR_STATUS) {
                toast.error(ERROR_MESSAGE.API_ERROR.NETWORK_ERROR, { position: toast.POSITION.TOP_RIGHT });
            }
            if (error.code === COMMONVARIABLES.STATUS.BAD_REQUEST_ERROR_STATUS) {
                toast.error(ERROR_MESSAGE.API_ERROR.BAD_REQUEST_ERROR, { position: toast.POSITION.TOP_RIGHT });
            }
        }
    };

    const udfnHandleStock = () => {
        if (stockApplicable === 0) {
            setStockApplicable(1);
        }
        if (stockApplicable === 1) {
            setStockApplicable(0);
        }
    }

    const udfnVoucherNumber = (startingNumber, numberOfDigits) => {
        var formatted = String(startingNumber).padStart(numberOfDigits, '0');
        setVoucherNumber(formatted);
    };

    const onBlurStartingNumber = () => {
        udfnVoucherNumber(startingNumber, noOfDigits);
    }

    const buttonRef = useRef(null);

    const handleKeyDown = (event) => {
        // Check if the pressed key is the Tab key (key code 9)
        if (event.keyCode === 9) {
            // Prevent the default Tab behavior to prevent losing focus from the text input
            event.preventDefault();

            // Focus the button element
            buttonRef.current.focus();
        }
    };

    const openCard = () => {
        udfnClearForm()
        setAddCard(true)
        udfnGetBranchList()
    }

    useEffect(() => {
        udfnVoucherTypeList();
        udfnVoucherNumber(startingNumber, noOfDigits);
    }, [startingNumber]); // eslint-disable-line react-hooks/exhaustive-deps
    return (
        <>
            <ToastContainer />
            <div className="col-12">
                <LoadingOverlay
                    active={isLoading}
                    spinner
                    text='Loading ...'
                >
                    <div className="card">
                        <div className="card-header mb-sm-12 mb-3 d-flex align-items-center flex-wrap">
                            <div className='col-sm-5'>
                                <h2 className="card-title">Voucher setting -  Sales</h2>
                            </div>
                            <div className='col-sm-3'>
                                <p className="bgl-primary rounded p-3 text-center mb-2"><span className="text-black me-1" >Total Active Vouchers  :  </span><Badge bg='info badge-rounded' className='text-black font-w400 fs-18'>{voucherTypeTotalCount}</Badge></p>
                            </div>
                            <div className='col-sm-2 me-1'>
                                <Link className="btn btn-primary mb-2" onClick={() => openCard()}>
                                    + Add Voucher Type
                                </Link>
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="table-responsive">
                                <div className='row'>
                                    <div className='col-sm-9'>
                                        <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
                                    </div>
                                </div>
                                <table {...getTableProps()} className="table filtering-table table-responsive-lg">
                                    <thead>
                                        <tr>
                                            <th>S.No.</th>
                                            <th>Branch Name</th>
                                            <th>Voucher Name</th>
                                            <th className='text-center'>NO.Of.Digits</th>
                                            <th>Print Title</th>
                                            <th>Voucher Number</th>
                                            <th className='text-center'>Stock Applicable</th>
                                            <th>Status</th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody {...getTableBodyProps()} className="" >

                                        {page.map((row, index) => {
                                            prepareRow(row)
                                            return (
                                                <tr key={index}>
                                                    <td>{index + 1}</td>
                                                    <td>{row.original.branch_name}</td>
                                                    <td>{row.original.vt_voucher_type_name}</td>
                                                    <td className='text-center'>{row.original.vt_no_of_digits}</td>
                                                    <td>{row.original.vt_print_title}</td>
                                                    <td>{row.original.vt_prefix}{row.original.vouchernumber}{row.original.vt_suffix}</td>
                                                    <td>
                                                        {row.original.vt_stock_applicable === 1 ?
                                                            <div className="text-center">
                                                                <Button
                                                                    type="checkbox"
                                                                    variant="success"
                                                                    className="ms-1 shadow btn-xs sharp fa fa-check"
                                                                    id="customCheckBox1"
                                                                    required
                                                                    readOnly
                                                                />
                                                            </div>
                                                            :
                                                            <div className="text-center">
                                                                <Button
                                                                    type="checkbox"
                                                                    variant="danger"
                                                                    id="customCheckBox1"
                                                                    required
                                                                    readOnly
                                                                    className="ms-1 shadow btn-xs sharp fa fa-times"
                                                                />
                                                            </div>
                                                        }
                                                    </td>
                                                    <td>
                                                        {row.original.vt_status_code === 1 ? <Badge bg="" className='badge-success light'>
                                                            <span className="me-2">
                                                                <i className="ms-1 fa fa-check" />
                                                            </span>
                                                            {row.original.status}
                                                        </Badge> : <Badge bg="" className='badge-primary light'>
                                                            <span className="me-2">
                                                                <i className="fa fa-times"></i>
                                                            </span>
                                                            {row.original.status}
                                                        </Badge>
                                                        }
                                                    </td>
                                                    <td>
                                                        <Link className="btn btn-secondary	 shadow btn-xs sharp me-2"
                                                            onClick={() => udfnEditLoad(row.original)} >
                                                            <i className="fa fa-pencil" id={'edittooltip' + index}></i>
                                                            <UncontrolledTooltip placement="top" target={'edittooltip' + index}>
                                                                Edit
                                                            </UncontrolledTooltip>
                                                        </Link>
                                                        <Link className="btn btn-danger shadow btn-xs sharp"
                                                            onClick={() => udfnDelete(row.original.vt_voucher_type_code, row.original.vt_sync_flag)}>
                                                            <i className="fa fa-trash" id={'deletetooltip' + index}></i>
                                                            <UncontrolledTooltip placement="top" target={'deletetooltip' + index}>
                                                                Delete
                                                            </UncontrolledTooltip>
                                                        </Link>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                                <div className="d-flex justify-content-between">
                                    <span>
                                        Page{' '}
                                        <strong>
                                            {pageIndex + 1} of {pageOptions.length}
                                        </strong>{''}
                                    </span>
                                    <span className="table-index">
                                        Go to page : {' '}
                                        <input type="number"
                                            className="ms-2"
                                            defaultValue={pageIndex + 1}
                                            onChange={e => {
                                                const pageNumber = e.target.value ? Number(e.target.value) - 1 : 0
                                                gotoPage(pageNumber)
                                            }}
                                        />
                                    </span>
                                </div>
                                <div className="text-center">
                                    <div className="filter-pagination  mt-3">
                                        <button className=" previous-button" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>{'<<'}</button>

                                        <button className="previous-button" onClick={() => previousPage()} disabled={!canPreviousPage}>
                                            Previous
                                        </button>
                                        <button className="next-button" onClick={() => nextPage()} disabled={!canNextPage}>
                                            Next
                                        </button>
                                        <button className=" next-button" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>{'>>'}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </LoadingOverlay>
                <Modal className="fade bd-example-modal-lg" show={addCard} onHide={setAddCard} size="lg" backdrop="static">
                    <Modal.Header>
                        <h4 className="text-black">{editFlag === true ? 'Update Voucher Type For Sales' : 'Add Voucher Type For Sales'}</h4>
                        <Button
                            variant=""
                            className="close"
                            onClick={udfnClearForm}
                        >
                            <span>&times;</span>
                        </Button>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="basic-form">
                            <form onSubmit={(e) => e.preventDefault()}>
                                <div className="row">
                                    <div className="form-group mb-3 col-md-6">
                                        <label className="text-black font-w500">Branch Name <span className="text-danger">*</span></label>
                                        <Select
                                            defaultValue={branchName}
                                            onChange={setBranchName}
                                            options={options.map((option) => ({
                                                label: option.bm_branch_name,
                                                value: option.bm_branch_code,
                                            }))}
                                            value={branchName}
                                            showContent="true"
                                            open="true"
                                            className="my-react-select-container"
                                            theme={theme => ({
                                                ...theme,
                                                borderRadius: '6px',
                                                colors: {
                                                    ...theme.colors,
                                                    primary25: '#b3b3b3',
                                                    primary: 'black',
                                                    neutral80: "#b3b3b3",
                                                },
                                            })}
                                        />
                                    </div>
                                    <div className="form-group mb-3 col-md-6">
                                        <label className="text-black font-w500">Voucher Name <span className="text-danger">*</span></label>
                                        <input type="text" className="form-control" autoComplete="off" value={voucherName}
                                            name="voucher_name" required="required" onChange={e => setVoucherName(e.target.value)}
                                            onKeyUp={(e) => udfnNameValidation(e)}
                                            placeholder="Voucher Name" maxLength="100"
                                        />
                                    </div>
                                    <div className="form-group mb-3 col-md-6">
                                        <label className="text-black font-w500">Prefix</label>
                                        <input type="text" className="form-control" autoComplete="off" value={prefix}
                                            name="voucher_prefix" required="required" onChange={e => setPrefix(e.target.value)}
                                            placeholder="Prefix" maxLength="5"
                                        />
                                    </div>
                                    <div className="form-group mb-3 col-md-6">
                                        <label className="text-black font-w500">Suffix</label>
                                        <input type="text" className="form-control" autoComplete="off" value={suffix}
                                            name="voucher_suffix" required="required" onChange={e => setSuffix(e.target.value)}
                                            placeholder="Suffix" maxLength="6"
                                        />
                                    </div>
                                    <div className="form-group mb-3 col-md-6">
                                        <label className="text-black font-w500">No.Of.Digits</label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            value={noOfDigits}
                                            onChange={(e) => {
                                                setNoOfDigits(e.target.value);
                                            }}
                                            onKeyPress={(e) => udfnNoOfDigitsValidation(e)}
                                            onBlur={(e) => onBlurStartingNumber()}
                                            placeholder="No.Of.Digits"
                                        />
                                    </div>
                                    <div className="form-group mb-3 col-md-6">
                                        <label className="text-black font-w500">Starting Number <span className="text-danger">*</span></label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            value={startingNumber}
                                            onChange={(e) => { setStartingNumber(e.target.value) }}
                                            onKeyPress={(e) => udfnStartingNumberValidation(e)}
                                            placeholder="Starting Number"
                                        />
                                    </div>
                                    <div className="form-group mb-3 col-md-6 mt-5">
                                        <label className="text-black font-w500 me-2">Sample Voucher No.</label>
                                        <label className="text-black font-w200">{prefix}{voucherNumber}{suffix}</label>
                                    </div>
                                    <div className="form-group mb-3 col-md-6">
                                        <label className="text-black font-w500">Print Title <span className="text-danger">*</span></label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={printTitle}
                                            onChange={e => setPrintTitle(e.target.value)}
                                            onKeyDown={handleKeyDown}
                                            placeholder="Print Title" maxLength="15"
                                        />
                                    </div>
                                    <div className="form-group col-md-6">
                                        <div className="form-check custom-checkbox" style={{ marginTop: "9%" }}>
                                            {stockApplicable === 0 ? <input
                                                type="checkbox"
                                                value={stockApplicable}
                                                className="form-check-input text-center"
                                                id="customCheckBox1"
                                                onClick={udfnHandleStock}
                                                onKeyDown={handleKeyDown}
                                                required
                                            /> :
                                                <input
                                                    type="checkbox"
                                                    value={stockApplicable}
                                                    className="form-check-input text-center"
                                                    id="customCheckBox1"
                                                    onClick={udfnHandleStock}
                                                    onKeyDown={handleKeyDown}
                                                    required
                                                    checked={stockApplicable === 1}
                                                />}
                                            <label
                                                className="form-check-label text-center"
                                                htmlFor="customCheckBox1"
                                            >
                                                Stock Applicable
                                            </label>
                                        </div>
                                    </div>
                                    <div className="form-group mb-3 col-md-6 mt-4">
                                        {editFlag === true ?
                                            <fieldset className="form-group">
                                                <div className="row">
                                                    <label className="col-form-label col-sm-3 pt-1 text-black font-w500">
                                                        Status
                                                    </label>
                                                    <div className="col-sm-3">
                                                        <div className="form-check checkbox-success">
                                                            {status === 1 ? <input
                                                                className="form-check-input"
                                                                type="radio"
                                                                name="gridRadios"
                                                                onClick={() => setStatusCode(1)}
                                                                onKeyDown={handleKeyDown}
                                                                defaultChecked
                                                            /> :
                                                                <input
                                                                    className="form-check-input"
                                                                    type="radio"
                                                                    name="gridRadios"
                                                                    onClick={() => setStatusCode(1)}
                                                                    onKeyDown={handleKeyDown}
                                                                />}
                                                            <label className="form-check-label">
                                                                Active
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-5">
                                                        <div className="form-check checkbox-danger">
                                                            {status === 2 ? <input
                                                                className="form-check-input"
                                                                type="radio"
                                                                name="gridRadios"
                                                                onClick={() => setStatusCode(2)}
                                                                onKeyDown={handleKeyDown}
                                                                defaultChecked
                                                            /> :
                                                                <input
                                                                    className="form-check-input"
                                                                    type="radio"
                                                                    name="gridRadios"
                                                                    onClick={() => setStatusCode(2)}
                                                                    onKeyDown={handleKeyDown}
                                                                />}
                                                            <label className="form-check-label">
                                                                Inactive
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </fieldset> : ''}
                                    </div>
                                </div>
                            </form>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant="danger"
                            onClick={udfnClearForm}
                        >
                            Discard
                        </Button>
                        <Button className="me-2" variant="whatsapp" ref={buttonRef} onClick={udfnFromValidate} disabled={buttonFlag === false}>
                            {editFlag === true ? 'Update' : 'Save'}
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        </>
    )
}
export default VoucherTypeMaster;