import { ColumnFilter } from '../Common/ColumnFilter';
export const COLUMNS = [
	// {
	// 	Header: 'S.No.',
	// 	Footer: 'S.No.',
	// 	accessor: 'id',
	// 	Filter: ColumnFilter,
	// 	// disableFilters: true,
	// },
	{
		Header: 'Date',
		Footer: 'Date',
		accessor: 'transfer_date',
		Filter: ColumnFilter,
		// disableFilters: true,
	},
	{
		Header: 'Transfer No.',
		Footer: 'Transfer No.',
		accessor: 'transfer_no',
		Filter: ColumnFilter,
		// disableFilters: true,
	},
	{
		Header: 'From Branch',
		Footer: 'From Branch',
		accessor: 'from_branch_name',
		Filter: ColumnFilter,
		// disableFilters: true,
	},
	{
		Header: 'To Branch',
		Footer: 'To Branch',
		accessor: 'to_branch_name',
		Filter: ColumnFilter,
		// disableFilters: true,
	},
	{
		Header: 'Total Items',
		Footer: 'Total Items',
		accessor: 'no_of_items',
		Filter: ColumnFilter,
		// disableFilters: true,
	},
	{
		Header: 'Narration',
		Footer: 'Narration',
		accessor: 'st_narration',
		Filter: ColumnFilter,
		// disableFilters: true,
	},
]

export const GROUPED_COLUMNS = [
]