export const isAuthenticated = (state) => {
   const UserInfo = localStorage.getItem("userDetails");
    // if (UserInfo) return JSON.parse(UserInfo);
     console.log(UserInfo,'state.auth.auth.accessToken')
    if (UserInfo) return true;
    return false;

};
// const getLoggedInUser = () => {
//     alert('d')
//     const UserInfo = localStorage.getItem("authUser");
//     if (UserInfo) return JSON.parse(UserInfo);
//     return null;
// };