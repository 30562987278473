import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import '../Filters/Common/filtering.css';
/// Bootstrap
import Select from "react-select";
import ERROR_MESSAGE from '../Validation/ErrorMessage';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from 'axios';
import {
    getLoggedInUser, getRefreshToken
} from '../../../services/AuthService';
import { UserLogout } from '../../layouts/nav/Logout';
import COMMONVARIABLES from '../Utilities/constants';
import LoadingOverlay from 'react-loading-overlay-ts';
import COMMON_API from '../../Api/CommonApi';
import swal from "sweetalert";
import QRCode from "react-qr-code";

const QrCodeRePrint = () => {

    //Modal box
    const [cardItemName, setCardItemName] = useState('');
    const [itemCode, setItemCode] = useState('');
    const [cardItemCode, setCardItemCode] = useState('');
    const [qsItemCode, setQsItemCode] = useState('');
    const [labelDetails, setLabelDetails] = useState('');
    const [currentSellingPrice, setCurrentSellingPrice] = useState('');
    const [noOfLabelToPrint, setNoOfLabelToPrint] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [buttonFlag, setButtonFlag] = useState(true);

    const [labelTypeName, setLabelTypeName] = useState({ label: '35*22', value: 1 })
    const labelTypeNameOptions = COMMONVARIABLES.LABLEPRINTTYPE;
    const [qrCodeItemDetails, setQRCodeItemDetails] = useState([]);
	const [printStatus, setPrintStatus] = useState(false)
	const [printItemName, setPrintCardItemName] = useState('');
    const myid = "myqr"
    const myqrid = "myqrid"

    //Add Suumit data
    const udfnFromValidate = (event) => {

        var error = false;
        var errorMsg = '';
        // All Input Fields Validation
        if (cardItemCode === '' || noOfLabelToPrint === '') {
            error = true;
            errorMsg = ERROR_MESSAGE.COMMON_REQUIRED;
        }

        if (!error) {
            setButtonFlag(false);
            swal({
                text:
                    "Are you sure you want print?",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            }).then((willDelete) => {
                if (willDelete) {
                    setPrintStatus(true)
                    setQRCodeItemDetails([
                        {
                            cr_new_selling_price: currentSellingPrice,
                            cr_label_details: labelDetails,
                            cr_qr_code: cardItemCode,
                            cr_item_code: qsItemCode,
                            im_display_name: printItemName
                        }
                    ])

                } else {
                    udfnClearForm();
                    setItemCode('');
                }
            })
        } else {
            toast.error(errorMsg, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    };

    async function fetchItemListFromQrCode(qrCode) {
        setIsLoading(true)
        try {
            const user = getLoggedInUser()

            const itemListParams = { qr_code: Number(qrCode), user_id: user?.id, branch_code: Number(0) };


            const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } };
            const [itemData] = await Promise.all([
                axios.post(process.env.REACT_APP_BASE_URL + COMMON_API.ITEM_LIST_FROM_QRCODE, itemListParams, config),

            ]);
            setIsLoading(false)

            if (itemData?.data?.body?.status === COMMONVARIABLES.STATUS.UNAUTHORIZED) {


                const refresh = await getRefreshToken();

                if (refresh) {
                    if (Number(refresh) === COMMONVARIABLES.STATUS.EXPIRED_TOKEN) {
                        UserLogout()
                    } else {
                        await fetchItemListFromQrCode(qrCode);
                    }
                }
            }

            if (itemData?.data?.body?.status === COMMONVARIABLES.STATUS.SERVICE_CATCH_STATUS) {
                toast.error(ERROR_MESSAGE.API_ERROR.SERVICE_CATCH_ERROR, { position: toast.POSITION.TOP_RIGHT });
            }

            if (itemData?.data?.body?.itemList) {
                setCardItemName(itemData?.data?.body?.itemList[0].im_item_name);
                setLabelDetails(itemData?.data?.body?.itemList[0].label_details);
                setCurrentSellingPrice(itemData?.data?.body?.itemList[0].selling_rate)
				setQsItemCode(itemData?.data?.body?.itemList[0].qs_item_code)
				setPrintCardItemName(itemData?.data?.body?.itemList[0].print_im_display_name);
            }


        } catch (error) {
            setIsLoading(false)
            console.error('Error fetching master data:', error);
            if (error.code === COMMONVARIABLES.STATUS.NETWORK_ERROR_STATUS) {
                toast.error(ERROR_MESSAGE.API_ERROR.NETWORK_ERROR, { position: toast.POSITION.TOP_RIGHT });
            }
            if (error.code === COMMONVARIABLES.STATUS.BAD_REQUEST_ERROR_STATUS) {
                toast.error(ERROR_MESSAGE.API_ERROR.BAD_REQUEST_ERROR, { position: toast.POSITION.TOP_RIGHT });
            }
        }
    }

    const udfnClearForm = () => {
        setCardItemName('')
        setQsItemCode('')
        setCardItemCode('')
        setLabelDetails('')
        setCurrentSellingPrice('')
        setNoOfLabelToPrint('')
        setLabelTypeName({ label: '35*22', value: 1 });
        setPrintStatus(false)
		setButtonFlag(true)
		setPrintCardItemName('')
    };

    const onBlurQrCode = (qrCode) => {
        if (qrCode) {
            fetchItemListFromQrCode(qrCode)
        }
    }

    function printQRCode(qrcodeList) {
        const printHtml = window.open('', 'PRINT', 'height=auto,width=100%')
        let html = ''
        let itemDetails = ''
        const user = getLoggedInUser()
        for (let j = 0; j < Number(noOfLabelToPrint); j++) {
            for (let i = 0; i < qrcodeList.length; i++) {
                const myids = "myqr"
                const getid = myids + qrcodeList[i].cr_qr_code
                const innerContents = document.getElementById(getid).innerHTML
                itemDetails += `
					<div class="float-child col-4  ml-5 mt-3">				
						<div class="align-center">
							<div class="heading">${user.label_title}</div>
						</div>

						<div class="float-container botton-align ">`
                if (qrcodeList[i].cr_label_details) {
                    itemDetails += `<div class="qr_div">
													<div class="rotated"> ${qrcodeList[i].cr_label_details}</div>
												</div>`
                }
                itemDetails += `<div class="float-qrcode ml-2">
												<div class="qr_div ">
													<div> ${innerContents}</div>
												
												</div>
												<div class="qr_div bold-design size-10 align-center">${qrcodeList[i].cr_qr_code}</div>
											</div>
											<div class="float-details  ml-2">
												<div class="bold-design size-9 wor-wrap">${qrcodeList[i].im_display_name}</div>
												<div class="bold-design size-15 m-10">₹${qrcodeList[i].cr_new_selling_price}</div>
											</div>
						</div>			
					</div>`
            }
        }

        html += `<html>
					<head>
						<title>Change Price List</title>
						<style>
						.rotated {
							writing-mode: tb-rl;
							transform: rotate(-180deg);
							font-size: 10px;
						}
				
						.qr_div {
							display: flex;
							align-items: center;
							justify-content: center;
						}
				
						body {
							font-family: Arial, Helvetica, sans-serif;
							width: 416px !important; 
						}
				
						.row {
							width: 100%;
							/* margin-top: 10px; */
						}
				
						.column {
							float: left;
						}
				
						@media all {
							.page-break {
								display: none
							}
						}
						@media print {
				
							body {
							margin-top: 1 !important;
							color: #000;
							}
				
						}
						.heading {
							font-size: 13px !important;
							font-weight: bold;
							text-align: center
						}
				
						.align-center {
							flex-direction: column;
							text-align: left;
						}
				
						.align-left {
							text-align: left;
						}
				
						.align-right {
							text-align: right;
							font-size: large;
						}
				
						.paddding-space {
							/* padding-bottom: 15px */
						}
				
						.center {
							text-align: center;
						}
				
						.padding {
							padding-left: 10px
						}
				
						.qrcode {
							padding-left: 50px
						}
				
						.botton-align {
							/* padding-top: 10px */
						}
				
						.botton {
							padding-top: 10px
						}
				
						.size-10 {
							font-size: 10px !important;
						}
				
						.size-11 {
							font-size: 11px !important;
						}

						.size-9 {
							font-size: 9px !important;
						}
				
						.size-12 {
							font-size: 12px !important;
						}
				
						.size-15 {
							font-size: 15px !important;
						}
				
						.size-small {
							font-size: 10px !important;
							text-align: center !important;
							font-weight: bold;
						}
						.bold-design {
							font-weight: bold;
						}
				
						.float-child {
							display: flex;
							flex-direction: column;
						}
				
						.float-container {
							width: 100%;
							display: flex;
							flex-direction: row;
						}
				
						.float-details {
							width: 65%;
							display: flex;
							flex-direction: column;
							padding-left: 2px;
							text-align: left;
						}
				
						.float-qrcode {
							width: 35%;
							display: flex;
							flex-direction: column;
						}
				
						.marleft {
							padding-left: 20px;
						}
				
						.marleft-10 {
							padding-left: 10px;
						}
				
						.marright {
							padding-right: 20px;
						}
				
						.col-4 {
							flex: 0 0 auto;
							width: 133px !important;
							height: 83px;
						}
				
						.parent {
							display: flex;
							flex-wrap: wrap;
							flex-direction: row;
						}
				
						.mb-15 {
							margin-bottom: 15px;
						}
				
						.mt-5 {
							margin-bottom: 5px;
						}
						.ml-5 {
							margin-left: 5px;
						}
						.ml-10{
							margin-left: 10px;
						}
						.mt-5{
							margin-top: 5px;
						}
						.ml-2 {
							margin-left: 2px;
						}
						.mt-3 {
							margin-top: 3px;
						}
						.mt-2 {
							margin-top: 2px;
						}
						.wor-wrap {
							text-wrap: wrap;
							width: 50px;
							overflow-wrap: break-word;
							height: 26px;
							overflow: hidden;
						}
						.mar-top{
							margin-top: -5px;
						}
						.m-10{
							margin-top: 10px
						}
						</style>
					</head>
					<body onload="window.print();window.close()">
						<div class="row  ml-10">
							<div class='paddding-space'>
								<div class="float-container parent">
									${itemDetails}					
								</div>
							</div>				
						</div>		
					</body>
                </html>`
        console.log(html, "html")
        printHtml.document.write(html)
        printHtml.document.close()
        printHtml.print()
        printHtml.close()
        udfnClearForm();
        setItemCode('');
        return true
    }

    function printSingleQRCode(qrcodeList) {
        const printHtml = window.open('', 'PRINT', 'height=auto,width=100%')
        let html = ''
        let itemDetails = ''
        const user = getLoggedInUser()
        for (let j = 0; j < Number(noOfLabelToPrint); j++) {
            for (let i = 0; i < qrcodeList.length; i++) {
                const myids = "myqrid"
                const getid = myids + qrcodeList[i].cr_qr_code
                const innerContents = document.getElementById(getid).innerHTML

                itemDetails += `<div class="float-child">
			<div class="float-container align-center justfy-center">
				<div class="heading">${user.label_title}</div>
			</div>

			<div class="float-container botton-align ">`
                if (qrcodeList[i].cr_label_details) {
                    itemDetails += `<div class="qr_div marleft-10">
												<div class="rotated"> ${qrcodeList[i].cr_label_details}</div>
											</div>`
                }
                itemDetails += `
				<div class="float-qrcode marleft-10">
					<div class="qr_div ">
						<div class=""> ${innerContents}</div>

					</div>
					<div class="qr_div bold-design size-large align-center">${qrcodeList[i].cr_qr_code}</div>
				</div>
				<div class="float-details marleft-10">
					<div class="bold-design size-large wor-wrap">${qrcodeList[i].im_display_name}</div>
					<div class="bold-design botton size-Xlarge">₹${COMMONVARIABLES.PDFIndia_Rupee_Formatter(qrcodeList[i].cr_new_selling_price)}</div>
				</div>
			</div>
		</div>`

            }
        }

        html += `<html>
					<head>
						<title>Purchase Print</title>
						<style>
						.rotated {
							writing-mode: tb-rl;
							transform: rotate(-180deg);
							font-size: 8px;
						}
				
						.qr_div {
							display: flex;
							align-items: center;
							justify-content: center;
						}
				
						body {
							font-family: Arial, Helvetica, sans-serif;
							width: 384px;
                            margin: 0px;
						}
				
						.row {
							width: 100%;
                            height: 56.69px;
                            display: block;
						}
				
						.column {
							float: left;
						}
				
						@media all {
							.page-break {
								display: none
							}
						}
				
						.heading {
							font-size: 12px !important;
							font-weight: bold;
							text-align: center;
						}
				
						.align-center {
							flex-direction: column;
							text-align: center;
						}
				
						.align-left {
							text-align: left;
						}
				
						.align-right {
							text-align: right;
							font-size: large;
						}

						.paddding-space {
							float:right;
						}
				
						.center {
							text-align: center;
						}
				
						.padding {
							padding-left: 10px
						}
				
						.qrcode {
							padding-left: 50px
						}
				
						.botton-align {

						}
				
						.botton {
							padding-top: 5px
						}
				
						.size-large {
							font-size: 8px !important;
						}
				
						.size-Xlarge {
							font-size: 13px !important;
						}
				
						.size-20 {
							font-size: 20px !important;
						}
				
						.size-small {
							font-size: 10px !important;
							text-align: center !important;
							font-weight: bold;
						}
				
						.color-style {
							padding-top: 20px;
							font-size: 20px !important;
							font-weight: bold;
						}
				
						.bold-design {
							font-weight: bold;
						}
				
						.float-child {
							width: 100%;
							display: flex;
							flex-direction: column;
                            padding: 1px;
						}
				
						.float-container {
							width: 100%;
							display: flex;
							flex-direction: row;
						}
				
						.float-details {
							width: 65%;
							display: flex;
							flex-direction: column;
							padding-left: 2px;
							text-align: left;
						}
				
						.float-qrcode {
							width: 35%;
							display: flex;
							flex-direction: column;
						}
				
						.marleft {
							padding-left: 20px;
						}
				
						.marleft-10 {
							padding-left: 5px;
						}
				
						.marright {
							padding-right: 20px;
						}
				
						.marright-10 {
							padding-right: 10px;
						}
				
						.col-4 {
							flex: 0 0 auto;
							width: 33.333%;
						}
				
						.parent {
							display: block;
							flex-wrap: wrap;
							flex-direction: row;
						}
				
						.mt-10 {
							margin-top: 10px;
						}
				
						.pb-15 {
							padding-bottom: 13px;
						}
				
						.mt-5 {
							margin-top: 5px;
						}
				
						.mb-5 {

						}
				
						.mb-2 {
							margin-bottom: 2px;
						}
				
						.justfy-center {
							justify-content: center;
						}
						.p-tb{
							padding: 5px 0px;
						}
						.ml-20{
							margin-left:20px;
						}
						.wor-wrap {
							text-wrap: wrap;
    						width: 10px;
						}
						</style>
					</head>
					<body onload="window.print();window.close()">
						<div class="row">
							<div class='paddding-space'>
								<div class="float-container parent">
									${itemDetails}					
								</div>
							</div>				
						</div>		
					</body>
				</html>`
        console.log(html, "html")
        printHtml.document.write(html)
        printHtml.document.close()
        printHtml.print()
        printHtml.close()
        udfnClearForm();
        setItemCode('');
        return true
    }

    useEffect(() => {
        if (printStatus && qrCodeItemDetails && qrCodeItemDetails.length > 0) {
            if (labelTypeName.value === 1) {
                printQRCode(qrCodeItemDetails);
            } else {
                printSingleQRCode(qrCodeItemDetails);
            }

        }
    }, [qrCodeItemDetails]) // eslint-disable-line react-hooks/exhaustive-deps
    return (
        <>
            <ToastContainer />
            <div className="col-12">
                <LoadingOverlay
                    active={isLoading}
                    spinner
                    text='Loading ...'
                >
                    <div className="card  col-sm-6">
                        <div className="card-header mb-sm-12 d-flex align-items-center flex-wrap">
                            <div className='col-sm-7'>
                                <h2 className="card-title">QrCode Reprint</h2>
                            </div>
                        </div>
                        <div className="card-body">

                            <div className="" role="document">
                                <div className="">
                                    <form >
                                        <div className="modal-body">
                                            <i className="flaticon-cancel-12 close" data-dismiss="modal"></i>
                                            <div className="add-contact-box">
                                                <div className="add-contact-content">
                                                    <div className='row mb-3'>
                                                        <div className="col-md-5">
                                                            <label className="text-black font-w500 mt-3">Item Code<span className="text-danger">*</span></label>
                                                        </div>
                                                        <div className="col-md-5">
                                                            <input type="text" className="form-control" autoComplete="off" value={cardItemCode}
                                                                name="name" required="required" onChange={(e) => {
                                                                    setCardItemCode(e.target.value)
                                                                }}
                                                                onBlur={(e) => onBlurQrCode(e.target.value)}
                                                                placeholder="Item Code" maxLength="100"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="row mb-3">
                                                        <div className="col-md-5">
                                                            <label className="text-black font-w500">Item Name</label>
                                                        </div>
                                                        <div className="col-md-7">
                                                            <label className="text-black font-w500">{cardItemName}</label>
                                                        </div>
                                                    </div>
                                                    <div className="row mb-3">
                                                        <div className="col-md-5">
                                                            <label className="text-black font-w500">Label Details</label>
                                                        </div>
                                                        <div className="col-md-5">
                                                            <label className="text-black font-w500">{labelDetails}</label>
                                                        </div>
                                                    </div>
                                                    <div className="row mb-3">
                                                        <div className="col-md-5">
                                                            <label className="text-black font-w500">Selling Price</label>
                                                        </div>
                                                        <div className="col-md-5">
                                                            <label className="text-black font-w500">{currentSellingPrice}</label>
                                                        </div>
                                                    </div>
                                                    <div className="row mb-3">
                                                        <div className="col-md-5">
                                                            <label className="text-black font-w500 mt-3">No.of Labels to print <span className="text-danger">*</span></label>
                                                        </div>
                                                        <div className="col-md-5">
                                                            <input type="number" className="form-control" autoComplete="off" value={noOfLabelToPrint}
                                                                name="name" required="required" onChange={e => setNoOfLabelToPrint(e.target.value)}
                                                                placeholder="No.of Labels to print" />
                                                        </div>
                                                    </div>
                                                    <div className="row mb-3">
                                                        <div className="col-md-5">
                                                            <label className="col-form-label text-black font-w500">Label Print Types</label>
                                                        </div>
                                                        <div className="col-md-5">
                                                            <Select
                                                                defaultValue={labelTypeName}
                                                                value={labelTypeName}
                                                                onChange={setLabelTypeName}
                                                                options={labelTypeNameOptions}
                                                                showContent="true"
                                                                open="true"
                                                                className="my-react-select-container"
                                                                theme={theme => ({
                                                                    ...theme,
                                                                    borderRadius: '6px',
                                                                    colors: {
                                                                        ...theme.colors,
                                                                        primary25: '#b3b3b3',
                                                                        primary: 'black',
                                                                        neutral80: "data-theme-version" === "dark" ? 'white' : ''
                                                                    },
                                                                })}
                                                            />



                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="modal-footer">
                                            {/* <button type="button" className="btn btn-danger" onClick={() => udfnClearForm()}> <i className="flaticon-delete-1"></i> Discard</button> */}
                                            <Button className="me-2" variant="whatsapp" onClick={udfnFromValidate} disabled={buttonFlag === false}>
                                                RePrint
                                            </Button>
                                        </div>
                                    </form>
                                </div>
                            </div>

                        </div>
                    </div>
                    {qrCodeItemDetails.map((row) => (
                        <div id={myid + row.cr_qr_code} className='color-picker' style={{ display: 'none' }}>
                            <QRCode
                                size={50}
                                value={row.cr_qr_code.toString()}
                            />
                        </div>
                    ))}

                    {qrCodeItemDetails.map((row) => (
                        <div id={myqrid + row.cr_qr_code} className='color-picker' style={{ display: 'none' }}>
                            <QRCode
                                size={35}
                                value={row.cr_qr_code.toString()}
                            />
                        </div>
                    ))}
                </LoadingOverlay>
            </div>
        </>
    )
}
export default QrCodeRePrint;