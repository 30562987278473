
const STOCK_TRANSFER_API = {
    // STOCK TRANSFER
    STOCK_TRANSFER_NO: "api/stocktransfer/getStockTransferMaxNo",
    SAVE_STOCK_TRANSFER: "api/stocktransfer/saveStockTransfer",
    STOCK_TRANSFER_LIST: "api/stocktransfer/getStockTransferList",
    EDIT_STOCK_TRASFER:"api/stocktransfer/getStockTransferEditLoad",
    UPDATE_STOCK_TRASFER:"api/stocktransfer/updateStockTransfer",
    DELETE_STOCK_TRASFER:"api/stocktransfer/deleteStockTransfer"
};

export default STOCK_TRANSFER_API;