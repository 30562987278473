import React, { useState, useEffect } from 'react';
import { Badge, Button, Card, Modal } from 'react-bootstrap';
import '../Filters/Common/filtering.css';
import "bootstrap-daterangepicker/daterangepicker.css";
import { ToastContainer, toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { getLoggedInUser, getRefreshToken } from '../../../services/AuthService';
import COMMONVARIABLES from '../Utilities/constants';
import LoadingOverlay from 'react-loading-overlay-ts';
import GSTR_REPORT_API from '../../Api/GstrReportApi';
import ERROR_MESSAGE from '../Validation/ErrorMessage';
import MASTER_API from '../../Api/MasterApi';
import { UserLogout } from '../../layouts/nav/Logout';

const ExcelJS = require('exceljs');

export const QrCodeAnalysisReport = () => {
    const user = getLoggedInUser();
    const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } };
    const BASE_URL = process.env.REACT_APP_BASE_URL;

    const [isLoading, setIsLoading] = useState(false);
    const [qrCode, setQrCode] = useState('');
    const [purchaseDetails, setPurchaseDetails] = useState('');
    const [stockTransferCount, setStockTransferCount] = useState(0);
    const [inward, setInward] = useState(0);
    const [outward, setOutward] = useState(0);
    const [billingDetails, setBillingDetails] = useState([]);
    const [sales, setSales] = useState(0);
    const [openCard, setOpenCard] = useState(false);

    Date.prototype.toDateString = function () {
        var month = this.getMonth() + 1;
        var day = this.getDate();
        var year = this.getFullYear();
        return year + "-" + (month < 10 ? '0' : '') + month + '-' + (day < 10 ? '0' : '') + day;
    };

    const udfnQrCodeValidation = (event) => {
        const regex = /^[0-9]*$/;
        const input = event.target.value + event.key;
        if (!regex.test(input) || input.length > 15) {
            event.preventDefault();
        }
    };

    async function udfnQrCodeAnalysisReport() {

        if (qrCode !== '') {
            try {
                setIsLoading(true)

                const params = {
                    user_id: Number(user?.id),
                    qrcode: Number(qrCode),
                };

                const [qrCodeList] = await Promise.all([
                    axios.post(BASE_URL + MASTER_API.QRCODE_ANALYSIS_LIST, params, config),
                ]);

                if (qrCodeList && qrCodeList?.data?.body?.status === COMMONVARIABLES.STATUS.INVALIDUSER) {
                    setIsLoading(false)
                    toast.error(qrCodeList?.data?.body?.message, { position: toast.POSITION.TOP_RIGHT });
                    UserLogout();
                }

                if (qrCodeList?.data?.body?.status === COMMONVARIABLES.STATUS.UNAUTHORIZED) {
                    setIsLoading(false)
                    const refresh = await getRefreshToken();
                    if (refresh) {
                        if (Number(refresh) === COMMONVARIABLES.STATUS.EXPIRED_TOKEN) {
                            UserLogout();
                        } else {
                            await udfnQrCodeAnalysisReport();
                        }
                    }
                }

                if (qrCodeList?.data?.body?.purchaseDetails) {
                    setPurchaseDetails(qrCodeList?.data?.body?.purchaseDetails)
                }
                if (qrCodeList?.data?.body?.stockTransferCount) {
                    setStockTransferCount(qrCodeList?.data?.body?.stockTransferCount)
                }
                if (qrCodeList?.data?.body?.inward) {
                    setInward(qrCodeList?.data?.body?.inward)
                }
                if (qrCodeList?.data?.body?.outward) {
                    setOutward(qrCodeList?.data?.body?.outward)
                }
                if (qrCodeList?.data?.body?.billingDetails) {
                    setBillingDetails(qrCodeList?.data?.body?.billingDetails)
                    var total = 0;
                    qrCodeList?.data?.body?.billingDetails.forEach(row => {
                        total = total + row.si_qty;
                    });
                    setSales(total)
                }
                setIsLoading(false)

            } catch (error) {
                setIsLoading(false)
                console.error('Error fetching master data:', error);
            }
        } else {
            toast.error(ERROR_MESSAGE.COMMON_REQUIRED, { position: toast.POSITION.TOP_RIGHT });
        }
    }

    useEffect(() => {
        setPurchaseDetails('');
        setStockTransferCount(0);
        setInward(0);
        setOutward(0);
        setBillingDetails([]);
        setSales(0);
    }, [qrCode]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        // udfnGstrReport();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <LoadingOverlay
                active={isLoading}
                spinner
                text='Loading ...'
            >
                <ToastContainer />
                <div className="card">
                    <div className="card-header">
                        <div className='col-sm-5'>
                            <h4 className="card-title">QrCode Analysis Report</h4>
                        </div>
                    </div>
                    <div className="card-body">
                        <div className='row'>
                            <div className='col-lg-3'>
                                <p className="mb-1">Qr Code <span className="text-danger star-txt">*</span></p>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={qrCode}
                                    onChange={(e) => setQrCode(e.target.value)}
                                    onKeyPress={(e) => udfnQrCodeValidation(e)}
                                    placeholder="Qr Code"
                                />
                            </div>
                            <div className="col-lg-3">
                                <Button className="me-2 py-2" variant="whatsapp" style={{ marginTop: "14%" }} onClick={udfnQrCodeAnalysisReport}>
                                    View
                                </Button>
                            </div>
                        </div>
                        <div className='row mt-5'>
                            <div className='col-lg-5'>
                                <Card className="text-white bg-secondary">
                                    <Card.Header className='p-2'>
                                        {/* <Card.Title className="text-white"> */}
                                        <h5 className="text-white mt-2">Purchase Details</h5>
                                        {/* </Card.Title> */}
                                    </Card.Header>
                                    <Card.Body className="p-3 mb-0">
                                        <Card.Text>
                                            <div className='row'>
                                                <div className='col-sm-5'>
                                                    Voucher No.
                                                </div>
                                                <div className='col-sm-7'>
                                                    {purchaseDetails?.voucher_number}
                                                </div>
                                                <div className='col-sm-5'>
                                                    Voucher Date
                                                </div>
                                                <div className='col-sm-7'>
                                                    {purchaseDetails?.voucher_date}
                                                </div>
                                                <div className='col-sm-5'>
                                                    Invoice No.
                                                </div>
                                                <div className='col-sm-7'>
                                                    {purchaseDetails?.inv_number}
                                                </div>
                                                <div className='col-sm-5'>
                                                    Invoice Date
                                                </div>
                                                <div className='col-sm-7'>
                                                    {purchaseDetails?.inv_date}
                                                </div>
                                            </div>
                                            <Badge bg=" badge-lg " className='badge-info light mt-3 mb-0'>Supplier Name : {purchaseDetails?.supplier_name}</Badge>
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </div>
                            <div className='col-lg-7'>
                                <div className='row mt-0'>
                                    <div className='col-lg-6 mb-2'>
                                        <div className="bg-success coin-holding flex-wrap px-1">
                                            <div className="coin-bx">
                                                <div className="d-flex align-items-center">
                                                    <div className="ms-3">
                                                        <h4 className="coin-font font-w600 mb-2 text-white">Stock Transfer</h4>
                                                        <p className="mb-0 text-white op-6">{stockTransferCount}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-6 mb-2'>
                                        <div className="bg-secondary coin-holding flex-wrap px-1">
                                            <div className="coin-bx">
                                                <div className="d-flex align-items-center">
                                                    <div className="ms-3">
                                                        <h4 className="coin-font font-w600 mb-2 text-white">Sales</h4>
                                                        <p className="mb-0 text-white op-6">{sales}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="mb-2">
                                                <div className="d-flex align-items-center">
                                                    <span className="btn-icon-left text-info udfn-eye-icon" onClick={() => setOpenCard(true)}>
                                                        <i className="fa fa-eye" />
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='row mt-2'>
                                    <div className='col-lg-6 mb-2'>
                                        <div className="bg-primary coin-holding flex-wrap px-1">
                                            <div className="coin-bx">
                                                <div className="d-flex align-items-center">
                                                    <div className="ms-3">
                                                        <h4 className="coin-font font-w600 mb-2 text-white">Stock Adjustment - Add</h4>
                                                        <p className="mb-0 text-white op-6">{inward}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-6 mb-2'>
                                        <div className="bg-warning coin-holding flex-wrap px-1">
                                            <div className="coin-bx">
                                                <div className="d-flex align-items-center">
                                                    <div className="ms-3">
                                                        <h4 className="coin-font font-w600 mb-2 text-white">Stock Adjustment - Sub</h4>
                                                        <p className="mb-0 text-white op-6">{outward}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div >
                            </div>
                        </div>
                        <Modal className="fade bd-example-modal-lg" show={openCard} onHide={setOpenCard} size="lg" backdrop="static" centered>
                            <Modal.Header>
                                <h4 className="text-black">Sales Details</h4>
                                <Button
                                    variant=""
                                    className="close"
                                    onClick={() => setOpenCard(false)}
                                >
                                    <span>&times;</span>
                                </Button>
                            </Modal.Header>
                            <Modal.Body>
                                <div className="table-responsive">
                                    <table className="table filtering-table table-responsive">
                                        <thead>
                                            <tr>
                                                <th>S.NO.</th>
                                                <th>Bill Date</th>
                                                <th>Bill No.</th>
                                                <th>Item Name</th>
                                                <th className='text-end'>Item Qty</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {billingDetails.map((row, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td>{index + 1}</td>
                                                        <td>{row.bill_date}</td>
                                                        <td>{row.bill_text}</td>
                                                        <td>{row.im_display_name}</td>
                                                        <td className='text-end'>{row.si_qty}</td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                    <p className='text-end' style={{ paddingRight: '1%' }}>{sales}</p>
                                </div>
                            </Modal.Body>
                        </Modal >
                    </div >
                </div >
            </LoadingOverlay >
        </>
    )
}

export default QrCodeAnalysisReport;