
const COMMONVARIABLES = {

    DROPDOWN_LABEL: {
        ACTIVE: "Active",
        CANCELLED: "Cancelled",
    },

    DROPDOWN_VALUE: {
        ACTIVE: 1,
        CANCELLED: 2,
    },

    VERSION: 'v1.0',

    "STATUS": {
        SUCCESS: 200,
        EXISTS: 201,
        UNAUTHORIZED: 401,
        INVALIDUSER: 402,
        EXPIRED_TOKEN: 498,
        ALREADY_IN_USE: 202,
        ERROR: 500,
        NETWORK_ERROR_STATUS: "ERR_NETWORK",
        BAD_REQUEST_ERROR_STATUS: "ERR_BAD_REQUEST",
        SERVICE_CATCH_STATUS: 500
    },
    "LABLEPRINTTYPE": [
        {
            label: '35*22',
            value: 1
        },
        {
            label: '25*25',
            value: 2
        }

    ],
    "SUPPLIERTYPE": [
        {
            label: 'Registered',
            value: 1
        },
        {
            label: 'Unregistered',
            value: 2
        }

    ],
    PDFIndia_Rupee_Formatter: (value) => new Intl.NumberFormat('en-IN', {
        currency: 'INR'
    }).format(value),

    India_Rupee_Formatter: (value) => new Intl.NumberFormat('en-IN', {
        currency: 'INR'
    }).format(value),

    India_Rupee_Formatter_Digits: (value) => new Intl.NumberFormat('en-IN', {
        minimumFractionDigits: 2
    }).format(value)
};

export default COMMONVARIABLES;

