import { ColumnFilter } from '../Common/ColumnFilter';
export const COLUMNS = [
	{
		Header: 'S.No.',
		Footer: 'S.No.',
		accessor: 'id',
		Filter: ColumnFilter,
		disableFilters: true,
	},
	{
		Header: 'Date',
		Footer: 'Date',
		accessor: 'bill_date',
		Filter: ColumnFilter,
		disableFilters: true,
	},
	{
		Header: 'Bill No.',
		Footer: 'Bill No.',
		accessor: 'bill_text',
		Filter: ColumnFilter,
		disableFilters: true,
	},
	{
		Header: 'Buyback Kg',
		Footer: 'Buyback Kg',
		accessor: 'buy_back_kg',
		Filter: ColumnFilter,
		disableFilters: true,
	},
	{
		Header: 'Buyback Amount',
		Footer: 'Buyback Amount',
		accessor: 'buy_back_amount',
		Filter: ColumnFilter,
		disableFilters: true,
	},
]

export const GROUPED_COLUMNS = [
]