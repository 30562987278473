import { format } from 'date-fns';
import { ColumnFilter } from '../Common/ColumnFilter';
export const COLUMNS = [
	{
		Header: 'S.No.',
		Footer: 'S.No.',
		accessor: 'id',
		Filter: ColumnFilter,
		disableFilters: true,
	},
	{
		Header: 'Date',
		Footer: 'Date',
		accessor: 'trans_date',
		Filter: ColumnFilter,
		disableFilters: true,
	},
	{
		Header: 'Type',
		Footer: 'Type',
		accessor: 'trans_type',
		Filter: ColumnFilter,
		disableFilters: true,
	},
	{
		Header: 'Inward',
		Footer: 'Inward',
		accessor: 'inward',
		Filter: ColumnFilter,
		disableFilters: true,
	},
	{
		Header: 'Outward',
		Footer: 'Outward',
		accessor: 'outward',
		Filter: ColumnFilter,
		disableFilters: true,
	},
	{
		Header: 'Closing',
		Footer: 'Closing',
		accessor: 'finalclosing',
		Filter: ColumnFilter,
		disableFilters: true,
	}
]

export const GROUPED_COLUMNS = [
]