import React, { useMemo, useState, useEffect, useRef } from 'react';
import { useTable, useGlobalFilter, useFilters, useSortBy, usePagination } from 'react-table';
import { COLUMNS } from '../../Filters/StockAdjustment/StockAdjustmentColumns';
import { Link } from "react-router-dom";
import { Button, Modal, Accordion } from 'react-bootstrap';
import '../../Filters/Common/filtering.css';
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import { UncontrolledTooltip } from "reactstrap"
import { useNavigate } from 'react-router-dom';
import Select from "react-select";
import { ToastContainer, toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import ERROR from "../../../components/Validation/ErrorMessage";
import axios from "axios";
import { getLoggedInUser, getRefreshToken } from '../../../../services/AuthService';
import { Logout } from '../../../../store/actions/AuthActions';
import COMMONVARIABLES from '../../Utilities/constants';
import COMMON_API from '../../../Api/CommonApi';
import LoadingOverlay from 'react-loading-overlay-ts';
import STOCK_ADJUSTMENT_API from '../../../Api/StockAdjustmentApi'
import { useReactToPrint } from 'react-to-print';
import swal from "sweetalert";
import QRCode from "react-qr-code";
import Logo from '../../../../pictures/bw1.png';
export const StockAdjustmentList = () => {
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    const columns = useMemo(() => COLUMNS, [])
    let navigate = useNavigate();
    // const data = useMemo(() => STOCK_ADJUSTMENT_DETAILS, [])
    const [data, setData] = useState([])
    const tableInstance = useTable({
        columns,
        data,
        initialState: { pageIndex: 0 }
    }, useFilters, useGlobalFilter, useSortBy, usePagination)

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        state,
        page,
        gotoPage,
        pageCount,
        pageOptions,
        nextPage,
        previousPage,
        canNextPage,
        canPreviousPage,
    } = tableInstance

    const { pageIndex } = state

    const [addOrSub, setAddOrSub] = useState('Add');
    const [branchName, setBranchName] = useState('');
    const [itemName, setItemName] = useState('');
    const [itemCode, setItemCode] = useState('');
    const [itemQty, setItemQty] = useState('');
    const [noOfPrints, setNoOfPrints] = useState('');
    const [remarks, setRemarks] = useState('');

    const [filterItemName, setFilterItemName] = useState(JSON.parse(localStorage.getItem('stockAdjustmentItemName')) || { label: "All", value: 0 })

    const [range, setRange] = useState(localStorage.getItem('stockAdjustmentRange') || null)

    var start_date = localStorage.getItem('stockAdjustmentRange') && new Date(localStorage.getItem('stockAdjustmentRange').split('-')[0]).toDateString();
    var end_date = localStorage.getItem('stockAdjustmentRange') && new Date(localStorage.getItem('stockAdjustmentRange').split('-')[1]).toDateString();

    const [rangeDate, setRangeDate] = useState(localStorage.getItem('stockAdjustmentRange') && localStorage.getItem('stockAdjustmentRange') !== "null" ? { startDate: new Date(start_date), endDate: new Date(end_date) } : { startDate: new Date(), endDate: new Date() })



    const [qrCode, setQrCode] = useState(localStorage.getItem('stockAdjustmentQrcode') || '')
    const [filterItemNameOptions, setFilterItemNameOptions] = useState([])
    const [branchNameOptions, setBranchNameOptions] = useState([])
    const [isLoading, setIsLoading] = useState(false);
    const [addCard, setAddCard] = useState(false);
    const [DetailsAddCard, setDetailsAddCard] = useState(false);
    const [buttonFlag, setButtonFlag] = useState(false);
    const [labelTypeName, setLabelTypeName] = useState({ label: '35*22', value: 1 })
    const labelTypeNameOptions = COMMONVARIABLES.LABLEPRINTTYPE;
    const [qrCodeItemDetails, setQRCodeItemDetails] = useState([]);
    const [printStatus, setPrintStatus] = useState(false)
    const [stockAdjustmentDetailsList, setStockAdjustmentDetailsList] = useState([]);
    const myid = "myqr"
    const myqrid = "myqrid"

    const buttonRef = useRef(null);
    // const [transferNoPrint, setTransferNoPrint] = useState('');
    // const [transferDatePrint, setTransferDatePrint] = useState("");
    // const [transferFromPrint, setTransferFromPrint] = useState('');
    const [itemDetailsPrint, setItemDetailsPrint] = useState([]);



    const [localUserDetails, setLocalUserDetails] = useState(null)
    const contentToPrint = useRef(null);
    const [isPrint, setIsPrint] = useState(0)
    useEffect(() => {
        var lcluserDetails = localStorage.getItem("userDetails")
        setLocalUserDetails(JSON.parse(lcluserDetails))

    }, [])  // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (isPrint == 1) {

            handlePrint(null, () => contentToPrint.current);
            setIsPrint(0)
        }



    }, [itemDetailsPrint])

    const handlePrint = useReactToPrint({
        documentTitle: "Print This Document",

        onBeforePrint: () => {
            //     let temp = document.querySelectorAll(".printfooter");
            //     // alert(temp.length)
            //     for (let i = 0; i < temp.length; i++) {
            //       if((i+1) == temp.length )
            //            temp[i].style.display = "block";

            //   }
        },
        onAfterPrint: () => {
            // if(transactionNo != 0)
            //      navigate("/billing-list")
        },
        removeAfterPrint: true,
    });

    const handleKeyDown = (event) => {
        // Check if the pressed key is the Tab key (key code 9)
        if (event.keyCode === 9) {
            // Prevent the default Tab behavior to prevent losing focus from the text input
            event.preventDefault();

            // Focus the button element
            buttonRef.current.focus();
        }
    };

    useEffect(() => {
        fetchMasterDataWithParams()
        bindStockAdjustmentData()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    async function fetchMasterDataWithParams() {
        try {
            setIsLoading(true)
            const user = getLoggedInUser()
            const branchParams = { status_code: 1, user_id: user?.id };
            const itemParams = { status_code: 0, user_id: user?.id };
            const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } };
            const [branchData, itemData] = await Promise.all([
                axios.post(BASE_URL + COMMON_API.BRANCH_MASTER_LIST, branchParams, config),
                axios.post(BASE_URL + COMMON_API.ITEM_MASTER_LIST, itemParams, config)

            ]);
            setIsLoading(false)
            if (branchData?.data?.body?.status === COMMONVARIABLES.STATUS.UNAUTHORIZED || itemData?.data?.body?.status === COMMONVARIABLES.STATUS.UNAUTHORIZED) {


                const refresh = await getRefreshToken();

                if (refresh) {
                    if (Number(refresh) === COMMONVARIABLES.STATUS.EXPIRED_TOKEN) {
                        Logout();
                    } else {
                        await fetchMasterDataWithParams();
                    }
                }
            }

            if (branchData?.data?.body?.status === COMMONVARIABLES.STATUS.SERVICE_CATCH_STATUS) {
                toast.error(ERROR.API_ERROR.SERVICE_CATCH_ERROR, { position: toast.POSITION.TOP_RIGHT });
            }

            if (branchData?.data?.body?.branchList || itemData?.data?.body?.itemList) {
                setFilterItemNameOptions([{ im_display_name: "All", im_item_code: 0 }, ...itemData?.data?.body?.itemList])
                setBranchNameOptions(branchData?.data?.body?.branchList)

            }


        } catch (error) {
            console.error('Error fetching master data:', error);
            if (error.code === COMMONVARIABLES.STATUS.NETWORK_ERROR_STATUS) {
                toast.error(ERROR.API_ERROR.NETWORK_ERROR, { position: toast.POSITION.TOP_RIGHT });
            }
            if (error.code === COMMONVARIABLES.STATUS.BAD_REQUEST_ERROR_STATUS) {
                toast.error(ERROR.API_ERROR.BAD_REQUEST_ERROR, { position: toast.POSITION.TOP_RIGHT });
            }
        }
    }
    const udfnPrint = async (editRow) => {
        swal({
            // title: "Are you sure?",
            text:
                "Are you sure you want to print?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                udfnStockAdjustmentDetails(editRow,1)
            } else {
                // swal("Your Stock is safe!");
            }
        })
    }

    const qtyValidation = (event) => {
        const regex = /^[0-9]*$/;
        const input = event.target.value + event.key;
        if (!regex.test(input) || input.length > 4) {
            event.preventDefault();
        }
    };

    const udfnSaveStockAdjustment = async (e) => {
        try {
            e.preventDefault();
            var error = "";
            if (branchName === '' || itemName === '' || itemCode === '' || itemQty === '' || remarks === '') {
                error = ERROR.COMMON_REQUIRED;
            } else if (itemQty > 0 && addOrSub === 'Add' && noOfPrints === '') {
                error = ERROR.COMMON_REQUIRED;
            } else if (addOrSub === 'Sub') {
                if (itemQty > itemName.stock) {
                    error = ERROR.STOCK_TRANSFER_ADD.ITEM_QTY_VALID
                }
            }
            else {
                error = '';
            }
            if (error === '') {
                setButtonFlag(true)
                var localFy = localStorage.getItem("fy")

                const user = getLoggedInUser()
                var stockAdjustmentParams = {
                    "adjustment_type": addOrSub === 'Add' ? 1 : 2,
                    "user_id": user?.id,
                    "branch_code": Number(branchName?.value || 0),
                    "item_code": Number(itemName?.qs_item_code || 0),
                    "qr_code": Number(itemCode),
                    "qty": Number(itemQty),
                    "remarks": remarks,
                    "company_code": user?.company_code,
                    "fy": Number(localFy)
                }

                const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } };
                const [saveData] = await Promise.all([
                    axios.post(BASE_URL + STOCK_ADJUSTMENT_API.SAVE_STOCK_ADJUSTMENT, stockAdjustmentParams, config)

                ]);
                setIsLoading(false)
                if (saveData?.data?.body?.status === COMMONVARIABLES.STATUS.UNAUTHORIZED) {
                    fetchMasterDataWithParams()
                    const refresh = await getRefreshToken();

                    if (refresh) {
                        if (Number(refresh) === COMMONVARIABLES.STATUS.EXPIRED_TOKEN) {
                            Logout();
                        } else {
                            await udfnSaveStockAdjustment(e);
                        }
                    }
                }

                if (saveData?.data?.body?.status === COMMONVARIABLES.STATUS.SERVICE_CATCH_STATUS) {
                    toast.error(ERROR.API_ERROR.SERVICE_CATCH_ERROR, { position: toast.POSITION.TOP_RIGHT });
                }

                if (saveData?.data?.body?.status === COMMONVARIABLES.STATUS.SUCCESS) {
                    swal({
                        title: "Success",
                        text:
                            "Stock adjustment saved successfully",
                        icon: "success",
                        //buttons: true,
                        dangerMode: true,
                    }).then((resp) => {
                        if (resp) {
                            if (saveData?.data?.body?.qrcodeList && saveData?.data?.body?.qrcodeList.length > 0) {
                                swal({
                                    text:
                                        "Are you sure you want print?",
                                    icon: "warning",
                                    buttons: true,
                                    dangerMode: true,
                                }).then((willDelete) => {
                                    if (willDelete) {
                                        setPrintStatus(true)
                                        setQRCodeItemDetails(saveData?.data?.body?.qrcodeList)

                                    } else {
                                        setAddCard(false)
                                        clearAddData();
                                        bindStockAdjustmentData()
                                    }
                                })
                            } else {
                                setAddCard(false)
                                clearAddData();
                                bindStockAdjustmentData()
                            }

                        }
                    })

                } else {
                    setIsLoading(false)
                }
            } else {
                toast.error(error, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        }
        catch (e) {
            setButtonFlag(false)
            if (e.code === COMMONVARIABLES.STATUS.NETWORK_ERROR_STATUS) {
                toast.error(ERROR.API_ERROR.NETWORK_ERROR, { position: toast.POSITION.TOP_RIGHT });
            }
            if (e.code === COMMONVARIABLES.STATUS.BAD_REQUEST_ERROR_STATUS) {
                toast.error(ERROR.API_ERROR.BAD_REQUEST_ERROR, { position: toast.POSITION.TOP_RIGHT });
            }
        }
    }

    async function fetchItemListFromQrCode(qrCode) {
        try {
            const user = getLoggedInUser()

            const itemListParams = { qr_code: Number(qrCode), user_id: user?.id, branch_code: Number(branchName?.value || 0) };


            const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } };
            const [itemData] = await Promise.all([
                axios.post(BASE_URL + COMMON_API.ITEM_LIST_FROM_QRCODE, itemListParams, config),

            ]);

            if (itemData?.data?.body?.status === COMMONVARIABLES.STATUS.UNAUTHORIZED) {


                const refresh = await getRefreshToken();

                if (refresh) {
                    if (Number(refresh) === COMMONVARIABLES.STATUS.EXPIRED_TOKEN) {
                        Logout();
                    } else {
                        await fetchItemListFromQrCode(qrCode);
                    }
                }
            }

            if (itemData?.data?.body?.status === COMMONVARIABLES.STATUS.SERVICE_CATCH_STATUS) {
                toast.error(ERROR.API_ERROR.SERVICE_CATCH_ERROR, { position: toast.POSITION.TOP_RIGHT });
            }

            if (itemData?.data?.body?.itemList) {
                setItemName(itemData?.data?.body?.itemList[0])
            }


        } catch (error) {
            console.error('Error fetching master data:', error);
            if (error.code === COMMONVARIABLES.STATUS.NETWORK_ERROR_STATUS) {
                toast.error(ERROR.API_ERROR.NETWORK_ERROR, { position: toast.POSITION.TOP_RIGHT });
            }
            if (error.code === COMMONVARIABLES.STATUS.BAD_REQUEST_ERROR_STATUS) {
                toast.error(ERROR.API_ERROR.BAD_REQUEST_ERROR, { position: toast.POSITION.TOP_RIGHT });
            }
        }
    }

    const onBlurQrCode = (qrCode) => {
        if (qrCode) {
            fetchItemListFromQrCode(qrCode)
        }

    }

    function clearAddData() {
        setItemName('');
        setItemCode('');
        setItemQty('');
        setBranchName('')
        setRemarks('')
        setAddOrSub('Add')
        setNoOfPrints('')
        setLabelTypeName({ label: '35*22', value: 1 });
        setPrintStatus(false)
    }

    async function bindStockAdjustmentData() {
        try {
            setIsLoading(true)
            const user = getLoggedInUser()
            var varFromDate = new Date().toDateString()
            var varToDate = new Date().toDateString()

            if (range !== "null" && range !== null) {
                varFromDate = new Date(range.split('-')[0]).toDateString()
                varToDate = new Date(range.split('-')[1]).toDateString()
            }

            const params = { item_code: filterItemName?.value, user_id: user?.id, qr_code: Number(qrCode), from_date: varFromDate, to_date: varToDate };

            const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } };
            const [stockTransferList] = await Promise.all([
                axios.post(BASE_URL + STOCK_ADJUSTMENT_API.STOCK_ADJUSTMENT_LIST, params, config),

            ]);
            setIsLoading(false)

            if (stockTransferList?.data?.body?.status === COMMONVARIABLES.STATUS.UNAUTHORIZED) {

                const refresh = await getRefreshToken();

                if (refresh) {
                    if (Number(refresh) === COMMONVARIABLES.STATUS.EXPIRED_TOKEN) {
                        Logout();
                    } else {
                        await bindStockAdjustmentData();
                    }
                }
            }

            if (stockTransferList?.data?.body?.status === COMMONVARIABLES.STATUS.SERVICE_CATCH_STATUS) {
                toast.error(ERROR.API_ERROR.SERVICE_CATCH_ERROR, { position: toast.POSITION.TOP_RIGHT });
            }

            if (stockTransferList?.data?.body?.stockTransferList) {
                setData(stockTransferList?.data?.body?.stockTransferList)
            }


        } catch (error) {
            setIsLoading(false)
            console.error('Error fetching master data:', error);
            if (error.code === COMMONVARIABLES.STATUS.NETWORK_ERROR_STATUS) {
                toast.error(ERROR.API_ERROR.NETWORK_ERROR, { position: toast.POSITION.TOP_RIGHT });
            }
            if (error.code === COMMONVARIABLES.STATUS.BAD_REQUEST_ERROR_STATUS) {
                toast.error(ERROR.API_ERROR.BAD_REQUEST_ERROR, { position: toast.POSITION.TOP_RIGHT });
            }
        }
    }

    const udfnDelete = async (transfer_no, branch_code) => {
        swal({
            title: "Are you sure?",
            text:
                "Once deleted, you will not be able to recover this Stock Adjustment!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                udfnStockAdjustmentDelete(transfer_no, branch_code);
            } else {
                swal("Your Stock is safe!");
            }
        })
    }

    const udfnStockAdjustmentDelete = async (transfer_no, branch_code) => {
        try {
            setIsLoading(true);
            var apiUrl = BASE_URL + STOCK_ADJUSTMENT_API.DELETE_STOCK_ADJUSTMENT;
            const user = getLoggedInUser()
            const data = {
                user_id: user.id,
                company_code: user.company_code,
                transaction_no: transfer_no,
                branch_code: branch_code
            };

            const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } };
            const response = await axios.post(apiUrl, data, config);
            setIsLoading(false);

            if (response && response.status === COMMONVARIABLES.STATUS.INVALIDUSER) {
                toast.error(response.data.body.message, { position: toast.POSITION.TOP_RIGHT });
                localStorage.removeItem("userDetails");
                localStorage.removeItem("stateList");
                localStorage.removeItem("fy");
                window.location.reload();
                return;
            }

            if (response?.data?.body?.status === COMMONVARIABLES.STATUS.UNAUTHORIZED) {
                const refresh = await getRefreshToken();

                if (refresh) {
                    if (Number(refresh) === COMMONVARIABLES.STATUS.EXPIRED_TOKEN) {
                        Logout();
                    } else {
                        await udfnStockAdjustmentDelete(transfer_no, branch_code);
                    }
                }
            }

            if (response && response?.data?.body?.status === COMMONVARIABLES.STATUS.EXISTS) {
                toast.error(response.data.body.message, { position: toast.POSITION.TOP_RIGHT });
            }

            if (response?.data?.body?.status === COMMONVARIABLES.STATUS.SERVICE_CATCH_STATUS) {
                toast.error(ERROR.API_ERROR.SERVICE_CATCH_ERROR, { position: toast.POSITION.TOP_RIGHT });
            }

            if (response?.data?.body?.status === COMMONVARIABLES.STATUS.SUCCESS) {
                swal(response.data.body.message, {
                    icon: "success",
                });
                await bindStockAdjustmentData();
            } else {
                toast.error(response.data.body.message, { position: toast.POSITION.TOP_RIGHT });
            }
        } catch (error) {
            // Handle error
            setIsLoading(false);
            console.error("Stocktransferadjustment list API Error:", error);
            if (error.code === COMMONVARIABLES.STATUS.NETWORK_ERROR_STATUS) {
                toast.error(ERROR.API_ERROR.NETWORK_ERROR, { position: toast.POSITION.TOP_RIGHT });
            }
            if (error.code === COMMONVARIABLES.STATUS.BAD_REQUEST_ERROR_STATUS) {
                toast.error(ERROR.API_ERROR.BAD_REQUEST_ERROR, { position: toast.POSITION.TOP_RIGHT });
            }
        }
    };
    function printQRCode(qrcodeList) {
        const printHtml = window.open('', 'PRINT', 'height=auto,width=100%')
        let html = ''
        let itemDetails = ''
        const user = getLoggedInUser()
        for (let j = 0; j < Number(noOfPrints); j++) {
            for (let i = 0; i < qrcodeList.length; i++) {
                const myids = "myqr"
                const getid = myids + qrcodeList[i].sa_qr_code
                const innerContents = document.getElementById(getid).innerHTML
                itemDetails += `
					<div class="float-child col-4  ml-5 mt-3">				
						<div class="align-center">
							<div class="heading">${user.label_title}</div>
						</div>

						<div class="float-container botton-align ">`
                if (qrcodeList[i].qs_label_details) {
                    itemDetails += `<div class="qr_div">
													<div class="rotated"> ${qrcodeList[i].qs_label_details}</div>
												</div>`
                }
                itemDetails += `<div class="float-qrcode ml-2">
												<div class="qr_div ">
													<div> ${innerContents}</div>
												
												</div>
												<div class="qr_div bold-design size-10 align-center">${qrcodeList[i].sa_qr_code}</div>
											</div>
											<div class="float-details ml-2">
												<div class="bold-design size-9 wor-wrap">${qrcodeList[i].im_display_name}</div>
												<div class="bold-design size-15 m-10">₹${qrcodeList[i].qs_selling_rate}</div>
											</div>
						</div>			
					</div>`
            }
        }

        html += `<html>
					<head>
						<title>StockAdjustment Print</title>
						<style>
						.rotated {
							writing-mode: tb-rl;
							transform: rotate(-180deg);
							font-size: 10px;
						}
				
						.qr_div {
							display: flex;
							align-items: center;
							justify-content: center;
						}
				
						body {
							font-family: Arial, Helvetica, sans-serif;
							width: 416px !important; 
						}
				
						.row {
							width: 100%;
							/* margin-top: 10px; */
						}
				
						.column {
							float: left;
						}
				
						@media all {
							.page-break {
								display: none
							}
						}
						@media print {
				
							body {
							margin-top: 1 !important;
							color: #000;
							}
				
						}
						.heading {
							font-size: 13px !important;
							font-weight: bold;
							text-align: center
						}
				
						.align-center {
							flex-direction: column;
							text-align: left;
						}
				
						.align-left {
							text-align: left;
						}
				
						.align-right {
							text-align: right;
							font-size: large;
						}
				
						.paddding-space {
							/* padding-bottom: 15px */
						}
				
						.center {
							text-align: center;
						}
				
						.padding {
							padding-left: 10px
						}
				
						.qrcode {
							padding-left: 50px
						}
				
						.botton-align {
							/* padding-top: 10px */
						}
				
						.botton {
							padding-top: 10px
						}
				
						.size-10 {
							font-size: 10px !important;
						}
				
						.size-11 {
							font-size: 11px !important;
						}

                        .size-9 {
							font-size: 9px !important;
						}
				
						.size-12 {
							font-size: 12px !important;
						}
				
						.size-15 {
							font-size: 15px !important;
						}
				
						.size-small {
							font-size: 10px !important;
							text-align: center !important;
							font-weight: bold;
						}
						.bold-design {
							font-weight: bold;
						}
				
						.float-child {
							display: flex;
							flex-direction: column;
						}
				
						.float-container {
							width: 100%;
							display: flex;
							flex-direction: row;
						}
				
						.float-details {
							width: 65%;
							display: flex;
							flex-direction: column;
							padding-left: 2px;
							text-align: left;
						}
				
						.float-qrcode {
							width: 35%;
							display: flex;
							flex-direction: column;
						}
				
						.marleft {
							padding-left: 20px;
						}
				
						.marleft-10 {
							padding-left: 10px;
						}
				
						.marright {
							padding-right: 20px;
						}
				
						.col-4 {
							flex: 0 0 auto;
							width: 133px !important;
							height: 83px;
						}
				
						.parent {
							display: flex;
							flex-wrap: wrap;
							flex-direction: row;
						}
				
						.mb-15 {
							margin-bottom: 15px;
						}
				
						.mt-5 {
							margin-bottom: 5px;
						}
						.ml-5 {
							margin-left: 5px;
						}
						.ml-10{
							margin-left: 10px;
						}
						.mt-5{
							margin-top: 5px;
						}
						.ml-2 {
							margin-left: 2px;
						}
						.mt-3 {
							margin-top: 3px;
						}
						.mt-2 {
							margin-top: 2px;
						}
						.wor-wrap {
                            text-wrap: wrap;
                            width: 50px;
                            overflow-wrap: break-word;
                            height: 26px;
                            overflow: hidden;
                        }
                        .mar-top{
                            margin-top: -5px;
                        }
                        .m-10{
                            margin-top: 10px
                        }
						</style>
					</head>
					<body onload="window.print();window.close()">
						<div class="row  ml-10">
							<div class='paddding-space'>
								<div class="float-container parent">
									${itemDetails}					
								</div>
							</div>				
						</div>		
					</body>
                </html>`
        console.log(html, "html")
        printHtml.document.write(html)
        printHtml.document.close()
        printHtml.print()
        printHtml.close()
        setAddCard(false);
        clearAddData();
        bindStockAdjustmentData();
        return true
    }

    function printSingleQRCode(qrcodeList) {
        const printHtml = window.open('', 'PRINT', 'height=auto,width=100%')
        let html = ''
        let itemDetails = ''
        const user = getLoggedInUser()
        for (let j = 0; j < Number(noOfPrints); j++) {
            for (let i = 0; i < qrcodeList.length; i++) {
                const myids = "myqrid"
                const getid = myids + qrcodeList[i].sa_qr_code
                const innerContents = document.getElementById(getid).innerHTML

                itemDetails += `<div class="float-child">
			<div class="float-container align-center justfy-center">
				<div class="heading">${user.label_title}</div>
			</div>

			<div class="float-container botton-align ">`
                if (qrcodeList[i].qs_label_details) {
                    itemDetails += `<div class="qr_div marleft-10">
												<div class="rotated"> ${qrcodeList[i].qs_label_details}</div>
											</div>`
                }
                itemDetails += `
				<div class="float-qrcode marleft-10">
					<div class="qr_div ">
						<div class=""> ${innerContents}</div>

					</div>
					<div class="qr_div bold-design size-large align-center">${qrcodeList[i].sa_qr_code}</div>
				</div>
				<div class="float-details marleft-10">
					<div class="bold-design size-large wor-wrap">${qrcodeList[i].im_display_name}</div>
					<div class="bold-design botton size-Xlarge">₹${COMMONVARIABLES.PDFIndia_Rupee_Formatter(qrcodeList[i].qs_selling_rate)}</div>
				</div>
			</div>
		</div>`

            }
        }

        html += `<html>
					<head>
						<title>Purchase Print</title>
						<style>
						.rotated {
							writing-mode: tb-rl;
							transform: rotate(-180deg);
							font-size: 8px;
						}
				
						.qr_div {
							display: flex;
							align-items: center;
							justify-content: center;
						}
				
						body {
							font-family: Arial, Helvetica, sans-serif;
							width: 384px;
                            margin: 0px;
						}
				
						.row {
							width: 100%;
                            height: 56.69px;
                            display: block;
						}
				
						.column {
							float: left;
						}
				
						@media all {
							.page-break {
								display: none
							}
						}
				
						.heading {
							font-size: 12px !important;
							font-weight: bold;
							text-align: center;
						}
				
						.align-center {
							flex-direction: column;
							text-align: center;
						}
				
						.align-left {
							text-align: left;
						}
				
						.align-right {
							text-align: right;
							font-size: large;
						}

						.paddding-space {
							float:right;
						}
				
						.center {
							text-align: center;
						}
				
						.padding {
							padding-left: 10px
						}
				
						.qrcode {
							padding-left: 50px
						}
				
						.botton-align {

						}
				
						.botton {
							padding-top: 5px
						}
				
						.size-large {
							font-size: 8px !important;
						}
				
						.size-Xlarge {
							font-size: 13px !important;
						}
				
						.size-20 {
							font-size: 20px !important;
						}
				
						.size-small {
							font-size: 10px !important;
							text-align: center !important;
							font-weight: bold;
						}
				
						.color-style {
							padding-top: 20px;
							font-size: 20px !important;
							font-weight: bold;
						}
				
						.bold-design {
							font-weight: bold;
						}
				
						.float-child {
							width: 100%;
							display: flex;
							flex-direction: column;
                            padding: 1px;
						}
				
						.float-container {
							width: 100%;
							display: flex;
							flex-direction: row;
						}
				
						.float-details {
							width: 65%;
							display: flex;
							flex-direction: column;
							padding-left: 2px;
							text-align: left;
						}
				
						.float-qrcode {
							width: 35%;
							display: flex;
							flex-direction: column;
						}
				
						.marleft {
							padding-left: 20px;
						}
				
						.marleft-10 {
							padding-left: 5px;
						}
				
						.marright {
							padding-right: 20px;
						}
				
						.marright-10 {
							padding-right: 10px;
						}
				
						.col-4 {
							flex: 0 0 auto;
							width: 33.333%;
						}
				
						.parent {
							display: block;
							flex-wrap: wrap;
							flex-direction: row;
						}
				
						.mt-10 {
							margin-top: 10px;
						}
				
						.pb-15 {
							padding-bottom: 13px;
						}
				
						.mt-5 {
							margin-top: 5px;
						}
				
						.mb-5 {

						}
				
						.mb-2 {
							margin-bottom: 2px;
						}
				
						.justfy-center {
							justify-content: center;
						}
						.p-tb{
							padding: 5px 0px;
						}
						.ml-20{
							margin-left:20px;
						}
						.wor-wrap {
							text-wrap: wrap;
    						width: 10px;
						}
						</style>
					</head>
					<body onload="window.print();window.close()">
						<div class="row">
							<div class='paddding-space'>
								<div class="float-container parent">
									${itemDetails}					
								</div>
							</div>				
						</div>		
					</body>
                </html>`
        console.log(html, "html")
        printHtml.document.write(html)
        printHtml.document.close()
        printHtml.print()
        printHtml.close()
        setAddCard(false);
        clearAddData();
        bindStockAdjustmentData();
        return true
    }

    async function udfnStockAdjustmentDetails(row,is_print) {
        try {
            const user = getLoggedInUser()
            setIsLoading(true)
            setStockAdjustmentDetailsList([])

            const params = {
                user_id: Number(user?.id),
                trans_no: Number(row.sa_trans_no),
                // branch_code: Number(row.sa_branch_code),
                date: String(row.adjustment_date)
            };

            const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } };

            const [stockAdjustmentDetails] = await Promise.all([
                axios.post(BASE_URL + STOCK_ADJUSTMENT_API.STOCK_ADJUSTMENT_DETAILS, params, config),
            ]);
            setIsLoading(false)

            if (stockAdjustmentDetails && stockAdjustmentDetails?.data?.body?.status === COMMONVARIABLES.STATUS.INVALIDUSER) {
                toast.error(stockAdjustmentDetails?.data?.body?.message, { position: toast.POSITION.TOP_RIGHT });
                Logout();
            }

            if (stockAdjustmentDetails?.data?.body?.status === COMMONVARIABLES.STATUS.UNAUTHORIZED) {
                const refresh = await getRefreshToken();
                if (refresh) {
                    if (Number(refresh) === COMMONVARIABLES.STATUS.EXPIRED_TOKEN) {
                        Logout();
                    } else {
                        await udfnStockAdjustmentDetails(row,is_print);
                    }
                }
            }

            if (stockAdjustmentDetails?.data?.body?.stockAdjustmentDetails) {
                console.log(stockAdjustmentDetails?.data?.body,'stockAdjustmentDetails?.data?.body')
                if(is_print  != 1)
                {
                    setStockAdjustmentDetailsList(stockAdjustmentDetails?.data?.body?.stockAdjustmentDetails)              
                    setDetailsAddCard(true)
                }
                else{
                    setItemDetailsPrint(stockAdjustmentDetails?.data?.body?.stockAdjustmentDetails)
                    setIsPrint(1)
                }
              
            }

        } catch (error) {
            setIsLoading(false)
            console.error('Error fetching master data:', error);
        }
    }

    useEffect(() => {
        if (printStatus && qrCodeItemDetails && qrCodeItemDetails.length > 0) {
            if (labelTypeName.value === 1) {
                printQRCode(qrCodeItemDetails);
            } else {
                printSingleQRCode(qrCodeItemDetails);
            }

        }
    }, [qrCodeItemDetails])

    useEffect(() => {
        localStorage.setItem('stockAdjustmentItemName', JSON.stringify(filterItemName))
        localStorage.setItem('stockAdjustmentRange', range)
        localStorage.setItem('stockAdjustmentQrcode', qrCode)
    }, [range, filterItemName, qrCode])  // eslint-disable-line react-hooks/exhaustive-deps
    return (
        <>

            <Modal className="fade bd-example-modal-xl" show={DetailsAddCard} onHide={setDetailsAddCard} backdrop="static" size='xl'>
                <div className="" role="document">
                    <div className="">
                        <form >
                            <div className="modal-header">
                                <div className='row profit-report-row'>
                                    <div className='col-sm-5'>
                                        <h4 className="card-title mt-4">Stock Adjustment Details</h4>
                                    </div>
                                    <div className='col-sm-1'>
                                        <button type="button" className="close btn" onClick={() => setDetailsAddCard(false)}><span>×</span></button>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-body">
                                <i className="flaticon-cancel-12 close" data-dismiss="modal"></i>
                                <div className="add-contact-box">
                                    <div className="add-contact-content">
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th>
                                                        S.NO.
                                                    </th>
                                                    <th>
                                                        Item Name
                                                    </th>
                                                    <th>
                                                        QR Code
                                                    </th>
                                                    <th>
                                                        Adjustment Type
                                                    </th>
                                                    <th>
                                                        Quantity
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody className="" >
                                                {stockAdjustmentDetailsList.map((row, index) => {
                                                    return (
                                                        <tr>
                                                            <td>{index + 1}</td>
                                                            <td>{row.im_display_name}</td>
                                                            <td>{row.qr_code}</td>
                                                            <td>{row.adjustment_type}</td>
                                                            <td className='text-end'>{row.qs_quantity}</td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <Button className="me-2" variant="whatsapp" onClick={() => { setDetailsAddCard(false) }}>
                                    Discard
                                </Button>
                            </div>
                        </form>
                    </div>
                </div>
            </Modal>
            <LoadingOverlay
                active={isLoading}
                spinner
                text='Loading ...'
            >
                <ToastContainer />
                <div className="card">
                    <div className="card-header">
                        <div className='col-sm-5'>
                            <h4 className="card-title">Stock Adjustment List</h4>
                        </div>
                        <div className='col-sm-3'>
                            <Link to="/stock-adjustment-add" className="btn btn-primary">
                                <i className="fa fa-plus"></i> &nbsp;Add Stock Adjustment
                            </Link>
                        </div>
                    </div>
                    <div className="card-body">
                        <Accordion className="accordion accordion-rounded-stylish accordion-bordered  accordion-active-header" defaultActiveKey="0">

                            <Accordion.Item>
                                <Accordion.Header className="accordion-header accordion-header-primary">
                                    <h5>Filters</h5>
                                    <span className="accordion-header-indicator "></span>
                                </Accordion.Header>
                                <Accordion.Collapse className="accordion__body">
                                    <div className="accordion-body">
                                        <div className="row">
                                            <div className="col-md-4 mb-3">
                                                <div className="example rangeDatePicker">
                                                    <p className="mb-1">Select From Date And To Date</p>

                                                    <DateRangePicker
                                                        onApply={(e) => {
                                                            setRange(e.target.value)
                                                        }}
                                                        initialSettings={rangeDate}
                                                    >
                                                        <input type="text" className="form-control input-daterange-timepicker" styles={{ height: "90%" }} />
                                                    </DateRangePicker>
                                                </div>
                                            </div>
                                            <div className="col-md-3 mb-3">
                                                <p className="mb-1">Item Name</p>

                                                <Select
                                                    defaultValue={filterItemName}
                                                    onChange={setFilterItemName}
                                                    options={filterItemNameOptions.map((option) => ({
                                                        label: option?.im_display_name,
                                                        value: option?.im_item_code,
                                                    }))}
                                                    showContent="true"
                                                    open="true"
                                                    className="my-react-select-container"
                                                    theme={theme => ({
                                                        ...theme,
                                                        borderRadius: 0,
                                                        colors: {
                                                            ...theme.colors,
                                                            primary25: '#b3b3b3',
                                                            primary: 'black',
                                                            neutral80: "data-theme-version" === "dark" ? 'white' : ''
                                                        },
                                                    })}
                                                />
                                            </div>
                                            {/* </div>
        <div className="row"> */}
                                            <div className="col-md-3 mb-3">
                                                <p className="mb-1">QR Code</p>
                                                <input
                                                    value={qrCode}
                                                    onChange={(e) => {
                                                        setQrCode(e.target.value)
                                                    }}
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="QR Code"
                                                />
                                            </div>
                                            <div className="col-md-2 mt-3">
                                                <Button className="me-2" variant="whatsapp" style={{ marginTop: "6%" }} onClick={() => bindStockAdjustmentData()}>
                                                    List{" "}
                                                    <span className="btn-icon-right">
                                                        <i className="fa fa-filter" />
                                                    </span>
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </Accordion.Collapse>
                            </Accordion.Item>
                        </Accordion>
                        <div className="table-responsive">
                            {/* have to add when needed */}
                            {/* <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} /> */}
                            <div className='row'>
                                <div className='col-sm-9'>
                                    {/* <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} /> */}
                                </div>
                            </div>
                            <table {...getTableProps()} className="table filtering-table table-responsive-lg">
                                <thead>
                                    {headerGroups.map(headerGroup => (
                                        <tr {...headerGroup.getHeaderGroupProps()}>
                                            <th>
                                                <p className='tbl-heading-txt'>S.NO.</p>
                                            </th>
                                            {headerGroup.headers.map(column => (
                                                <>
                                                    {column.Header === 'Quantity' || column.Header === 'No.Of.Prints' ?
                                                        <th className='text-center'>
                                                            {column.render('Header')}
                                                            {column.canFilter ? column.render('Filter') : null}
                                                        </th>
                                                        :
                                                        <th>
                                                            {column.render('Header')}
                                                            {column.canFilter ? column.render('Filter') : null}
                                                        </th>
                                                    }
                                                </>
                                            ))}
                                            <th>
                                                <p className='tbl-heading-txt'>Action</p>
                                            </th>
                                        </tr>
                                    ))}
                                </thead>
                                <tbody {...getTableBodyProps()} className="" >
                                    {page.map((row, index) => {
                                        return (
                                            <tr key={index}>
                                                <td>{index + 1}</td>
                                                <td>{row.original.adjustment_date}</td>
                                                <td>{row.original.bm_branch_name}</td>
                                                <td className='text-end'>{row.original.itemcount}</td>
                                                <td>
                                                    <Link className="btn btn-warning shadow btn-xs sharp me-2"   onClick={() => udfnPrint(row.original)}>
                                                        <i className="fa fa-print" id={'printtooltip' + index} />
                                                        <UncontrolledTooltip placement="top" target={'printtooltip' + index}>
                                                            Print
                                                        </UncontrolledTooltip>
                                                    </Link>
                                                    <Link className="btn btn-secondary shadow btn-xs sharp me-2"
                                                        onClick={() => udfnStockAdjustmentDetails(row.original,0)}
                                                    >
                                                        <i className="fa fa-eye" id={'edittooltip' + index}></i>
                                                        <UncontrolledTooltip placement="top" target={'edittooltip' + index}>
                                                            View
                                                        </UncontrolledTooltip>
                                                    </Link>
                                                    <Link className="btn btn-danger shadow btn-xs sharp" onClick={() => udfnDelete(row.original.sa_trans_no, row.original.sa_branch_code)}>
                                                        <i className="fa fa-trash" id={'deletetooltip' + index}></i>
                                                        <UncontrolledTooltip placement="top" target={'deletetooltip' + index}>
                                                            Delete
                                                        </UncontrolledTooltip>
                                                    </Link>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                            <div className="d-flex justify-content-between">
                                <span>
                                    Page{' '}
                                    <strong>
                                        {pageIndex + 1} of {pageOptions.length}
                                    </strong>{''}
                                </span>
                                <span className="table-index">
                                    Go to page : {' '}
                                    <input type="number"
                                        className="ms-2"
                                        defaultValue={pageIndex + 1}
                                        onChange={e => {
                                            const pageNumber = e.target.value ? Number(e.target.value) - 1 : 0
                                            gotoPage(pageNumber)
                                        }}
                                    />
                                </span>
                            </div>
                            <div className="text-center">
                                <div className="filter-pagination  mt-3">
                                    <button className=" previous-button" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>{'<<'}</button>

                                    <button className="previous-button" onClick={() => previousPage()} disabled={!canPreviousPage}>
                                        Previous
                                    </button>
                                    <button className="next-button" onClick={() => nextPage()} disabled={!canNextPage}>
                                        Next
                                    </button>
                                    <button className=" next-button" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>{'>>'}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal className="fade bd-example-modal-lg" show={addCard} onHide={setAddCard} size="lg">
                    <Modal.Header>
                        <h4 className="text-black">Add Stock Adjustment</h4>
                        <Button
                            variant=""
                            className="close"
                            onClick={() => setAddCard(false)}
                        >
                            <span>&times;</span>
                        </Button>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="basic-form">
                            <form onSubmit={(e) => e.preventDefault()}>
                                <div className="row">
                                    <div className="form-group mb-3 col-md-6">
                                        <label className="text-black font-300">Adjustment Type</label>
                                        <span className="text-danger star-txt">*</span>
                                        <fieldset className="form-group">
                                            <div className="row">
                                                <div className="col-sm-4">
                                                    <div className="form-check checkbox-success">
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="gridRadios"
                                                            value={addOrSub}
                                                            onChange={(e) => setAddOrSub('Add')}
                                                            defaultChecked
                                                        />
                                                        <label className="form-check-label">
                                                            Add
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="col-sm-5 px-0">
                                                    <div className="form-check checkbox-danger px-0">
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="gridRadios"
                                                            value={addOrSub}
                                                            onChange={(e) => setAddOrSub('Sub')}
                                                        />
                                                        <label className="form-check-label">
                                                            Sub
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </fieldset>
                                    </div>
                                    <div className="form-group mb-3 col-md-6">
                                        <label className="text-black font-300">Branch Name</label>
                                        <span className="text-danger star-txt">*</span>
                                        <Select
                                            defaultValue={branchName}
                                            value={branchName}
                                            onChange={setBranchName}
                                            options={branchNameOptions.map((option) => ({
                                                label: option.bm_branch_name,
                                                value: option.bm_branch_code,
                                            }))}
                                            showContent="true"
                                            open="true"
                                            className="my-react-select-container"
                                            theme={theme => ({
                                                ...theme,
                                                borderRadius: '6px',
                                                colors: {
                                                    ...theme.colors,
                                                    primary25: '#b3b3b3',
                                                    primary: 'black',
                                                    neutral80: "data-theme-version" === "dark" ? 'white' : ''
                                                },
                                            })}
                                        />
                                    </div>

                                    <div className="form-group mb-3 col-md-6">
                                        <label className="text-black font-300">Item Code</label>
                                        <span className="text-danger star-txt">*</span>
                                        <input
                                            type="number"
                                            className="form-control"
                                            placeholder="Item Code"
                                            value={itemCode}
                                            onChange={(e) => {
                                                setItemName('')
                                                setItemCode(e.target.value)
                                            }}
                                            onBlur={(e) => onBlurQrCode(e.target.value)}
                                        />
                                    </div>
                                    <div className="form-group mb-3 col-md-6">
                                        <div className="currentStock">
                                            <div>
                                                <label className="text-black font-w300">Item Name</label>
                                                <span className="text-danger star-txt">*</span>
                                            </div>
                                            {itemName && <div>
                                                <label className="text-black font-w300">Current Stock : </label>
                                                <span className="text-danger star-txt stock-transfer-span">{itemName?.stock || '0'}</span>
                                            </div>}
                                        </div>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Item Name"
                                            value={itemName?.im_display_name || ''}
                                            readOnly
                                        />

                                    </div>

                                    <div className="form-group mb-3 col-md-6">
                                        <label className="text-black font-300">Quantity</label>
                                        <span className="text-danger star-txt">*</span>
                                        <input
                                            type="number"
                                            className="form-control"
                                            placeholder="Quantity"
                                            value={itemQty}
                                            onKeyPress={(e) => qtyValidation(e)}
                                            onChange={(e) => setItemQty(e.target.value)}
                                        />
                                    </div>
                                    <div className="form-group mb-3 col-md-6">
                                        <label className="text-black font-300">Remarks</label>
                                        <span className="text-danger star-txt">*</span>
                                        <textarea className="form-control text-area-udf"
                                            placeholder="Remarks"
                                            value={remarks}
                                            onChange={(e) => setRemarks(e.target.value)} />
                                    </div>


                                    {
                                        addOrSub === 'Add' ?
                                            <div className="form-group mb-3 mt-3 col-md-6">
                                                <label className="text-black font-300">No of Prints</label>
                                                <span className="text-danger star-txt">*</span>
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    placeholder="No of Prints"
                                                    onKeyPress={(e) => qtyValidation(e)}
                                                    value={noOfPrints}
                                                    onChange={(e) => setNoOfPrints(e.target.value)}
                                                />
                                            </div>
                                            : ""
                                    }

                                    <div className="form-group mb-3 mt-3 col-md-6">
                                        <label className="text-black font-300">Label Size</label>
                                        <Select
                                            defaultValue={labelTypeName}
                                            value={labelTypeName}
                                            onChange={setLabelTypeName}
                                            options={labelTypeNameOptions}
                                            onKeyDown={handleKeyDown}
                                            showContent="true"
                                            open="true"
                                            className="my-react-select-container"
                                            theme={theme => ({
                                                ...theme,
                                                borderRadius: '6px',
                                                colors: {
                                                    ...theme.colors,
                                                    primary25: '#b3b3b3',
                                                    primary: 'black',
                                                    neutral80: "data-theme-version" === "dark" ? 'white' : ''
                                                },
                                            })}
                                        />
                                    </div>

                                </div>
                            </form>
                        </div>
                    </Modal.Body >
                    <Modal.Footer>

                        <Button
                            variant="danger"
                            onClick={() => setAddCard(false)}
                            className="me-2"
                        >
                            Discard
                        </Button>
                        <Button className="me-2" variant="whatsapp" ref={buttonRef} onClick={udfnSaveStockAdjustment} disabled={buttonFlag}>
                            Save
                        </Button>

                    </Modal.Footer>
                </Modal >
                {qrCodeItemDetails.map((row) => (
                    <div id={myid + row.sa_qr_code} className='color-picker' style={{ display: 'none' }}>
                        <QRCode
                            size={45}
                            value={row.sa_qr_code.toString()}
                        />
                    </div>
                ))}

                {qrCodeItemDetails.map((row) => (
                    <div id={myqrid + row.sa_qr_code} className='color-picker' style={{ display: 'none' }}>
                        <QRCode
                            size={35}
                            value={row.sa_qr_code.toString()}
                        />
                    </div>
                ))}

<div style={{ visibility: 'hidden', height: 0 }}>
				<div ref={contentToPrint} className="row purchsecontentToPrint" style={{ padding: '16px' }} >
					<table>


						<style>
							{`
       @page {    
        size: A5 portrait;    
        padding-left: 8px;    
        padding-right: 0px;
        // margin-top: 50px;
        //  margin-bottom: 150px;
    
   
    }  
    
        
    
        `}
						</style>

						<thead ><tr><td>


							<div class="printheader" >
								<div className="col-12">
                                <div className="row text-center">
										
									
                                        <div className="brand-logo p-16 col-4">
                                                   <img className="logo-abbr me-3" src={Logo} alt="" style={{ width: '170px', height: '100px' }} />
       
                                               </div> 
       
       
                                               <div className='col-8 text-end'>
                                               <span>
                                                   <strong style={{ fontSize: 20, fontWeight: 700 }} className="d-block text-black"> {localUserDetails?.company_name}</strong>
       
       
                                               </span>
       
                                               <small className="text-black" style={{ fontSize: 16, lineHeight: 1.2 }}>
       
                                                   {localUserDetails?.address}{', '}{localUserDetails?.city}
                                                   <br></br>
                                                   {localUserDetails?.state_name}
                                                   <br></br>
                                                   {'Email'}{' : '}{localUserDetails?.email_id}
                                                   <br></br>
                                                   {'Contact No'}{' : '}{localUserDetails?.mobile_no}
       
                                                   <strong className="d-block">GSTIN : {localUserDetails?.gstin}</strong>
       
                                               </small>
                                           </div> 
                                           <div class="h_line"></div>
                                           <strong style={{ fontSize: 16, fontWeight: 700 }} className="d-block text-center text-black"> {'Stock Adjustment'}</strong>
       
       
                                           </div>
									<div class="h_line"></div>
									<div className='row'>
										<div className='col-6'>
											<span>
												<strong style={{ fontSize: 16, fontWeight: 700 }} className="d-block text-black"> {'Branch:'}</strong>


											</span>
									
											<small className="text-black" style={{ fontSize: 16, lineHeight: 1.2 }}>

												
												{itemDetailsPrint[0]?.bm_branch_name}
												<br></br>
												{itemDetailsPrint[0]?.bm_address}
												<br></br>
												{itemDetailsPrint[0]?.bm_city}
											

											</small>
										

										</div>
										<div className='col-6 text-end'>
														<div className='row'>


									<div className="col-12">
										<span className="d-block text-black"> Delivery Note No. : <strong>{itemDetailsPrint[0]?.sa_trans_no}</strong></span>
									</div>

									<div className="text-end col-12">


										<span className="d-block text-black"> Dated : <strong>{itemDetailsPrint[0]?.adjustment_date}</strong></span>

									</div>
								</div>
										</div>
									</div>
								</div>
								<div class="h_line"></div>




							</div>
						</td></tr></thead>
						<tbody ><tr><td>

							<div className="col-lg-12 ">

								<div className="table-responsive">

									<table className="table table-striped">
										<thead >
											<tr>
												<th className="center">#</th>
                                                <th className="center text-black">Type</th>
												<th style={{ textAlign: 'center !important' }} className="center text-black">Item</th>
												<th className="center text-black">Qty</th>
												<th className="text-end text-black" >Rate</th>
												<th className="text-end text-black">Amount</th>
											</tr>
										</thead>
										<tbody>


											{
												itemDetailsPrint?.length > 0 ?
                                                itemDetailsPrint?.map((row, index) => {
														return (
															<tr key={index}>
																<td className='text-black'>{index + 1}</td>
                                                                <td className='text-black'>{row.adjustment_type}<br></br>
                                                                {row.sa_remarks != '' ?<span>{'('}{row.sa_remarks}{')'}</span>:null}
                                                                </td>
																<td className='text-black'>{row.im_display_name}<br></br>
																{row.qr_code != '0' ?<span>{'('}{row.qr_code}{')'}</span>:null}
																</td>
                                                                <td className='center text-black'>{row.qs_quantity} {row.um_display_name}</td>
																<td className='text-end text-black'>{COMMONVARIABLES.India_Rupee_Formatter_Digits(row.qs_selling_rate)}</td>
																<td className='text-end text-black'>{COMMONVARIABLES.India_Rupee_Formatter_Digits(row.qs_selling_rate * row.qs_quantity)}</td>

															</tr>
														)
													})
													: ''
											}


										</tbody>
									</table>




								</div>

							</div>






						</td></tr></tbody>

						<tfoot>
							{/* <tr><td className='text-black' style={{ paddingRight: 16, paddingLeft: 16 }}>Remarks : {narrationPrint}</td></tr> */}
							<tr>
								<td> <strong style={{ fontSize: 16, fontWeight: 700, paddingRight: 16, paddingLeft: 16  }} className="d-block text-black"> {'NOT FOR SALE'}</strong></td>
							</tr>
							<tr><td>
								<div class="footer-space" style={{ padding: 16 }}>



									<div className="printfooter" >
										<div class="h_line"></div>
										<div className='' style={{ marginTop: '35px' }}>
											<div className='row'>
												<div className='col-4'>

													<strong>Checked by</strong>
												</div>
												<div className='col-4'>

													<strong>Verified by</strong>
												</div>
												<div className='col-4 text-end'>

													<strong>Authorised Signatory</strong>
												</div>
											</div>
										</div>


									</div> </div>
							</td></tr></tfoot>
					</table>


				</div>
			</div>
            </LoadingOverlay>
        </>
    )

}
export default StockAdjustmentList;