import React, { useMemo, useState, useEffect, useRef } from 'react';
import { Link } from "react-router-dom";
import { useTable, useGlobalFilter, useFilters, useSortBy, usePagination } from 'react-table';
import { COLUMNS } from '../Filters/Unit/UnitColumns';
import { Modal, Button } from 'react-bootstrap';
import '../Filters/Common/filtering.css';
/// Bootstrap
import Select from "react-select";
import ERROR_MESSAGE from '../Validation/ErrorMessage';
import { ToastContainer, toast } from "react-toastify";
import { Accordion } from "react-bootstrap";
import "react-toastify/dist/ReactToastify.css";
import axios from 'axios';
import {
    getLoggedInUser, getRefreshToken, getFinancialYear
} from '../../../services/AuthService';
import { UserLogout } from '../../layouts/nav/Logout';
import COMMONVARIABLES from '../Utilities/constants';
import LoadingOverlay from 'react-loading-overlay-ts';
import DateRangePicker from "react-bootstrap-daterangepicker";
import COMMON_API from '../../Api/CommonApi';
import CHANGE_PRICE_LIST_API from '../../Api/ChangePriceListApi';
import swal from "sweetalert";
import QRCode from "react-qr-code";

const ChangePriceList = () => {

    //Modal box
    const [addCard, setAddCard] = useState(false);
    const [itemName, setItemName] = useState(JSON.parse(localStorage.getItem('changePriceItemName')) || { label: "All", value: 0 });
    const [cardItemName, setCardItemName] = useState('');
    const [itemCode, setItemCode] = useState(localStorage.getItem('changePriceItemcode') || '');
    const [cardItemCode, setCardItemCode] = useState('');
    const [qsItemCode, setQsItemCode] = useState('');
    const [labelDetails, setLabelDetails] = useState('');
    const [currentSellingPrice, setCurrentSellingPrice] = useState('');
    const [newSellingPrice, setNewSellingPrice] = useState('');
    const [noOfLabelToPrint, setNoOfLabelToPrint] = useState('');
    const [availableQty, setAvailableQty] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [buttonFlag, setButtonFlag] = useState(true);
    const [itemNameOptions, setItemNameOptions] = useState([])
    const [changePriceList, setChangePriceList] = useState([]);
    const [range, setRange] = useState(localStorage.getItem('changePriceRange') || null)

    var start_date = localStorage.getItem('changePriceRange') && new Date(localStorage.getItem('changePriceRange').split('-')[0]).toDateString();
    var end_date = localStorage.getItem('changePriceRange') && new Date(localStorage.getItem('changePriceRange').split('-')[1]).toDateString();

    const [rangeDate, setRangeDate] = useState(localStorage.getItem('changePriceRange') && localStorage.getItem('changePriceRange') !== "null" ? { startDate: new Date(start_date), endDate: new Date(end_date) } : { startDate: new Date(), endDate: new Date() })

    const columns = useMemo(() => COLUMNS, [])
    const data = changePriceList;
    const tableInstance = useTable({
        columns,
        data,
        initialState: { pageIndex: 0 }
    }, useFilters, useGlobalFilter, useSortBy, usePagination)

    const {
        getTableProps,
        getTableBodyProps,
        prepareRow,
        state,
        page,
        gotoPage,
        pageCount,
        pageOptions,
        nextPage,
        previousPage,
        canNextPage,
        canPreviousPage,
    } = tableInstance

    const { pageIndex } = state

    const [labelTypeName, setLabelTypeName] = useState({ label: '35*22', value: 1 })
    const labelTypeNameOptions = COMMONVARIABLES.LABLEPRINTTYPE;
    const [qrCodeItemDetails, setQRCodeItemDetails] = useState([]);
    const [printStatus, setPrintStatus] = useState(false)
    const myid = "myqr"
    const myqrid = "myqrid"

    const buttonRef = useRef(null);

    const handleKeyDown = (event) => {
        // Check if the pressed key is the Tab key (key code 9)
        if (event.keyCode === 9) {
            // Prevent the default Tab behavior to prevent losing focus from the text input
            event.preventDefault();

            // Focus the button element
            buttonRef.current.focus();
        }
    };

    //Add Suumit data
    const udfnFromValidate = (event) => {

        var error = false;
        var errorMsg = '';
        // All Input Fields Validation
        if (cardItemCode === '' || newSellingPrice === '' || noOfLabelToPrint === '') {
            error = true;
            errorMsg = ERROR_MESSAGE.COMMON_REQUIRED;
        }

        if (!error) {
            setButtonFlag(false);
            udfnChangePriceCreate();
        } else {
            toast.error(errorMsg, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    };

    async function fetchMasterDataWithParams() {
        try {

            const user = getLoggedInUser()

            const itemParams = { status_code: 0, user_id: user?.id };
            const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } };
            const [itemData] = await Promise.all([
                axios.post(process.env.REACT_APP_BASE_URL + COMMON_API.ITEM_MASTER_LIST, itemParams, config)

            ]);

            if (itemData?.data?.body?.status === COMMONVARIABLES.STATUS.UNAUTHORIZED) {


                const refresh = await getRefreshToken();

                if (refresh) {
                    if (Number(refresh) === COMMONVARIABLES.STATUS.EXPIRED_TOKEN) {
                        UserLogout();
                    } else {
                        await fetchMasterDataWithParams();
                    }
                }
            }

            if (itemData?.data?.body?.status === COMMONVARIABLES.STATUS.SERVICE_CATCH_STATUS) {
                toast.error(ERROR_MESSAGE.API_ERROR.SERVICE_CATCH_ERROR, { position: toast.POSITION.TOP_RIGHT });
            }

            if (itemData?.data?.body?.itemList) {
                setItemNameOptions([{ im_display_name: "All", im_item_code: 0 }, ...itemData?.data?.body?.itemList])

            }


        } catch (error) {

            console.error('Error fetching master data:', error);
            if (error.code === COMMONVARIABLES.STATUS.NETWORK_ERROR_STATUS) {
                toast.error(ERROR_MESSAGE.API_ERROR.NETWORK_ERROR, { position: toast.POSITION.TOP_RIGHT });
            }
            if (error.code === COMMONVARIABLES.STATUS.BAD_REQUEST_ERROR_STATUS) {
                toast.error(ERROR_MESSAGE.API_ERROR.BAD_REQUEST_ERROR, { position: toast.POSITION.TOP_RIGHT });
            }
        }
    }

    async function fetchItemListFromQrCode(qrCode) {
        try {
            const user = getLoggedInUser()

            const itemListParams = { qr_code: Number(qrCode), user_id: user?.id, branch_code: Number(0) };


            const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } };
            const [itemData] = await Promise.all([
                axios.post(process.env.REACT_APP_BASE_URL + COMMON_API.ITEM_LIST_FROM_QRCODE, itemListParams, config),

            ]);

            if (itemData?.data?.body?.status === COMMONVARIABLES.STATUS.UNAUTHORIZED) {


                const refresh = await getRefreshToken();

                if (refresh) {
                    if (Number(refresh) === COMMONVARIABLES.STATUS.EXPIRED_TOKEN) {
                        UserLogout()
                    } else {
                        await fetchItemListFromQrCode(qrCode);
                    }
                }
            }

            if (itemData?.data?.body?.status === COMMONVARIABLES.STATUS.SERVICE_CATCH_STATUS) {
                toast.error(ERROR_MESSAGE.API_ERROR.SERVICE_CATCH_ERROR, { position: toast.POSITION.TOP_RIGHT });
            }

            if (itemData?.data?.body?.itemList) {
                setCardItemName(itemData?.data?.body?.itemList[0].im_item_name);
                setLabelDetails(itemData?.data?.body?.itemList[0].label_details);
                setCurrentSellingPrice(itemData?.data?.body?.itemList[0].selling_rate)
                setAvailableQty(itemData?.data?.body?.itemList[0].stock)
                setQsItemCode(itemData?.data?.body?.itemList[0].qs_item_code)
            }


        } catch (error) {
            console.error('Error fetching master data:', error);
            if (error.code === COMMONVARIABLES.STATUS.NETWORK_ERROR_STATUS) {
                toast.error(ERROR_MESSAGE.API_ERROR.NETWORK_ERROR, { position: toast.POSITION.TOP_RIGHT });
            }
            if (error.code === COMMONVARIABLES.STATUS.BAD_REQUEST_ERROR_STATUS) {
                toast.error(ERROR_MESSAGE.API_ERROR.BAD_REQUEST_ERROR, { position: toast.POSITION.TOP_RIGHT });
            }
        }
    }

    const udfnClearForm = () => {
        setCardItemName('')
        setQsItemCode('')
        setCardItemCode('')
        setLabelDetails('')
        setCurrentSellingPrice('')
        setNewSellingPrice('')
        setNoOfLabelToPrint('')
        setAvailableQty(0);
        setAddCard(false);
        setLabelTypeName({ label: '35*22', value: 1 });
        setPrintStatus(false)
        setButtonFlag(true)
    };

    const udfnChangePriceList = async () => {
        const user = getLoggedInUser();
        setIsLoading(true);
        var varFromDate = new Date().toDateString()
        var varToDate = new Date().toDateString()
        if (range != "null" && range !== null) {
            varFromDate = new Date(range.split('-')[0]).toDateString()
            varToDate = new Date(range.split('-')[1]).toDateString()
        }
        const data = {
            user_id: user.id, item_code: itemName.value, qr_code: Number(itemCode), from_date: String(varFromDate), to_date: String(varToDate)
        };
        const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } };

        try {
            const response = await axios.post(process.env.REACT_APP_BASE_URL + CHANGE_PRICE_LIST_API.CHANGE_PRICE_LIST, data, config);
            setIsLoading(false);
            if (response && response?.data?.body?.status === COMMONVARIABLES.STATUS.INVALIDUSER) {
                toast.error(response.data.body.message, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
                UserLogout();
            }

            if (response?.data?.body?.status === COMMONVARIABLES.STATUS.UNAUTHORIZED) {
                const refresh = await getRefreshToken();

                if (refresh) {
                    if (Number(refresh) === COMMONVARIABLES.STATUS.EXPIRED_TOKEN) {
                        UserLogout();
                    } else {
                        await udfnChangePriceList();
                    }
                }
            }

            if (response?.data?.body?.status === COMMONVARIABLES.STATUS.SERVICE_CATCH_STATUS) {
                toast.error(ERROR_MESSAGE.API_ERROR.SERVICE_CATCH_ERROR, { position: toast.POSITION.TOP_RIGHT });
            }

            if (response?.data?.body?.ChangePriceList) {
                setChangePriceList(response.data.body.ChangePriceList);
                toast.success(response.data.body.message, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
                setIsLoading(false);
            } else {
                toast.error(response.data.body.message, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
            }
        } catch (error) {
            // Handle error
            setIsLoading(false);
            console.error("change price list API Error:", error);
            if (error.code === COMMONVARIABLES.STATUS.NETWORK_ERROR_STATUS) {
                toast.error(ERROR_MESSAGE.API_ERROR.NETWORK_ERROR, { position: toast.POSITION.TOP_RIGHT });
            }
            if (error.code === COMMONVARIABLES.STATUS.BAD_REQUEST_ERROR_STATUS) {
                toast.error(ERROR_MESSAGE.API_ERROR.BAD_REQUEST_ERROR, { position: toast.POSITION.TOP_RIGHT });
            }
        }

    };

    const udfnChangePriceCreate = async () => {
        setIsLoading(true);
        const user = getLoggedInUser();
        const financialYear = getFinancialYear();
        var uploadData = {};
        var apiUrl = '';
        uploadData = {
            user_id: user.id,
            company_code: user.company_code,
            item_code: Number(qsItemCode),
            financial_year: financialYear,
            current_selling_price: Number(currentSellingPrice),
            new_selling_price: Number(newSellingPrice),
            qr_code: Number(cardItemCode),
            label_details: labelDetails
        };
        apiUrl = CHANGE_PRICE_LIST_API.CHANGE_PRICE_LIST_CREATE;

        const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } };

        try {
            const response = await axios.post(process.env.REACT_APP_BASE_URL + apiUrl, uploadData, config);
            setIsLoading(false);
            if (response && response?.data?.body?.status === COMMONVARIABLES.STATUS.INVALIDUSER) {
                toast.error(response.data.body.message, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
                UserLogout();
            }

            if (response?.data?.body?.status === COMMONVARIABLES.STATUS.UNAUTHORIZED) {
                const refresh = await getRefreshToken();
                if (refresh) {
                    if (Number(refresh) === COMMONVARIABLES.STATUS.EXPIRED_TOKEN) {
                        UserLogout();
                    } else {
                        await udfnChangePriceCreate();
                    }
                }
            }

            if (response && response?.data?.body?.status === COMMONVARIABLES.STATUS.EXISTS) {
                setButtonFlag(true);
                toast.error(response.data.body.message, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
            }

            if (response && response?.data?.body?.status === COMMONVARIABLES.STATUS.ALREADY_IN_USE) {
                toast.error(response.data.body.message, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
            }

            if (response?.data?.body?.status === COMMONVARIABLES.STATUS.SERVICE_CATCH_STATUS) {
                toast.error(ERROR_MESSAGE.API_ERROR.SERVICE_CATCH_ERROR, { position: toast.POSITION.TOP_RIGHT });
            }

            if (response?.data?.body?.status === COMMONVARIABLES.STATUS.SUCCESS) {
                toast.success(response.data.body.message, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
                // setButtonFlag(true);
                swal({
                    text:
                        "Are you sure you want print?",
                    icon: "warning",
                    buttons: true,
                    dangerMode: true,
                }).then((willDelete) => {
                    if (willDelete) {
                        setPrintStatus(true)
                        setQRCodeItemDetails(response?.data?.body?.qrcodeList)

                    } else {
                        udfnClearForm();
                        setAddCard(true);
                        setItemCode('');
                        udfnChangePriceList();
                    }
                })

            } else {
                toast.error(response.data.body.message, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
                setAddCard(true);
                setButtonFlag(true);
            }
        } catch (error) {
            // Handle error
            setIsLoading(false);
            setButtonFlag(true);
            console.error("change price create API Error:", error);
            if (error.code === COMMONVARIABLES.STATUS.NETWORK_ERROR_STATUS) {
                toast.error(ERROR_MESSAGE.API_ERROR.NETWORK_ERROR, { position: toast.POSITION.TOP_RIGHT });
            }
            if (error.code === COMMONVARIABLES.STATUS.BAD_REQUEST_ERROR_STATUS) {
                toast.error(ERROR_MESSAGE.API_ERROR.BAD_REQUEST_ERROR, { position: toast.POSITION.TOP_RIGHT });
            }
        }
    };

    const onBlurQrCode = (qrCode) => {
        if (qrCode) {
            fetchItemListFromQrCode(qrCode)
        }
    }

    useEffect(() => {
        fetchMasterDataWithParams();
        udfnChangePriceList();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    function printQRCode(qrcodeList) {
        const printHtml = window.open('', 'PRINT', 'height=auto,width=100%')
        let html = ''
        let itemDetails = ''
        const user = getLoggedInUser()
        for (let j = 0; j < Number(noOfLabelToPrint); j++) {
            for (let i = 0; i < qrcodeList.length; i++) {
                const myids = "myqr"
                const getid = myids + qrcodeList[i].cr_qr_code
                const innerContents = document.getElementById(getid).innerHTML
                itemDetails += `
					<div class="float-child col-4  ml-5 mt-3">				
						<div class="align-center">
							<div class="heading">${user.label_title}</div>
						</div>

						<div class="float-container botton-align ">`
                if (qrcodeList[i].cr_label_details) {
                    itemDetails += `<div class="qr_div">
													<div class="rotated"> ${qrcodeList[i].cr_label_details}</div>
												</div>`
                }
                itemDetails += `<div class="float-qrcode ml-2">
												<div class="qr_div ">
													<div> ${innerContents}</div>
												
												</div>
												<div class="qr_div bold-design size-10 align-center">${qrcodeList[i].cr_qr_code}</div>
											</div>
											<div class="float-details  ml-2">
												<div class="bold-design size-9 wor-wrap">${qrcodeList[i].im_display_name}</div>
												<div class="bold-design size-15 m-10">₹${qrcodeList[i].cr_new_selling_price}</div>
											</div>
						</div>			
					</div>`
            }
        }

        html += `<html>
					<head>
						<title>Change Price List</title>
						<style>
						.rotated {
							writing-mode: tb-rl;
							transform: rotate(-180deg);
							font-size: 10px;
						}
				
						.qr_div {
							display: flex;
							align-items: center;
							justify-content: center;
						}
				
						body {
							font-family: Arial, Helvetica, sans-serif;
							width: 416px !important; 
						}
				
						.row {
							width: 100%;
							/* margin-top: 10px; */
						}
				
						.column {
							float: left;
						}
				
						@media all {
							.page-break {
								display: none
							}
						}
						@media print {
				
							body {
							margin-top: 1 !important;
							color: #000;
							}
				
						}
						.heading {
							font-size: 13px !important;
							font-weight: bold;
							text-align: center
						}
				
						.align-center {
							flex-direction: column;
							text-align: left;
						}
				
						.align-left {
							text-align: left;
						}
				
						.align-right {
							text-align: right;
							font-size: large;
						}
				
						.paddding-space {
							/* padding-bottom: 15px */
						}
				
						.center {
							text-align: center;
						}
				
						.padding {
							padding-left: 10px
						}
				
						.qrcode {
							padding-left: 50px
						}
				
						.botton-align {
							/* padding-top: 10px */
						}
				
						.botton {
							padding-top: 10px
						}
				
						.size-10 {
							font-size: 10px !important;
						}
				
						.size-11 {
							font-size: 11px !important;
						}

                        .size-9 {
							font-size: 9px !important;
						}
				
						.size-12 {
							font-size: 12px !important;
						}
				
						.size-15 {
							font-size: 15px !important;
						}
				
						.size-small {
							font-size: 10px !important;
							text-align: center !important;
							font-weight: bold;
						}
						.bold-design {
							font-weight: bold;
						}
				
						.float-child {
							display: flex;
							flex-direction: column;
						}
				
						.float-container {
							width: 100%;
							display: flex;
							flex-direction: row;
						}
				
						.float-details {
							width: 65%;
							display: flex;
							flex-direction: column;
							padding-left: 2px;
							text-align: left;
						}
				
						.float-qrcode {
							width: 35%;
							display: flex;
							flex-direction: column;
						}
				
						.marleft {
							padding-left: 20px;
						}
				
						.marleft-10 {
							padding-left: 10px;
						}
				
						.marright {
							padding-right: 20px;
						}
				
						.col-4 {
							flex: 0 0 auto;
							width: 133px !important;
							height: 83px;
						}
				
						.parent {
							display: flex;
							flex-wrap: wrap;
							flex-direction: row;
						}
				
						.mb-15 {
							margin-bottom: 15px;
						}
				
						.mt-5 {
							margin-bottom: 5px;
						}
						.ml-5 {
							margin-left: 5px;
						}
						.ml-10{
							margin-left: 10px;
						}
						.mt-5{
							margin-top: 5px;
						}
						.ml-2 {
							margin-left: 2px;
						}
						.mt-3 {
							margin-top: 3px;
						}
						.mt-2 {
							margin-top: 2px;
						}
						.wor-wrap {
                            text-wrap: wrap;
                            width: 50px;
                            overflow-wrap: break-word;
                            height: 26px;
                            overflow: hidden;
                        }
                        .mar-top{
                            margin-top: -5px;
                        }
                        .m-10{
                            margin-top: 10px
                        }
						</style>
					</head>
					<body onload="window.print();window.close()">
						<div class="row  ml-10">
							<div class='paddding-space'>
								<div class="float-container parent">
									${itemDetails}					
								</div>
							</div>				
						</div>		
					</body>
                </html>`
        console.log(html, "html")
        printHtml.document.write(html)
        printHtml.document.close()
        printHtml.print()
        printHtml.close()
        udfnClearForm();
        setAddCard(true);
        setItemCode('');
        udfnChangePriceList();
        return true
    }

    function printSingleQRCode(qrcodeList) {
        const printHtml = window.open('', 'PRINT', 'height=auto,width=100%')
        let html = ''
        let itemDetails = ''
        const user = getLoggedInUser()
        for (let j = 0; j < Number(noOfLabelToPrint); j++) {
            for (let i = 0; i < qrcodeList.length; i++) {
                const myids = "myqrid"
                const getid = myids + qrcodeList[i].cr_qr_code
                const innerContents = document.getElementById(getid).innerHTML

                itemDetails += `<div class="float-child">
			<div class="float-container align-center justfy-center">
				<div class="heading">${user.label_title}</div>
			</div>

			<div class="float-container botton-align ">`
                if (qrcodeList[i].cr_label_details) {
                    itemDetails += `<div class="qr_div marleft-10">
												<div class="rotated"> ${qrcodeList[i].cr_label_details}</div>
											</div>`
                }
                itemDetails += `
				<div class="float-qrcode marleft-10">
					<div class="qr_div ">
						<div class=""> ${innerContents}</div>

					</div>
					<div class="qr_div bold-design size-large align-center">${qrcodeList[i].cr_qr_code}</div>
				</div>
				<div class="float-details marleft-10">
					<div class="bold-design size-large wor-wrap">${qrcodeList[i].im_display_name}</div>
					<div class="bold-design botton size-Xlarge">₹${COMMONVARIABLES.PDFIndia_Rupee_Formatter(qrcodeList[i].cr_new_selling_price)}</div>
				</div>
			</div>
		</div>`

            }
        }

        html += `<html>
					<head>
						<title>Purchase Print</title>
						<style>
						.rotated {
							writing-mode: tb-rl;
							transform: rotate(-180deg);
							font-size: 8px;
						}
				
						.qr_div {
							display: flex;
							align-items: center;
							justify-content: center;
						}
				
						body {
							font-family: Arial, Helvetica, sans-serif;
							width: 384px;
                            margin: 0px;
						}
				
						.row {
							width: 100%;
                            height: 56.69px;
                            display: block;
						}
				
						.column {
							float: left;
						}
				
						@media all {
							.page-break {
								display: none
							}
						}
				
						.heading {
							font-size: 12px !important;
							font-weight: bold;
							text-align: center;
						}
				
						.align-center {
							flex-direction: column;
							text-align: center;
						}
				
						.align-left {
							text-align: left;
						}
				
						.align-right {
							text-align: right;
							font-size: large;
						}

						.paddding-space {
							float:right;
						}
				
						.center {
							text-align: center;
						}
				
						.padding {
							padding-left: 10px
						}
				
						.qrcode {
							padding-left: 50px
						}
				
						.botton-align {

						}
				
						.botton {
							padding-top: 5px
						}
				
						.size-large {
							font-size: 8px !important;
						}
				
						.size-Xlarge {
							font-size: 13px !important;
						}
				
						.size-20 {
							font-size: 20px !important;
						}
				
						.size-small {
							font-size: 10px !important;
							text-align: center !important;
							font-weight: bold;
						}
				
						.color-style {
							padding-top: 20px;
							font-size: 20px !important;
							font-weight: bold;
						}
				
						.bold-design {
							font-weight: bold;
						}
				
						.float-child {
							width: 100%;
							display: flex;
							flex-direction: column;
                            
                            padding: 1px;
						}
				
						.float-container {
							width: 100%;
							display: flex;
							flex-direction: row;
						}
				
						.float-details {
							width: 65%;
							display: flex;
							flex-direction: column;
							padding-left: 2px;
							text-align: left;
						}
				
						.float-qrcode {
							width: 35%;
							display: flex;
							flex-direction: column;
						}
				
						.marleft {
							padding-left: 20px;
						}
				
						.marleft-10 {
							padding-left: 5px;
						}
				
						.marright {
							padding-right: 20px;
						}
				
						.marright-10 {
							padding-right: 10px;
						}
				
						.col-4 {
							flex: 0 0 auto;
							width: 33.333%;
						}
				
						.parent {
							display: block;
							flex-wrap: wrap;
							flex-direction: row;
						}
				
						.mt-10 {
							margin-top: 10px;
						}
				
						.pb-15 {
							padding-bottom: 13px;
						}
				
						.mt-5 {
							margin-top: 5px;
						}
				
						.mb-5 {

						}
				
						.mb-2 {
							margin-bottom: 2px;
						}
				
						.justfy-center {
							justify-content: center;
						}
						.p-tb{
							padding: 5px 0px;
						}
						.ml-20{
							margin-left:20px;
						}
						.wor-wrap {
							text-wrap: wrap;
    						width: 10px;
						}
						</style>
					</head>
					<body onload="window.print();window.close()">
						<div class="row">
							<div class='paddding-space'>
								<div class="float-container parent">
									${itemDetails}					
								</div>
							</div>				
						</div>		
					</body>
				</html>`
        console.log(html, "html")
        printHtml.document.write(html)
        printHtml.document.close()
        printHtml.print()
        printHtml.close()
        udfnClearForm();
        setAddCard(true);
        setItemCode('');
        udfnChangePriceList();
        return true
    }

    useEffect(() => {
        if (printStatus && qrCodeItemDetails && qrCodeItemDetails.length > 0) {
            if (labelTypeName.value === 1) {
                printQRCode(qrCodeItemDetails);
            } else {
                printSingleQRCode(qrCodeItemDetails);
            }

        }
    }, [qrCodeItemDetails]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        localStorage.setItem('changePriceItemName', JSON.stringify(itemName))
        localStorage.setItem('changePriceRange', range)
        localStorage.setItem('changePriceItemcode', itemCode)
    }, [range, itemName, itemCode])  // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <ToastContainer />
            <div className="col-12">
                <Modal className="fade bd-example-modal-lg" show={addCard} onHide={setAddCard} >
                    <div className="" role="document">
                        <div className="">
                            <form >
                                <div className="modal-header">
                                    <h4 className="modal-title fs-20">Change Price</h4>
                                    <button type="button" className="close btn" onClick={() => udfnClearForm()}><span>×</span></button>
                                </div>
                                <div className="modal-body">
                                    <i className="flaticon-cancel-12 close" data-dismiss="modal"></i>
                                    <div className="add-contact-box">
                                        <div className="add-contact-content">
                                            <div className='row mb-3'>
                                                <div className="col-md-5">
                                                    <label className="text-black font-w500 mt-3">Item Code<span className="text-danger">*</span></label>
                                                </div>
                                                <div className="col-md-6">
                                                    <input type="text" className="form-control" autoComplete="off" value={cardItemCode}
                                                        name="name" required="required" onChange={(e) => {
                                                            setCardItemCode(e.target.value)
                                                        }}
                                                        onBlur={(e) => onBlurQrCode(e.target.value)}
                                                        placeholder="Item Code" maxLength="100"
                                                    />
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <div className="col-md-5">
                                                    <label className="text-black font-w500">Item Name</label>
                                                </div>
                                                <div className="col-md-7">
                                                    <label className="text-black font-w500">{cardItemName}</label>
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <div className="col-md-5">
                                                    <label className="text-black font-w500">Label Details</label>
                                                </div>
                                                <div className="col-md-5">
                                                    <label className="text-black font-w500">{labelDetails}</label>
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <div className="col-md-5">
                                                    <label className="text-black font-w500">Current Selling Price</label>
                                                </div>
                                                <div className="col-md-5">
                                                    <label className="text-black font-w500">{currentSellingPrice}</label>
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <div className="col-md-5">
                                                    <label className="text-black font-w500 mt-3">New Selling Price<span className="text-danger">*</span></label>
                                                </div>
                                                <div className="col-md-6">
                                                    <input type="number" className="form-control" autoComplete="off" value={newSellingPrice}
                                                        name="name" required="required" onChange={e => setNewSellingPrice(e.target.value)}
                                                        placeholder="New Selling Price" maxLength="100"
                                                    />
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <div className="col-md-5">
                                                    <label className="text-black font-w500 mt-3">No.of Labels to print <span className="text-danger">*</span></label>
                                                </div>
                                                <div className="col-md-3">
                                                    <input type="number" className="form-control" autoComplete="off" value={noOfLabelToPrint}
                                                        name="name" required="required" onChange={e => setNoOfLabelToPrint(e.target.value)}
                                                        placeholder="No.of Labels to print" />
                                                </div>
                                                <div className="col-md-4 mt-3">
                                                    <label>Current stock.{availableQty}</label>
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <div className="col-md-5">
                                                    <label className="col-form-label text-black font-w500">Label Print Types</label>
                                                </div>
                                                <div className="col-md-6">
                                                    <Select
                                                        defaultValue={labelTypeName}
                                                        value={labelTypeName}
                                                        onChange={setLabelTypeName}
                                                        options={labelTypeNameOptions}
                                                        onKeyDown={handleKeyDown}
                                                        showContent="true"
                                                        open="true"
                                                        className="my-react-select-container"
                                                        theme={theme => ({
                                                            ...theme,
                                                            borderRadius: '6px',
                                                            colors: {
                                                                ...theme.colors,
                                                                primary25: '#b3b3b3',
                                                                primary: 'black',
                                                                neutral80: "data-theme-version" === "dark" ? 'white' : ''
                                                            },
                                                        })}
                                                    />



                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-danger" onClick={() => udfnClearForm()}> <i className="flaticon-delete-1"></i> Discard</button>
                                    <Button className="me-2" variant="whatsapp" ref={buttonRef} onClick={udfnFromValidate} disabled={buttonFlag === false}>
                                        Save
                                    </Button>

                                </div>
                            </form>
                        </div>
                    </div>
                </Modal>
                <LoadingOverlay
                    active={isLoading}
                    spinner
                    text='Loading ...'
                >
                    <div className="card">
                        <div className="card-header mb-sm-12 d-flex align-items-center flex-wrap">
                            <div className='col-sm-7'>
                                <h2 className="card-title">Change Price List</h2>
                            </div>
                            <div className='col-sm-2 me-1'>
                                <Link className="btn btn-primary mb-2" onClick={() => setAddCard(true)}>
                                    + Change Price
                                </Link>
                            </div>
                        </div>
                        <div className="card-body">
                            <Accordion className="accordion accordion-rounded-stylish accordion-bordered  accordion-active-header" defaultActiveKey="0">
                                <Accordion.Item>
                                    <Accordion.Header className="accordion-header accordion-header-primary">
                                        <h5>Filters</h5>
                                        <span className="accordion-header-indicator "></span>
                                    </Accordion.Header>
                                    <Accordion.Collapse className="accordion__body">
                                        <div className="accordion-body">
                                            <div className="row">
                                                <div className="col-md-3 mb-3">
                                                    <div className="example rangeDatePicker">
                                                        <p className="mb-1">Select From Date And To Date</p>
                                                        <DateRangePicker
                                                            onApply={(e) => {
                                                                setRange(e.target.value)
                                                            }}
                                                            initialSettings={rangeDate}
                                                        >
                                                            <input type="text" className="form-control input-daterange-timepicker" styles={{ height: "90%" }} />
                                                        </DateRangePicker>
                                                    </div>
                                                </div>
                                                <div className="col-md-3 mb-3">
                                                    <div className="example rangeDatePicker">
                                                        <p className="mb-1">Item Name</p>
                                                        <Select
                                                            defaultValue={itemName}
                                                            onChange={setItemName}
                                                            options={itemNameOptions.map((option) => ({
                                                                label: option?.im_display_name,
                                                                value: option?.im_item_code,
                                                            }))}
                                                            showContent="true"
                                                            open="true"
                                                            className="my-react-select-container"
                                                            theme={theme => ({
                                                                ...theme,
                                                                borderRadius: '6px',
                                                                colors: {
                                                                    ...theme.colors,
                                                                    primary25: '#b3b3b3',
                                                                    primary: 'black',
                                                                    neutral80: "data-theme-version" === "dark" ? 'white' : ''
                                                                },
                                                            })}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-3 mb-3">
                                                    <div className="example rangeDatePicker">
                                                        <p className="mb-1">Item Code</p>
                                                        <input type="text" className="form-control" autoComplete="off"
                                                            value={itemCode}
                                                            name="name" required="required" onChange={(e) => {
                                                                setItemCode(e.target.value)
                                                            }}
                                                            placeholder="Item Code" maxLength="100"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-3 mt-4">
                                                    <Button className="me-2" variant="whatsapp" style={{ marginTop: "6%" }} onClick={() => {
                                                        udfnChangePriceList()
                                                    }}>
                                                        List{" "}
                                                        <span className="btn-icon-right">
                                                            <i className="fa fa-filter" />
                                                        </span>
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                    </Accordion.Collapse>
                                </Accordion.Item>
                            </Accordion>
                            <div className="table-responsive">
                                <table {...getTableProps()} className="table filtering-table table-responsive-lg">
                                    <thead>
                                        <tr>
                                            <th>S.No.</th>
                                            <th>Transaction Date</th>
                                            <th>Item Code</th>
                                            <th>Item Name</th>
                                            <th>Old Selling Price</th>
                                            <th>New Selling Price</th>
                                        </tr>
                                    </thead>
                                    <tbody {...getTableBodyProps()} className="" >

                                        {page.map((row, index) => {
                                            prepareRow(row)
                                            return (
                                                <tr key={index}>
                                                    <td>{index + 1}</td>
                                                    <td>{row.original.transaction_date}</td>
                                                    <td>{row.original.cr_qr_code}</td>
                                                    <td>{row.original.im_item_name}</td>
                                                    <td className='text-end pr-6'>
                                                        <i className="fa fa-rupee udfn-rupee-icon rupeeicon"></i> {row.original.cr_current_selling_price}
                                                    </td>
                                                    <td className='text-end pr-6'><i className="fa fa-rupee udfn-rupee-icon rupeeicon"></i> {row.original.cr_new_selling_price}</td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                                <div className="d-flex justify-content-between">
                                    <span>
                                        Page{' '}
                                        <strong>
                                            {pageIndex + 1} of {pageOptions.length}
                                        </strong>{''}
                                    </span>
                                    <span className="table-index">
                                        Go to page : {' '}
                                        <input type="number"
                                            className="ms-2"
                                            defaultValue={pageIndex + 1}
                                            onChange={e => {
                                                const pageNumber = e.target.value ? Number(e.target.value) - 1 : 0
                                                gotoPage(pageNumber)
                                            }}
                                        />
                                    </span>
                                </div>
                                <div className="text-center">
                                    <div className="filter-pagination  mt-3">
                                        <button className=" previous-button" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>{'<<'}</button>

                                        <button className="previous-button" onClick={() => previousPage()} disabled={!canPreviousPage}>
                                            Previous
                                        </button>
                                        <button className="next-button" onClick={() => nextPage()} disabled={!canNextPage}>
                                            Next
                                        </button>
                                        <button className=" next-button" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>{'>>'}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {qrCodeItemDetails.map((row) => (
                        <div id={myid + row.cr_qr_code} className='color-picker' style={{ display: 'none' }}>
                            <QRCode
                                size={50}
                                value={row.cr_qr_code.toString()}
                            />
                        </div>
                    ))}

                    {qrCodeItemDetails.map((row) => (
                        <div id={myqrid + row.cr_qr_code} className='color-picker' style={{ display: 'none' }}>
                            <QRCode
                                size={35}
                                value={row.cr_qr_code.toString()}
                            />
                        </div>
                    ))}
                </LoadingOverlay>
            </div>
        </>
    )
}
export default ChangePriceList;