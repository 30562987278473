import { format } from 'date-fns';
import { ColumnFilter } from '../Common/ColumnFilter';
export const COLUMNS = [
	{
		Header: 'S.No.',
		Footer: 'S.No.',
		accessor: 'id',
		Filter: ColumnFilter,
		disableFilters: true,
	},
	{
		Header: 'Bill Date',
		Footer: 'Bill Date',
		accessor: 'ps_billing_date',
		Filter: ColumnFilter,
		disableFilters: true,
	},
	{
		Header: 'Bill No.',
		Footer: 'Bill No.',
		accessor: 'ps_bill_no',
		Filter: ColumnFilter,
		disableFilters: true,
	},
	{
		Header: 'Item Group',
		Footer: 'Item Group',
		accessor: 'gm_group_name',
		Filter: ColumnFilter,
		disableFilters: true,
	},
	{
		Header: 'Item Category',
		Footer: 'Item Category',
		accessor: 'cm_category_name',
		Filter: ColumnFilter,
		disableFilters: true,
	},
	{
		Header: 'Item Name',
		Footer: 'Item Name',
		accessor: 'itemname',
		Filter: ColumnFilter,
		disableFilters: true,
	},
	{
		Header: 'Item Code',
		Footer: 'Item Code',
		accessor: 'qrcode',
		Filter: ColumnFilter,
		disableFilters: true,
	},
	{
		Header: 'Rate',
		Footer: 'Rate',
		accessor: 'rate',
		Filter: ColumnFilter,
		disableFilters: true,
	},
	{
		Header: 'Qty',
		Footer: 'Qty',
		accessor: 'itemqty',
		Filter: ColumnFilter,
		disableFilters: true,
	},
	{
		Header: 'CGST',
		Footer: 'CGST',
		accessor: 'cgstamt',
		Filter: ColumnFilter,
		disableFilters: true,
	},
	{
		Header: 'SGST',
		Footer: 'SGST',
		accessor: 'sgstamt',
		Filter: ColumnFilter,
		disableFilters: true,
	},
	{
		Header: 'IGST',
		Footer: 'IGST',
		accessor: 'igstamt',
		Filter: ColumnFilter,
		disableFilters: true,
	},
	{
		Header: 'Disc Amount',
		Footer: 'Disc Amount',
		accessor: 'discamt',
		Filter: ColumnFilter,
		disableFilters: true,
	},
	{
		Header: 'Amount',
		Footer: 'Amount',
		accessor: 'amount',
		Filter: ColumnFilter,
		disableFilters: true,
	}
]

export const GROUPED_COLUMNS = [
]