function Formatting(option, value) {

    var result = false;

    switch (option) {

        case 'two_decimal':
            result = Math.round(Number(value) * 100) / 100; ;
            break;

        case 'round_off':
            result = Math.round(Number(value));
            break;

      
        default:
            break;
    }

    return result
}

export default Formatting;