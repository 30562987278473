import { ColumnFilter } from '../Common/ColumnFilter';
export const COLUMNS = [
    {
        Header: 'S.No.',
        Footer: 'S.No.',
        accessor: 'id',
        Filter: ColumnFilter,
        disableFilters: true,
    },
    {
        Header: 'Salesman Name',
        Footer: 'Salesman Name',
        accessor: 'sm_sales_man_name',
        Filter: ColumnFilter,
        disableFilters: true,
    },
    {
        Header: 'Salesman Mannual Code',
        Footer: 'Salesman Mannual Code',
        accessor: 'sm_sales_man_code',
        Filter: ColumnFilter,
        disableFilters: true,
    },
    {
        Header: 'Short Name',
        Footer: 'Short Name',
        accessor: 'sm_short_name',
        Filter: ColumnFilter,
        disableFilters: true,
    },
    {
        Header: 'Designation',
        Footer: 'Designation',
        accessor: 'sm_designation',
        Filter: ColumnFilter,
        disableFilters: true,
    }
]

export const GROUPED_COLUMNS = [
]