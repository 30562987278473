
const COMMON_API = {
    LOGIN: "api/login/login",
    REFRESHTOKEN: "api/login/refreshToken",
    // SUPPLIER
    SUPPLIER_MASTER_LIST: "api/commonList/getSupplierList",

    //ITEM
    ITEM_MASTER_LIST: "api/commonList/getItemList",

    //BRAND
    BRAND_MASTER_LIST: "api/commonList/getBrandList",
    //BRANCH
    BRANCH_MASTER_LIST: "api/commonList/getBranchList",

    //User Role list
    USER_ROLE_LIST: "api/commonList/getUserRole",

    // Item Details From QR_Code
    ITEM_LIST_FROM_QRCODE: "api/commonList/getItemListFromQrCode",
    GET_GROUP_LIST: "api/commonlist/getGroupList",
    CATEGORY_LIST: "api/commonList/getCategoryList",
    UNIT_LIST: "api/commonList/getUnitList",
    TAX_LIST: "api/commonList/getTaxList",

    CUSTOMER_MASTER_LIST: "api/commonList/getCustomerMasterList",
    VOUCHER_TYPE_LIST: "api/commonList/getVoucherTypeList",
    SALESMAN_LIST: "api/commonList/getSalesMasterList",
    BRANCH_DETAILS: "api/commonList/getBranchDetails",
};

export default COMMON_API;