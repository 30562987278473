function FormValidation(option, value) {

    var result = false;

    switch (option) {

        case 'alpha_numeric':
            result = !value.match(/^([A-Za-z]|[0-9]|_)+$/) ? false : true;
            break;

        case 'alpha_space':
            result = !value.match('^[A-Z a-z]{1,100}$') ? false : true;
            break;

        case 'alpha_numeric_space':
            result = !value.match('^[a-zA-Z0-9 ]*$') ? false : true;
            break;

        case 'alpha_numeric_space_symbol':
            result = !value.match('^[a-zA-Z0-9 &]*$') ? false : true;
            break;

        case 'white_space':
            result = /\s/g.test(value) ? false : true;
            break;

        case 'password':
            result = !value.match('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,10}$') ? false : true;
            break;

        case 'pincode':
            var result1 = value.length !== 6 ? false : true;
            var result2 = value > 0 ? true : false;
            result = result1 && result2 ? true : false;
            break;

        case 'phone_number':
            var result1 = value.length !== 10 ? false : true;
            var result2 = value > 0 ? true : false;
            result = result1 && result2 ? true : false;
            break;

        case 'email':
            result = !value.match(/^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/) ? false : true;
            break;

        case 'gstin':
            result = value.length !== 15 || !value.match(/^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/) ? false : true;
            break;

        case 'website':
            result = !value.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g) ? false : true;
            break;


        case 'item_name':
            result = value.match(/^[a-zA-Z0-9 !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]{1,100}$/) ? true : false;
            break;
        case 'manual_code':
            var special_character_valid = !value.match('(?=.*?[!@#$%^&*])') ? true : false;
            if (special_character_valid === true) {
                result = true;
            } else {
                result = false;
            }
            break;

        case 'salesman_code':
            result = value.match('(?=.*[A-Z])') ? true : false;
            break;
        default:
            break;
    }

    return result
}

export default FormValidation;