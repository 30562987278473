import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";

import LogoutPage from './Logout';
/// Image
import profile from "../../../images/profile/pic1.jpg";
import { Dropdown } from "react-bootstrap";

import { ThemeContext } from "../../../context/ThemeContext";
import { Modal, Button } from 'react-bootstrap';
import ERROR_MESSAGE from '../../components/Validation/ErrorMessage';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import swal from "sweetalert";
import FORM_VALIDATION from '../../components/Validation/FormValidation';
import axios from 'axios';
import { getLoggedInUser, getRefreshToken } from '../../../services/AuthService';
import MASTER_API from '../../Api/MasterApi';
import { UserLogout } from '../../layouts/nav/Logout';
import COMMONVARIABLES from "../../components/Utilities/constants";
import LoadingOverlay from 'react-loading-overlay-ts';

const Header = ({ onNote }) => {
  const userData = getLoggedInUser();

  const { background, changeBackground } = useContext(ThemeContext);
  const [userName, setUserName] = useState("");
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [oldPasswordShow, setOldPasswordShow] = useState(false);
  const [newPasswordShow, setNewPasswordShow] = useState(false);
  const [confirmPasswordShow, setConfirmPasswordShow] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [buttonFlag, setButtonFlag] = useState(true);

  //Modal box
  const [addCard, setAddCard] = useState(false);

  const handleThemeMode = () => {
    if (background.value === 'dark') {
      changeBackground({ value: "light", label: "Light" });
    } else {
      changeBackground({ value: "dark", label: "Dark" });
    }
  }

  const formSubmit = (e) => {
    e.preventDefault();

    var error = '';
    if (oldPassword === '' || newPassword === '' || confirmPassword === '') {
      error = ERROR_MESSAGE.COMMON_REQUIRED;
    } else if (newPassword.length < 6) {
      error = ERROR_MESSAGE.USER_MASTER.PASSWORD_VALID;
    } else if (!FORM_VALIDATION('password', newPassword)) {
      error = ERROR_MESSAGE.USER_MASTER.PASSWORD_VALID;
    } else if (newPassword !== confirmPassword) {
      error = ERROR_MESSAGE.CHANGE_PASSWORD.CHANGE_PASSWORD_VALID;
    } else {
      error = '';
    }
    if (error === '') {
      setButtonFlag(false);
      udfnChangePassword();
    } else {
      toast.error(error, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };

  const udfnChangePassword = async () => {
    setIsLoading(true);
    const user = getLoggedInUser();
    var uploadData = {};
    uploadData = {
      user_id: user.id,
      company_code: user.company_code,
      user_name: user.user_name,
      old_password: String(oldPassword),
      new_password: String(newPassword),
    };
    const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } };

    try {
      const response = await axios.post(process.env.REACT_APP_BASE_URL + MASTER_API.USER_MASTER_PASSWORD_CHANGE, uploadData, config);
      setIsLoading(false);
      if (response && response?.data?.body?.status === COMMONVARIABLES.STATUS.INVALIDUSER) {
        toast.error(response.data.body.message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        UserLogout();
      }

      if (response?.data?.body?.status === COMMONVARIABLES.STATUS.UNAUTHORIZED) {
        const refresh = await getRefreshToken();
        if (refresh) {
          if (Number(refresh) === COMMONVARIABLES.STATUS.EXPIRED_TOKEN) {
            UserLogout();
          } else {
            await udfnChangePassword();
          }
        }
      }

      if (response && response?.data?.body?.status === COMMONVARIABLES.STATUS.EXISTS) {
        setButtonFlag(true);
        toast.error(response.data.body.message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }

      if (response && response?.data?.body?.status === COMMONVARIABLES.STATUS.ALREADY_IN_USE) {
        toast.error(response.data.body.message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }

      if (response?.data?.body?.status === COMMONVARIABLES.STATUS.SERVICE_CATCH_STATUS) {
        toast.error(ERROR_MESSAGE.API_ERROR.SERVICE_CATCH_ERROR, { position: toast.POSITION.TOP_RIGHT });
      }

      if (response?.data?.body?.status === COMMONVARIABLES.STATUS.SUCCESS) {
        swal(response.data.body.message, {
          icon: "success",
        });
        setButtonFlag(true);
        udfnClearForm();
      } else {
        toast.error(response.data.body.message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        setAddCard(true);
        setButtonFlag(true);
      }
    } catch (error) {
      // Handle error
      setIsLoading(false);
      console.error("change password API Error:", error);
      if (error.code === COMMONVARIABLES.STATUS.NETWORK_ERROR_STATUS) {
        toast.error(ERROR_MESSAGE.API_ERROR.NETWORK_ERROR, { position: toast.POSITION.TOP_RIGHT });
      }
      if (error.code === COMMONVARIABLES.STATUS.BAD_REQUEST_ERROR_STATUS) {
        toast.error(ERROR_MESSAGE.API_ERROR.BAD_REQUEST_ERROR, { position: toast.POSITION.TOP_RIGHT });
      }
    }
  };

  const udfnClearForm = () => {
    setOldPassword('')
    setNewPassword('')
    setConfirmPassword('Select Role')
    setAddCard(false);
  };

  useEffect(() => {
    setUserName(userData.user_name);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      {/* <ToastContainer /> */}
      <div className="header">
        <div className="header-content">
          <nav className="navbar navbar-expand">
            <div className="collapse navbar-collapse justify-content-between">
              <div className="header-left">
              </div>
              <ul className="navbar-nav header-right main-notification">
                <li className="nav-item dropdown notification_dropdown">
                  <label className="me-2 mt-2">Last login activity :
                    <span className="me-2">  {userData.last_sync_date}</span> </label>
                  <Link to={"#"} className={` nav-link bell dz-theme-mode ${background.value === "light" ? "active" : ""}`}>
                    <i id="icon-light" className="fa fa-sun-o" onClick={() => handleThemeMode()}></i>
                    <i id="icon-dark" className="fa fa-moon-o" onClick={() => handleThemeMode()}></i>
                  </Link>
                </li>

                <Dropdown as="li" className="nav-item dropdown header-profile">
                  <Dropdown.Toggle
                    variant=""
                    as="a"
                    className="nav-link i-false c-pointer"
                    // href="#"
                    role="button"
                    data-toggle="dropdown"
                  >
                    <img src={profile} width={20} alt="" />
                    <div className="header-info">
                      <span>{userName}</span>
                      {/* <small>Super Admin</small> */}
                    </div>
                  </Dropdown.Toggle>

                  <Dropdown.Menu align="right" className="mt-2">
                    <Link className="dropdown-item ai-icon" onClick={() => setAddCard(true)}>
                      <i
                        className="text-info fa fa-key"
                        width={18}
                        height={18}
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                      </i>
                      <span className="ms-2">Change Password </span>
                    </Link>
                    <LogoutPage />
                  </Dropdown.Menu>
                </Dropdown>
              </ul>
            </div>
          </nav>
        </div>
      </div>
      <LoadingOverlay
        active={isLoading}
        spinner
        text='Loading ...'
      >
        <Modal className="modal fade" show={addCard} onHide={setAddCard} backdrop="static">
          < div className="" role="document">
            <div className="">
              <div className="modal-header">
                <h4 className="modal-title fs-20">Change Password</h4>
                <button type="button" className="close btn" onClick={() => setAddCard(false)}><span>×</span></button>
              </div>
              <div className="modal-body">
                <i className="flaticon-cancel-12 close" data-dismiss="modal"></i>
                <div className="add-contact-box">
                  <div className="add-contact-content">
                    <form>
                      <div className="form-group">
                        <div
                          className={`form-group mb-3 ${oldPassword !== ""
                            ? "is-invalid"
                            : "is-valid"
                            }`}
                        >
                          <label className=""> Old Password <span className="text-danger">*</span></label>
                          <div className="input-group transparent-append mb-2">

                            <input
                              type={`${oldPasswordShow ? "text" : "password"}`}
                              className="form-control"
                              id="val-password1"
                              name="password"
                              onChange={e => setOldPassword(e.target.value)}
                              placeholder="Old Password"
                              maxLength="10"
                            />

                            <div
                              className="input-group-text "
                              onClick={() => setOldPasswordShow(!oldPasswordShow)}
                            >

                              {" "}
                              {oldPasswordShow === false ? (<i className="fa fa-eye-slash" />) : (<i className="fa fa-eye" />)}

                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="form-group mt-3">
                        <div
                          className={`form-group mb-3 ${newPassword !== ""
                            ? "is-invalid"
                            : "is-valid"
                            }`}
                        >
                          <label className=""> New Password <span className="text-danger">*</span></label>
                          <div className="input-group transparent-append mb-2">

                            <input
                              type={`${newPasswordShow ? "text" : "password"}`}
                              className="form-control"
                              id="val-password1"
                              name="password"
                              onChange={e => setNewPassword(e.target.value)}
                              placeholder="New Password"
                              maxLength="10"
                            />

                            <div
                              className="input-group-text "
                              onClick={() => setNewPasswordShow(!newPasswordShow)}
                            >

                              {" "}
                              {newPasswordShow === false ? (<i className="fa fa-eye-slash" />) : (<i className="fa fa-eye" />)}

                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="form-group mt-3">
                        <div
                          className={`form-group mb-3 ${confirmPassword !== ""
                            ? "is-invalid"
                            : "is-valid"
                            }`}
                        >
                          <label className=""> Confirm Password <span className="text-danger">*</span></label>
                          <div className="input-group transparent-append mb-2">

                            <input
                              type={`${confirmPasswordShow ? "text" : "password"}`}
                              className="form-control"
                              id="val-password1"
                              name="password"
                              onChange={e => setConfirmPassword(e.target.value)}
                              placeholder="Confirm Password"
                              maxLength="10"
                            />

                            <div
                              className="input-group-text "
                              onClick={() => setConfirmPasswordShow(!confirmPasswordShow)}
                            >

                              {" "}
                              {confirmPasswordShow === false ? (<i className="fa fa-eye-slash" />) : (<i className="fa fa-eye" />)}

                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                    <div className="text-center mt-5">
                      <Button className="me-2" variant="whatsapp" onClick={formSubmit} disabled={buttonFlag === false}>
                        Submit
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </LoadingOverlay>
    </div>

  );
};

export default Header;
