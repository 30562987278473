import React from "react";

const Footer = () => {
  var d = new Date();
  return (
    <div className="footer">
      <div className="copyright">
        <p>
        Powered by {" "}
          <a href="https://Trio-s.com" target="_blank" rel="noreferrer">
            Trio-S Software Consultancy (P) Ltd
          </a>{" "}
        </p>
      </div>
    </div>
  );
};

export default Footer;
