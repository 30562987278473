import { format } from 'date-fns';
import { ColumnFilter } from '../Common/ColumnFilter';
export const COLUMNS = [
	// {
	// 	Header: 'S.No.',
	// 	Footer: 'S.No.',
	// 	accessor: 'id',
	// 	Filter: ColumnFilter,
	// 	// disableFilters: true,
	// },
	{
		Header: 'Item Name',
		Footer: 'Item Name',
		accessor: 'im_item_name',
		Filter: ColumnFilter,
		disableFilters: true,
	},
	{
		Header: 'Category Name',
		Footer: 'Category Name',
		accessor: 'cm_category_name',
		Filter: ColumnFilter,
		// disableFilters: true,
	},
	{
		Header: 'Group Name',
		Footer: 'Group Name',
		accessor: 'gm_group_name',
		Filter: ColumnFilter,
		// disableFilters: true,
	},
	{
		Header: 'Item Manual Code',
		Footer: 'Item Manual Code',
		accessor: 'im_item_manual_code',
		Filter: ColumnFilter,
		// disableFilters: true,
	},
	{
		Header: 'Display Name',
		Footer: 'Display Name',
		accessor: 'im_display_name',
		Filter: ColumnFilter,
		// disableFilters: true,
	},
	{
		Header: 'Unit Name',
		Footer: 'Unit Name',
		accessor: 'um_display_name',
		Filter: ColumnFilter,
		// disableFilters: true,
	},
	{
		Header: 'HSN Code',
		Footer: 'HSN Code',
		accessor: 'im_hsn_code',
		Filter: ColumnFilter,
		// disableFilters: true,
	},
	{
		Header: 'Tax %',
		Footer: 'Tax %',
		accessor: 'im_tax_per',
		Filter: ColumnFilter,
		// disableFilters: true,
	},
	{
		Header: 'Status',
		Footer: 'Status',
		accessor: 'status',
		Filter: ColumnFilter,
		// disableFilters: true,
	}
]

export const GROUPED_COLUMNS = [
]