import { format } from 'date-fns';
import { ColumnFilter } from '../Common/ColumnFilter';
export const COLUMNS = [
	{
		Header: 'S.No.',
		Footer: 'Branch Code',
		accessor: 'id',
		Filter: ColumnFilter,
		disableFilters: true,
	},
	{
		Header: 'Branch Name',
		Footer: 'Branch Name',
		accessor: 'bm_branch_name',
		Filter: ColumnFilter,
		disableFilters: true,
	},
	{
		Header: 'Branch City',
		Footer: 'Branch City',
		accessor: 'bm_city',
		Filter: ColumnFilter,
		disableFilters: true,
	},
	{
		Header: 'Status',
		Footer: 'Branch Status',
		accessor: 'status',
		Filter: ColumnFilter,
		disableFilters: true,
	}
]

export const GROUPED_COLUMNS = [
]