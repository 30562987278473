import React, { useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import swal from "sweetalert";
import { Button, Modal } from "react-bootstrap";
import { Logout } from '../../../store/actions/AuthActions';
import { isAuthenticated } from '../../../store/selectors/AuthSelectors';

function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();
    return (
      <Component
        {...props}
        router={{ location, navigate, params }}
      />
    );
  }

  return ComponentWithRouterProp;
}

export const UserLogout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  dispatch(Logout(navigate));
  // window.location.reload();

}

function LogoutPage(props) {
  const [smallModal, setSmallModal] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  function onLogout() {
    setSmallModal(false);
    dispatch(Logout(navigate));
  }
  return (
    <>
      <button className="dropdown-item ai-icon" onClick={() => setSmallModal(true)}>
        <svg
          id="icon-logout" xmlns="http://www.w3.org/2000/svg"
          className="text-danger me-1" width={18} height={18} viewBox="0 0 24 24"
          fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round"
        >
          <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4" />
          <polyline points="16 17 21 12 16 7" />
          <line x1={21} y1={12} x2={9} y2={12} />
        </svg>
        <span className="ms-2"  >Logout </span>
      </button>

      <Modal className="fade" show={smallModal} onHide={setSmallModal} aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Body>
          <h3 className='text-center p-6'>
            Are you sure you want to Logout ?
            <br />
          </h3>
          <div className='text-center mt-4'>
            <Button variant="secondary" className='mx-2 px-3 py-2' onClick={() => setSmallModal(false)} style={{ fontSize: '16px' }}> No </Button>
            <Button variant="primary" className='mx-2 px-3 py-2' onClick={onLogout} style={{ fontSize: '16px' }}> Yes  </Button>
          </div>
        </Modal.Body>
      </Modal>



      {/* <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <h4>Centered Modal</h4>
          <p>
            Cras mattis consectetur purus sit amet fermentum. Cras justo odio,
            dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac
            consectetur ac, vestibulum at eros.
          </p>
        </Modal.Body>
      </Modal> */}

    </>
  )
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: isAuthenticated(state),
  };
};

export default withRouter(connect(mapStateToProps)(LogoutPage));