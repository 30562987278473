import {
    formatError,
    login,
    runLogoutTimer,
    saveTokenInLocalStorage,
    signUp,
} from '../../services/AuthService';


export const SIGNUP_CONFIRMED_ACTION = '[signup action] confirmed signup';
export const SIGNUP_FAILED_ACTION = '[signup action] failed signup';
export const LOGIN_CONFIRMED_ACTION = '[login action] confirmed login';
export const LOGIN_FAILED_ACTION = '[login action] failed login';
export const LOADING_TOGGLE_ACTION = '[Loading action] toggle loading';
export const LOGOUT_ACTION = '[Logout action] logout action';

export const NAVTOGGLE = 'NAVTOGGLE';

export function signupAction(email, password, navigate) {

    return (dispatch) => {
        signUp(email, password)
            .then((response) => {
                saveTokenInLocalStorage(response.data);
                runLogoutTimer(
                    dispatch,
                    response.data.expiresIn * 1000,
                    //history,
                );
                dispatch(confirmedSignupAction(response.data));
                navigate('/dashboard');
                //history.push('/dashboard');
            })
            .catch((error) => {
                const errorMessage = formatError(error.response.data);
                dispatch(signupFailedAction(errorMessage));
            });
    };
}

export function Logout(navigate) {

    localStorage.removeItem('userDetails');
    localStorage.removeItem('stateList');
    localStorage.removeItem('fy');
    localStorage.removeItem('supplierName');
    localStorage.removeItem('itemName');
    localStorage.removeItem('qrcode');
    localStorage.removeItem('range');
    localStorage.removeItem('itemWiseAnalyseRange');
    localStorage.removeItem('stockAdjustmentRange');
    localStorage.removeItem('stockAdjustmentItemName');
    localStorage.removeItem('stockAdjustmentQrcode');
    localStorage.removeItem('stockTransferItemName');
    localStorage.removeItem('stockTransferRange');
    localStorage.removeItem('stockTransferQrcode');
    localStorage.removeItem('changePriceItemName');
    localStorage.removeItem('changePriceRange');
    localStorage.removeItem('changePriceItemcode');
    localStorage.removeItem('agingStockReportDate');
    localStorage.removeItem('agingStockReportBranchName');
    localStorage.removeItem('agingStockReportBrandName');
    localStorage.removeItem('agingStockReportGroupName');
    localStorage.removeItem('agingStockReportCategoryName');
    localStorage.removeItem('agingStockReportItemName');
    localStorage.removeItem('agingStockReportSupplierName');
    localStorage.removeItem('agingStockReportQrcodeWise');
    localStorage.removeItem('agingStockReportSupplierWise');
    localStorage.removeItem('buyBackReportRange');
    localStorage.removeItem('buyBackReportBranchName');
    localStorage.removeItem('itemWiseAnalyseItemName');
    localStorage.removeItem('itemWiseAnalyseRange');
    localStorage.removeItem('itemWiseAnalyseBranchName');
    localStorage.removeItem('itemWiseSalesReportRange');
    localStorage.removeItem('itemWiseSalesReportGroupName');
    localStorage.removeItem('itemWiseSalesReportStatus');
    localStorage.removeItem('itemWiseSalesReportEstimate');
    localStorage.removeItem('itemWiseSalesReportBranchName');
    localStorage.removeItem('itemWiseSalesReportVoucherType');
    localStorage.removeItem('itemWiseSalesReportCategoryName');
    localStorage.removeItem('itemWiseSalesReportSalesManName');
    localStorage.removeItem('itemWiseSalesReportItemName');
    localStorage.removeItem('posReportRange');
    localStorage.removeItem('posReportCustomerName');
    localStorage.removeItem('posReportStatus');
    localStorage.removeItem('posReportEstimate');
    localStorage.removeItem('posReportBranchName');
    localStorage.removeItem('posReportVoucherType');
    localStorage.removeItem('posReportBillNo');
    localStorage.removeItem('posReportMobileNo');
    localStorage.removeItem('profitReportRange');
    localStorage.removeItem('salesManReportSalesManName');
    localStorage.removeItem('salesManReportRange');
    localStorage.removeItem('stockReportDate');
    localStorage.removeItem('stockReportBranchName');
    localStorage.removeItem('stockReportBrandName');
    localStorage.removeItem('stockReportGroupName');
    localStorage.removeItem('stockReportCategoryName');
    localStorage.removeItem('stockReportItemName');
    localStorage.removeItem('stockReportSupplierName');
    localStorage.removeItem('stockReportItemFilter');
    localStorage.removeItem('stockReportNegative');
    localStorage.removeItem('rateAndAmountShow');

    navigate('/login');
    //history.push('/login');

    return {
        type: LOGOUT_ACTION,
    };
}

export function loginAction(userid, password, navigate) {
    return (dispatch) => {
        const response = login(userid, password);
        // .then((response) => {
        console.log(response);
        console.log(response.expiresIn);
        saveTokenInLocalStorage(response);
        runLogoutTimer(
            dispatch,
            response.expiresIn * 1000,
            navigate,
        );
        dispatch(loginConfirmedAction(response));

        navigate('/dashboard');
        // })
        // .catch((error) => {
        //     const errorMessage = formatError(error.response.data);
        //     dispatch(loginFailedAction(errorMessage));
        // });
    };
}

export function loginFailedAction(data) {
    return {
        type: LOGIN_FAILED_ACTION,
        payload: data,
    };
}

export function loginConfirmedAction(data) {
    return {
        type: LOGIN_CONFIRMED_ACTION,
        payload: data,
    };
}

export function confirmedSignupAction(payload) {
    return {
        type: SIGNUP_CONFIRMED_ACTION,
        payload,
    };
}

export function signupFailedAction(message) {
    return {
        type: SIGNUP_FAILED_ACTION,
        payload: message,
    };
}

export function loadingToggleAction(status) {
    return {
        type: LOADING_TOGGLE_ACTION,
        payload: status,
    };
}

export const navtoggle = () => {

    return {
        type: 'NAVTOGGLE',
    };
};