const PURCHASE_API = {

    // branch api url
    PURCHASE_INVNO: "api/purchase/getPurchaseInvNo",
    PREVIOUS_PURCHASE : "api/purchase/getPrevPurchase",
    SAVE_PURCHASE : "api/purchase/savePurchase",
    UPDATE_PURCHASE : "api/purchase/updatePurchase",
    PURCHASE_LIST : "api/purchase/getPurchaseList",
    PURCHASE_EDIT_LOAD :"api/purchase/getPurchaseEditLoad",
    PURCHASE_DELETE:"api/purchase/deletePurchase",
    UPLOAD_INVOICE:"api/purchase/uploadInvoice",
    DOWNLOAD_INVOICE:"api/purchase/download",
    GetPurchaseLabelDetails : "api/purchase/getPurchaseLabelDetails",
    REPRINTPURCHASE:"api/purchase/purchaseReprint",


};

export default PURCHASE_API;


