import { format } from 'date-fns';
import { ColumnFilter } from '../Common/ColumnFilter';
export const COLUMNS = [
    {
        Header: 'S.No',
        Footer: 'Brand Code',
        accessor: 'id',
        Filter: ColumnFilter,
        disableFilters: true,
    },
    {
        Header: 'Brand Name',
        Footer: 'Brand Name',
        accessor: 'bm_brand_name',
        Filter: ColumnFilter,
        disableFilters: true,
    },
    {
        Header: 'Status',
        Footer: 'Brand Status',
        accessor: 'status',
        Filter: ColumnFilter,
        disableFilters: true,
    }
]

export const GROUPED_COLUMNS = [
]