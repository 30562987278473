import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import '../Filters/Common/filtering.css';
import ERROR_MESSAGE from '../Validation/ErrorMessage';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import { getLoggedInUser, getRefreshToken } from "../../../services/AuthService"
import MASTER_API from '../../Api/MasterApi';
import COMMON_API from '../../Api/CommonApi';
import Formatting from '../Utilities/format';
import { UserLogout } from '../../layouts/nav/Logout';
import axios from 'axios';
import COMMONVARIABLES from '../Utilities/constants';
import LoadingOverlay from 'react-loading-overlay-ts';

export const DiscountBulkUpdate = () => {
    const user = getLoggedInUser();
    const navigate = useNavigate();

    const BASE_URL = process.env.REACT_APP_BASE_URL;
    const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } };

    const [isLoading, setIsLoading] = useState(false);
    const [listButton, setlistButton] = useState(false);
    const [buttonFlag, setButtonFlag] = useState(false);
    const [applyBtn, setApplyBtn] = useState(false);

    const discountTypeOptions = [
        { value: 1, label: "Item wise" },
        // { value: 2, label: "QR Code wise" },
    ];
    const [groupOptions, setGroupOptions] = useState([]);
    const [categoryOptions, setCategoryOptions] = useState([]);
    const [categoryOptionsFiltered, setCategoryOptionsFiltered] = useState([]);
    const [brandOptions, setBrandOptions] = useState([]);
    const [itemOptions, setItemOptions] = useState([]);

    const [itemList, setItemList] = useState([]);

    const [discountType, setDiscountType] = useState(1);
    const [groupCode, setGroupCode] = useState('');
    const [categoryCode, setCategoryCode] = useState('');
    const [brandCode, setBrandCode] = useState('');
    const [itemCode, setItemCode] = useState('');
    const [discPer, setDiscPer] = useState(0);

    const udfnDiscountValidation = (e) => {
        // const regex = /^[0-9]*$/;
        // const input = event.target.value + event.key;
        // if (!regex.test(input) || input.length > 3 || input > 100) {
        //     event.preventDefault();
        // }
        if (e.key === '-' || e.key === 'e') {
            e.preventDefault();
        }
    };

    const udfnFormValidate = (event) => {
        event.preventDefault();
        var error = "";

        // if (discountType === undefined || discountType === '') {
        //     error = ERROR_MESSAGE.COMMON_REQUIRED;
        // } 
        if ((groupCode?.value === undefined || groupCode?.value === '') && (categoryCode?.value === undefined || categoryCode?.value === '') && (itemCode?.value === undefined || itemCode?.value === '')) {
            error = ERROR_MESSAGE.DISCOUNT_BULK_UPDATE_MASTER.SELECT_VALID;
        } else {
            error = '';
        }

        if (error === '') {
            getItemListWithParams()
        } else {
            toast.error(error, { position: toast.POSITION.TOP_RIGHT });
        }
    };

    const fetchMasterDataWithParams = async () => {
        setIsLoading(true)
        try {
            const categoryParams = { user_id: Number(user?.id), status_code: Number(1) };
            const groupParams = { user_id: Number(user?.id), status_code: Number(1) };
            const itemParams = { user_id: Number(user?.id), status_code: Number(1) };
            const brandParams = { user_id: Number(user?.id), status_code: Number(1) };

            const [categoryData, groupData, itemData, brandData] = await Promise.all([
                axios.post(BASE_URL + COMMON_API.CATEGORY_LIST, categoryParams, config),
                axios.post(BASE_URL + COMMON_API.GET_GROUP_LIST, groupParams, config),
                axios.post(BASE_URL + COMMON_API.ITEM_MASTER_LIST, itemParams, config),
                axios.post(BASE_URL + COMMON_API.BRAND_MASTER_LIST, brandParams, config),
            ]);

            setIsLoading(false)
            if (categoryData?.data?.body?.status === COMMONVARIABLES.STATUS.INVALIDUSER || groupData?.data?.body?.status === COMMONVARIABLES.STATUS.INVALIDUSER || itemData?.data?.body?.status === COMMONVARIABLES.STATUS.INVALIDUSER || brandData?.data?.body?.status === COMMONVARIABLES.STATUS.INVALIDUSER) {
                var errorMsg = categoryData?.data?.body?.message || groupData?.data?.body?.message || itemData?.data?.body?.message || brandData?.data?.body?.message;
                toast.error(errorMsg, { position: toast.POSITION.TOP_RIGHT });
                UserLogout();
            }

            if (categoryData?.data?.body?.status === COMMONVARIABLES.STATUS.UNAUTHORIZED || groupData?.data?.body?.status === COMMONVARIABLES.STATUS.UNAUTHORIZED || itemData?.data?.body?.status === COMMONVARIABLES.STATUS.UNAUTHORIZED || brandData?.data?.body?.status === COMMONVARIABLES.STATUS.UNAUTHORIZED) {
                const refresh = await getRefreshToken();

                if (refresh) {
                    if (Number(refresh) === COMMONVARIABLES.STATUS.EXPIRED_TOKEN) {
                        UserLogout();
                    } else {
                        await fetchMasterDataWithParams();
                    }
                }
            }

            if (categoryData?.data?.body?.categoryList || groupData?.data?.body?.groupList || itemData?.data?.body?.itemList || brandData?.data?.body?.brandList) {
                setCategoryOptions(categoryData?.data?.body?.categoryList)
                setCategoryOptionsFiltered(categoryData?.data?.body?.categoryList)
                setGroupOptions(groupData?.data?.body?.groupList)
                setItemOptions(itemData?.data?.body?.itemList)
                setBrandOptions(brandData?.data?.body?.brandList)
            }
        } catch (error) {
            setIsLoading(false)
            console.error('Error fetching master data:', error);
        }
    }

    const getItemListWithParams = async () => {
        setlistButton(true)
        setIsLoading(true)

        const uploadData = {
            user_id: Number(user.id),
            discount_type: Number(discountType),
            group_code: groupCode?.value === '' || groupCode?.value == null ? 0 : Number(groupCode?.value),
            category_code: categoryCode?.value === '' || categoryCode?.value == null ? 0 : Number(categoryCode?.value),
            brand_code: brandCode?.value === '' || brandCode?.value == null ? 0 : Number(brandCode?.value),
            item_code: itemCode?.value === '' || itemCode?.value == null ? 0 : Number(itemCode?.value),
        };

        try {
            const response = await axios.post(BASE_URL + MASTER_API.BULK_UPDATE_ITEM_LIST, uploadData, config);
            setIsLoading(false)
            setlistButton(false)

            if (response && response?.data?.body?.status === COMMONVARIABLES.STATUS.INVALIDUSER) {
                toast.error(response.data.body.message, { position: toast.POSITION.TOP_RIGHT });
                UserLogout();
            }

            if (response?.data?.body?.status === COMMONVARIABLES.STATUS.UNAUTHORIZED) {
                const refresh = await getRefreshToken();

                if (refresh) {
                    if (Number(refresh) === COMMONVARIABLES.STATUS.EXPIRED_TOKEN) {
                        UserLogout();
                    } else {
                        await getItemListWithParams();
                    }
                }
            }

            if (response?.data?.body?.itemList) {
                response?.data?.body?.itemList.map(row => {
                    row['checkbox_value'] = true
                    return row
                });
                setItemList(response.data.body.itemList);
                toast.success(response.data.body.message, { position: toast.POSITION.TOP_RIGHT });
            } else {
                toast.error(response.data.body.message, { position: toast.POSITION.TOP_RIGHT });
            }
        } catch (error) {
            setIsLoading(false)
            setlistButton(false)
            // Handle error
            console.error("Item List with Params API Error:", error);
        }

    };

    const udfnCheckBoxSpecific = (id, row) => {
        const obj1 = row;
        obj1['checkbox_value'] = obj1['checkbox_value'] ? false : true;
        itemList.splice(id, 1, obj1)
        setItemList(itemList)
        // console.log("obj1 ", obj1);
        // console.log("itemList ", itemList);
    };

    const udfnDiscountBulkUpdate = async () => {
        if (!applyBtn) {
            toast.error("Please click Apply button to apply the discount.", { position: toast.POSITION.TOP_RIGHT });
        } else if (itemList.length < 0) {
            toast.error("atleast one item required to update dicount percentage.", { position: toast.POSITION.TOP_RIGHT });
        } else {
            setIsLoading(true);
            setButtonFlag(true);

            const itemArray = [];
            itemList.map(row => {
                var obj = {};
                if (discountType === 1 && row.checkbox_value === true) {
                    obj = { item_code: row.im_item_code }
                    itemArray.push(obj)
                } else if (discountType === 2 && row.checkbox_value === true) {
                    obj = { qr_code: row.qs_qr_code }
                    itemArray.push(obj)
                }
                return itemArray
            });

            var uploadData = {
                user_id: Number(user.id),
                company_code: Number(user.company_code),
                disc_type: Number(discountType),
                disc_per: Number(Formatting('two_decimal', discPer)),
                item_list: itemArray,
            };

            try {
                const response = await axios.post(BASE_URL + MASTER_API.ITEM_DISCOUNT_BULK_UPDATE, uploadData, config);
                setIsLoading(false);
                setButtonFlag(false);

                if (response && response?.data?.body?.status === COMMONVARIABLES.STATUS.INVALIDUSER) {
                    toast.error(response.data.body.message, { position: toast.POSITION.TOP_RIGHT });
                    UserLogout();
                }
                if (response?.data?.body?.status === COMMONVARIABLES.STATUS.UNAUTHORIZED) {
                    const refresh = await getRefreshToken();
                    if (refresh) {
                        if (Number(refresh) === COMMONVARIABLES.STATUS.EXPIRED_TOKEN) {
                            UserLogout();
                        } else {
                            await udfnDiscountBulkUpdate();
                        }
                    }
                }
                if (response?.data?.body?.status === COMMONVARIABLES.STATUS.SUCCESS) {
                    toast.success(response.data.body.message, { position: toast.POSITION.TOP_RIGHT });
                    setDiscPer(0)
                    setApplyBtn(false)
                    setItemList([])
                    getItemListWithParams();
                } else {
                    toast.error(response.data.body.message, { position: toast.POSITION.TOP_RIGHT });
                }
            } catch (error) {
                // Handle error
                setIsLoading(false);
                setButtonFlag(false);
                console.error("Discount Bulk Update API Error:", error);
            }
        }

    };

    useEffect(() => {
        fetchMasterDataWithParams()
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
    }, [applyBtn]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (groupCode?.value > 0) {
            setCategoryCode('')
            var categoryOptionsArray = categoryOptions
            var filtArray = categoryOptionsArray.filter(item => item.gm_group_code === groupCode?.value)
            setCategoryOptionsFiltered(filtArray)
        } else {
            setCategoryOptionsFiltered(categoryOptions)
        }
    }, [groupCode?.value]); // eslint-disable-line react-hooks/exhaustive-deps

    const udfnApplyBtn = () => {
        if (Number(discPer) < 0 || Number(discPer) > 100) {
            toast.error("Discount Percentage is invalid", { position: toast.POSITION.TOP_RIGHT });
        } else {
            setApplyBtn(true)
        }
    };


    useEffect(() => {
        setItemList([])
        setDiscPer(0)
    }, [discountType, groupCode?.value, categoryCode?.value, brandCode?.value, itemCode?.value]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <ToastContainer />
            <LoadingOverlay
                active={isLoading}
                spinner
                text='Loading ...'
            >
                <div className="card">
                    <div className="card-header">
                        <h4 className="card-title">Discount Bulk Update</h4>
                    </div>
                    <div className="card-body">
                        <form onSubmit={(e) => e.preventDefault()}>
                            <div className="row">
                                {/* <div className="form-group mb-3 col-md-3 col-lg-3">
                                    <label className="text-black font-w500">Discount Type<span className="text-danger star-txt">*</span></label>
                                    <Select
                                        defaultValue={discountType}
                                        onChange={setDiscountType}
                                        value={discountType}
                                        options={discountTypeOptions}
                                        isClearable
                                        showContent="true"
                                        open="true"
                                        className="my-react-select-container"
                                        theme={theme => ({
                                            ...theme,
                                            borderRadius: '6px',
                                            colors: {
                                                ...theme.colors,
                                                primary25: '#b3b3b3',
                                                primary: 'black',
                                                neutral80: "data-theme-version" === "dark" ? 'white' : ''
                                            },
                                        })}
                                    />
                                </div> */}
                                <div className="form-group mb-3 col-md-6 col-lg-3">
                                    <label className="text-black font-w500">Group Name</label>
                                    <Select
                                        defaultValue={groupCode}
                                        onChange={setGroupCode}
                                        value={groupCode}
                                        options={groupOptions.map((option) => ({
                                            label: option.gm_group_name,
                                            value: option.gm_group_code,
                                        }))}
                                        isClearable
                                        showContent="true"
                                        open="true"
                                        className="my-react-select-container"
                                        theme={theme => ({
                                            ...theme,
                                            borderRadius: '6px',
                                            colors: {
                                                ...theme.colors,
                                                primary25: '#b3b3b3',
                                                primary: 'black',
                                                neutral80: "data-theme-version" === "dark" ? 'white' : ''
                                            },
                                        })}
                                    />
                                </div>
                                <div className="form-group mb-3 col-md-6 col-lg-3">
                                    <label className="text-black font-w500">Category Name</label>
                                    <Select
                                        defaultValue={categoryCode}
                                        value={categoryCode}
                                        onChange={setCategoryCode}
                                        options={categoryOptionsFiltered.map((option) => ({
                                            label: option.cm_category_name,
                                            value: option.cm_category_code,
                                        }))}
                                        isClearable
                                        showContent="true"
                                        open="true"
                                        className="my-react-select-container"
                                        theme={theme => ({
                                            ...theme,
                                            borderRadius: '6px',
                                            colors: {
                                                ...theme.colors,
                                                primary25: '#b3b3b3',
                                                primary: 'black',
                                                neutral80: "data-theme-version" === "dark" ? 'white' : ''
                                            },
                                        })}
                                    />
                                </div>
                                {/* <div className="form-group mb-3 col-md-6 col-lg-3">
                                    <label className="text-black font-w500">Brand Name</label>
                                    <Select
                                        defaultValue={brandCode}
                                        value={brandCode}
                                        onChange={setBrandCode}
                                        options={brandOptions.map((option) => ({
                                            label: option.bm_brand_name,
                                            value: option.bm_brand_code,
                                        }))}
                                        showContent="true"
                                        open="true"
                                        className="my-react-select-container"
                                        theme={theme => ({
                                            ...theme,
                                            borderRadius: '6px',
                                            colors: {
                                                ...theme.colors,
                                                primary25: '#b3b3b3',
                                                primary: 'black',
                                                neutral80: "data-theme-version" === "dark" ? 'white' : ''
                                            },
                                        })}
                                    />
                                </div> */}
                                <div className="form-group mb-3 col-md-6 col-lg-3">
                                    <label className="text-black font-w500">Item Name</label>
                                    <Select
                                        defaultValue={itemCode}
                                        value={itemCode}
                                        onChange={setItemCode}
                                        options={itemOptions.map((option) => ({
                                            label: option.im_item_name,
                                            value: option.im_item_code,
                                        }))}
                                        isClearable
                                        showContent="true"
                                        open="true"
                                        className="my-react-select-container"
                                        theme={theme => ({
                                            ...theme,
                                            borderRadius: '6px',
                                            colors: {
                                                ...theme.colors,
                                                primary25: '#b3b3b3',
                                                primary: 'black',
                                                neutral80: "data-theme-version" === "dark" ? 'white' : ''
                                            },
                                        })}
                                    />
                                </div>
                                <div className="form-group mb-3 col-md-6 col-lg-3">
                                    <Button
                                        className="me-2"
                                        variant="whatsapp"
                                        onClick={udfnFormValidate}
                                        style={{ marginTop: "10%" }}
                                        disabled={listButton ? true : false}
                                    >
                                        List{" "}
                                        <span className="btn-icon-right">
                                            <i className="fa fa-filter" />
                                        </span>
                                    </Button>
                                </div>
                            </div>
                            <div className="row">
                                <div className="form-group mb-2 col-lg-9 col-md-6 text-end">
                                    <label className="text-black font-w500 mt-3">Discount % <span className="text-danger star-txt">*</span></label>
                                </div>
                                <div className="form-group mb-2 col-lg-2 col-md-6">
                                    <input
                                        type="number"
                                        className="form-control"
                                        placeholder="Disc %"
                                        onKeyPress={(e) => udfnDiscountValidation(e)}
                                        value={discPer}
                                        onChange={(e) => setDiscPer(e.target.value)}
                                    />
                                </div>
                                <div className="form-group mb-2 col-lg-1 col-md-6 px-0">
                                    <Button className='me-2 mr-1' variant="info" onClick={udfnApplyBtn}>
                                        Apply
                                    </Button>
                                </div>
                            </div>
                            <table className="table filtering-table table-responsive-lg">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>S.NO.</th>
                                        <th>Group Name</th>
                                        <th>Category Name</th>
                                        {/* <th>Brand Name</th> */}
                                        <th>Item Name</th>
                                        <th>Old Disc (%) </th>
                                        {/* <th>Qr Code</th> */}
                                        <th>New Disc (%) </th>
                                    </tr>
                                </thead>
                                <tbody className="" >
                                    {itemList.map((row, index) => {
                                        return (
                                            <tr key={index}>
                                                <td>
                                                    <input
                                                        type="checkbox"
                                                        className="custom-control-input"
                                                        value={row.checkbox_value}
                                                        defaultChecked
                                                        // onClick={() => udfnCheckBoxSpecific(index, row)}
                                                        onChange={() => udfnCheckBoxSpecific(index, row)}
                                                    />
                                                </td>
                                                <td>{index + 1}</td>
                                                <td>{row.gm_group_name}</td>
                                                <td>{row.cm_category_name}</td>
                                                {/* <td>{row.bm_brand_name ? row.bm_brand_name : "-"}</td> */}
                                                <td width="25%">{row.im_display_name}</td>
                                                {/* <td>{row.qs_qr_code ? row.qs_qr_code : "-"}</td> */}
                                                <td width="12%" className='text-end discount_per' style={{ paddingRight: "3%" }}>
                                                    {row?.im_disc_per ? COMMONVARIABLES.India_Rupee_Formatter_Digits(Formatting('two_decimal', row?.im_disc_per)) : 0}
                                                </td>
                                                <td width="12%" className='text-end discount_per' style={{ paddingRight: "3%" }}>
                                                    {applyBtn ? COMMONVARIABLES.India_Rupee_Formatter_Digits(Formatting('two_decimal', discPer)) : "-"}
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                            <div align='right'>
                                <Button
                                    variant="danger"
                                    onClick={() => { navigate("/dashboard") }}
                                    className="me-2"
                                >
                                    Discard
                                </Button>
                                <Button variant="whatsapp" onClick={udfnDiscountBulkUpdate} disabled={buttonFlag ? true : false}>
                                    Update
                                </Button>
                            </div>
                        </form>
                    </div>
                </div >
            </LoadingOverlay >
        </>
    )

}
export default DiscountBulkUpdate;