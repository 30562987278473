import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import '../Filters/Common/filtering.css';
import "bootstrap-daterangepicker/daterangepicker.css";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { ToastContainer, toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { getLoggedInUser, getRefreshToken } from '../../../services/AuthService';
import COMMONVARIABLES from '../Utilities/constants';
import LoadingOverlay from 'react-loading-overlay-ts';
import GSTR_REPORT_API from '../../Api/GstrReportApi';
import ERROR_MESSAGE from '../Validation/ErrorMessage';
import { UserLogout } from '../../layouts/nav/Logout';

const ExcelJS = require('exceljs');

export const GSTRReport = () => {
    const user = getLoggedInUser();
    const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } };

    const [isLoading, setIsLoading] = useState(false);
    const [range, setRange] = useState(null);
    const [b2blist, setB2blist] = useState([]);
    const [b2cList, setB2CList] = useState([]);
    const [b2csList, setB2CSList] = useState([]);
    const [docsList, setDocsList] = useState([]);
    const [consolidateHsnList, setConsolidateHsnList] = useState([]);
    const [hsnList, setHsnList] = useState([]);

    Date.prototype.toDateString = function () {
        var month = this.getMonth() + 1;
        var day = this.getDate();
        var year = this.getFullYear();
        return year + "-" + (month < 10 ? '0' : '') + month + '-' + (day < 10 ? '0' : '') + day;
    };

    const udfnGstrReport = async () => {
        setIsLoading(true)
        try {
            var varFromDate = new Date().toDateString()
            var varToDate = new Date().toDateString()
            if (range !== "null" && range !== null) {
                varFromDate = new Date(range.split('-')[0]).toDateString()
                varToDate = new Date(range.split('-')[1]).toDateString()
            }

            const params = {
                user_id: user.id,
                from_date: String(varFromDate),
                to_date: String(varToDate)
            };

            const [b2bData, b2cData, b2csData, hsnData, docsData, consolidateHsnData] = await Promise.all([
                axios.post(process.env.REACT_APP_BASE_URL + GSTR_REPORT_API.GSTR_B2B_LIST, params, config),
                axios.post(process.env.REACT_APP_BASE_URL + GSTR_REPORT_API.GSTR_B2C_LIST, params, config),
                axios.post(process.env.REACT_APP_BASE_URL + GSTR_REPORT_API.GSTR_B2CS_LIST, params, config),
                axios.post(process.env.REACT_APP_BASE_URL + GSTR_REPORT_API.GSTR_HSN_LIST, params, config),
                axios.post(process.env.REACT_APP_BASE_URL + GSTR_REPORT_API.GSTR_DOCS_LIST, params, config),
                axios.post(process.env.REACT_APP_BASE_URL + GSTR_REPORT_API.GSTR_CONSOLIDATE_HSN_LIST, params, config)
            ]);

            if (b2bData?.data?.body?.status === COMMONVARIABLES.STATUS.INVALIDUSER || b2cData?.data?.body?.status === COMMONVARIABLES.STATUS.INVALIDUSER || b2csData?.data?.body?.status === COMMONVARIABLES.STATUS.INVALIDUSER || hsnData?.data?.body?.status === COMMONVARIABLES.STATUS.INVALIDUSER || docsData?.data?.body?.status === COMMONVARIABLES.STATUS.INVALIDUSER || consolidateHsnData?.data?.body?.status === COMMONVARIABLES.STATUS.INVALIDUSER) {
                setIsLoading(false);

                var errorMsg = b2bData?.data?.body?.message || b2cData?.data?.body?.message || b2csData?.data?.body?.message || hsnData?.data?.body?.message || docsData?.data?.body?.message || consolidateHsnData?.data?.body?.message;
                toast.error(errorMsg, { position: toast.POSITION.TOP_RIGHT });
                UserLogout();
            }

            if (b2bData?.data?.body?.status === COMMONVARIABLES.STATUS.UNAUTHORIZED || b2cData?.data?.body?.status === COMMONVARIABLES.STATUS.UNAUTHORIZED || b2csData?.data?.body?.status === COMMONVARIABLES.STATUS.UNAUTHORIZED || hsnData?.data?.body?.status === COMMONVARIABLES.STATUS.UNAUTHORIZED || docsData?.data?.body?.status === COMMONVARIABLES.STATUS.UNAUTHORIZED || consolidateHsnData?.data?.body?.status === COMMONVARIABLES.STATUS.UNAUTHORIZED) {
                const refresh = await getRefreshToken();
                setIsLoading(false);

                if (refresh) {
                    if (Number(refresh) === COMMONVARIABLES.STATUS.EXPIRED_TOKEN) {
                        UserLogout();
                    } else {
                        await udfnGstrReport();
                    }
                }
            }

            if (b2bData?.data?.body?.status === COMMONVARIABLES.STATUS.SERVICE_CATCH_STATUS || b2cData?.data?.body?.status === COMMONVARIABLES.STATUS.SERVICE_CATCH_STATUS || b2csData?.data?.body?.status === COMMONVARIABLES.STATUS.SERVICE_CATCH_STATUS || hsnData?.data?.body?.status === COMMONVARIABLES.STATUS.SERVICE_CATCH_STATUS || docsData?.data?.body?.status === COMMONVARIABLES.STATUS.SERVICE_CATCH_STATUS || consolidateHsnData?.data?.body?.status === COMMONVARIABLES.STATUS.SERVICE_CATCH_STATUS) {
                setIsLoading(false);
                toast.error(ERROR_MESSAGE.API_ERROR.SERVICE_CATCH_ERROR, { position: toast.POSITION.TOP_RIGHT });
            }

            if (b2bData?.data?.body?.GSTRB2BList || b2cData?.data?.body?.GSTRB2CList || b2csData?.data?.body?.GSTRB2CSList || hsnData?.data?.body?.GstrHsnList || docsData?.data?.body?.GstrDocsList || consolidateHsnData?.data?.body?.GstrDocsList) {
                setB2blist(b2bData?.data?.body?.GSTRB2BList)
                setB2CList(b2cData?.data?.body?.GSTRB2CList)
                setB2CSList(b2csData?.data?.body?.GSTRB2CSList)
                setHsnList(hsnData?.data?.body?.GstrHsnList)
                setDocsList(docsData?.data?.body?.GstrDocsList)
                setConsolidateHsnList(consolidateHsnData?.data?.body?.GstrHsnConsolidateList)
                setIsLoading(false);
            }
        } catch (error) {
            setIsLoading(false)
            console.error('Error fetching master data:', error);
        }
    }

    const exportToExcel = async () => {
        setIsLoading(true)

        const b2b_header = [
            'GSTIN/UIN of Recipient',
            'Receiver Name',
            'Invoice Number',
            'Invoice date',
            'Invoice Value',
            'Place Of Supply',
            'Reverse Charge',
            'Applicable % of Tax Rate',
            'Invoice Type',
            'E-Commerce GSTIN',
            'Rate',
            'Taxable Value',
            'Cess Amount'
        ];
        const b2c_header = [
            'Invoice Number',
            'Invoice date',
            'Invoice Value',
            'Place Of Supply',
            'Rate',
            'Taxable Value',
            'Cess Amount',
            'E-Commerce GSTIN'
        ];
        const b2cs_header = [
            'type',
            'Place Of Supply',
            'Applicable % of Tax Rate',
            'Rate',
            'Taxable Value',
            'Cess Amount',
            'E-Commerce GSTIN'
        ];
        const hsn_header = [
            'HSN',
            'Description',
            'UQC',
            'Total Quantity',
            'Total Value',
            'Rate',
            'Taxable Value',
            'Integrated Tax Amount',
            'Central Tax Amount',
            'State/UT Tax Amount',
            'Cess Amount'
        ];
        const docs_header = [
            'Nature of Document',
            'Sr.No.From',
            'Sr.No.To',
            'Total Number',
            'Cancelled'
        ];
        const consolidate_hsn_header = [
            'HSN',
            'Total Quantity',
            'Total Value',
            'Taxable Value',
            'Integrated Tax Amount',
            'Central Tax Amount',
            'State/UT Tax Amount',
            'Cess Amount'
        ];

        const sheetNames = [
            {
                sheetName: 'b2b',
                Header: b2b_header,
                data: b2blist
            },
            {
                sheetName: 'b2c',
                Header: b2c_header,
                data: b2cList
            },
            {
                sheetName: 'b2cs',
                Header: b2cs_header,
                data: b2csList
            },
            {
                sheetName: 'hsn',
                Header: hsn_header,
                data: hsnList
            },
            {
                sheetName: 'docs',
                Header: docs_header,
                data: docsList
            },
            {
                sheetName: 'consolidate-hsn',
                Header: consolidate_hsn_header,
                data: consolidateHsnList
            }
        ];

        const workbook = new ExcelJS.Workbook()

        sheetNames.forEach(Sheet => {
            let worksheet = workbook.addWorksheet(Sheet.sheetName);

            if (Sheet.sheetName === 'b2b') {
                const headerRow = worksheet.addRow(Sheet.Header)
                headerRow.font = { bold: true }
                headerRow.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'ffc0cb' } // Background color
                }
                Sheet.data.forEach((item, index) => {
                    worksheet.addRow([
                        item['GSTIN/UIN of Recipient'],
                        item['Receiver Name'],
                        item['Invoice Number'],
                        item['Invoice date'],
                        item['Invoice Value'],
                        item['Place of Supply'],
                        item['Reverse Charge'],
                        item['Applicable % of Tax Rate'],
                        item['Invoice Type'],
                        item['E-Commerce GSTIN'],
                        item['rate'],
                        item['Taxable Value'],
                        item['Cess Amount']
                    ])
                })
            }

            if (Sheet.sheetName === 'b2c') {
                const headerRow = worksheet.addRow(Sheet.Header)
                headerRow.font = { bold: true }
                headerRow.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'ffc0cb' } // Background color
                }
                Sheet.data.forEach((item, index) => {
                    worksheet.addRow([
                        item['Invoice Number'],
                        item['Invoice date'],
                        item['Invoice Value'],
                        item['Place Of Supply'],
                        item['Rate'],
                        item['Taxable Value'],
                        item['Cess Amount'],
                        item['E-Commerce GSTIN']
                    ])
                })
            }

            if (Sheet.sheetName === 'b2cs') {
                const headerRow = worksheet.addRow(Sheet.Header)
                headerRow.font = { bold: true }
                headerRow.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'ffc0cb' } // Background color
                }
                Sheet.data.forEach((item, index) => {
                    worksheet.addRow([
                        item['type'],
                        item['Place Of Supply'],
                        item['Applicable % of Tax Rate'],
                        item['Rate'],
                        item['Taxable Value'],
                        item['Cess Amount'],
                        item['E-Commerce GSTIN']
                    ])
                })
            }

            if (Sheet.sheetName === 'hsn') {
                const headerRow = worksheet.addRow(Sheet.Header)
                headerRow.font = { bold: true }
                headerRow.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'ffc0cb' } // Background color
                }
                Sheet.data.forEach((item, index) => {
                    worksheet.addRow([
                        item['HSN'],
                        item['Description'],
                        item['UQC'],
                        item['Total Quantity'],
                        item['Total Value'],
                        item['Rate'],
                        item['Taxable Value'],
                        item['Integrated Tax Amount'],
                        item['Central Tax Amount'],
                        item['State/UT Tax Amount'],
                        item['Cess Amount']
                    ])
                })
            }

            if (Sheet.sheetName === 'docs') {
                const headerRow = worksheet.addRow(Sheet.Header)
                headerRow.font = { bold: true }
                headerRow.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'ffc0cb' } // Background color
                }
                Sheet.data.forEach((item, index) => {
                    worksheet.addRow([
                        item["Nature of Document"],
                        item["Sr.No.From"],
                        item["Sr.No.To"],
                        item["Total Number"],
                        item["Cancelled"],
                    ])
                })
            }

            if (Sheet.sheetName === 'consolidate-hsn') {
                const headerRow = worksheet.addRow(Sheet.Header)
                headerRow.font = { bold: true }
                headerRow.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'ffc0cb' } // Background color
                }

                console.log(Sheet.data);

                Sheet.data.forEach((item, index) => {
                    worksheet.addRow([
                        item['HSN'],
                        item['Total Quantity'],
                        item['Total Value'],
                        item['Taxable Value'],
                        item['Integrated Tax Amount'],
                        item['Central Tax Amount'],
                        item['State/UT Tax Amount'],
                        item['Cess Amount']
                    ])
                })
            }

            worksheet.state = 'visible';
        });

        // Save the workbook
        const buffer = await workbook.xlsx.writeBuffer()
        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })

        const url = URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.href = url
        a.download = `GSTR Report.xlsx`
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
        setIsLoading(false)
    }

    useEffect(() => {
        udfnGstrReport();
    }, [range]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <LoadingOverlay
                active={isLoading}
                spinner
                text='Loading ...'
            >
                <ToastContainer />
                <div className="card">
                    <div className="card-header">
                        <div className='col-sm-5'>
                            <h4 className="card-title">GSTR Report</h4>
                        </div>

                    </div>
                    <div className="card-body">
                        <div className='row'>

                            <div className='col-sm-3'>
                                <p className="mb-1">Select From Date And To Date</p>

                                <DateRangePicker
                                    onApply={(e) => {
                                        setRange(e.target.value)
                                    }}
                                    initialSettings={{ startDate: new Date(), endDate: new Date() }}
                                >
                                    <input type="text" className="form-control input-daterange-timepicker" styles={{ height: "90%" }} />
                                </DateRangePicker>
                            </div>
                            <div className='col-sm-2 text-center mt-2'>
                                <Button variant="info" className="mb-2 mt-4" onClick={() => exportToExcel()}>
                                    <i className="fa fa-download" /> &nbsp; Export
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </LoadingOverlay>
        </>
    )
}

export default GSTRReport;