import { ColumnFilter } from '../Common/ColumnFilter';
export const COLUMNS = [
	// {
	// 	Header: 'S.No.',
	// 	Footer: 'S.No.',
	// 	accessor: 'id',
	// 	Filter: ColumnFilter,
	// 	// disableFilters: true,
	// },
	{
		Header: 'Adjustment Date',
		Footer: 'Adjustment Date',
		accessor: 'adjustment_date',
		Filter: ColumnFilter,
		// disableFilters: true,
	},
	// {
	// 	Header: 'Adjustment Type',
	// 	Footer: 'Adjustment Type',
	// 	accessor: 'adjustment_type',
	// 	Filter: ColumnFilter,
	// 	// disableFilters: true,
	// },
	{
		Header: 'Branch Name',
		Footer: 'Branch Name',
		accessor: 'bm_branch_name',
		Filter: ColumnFilter,
		// disableFilters: true,
	},
	{
		Header: 'Item Count',
		Footer: 'Item Count',
		accessor: 'itemcount',
		Filter: ColumnFilter,
		// disableFilters: true,
	},
	// {
	// 	Header: 'QR Code',
	// 	Footer: 'QR Code',
	// 	accessor: 'qr_code',
	// 	Filter: ColumnFilter,
	// 	// disableFilters: true,
	// },
	// {
	// 	Header: 'Quantity',
	// 	Footer: 'Quantity',
	// 	accessor: 'qs_quantity',
	// 	Filter: ColumnFilter,
	// 	// disableFilters: true,
	// }
]

export const GROUPED_COLUMNS = [
]