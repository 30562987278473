import React, { useMemo, useState, useEffect } from 'react';
import { useTable, useGlobalFilter, useFilters, useSortBy, usePagination } from 'react-table';
import { COLUMNS } from '../Filters/StockReport/StockReportColumns';
import { Button, Accordion } from 'react-bootstrap';
import '../Filters/Common/filtering.css';
import "bootstrap-daterangepicker/daterangepicker.css";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { getLoggedInUser, getRefreshToken } from '../../../services/AuthService';
import { Logout } from '../../../store/actions/AuthActions';
import COMMONVARIABLES from '../Utilities/constants';
import COMMON_API from '../../Api/CommonApi';
import LoadingOverlay from 'react-loading-overlay-ts';
import STOCK_REPORT_API from '../../Api/StockReportApi';
import ERROR_MESSAGE from '../Validation/ErrorMessage';
import Formatting from '../Utilities/format';
const ExcelJS = require('exceljs');

export const StockReport = () => {

    const [rateAndAmountShow, setRateAndAmountShow] = useState(localStorage.getItem('rateAndAmountShow') || false);

    var header = COLUMNS;
    const columns = useMemo(() => header, [])
    const [data, setData] = useState([])
    const [totalAmount, setTotalAmount] = useState(0)
    const tableInstance = useTable({
        columns,
        data,
        initialState: { pageIndex: 0 }
    }, useFilters, useGlobalFilter, useSortBy, usePagination)

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        state,
        page,
        gotoPage,
        pageCount,
        pageOptions,
        nextPage,
        previousPage,
        canNextPage,
        canPreviousPage,
    } = tableInstance

    const { pageIndex } = state

    Date.prototype.toDateString = function () {
        var month = this.getMonth() + 1;
        var day = this.getDate();
        var year = this.getFullYear();
        return year + "-" + (month < 10 ? '0' : '') + month + '-' + (day < 10 ? '0' : '') + day;
    };

    const [isLoading, setIsLoading] = useState(false);
    const currentDate = new Date().toDateString();
    const [transferDate, setTransferDate] = useState(localStorage.getItem('stockReportDate') || currentDate);

    const [branchName, setBranchName] = useState(JSON.parse(localStorage.getItem('stockReportBranchName')) || { label: "All", value: 0 });
    const [branchNameOptions, setBranchNameOptions] = useState([])
    const [groupName, setGroupName] = useState(JSON.parse(localStorage.getItem('stockReportGroupName')) || { label: "All", value: 0 });
    const [itemGroupNameOptions, setItemGroupNameOptions] = useState([])
    const [categoryName, setCategoryName] = useState(JSON.parse(localStorage.getItem('stockReportCategoryName')) || { label: "All", value: 0 });
    const [categoryNameOptions, setCategoryNameOptions] = useState([])
    const [brandName, setBrandName] = useState(JSON.parse(localStorage.getItem('stockReportBrandName')) || { label: "All", value: 0 });
    const [brandNameOptions, setBrandNameOptions] = useState([])
    const [itemName, setItemName] = useState(JSON.parse(localStorage.getItem('stockReportItemName')) || { label: "All", value: 0 });
    const [itemNameOptions, setItemNameOptions] = useState([])
    const [itemFilter, setItemFilter] = useState(JSON.parse(localStorage.getItem('stockReportItemFilter')) || { label: "Item Wise", value: 1 })
    const [filterOptions, setFilterOptions] = useState([{ label: "Item Wise", value: 1 }, { label: "Barcode Wise", value: 2 }, { label: "Supplier Wise", value: 3 }]);
    const [supplierNameOptions, setSupplierNameOptions] = useState([])
    const [supplierName, setSupplierName] = useState(JSON.parse(localStorage.getItem('stockReportSupplierName')) || { label: "All", value: 0 })
    const [negativeStockData, setNegativeStockData] = useState([])
    const [negativeStock, setNegativeStock] = useState(localStorage.getItem('stockReportNegative') || false);

    useEffect(() => {
        fetchMasterDataWithParams()
        if (itemFilter.value === 1) {
            udfnBindStockReportData();
        } else if (itemFilter.value === 2) {
            udfnBindStockReportDataBarcodeWise()
        } else if (itemFilter.value === 3) {
            udfnBindStockReportDataSupplierWise()
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    async function fetchMasterDataWithParams() {
        try {
            setIsLoading(true)
            const user = getLoggedInUser()

            const branchParams = { status_code: 0, user_id: user?.id };
            const categoryParams = { status_code: 0, user_id: user?.id };
            const itemGroupParams = { status_code: 0, user_id: user?.id };
            const brandParams = { status_code: 0, user_id: user?.id };
            const itemParams = { status_code: 0, user_id: user?.id };
            const supplierParams = {
                user_id: user?.id,
                status_code: Number(1)
            };
            const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } };
            const [branchData, categoryData, itemGroupData, brandData, itemData, supplierData] = await Promise.all([
                axios.post(process.env.REACT_APP_BASE_URL + COMMON_API.BRANCH_MASTER_LIST, branchParams, config),
                axios.post(process.env.REACT_APP_BASE_URL + COMMON_API.CATEGORY_LIST, categoryParams, config),
                axios.post(process.env.REACT_APP_BASE_URL + COMMON_API.GET_GROUP_LIST, itemGroupParams, config),
                axios.post(process.env.REACT_APP_BASE_URL + COMMON_API.BRAND_MASTER_LIST, brandParams, config),
                axios.post(process.env.REACT_APP_BASE_URL + COMMON_API.ITEM_MASTER_LIST, itemParams, config),
                axios.post(process.env.REACT_APP_BASE_URL + COMMON_API.SUPPLIER_MASTER_LIST, supplierParams, config)

            ]);
            if (branchData?.data?.body?.status === COMMONVARIABLES.STATUS.UNAUTHORIZED || categoryData?.data?.body?.status === COMMONVARIABLES.STATUS.UNAUTHORIZED || itemGroupData?.data?.body?.status === COMMONVARIABLES.STATUS.UNAUTHORIZED || brandData?.data?.body?.status === COMMONVARIABLES.STATUS.UNAUTHORIZED || itemData?.data?.body?.status === COMMONVARIABLES.STATUS.UNAUTHORIZED || supplierData?.data?.body?.status === COMMONVARIABLES.STATUS.UNAUTHORIZED) {
                const refresh = await getRefreshToken();
                if (refresh) {
                    if (Number(refresh) === COMMONVARIABLES.STATUS.EXPIRED_TOKEN) {
                        Logout();
                    } else {
                        await fetchMasterDataWithParams();
                    }
                }
            }

            else if (branchData?.data?.body?.branchList || categoryData?.data?.body?.categoryList || itemGroupData?.data?.body?.groupList || brandData?.data?.body?.brandList || itemData?.data?.body?.itemList || supplierData?.data?.body?.supplierList) {
                var brand_filter = brandData?.data?.body?.brandList.filter(item => item.bm_brand_code > 0)
                setBranchNameOptions([{ bm_branch_name: "All", bm_branch_code: 0 }, ...branchData?.data?.body?.branchList])
                setItemNameOptions([{ im_display_name: "All", im_item_code: 0 }, ...itemData?.data?.body?.itemList])
                setBrandNameOptions([{ bm_brand_name: "All", bm_brand_code: 0 }, ...brand_filter])
                setCategoryNameOptions([{ cm_category_name: "All", cm_category_code: 0 }, ...categoryData?.data?.body?.categoryList])
                setItemGroupNameOptions([{ gm_group_name: "All", gm_group_code: 0 }, ...itemGroupData?.data?.body?.groupList])
                setSupplierNameOptions([{ sm_supplier_name: "All", sm_supplier_code: 0 }, ...supplierData?.data?.body?.supplierList])
            }
        } catch (error) {
            console.error('Error fetching master data:', error);
            if (error.code === COMMONVARIABLES.STATUS.NETWORK_ERROR_STATUS) {
                toast.error(ERROR_MESSAGE.API_ERROR.NETWORK_ERROR, { position: toast.POSITION.TOP_RIGHT });
            }
            if (error.code === COMMONVARIABLES.STATUS.BAD_REQUEST_ERROR_STATUS) {
                toast.error(ERROR_MESSAGE.API_ERROR.BAD_REQUEST_ERROR, { position: toast.POSITION.TOP_RIGHT });
            }
        }
    }

    async function udfnBindStockReportData() {
        try {
            setIsLoading(true)
            const user = getLoggedInUser()
            const params = { item_code: itemName.value, user_id: user?.id, branch_code: branchName.value, category_code: categoryName.value, group_code: groupName.value, brand_code: brandName.value, date: transferDate };

            const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } };
            const [stockReportList] = await Promise.all([
                axios.post(process.env.REACT_APP_BASE_URL + STOCK_REPORT_API.STOCK_REPORT_LIST, params, config),

            ]);
            // setIsLoading(false)

            if (stockReportList?.data?.body?.status === COMMONVARIABLES.STATUS.UNAUTHORIZED) {

                const refresh = await getRefreshToken();

                if (refresh) {
                    if (Number(refresh) === COMMONVARIABLES.STATUS.EXPIRED_TOKEN) {
                        Logout();
                    } else {
                        await udfnBindStockReportData();
                    }
                }
            }

            if (stockReportList?.data?.body?.status === COMMONVARIABLES.STATUS.SERVICE_CATCH_STATUS) {
                toast.error(ERROR_MESSAGE.API_ERROR.SERVICE_CATCH_ERROR, { position: toast.POSITION.TOP_RIGHT });
            }

            if (stockReportList?.data?.body?.stockReportList) {
                setData(stockReportList?.data?.body?.stockReportList)
                var total = 0;
                stockReportList?.data?.body?.stockReportList.forEach(row => {
                    total = total + row.total_amount;
                });
                setTotalAmount(COMMONVARIABLES.India_Rupee_Formatter_Digits(Formatting('two_decimal', total)))
                if (negativeStock === true || negativeStock === "true") {
                    var negativeStockReport = stockReportList.data.body.stockReportList.filter(item => item.current_stock < 0);
                    setData(negativeStockReport)
                }
                setIsLoading(false)
            }


        } catch (error) {
            setIsLoading(false)
            console.error('Error fetching master data:', error);
            if (error.code === COMMONVARIABLES.STATUS.NETWORK_ERROR_STATUS) {
                toast.error(ERROR_MESSAGE.API_ERROR.NETWORK_ERROR, { position: toast.POSITION.TOP_RIGHT });
            }
            if (error.code === COMMONVARIABLES.STATUS.BAD_REQUEST_ERROR_STATUS) {
                toast.error(ERROR_MESSAGE.API_ERROR.BAD_REQUEST_ERROR, { position: toast.POSITION.TOP_RIGHT });
            }
        }
    }

    async function udfnBindStockReportDataBarcodeWise() {
        try {
            setData([])
            setIsLoading(true)
            const user = getLoggedInUser()
            const params = { item_code: itemName.value, user_id: user?.id, branch_code: branchName.value, category_code: categoryName.value, group_code: groupName.value, brand_code: brandName.value, date: transferDate };

            const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } };
            const [stockReportList] = await Promise.all([
                axios.post(process.env.REACT_APP_BASE_URL + STOCK_REPORT_API.STOCK_REPORT_BARCODE_WISE_LIST, params, config),

            ]);
            setIsLoading(false)

            if (stockReportList?.data?.body?.status === COMMONVARIABLES.STATUS.UNAUTHORIZED) {

                const refresh = await getRefreshToken();

                if (refresh) {
                    if (Number(refresh) === COMMONVARIABLES.STATUS.EXPIRED_TOKEN) {
                        Logout();
                    } else {
                        await udfnBindStockReportDataBarcodeWise();
                    }
                }
            }

            if (stockReportList?.data?.body?.status === COMMONVARIABLES.STATUS.SERVICE_CATCH_STATUS) {
                toast.error(ERROR_MESSAGE.API_ERROR.SERVICE_CATCH_ERROR, { position: toast.POSITION.TOP_RIGHT });
            }

            if (stockReportList?.data?.body?.stockReportList) {
                setData(stockReportList?.data?.body?.stockReportList)
                var total = 0;
                stockReportList?.data?.body?.stockReportList.forEach(row => {
                    total = total + row.total_amount;
                });
                setTotalAmount(COMMONVARIABLES.India_Rupee_Formatter_Digits(Formatting('two_decimal', total)))
                if (negativeStock === true || negativeStock === "true") {
                    var negativeStockReport = stockReportList.data.body.stockReportList.filter(item => item.current_stock < 0);
                    setData(negativeStockReport)
                }
            }


        } catch (error) {
            setIsLoading(false)
            console.error('Error fetching master data:', error);
            if (error.code === COMMONVARIABLES.STATUS.NETWORK_ERROR_STATUS) {
                toast.error(ERROR_MESSAGE.API_ERROR.NETWORK_ERROR, { position: toast.POSITION.TOP_RIGHT });
            }
            if (error.code === COMMONVARIABLES.STATUS.BAD_REQUEST_ERROR_STATUS) {
                toast.error(ERROR_MESSAGE.API_ERROR.BAD_REQUEST_ERROR, { position: toast.POSITION.TOP_RIGHT });
            }
        }
    }

    const exportToExcel = async () => {
        setIsLoading(true)
        const arraydata = data

        var listCount = data.length

        if (listCount > 0) {

            // colorWiseTotalDispatch
            let Stock_Report = []

            let total_current_stock = 0
            let total_purchase_rate = 0
            let total_amount = 0

            if (itemFilter.value === 1) {
                Stock_Report = arraydata.map((e, index) => {
                    total_current_stock = total_current_stock + e.current_stock
                    total_purchase_rate = total_purchase_rate + e.qs_rate
                    total_amount = total_amount + e.total_amount

                    return {
                        "S.No.": index + 1, "Item Group": e.group_name, "Item Category": e.category_name, "Brand": e.brand_name ? e.brand_name : '-', 'Item Name': e.item_name, 'Current Stock': e.current_stock, 'Purchase Rate': COMMONVARIABLES.India_Rupee_Formatter_Digits(Formatting('two_decimal', e.qs_rate)), "Total Amount": COMMONVARIABLES.India_Rupee_Formatter_Digits(Formatting('two_decimal', e.total_amount))
                    }
                })
            } else if (itemFilter.value === 2) {
                Stock_Report = arraydata.map((e, index) => {
                    total_current_stock = total_current_stock + e.current_stock
                    total_purchase_rate = total_purchase_rate + e.qs_rate
                    total_amount = total_amount + e.total_amount

                    return {
                        "S.No.": index + 1, "Item Group": e.group_name, "Item Category": e.category_name, "Brand": e.brand_name ? e.brand_name : '-', 'Item Name': e.item_name, 'QR Code': e.qr_code, 'Current Stock': e.current_stock, 'Purchase Rate': COMMONVARIABLES.India_Rupee_Formatter_Digits(Formatting('two_decimal', e.qs_rate)), "Total Amount": COMMONVARIABLES.India_Rupee_Formatter_Digits(Formatting('two_decimal', e.total_amount))
                    }
                })
            } else if (itemFilter.value === 3) {
                Stock_Report = arraydata.map((e, index) => {
                    total_current_stock = total_current_stock + e.current_stock
                    total_purchase_rate = total_purchase_rate + e.qs_rate
                    total_amount = total_amount + e.total_amount

                    return {
                        "S.No.": index + 1, 'Supplier Name': e.sm_supplier_name, "Item Group": e.group_name, "Item Category": e.category_name, "Brand": e.brand_name ? e.brand_name : '-', 'Item Name': e.item_name, 'Current Stock': e.current_stock, 'Purchase Rate': COMMONVARIABLES.India_Rupee_Formatter_Digits(Formatting('two_decimal', e.qs_rate)), "Total Amount": COMMONVARIABLES.India_Rupee_Formatter_Digits(Formatting('two_decimal', e.total_amount))
                    }
                })
            }

            const data_array = []
            Stock_Report.forEach((row) => {
                data_array.push(Object.values(row))
            })

            var array_data_count = data_array.length;

            const title = 'Stock Report On - ' + transferDate

            const reportData = {
                title: title,
                data: data_array,
                headers: Object.keys(Stock_Report[0])
            }

            const workbook = new ExcelJS.Workbook()
            const worksheet = workbook.addWorksheet(itemFilter.label)

            if (itemFilter.value === 1) {
                worksheet.mergeCells('A1', 'H1')
            } else {
                worksheet.mergeCells('A1', 'I1')
            }
            const titleRow = worksheet.getCell('A1')
            titleRow.value = reportData['title']
            titleRow.font = {
                name: 'Calibri',
                size: 16,
                bold: true,
                color: { argb: '000000' }
            }
            titleRow.alignment = { vertical: 'middle', horizontal: 'center' }

            worksheet.mergeCells('A2', 'D2')
            const branch_head = 'Branch : '
            const branch_val = branchName.label ? branchName.label : 'All'
            const branchRow = worksheet.getCell('A2')
            branchRow.value = branch_head + branch_val
            branchRow.font = {
                name: 'Calibri',
                size: 12,
                color: { argb: '00000' }
            }
            branchRow.alignment = { vertical: 'left', horizontal: 'left' }

            if (itemFilter.value === 1) {
                worksheet.mergeCells('E2', 'H2')
            } else {
                worksheet.mergeCells('E2', 'I2')
            }
            const item_group_head = 'Item Group : '
            const item_group_val = groupName.label ? groupName.label : 'All'
            const itemGroupRow = worksheet.getCell('E2')
            itemGroupRow.value = item_group_head + item_group_val
            itemGroupRow.font = {
                name: 'Calibri',
                size: 12,
                color: { argb: '00000' }
            }
            itemGroupRow.alignment = { vertical: 'left', horizontal: 'left' }

            worksheet.mergeCells('A3', 'D3')
            const item_category_head = 'Item Category : '
            const item_category_val = branchName.label ? branchName.label : 'All'
            const itemCategoryRow = worksheet.getCell('A3')
            itemCategoryRow.value = item_category_head + item_category_val
            itemCategoryRow.font = {
                name: 'Calibri',
                size: 12,
                color: { argb: '00000' }
            }
            itemCategoryRow.alignment = { vertical: 'left', horizontal: 'left' }

            if (itemFilter.value === 1) {
                worksheet.mergeCells('E3', 'H3')
            } else {
                worksheet.mergeCells('E3', 'I3')
            }
            const brand_head = 'Brand : '
            const brand_val = brandName.label ? brandName.label : 'All'
            const brandRow = worksheet.getCell('E3')
            brandRow.value = brand_head + brand_val
            brandRow.font = {
                name: 'Calibri',
                size: 12,
                color: { argb: '00000' }
            }
            brandRow.alignment = { vertical: 'left', horizontal: 'left' }

            if (itemFilter.value === 1) {
                worksheet.mergeCells('A4', 'H4')
            } else {
                worksheet.mergeCells('A4', 'I4')
            }
            const item_name_head = 'Item Name : '
            const item_name_val = itemName.label ? itemName.label : 'All'
            const itemNameRow = worksheet.getCell('A4')
            itemNameRow.value = item_name_head + item_name_val
            itemNameRow.font = {
                name: 'Calibri',
                size: 12,
                color: { argb: '00000' }
            }
            itemNameRow.alignment = { vertical: 'left', horizontal: 'left' }

            const headerRow = worksheet.addRow(reportData['headers'])
            headerRow.eachCell((cell) => {
                cell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'ff8b3d' },
                    bgColor: { argb: 'ff8b3d' }
                }
                cell.font = {
                    bold: true,
                    color: { argb: 'FFFFFF' },
                    size: 12
                }
            })

            data_array.forEach(d => {
                worksheet.addRow(d)
            })

            var format_total_purchase_rate = COMMONVARIABLES.India_Rupee_Formatter_Digits(Formatting('two_decimal', total_purchase_rate))
            var format_total_amount = COMMONVARIABLES.India_Rupee_Formatter_Digits(Formatting('two_decimal', total_amount))

            if (itemFilter.value === 1) {
                const total = ['', '', '', '', 'Total', total_current_stock, format_total_purchase_rate, format_total_amount]
                const footerRow = worksheet.addRow(total)
                footerRow.eachCell((cell) => {
                    cell.font = {
                        bold: true,
                        color: { argb: '000000' },
                        size: 14
                    }
                })
                footerRow.alignment = { vertical: 'middle', horizontal: 'right' }
            } else {
                const total = ['', '', '', '', '', 'Total', total_current_stock, format_total_purchase_rate, format_total_amount]
                const footerRow = worksheet.addRow(total)
                footerRow.eachCell((cell) => {
                    cell.font = {
                        bold: true,
                        color: { argb: '000000' },
                        size: 14
                    }
                })
                footerRow.alignment = { vertical: 'middle', horizontal: 'right' }
            }

            var starting_cell = 5;
            var last_cell = array_data_count + starting_cell

            for (let i = starting_cell; i <= last_cell; i++) {
                if (itemFilter.value === 1) {
                    const inwardColumn = worksheet.getCell('F' + i)
                    const outwardColumn = worksheet.getCell('G' + i)
                    const closingColumn = worksheet.getCell('H' + i)
                    inwardColumn.alignment = { vertical: 'middle', horizontal: 'right' }
                    outwardColumn.alignment = { vertical: 'middle', horizontal: 'right' }
                    closingColumn.alignment = { vertical: 'middle', horizontal: 'right' }
                } else {
                    const inwardColumn = worksheet.getCell('G' + i)
                    const outwardColumn = worksheet.getCell('H' + i)
                    const closingColumn = worksheet.getCell('I' + i)
                    inwardColumn.alignment = { vertical: 'middle', horizontal: 'right' }
                    outwardColumn.alignment = { vertical: 'middle', horizontal: 'right' }
                    closingColumn.alignment = { vertical: 'middle', horizontal: 'right' }
                }
            }

            worksheet.getColumn(1).width = 5
            worksheet.getColumn(2).width = 20
            worksheet.getColumn(3).width = 35
            worksheet.getColumn(4).width = 20
            worksheet.getColumn(5).width = 20
            worksheet.getColumn(6).width = 20
            worksheet.getColumn(7).width = 15
            worksheet.getColumn(8).width = 20
            if (itemFilter.value === 2) {
                worksheet.getColumn(9).width = 20
            }

            // Save the workbook
            const buffer = await workbook.xlsx.writeBuffer()
            const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })

            const url = URL.createObjectURL(blob)
            const a = document.createElement('a')
            a.href = url
            a.download = `Stock Report.xlsx`
            document.body.appendChild(a)
            a.click()
            document.body.removeChild(a)
        } else {
            toast.error(ERROR_MESSAGE.EXPORT_TO_EXCEL_NO_RECORD, { position: toast.POSITION.TOP_RIGHT });
        }
        setIsLoading(false)
    }
    async function udfnBindStockReportDataSupplierWise() {
        try {
            setData([])
            setIsLoading(true)
            const user = getLoggedInUser()
            const params = { item_code: itemName.value, user_id: user?.id, branch_code: branchName.value, category_code: categoryName.value, group_code: groupName.value, brand_code: brandName.value, date: transferDate, supplier_code: supplierName.value };

            const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } };
            const [stockReportList] = await Promise.all([
                axios.post(process.env.REACT_APP_BASE_URL + STOCK_REPORT_API.STOCK_REPORT_SUPPLIER_WISE_LIST, params, config),

            ]);
            setIsLoading(false)

            if (stockReportList?.data?.body?.status === COMMONVARIABLES.STATUS.UNAUTHORIZED) {

                const refresh = await getRefreshToken();

                if (refresh) {
                    if (Number(refresh) === COMMONVARIABLES.STATUS.EXPIRED_TOKEN) {
                        Logout();
                    } else {
                        await udfnBindStockReportDataSupplierWise();
                    }
                }
            }

            if (stockReportList?.data?.body?.status === COMMONVARIABLES.STATUS.SERVICE_CATCH_STATUS) {
                toast.error(ERROR_MESSAGE.API_ERROR.SERVICE_CATCH_ERROR, { position: toast.POSITION.TOP_RIGHT });
            }

            if (stockReportList?.data?.body?.supplierWiseStockReportList) {
                setData(stockReportList?.data?.body?.supplierWiseStockReportList)
                var total = 0;
                stockReportList?.data?.body?.supplierWiseStockReportList.forEach(row => {
                    total = total + row.total_amount;
                });
                setTotalAmount(COMMONVARIABLES.India_Rupee_Formatter_Digits(Formatting('two_decimal', total)))
                if (negativeStock === true || negativeStock === "true") {
                    var negativeStockReport = stockReportList.data.body.supplierWiseStockReportList.filter(item => item.current_stock < 0);
                    setData(negativeStockReport)
                }
            }


        } catch (error) {
            setIsLoading(false)
            console.error('Error fetching master data:', error);
            if (error.code === COMMONVARIABLES.STATUS.NETWORK_ERROR_STATUS) {
                toast.error(ERROR_MESSAGE.API_ERROR.NETWORK_ERROR, { position: toast.POSITION.TOP_RIGHT });
            }
            if (error.code === COMMONVARIABLES.STATUS.BAD_REQUEST_ERROR_STATUS) {
                toast.error(ERROR_MESSAGE.API_ERROR.BAD_REQUEST_ERROR, { position: toast.POSITION.TOP_RIGHT });
            }
        }
    }

    function stockReport() {
        if (itemFilter.value === 1) {
            udfnBindStockReportData();
        } else if (itemFilter.value === 2) {
            udfnBindStockReportDataBarcodeWise()
        } else if (itemFilter.value === 3) {
            udfnBindStockReportDataSupplierWise()
        }
    }

    const udfnNegativeStockReport = (value) => {
        var returnVal = value === 'true' ? false : true;
        setNegativeStock(returnVal);
    };

    const udfnRateAndAmountHandle = (value) => {
        var returnVal = value === 'true' ? false : true;
        setRateAndAmountShow(returnVal);
    }

    console.log("rateAndAmountShow", rateAndAmountShow);

    useEffect(() => {
        setData([])
    }, [transferDate, itemFilter?.value, branchName?.value, groupName?.value, categoryName?.value, brandName?.value, itemName?.value]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        localStorage.setItem('stockReportDate', transferDate)
        localStorage.setItem('stockReportBranchName', JSON.stringify(branchName))
        localStorage.setItem('stockReportBrandName', JSON.stringify(brandName))
        localStorage.setItem('stockReportGroupName', JSON.stringify(groupName))
        localStorage.setItem('stockReportCategoryName', JSON.stringify(categoryName))
        localStorage.setItem('stockReportItemName', JSON.stringify(itemName))
        localStorage.setItem('stockReportSupplierName', JSON.stringify(supplierName))
        localStorage.setItem('stockReportItemFilter', JSON.stringify(itemFilter))
        localStorage.setItem('stockReportNegative', negativeStock)
        localStorage.setItem('rateAndAmountShow', rateAndAmountShow)
    }, [transferDate, itemFilter, groupName, categoryName, branchName, itemName, supplierName, brandName, negativeStock, rateAndAmountShow])  // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <LoadingOverlay
                active={isLoading}
                spinner
                text='Loading ...'
            >
                <ToastContainer />
                <div className="card">
                    <div className="card-header">
                        <div className='col-sm-5'>
                            <h4 className="card-title">Stock Report</h4>
                        </div>
                        <div className='cash-total col-sm-4 text-center'>
                            <Button variant="info" className="mb-2" onClick={() => exportToExcel()}>
                                <i className="fa fa-download" /> &nbsp; Export
                            </Button>
                        </div>
                        <div className='col-sm-2 mb-2'>
                            <div className="bg-secondary coin-holding flex-wrap py-0 m-0">
                                <div className="mb-2 coin-bx">
                                    <div className="d-flex align-items-center">
                                        <div>
                                            <i className="fa fa-rupee udfn-rupee-icon"></i>
                                        </div>
                                        <div className="ms-3">
                                            <h4 className="coin-font font-w600 mb-0 text-white mt-2">Total</h4>
                                            <p className="mb-0 text-white op-6">{totalAmount}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card-body">
                        <Accordion className="accordion accordion-rounded-stylish accordion-bordered  accordion-active-header" defaultActiveKey="0">
                            <Accordion.Item>
                                <Accordion.Header className="accordion-header accordion-header-primary">
                                    <h5>Filters</h5>
                                    <span className="accordion-header-indicator "></span>
                                </Accordion.Header>
                                <Accordion.Collapse className="accordion__body">
                                    <div className="accordion-body">
                                        <div className="row">
                                            <div className="col-md-4 mb-3">
                                                <label className="text-black font-w300">Date</label>
                                                <input value={transferDate} onChange={e => setTransferDate(e.target.value)} type="date" className="form-control" />
                                            </div>
                                            <div className="col-md-3 mb-3">
                                                <p className="mb-1">Report Type</p>
                                                <Select
                                                    defaultValue={itemFilter}
                                                    onChange={setItemFilter}
                                                    options={filterOptions.map((option) => ({
                                                        label: option?.label,
                                                        value: option?.value,
                                                    }))}
                                                    showContent="true"
                                                    open="true"
                                                    className="my-react-select-container"
                                                    theme={theme => ({
                                                        ...theme,
                                                        borderRadius: "6px",
                                                        colors: {
                                                            ...theme.colors,
                                                            primary25: '#b3b3b3',
                                                            primary: 'black',
                                                            neutral80: "data-theme-version" === "dark" ? 'white' : ''
                                                        },
                                                    })}
                                                />
                                            </div>
                                            {itemFilter.value === 3 && <div className="col-md-3 mb-3">
                                                <p className="mb-1">Supplier Name</p>
                                                <Select
                                                    defaultValue={supplierName}
                                                    onChange={setSupplierName}
                                                    options={supplierNameOptions.map((option) => ({
                                                        label: option?.sm_supplier_name,
                                                        value: option?.sm_supplier_code,
                                                    }))}
                                                    showContent="true"
                                                    open="true"
                                                    className="my-react-select-container"
                                                    theme={theme => ({
                                                        ...theme,
                                                        borderRadius: "6px",
                                                        colors: {
                                                            ...theme.colors,
                                                            primary25: '#b3b3b3',
                                                            primary: 'black',
                                                            neutral80: "data-theme-version" === "dark" ? 'white' : ''
                                                        },
                                                    })}
                                                />
                                            </div>}
                                            <div className="col-md-3 mb-3">
                                                <p className="mb-1">Branch</p>
                                                <Select
                                                    defaultValue={branchName}
                                                    onChange={setBranchName}
                                                    options={branchNameOptions.map((option) => ({
                                                        label: option?.bm_branch_name,
                                                        value: option?.bm_branch_code,
                                                    }))}
                                                    showContent="true"
                                                    open="true"
                                                    className="my-react-select-container"
                                                    theme={theme => ({
                                                        ...theme,
                                                        borderRadius: "6px",
                                                        colors: {
                                                            ...theme.colors,
                                                            primary25: '#b3b3b3',
                                                            primary: 'black',
                                                            neutral80: "data-theme-version" === "dark" ? 'white' : ''
                                                        },
                                                    })}
                                                />
                                            </div>
                                            <div className="col-md-3 mb-3">
                                                <p className="mb-1">Item Group</p>
                                                <Select
                                                    defaultValue={groupName}
                                                    onChange={setGroupName}
                                                    options={itemGroupNameOptions.map((option) => ({
                                                        label: option?.gm_group_name,
                                                        value: option?.gm_group_code,
                                                    }))}
                                                    showContent="true"
                                                    open="true"
                                                    className="my-react-select-container"
                                                    theme={theme => ({
                                                        ...theme,
                                                        borderRadius: "6px",
                                                        colors: {
                                                            ...theme.colors,
                                                            primary25: '#b3b3b3',
                                                            primary: 'black',
                                                            neutral80: "data-theme-version" === "dark" ? 'white' : ''
                                                        },
                                                    })}
                                                />
                                            </div>
                                            <div className="col-md-3 mb-3">
                                                <p className="mb-1">Item Category</p>
                                                <Select
                                                    defaultValue={categoryName}
                                                    onChange={setCategoryName}
                                                    options={categoryNameOptions.map((option) => ({
                                                        label: option?.cm_category_name,
                                                        value: option?.cm_category_code,
                                                    }))}
                                                    showContent="true"
                                                    open="true"
                                                    className="my-react-select-container"
                                                    theme={theme => ({
                                                        ...theme,
                                                        borderRadius: "6px",
                                                        colors: {
                                                            ...theme.colors,
                                                            primary25: '#b3b3b3',
                                                            primary: 'black',
                                                            neutral80: "data-theme-version" === "dark" ? 'white' : ''
                                                        },
                                                    })}
                                                />
                                            </div>

                                            <div className="col-md-3 mb-3">
                                                <p className="mb-1">Brand</p>

                                                <Select
                                                    defaultValue={brandName}
                                                    onChange={setBrandName}
                                                    options={brandNameOptions.map((option) => ({
                                                        label: option?.bm_brand_name,
                                                        value: option?.bm_brand_code,
                                                    }))}
                                                    showContent="true"
                                                    open="true"
                                                    className="my-react-select-container"
                                                    theme={theme => ({
                                                        ...theme,
                                                        borderRadius: "6px",
                                                        colors: {
                                                            ...theme.colors,
                                                            primary25: '#b3b3b3',
                                                            primary: 'black',
                                                            neutral80: "data-theme-version" === "dark" ? 'white' : ''
                                                        },
                                                    })}
                                                />
                                            </div>

                                            <div className="col-md-3 mb-3">
                                                <p className="mb-1">Item Name</p>

                                                <Select
                                                    defaultValue={itemName}
                                                    onChange={setItemName}
                                                    options={itemNameOptions.map((option) => ({
                                                        label: option?.im_display_name,
                                                        value: option?.im_item_code,
                                                    }))}
                                                    showContent="true"
                                                    open="true"
                                                    className="my-react-select-container"
                                                    theme={theme => ({
                                                        ...theme,
                                                        borderRadius: "6px",
                                                        colors: {
                                                            ...theme.colors,
                                                            primary25: '#b3b3b3',
                                                            primary: 'black',
                                                            neutral80: "data-theme-version" === "dark" ? 'white' : ''
                                                        },
                                                    })}
                                                />
                                            </div>
                                            <div className="col-md-2 mt-3">
                                                <p className="text-center mb-1">Negative Stock </p>
                                                <p className='text-center'>
                                                    <input
                                                        type="checkbox"
                                                        className="custom-control-input p-2"
                                                        value={negativeStock}
                                                        onChange={(e) => udfnNegativeStockReport(e.target.value)}
                                                        checked={negativeStock === "true" || negativeStock === true}
                                                    />
                                                </p>
                                            </div>
                                            <div className="col-md-3 mt-3">
                                                <p className="text-center mb-1">Purchase Rate And Amount</p>
                                                <p className='text-center'>
                                                    <input
                                                        type="checkbox"
                                                        className="custom-control-input p-2"
                                                        value={rateAndAmountShow}
                                                        onChange={(e) => udfnRateAndAmountHandle(e.target.value)}
                                                        checked={rateAndAmountShow === "true" || rateAndAmountShow === true}
                                                    />
                                                </p>
                                            </div>
                                            <div className="col-md-2 mt-3">
                                                <Button className="me-2" variant="whatsapp" style={{ marginTop: "6%" }} onClick={stockReport} >
                                                    List{" "}
                                                    <span className="btn-icon-right">
                                                        <i className="fa fa-filter" />
                                                    </span>
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </Accordion.Collapse>
                            </Accordion.Item>
                        </Accordion>
                        <div className="table-responsive">
                            {/* have to add when needed */}
                            {/* <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} /> */}
                            <div className='row'>
                                <div className='col-sm-9'>
                                    {/* <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} /> */}
                                </div>
                            </div>
                            <table {...getTableProps()} className="table filtering-table table-responsive-lg">
                                <thead>
                                    {headerGroups.map(headerGroup => (
                                        <tr {...headerGroup.getHeaderGroupProps()}>
                                            <th>
                                                <p className='tbl-heading-txt'>S.NO.</p>
                                            </th>
                                            {headerGroup.headers.map((column, index) => (
                                                <>
                                                    {
                                                        column.Header === 'Current Stock' ?
                                                            <th className='text-end' key={index}>
                                                                {column.render('Header')}
                                                                {column.canFilter ? column.render('Filter') : null}
                                                            </th>
                                                            :
                                                            (column.Header === 'Purchase Rate' && (rateAndAmountShow == false || rateAndAmountShow == 'false')) ||
                                                                (column.Header == 'Total Amount' && (rateAndAmountShow === false || rateAndAmountShow == 'false')) ||
                                                                (column.Header === 'Qr Code' && itemFilter?.value === 1) ||
                                                                (column.Header === 'Qr Code' && itemFilter?.value === 3) || (column.Header === 'Supplier Name' && itemFilter?.value === 1) || (column.Header === 'Supplier Name' && itemFilter?.value === 2) ? ''
                                                                :
                                                                <th key={index}>
                                                                    {column.render('Header')}
                                                                    {column.canFilter ? column.render('Filter') : null}
                                                                </th>
                                                    }

                                                </>
                                            ))}
                                        </tr>
                                    ))}
                                </thead>
                                <tbody {...getTableBodyProps()} className="" >
                                    {page.map((row, index) => {
                                        return (
                                            <tr key={index}>
                                                <td>{index + 1}</td>
                                                {itemFilter?.value === 3 ? <td>{row.original.sm_supplier_name}</td> : ''}
                                                <td>{row.original.group_name}</td>
                                                <td>{row.original.category_name}</td>
                                                <td>{row.original.brand_name}</td>
                                                <td>{row.original.item_name}</td>
                                                {itemFilter?.value === 2 ? <td>{row.original.qr_code}</td> : ''}
                                                <td className='text-end' style={{ paddingRight: '17px' }}>{row.original.current_stock}</td>
                                                {rateAndAmountShow === true || rateAndAmountShow === 'true' ? <td className='text-end' style={{ paddingRight: '17px' }}><i className="fa fa-rupee udfn-rupee-icon rupeeicon"></i> {COMMONVARIABLES.India_Rupee_Formatter_Digits(Formatting('two_decimal', row.original.qs_rate))}</td> : ''}
                                                {rateAndAmountShow === true || rateAndAmountShow === 'true' ? <td className='text-end' style={{ paddingRight: '17px' }}><i className="fa fa-rupee udfn-rupee-icon rupeeicon"></i> {COMMONVARIABLES.India_Rupee_Formatter_Digits(Formatting('two_decimal', row.original.total_amount))}</td> : ''}
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                            <div className='text-center'>
                                {data.length < 1 ? "No records found." : ""}
                            </div>
                            <div className="d-flex justify-content-between">
                                <span>
                                    Page{' '}
                                    <strong>
                                        {pageIndex + 1} of {pageOptions.length}
                                    </strong>{''}
                                </span>
                                <span className="table-index">
                                    Go to page : {' '}
                                    <input type="number"
                                        className="ms-2"
                                        defaultValue={pageIndex + 1}
                                        onChange={e => {
                                            const pageNumber = e.target.value ? Number(e.target.value) - 1 : 0
                                            gotoPage(pageNumber)
                                        }}
                                    />
                                </span>
                            </div>
                            <div className="text-center">
                                <div className="filter-pagination  mt-3">
                                    <button className=" previous-button" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>{'<<'}</button>

                                    <button className="previous-button" onClick={() => previousPage()} disabled={!canPreviousPage}>
                                        Previous
                                    </button>
                                    <button className="next-button" onClick={() => nextPage()} disabled={!canNextPage}>
                                        Next
                                    </button>
                                    <button className=" next-button" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>{'>>'}</button>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </LoadingOverlay >
        </>
    )

}
export default StockReport;
