import { ColumnFilter } from '../Common/ColumnFilter';
export const COLUMNS = [
    {
        Header: 'Month',
        Footer: 'Month',
        accessor: 'billing_month',
        Filter: ColumnFilter,
        // disableFilters: true,
    },
    {
        Header: 'Bill Total Amount',
        Footer: 'Bill Total Amount',
        accessor: 'ps_total_amount',
        Filter: ColumnFilter,
        // disableFilters: true,
    },
    {
        Header: 'Item Total Amount',
        Footer: 'Item Total Amount',
        accessor: 'item_amount',
        Filter: ColumnFilter,
        // disableFilters: true,
    },
    {
        Header: 'Profit',
        Footer: 'Profit',
        accessor: 'profit',
        Filter: ColumnFilter,
        // disableFilters: true,
    },
    // {
    //     Header: 'Profit (%)',
    //     Footer: 'Profit (%)',
    //     accessor: '',
    //     Filter: ColumnFilter,
    //     // disableFilters: true,
    // },
]

export const GROUPED_COLUMNS = [
]
