import React, { useEffect, useState } from 'react';
import { Link, useLocation } from "react-router-dom";
import { Badge, Button, Col, Row } from 'react-bootstrap';
import '../../Filters/Common/filtering.css';
import Select from "react-select";
import "react-datepicker/dist/react-datepicker.css";
import Formatting from '../../Utilities/format';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { getLoggedInUser, getRefreshToken } from '../../../../services/AuthService';
import { Logout } from '../../../../store/actions/AuthActions';
import COMMONVARIABLES from '../../Utilities/constants';
import PURCHASE_API from '../../../Api/PurchaseApi';
import COMMON_API from '../../../Api/CommonApi';
import LoadingOverlay from 'react-loading-overlay-ts';
import { navtoggle } from "../../../../store/actions/AuthActions";
import { useDispatch } from 'react-redux';
import ERROR_MESSAGE from '../../Validation/ErrorMessage';

export const ViewPurchase = () => {
	const BASE_URL = process.env.REACT_APP_BASE_URL;
	const paramData = useLocation();
	const [voucherNumber, setVoucherNumber] = useState("")
	const [voucherDate, setVoucherDate] = useState("")
	const [invNumber, setInvNumber] = useState("")
	const [invDate, setInvDate] = useState("")
	const [supplierName, setSupplierName] = useState({})

	const [supplierAddress, setSupplierAddress] = useState("")
	const [supplierCity, setSupplierCity] = useState("")
	const [supplierGstin, setSupplierGstin] = useState("")

	const [supplierNameOptions, setSupplierNameOptions] = useState([])
	const [itemNameOptions, setItemNameOptions] = useState([])

	const [currentItem, setCurrentItem] = useState(null)
	const [currentRate, setCurrentRate] = useState(0)
	const [currentQty, setCurrentQty] = useState(0)
	const [currentDiscPer, setCurrentDiscPer] = useState(0)
	const [currentDiscRate, setCurrentDiscRate] = useState(0)
	const [currentMarginPer, setCurrentMarginPer] = useState(0)
	const [currentSellingRate, setCurrentSellingRate] = useState(null)
	const [currentLabelDetails, setCurrentLabelDetails] = useState(null)
	const [currentSno, setCurrentSno] = useState(0)

	const [subTotal, setSubTotal] = useState(0)
	const [cgstAmt, setCgstAmt] = useState(0)
	const [sgstAmt, setSgstAmt] = useState(0)
	const [igstAmt, setIgstAmt] = useState(0)
	const [roundedOff, setRoundedOff] = useState(0)
	const [taxType, setTaxType] = useState(0)
	const [grandTotal, setGrandTotal] = useState(0)
	const [freightAmt, setFreightAmt] = useState(0)
	const [purchaseDisc, setPurchaseDisc] = useState(0)
	const [othersAmt, setOthersAmt] = useState(0)

	const [transportDetails, setTransportDetails] = useState("")
	const [narration, setNarration] = useState("")

	const [transactionNo, setTransactionNo] = useState(0)
	const [isLoading, setIsLoading] = useState(false);

	const [invFile, setInvFile] = useState("");
	const dispatch = useDispatch();

	const downloadFile = async (e) => {

		// const url = `https://acquirebase.com/img/logo.png`
		// 	window.open(url, '_blank');
		try {
			const response = await axios.get(
				BASE_URL + PURCHASE_API.DOWNLOAD_INVOICE + "?fileName=" + invFile

				, {
					responseType: 'blob' // Set response type to blob
				});

			// Create a temporary URL for the downloaded file
			const url = window.URL.createObjectURL(new Blob([response.data]));
			// Create a temporary link element
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', invFile); // Set the filename for the downloaded file
			// Simulate a click on the link to start the download
			document.body.appendChild(link);
			link.click();
			// Clean up
			link.parentNode.removeChild(link);
			//console.log(res);

			// const blob = new Blob([res], { type: 'text/plain' });
			// const url = window.URL.createObjectURL(blob);
			// window.open(url, '_blank');
		} catch (ex) {
			console.log(ex);
		}
	};

	const [purchaseItemDetails, setPurchaseItemDetails] = useState([]);

	useEffect(() => {

		var sub_total = 0;
		var total_tax = 0;

		for (var i = 0; i < purchaseItemDetails.length; i++) {
			sub_total = (purchaseItemDetails[i]['discrate'] * purchaseItemDetails[i]['itemqty']) + sub_total
			total_tax = purchaseItemDetails[i]['taxamt'] + total_tax
		}
		setSubTotal(Formatting('two_decimal', sub_total))

		if (taxType === 1) {
			setCgstAmt(Formatting('two_decimal', (total_tax / 2))) // 2 decimal format
			setSgstAmt(Formatting('two_decimal', (total_tax / 2)))
			setIgstAmt(0)
		}
		else {
			setIgstAmt(Formatting('two_decimal', total_tax))
			setCgstAmt(0) // 2 decimal format
			setSgstAmt(0)
		}



		var grand_total = (Formatting('two_decimal', sub_total) + Formatting('two_decimal', othersAmt) + Formatting('two_decimal', freightAmt) + Formatting('two_decimal', total_tax)) - Formatting('two_decimal', purchaseDisc)
		setGrandTotal(Formatting('round_off', grand_total))

		var rounded_off = Formatting('round_off', grand_total) - Formatting('two_decimal', grand_total)

		setRoundedOff(Formatting('two_decimal', rounded_off))

	}, [purchaseItemDetails.length, othersAmt, freightAmt, purchaseDisc, supplierName, taxType]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		var item_detail_json = itemNameOptions.find(e => e.im_item_code === currentItem?.value)

		if (currentQty > 0) {
			const formattedValue = parseFloat(currentQty).toFixed(item_detail_json?.um_no_of_decimals);
			setCurrentQty(formattedValue)
		}

		if (currentLabelDetails === "")
			udfnBindLabelDetails()  //  load the previous label details	




	}, [currentItem]) // eslint-disable-line react-hooks/exhaustive-deps

	async function udfnBindLabelDetails() {
		try {
			const user = getLoggedInUser()

			const labelParams = { item_code: currentItem?.value, user_id: user?.id };

			const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } };
			const [labelData] = await Promise.all([
				axios.post(BASE_URL + PURCHASE_API.GetPurchaseLabelDetails, labelParams, config),

			]);

			if (labelData?.data?.body?.status === COMMONVARIABLES.STATUS.UNAUTHORIZED) {


				const refresh = await getRefreshToken();

				if (refresh) {
					if (Number(refresh) === COMMONVARIABLES.STATUS.EXPIRED_TOKEN) {
						Logout();
					} else {
						await editLoadData();
					}
				}
			}

			else if (labelData?.data?.body?.LabelDetails) {
				setCurrentLabelDetails(labelData?.data?.body?.LabelDetails)
			}

		} catch (error) {
			console.error('Error fetching master data:', error);
			if (error.code === COMMONVARIABLES.STATUS.NETWORK_ERROR_STATUS) {
				toast.error(ERROR_MESSAGE.API_ERROR.NETWORK_ERROR, { position: toast.POSITION.TOP_RIGHT });
			}
			if (error.code === COMMONVARIABLES.STATUS.BAD_REQUEST_ERROR_STATUS) {
				toast.error(ERROR_MESSAGE.API_ERROR.BAD_REQUEST_ERROR, { position: toast.POSITION.TOP_RIGHT });
			}
		}

	}

	Date.prototype.toDateString = function () {
		var month = this.getMonth() + 1;
		var day = this.getDate();
		var year = this.getFullYear();
		return year + "-" + (month < 10 ? '0' : '') + month + '-' + (day < 10 ? '0' : '') + day;
	};

	useEffect(() => {
		dispatch(navtoggle());
		fetchMasterDataWithParams()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	async function fetchMasterDataWithParams() {
		try {

			var localFy = localStorage.getItem("fy")


			const user = getLoggedInUser()

			const supplierParams = { status_code: 1, user_id: user?.id };
			const itemParams = { status_code: 1, user_id: user?.id };
			const branchParams = { status_code: 1, user_id: user?.id };
			const brandParams = { status_code: 1, user_id: user?.id };
			const invNoParams = { fy: localFy ? Number(localFy) : 0, user_id: user?.id };




			const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } };
			const [supplierData, itemData, branchData, brandData, invData] = await Promise.all([
				axios.post(BASE_URL + COMMON_API.SUPPLIER_MASTER_LIST, supplierParams, config),
				axios.post(BASE_URL + COMMON_API.ITEM_MASTER_LIST, itemParams, config),
				axios.post(BASE_URL + COMMON_API.BRANCH_MASTER_LIST, branchParams, config),
				axios.post(BASE_URL + COMMON_API.BRAND_MASTER_LIST, brandParams, config),
				axios.post(BASE_URL + PURCHASE_API.PURCHASE_INVNO, invNoParams, config),

			]);

			if (supplierData?.data?.body?.status === COMMONVARIABLES.STATUS.UNAUTHORIZED || itemData?.data?.body?.status === COMMONVARIABLES.STATUS.UNAUTHORIZED || branchData?.data?.body?.status === COMMONVARIABLES.STATUS.UNAUTHORIZED || brandData?.data?.body?.status === COMMONVARIABLES.STATUS.UNAUTHORIZED || invData?.data?.body?.status === COMMONVARIABLES.STATUS.UNAUTHORIZED) {


				const refresh = await getRefreshToken();

				if (refresh) {
					if (Number(refresh) === COMMONVARIABLES.STATUS.EXPIRED_TOKEN) {
						Logout();
					} else {
						await fetchMasterDataWithParams();
					}
				}
			}

			if (supplierData?.data?.body?.status === COMMONVARIABLES.STATUS.SERVICE_CATCH_STATUS) {
				toast.error(ERROR_MESSAGE.API_ERROR.SERVICE_CATCH_ERROR, { position: toast.POSITION.TOP_RIGHT });
			}

			if (supplierData?.data?.body?.supplierList || itemData?.data?.body?.itemList || branchData?.data?.body?.branchList || brandData?.data?.body?.brandList || invData?.data?.body?.voucher_no) {
				setSupplierNameOptions(supplierData?.data?.body?.supplierList)
				setItemNameOptions(itemData?.data?.body?.itemList)
				setVoucherNumber(invData?.data?.body?.voucher_no)

				var currentDate = new Date(invData?.data?.body?.vou_date);
				setVoucherDate(currentDate.toDateString())
				setInvDate(currentDate.toDateString())

			}

		} catch (error) {
			console.error('Error fetching master data:', error);
			if (error.code === COMMONVARIABLES.STATUS.NETWORK_ERROR_STATUS) {
				toast.error(ERROR_MESSAGE.API_ERROR.NETWORK_ERROR, { position: toast.POSITION.TOP_RIGHT });
			}
			if (error.code === COMMONVARIABLES.STATUS.BAD_REQUEST_ERROR_STATUS) {
				toast.error(ERROR_MESSAGE.API_ERROR.BAD_REQUEST_ERROR, { position: toast.POSITION.TOP_RIGHT });
			}
		}
	}
	async function editLoadData() {
		try {


			const user = getLoggedInUser()

			const editloadParams = { transaction_no: paramData?.state?.transaction_no, user_id: user?.id };

			const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } };
			const [editloadValues] = await Promise.all([
				axios.post(BASE_URL + PURCHASE_API.PURCHASE_EDIT_LOAD, editloadParams, config),

			]);

			if (editloadValues?.data?.body?.status === COMMONVARIABLES.STATUS.UNAUTHORIZED) {


				const refresh = await getRefreshToken();

				if (refresh) {
					if (Number(refresh) === COMMONVARIABLES.STATUS.EXPIRED_TOKEN) {
						Logout();
					} else {
						await editLoadData();
					}
				}
			}

			if (editloadValues?.data?.body?.status === COMMONVARIABLES.STATUS.SERVICE_CATCH_STATUS) {
				toast.error(ERROR_MESSAGE.API_ERROR.SERVICE_CATCH_ERROR, { position: toast.POSITION.TOP_RIGHT });
			}

			if (editloadValues?.data?.body?.purchaseList) {
				setInvFile(editloadValues?.data?.body?.purchaseList[0]?.pr_file_name)

				var purchase_item_list = editloadValues?.data?.body?.purchaseItemList
				var inc = 0
				purchase_item_list.forEach(element => {
					inc++
					return element['sno'] = inc
				});
				// qid = response.
				setPurchaseItemDetails(purchase_item_list)
				var purchase = editloadValues?.data?.body?.purchaseList[0]
				bindEditLoadData(purchase)

			}

		} catch (error) {
			console.error('Error fetching master data:', error);
			if (error.code === COMMONVARIABLES.STATUS.NETWORK_ERROR_STATUS) {
				toast.error(ERROR_MESSAGE.API_ERROR.NETWORK_ERROR, { position: toast.POSITION.TOP_RIGHT });
			}
			if (error.code === COMMONVARIABLES.STATUS.BAD_REQUEST_ERROR_STATUS) {
				toast.error(ERROR_MESSAGE.API_ERROR.BAD_REQUEST_ERROR, { position: toast.POSITION.TOP_RIGHT });
			}
		}
	}

	function bindEditLoadData(purchase) {



		setVoucherNumber(purchase?.pr_voucher_number)
		setVoucherDate(new Date(purchase?.pr_voucher_date).toDateString())
		setInvNumber(purchase?.pr_inv_number)
		setInvDate(new Date(purchase?.pr_inv_date).toDateString())
		setSubTotal(purchase?.pr_sub_total)
		setFreightAmt(purchase?.pr_freight_amount)
		setOthersAmt(purchase?.pr_others)
		setPurchaseDisc(purchase?.pr_disc_amount)
		setGrandTotal(purchase?.pr_grand_total)
		setTransportDetails(purchase?.pr_transport_details)
		setNarration(purchase?.pr_narration)
		setRoundedOff(purchase?.pr_rounded_off)

		var supplierJson = supplierNameOptions.find(e => e?.sm_supplier_code === purchase?.pr_supplier_code)
		setSupplierName({ value: purchase?.pr_supplier_code, label: supplierJson?.sm_supplier_name })

	}

	useEffect(() => {

		if (paramData?.state?.transaction_no > 0 && supplierNameOptions.length > 0) {
			editLoadData()
			setTransactionNo(paramData?.state?.transaction_no)
		}
	}, [supplierNameOptions]) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {

		var supplierJson = supplierNameOptions.find(e => e?.sm_supplier_code === supplierName?.value)
		setSupplierAddress(supplierJson?.sm_address)
		setSupplierCity(supplierJson?.sm_city)
		setSupplierGstin(supplierJson?.sm_gstin)
		const gstin = supplierJson?.sm_gstin && supplierJson?.sm_gstin?.length >= 2 ? supplierJson?.sm_gstin?.substring(0, 2) : '';
		if (gstin === "33")
			setTaxType(1)
		else
			setTaxType(2)

		getPreviousPurchaseDetails()
	}, [supplierName]) // eslint-disable-line react-hooks/exhaustive-deps

	async function getPreviousPurchaseDetails() {
		const user = getLoggedInUser()
		const prevPurchaseParams = { supplier_code: supplierName?.value, user_id: user?.id, trans_no: transactionNo };
		const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } };
		const [prevPurchaseData] = await Promise.all([
			axios.post(BASE_URL + PURCHASE_API.PREVIOUS_PURCHASE, prevPurchaseParams, config)
		]);
		if (prevPurchaseData?.data?.body?.status === COMMONVARIABLES.STATUS.UNAUTHORIZED) {
			getPreviousPurchaseDetails()

			const refresh = await getRefreshToken();

			if (refresh) {
				if (Number(refresh) === COMMONVARIABLES.STATUS.EXPIRED_TOKEN) {
					Logout();
				} else {
					await getPreviousPurchaseDetails();
				}
			}
		}
		if (prevPurchaseData?.data?.body?.status === COMMONVARIABLES.STATUS.SERVICE_CATCH_STATUS) {
			toast.error(ERROR_MESSAGE.API_ERROR.SERVICE_CATCH_ERROR, { position: toast.POSITION.TOP_RIGHT });
		}
		if (prevPurchaseData?.data?.body?.prevPurchaseList) {
		}
	}

	useEffect(() => {

		if (currentDiscPer < 0 || currentDiscPer > 100) {
			toast.error("Discount Percentage is invalid", {
				position: "top-right",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
			});
		}

		var disc_amount = Number(currentRate) * (Number(currentDiscPer) / 100)

		var disc_rate = Number(currentRate) - disc_amount
		setCurrentDiscRate(disc_rate)

		sellDiscRateCalculation(currentSellingRate, disc_rate)

		// var actual_amount = currentQty * currentRate
		// var disc_value = currentDiscPer / 100;
		// var disc_amount = actual_amount * disc_value;
		// setCurrentAmount(actual_amount)
		// setCurrentDiscAmt(actual_amount * disc_value)
		// setCurrentDiscRate(actual_amount - disc_amount)

		// var selling_rate = (currentDiscRate * (currentMarginPer / 100)) + currentDiscRate
		// setCurrentSellingRate(selling_rate)

	}, [currentRate, currentQty, currentDiscPer, currentMarginPer, currentDiscRate]) // eslint-disable-line react-hooks/exhaustive-deps

	const sellDiscRateCalculation = (selleingrate, discRate) => {
		var margin_perc = ((Number(selleingrate) - Number(discRate)) / Number(discRate)) * 100

		setCurrentMarginPer(margin_perc)
	}

	// useEffect(() => {
	// 	var margin_perc = ((Number(currentSellingRate) - Number(currentDiscRate)) / Number(currentDiscRate)) * 100

	// 	setCurrentMarginPer(margin_perc)

	// }, [currentSellingRate,currentDiscRate])

	// useEffect(() => {

	// 	var selling_rate =  Number(currentRate) + (currentRate * (currentMarginPer / 100)) 
	// 	setCurrentSellingRate(selling_rate)

	// }, [currentMarginPer])

	return (
		<>
			<ToastContainer />
			<LoadingOverlay
				active={isLoading}
				spinner
				text='Loading ...'
			>
				{/* <div
					onClick={handleKeyDown}
					onClickCapture={handleKeyDown}
					onMouseDown={handleMouseDown}
					onKeyDown={handleKeyDown}
					contentEditable={false} // Disable editing of content inside the div
					tabIndex={-1} // Disable focusing via tab key
		  > */}
				<h4 className="card-title">Purchase Details</h4>
				<div className="basic-form overlay">
					<form>
						<div className="card">
							<div className="card-body">
								<Row>
									<Col lg={3}>
										<div className="row">
											<div className="form-group text-black font-w500 mb-2 mt-2 col-lg-4 align-middle text-end">
												<label className="col-form-label text-black font-w500">Vou No. <span className="text-danger">*</span></label>
											</div>
											<div className="form-group text-black font-w300 mb-2 ml-2 col-lg-8">
												<input
													type="text"
													className="form-control"
													placeholder="Vou No."
													readOnly={true}

													value={voucherNumber}
												/>
											</div>
											<div className="form-group text-black font-w500 mb-2 mt-2 col-lg-4 align-middle text-end">
												<label className="col-form-label text-black font-w500">Vou Date <span className="text-danger">*</span></label>
											</div>
											<div className="form-group text-black font-w500 mb-2 col-lg-8">
												<input value={voucherDate} onChange={e => setVoucherDate(e.target.value)} type="date" className="form-control" />
											</div>
										</div>
									</Col>
									<Col lg={6}>
										<div className="row">
											<div className="form-group text-black font-w500 mb-2 col-lg-3 text-end">
												<label className="col-form-label text-black font-w500">Supplier Name <span className="text-danger">*</span></label>
											</div>
											<div className="form-group text-black font-w300 mb-2 col-lg-9">
												<Select
													defaultValue={supplierName}
													value={supplierName}
													isDisabled={true}
													onChange={setSupplierName}
													options={supplierNameOptions.map((option) => ({
														label: option.sm_supplier_name,
														value: option.sm_supplier_code,
													}))}
													showContent="true"
													open="true"
													className="my-react-select-container"
													theme={theme => ({
														...theme,
														borderRadius: '6px',
														colors: {
															...theme.colors,
															primary25: '#b3b3b3',
															primary: 'black',
															neutral80: "data-theme-version" === "dark" ? 'white' : ''
														},
													})}
												/>



											</div>
											<div className="form-group text-black font-w500 mb-2 mt-2 col-lg-3 ml-4 pl-4 text-end">
												<label className="col-form-label text-black font-w500">Inv No. <span className="text-danger">*</span></label>
											</div>
											<div className="form-group text-black font-w300 mb-2 col-lg-3">
												<input
													type="text"
													readOnly={true}
													className="form-control"
													placeholder="Inv No."
													value={invNumber} onChange={e => setInvNumber(e.target.value)}
												/>
											</div>
											<div className="form-group text-black font-w500 mb-2 mt-2 col-lg-2" align='right'>
												<label className="col-form-label text-black font-w500">Inv Date <span className="text-danger">*</span></label>
											</div>
											<div className="form-group text-black font-w500 mb-2 col-lg-4">
												<input type="date" className="form-control" value={invDate} onChange={e => setInvDate(e.target.value)} />
											</div>
										</div>
									</Col>
									{supplierName?.value ?
										<Col lg={3}>
											<div className="row">
												<div className="card-content border border-info pt-2">
													<address className='text-black'>
														<strong>Supplier Details</strong>
														<br></br>
														{supplierAddress}
														<br />

														{supplierCity}
														<br />
														<abbr title="Phone">Gstin :{supplierGstin} </abbr>
													</address>

												</div>
											</div>
										</Col> : null}
								</Row>
							</div>
						</div>

						<div className="card">

							<div className="card-body px-4 pt-3 py-0">

								<Row>

									<Col xl={12}>
										<div className="row">
											<div className="table-responsive">


												<table className="table table-striped">
													<thead>
														<tr className='table-purchase-tr '>
															<th width='2%'>#</th>

															<th width='8%'>Q/M</th>
															<th width='17%'>Item Name</th>
															<th className='text-end' width='5%'>Rate</th>
															<th className='text-end' width='5%'>Qty</th>
															<th className='text-end' width='5%'>Disc %</th>
															<th className='text-end' width='5%'>Disc Amt</th>
															<th className='text-end' width='5%'>Disc Rate</th>
															<th className='text-end' width='5%'>Tax %</th>
															<th className='text-end' width='5%'>Tax Amt</th>
															<th className='text-end' width='5%'>Amount</th>
															<th className='text-end' width='5%'>Margin %</th>
															<th className='text-end' width='5%'>Selling Rate</th>

															<th>Label Details</th>

														</tr>
													</thead>
													<tbody>

														{purchaseItemDetails.map((purchaseItem, index) => (

															<tr style={{ backgroundColor: currentSno === purchaseItem.sno ? '#2df1ff6e' : '' }}>
																<td> {purchaseItem?.sno}</td>

																<td>
																	<Badge bg="" className='badge-info light'>{purchaseItem?.qrmantype}</Badge>
																	<span className='table-purchase-span' style={{ width: '150px' }}>{purchaseItem?.qrcode}</span>
																</td>
																<td className='table-purchase-td'>
																	<label className="text-black font-w500 table-purchase-label" style={{ width: '200px' }}>{purchaseItem?.itemname}
																		{purchaseItem.brandcode !== 0 ? <span className='fst-italic table-purchase-span'>({purchaseItem?.brandname})</span> : null}
																	</label><br />
																	<span className='table-purchase-span'>({purchaseItem.branchname})</span>
																</td>
																<td align='right'>{purchaseItem?.itemrate}</td>
																<td align='right'>{purchaseItem?.itemqty}</td>
																<td align='right'>{purchaseItem?.discperc}</td>
																<td align='right'>{purchaseItem?.discamt}</td>
																<td align='right'>{purchaseItem?.discrate}</td>
																<td align='right'>{purchaseItem?.taxperc}</td>
																<td align='right'>{purchaseItem?.taxamt}</td>
																<td align='right'>{purchaseItem?.itemamt}</td>
																<td align='right'>{purchaseItem?.marginperc}</td>
																<td align='right'>{purchaseItem?.sellrate}</td>
																{/* <td align='right'>10</td> */}


																<td>{purchaseItem?.labeldetails}</td>

															</tr>
														))}

													</tbody>
												</table>
											</div>
										</div>
									</Col>

								</Row>
							</div>
						</div>

						<Row>
							<Col xl={4}>

							</Col>
							<Col xl={4}>
								<div className="card">
									<div className="card-body">
										<div className="row">
											<div className="form-group mb-5 col-lg-12">
												<label className="text-black font-w300">Transport Details</label>
												<textarea readOnly={true} value={transportDetails} onChange={(e) => setTransportDetails(e.target.value)} className="form-control text-area-udf"></textarea>
											</div>
											<div className="form-group mb-2 col-lg-12">
												<label className="text-black font-w300">Narration</label>
												<textarea readOnly={true} value={narration} onChange={(e) => setNarration(e.target.value)} className="form-control text-area-udf"></textarea>
											</div>

											{invFile !== "" ?
												<div className='row'>
													<div onClick={downloadFile} className="form-group mt-4 col-lg-12 cursor-pointer"><Badge bg="" className='badge-success light'>
														<span className="me-2">
															<i className="ms-1 fa fa-download" />
														</span>
														{invFile}
													</Badge></div>


												</div> : null}
										</div>
									</div>
								</div>
							</Col>
							<Col xl={4}>
								<div className="card">
									<div className="card-body">
										<div className="row">
											<ul className="list-group p-0">
												<li className="list-group-item d-flex justify-content-between lh-condensed p-2">
													<div>
														<span className="my-0 text-black font-w500">Sub Total</span>
													</div>
													<span className="text-muted">{'₹'}{subTotal.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
												</li>
												<li className="list-group-item d-flex justify-content-between lh-condensed p-2">
													<div>
														<span className="my-0 text-black font-w500">CGST</span>
													</div>
													<span className="text-muted">{'₹'}{cgstAmt.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
												</li>
												<li className="list-group-item d-flex justify-content-between lh-condensed p-2">
													<div>
														<span className="my-0 text-black font-w500">SGST</span>
													</div>
													<span className="text-muted">{'₹'}{sgstAmt.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
												</li>
												<li className="list-group-item d-flex justify-content-between lh-condensed p-2">
													<div>
														<span className="my-0 text-black font-w500">IGST</span>
													</div>
													<span className="text-muted">{'₹'}{igstAmt.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
												</li>

												<li className="list-group-item d-flex justify-content-between lh-condensed p-2">
													<div>
														<span className="my-0 text-black font-w500">Frieght Amt</span>
													</div>
													<div className="col-5">
														<span className="text-muted" align='right'>
															<input
																type="text"
																className="form-control text-end"
																placeholder="Frieght Amt"
																value={freightAmt}
																onChange={(e) => setFreightAmt(e.target.value)}
																onKeyPress={(e) => {
																	if (e.key === '-' || e.key === 'e') {
																		e.preventDefault();
																	}
																}}
																min="0"
																readOnly={true}
																onPaste={(e) => {
																	e.preventDefault();
																	const pastedText = e.clipboardData.getData('text');
																	let pastedValue = parseFloat(pastedText);
																	if (isNaN(pastedValue) && pastedValue < 0) {
																		pastedValue = "";
																	}
																	e.target.value = pastedValue.toString().slice(0, 3);

																}}
															/>
														</span>
													</div>
												</li>
												<li className="list-group-item d-flex justify-content-between lh-condensed p-2">
													<div>
														<span className="my-0 text-black font-w500">Disc Amt(-)</span>
													</div>
													<div className="col-5">
														<span className="text-muted" align='right'>
															<input
																type="text"
																className="form-control text-end"
																placeholder="Disc Amt"
																value={purchaseDisc}
																readOnly={true}
																onChange={(e) => setPurchaseDisc(e.target.value)}
																onKeyPress={(e) => {
																	if (e.key === '-' || e.key === 'e') {
																		e.preventDefault();
																	}
																}}
																min="0"
																onPaste={(e) => {
																	e.preventDefault();
																	const pastedText = e.clipboardData.getData('text');
																	let pastedValue = parseFloat(pastedText);
																	if (isNaN(pastedValue) && pastedValue < 0) {
																		pastedValue = "";
																	}
																	e.target.value = pastedValue.toString().slice(0, 3);

																}}
															/>
														</span>
													</div>
												</li>
												<li className="list-group-item d-flex justify-content-between lh-condensed p-2">
													<div>
														<span className="my-0 text-black font-w500">Others</span>
													</div>
													<div className="col-5">
														<span className="text-muted" align='right'>
															<input
																type="text"
																className="form-control form-control-sm text-end"
																placeholder="Others"
																value={othersAmt}
																readOnly={true}
																onChange={(e) => setOthersAmt(e.target.value)}
																onKeyPress={(e) => {
																	if (e.key === 'e') {
																		e.preventDefault();
																	}
																}}
																min="0"
																onPaste={(e) => {
																	e.preventDefault();
																	const pastedText = e.clipboardData.getData('text');
																	let pastedValue = parseFloat(pastedText);
																	if (isNaN(pastedValue) && pastedValue < 0) {
																		pastedValue = "";
																	}
																	e.target.value = pastedValue.toString().slice(0, 3);

																}}
															/>
														</span>
													</div>
												</li>
												<li className="list-group-item d-flex justify-content-between lh-condensed p-2">
													<div>
														<span className="my-0 text-black font-w500">Rounded Off</span>
													</div>
													<span className="text-muted">{'₹'}{roundedOff.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
												</li>
												<li className="list-group-item d-flex justify-content-between active p-2">
													<div className="text-white">
														<h6 className="my-0 text-white">Grand Total</h6>
													</div>
													<span className="text-white">
														<h5>{'₹'}{grandTotal.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</h5>
													</span>
												</li>
											</ul>
										</div>
									</div>
								</div>
							</Col>
						</Row>
						<div align='right' class="mb-6">

							<Link to='/purchase-list'>
								<Button
									variant="danger"
									type="button"
									className="btn btn-success"
								>
									Back
								</Button>
							</Link>

						</div>

					</form >
				</div >
				{/* </div> */}
			</LoadingOverlay>
		</>
	)

}
export default ViewPurchase;