const STOCK_ADJUSTMENT_API = {
    // STOCK ADJUSTMENT
    SAVE_STOCK_ADJUSTMENT: "api/stockadjustment/saveStockAdjustment",
    STOCK_ADJUSTMENT_LIST: "api/stockadjustment/getStockAdjustmentList",
    STOCK_ADJUSTMENT_DETAILS: "api/stockadjustment/getStockAdjustmentDetails",
    DELETE_STOCK_ADJUSTMENT: "api/stockadjustment/deleteStockAdjustment",
    MULTISAVE_STOCK_ADJUSTMENT: "api/stockadjustment/saveStockAdjustmentArray",
    
};

export default STOCK_ADJUSTMENT_API;
