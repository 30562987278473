import React from "react";
import { useSelector } from "react-redux";

/// React router dom
import { Routes, Route, Outlet } from "react-router-dom";

/// Css
import "./index.css";
import "./chart.css";
import "./step.css";


/// Layout
import Nav from "./layouts/nav";
import Footer from "./layouts/Footer";
import ScrollToTop from './pages/ScrollToTop';

/// Dashboard
import Home from "./components/Dashboard/Home";
import Setting from "./layouts/Setting";
import CompanyMaster from "./components/Masters/CompanyMaster";
import BranchMaster from "./components/Masters/BranchMaster";
import ItemMaster from "./components/Masters/ItemMaster";
import CustomerMaster from "./components/Masters/CustomerMaster";
import SupplierMaster from "./components/Masters/SupplierMaster";
import PurchaseList from "./components/Transactions/Purchase/PurchaseList";
import PurchaseAdd from "./components/Transactions/Purchase/PurchaseAdd";
import UserMaster from "./components/Masters/UserMaster";
import UnitMaster from "./components/Masters/UnitMaster";
import ItemGroupMaster from "./components/Masters/ItemGroupMaster";
import ItemCategoryMaster from "./components/Masters/ItemCategoryMaster";
import BrandMaster from "./components/Masters/BrandMaster";
import VoucherTypeMaster from "./components/Masters/VoucherTypeMaster";
import SalesmanMaster from "./components/Masters/SalesmanMaster";
import StockTransferList from "./components/Transactions/StockTransfer/StockTransferList";
import StockTransferAdd from "./components/Transactions/StockTransfer/StockTransferAdd";
import StockAdjustmentList from "./components/Transactions/StockAdjustment/StockAdjustmentList";
import SalesmanPrint from "./components/Masters/SalesManPrint";
import ChangePriceList from "./components/Transactions/ChangePriceList";
import DiscountBulkUpdate from "./components/Masters/DiscountBulkUpdate";
import ViewPurchase from "./components/Transactions/Purchase/ViewPurchase";
import StockReports from "./components/Reports/StockReport";
import POSReport from "./components/Reports/POSReport";
import ItemwiseSalesReport from "./components/Reports/ItemwiseSalesReport";

import GSTRReport from "./components/Reports/GSTR";
import QrCodeRePrint from "./components/Transactions/QrCodeRePrint";
import ItemwiseAnalysisReport from "./components/Reports/ItemwiseAnalysisReport";
import BuybackReport from "./components/Reports/BuybackReport";
import { SalesmanReport } from "./components/Reports/SalesmanReport";
import QrCodeAnalysisReport from "./components/Reports/QrCodeAnalysisReport";
import AgingStockReport from "./components/Reports/AgingStockReport";
import ItemPriceDetails from "./components/Reports/ItemPriceDetails";
import ProfitReport from "./components/Reports/ProfitReport";
import StockAdjustmentAdd from "./components/Transactions/StockAdjustment/StockAdjustmentAdd";
import HSNBulkUpdate from "./components/Masters/HSNBulkUpdate";

const Markup = () => {

  const allroutes = [
    /// Dashboard
    { url: "", element: <Home /> },
    { url: "dashboard", element: <Home /> },
    { url: "userlist", element: <UserMaster /> },
    { url: "company", element: <CompanyMaster /> },
    { url: "Item", element: <ItemMaster /> },
    { url: "customer", element: <CustomerMaster /> },
    { url: "supplier", element: <SupplierMaster /> },
    { url: "purchase-list", element: <PurchaseList /> },
    { url: "purchase-add", element: <PurchaseAdd /> },
    { url: "Branch", element: <BranchMaster /> },
    { url: "unit", element: <UnitMaster /> },
    { url: "item-group", element: <ItemGroupMaster /> },
    { url: "item-category", element: <ItemCategoryMaster /> },
    { url: "brand", element: <BrandMaster /> },
    { url: "voucher-type", element: <VoucherTypeMaster /> },
    { url: "salesman", element: <SalesmanMaster /> },
    { url: "stock-transfer-list", element: <StockTransferList /> },
    { url: "stock-transfer-add", element: <StockTransferAdd /> },
    { url: "stock-adjustment-list", element: <StockAdjustmentList /> },
    { url: "salesman-print", element: <SalesmanPrint /> },
    { url: "change-price-list", element: <ChangePriceList /> },
    { url: "discount-bulk-update", element: <DiscountBulkUpdate /> },
    { url: "purchase-view", element: <ViewPurchase /> },
    { url: "stock-report", element: <StockReports /> },
    { url: "pos-report", element: <POSReport /> },
    { url: "itemwise-sales-report", element: <ItemwiseSalesReport /> },
    { url: "gstr-report", element: <GSTRReport /> },
    { url: "qrcode-reprint", element: <QrCodeRePrint /> },
    { url: "itemwise-analysis-report", element: <ItemwiseAnalysisReport /> },
    { url: "buyback-report", element: <BuybackReport /> },
    { url: "salesman-report", element: <SalesmanReport /> },
    { url: "qrcode-analysis-report", element: <QrCodeAnalysisReport /> },
    { url: "aging-stock-report", element: <AgingStockReport /> },
    { url: "item-price-details", element: <ItemPriceDetails /> },
    { url: "profit-report", element: <ProfitReport/> },
    { url: "stock-adjustment-add", element: <StockAdjustmentAdd /> },
    { url: "hsn-bulk-update", element: <HSNBulkUpdate /> },
   
  ];
  // let path = window.location.pathname;
  // path = path.split("/");
  // path = path[path.length - 1];
  // let pagePath = path.split("-").includes("page");

  setTimeout(function () {
    let btn = document.querySelector("#main-wrapper");
    let metisMenu = document.querySelector(".metismenu");
    metisMenu.addEventListener("mouseenter", toggleFunc1);
    metisMenu.addEventListener("mouseleave", toggleFunc2);
    function toggleFunc1() {
      return btn.classList.add("iconhover-toggle");
    }
    function toggleFunc2() {
      return btn.classList.remove("iconhover-toggle");
    }
  }, 200);


  return (
    <>
      <Routes>
        <Route element={<MainLayout />} >
          {allroutes.map((data, i) => (
            <Route
              key={i}
              exact
              path={`${data.url}`}
              element={data.element}
            />
          ))}
        </Route>
      </Routes>
      <Setting />
      <ScrollToTop />
    </>
  );
};

function MainLayout() {
  // const { menuToggle } = useContext(ThemeContext);
  const sideMenu = useSelector(state => state.sideMenu);
  return (
    <div id="main-wrapper" className={`show ${sideMenu ? "menu-toggle" : ""}`}>
      <Nav />
      <div className="content-body" style={{ minHeight: window.screen.height - 60 }}>
        <div className="container-fluid">
          <Outlet />
        </div>
      </div>
      <Footer />
    </div>
  )

};

export default Markup;
