import { format } from 'date-fns';
import { ColumnFilter } from '../Common/ColumnFilter';
export const COLUMNS = [
	// {
	// 	Header: 'S.No.',
	// 	Footer: 'S.No.',
	// 	accessor: 'id',
	// 	Filter: ColumnFilter,
	// 	disableFilters: true,
	// },
	{
		Header: 'Voucher Date',
		Footer: 'Voucher Date',
		accessor: 'vou_date',
		Filter: ColumnFilter,
		// disableFilters: true,
	},
	{
		Header: 'Voucher Number',
		Footer: 'Voucher Number',
		accessor: 'pr_voucher_number',
		Filter: ColumnFilter,
		// disableFilters: true,
	},
	{
		Header: 'Invoice Date',
		Footer: 'Invoice Date',
		accessor: 'inv_date',
		Filter: ColumnFilter,
		// disableFilters: true,
	},

	{
		Header: 'Invoice Number',
		Footer: 'Invoice Number',
		accessor: 'pr_inv_number',
		Filter: ColumnFilter,
		// disableFilters: true,
	},

	{
		Header: 'Supplier Name',
		Footer: 'Supplier Name',
		accessor: 'sm_supplier_name',
		Filter: ColumnFilter,
		// disableFilters: true,
	},
	{
		Header: 'Total Amount',
		Footer: 'Total Amount',
		accessor: 'pr_grand_total',
		Filter: ColumnFilter,
		// disableFilters: true,
	}
]

export const GROUPED_COLUMNS = [
]