import React, { useState, useEffect } from 'react';
import { Badge, Button, Card, Modal } from 'react-bootstrap';
import '../Filters/Common/filtering.css';
import "bootstrap-daterangepicker/daterangepicker.css";
import { ToastContainer, toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { getLoggedInUser, getRefreshToken } from '../../../services/AuthService';
import COMMONVARIABLES from '../Utilities/constants';
import LoadingOverlay from 'react-loading-overlay-ts';
import ERROR_MESSAGE from '../Validation/ErrorMessage';
import Formatting from '../Utilities/format';
import MASTER_API from '../../Api/MasterApi';
import { UserLogout } from '../../layouts/nav/Logout';
import COMMON_API from '../../Api/CommonApi';
import Select from "react-select";

const ExcelJS = require('exceljs');

export const ItemPriceDetails = () => {
    const user = getLoggedInUser();
    const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } };
    const BASE_URL = process.env.REACT_APP_BASE_URL;

    const [isLoading, setIsLoading] = useState(false);
    const [itemName, setItemName] = useState('');
    const [itemOptions, setItemOptions] = useState([]);
    const [itemPriceDetails, setItemPriceDetails] = useState([]);

    Date.prototype.toDateString = function () {
        var month = this.getMonth() + 1;
        var day = this.getDate();
        var year = this.getFullYear();
        return year + "-" + (month < 10 ? '0' : '') + month + '-' + (day < 10 ? '0' : '') + day;
    };

    const udfnItemNameList = async () => {
        setIsLoading(true)
        try {
            const itemParams = { user_id: Number(user?.id), status_code: Number(0) };

            const [itemData] = await Promise.all([
                axios.post(BASE_URL + COMMON_API.ITEM_MASTER_LIST, itemParams, config),
            ]);

            if (itemData?.data?.body?.status === COMMONVARIABLES.STATUS.INVALIDUSER) {
                setIsLoading(false)
                var errorMsg = itemData?.data?.body?.message;
                toast.error(errorMsg, { position: toast.POSITION.TOP_RIGHT });
                UserLogout();
            }

            if (itemData?.data?.body?.status === COMMONVARIABLES.STATUS.UNAUTHORIZED) {
                setIsLoading(false)
                const refresh = await getRefreshToken();
                if (refresh) {
                    if (Number(refresh) === COMMONVARIABLES.STATUS.EXPIRED_TOKEN) {
                        UserLogout();
                    } else {
                        await udfnItemNameList();
                    }
                }
            }

            if (itemData?.data?.body?.itemList) {
                setIsLoading(false)
                setItemOptions(itemData?.data?.body?.itemList)
            }
        } catch (error) {
            setIsLoading(false)
            console.error('Error fetching master data:', error);
        }
    }

    async function udfnItemPriceDetails() {
        if (itemName !== '') {
            try {
                setIsLoading(true)

                const params = {
                    user_id: Number(user?.id),
                    item_code: itemName?.value,
                };

                const [itemPriceDetails] = await Promise.all([
                    axios.post(BASE_URL + MASTER_API.ITEM_PRICE_DETAILS, params, config),
                ]);

                if (itemPriceDetails && itemPriceDetails?.data?.body?.status === COMMONVARIABLES.STATUS.INVALIDUSER) {
                    setIsLoading(false)
                    toast.error(itemPriceDetails?.data?.body?.message, { position: toast.POSITION.TOP_RIGHT });
                    UserLogout();
                }

                if (itemPriceDetails?.data?.body?.status === COMMONVARIABLES.STATUS.UNAUTHORIZED) {
                    setIsLoading(false)
                    const refresh = await getRefreshToken();
                    if (refresh) {
                        if (Number(refresh) === COMMONVARIABLES.STATUS.EXPIRED_TOKEN) {
                            UserLogout();
                        } else {
                            await udfnItemPriceDetails();
                        }
                    }
                }

                if (itemPriceDetails?.data?.body?.itemPriceDetails) {
                    setIsLoading(false)
                    setItemPriceDetails(itemPriceDetails?.data?.body?.itemPriceDetails)
                }

            } catch (error) {
                setIsLoading(false)
                console.error('Error fetching master data:', error);
            }
        } else {
            toast.error(ERROR_MESSAGE.COMMON_REQUIRED, { position: toast.POSITION.TOP_RIGHT });
        }
    }

    useEffect(() => {
        setItemPriceDetails([])
    }, [itemName?.value]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        udfnItemNameList()
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <LoadingOverlay
                active={isLoading}
                spinner
                text='Loading ...'
            >
                <ToastContainer />
                <div className="card">
                    <div className="card-header">
                        <div className='col-sm-5'>
                            <h4 className="card-title">Item Price Details</h4>
                        </div>
                    </div>
                    <div className="card-body">
                        <div className='row'>
                            <div className='col-lg-4'>
                                <p className="mb-1">Item Name <span className="text-danger star-txt">*</span></p>
                                <Select
                                    defaultValue={itemName}
                                    value={itemName}
                                    onChange={setItemName}
                                    options={itemOptions.map((option) => ({
                                        label: option.im_item_name,
                                        value: option.im_item_code,
                                    }))}
                                    isClearable
                                    showContent="true"
                                    open="true"
                                    className="my-react-select-container"
                                    theme={theme => ({
                                        ...theme,
                                        borderRadius: '6px',
                                        colors: {
                                            ...theme.colors,
                                            primary25: '#b3b3b3',
                                            primary: 'black',
                                            neutral80: "data-theme-version" === "dark" ? 'white' : ''
                                        },
                                    })}
                                />
                            </div>
                            <div className="col-lg-4">
                                <Button className="py-2" variant="whatsapp" style={{ marginTop: "10%" }} onClick={udfnItemPriceDetails}>
                                    View
                                </Button>
                            </div>
                        </div>

                        <div className='row mt-4'>
                            <div className='col-lg-7'>
                                <div className="table-responsive">
                                    <table className="table filtering-table table-responsive">
                                        <thead>
                                            <tr>
                                                <th>S.NO.</th>
                                                <th>QrCode</th>
                                                <th className='text-end'>Purchase Rate</th>
                                                <th className='text-end'>Selling Price</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {itemPriceDetails.map((row, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td>{index + 1}</td>
                                                        <td>{row.qr_code}</td>
                                                        <td className='text-end' style={{ paddingRight: '2%' }}>
                                                            <i className="fa fa-rupee udfn-rupee-icon rupeeicon"></i> {COMMONVARIABLES.India_Rupee_Formatter_Digits(Formatting('two_decimal', row.purcahse_rate))}
                                                        </td>
                                                        <td className='text-end' style={{ paddingRight: '2%' }}>
                                                            <i className="fa fa-rupee udfn-rupee-icon rupeeicon"></i> {COMMONVARIABLES.India_Rupee_Formatter_Digits(Formatting('two_decimal', row.selling_rate))}
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            </LoadingOverlay >
        </>
    )
}

export default ItemPriceDetails;